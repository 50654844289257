import { call } from '../core-reference.service';
import {
  TreatmentUpdateType,
  TreatmentDeleteType,
  TreatmentInsertType,
  TreatmentSearchType,
  TreatmentSelectType,
  TreatmentType,
} from '../../models/core/treatments.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const treatmentsEndpoint = '/v1/residuo-tratamentos';

export default class TreatmentsTreatment {
  static search = async (searchProps: TreatmentSearchType): Promise<TreatmentType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: treatmentsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<TreatmentType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: TreatmentSelectType): Promise<TreatmentType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${treatmentsEndpoint}/${selectProps.idResiduoTratamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: TreatmentUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${treatmentsEndpoint}/${updateProps.idResiduoTratamento}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: TreatmentDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${treatmentsEndpoint}/${deleteProps.idResiduoTratamento}`,
    };
    return call(request);
  };

  static insert = async (insertProps: TreatmentInsertType): Promise<TreatmentType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: treatmentsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
