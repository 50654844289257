/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Grid, Group, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import { EntityType } from '../../../../../../models/core/entities.type';
import { Feature } from '../../../../../../utils/constants.utils';

type DataViewProps = {
  data: EntityType;
};

const buildBadges = (list: string[]) => {
  if ((list || []).length === 0) {
    return '-';
  }
  return (
    <Group>
      {list.map((x) => (
        <Badge key={x} variant="outline">
          {x}
        </Badge>
      ))}
    </Group>
  );
};

export default function GeneralView(props: DataViewProps) {
  return (
    <div>
      <Group>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Geral" text="" />
      </Group>
      <Space h="xs" />
      <Grid columns={3}>
        <Grid.Col span={3}>
          <PageViewProperty
            label="Escopos"
            text={buildBadges(props.data.tipos.map((x) => x.entidadeTipo))}
            size="md"
          />
        </Grid.Col>
      </Grid>
      <Divider my="sm" variant="dotted" />
      {props.data.cnpj ? (
        <SimpleGrid cols={5}>
          <PageViewProperty label="CNPJ" text={props.data.cnpj || '-'} size="md" />
          <PageViewProperty label="Razão Social" text={props.data.razaoSocial || '-'} size="md" />
          <PageViewProperty label="Nome Fantasia" text={props.data.nomeFantasia || '-'} size="md" />
          <PageViewProperty label="Inscrição Estadual" text={props.data.incricaoEstadual || '-'} size="md" />
          <PageViewProperty
            label="Inscrição Municipal"
            text={props.data.incricaoMunicipal || '-'}
            size="md"
          />
        </SimpleGrid>
      ) : (
        <SimpleGrid cols={5}>
          <PageViewProperty label="CPF" text={props.data.cpf || '-'} size="md" />
          <PageViewProperty label="Nome" text={props.data.nome || '-'} size="md" />
        </SimpleGrid>
      )}
      <Divider my="sm" variant="dotted" />
      <SimpleGrid cols={5}>
        <PageViewProperty
          label="Encontro de Contas?"
          text={props.data.aceitaEncontroContas ? 'Sim' : 'Não'}
          size="md"
        />
        <PageViewProperty label="Web Site" text={props.data.webSite || '-'} size="md" />
      </SimpleGrid>
    </div>
  );
}
