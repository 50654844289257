/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Divider, Grid, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Eye } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { ProposalResiduePlanEquipmentType } from '../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../theme';
import { Feature } from '../../../../../../../../../utils/constants.utils';

type ViewProps = {
  data: ProposalResiduePlanEquipmentType[];
};

export default function ResiduePlanGeneralEquipmentView(props: ViewProps) {
  const [planItem, setPlanItem] = useState<ProposalResiduePlanEquipmentType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanEquipmentType> = [
    {
      title: 'Equipamento',
      key: 'residuoEquipamento',
      sorter: (a: ProposalResiduePlanEquipmentType, b: ProposalResiduePlanEquipmentType) =>
        a.residuoEquipamento.localeCompare(b.residuoEquipamento),
      render: (row: ProposalResiduePlanEquipmentType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoEquipamento?.toString() || ''}
            name={row.residuoEquipamento || ''}
            nameSize="sm"
            description={row.residuoEquipamentoDescricao}
            descriptionSize="xs"
            linkPrefix="equipment"
            showLink
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalResiduePlanEquipmentType, b: ProposalResiduePlanEquipmentType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanEquipmentType) => (
        <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ProposalResiduePlanEquipmentType, b: ProposalResiduePlanEquipmentType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ProposalResiduePlanEquipmentType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanEquipmentType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Plano/Equipamento - Visualizar"
        size="50%"
      >
        {planItem ? (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={5}>
                <Grid.Col span={3}>
                  <PageViewProperty
                    label="Equipamento"
                    text={
                      <ProfileCardLink
                        id={(planItem.idResiduoEquipamento || '').toString()}
                        name={planItem.residuoEquipamento || '-'}
                        nameSize="sm"
                        description={planItem.residuoEquipamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="equipment"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={`${planItem.quantidade} ${planItem.quantidadeUnidadeMedida}`}
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planItem.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planItem.idCriadoPor}
              criadoPor={planItem.criadoPor}
              dataCriacao={planItem.dataCriacao}
              idModificadoPor={planItem?.idModificadoPor || null}
              modificadoPor={planItem?.modificadoPor || null}
              dataModificacao={planItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Equipamentos"
        text="Equipamentos para este plano."
      />
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ProposalResiduePlanEquipmentType) => item.idPropostaResiduoPlanoEquipamento || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
