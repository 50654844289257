/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Button, Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { DepartmentType } from '../../../../models/core/departments.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import departmentsService from '../../../../services/core/departments.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import cacheUtils from '../../../../utils/cache.utils';
import { CompanyType } from '../../../../models/core/cache.type';

function DepartmentSearch() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
  }>({ companyData: [] });

  const [data, setData] = useState<DepartmentType[]>([]);
  const [loading, setLoading] = useState(false);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      const companyData = await cacheUtils.listCompanies();
      const tempCompanyData = JSON.parse(JSON.stringify(companyData));
      if (currentUser.executivo) {
        tempCompanyData.splice(0, 0, { ...currentUser, idEmpresa: 0 });
      }

      setFilterData({
        companyData: tempCompanyData,
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.DepartmentSearch) || '[]'
      ) as DepartmentType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        filter({ idEmpresa: '', departamento: '' });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.DepartmentSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await departmentsService.search({
        idEmpresa: inputFilterData?.idEmpresa || null,
        departamento: inputFilterData?.departamento || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.DepartmentSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Departamentos',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar departamentos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: DepartmentType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);

      switch (action) {
        case 'excluir':
          await departmentsService.delete({ idDepartamento: item.idDepartamento });
          setData(data.filter((x) => x.idDepartamento !== item.idDepartamento));
          break;
        case 'visualizar':
          navigate(`/departments/${item.idDepartamento}`);
          return;
        case 'editar':
          navigate(`/departments/${item.idDepartamento}/edit`);
          return;
        default:
          break;
      }

      showNotification({
        title: 'Departamentos',
        message: `Departamento ${action === 'cancelar' ? 'cancelado' : 'excluído'} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Departamentos',
        message: error?.isBusinessException
          ? error.description
          : `Não foi possível ${action} o departamento.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.Department}
        title="Departamentos"
        description="Gerencie seus departamentos."
        buttons={[
          <Button
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={() => navigate('/departments/add')}
          >
            Adicionar
          </Button>,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter companies={filterData.companyData} filter={filter} clear={clear} loading={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default DepartmentSearch;
