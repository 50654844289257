import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { createRoot } from 'react-dom/client';
import ptBR from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import reportWebVitals from './reportWebVitals';
import App from './App';
import theme from './theme';
import './index.css';

dayjs.locale('pt-br');
dayjs.extend(customParseFormat);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <MantineProvider theme={theme} withNormalizeCSS>
    <ConfigProvider locale={ptBR}>
      <NotificationsProvider position="top-right">
        <ModalsProvider modals={{}} labels={{ confirm: 'Sim', cancel: 'Não' }}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ModalsProvider>
      </NotificationsProvider>
    </ConfigProvider>
  </MantineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
