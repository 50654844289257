/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { Avatar, Box, Group, MantineSize, Text } from '@mantine/core';
import { Core } from '../../../utils/constants.utils';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';

type ProfileCardLinkProps = {
  id: string;
  name: string;
  nameSize: MantineSize;
  description?: string | null;
  descriptionSize?: MantineSize;
  descriptionLength?: number;
  avatar?: string;
  avatarSize?: MantineSize;
  linkPrefix?: string;
  showLink?: boolean;
};

export default function ProfileCardLink(props: ProfileCardLinkProps) {
  const shouldShowLink = () => {
    if (props.linkPrefix !== 'users') {
      return true;
    }
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(props.id) && props.id !== Core.SystemId;
  };

  const buildContent = () => {
    let { name } = props;
    name = name.trim();

    let initials = !name.includes(' ')
      ? name[0].toUpperCase()
      : `${name[0].toUpperCase()}${name.split(' ').reverse()[0][0].toUpperCase()}`;

    if (props.avatar) {
      initials = props.avatar.substring(0, 2);
    }

    return (
      <Group>
        <Avatar
          color="primary"
          variant="gradient"
          radius="xl"
          size={props.avatarSize || props.nameSize}
          classNames={{ placeholder: 'custom-mantine-avatar-placeholder-gradient' }}
        >
          <Text size={props.nameSize}>{initials}</Text>
        </Avatar>
        <Box sx={{ flex: 1 }}>
          <Text size={props.nameSize} color="primary" weight={500}>
            <TableCellEllipsis label={props.name} numberOfChars={100} toolTipWidth={300} />
          </Text>
          <Text color="dimmed" size={props?.descriptionSize}>
            {props?.description && (
              <TableCellEllipsis
                label={props?.description}
                numberOfChars={props.descriptionLength || 35}
                toolTipWidth={300}
              />
            )}
          </Text>
        </Box>
      </Group>
    );
  };

  let finalLink = `/${props.linkPrefix || 'users'}/${props.id}`;
  if (props.linkPrefix === 'events') {
    finalLink = `/${props.linkPrefix}?id=${props.id}`;
  }

  return (
    <div>
      {(props.showLink === undefined || props.showLink === true) && shouldShowLink() ? (
        <a href={finalLink} target="_blank" rel="noreferrer">
          {buildContent()}
        </a>
      ) : (
        buildContent()
      )}
    </div>
  );
}
