/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Grid, Group, Input, NumberInput, Select, SimpleGrid, Space, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Notes, Ruler2 } from 'tabler-icons-react';
import EquipmentSearch from '../../../../../../../components/core/EquipmentSearch/EquipmentSearch';
import { useEventEquipmentContext } from '../../../../../../../contexts/core/events/EventEquipment.context';
import { UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EventEquipmentType } from '../../../../../../../models/core/events.type';
import { EquipmentType } from '../../../../../../../models/core/equipment.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoEquipamento: number | null;
  residuoEquipamento: string;
  residuoEquipamentoDescricao: string | null;

  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventEquipmentType | null;
  item: EventEquipmentType | null;
  disabled: boolean;
};

const EventEquipmentFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const { setEventEquipmentData } = useEventEquipmentContext();
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoEquipamento: props.item?.idResiduoEquipamento || null,
      residuoEquipamento: props.item?.residuoEquipamento || '',
      residuoEquipamentoDescricao: props.item?.residuoEquipamentoDescricao || null,

      quantidade: props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida.toString() ||
        props.referenceData.unitOfMeasures
          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
          ?.idUnidadeMedida.toString() ||
        null,

      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoEquipamento: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoEquipamento || ''}
    |${Number(props.origItem?.quantidade) || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoEquipamento || ''}
    |${Number(form.values.quantidade) || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): EventEquipmentType | null {
      if (form.validate().hasErrors) {
        return null;
      }

      let { values } = form;
      if (props.origItem) {
        if (isModified()) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
            quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          };
        }
      }

      const formItem: EventEquipmentType = {
        ...values,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idEventoEquipamento: props.item?.idEventoEquipamento || -1,
      };

      formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
      formItem.quantidadeUnidadeMedida =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
        )?.unidadeMedida || '';

      formItem.observacao = formItem.observacao?.trim() || null;

      return formItem;
    },
  }));

  useEffect(() => {
    setEventEquipmentData({
      idResiduoEquipamento: form.values.idResiduoEquipamento,
      quantidade: form.values.quantidade,
      quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida,
    });
  }, [form.values, setEventEquipmentData]);

  return (
    <form id="event-equipment" noValidate>
      <Grid columns={6}>
        <Grid.Col span={3}>
          <EquipmentSearch
            label="Equipamento"
            formItem={form.getInputProps('residuoEquipamento')}
            idsToBeDisabled={[]}
            callback={(item: EquipmentType | null) => {
              if (item) {
                form.setFieldValue('idResiduoEquipamento', item.idResiduoEquipamento);
                form.setFieldValue('residuoEquipamento', item.residuoEquipamento);
                form.setFieldValue('residuoEquipamentoDescricao', item.descricao);
              }
            }}
            disabled={props.disabled}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Input.Wrapper label="Quantidade" required>
            <div />
          </Input.Wrapper>
          <Group spacing="xs">
            <NumberInput
              style={{ width: 125 }}
              placeholder="Quantidade"
              min={1}
              defaultValue={1}
              step={1}
              stepHoldDelay={500}
              stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
              required
              {...form.getInputProps('quantidade')}
              disabled={props.disabled}
            />
            <Select
              icon={<Ruler2 size={15} />}
              placeholder="Selecione..."
              data={props.referenceData.unitOfMeasures
                .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                .map((x) => {
                  return {
                    value: x.idUnidadeMedida.toString(),
                    label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                    group: x.tipo,
                  };
                })}
              searchable
              disabled
              required
              {...form.getInputProps('quantidadeIdUnidadeMedida')}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Observação"
          placeholder="Observação sobre o equipamento"
          autosize
          maxLength={500}
          {...form.getInputProps('observacao')}
          disabled={props.disabled}
        />
      </SimpleGrid>
    </form>
  );
});

export default EventEquipmentFormAddEdit;
