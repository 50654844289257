/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Grid, Group, Modal, NumberInput, Paper, SimpleGrid, Space, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import lodash from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Calendar, CurrencyReal, DeviceFloppy, FileInvoice, Notes } from 'tabler-icons-react';
import { Status } from '../../../../../business/events/general';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { EventStatusType, MovementType, UnitOfMeasureType } from '../../../../../models/core/cache.type';
import { Action } from '../../../../../models/core/core.type';
import { Permission } from '../../../../../models/core/departments.type';
import {
  EventBillingMeasurementType,
  EventBillingType,
  EventType,
} from '../../../../../models/core/events.type';
import { UserType } from '../../../../../models/core/users.type';
import eventsService from '../../../../../services/core/events.service';
import { Feature } from '../../../../../utils/constants.utils';
import {
  formatCurrency,
  formatDateStringToDate,
  formatDateToString,
} from '../../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid, tryGetDateISOFormat } from '../../../../../utils/helper.utils';
import { validate } from '../../../../../utils/permission.utils';
import BillingMeasurementFormView from './BillingFormView/BillingMeasurementFormView/BillingMeasurementFormView';
import BillingStatementView from './BillingFormView/BillingStatementView/BillingStatementView';

type EventFormViewProps = {
  referenceData: {
    eventStatusData: EventStatusType[];
    userData: UserType[];
    unitOfMeasures: UnitOfMeasureType[];
    movementTypeData: MovementType[];
  };
  data: EventType;
  callbackBilling(item: EventBillingType): void;
};

type FormViewData = {
  action: Action;
  dataInicial: Date;
  dataFinal: Date;
  receita: number;
  receitaPersonalizada: number | null;
  despesa: number;
  despesaPersonalizada: number | null;
  imposto: number;
  impostoPersonalizado: number | null;
  personalizado: boolean;
  observacao: string | null;
};

type ModalData = {
  opened: boolean;
  faturamento: EventBillingType | null;
};

export default function BillingFormView(props: EventFormViewProps) {
  const [currentUser] = useCurrentUser();
  const refBillingMeasurementForm = useRef<any>();

  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState<{
    faturamento: EventBillingType | null;
    key: string;
  }>({ faturamento: props.data.faturamento, key: newGuid() });

  const [modalData, setModalData] = useState<ModalData>({
    opened: false,
    faturamento: null,
  });

  const dataDescricao = props.data.descricao
    .substring(props.data.descricao.indexOf('(') + 1, props.data.descricao.lastIndexOf(')'))
    .split(' - ');
  let extDataInicial = props.data.data;
  let extDataFinal = props.data.data;

  if (dataDescricao.length === 2) {
    try {
      extDataInicial = moment(dataDescricao[0], 'DD/MM/YYYY').toDate();
      extDataFinal = moment(dataDescricao[1], 'DD/MM/YYYY').toDate();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const disabled = props.data.codigoEventoStatus !== Status.Pendente;

  const form = useForm<FormViewData>({
    initialValues: {
      action: data.faturamento ? Action.Nothing : Action.Add,
      dataInicial: formatDateStringToDate(
        tryGetDateISOFormat(data?.faturamento?.dataInicial || extDataInicial)
      ),
      dataFinal: formatDateStringToDate(tryGetDateISOFormat(data?.faturamento?.dataFinal || extDataFinal)),
      receita: data?.faturamento?.receita || 0,
      receitaPersonalizada: data?.faturamento?.receitaPersonalizada || null,
      despesa: data?.faturamento?.despesa || 0,
      despesaPersonalizada: data?.faturamento?.despesaPersonalizada || null,
      imposto: data?.faturamento?.imposto || 0,
      impostoPersonalizado: data?.faturamento?.impostoPersonalizado || null,
      personalizado: data?.faturamento?.personalizado || false,
      observacao: data?.faturamento?.observacao || '',
    },
    validate: {
      dataInicial: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        return null;
      },
      dataFinal: (value, values: FormViewData) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (value < values.dataInicial) {
          return 'Data final não pode ser anterior à data inicial';
        }
        return null;
      },
      receita: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (Number(value) < 0) {
          return `Valor deve ser no mínimo ${formatCurrency(0.0)}`;
        }
        return null;
      },
      despesa: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (Number(value) < 0) {
          return `Valor deve ser no mínimo ${formatCurrency(0.0)}`;
        }
        return null;
      },
      imposto: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (Number(value) < 0) {
          return `Valor deve ser no mínimo ${formatCurrency(0.0)}`;
        }
        return null;
      },
      receitaPersonalizada: (value, values: FormViewData) => {
        if (!value && values.personalizado) {
          return 'Campo obrigatório';
        }
        return null;
      },
      despesaPersonalizada: (value, values: FormViewData) => {
        if (!value && values.personalizado) {
          return 'Campo obrigatório';
        }
        return null;
      },
      impostoPersonalizado: (value, values: FormViewData) => {
        if (!value && values.personalizado) {
          return 'Campo obrigatório';
        }
        return null;
      },
      observacao: (value: string | null, values: FormViewData) => {
        if ((!value || value.trim() === '') && values.personalizado) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const balanco = form.values.receita - form.values.despesa - form.values.imposto;
  const balancoPersonalizado = !form.values.personalizado
    ? 0
    : (form.values.receitaPersonalizada || 0) -
      (form.values.despesaPersonalizada || 0) -
      (form.values.impostoPersonalizado || 0);

  const isModified = (): boolean => {
    const origData = `${formatDateToString(data?.faturamento?.dataInicial || extDataInicial) || ''}
    |${formatDateToString(data?.faturamento?.dataFinal || extDataFinal) || ''}
    |${data?.faturamento?.receita || ''}
    |${Number(data?.faturamento?.receitaPersonalizada) || ''}
    |${data?.faturamento?.despesa || ''}
    |${Number(data?.faturamento?.despesaPersonalizada) || ''}
    |${data?.faturamento?.imposto || ''}
    |${data?.faturamento?.impostoPersonalizado || ''}
    |${data?.faturamento?.personalizado || ''}
    |${data?.faturamento?.observacao || ''}`;

    const formData = `${formatDateToString(form.values.dataInicial)}
    |${formatDateToString(form.values.dataFinal) || ''}
    |${form.values.receita || ''}
    |${form.values.receitaPersonalizada || ''}
    |${form.values.despesa || ''}
    |${form.values.despesaPersonalizada || ''}
    |${form.values.imposto || ''}
    |${form.values.impostoPersonalizado || ''}
    |${form.values.personalizado || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const buildFormItem = () => {
    const formValues = form.values;

    const formItem: EventBillingType = {
      ...formValues,
      ...buildFakeAuditObject(),
      idEvento: props.data.idEvento,
      idEventoFaturamento: data?.faturamento?.idEventoFaturamento,
      balanco,
      balancoPersonalizado,
    };

    if (!formItem.personalizado) {
      formItem.receitaPersonalizada = null;
      formItem.despesaPersonalizada = null;
      formItem.impostoPersonalizado = null;
      formItem.balancoPersonalizado = null;
    }

    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.relacoes = refBillingMeasurementForm.current.validate().map((x: EventBillingMeasurementType) => {
      return {
        action: (x as any).action,
        idEventoRelacao: x.idEventoRelacao || null,
        idEventoReferenciado: x.idEvento,
      };
    });

    return formItem;
  };

  const callbackBillingMeasurement = (items: EventBillingMeasurementType[]) => {
    const calcItems = items.filter((x) => x.action !== Action.Delete);

    const grandTotal = {
      receita: lodash.sumBy(calcItems, 'receita'),
      despesa: lodash.sumBy(calcItems, 'despesa'),
      imposto: lodash.sumBy(calcItems, 'imposto'),
    };

    form.setValues({
      ...form.values,
      receita: grandTotal.receita,
      despesa: grandTotal.despesa,
      imposto: grandTotal.imposto,
    });
  };

  const handleSubmit = async () => {
    const formResult = form.validate();
    if (formResult.hasErrors) {
      return;
    }

    try {
      setUpdating(true);

      const formValues = form.values;
      if (props.data.faturamento || data.faturamento?.idEventoFaturamento) {
        if (isModified()) {
          formValues.action = Action.Modify;
        } else {
          formValues.action = Action.Nothing;
        }
      }

      const formItem = buildFormItem();
      let response;

      if (formItem.action === Action.Add && !data.faturamento?.idEventoFaturamento) {
        response = await eventsService.insertBilling(formItem);
      } else {
        response = await eventsService.updateBilling(formItem);
      }

      setData({ faturamento: response, key: newGuid() });

      props.callbackBilling(response);
      showNotification({
        title: 'Evento - Visualizar',
        message: `Faturamento definido com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Evento - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível difinir o faturamento.`,
        color: 'red',
      });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setModalData({ opened: false, faturamento: null })}
        title="Evento/Faturamento - Extrato"
        size="80%"
      >
        <BillingStatementView data={props.data} />
      </Modal>

      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Faturamento"
          text="Informações de faturamento do evento."
        />
        <Group position="right">
          <Button
            color={Feature.Home.Event.color}
            leftIcon={<FileInvoice size={18} />}
            type="button"
            onClick={() => {
              setModalData({ opened: true, faturamento: data.faturamento });
            }}
            hidden={!data.faturamento}
            loading={updating}
          >
            Extrato
          </Button>
          <Button
            color="accent"
            leftIcon={<DeviceFloppy size={18} />}
            type="button"
            form="event-billing"
            onClick={handleSubmit}
            loading={updating}
            hidden={disabled}
            disabled={!validate(Permission.EventEdit, currentUser.permissoes)}
          >
            Definir Faturamento
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <form id="event-billing" noValidate>
          <Grid columns={4}>
            <Grid.Col span={1}>
              <DatePicker
                icon={<Calendar size={15} />}
                label="Data Inicial"
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                required
                disabled={disabled}
                {...form.getInputProps('dataInicial')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <DatePicker
                icon={<Calendar size={15} />}
                label="Data Final"
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={form.values.dataInicial}
                required
                disabled={disabled}
                {...form.getInputProps('dataFinal')}
              />
            </Grid.Col>
            {/* <Grid.Col span={1}>
                <div style={{ marginTop: 30 }}>
                  <Switch
                    label="Personalizar?"
                    disabled={disabled}
                    {...form.getInputProps('personalizado', { type: 'checkbox' })}
                  />
                </div>
              </Grid.Col> */}
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Receita"
                placeholder="Receita"
                min={0}
                precision={2}
                hideControls
                required
                disabled
                {...form.getInputProps('receita')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Despesa"
                placeholder="Despesa"
                min={0}
                precision={2}
                hideControls
                required
                disabled
                {...form.getInputProps('despesa')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Imposto"
                placeholder="Imposto"
                min={0}
                precision={2}
                hideControls
                required
                disabled
                {...form.getInputProps('imposto')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <PageViewProperty
                label="Balanço"
                text={
                  <div
                    style={{
                      paddingTop: 5,
                      fontSize: 'medium',
                      color: balanco > 0 ? 'green' : balanco === 0 ? 'orange' : 'red',
                    }}
                  >
                    {formatCurrency(balanco)}
                  </div>
                }
                size="sm"
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          {form.values.personalizado && (
            <div>
              <Grid columns={4}>
                <Grid.Col span={1}>
                  <NumberInput
                    icon={<CurrencyReal size={15} />}
                    label="Receita Personalizada"
                    placeholder="Receita"
                    min={0}
                    precision={2}
                    hideControls
                    required
                    disabled={disabled}
                    {...form.getInputProps('receitaPersonalizada')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <NumberInput
                    icon={<CurrencyReal size={15} />}
                    label="Despesa Personalizada"
                    placeholder="Despesa"
                    min={0}
                    precision={2}
                    hideControls
                    required
                    disabled={disabled}
                    {...form.getInputProps('despesaPersonalizada')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <NumberInput
                    icon={<CurrencyReal size={15} />}
                    label="Imposto Personalizado"
                    placeholder="Imposto"
                    min={0}
                    precision={2}
                    hideControls
                    required
                    disabled={disabled}
                    {...form.getInputProps('impostoPersonalizado')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Balanço Personalizado"
                    text={
                      <div
                        style={{
                          paddingTop: 5,
                          fontSize: 'medium',
                          color:
                            balancoPersonalizado > 0
                              ? 'green'
                              : balancoPersonalizado === 0
                              ? 'orange'
                              : 'red',
                        }}
                      >
                        {formatCurrency(balancoPersonalizado)}
                      </div>
                    }
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Space h="xs" />
            </div>
          )}

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre a evento"
              autosize
              maxLength={500}
              disabled={disabled}
              required={form.values.personalizado}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </form>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <BillingMeasurementFormView
          key={data.key}
          ref={refBillingMeasurementForm}
          event={props.data}
          data={data.faturamento?.medicoes || []}
          referenceData={props.referenceData}
          editable={!disabled && validate(Permission.EventEdit, currentUser.permissoes)}
          dataInicial={form.values.dataInicial}
          dataFinal={form.values.dataFinal}
          callbackBillingMeasurement={callbackBillingMeasurement}
        />
      </Paper>
    </div>
  );
}
