/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import { Link, useNavigate } from 'react-router-dom';
import { Database, Plus } from 'tabler-icons-react';
import { ThemeIcon, UnstyledButton, Group, Text, Accordion, ActionIcon } from '@mantine/core';
import theme from '../../../../theme';
import { Feature } from '../../../../utils/constants.utils';
import { FeatureType } from '../../../../models/utils/constants.type';
import { validate } from '../../../../utils/permission.utils';
import { DepartmentPermissionType } from '../../../../models/core/departments.type';

type DataReferenceLinksProps = {
  userPermissions: DepartmentPermissionType[];
};

function DataReferenceLinks(props: DataReferenceLinksProps) {
  const navigate = useNavigate();

  const data: FeatureType[] = [
    Feature.Reference.Entity,
    Feature.Reference.Residue,
    Feature.Reference.Service,
    Feature.Reference.Packaging,
    Feature.Reference.Destination,
    Feature.Reference.Equipment,
    Feature.Reference.Treatment,
    Feature.Reference.Vehicle,
  ].filter((x) => validate(x.permissions?.Search, props.userPermissions));

  return data.length > 0 ? (
    <Accordion chevronPosition="right" defaultValue="references">
      <Accordion.Item value="references">
        <Accordion.Control>
          <Group>
            <ThemeIcon color={theme?.colors?.primary?.[6]} variant="outline">
              <Database size={16} />
            </ThemeIcon>
            <Text size="sm" weight={500} color={theme?.colors?.primary?.[6]}>
              Referências
            </Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          {data.map((item) => (
            <Link key={item.label} to={item.link}>
              <Group
                position="apart"
                sx={(innerTheme) => ({
                  padding: innerTheme.spacing.xs,
                  '&:hover': {
                    backgroundColor: innerTheme.colors.gray[0],
                  },
                })}
              >
                <UnstyledButton>
                  <Group>
                    <ThemeIcon color={item.color} variant="outline">
                      <item.icon size={16} />
                    </ThemeIcon>
                    <Text size="sm">{item.label}</Text>
                  </Group>
                </UnstyledButton>
                {item.hasAddLink && validate(item.permissions?.Add, props.userPermissions) && (
                  <ActionIcon
                    size="sm"
                    radius="sm"
                    color={item.color}
                    variant="outline"
                    onClick={(event: any) => {
                      event.preventDefault();
                      const goTo = `${item.link}/add`;
                      if (window.location.href.includes(item.link) && window.location.href.endsWith('edit')) {
                        window.location.href = goTo;
                      } else {
                        navigate(goTo);
                      }
                    }}
                  >
                    <Plus size={15} />
                  </ActionIcon>
                )}
              </Group>
            </Link>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  ) : (
    <></>
  );
}

export default DataReferenceLinks;
