/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { DeviceFloppy } from 'tabler-icons-react';
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { EntityTreatmentType } from '../../../../../../../models/core/entities.type';
import { FilterData, TreatmentFormSearchFilter } from './TreatmentFormSearchFilter';
import { TreatmentType } from '../../../../../../../models/core/treatments.type';
import { SessionStorageKey } from '../../../../../../../utils/constants.utils';
import treatmentsService from '../../../../../../../services/core/treatments.service';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';
import { Action } from '../../../../../../../models/core/core.type';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';

type FormViewProps = {
  items: EntityTreatmentType[];
  idEntidade: number | undefined;
  callback(items: EntityTreatmentType[], action: string, confirmed: boolean): void;
};

export default function TreatmentFormSearch(props: FormViewProps) {
  const [data, setData] = useState<TreatmentType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<TreatmentType[]>([]);

  const handleSubmit = () => {
    props.callback(
      selectedData.map((x) => {
        return {
          ...x,
          ...buildFakeAuditObject(),
          residuoTratamentoDescricao: x.descricao,
          idEntidade: props.idEntidade || -1,
          id: newGuid(),
          action: Action.Add,
        };
      }),
      'callback',
      true
    );
  };

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.TreatmentSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await treatmentsService.search({
        residuoTratamento: inputFilterData?.tratamento || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.TreatmentSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Entidade - ${!props.idEntidade ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar tratamentos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TreatmentType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: TreatmentType) => ({
      disabled: props.items.findIndex((x) => x.idResiduoTratamento === record.idResiduoTratamento) !== -1,
      name: record.idResiduoTratamento.toString(),
    }),
  };

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.TreatmentSearch) || '[]'
    ) as TreatmentType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({ tratamento: '' });
    }
  }, []);

  return (
    <div>
      <TreatmentFormSearchFilter filter={filter} clear={clear} loading={loading} />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: 'Tratamento',
                key: 'tratamento',
                dataIndex: 'residuoTratamento',
                sorter: (a: TreatmentType, b: TreatmentType) =>
                  a.residuoTratamento.localeCompare(b.residuoTratamento),
              },
              {
                title: 'Descrição',
                key: 'descricao',
                sorter: (a: TreatmentType, b: TreatmentType) => {
                  return (a.descricao || '').localeCompare(b.descricao || '');
                },
                render: (row: TreatmentType) => (
                  <TableCellEllipsis label={row?.descricao} numberOfChars={25} toolTipWidth={300} />
                ),
              },
            ]}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            rowKey={(item: TreatmentType) => item.idResiduoTratamento}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
