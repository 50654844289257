/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  TextInput,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  CurrencyReal,
  DeviceFloppy,
  FileDots,
  FileInfo,
  InfoCircle,
  Notes,
  Ruler2,
} from 'tabler-icons-react';
import { ReferenciaCodigo } from '../../../../../../business/events/general';
import { calculateHash, getMovementBillingType } from '../../../../../../business/events/measurement';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  MovementType,
  MovementTypeGroup,
  MovementTypeGroupCode,
  MovementTypeStandard,
  UnitOfMeasureType,
} from '../../../../../../models/core/cache.type';
import { Action } from '../../../../../../models/core/core.type';
import { EventMeasurementMovementType, EventServiceType } from '../../../../../../models/core/events.type';
import theme from '../../../../../../theme';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;
  idMovimentacaoTipo: string;
  movimentacaoTipo: string;
  outroTipo: string | null;
  idEventoAcondicionamento: number | null;
  idEventoEquipamento: number | null;
  idEventoVeiculo: number | null;
  idEventoTratamento: number | null;
  idEventoDestinoFinal: number | null;
  idEventoServico: number | null;
  idEventoTolerancia: number | null;
  quantidade: number;
  quantidadeIdUnidadeMedida: string | null;
  receita: number;
  despesa: number;
  imposto: number;
  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    movementTypeData: MovementType[];
  };
  origItem: EventMeasurementMovementType | null;
  item: EventMeasurementMovementType | null;
  idEventoMedicao: number | null;
  movementTypeGroup: MovementTypeGroup | null;
  movementTypeGroupRefId: number | null;
  movementTypeGroupToleranceId: number | null;
  supplier: {
    idFornecedor: number;
    fornecedorCNPJ: string | null;
    fornecedorRazaoSocial: string | null;
    fornecedorNomeFantasia: string | null;
    fornecedorCPF: string | null;
    fornecedorNome: string | null;
  };
  codigoEventoReferencia: ReferenciaCodigo;
  servico: EventServiceType | undefined;

  callback(
    item: EventMeasurementMovementType | null,
    group: MovementTypeGroup | null,
    refId: number | null,
    toleranceRefId: number | null,
    supplierId: number | null,
    action: string,
    confirmed: boolean
  ): void;
};

export default function MovementFormAddEdit(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.idEventoMedicaoMovimentacao?.toString() || props.item?.id || newGuid(),
      idMovimentacaoTipo: props.item?.idMovimentacaoTipo.toString() || '',
      movimentacaoTipo: props.item?.movimentacaoTipo || '',
      outroTipo: props.item?.outroTipo || '',

      idEventoAcondicionamento:
        props.movementTypeGroup === MovementTypeGroup.Acondicionamento ? props.movementTypeGroupRefId : null,
      idEventoEquipamento:
        props.movementTypeGroup === MovementTypeGroup.Equipamento ? props.movementTypeGroupRefId : null,
      idEventoVeiculo:
        props.movementTypeGroup === MovementTypeGroup.Veiculo ? props.movementTypeGroupRefId : null,
      idEventoTratamento:
        props.movementTypeGroup === MovementTypeGroup.Tratamento ? props.movementTypeGroupRefId : null,
      idEventoDestinoFinal:
        props.movementTypeGroup === MovementTypeGroup.DestinoFinal ? props.movementTypeGroupRefId : null,
      idEventoServico:
        props.movementTypeGroup === MovementTypeGroup.Servico ? props.movementTypeGroupRefId : null,
      idEventoTolerancia: props.movementTypeGroupToleranceId,

      quantidade: props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida: props.item?.quantidadeIdUnidadeMedida?.toString() || null,

      receita: props.item?.receita || 0,
      despesa: props.item?.despesa || 0,
      imposto: props.item?.imposto || 0,

      observacao: props.item?.observacao || '',
    },
    validate: {
      idMovimentacaoTipo: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      outroTipo: (value: string | null, values: FormViewData) => {
        if (values.idMovimentacaoTipo === '0' && value?.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null, values: FormViewData) => {
        if ((value === null || value === undefined) && values.quantidadeIdUnidadeMedida) {
          return 'Campo obrigatório';
        }
        if (Number(value) <= 0) {
          return 'Valor deve ser maior que zero';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.quantidade || values.quantidade === 0) {
          return 'Campo obrigatório';
        }
        return null;
      },
      receita: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        // if (Number(value) < 0) {
        //   return `Valor deve ser no mínimo ${formatCurrency(0.0)}`;
        // }
        return null;
      },
      despesa: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        // if (Number(value) < 0) {
        //   return `Valor deve ser no mínimo ${formatCurrency(0.0)}`;
        // }
        return null;
      },
      imposto: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (Number(value) < 0) {
          return `Valor deve ser no mínimo ${formatCurrency(0.0)}`;
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idMovimentacaoTipo?.toString() || ''}
    |${props.origItem?.outroTipo || ''}
    |${props.origItem?.idEventoAcondicionamento || ''}
    |${props.origItem?.idEventoEquipamento || ''}
    |${props.origItem?.idEventoVeiculo || ''}
    |${props.origItem?.idEventoTratamento || ''}
    |${props.origItem?.idEventoDestinoFinal || ''}
    |${props.origItem?.idEventoServico || ''}
    |${props.origItem?.idEventoTolerancia || ''}
    |${props.origItem?.quantidade || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.receita || ''}
    |${props.origItem?.despesa || ''}
    |${props.origItem?.imposto || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idMovimentacaoTipo || ''}
    |${form.values.outroTipo || ''}
    |${form.values.idEventoAcondicionamento || ''}
    |${form.values.idEventoEquipamento || ''}
    |${form.values.idEventoVeiculo || ''}
    |${form.values.idEventoTratamento || ''}
    |${form.values.idEventoDestinoFinal || ''}
    |${form.values.idEventoServico || ''}
    |${form.values.idEventoTolerancia || ''}
    |${form.values.quantidade || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.receita || ''}
    |${form.values.despesa || ''}
    |${form.values.imposto || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }

    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          action: Action.Nothing,
          idMovimentacaoTipo: values.idMovimentacaoTipo,
        };
      }
    }

    const formItem: EventMeasurementMovementType = {
      ...values,
      ...buildFakeAuditObject(),
      idMovimentacaoTipo: Number(values.idMovimentacaoTipo),
      idEventoMedicao: props.idEventoMedicao || -1,
      idFornecedor: props.supplier.idFornecedor,
    };

    const movementTypeData = props.referenceData.movementTypeData.find(
      (x) => x.idMovimentacaoTipo.toString() === values.idMovimentacaoTipo.toString()
    );
    formItem.movimentacaoTipo = movementTypeData?.movimentacaoTipo || '';
    formItem.codigoMovimentacaoGrupo = movementTypeData?.codigoMovimentacaoGrupo || 'Geral';

    const movementBillingType = getMovementBillingType(props.codigoEventoReferencia, props.servico)!;

    formItem.codigoMovimentacaoFaturamentoTipo = movementBillingType.codigoMovimentacaoFaturamentoTipo!;
    formItem.movimentacaoFaturamentoTipo = movementBillingType.movimentacaoFaturamentoTipo;

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.outroTipo = values.idMovimentacaoTipo === '0' ? formItem.outroTipo?.trim() || null : null;
    formItem.receita = Number(formItem.receita);
    formItem.despesa = Number(formItem.despesa);
    formItem.imposto = Number(formItem.imposto);
    formItem.balanco = formItem.receita - formItem.despesa - formItem.imposto;
    formItem.observacao = formItem.observacao?.trim() || null;

    formItem.hash = calculateHash(formItem);

    props.callback(
      { ...formItem, action: values.action, id: values.id },
      props.movementTypeGroup,
      props.movementTypeGroupRefId,
      props.movementTypeGroupToleranceId,
      props.supplier.idFornecedor,
      'callback',
      true
    );
  };

  const balanco = form.values.receita - form.values.despesa - form.values.imposto;

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <form id="event-measurement-movement" onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Movimentação"
            text="Gerenciar uma movimentação."
          />
          <Space h="xs" />

          <Paper shadow="xs" p="md" withBorder>
            {props.supplier.fornecedorCNPJ ? (
              <PageViewProperty
                label="Fornecedor"
                text={
                  <ProfileCardLink
                    id={props.supplier.idFornecedor?.toString() || ''}
                    name={
                      props.supplier.fornecedorNomeFantasia || props.supplier.fornecedorRazaoSocial || '-'
                    }
                    nameSize="sm"
                    description={
                      props.supplier!.fornecedorNomeFantasia
                        ? props.supplier!.fornecedorRazaoSocial
                        : props.supplier!.fornecedorCNPJ
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink={false}
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={props.supplier.idFornecedor?.toString() || ''}
                    name={props.supplier.fornecedorNome || '-'}
                    nameSize="sm"
                    description={props.supplier!.fornecedorCPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink={false}
                  />
                }
                size="sm"
              />
            )}
          </Paper>
          <Space h="lg" />

          <Paper shadow="xs" p="md" withBorder>
            <Grid columns={4}>
              <Grid.Col span={1}>
                <Select
                  icon={<FileDots size={15} />}
                  label="Tipo"
                  placeholder="Selecione..."
                  data={props.referenceData.movementTypeData
                    .filter(
                      (x) =>
                        x.codigoMovimentacaoGrupo === props.movementTypeGroup ||
                        x.codigoMovimentacaoGrupo === null
                    )
                    .map((x) => {
                      return {
                        value: x.idMovimentacaoTipo.toString(),
                        label: x.movimentacaoTipo,
                        group: x.codigoMovimentacaoGrupo
                          ? MovementTypeGroupCode[x.codigoMovimentacaoGrupo]
                          : 'Geral',
                        disabled:
                          x.codigoMovimentacaoPadrao === MovementTypeStandard.Compra ||
                          x.codigoMovimentacaoPadrao === MovementTypeStandard.ComissaoFixa ||
                          x.codigoMovimentacaoPadrao === MovementTypeStandard.ComissaoPercentual,
                      };
                    })}
                  searchable
                  required
                  onSelect={() => {
                    if (
                      props.referenceData.movementTypeData.find(
                        (x) => x.idMovimentacaoTipo === Number(form.values.idMovimentacaoTipo)
                      )?.codigoMovimentacaoPadrao === MovementTypeStandard.Venda
                    ) {
                      form.setFieldValue('despesa', 0);
                    }
                  }}
                  {...form.getInputProps('idMovimentacaoTipo')}
                />
              </Grid.Col>
              {form.values.idMovimentacaoTipo === '0' && (
                <Grid.Col span={1}>
                  <TextInput
                    icon={<FileInfo size={15} />}
                    label="Outro"
                    placeholder="Outro tipo"
                    maxLength={50}
                    required
                    disabled={form.values.idMovimentacaoTipo !== '0'}
                    {...form.getInputProps('outroTipo')}
                  />
                </Grid.Col>
              )}
            </Grid>

            <Space h="xs" />
            <Grid columns={7}>
              <Grid.Col span={3}>
                <Input.Wrapper label="Quantidade" required>
                  <div />
                </Input.Wrapper>
                <Group spacing="xs">
                  <NumberInput
                    style={{ width: 125 }}
                    placeholder="Quantidade"
                    min={0}
                    precision={2}
                    step={0.5}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    {...form.getInputProps('quantidade')}
                  />
                  <Select
                    icon={<Ruler2 size={15} />}
                    placeholder="Selecione..."
                    data={props.referenceData.unitOfMeasures
                      .filter(
                        (x) =>
                          x.tipo === 'Massa' ||
                          x.tipo === 'Volume' ||
                          x.tipo === 'Tempo' ||
                          x.unidadeMedidaSigla === 'col' ||
                          x.unidadeMedidaSigla === 'un'
                      )
                      .map((x) => {
                        return {
                          value: x.idUnidadeMedida.toString(),
                          label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                          group: x.tipo,
                        };
                      })}
                    searchable
                    {...form.getInputProps('quantidadeIdUnidadeMedida')}
                  />
                </Group>
              </Grid.Col>
              <Grid.Col span={1}>
                <div>
                  <Input.Wrapper label="Receita" required>
                    <Group
                      style={{
                        display: 'inline-block',
                        marginLeft: 5,
                        marginTop: -2,
                        verticalAlign: 'bottom',
                      }}
                    >
                      <Tooltip
                        withArrow
                        transition="fade"
                        transitionDuration={200}
                        label={
                          <div>
                            <div>Valor a ser repassado ao cliente.</div>
                            <div>Use valores negativos para descontos.</div>
                          </div>
                        }
                      >
                        <div>
                          <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                        </div>
                      </Tooltip>
                    </Group>
                  </Input.Wrapper>
                  <NumberInput
                    icon={<CurrencyReal size={15} />}
                    placeholder="Receita"
                    precision={2}
                    hideControls
                    required
                    {...form.getInputProps('receita')}
                  />
                </div>
              </Grid.Col>
              <Grid.Col span={1}>
                <div>
                  <Input.Wrapper label="Despesa" required>
                    <Group
                      style={{
                        display: 'inline-block',
                        marginLeft: 5,
                        marginTop: -2,
                        verticalAlign: 'bottom',
                      }}
                    >
                      <Tooltip
                        withArrow
                        transition="fade"
                        transitionDuration={200}
                        label={
                          <div>
                            <div>Valor a ser repassado ao fornecedor.</div>
                            <div>Use valores negativos para descontos.</div>
                          </div>
                        }
                      >
                        <div>
                          <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                        </div>
                      </Tooltip>
                    </Group>
                  </Input.Wrapper>
                  <NumberInput
                    icon={<CurrencyReal size={15} />}
                    placeholder="Despesa"
                    min={
                      !!props.movementTypeGroup ||
                      props.codigoEventoReferencia === ReferenciaCodigo.ContratoComissao
                        ? undefined
                        : 0
                    }
                    precision={2}
                    hideControls
                    required
                    disabled={
                      props.referenceData.movementTypeData.find(
                        (x) => x.idMovimentacaoTipo === Number(form.values.idMovimentacaoTipo)
                      )?.codigoMovimentacaoPadrao === MovementTypeStandard.Venda
                    }
                    {...form.getInputProps('despesa')}
                  />
                </div>
              </Grid.Col>
              <Grid.Col span={1}>
                <NumberInput
                  icon={<CurrencyReal size={15} />}
                  label="Imposto"
                  placeholder="Imposto"
                  min={0}
                  precision={2}
                  hideControls
                  required
                  {...form.getInputProps('imposto')}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                {/* <NumberInput
                  icon={<CurrencyReal size={15} />}
                  style={{ borderColor: balanco > 0 ? 'green' : balanco === 0 ? 'orange' : 'red' }}
                  label="Balanço"
                  min={0}
                  precision={2}
                  hideControls
                  disabled
                  value={balanco}
                /> */}
                <PageViewProperty
                  label="Balanço"
                  text={
                    <div
                      style={{
                        paddingTop: 5,
                        fontSize: 'medium',
                        color: balanco > 0 ? 'green' : balanco === 0 ? 'orange' : 'red',
                      }}
                    >
                      {formatCurrency(balanco)}
                    </div>
                  }
                  size="sm"
                />
              </Grid.Col>
            </Grid>
            <Space h="xs" />
            <SimpleGrid cols={1}>
              <Textarea
                icon={<Notes size={15} />}
                label="Observação"
                placeholder="Observação sobre o movimentação"
                autosize
                maxLength={500}
                {...form.getInputProps('observacao')}
              />
            </SimpleGrid>
          </Paper>
        </form>

        {/* {props.item?.tolerancia && (
          <div>
            <Space h="xs" />
            <Alert icon={<InfoCircle size={16} />} title="Tolerância de Referência" color="yellow">
              <div>
                Preço: {formatCurrency(props.item.tolerancia.precoUnitario)} /{' '}
                {props.item.quantidadeUnidadeMedida}
              </div>
              <div>
                Tolerância: {props.item.tolerancia.quantidade} {props.item.quantidadeUnidadeMedida}
              </div>
            </Alert>
          </div>
        )} */}
      </Paper>

      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="event-measurement-movement">
          Salvar
        </Button>
      </Group>
    </div>
  );
}
