/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Paper, Space } from '@mantine/core';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { EventDestinationType, EventType } from '../../../../../../../models/core/events.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import EventDestinationItem from './EventDestinationItem';
import EventDestinationQuotation from './EventDestinationQuotation';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type DataViewProps = {
  data: EventDestinationType;
  event: EventType;
};

export default function EventDestinationView(props: DataViewProps) {
  const cotacaoPrimaria = props.event.referencia?.contrato.residuo?.planos
    ?.find((x) => x.primario)
    ?.cotacoes?.find((y) => y.primario)?.destinoFinal;

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Destino Final"
          text="Detalhes do destino final."
        />
        <Space h="xs" />
        <EventDestinationItem data={props.data} />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Fornecedor"
            text="Detalhes do fornecedor."
          />
          <Group>
            {cotacaoPrimaria && (
              <Badge variant="outline" size="md" color="orange">
                {`Preço (Cliente): ${formatCurrency(
                  calcularPreco(
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.margem,
                    cotacaoPrimaria.precoFinal,
                    cotacaoPrimaria.imposto || 0,
                    props.event.referencia!.contrato.residuo!.compra,
                    cotacaoPrimaria.receita
                  )[cotacaoPrimaria.receita ? 'preco' : 'novoPrecoComMargem']
                )} / ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.quantidadeUnidadeMedida}`}
              </Badge>
            )}
          </Group>
        </Group>
        <Space h="xs" />
        <EventDestinationQuotation
          data={props.data.cotacao}
          compra={props.event.referencia!.contrato.residuo!.compra}
        />
      </Paper>
    </div>
  );
}
