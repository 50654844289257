/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { DeviceFloppy, DeviceMobile, Hierarchy, Mail, Notes, Phone, User } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Button, Group, Paper, SimpleGrid, Space, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Action } from '../../../../../../../models/core/core.type';
import { EntityAddressContactType } from '../../../../../../../models/core/entities.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;
  nome: string;
  setor: string | null;
  email: string | null;
  telefone: string | null;
  telefoneRamal: string | null;
  celular: string | null;
  observacao: string | null;
};

type FormViewProps = {
  origItem: EntityAddressContactType | null;
  item: EntityAddressContactType | null;
  idEntidadeEndereco: number | undefined;
  callback(item: EntityAddressContactType | null, action: string, confirmed: boolean): void;
};

export default function AddressContactFormAddEdit(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),
      nome: props.item?.nome || '',
      setor: props.item?.setor || '',
      email: props.item?.email || '',
      telefone: props.item?.telefone || '',
      telefoneRamal: props.item?.telefoneRamal || '',
      celular: props.item?.celular || '',
      observacao: props.item?.observacao || '',
    },
    validate: {
      nome: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      email: (value: string | null) => {
        if (value?.trim() !== '' && !/^\S+@\S+$/.test(value?.trim() || '')) {
          return 'Formato inválido (Ex: nome@dominio.com)';
        }
        return null;
      },
      telefone: (value: string | null) =>
        value === '' || /^\(\d{2}\) \d{4}-\d{4}$/.test(value || '')
          ? null
          : 'Formato inválido (Ex: (00) 0000-0000)',
      celular: (value: string | null) =>
        value === '' || /^\(\d{2}\) \d{4,5}-\d{4}$/.test(value || '')
          ? null
          : 'Formato inválido (Ex: (00) 00000-0000)',
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.nome || ''}
    |${props.origItem?.setor || ''}
    |${props.origItem?.email || ''}
    |${props.origItem?.telefone || ''}
    |${props.origItem?.telefoneRamal || ''}
    |${props.origItem?.celular || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.nome || ''}
    |${form.values.setor || ''}
    |${form.values.email || ''}
    |${form.values.telefone || ''}
    |${form.values.telefoneRamal || ''}
    |${form.values.celular || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = { ...values, ...props.origItem, action: Action.Nothing };
      }
    }

    const formItem: EntityAddressContactType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idEntidadeEndereco: props.idEntidadeEndereco || -1,
    };

    formItem.setor = formItem.setor?.trim() || null;
    formItem.email = formItem.email?.trim() || null;
    formItem.telefone = formItem.telefone?.trim() || null;
    formItem.telefoneRamal = formItem.telefoneRamal?.trim() || null;
    formItem.celular = formItem.celular?.trim() || null;
    formItem.observacao = formItem.observacao?.trim() || null;

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={2}>
          <TextInput
            icon={<User size={15} />}
            label="Nome"
            placeholder="Digite o nome"
            maxLength={100}
            required
            {...form.getInputProps('nome')}
          />
          <TextInput
            icon={<Hierarchy size={15} />}
            label="Setor"
            placeholder="Digite o setor"
            maxLength={50}
            {...form.getInputProps('setor')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <SimpleGrid cols={3}>
          <NumberFormat
            icon={<Phone size={15} />}
            label="Telefone"
            placeholder="(__) ____-____"
            mask="_"
            format="(##) ####-####"
            customInput={TextInput}
            {...form.getInputProps('telefone')}
          />
          <TextInput
            icon={<Phone size={15} />}
            label="Ramal"
            placeholder="0000"
            type="number"
            maxLength={50}
            {...form.getInputProps('telefoneRamal')}
          />
          <NumberFormat
            icon={<DeviceMobile size={15} />}
            label="Celular"
            placeholder="(__) _____-____"
            mask="_"
            format="(##) #####-####"
            customInput={TextInput}
            {...form.getInputProps('celular')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <SimpleGrid cols={1}>
          <TextInput
            icon={<Mail size={15} />}
            label="E-mail"
            placeholder="Digite o e-mail"
            type="email"
            maxLength={100}
            {...form.getInputProps('email')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o contato"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
