import {
  CompanyClientFinancialPotentialResponseType,
  CompanyClientQuantityPotentialResponseType,
  CompanyEntityFinancialResponseType,
  CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
  ContractEntityFinancialResponseType,
  ContractGeneralTopFinanceResponseType,
  ContractGeneralTopResidueResponseType,
  ContractGeneralTopServiceResponseType,
  ContractSingleEquipmentType,
  ContractSingleEstimateType,
  ContractSingleMeasurementType,
  ContractSinglePackagingType,
  ContractSingleServiceSupplierType,
  EntityGeneralResponseType,
  EntityGeneralTopCommissionResponseType,
  EntityGeneralTopFinanceResponseType,
  EventGeneralCorrectionResponseType,
  ReportItem,
  ReportRequestType,
} from '../../models/core/report.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { call } from '../core.service';

const reportsEndpoint = '/v1/relatorio';

export default class ReportService {
  static contratoGeralTopFinanceiro = async (
    searchProps: ReportRequestType
  ): Promise<ContractGeneralTopFinanceResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoGeralTopFinanceiro },
    };
    return (await call(request))?.data?.data;
  };

  static contratoGeralTopResiduos = async (
    searchProps: ReportRequestType
  ): Promise<ContractGeneralTopResidueResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoGeralTopResiduos },
    };

    return (await call(request))?.data?.data;
  };

  static contratoGeralTopServicos = async (
    searchProps: ReportRequestType
  ): Promise<ContractGeneralTopServiceResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoGeralTopServicos },
    };

    return (await call(request))?.data?.data;
  };

  static contratoIndividualEstimativas = async (
    searchProps: ReportRequestType
  ): Promise<ContractSingleEstimateType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoIndividualEstimativas },
    };

    return (await call(request))?.data?.data;
  };

  static contratoIndividualAcondicionamentos = async (
    searchProps: ReportRequestType
  ): Promise<ContractSinglePackagingType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoIndividualAcondicionamentos },
    };

    return (await call(request))?.data?.data;
  };

  static contratoIndividualEquipamentos = async (
    searchProps: ReportRequestType
  ): Promise<ContractSingleEquipmentType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoIndividualEquipamentos },
    };

    return (await call(request))?.data?.data;
  };

  static contratoIndividualFornecedores = async (
    searchProps: ReportRequestType
  ): Promise<ContractSingleServiceSupplierType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoIndividualFornecedores },
    };

    return (await call(request))?.data?.data;
  };

  static contratoIndividualMedicoes = async (
    searchProps: ReportRequestType
  ): Promise<ContractSingleMeasurementType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoIndividualMedicoes },
    };

    return (await call(request))?.data?.data;
  };

  static entidadeGeralTopFinanceiro = async (
    searchProps: ReportRequestType
  ): Promise<EntityGeneralTopFinanceResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EntidadeGeralTopFinanceiro },
    };
    return (await call(request))?.data?.data;
  };

  static entidadeGeralTopComissoes = async (
    searchProps: ReportRequestType
  ): Promise<EntityGeneralTopCommissionResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EntidadeGeralTopComissoes },
    };
    return (await call(request))?.data?.data;
  };

  static entidadeGeral = async (): Promise<EntityGeneralResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { item: ReportItem.EntidadeGeral },
    };
    return (await call(request))?.data?.data;
  };

  static eventoGeralCorrecoes = async (
    searchProps: ReportRequestType
  ): Promise<EventGeneralCorrectionResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EventoGeralCorrecoes },
    };
    return (await call(request))?.data?.data;
  };

  static empresaClientePotencialFinanceiro = async (
    searchProps: ReportRequestType
  ): Promise<CompanyClientFinancialPotentialResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EmpresaClientePotencialFinanceiro },
    };
    return (await call(request))?.data?.data;
  };

  static empresaClientePotencialQuantitativo = async (
    searchProps: ReportRequestType
  ): Promise<CompanyClientQuantityPotentialResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EmpresaClientePotencialQuantitativo },
    };
    return (await call(request))?.data?.data;
  };

  static empresaEntidadeFinanceiro = async (
    searchProps: ReportRequestType
  ): Promise<CompanyEntityFinancialResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EmpresaEntidadeFinanceiro },
    };
    return (await call(request))?.data?.data;
  };

  static empresaEntidadeAconEquiVeiQuantitativo = async (
    searchProps: ReportRequestType
  ): Promise<CompanyEntityPackagingEquipmentVehicleQuantityResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.EmpresaEntidadeAconEquiVeiQuantitativo },
    };
    return (await call(request))?.data?.data;
  };

  static contratoEntidadeMovimentacoes = async (
    searchProps: ReportRequestType
  ): Promise<ContractEntityFinancialResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: reportsEndpoint,
      params: { ...searchProps, item: ReportItem.ContratoEntidadeMovimentacoes },
    };
    return (await call(request))?.data?.data;
  };
}
