/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, Space } from '@mantine/core';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { ResidueType } from '../../../../../models/core/residues.type';
import { Feature } from '../../../../../utils/constants.utils';

type DataViewProps = {
  data: ResidueType;
};

export default function DataView(props: DataViewProps) {
  return (
    <>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Residue.color} label="Geral" text="" />
        <Space h="xs" />
        <PageViewProperty label="Resíduo" text={props.data.residuo} size="md" />
        <Divider my="sm" variant="dotted" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Unidade Medida"
              text={
                props.data.idUnidadeMedida
                  ? `${props.data.unidadeMedida} (${props.data.unidadeMedidaSigla})`
                  : '-'
              }
              size="md"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Perigoso" text={props.data.perigoso ? 'Sim' : 'Não'} size="md" />
          </Grid.Col>

          <Grid.Col span={3}>
            <PageViewProperty
              label="Código IBAMA"
              text={props.data.codigoIBAMA ? `${props.data.codigoIBAMA} | ${props.data.residuoIBAMA}` : '-'}
              size="md"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <PageViewProperty label="Descrição" text={props.data.descricao || '-'} size="md" />
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
      />
    </>
  );
}
