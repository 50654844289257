/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Calculator, Copy, Edit, Eye, Printer, Trash } from 'tabler-icons-react';
import { ProposalStatusEnum } from '../../../../../business/proposals/status';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { Permission } from '../../../../../models/core/departments.type';
import { ProposalSearchResponseType } from '../../../../../models/core/proposals.type';
import theme from '../../../../../theme';
import { validate } from '../../../../../utils/permission.utils';

type DataViewActionProps = {
  item: ProposalSearchResponseType;
  confirmActionResult(item: ProposalSearchResponseType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const showModal = (item: ProposalSearchResponseType, action: string) => {
    modals.openConfirmModal({
      title: `Gostaria de ${action} essa proposta?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={3}>
            <PageViewProperty label="#" text={item.idProposta.toString()} size="sm" />
            <PageViewProperty label="CNPJ/CPF" text={item.clienteCNPJ || item.clienteCPF || '-'} size="sm" />
            <PageViewProperty
              label="Nome Fantasia/Nome"
              text={item.clienteNomeFantasia || item.clienteNome || '-'}
              size="sm"
            />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });
  };

  const validateMyOwnProposal = () => {
    if (
      validate(Permission.ProposalOwn, currentUser.permissoes) &&
      props.item.idCriadoPor !== currentUser.idUsuario
    ) {
      return false;
    }
    return true;
  };

  return (
    <Group>
      {validate(Permission.ProposalView, currentUser.permissoes) && validateMyOwnProposal() && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'visualizar', true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {props.item.codigoPropostaStatus === ProposalStatusEnum.EmEdicao &&
        validate(Permission.ProposalEdit, currentUser.permissoes) &&
        validateMyOwnProposal() && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.accent?.[6] }}
              variant="outline"
              onClick={() => {
                props.confirmActionResult(props.item, 'editar', true);
              }}
            >
              <Edit size={15} color={theme?.colors?.accent?.[6]} />
            </ActionIcon>
          </Tooltip>
        )}
      {validate(Permission.ProposalAdd, currentUser.permissoes) && validateMyOwnProposal() && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Duplicar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.gray?.[6] }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'duplicar');
            }}
          >
            <Copy size={15} color={theme?.colors?.gray?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {props.item.codigoPropostaStatus === ProposalStatusEnum.Finalizado &&
        validate(Permission.ProposalAdd, currentUser.permissoes) &&
        validateMyOwnProposal() && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Reajustar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: '##15AABF' }}
              variant="outline"
              onClick={() => {
                showModal(props.item, 'reajustar');
              }}
            >
              <Calculator size={15} color="#15AABF" />
            </ActionIcon>
          </Tooltip>
        )}
      {validate(Permission.ProposalPrint, currentUser.permissoes) && validateMyOwnProposal() && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Imprimir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.dark?.[6] }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'imprimir');
            }}
          >
            <Printer size={15} color={theme?.colors?.dark?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {validate(Permission.ProposalDelete, currentUser.permissoes) && validateMyOwnProposal() && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'red' }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'excluir');
            }}
          >
            <Trash size={15} color="red" />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
}
