/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { DeviceFloppy, Refresh } from 'tabler-icons-react';
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { EntityAddressType } from '../../../models/core/entities.type';
import entitiesService from '../../../services/core/entities.service';

type FormViewProps = {
  referenceData: {
    idEntidade: number;
  };
  idsToBeDisabled: number[];
  callback(item: EntityAddressType | null, confirmed: boolean): void;
};

export default function EntityAddressListModal(props: FormViewProps) {
  const [data, setData] = useState<EntityAddressType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<EntityAddressType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const filter = async (idEntidade: number) => {
    try {
      setLoading(true);
      const result = await entitiesService.listAddresses({
        idEntidade,
      });
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Entidade - Endereços`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível listar os endereços dessa entidade.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EntityAddressType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: EntityAddressType) => ({
      disabled: props.idsToBeDisabled.includes(record.idEntidade),
      name: record.idEntidade.toString(),
    }),
  };

  useEffect(() => {
    filter(props.referenceData.idEntidade);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Paper shadow="xs" p="md" withBorder>
        <Table
          showSorterTooltip={false}
          size="small"
          dataSource={data}
          columns={[
            {
              title: 'Tipo',
              key: 'enderecoTipo',
              dataIndex: 'enderecoTipo',
              sorter: (a: EntityAddressType, b: EntityAddressType) =>
                a.enderecoTipo.localeCompare(b.enderecoTipo),
            },
            {
              title: 'Estado',
              key: 'estado',
              dataIndex: 'estado',
              sorter: (a: EntityAddressType, b: EntityAddressType) => a.estado.localeCompare(b.estado),
            },
            {
              title: 'Cidade',
              key: 'cidade',
              dataIndex: 'cidade',
              sorter: (a: EntityAddressType, b: EntityAddressType) => a.cidade.localeCompare(b.cidade),
            },
            {
              title: 'Bairro',
              key: 'bairro',
              dataIndex: 'bairro',
              sorter: (a: EntityAddressType, b: EntityAddressType) => a.bairro.localeCompare(b.bairro),
            },
            {
              title: 'Logradouro',
              key: 'logradouro',
              dataIndex: 'logradouro',
              sorter: (a: EntityAddressType, b: EntityAddressType) =>
                a.logradouro.localeCompare(b.logradouro),
            },
            {
              title: 'Número',
              key: 'numero',
              dataIndex: 'numero',
              sorter: (a: EntityAddressType, b: EntityAddressType) => a.numero.localeCompare(b.numero),
            },
            {
              title: 'Complemento',
              key: 'complemento',
              sorter: (a: EntityAddressType, b: EntityAddressType) => {
                return (a.complemento || '').localeCompare(b.complemento || '');
              },
              render: (row: EntityAddressType) => <div>{row?.complemento || '-'}</div>,
            },
            {
              title: 'Referência',
              key: 'referencia',
              sorter: (a: EntityAddressType, b: EntityAddressType) => {
                return (a.referencia || '').localeCompare(b.referencia || '');
              },
              render: (row: EntityAddressType) => <div>{row?.referencia || '-'}</div>,
            },
            {
              title: 'CEP',
              key: 'cep',
              sorter: (a: EntityAddressType, b: EntityAddressType) => {
                return (a.cep || '').localeCompare(b.cep || '');
              },
              render: (row: EntityAddressType) => <div>{row?.cep || '-'}</div>,
            },
          ]}
          rowSelection={{ type: 'radio', ...rowSelection }}
          rowKey={(item: EntityAddressType) => item.idEntidadeEndereco || 0}
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button
          color="accent"
          leftIcon={<Refresh size={18} />}
          onClick={() => {
            filter(props.referenceData.idEntidade);
          }}
        >
          Atualizar
        </Button>
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
