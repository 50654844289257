/* eslint-disable react/destructuring-assignment */
import { Trash } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { EntityServiceType } from '../../../../../../../models/core/entities.type';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';

type DataViewActionProps = {
  item: EntityServiceType;
  confirmActionResult(items: EntityServiceType[] | null, action: string, confirmed: boolean): void;
};

export default function ServiceFormViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: EntityServiceType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse serviço?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={2}>
            <PageViewProperty label="Serviço" text={item.servico} size="sm" />
            <PageViewProperty label="Descrição" text={item.servicoDescricao || '-'} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult([item], action, false);
      },
      onConfirm: () => {
        props.confirmActionResult([item], action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
