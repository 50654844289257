/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Group, Modal, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Action } from '../../../../../../../models/core/core.type';
import { EntityAddressContactType, EntityAddressType } from '../../../../../../../models/core/entities.type';
import AddressContactFormAddEdit from './AddressContactFormAddEdit';
import AddressContactFormViewActions from './AddressContactFormViewActions';
import { newGuid } from '../../../../../../../utils/helper.utils';
import { Feature } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';

type FormViewProps = {
  entityAddress: (EntityAddressType & { origContacts?: EntityAddressContactType[] }) | null;
};

const AddressContactFormView = forwardRef((props: FormViewProps, ref) => {
  const [data, setData] = useState<EntityAddressContactType[]>(
    props.entityAddress?.contatos.map((x) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idEntidadeEnderecoContato?.toString() || x.idTemporaria || newGuid(),
      };
    }) || []
  );

  const [contactItem, setContactItem] = useState<EntityAddressContactType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: EntityAddressContactType, b: EntityAddressContactType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Setor',
      key: 'setor',
      sorter: (a: EntityAddressContactType, b: EntityAddressContactType) => {
        return (a.setor || '').localeCompare(b.setor || '');
      },
      render: (row: EntityAddressContactType) => <div>{row?.setor || '-'}</div>,
    },
    {
      title: 'E-mail',
      key: 'email',
      sorter: (a: EntityAddressContactType, b: EntityAddressContactType) => {
        return (a.email || '').localeCompare(b.email || '');
      },
      render: (row: EntityAddressContactType) => <div>{row?.email || '-'}</div>,
    },
    {
      title: 'Telefone/Celular',
      key: 'telefoneCelular',
      sorter: (a: EntityAddressContactType, b: EntityAddressContactType) => {
        return (a.telefone || a.celular || '').localeCompare(b.telefone || b.celular || '');
      },
      render: (row: EntityAddressContactType) => <div>{row?.telefone || row?.celular || '-'}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityAddressContactType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <AddressContactFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EntityAddressContactType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeEnderecoContato) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setContactItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityAddressContactType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Endereço/Contato - ${contactItem ? 'Editar' : 'Adicionar'}`}
        size="xl"
      >
        <AddressContactFormAddEdit
          origItem={
            contactItem
              ? props.entityAddress?.origContacts?.find(
                  (x) =>
                    x.idEntidadeEnderecoContato === contactItem.idEntidadeEnderecoContato &&
                    x.idEntidadeEndereco !== -1
                ) || null
              : null
          }
          item={contactItem}
          idEntidadeEndereco={props.entityAddress?.idEntidadeEndereco}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Contatos" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityAddressContactType) => item.id || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default AddressContactFormView;
