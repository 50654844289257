/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Eye, Trash, X } from 'tabler-icons-react';
import { ContractStatusEnum } from '../../../../../business/contracts/status';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { ContractSearchResponseType } from '../../../../../models/core/contracts.type';
import theme from '../../../../../theme';
import { validate } from '../../../../../utils/permission.utils';
import { Permission } from '../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../hooks/useCurrentUser';

type DataViewActionProps = {
  item: ContractSearchResponseType;
  confirmActionResult(item: ContractSearchResponseType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const showModal = (item: ContractSearchResponseType, action: string) => {
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse contrato?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={3}>
            <PageViewProperty label="#" text={item.idContrato.toString()} size="sm" />
            <PageViewProperty label="CNPJ/CPF" text={item.clienteCNPJ || item.clienteCPF || '-'} size="sm" />
            <PageViewProperty
              label="Nome Fantasia/Nome"
              text={item.clienteNomeFantasia || item.clienteNome || '-'}
              size="sm"
            />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });
  };

  return (
    <Group>
      {validate(Permission.ContractView, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'visualizar', true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {props.item.codigoContratoStatus !== ContractStatusEnum.Cancelado &&
        props.item.codigoContratoStatus !== ContractStatusEnum.EmSubstituicao &&
        props.item.codigoContratoStatus !== ContractStatusEnum.Substituido &&
        props.item.codigoContratoStatus !== ContractStatusEnum.Finalizado &&
        validate(Permission.ContractCancel, currentUser.permissoes) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Cancelar">
            <ActionIcon
              size="sm"
              radius="sm"
              color="orange"
              style={{ borderColor: 'orange' }}
              variant="outline"
              onClick={() => {
                showModal(props.item, 'cancelar');
              }}
            >
              <X size={15} color="orange" />
            </ActionIcon>
          </Tooltip>
        )}
      {props.item.codigoContratoStatus === ContractStatusEnum.Cancelado &&
        validate(Permission.ContractDelete, currentUser.permissoes) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                showModal(props.item, 'excluir');
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        )}
    </Group>
  );
}
