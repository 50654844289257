/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Click, DeviceFloppy, Package } from 'tabler-icons-react';
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Input,
  Modal,
  Paper,
  SegmentedControl,
  Space,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { EntityPackagingType } from '../../../../../../../models/core/entities.type';
import { PackagingType } from '../../../../../../../models/core/packaging.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';
import PackagingFormSearch from './PackagingFormSearch';
import theme from '../../../../../../../theme';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewData = {
  action: Action;
  id: string;
  acondicionamento: string;
  proprio: string;
};

type FormViewProps = {
  items: EntityPackagingType[] | null;
  origItem: EntityPackagingType | null;
  item: EntityPackagingType | null;
  idEntidade: number | undefined;
  callback(item: EntityPackagingType | null, action: string, confirmed: boolean): void;
};

export default function PackagingFormAddEdit(props: FormViewProps) {
  const [opened, setOpened] = useState(false);
  const [packagingItem, setPackagingItem] = useState<PackagingType | null>(
    props.item ? { ...props.item, descricao: props.item.residuoAcondicionamentoDescricao } : null
  );

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),
      acondicionamento: props.item?.residuoAcondicionamento || '',
      proprio: props.item?.proprio.toString() || true.toString(),
    },
    validate: {
      acondicionamento: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoAcondicionamento || ''}|${props.origItem?.proprio || ''}`;
    const formData = `${packagingItem?.idResiduoAcondicionamento || ''}|${form.values.proprio || ''}`;
    return origData !== formData;
  };

  const confirmActionResult = (item: PackagingType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    switch (action) {
      case 'procurar':
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        setPackagingItem(item);
        form.values.acondicionamento = item?.residuoAcondicionamento || '';
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          proprio: values.proprio,
          action: Action.Nothing,
        };
      }
    }

    const formItem: EntityPackagingType = {
      ...values,
      ...buildFakeAuditObject(),
      idResiduoAcondicionamento: packagingItem!.idResiduoAcondicionamento,
      residuoAcondicionamento: packagingItem!.residuoAcondicionamento,
      residuoAcondicionamentoDescricao: packagingItem!.descricao,
      proprio: values.proprio === true.toString(),
      idEntidade: props.idEntidade || -1,
    };

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Modal
          opened={opened}
          closeOnClickOutside={false}
          closeOnEscape={false}
          onClose={() => setOpened(false)}
          title="Acondicionamentos - Procurar"
          size="60%"
        >
          <PackagingFormSearch
            itemsIdList={
              props.items
                ?.filter((x) => x.action !== Action.Delete)
                .map((y) => y.idResiduoAcondicionamento) || []
            }
            idEntidade={props.idEntidade}
            callback={confirmActionResult}
          />
        </Modal>

        <Grid columns={3}>
          <Grid.Col span={2}>
            <TextInput
              icon={<Package size={15} />}
              rightSection={
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="primary"
                  style={{ borderColor: theme?.colors?.primary?.[6] }}
                  variant="outline"
                  onClick={() => {
                    setOpened(true);
                  }}
                >
                  <Click size={15} color={theme?.colors?.primary?.[6]} />
                </ActionIcon>
              }
              onClick={() => {
                setOpened(true);
              }}
              label="Acondicionamento"
              placeholder="Selecione..."
              {...form.getInputProps('acondicionamento')}
              readOnly
              required
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <div>
              <Input.Wrapper label="Tipo" required>
                <div />
              </Input.Wrapper>
              <SegmentedControl
                color="primary"
                data={[
                  { label: 'Próprio', value: true.toString() },
                  { label: 'Terceirizado', value: false.toString() },
                ]}
                {...form.getInputProps('proprio')}
              />
            </div>
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
