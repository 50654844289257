/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Button, Group, LoadingOverlay, Paper, Space, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { DeviceFloppy, ExternalLink, Refresh } from 'tabler-icons-react';
import { CompanyType, ProposalStatusType } from '../../../models/core/cache.type';
import { ProposalSearchResponseType } from '../../../models/core/proposals.type';
import proposalsService from '../../../services/core/proposals.service';
import theme from '../../../theme';
import { SessionStorageKey } from '../../../utils/constants.utils';
import { formatDateToString } from '../../../utils/formatter.utils';
import ProfileCardLink from '../ProfileCardLink/ProfileCardLink';
import { FilterData, ProposalSearchModalFilter } from './ProposalSearchModalFilter';

type FormViewProps = {
  referenceData: {
    companyData: CompanyType[];
    proposalStatusData: ProposalStatusType[];
  };
  idsToBeDisabled: number[];
  callback(item: ProposalSearchResponseType | null, confirmed: boolean): void;
};

export default function ProposalSearchModal(props: FormViewProps) {
  const [data, setData] = useState<ProposalSearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<ProposalSearchResponseType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.ProposalSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await proposalsService.search({
        ...inputFilterData,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.ProposalSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Proposta - Pesquisar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar propostas.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProposalSearchResponseType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ProposalSearchResponseType) => ({
      disabled: props.idsToBeDisabled.includes(record.idProposta),
      name: record.idProposta.toString(),
    }),
  };

  const refresh = () => {
    filter({
      idEmpresa: null,
      listaStatus: [],
    });
  };

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.ProposalSearch) || '[]'
    ) as ProposalSearchResponseType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      refresh();
    }
  }, []);

  return (
    <div>
      <ProposalSearchModalFilter
        companyData={props.referenceData.companyData}
        proposalStatusData={props.referenceData.proposalStatusData}
        filter={filter}
        clear={clear}
        loading={loading}
      />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: '#',
                key: 'idProposta',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) => {
                  const aValue = a.idProposta;
                  const bValue = b.idProposta;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: ProposalSearchResponseType) => row.idProposta,
              },
              {
                title: 'Empresa',
                key: 'empresa',
                dataIndex: 'empresa',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
                  a.empresa.localeCompare(b.empresa),
              },
              {
                title: 'Cliente',
                key: 'idCliente',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
                  (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
                    b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
                  ),
                render: (row: ProposalSearchResponseType) => {
                  if (row.clienteCNPJ) {
                    return (
                      <ProfileCardLink
                        id={row.idCliente.toString()}
                        name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                        nameSize="sm"
                        description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                        descriptionSize="xs"
                        linkPrefix="entities"
                        showLink={false}
                      />
                    );
                  }
                  return (
                    <ProfileCardLink
                      id={row.idCliente.toString()}
                      name={row.clienteNome || '-'}
                      nameSize="sm"
                      description={row.clienteCPF}
                      descriptionSize="xs"
                      linkPrefix="entities"
                      showLink={false}
                    />
                  );
                },
              },
              {
                title: 'Status',
                key: 'status',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
                  a.propostaStatus.localeCompare(b.propostaStatus),
                render: (row: ProposalSearchResponseType) => (
                  <Badge variant="outline">{row.propostaStatus}</Badge>
                ),
              },
              {
                title: 'Validade',
                key: 'validade',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
                  new Date(a.validade).valueOf() - new Date(b.validade).valueOf(),
                render: (row: ProposalSearchResponseType) => formatDateToString(row.validade),
              },
              {
                title: 'Data Inicial',
                key: 'dataInicial',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
                  new Date(a.dataInicial).valueOf() - new Date(b.dataInicial).valueOf(),
                render: (row: ProposalSearchResponseType) => formatDateToString(row.dataInicial),
              },
              {
                title: 'Data Final',
                key: 'dataFinal',
                sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
                  new Date(a.dataFinal).valueOf() - new Date(b.dataFinal).valueOf(),
                render: (row: ProposalSearchResponseType) => formatDateToString(row.dataFinal),
              },
              {
                title: 'Ações',
                width: '100px',
                render: (row: ProposalSearchResponseType) => (
                  <Tooltip withArrow transition="fade" transitionDuration={200} label="Abrir">
                    <ActionIcon
                      size="sm"
                      color="primary"
                      style={{ borderColor: theme?.colors?.primary?.[6] }}
                      variant="outline"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.setAttribute('href', `/proposals/${row.idProposta}`);
                        link.setAttribute('target', '_blank');
                        link.click();
                      }}
                    >
                      <ExternalLink size={15} color={theme?.colors?.primary?.[6]} />
                    </ActionIcon>
                  </Tooltip>
                ),
              },
            ]}
            rowSelection={{ type: 'radio', ...rowSelection }}
            rowKey={(item: ProposalSearchResponseType) => item.idProposta}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button color="accent" leftIcon={<Refresh size={18} />} onClick={refresh}>
            Atualizar
          </Button>
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
