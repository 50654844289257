/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Alert,
  Badge,
  Divider,
  Grid,
  Group,
  Paper,
  Select,
  Space,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { Tabs } from 'antd';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AlertTriangle, Recycle, Target, Truck, ZoomMoney } from 'tabler-icons-react';
import { ReferenciaCodigo, Relacao, Status, TipoCodigo } from '../../../../../business/events/general';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useEventGeneralContext } from '../../../../../contexts/core/events/EventGeneral.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../models/core/cache.type';
import { EventType } from '../../../../../models/core/events.type';
import {
  ProposalResiduePlanDestinationType,
  ProposalResiduePlanEquipmentType,
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanQuotationDestinationType,
  ProposalResiduePlanQuotationEquipmentType,
  ProposalResiduePlanQuotationPackagingType,
  ProposalResiduePlanQuotationTreatmentType,
  ProposalResiduePlanQuotationVehicleType,
  ProposalResiduePlanTreatmentType,
  ProposalResiduePlanVehicleType,
} from '../../../../../models/core/proposals.type';
import { Feature, SessionStorageKey } from '../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../utils/helper.utils';
import { QuotationSelectItem } from './PlanQuotationSelectItem/PlanQuotationSelectItem';
import EventDestinationFormView from './ReferenceFormView/Destination/EventDestinationFormView';
import EventEquipmentFormView from './ReferenceFormView/Equipment/EventEquipmentFormView';
import EventPackagingFormView from './ReferenceFormView/Packaging/EventPackagingFormView';
import EventServiceFormView from './ReferenceFormView/Service/EventServiceFormView';
import EventTreatmentFormView from './ReferenceFormView/Treatment/EventTreatmentFormView';
import EventVehicleFormView from './ReferenceFormView/Vehicle/EventVehicleFormView';

type EventFormViewProps = {
  referenceData: { entityTypeData: EntityTypeType[]; unitOfMeasures: UnitOfMeasureType[] };
  event: EventType | null;
};

const ReferenceFormView = forwardRef((props: EventFormViewProps, ref) => {
  const { eventGeneralData } = useEventGeneralContext();
  const refPackagingForm = useRef<any>();
  const refEquipmentForm = useRef<any>();
  const refVehicleForm = useRef<any>();
  const refTreatmentForm = useRef<any>();
  const refDestinationForm = useRef<any>();
  const refServiceForm = useRef<any>();

  const [quotationId, setQuotationId] = useState<number | null>(null);

  const tempEvento = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEvent) || 'null');
  const evento =
    tempEvento && !eventGeneralData.idEventoReferencia2
      ? {
          ...tempEvento,
          idEvento: undefined,
          codigoEventoReferencia:
            tempEvento.codigoEventoTipo === TipoCodigo.Lembrete ? null : tempEvento.codigoEventoReferencia,
          idEventoReferencia1:
            tempEvento.codigoEventoTipo === TipoCodigo.Lembrete ? null : tempEvento.idEventoReferencia1,
          idEventoReferencia2: '-999', // set '-1' to import as 'Other'
          idEventoReferencia3: null,
          automatico: false,
        }
      : props.event;

  const statusSoftDisabled = evento
    ? evento.codigoEventoStatus === Status.PendenteFaturamento ||
      evento.codigoEventoStatus === Status.PendenteMedicao ||
      (evento.idEventoVeiculoCompartilhado && evento.resumoJSON.veiculoCompartilhado)
    : false;

  const clienteLocalData =
    eventGeneralData.propostaResiduoData || eventGeneralData.propostaServicoData || null;

  const documentoValido = () => {
    return eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.EntidadeDocumento;
  };

  const comissaoValida = () => {
    return eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoComissao;
  };

  const residuoValido = () => {
    return (
      (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
        eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
        eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) &&
      eventGeneralData.propostaResiduoData &&
      (eventGeneralData.idEventoReferencia2 || eventGeneralData.eventoReferenciaPersonalizado)
    );
  };

  const servicoValido = () => {
    return (
      eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoServico &&
      eventGeneralData.propostaServicoData
    );
  };

  const clienteLocalValido = () => {
    return (
      eventGeneralData.codigoEventoReferencia &&
      ((eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoServico &&
        eventGeneralData.propostaServicoData) ||
        ((eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
          eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
          eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) &&
          eventGeneralData.propostaResiduoData)) &&
      clienteLocalData
    );
  };
  const propostaContato = clienteLocalValido() ? evento?.referencia?.contrato.proposta || null : null;

  const encontrarPlanoCotacao = () => {
    const residuo = eventGeneralData.propostaResiduoData;
    if (!residuo) {
      return {};
    }

    let planoInicial;
    let planoCotacaoInicial;

    if (!eventGeneralData.eventoReferenciaPersonalizado) {
      if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento) {
        for (const plano of residuo.planos) {
          if (
            plano.acondicionamentos.findIndex(
              (x) => x.idPropostaResiduoPlanoAcondicionamento === eventGeneralData.idEventoReferencia2
            ) > -1
          ) {
            planoInicial = plano;
            for (const cotacao of planoInicial.cotacoes) {
              if (
                cotacao.acondicionamentos.findIndex(
                  (x) => x.idPropostaResiduoPlanoAcondicionamento === eventGeneralData.idEventoReferencia2
                ) > -1
              ) {
                planoCotacaoInicial = cotacao;
                break;
              }
            }
          }
          if (planoInicial && planoCotacaoInicial) {
            break;
          }
        }
      } else if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento) {
        for (const plano of residuo.planos) {
          if (
            plano.equipamentos.findIndex(
              (x) => x.idPropostaResiduoPlanoEquipamento === eventGeneralData.idEventoReferencia2
            ) > -1
          ) {
            planoInicial = plano;
            for (const cotacao of planoInicial.cotacoes) {
              if (
                cotacao.equipamentos.findIndex(
                  (x) => x.idPropostaResiduoPlanoEquipamento === eventGeneralData.idEventoReferencia2
                ) > -1
              ) {
                planoCotacaoInicial = cotacao;
                break;
              }
            }
          }
          if (planoInicial && planoCotacaoInicial) {
            break;
          }
        }
      } else if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) {
        planoInicial = residuo.planos.find(
          (x) => x.idPropostaResiduoPlano === eventGeneralData.idEventoReferencia2
        );
        planoCotacaoInicial = planoInicial?.cotacoes.find(
          (y) => y.idPropostaResiduoPlanoCotacao === quotationId
        );
      }
    }

    return { planoInicial, planoCotacaoInicial };
  };

  const montarResiduoAcondicionamento = (
    refItem: ProposalResiduePlanPackagingType | undefined,
    refCotacao: ProposalResiduePlanQuotationPackagingType | undefined
  ) => {
    let finalItem = evento?.acondicionamento || null;

    if (!finalItem && refItem && refCotacao) {
      finalItem = {
        ...buildFakeAuditObject(),
        ...refItem,
        cotacao: { ...refCotacao },
      };
    }

    if (finalItem) {
      finalItem.cotacao.idPropostaResiduoPlanoCotacaoAcondicionamento =
        finalItem.cotacao.idPropostaResiduoPlanoCotacaoAcondicionamento ??
        refCotacao?.idPropostaResiduoPlanoCotacaoAcondicionamento;
    }

    const { planoInicial } = encontrarPlanoCotacao();

    return (
      <EventPackagingFormView
        key={`packaging-${
          quotationId?.toString() || eventGeneralData.idEventoReferencia2?.toString() || '-1'
        }`}
        ref={refPackagingForm}
        origItem={props.event?.acondicionamento || null}
        item={finalItem}
        referenceData={props.referenceData}
        quotations={planoInicial?.cotacoes.map((x) => x.acondicionamentos).flat() || []}
        disabled={evento?.automatico || statusSoftDisabled || false}
      />
    );
  };

  const montarResiduoEquipamento = (
    refItem: ProposalResiduePlanEquipmentType | undefined,
    refCotacao: ProposalResiduePlanQuotationEquipmentType | undefined
  ) => {
    let finalItem = evento?.equipamento || null;

    if (!finalItem && refItem && refCotacao) {
      finalItem = {
        ...buildFakeAuditObject(),
        ...refItem,
        cotacao: { ...refCotacao },
      };
    }

    if (finalItem) {
      finalItem.cotacao.idPropostaResiduoPlanoCotacaoEquipamento =
        finalItem.cotacao.idPropostaResiduoPlanoCotacaoEquipamento ??
        refCotacao?.idPropostaResiduoPlanoCotacaoEquipamento;
    }

    const { planoInicial } = encontrarPlanoCotacao();

    return (
      <EventEquipmentFormView
        key={`equipment-${
          quotationId?.toString() || eventGeneralData.idEventoReferencia2?.toString() || '-1'
        }`}
        ref={refEquipmentForm}
        origItem={props.event?.equipamento || null}
        item={finalItem}
        referenceData={props.referenceData}
        quotations={planoInicial?.cotacoes.map((x) => x.equipamentos).flat() || []}
        disabled={evento?.automatico || statusSoftDisabled || false}
      />
    );
  };

  const montarResiduoVeiculo = (
    refItem: ProposalResiduePlanVehicleType | undefined,
    refCotacao: ProposalResiduePlanQuotationVehicleType | undefined
  ) => {
    let finalItem = evento?.veiculo || null;

    if (!finalItem && refItem && refCotacao) {
      finalItem = {
        ...buildFakeAuditObject(),
        ...refItem,
        cotacao: { ...refCotacao },
      };
    }
    if (refCotacao) {
      finalItem.cotacao = { ...finalItem.cotacao, ...refCotacao };
    }

    const { planoInicial } = encontrarPlanoCotacao();
    const { veiculoCompartilhado } = evento?.resumoJSON || {};

    return (
      <EventVehicleFormView
        key={`vehicle-${quotationId?.toString() || eventGeneralData.idEventoReferencia2?.toString() || '-1'}`}
        ref={refVehicleForm}
        origItem={veiculoCompartilhado ?? evento?.veiculo ?? null}
        item={veiculoCompartilhado ?? finalItem}
        referenceData={props.referenceData}
        quotations={
          veiculoCompartilhado
            ? [veiculoCompartilhado.cotacao]
            : planoInicial?.cotacoes
                .map((x) => x.veiculo)
                .flat()
                .filter((y) => y.idPropostaResiduoPlanoCotacao === quotationId) || []
        }
        newEvent={!props.event}
        disabled={evento?.automatico || statusSoftDisabled || false}
        statusSoftDisabled={statusSoftDisabled}
      />
    );
  };

  const montarResiduoTratamento = (
    refItem: ProposalResiduePlanTreatmentType | undefined,
    refCotacao: ProposalResiduePlanQuotationTreatmentType | undefined
  ) => {
    let finalItem = evento?.tratamento || null;

    if (!finalItem && refItem && refCotacao) {
      finalItem = {
        ...buildFakeAuditObject(),
        ...refItem,
        cotacao: { ...refCotacao },
      };
    }
    if (refCotacao) {
      finalItem.cotacao = { ...finalItem.cotacao, ...refCotacao };
    }

    const { planoInicial } = encontrarPlanoCotacao();

    return (
      <EventTreatmentFormView
        key={`treatment-${
          quotationId?.toString() || eventGeneralData.idEventoReferencia2?.toString() || '-1'
        }`}
        ref={refTreatmentForm}
        origItem={evento?.tratamento || null}
        item={finalItem}
        referenceData={props.referenceData}
        quotations={
          planoInicial?.cotacoes
            .map((x) => x.tratamento)
            .flat()
            .filter((y) => y.idPropostaResiduoPlanoCotacao === quotationId) || []
        }
        newEvent={!props.event}
        disabled={evento?.automatico || statusSoftDisabled || false}
      />
    );
  };

  const montarResiduoDestinoFinal = (
    refItem: ProposalResiduePlanDestinationType | undefined,
    refCotacao: ProposalResiduePlanQuotationDestinationType | undefined
  ) => {
    let finalItem = evento?.destinoFinal || null;

    if (!finalItem && refItem && refCotacao) {
      finalItem = {
        ...buildFakeAuditObject(),
        ...refItem,
        cotacao: { ...refCotacao },
      };
    }
    if (refCotacao) {
      finalItem.cotacao = { ...finalItem.cotacao, ...refCotacao };
    }

    const { planoInicial } = encontrarPlanoCotacao();

    return (
      <EventDestinationFormView
        key={`destination-${
          quotationId?.toString() || eventGeneralData.idEventoReferencia2?.toString() || '-1'
        }`}
        ref={refDestinationForm}
        origItem={evento?.destinoFinal || null}
        item={finalItem}
        referenceData={props.referenceData}
        quotations={
          planoInicial?.cotacoes
            .map((x) => x.destinoFinal)
            .flat()
            .filter((y) => y.idPropostaResiduoPlanoCotacao === quotationId) || []
        }
        newEvent={!props.event}
        disabled={evento?.automatico || statusSoftDisabled || false}
      />
    );
  };

  const montarDocumento = useMemo(() => {
    const entidade = evento!;
    const documento = evento?.referencia?.entidade?.documento;

    if (!documento) {
      return <></>;
    }

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={2}>
            {entidade!.CNPJ ? (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={entidade.idEntidade?.toString() || ''}
                    name={entidade.nomeFantasia || entidade.razaoSocial || '-'}
                    nameSize="sm"
                    description={entidade!.nomeFantasia ? entidade!.razaoSocial : entidade!.CNPJ}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={entidade.idEntidade?.toString() || ''}
                    name={entidade.nome || '-'}
                    nameSize="sm"
                    description={entidade!.CPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Documento"
              text={
                <ProfileCardLink
                  id={documento.idEntidadeDocumento?.toString() || '-1'}
                  name={documento?.documentoTipo || documento?.outroTipo || '-'}
                  nameSize="sm"
                  description={documento.numeroDocumento || documento.observacao}
                  descriptionSize="xs"
                  showLink={false}
                />
              }
              size="sm"
            />
          </Grid.Col>
        </Grid>
      </Paper>
    );
  }, [
    eventGeneralData.codigoEventoReferencia,
    eventGeneralData.idEventoReferencia2,
    eventGeneralData.propostaResiduoData,
    montarResiduoAcondicionamento,
  ]);

  const montarComissao = useMemo(() => {
    const comissao = evento?.referencia?.comissao;

    if (!comissao) {
      return <></>;
    }

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={2}>
            {comissao!.cnpj ? (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={comissao.idEntidade?.toString() || ''}
                    name={comissao.nomeFantasia || comissao.razaoSocial || '-'}
                    nameSize="sm"
                    description={comissao!.nomeFantasia ? comissao!.razaoSocial : comissao!.cnpj}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={comissao.idEntidade?.toString() || ''}
                    name={comissao.nome || '-'}
                    nameSize="sm"
                    description={comissao!.cpf}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Porcentagem"
              text={comissao?.porcentagem ? `${comissao.porcentagem}%` : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Preço"
              text={comissao?.preco ? formatCurrency(comissao.preco || 0) : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Ocorrências" text={comissao?.ocorrencia || '-'} size="sm" />
          </Grid.Col>
        </Grid>
      </Paper>
    );
  }, [
    eventGeneralData.codigoEventoReferencia,
    eventGeneralData.idEventoReferencia2,
    eventGeneralData.propostaResiduoData,
    montarResiduoAcondicionamento,
  ]);

  const montarResiduo = useMemo(() => {
    const residuo = eventGeneralData.propostaResiduoData;
    if (!residuo) {
      return <></>;
    }

    const { planoInicial, planoCotacaoInicial } = encontrarPlanoCotacao();

    let item;
    let cotacao;
    const nodes: ReactNode[] = [];

    const tabs = [];
    let content;

    let veiculoCompartilhadoOriginal = false;

    switch (eventGeneralData.codigoEventoReferencia) {
      case ReferenciaCodigo.ContratoResiduoAcondicionamento:
        item = planoInicial?.acondicionamentos.find(
          (x) => x.idPropostaResiduoPlanoAcondicionamento === eventGeneralData.idEventoReferencia2
        );
        cotacao = planoCotacaoInicial?.acondicionamentos.find(
          (x) => x.idPropostaResiduoPlanoAcondicionamento === eventGeneralData.idEventoReferencia2
        );
        nodes.push(montarResiduoAcondicionamento(item, cotacao));
        break;
      case ReferenciaCodigo.ContratoResiduoEquipamento:
        item = planoInicial?.equipamentos.find(
          (x) => x.idPropostaResiduoPlanoEquipamento === eventGeneralData.idEventoReferencia2
        );
        cotacao = planoCotacaoInicial?.equipamentos.find(
          (x) => x.idPropostaResiduoPlanoEquipamento === eventGeneralData.idEventoReferencia2
        );
        nodes.push(montarResiduoEquipamento(item, cotacao));
        break;
      case ReferenciaCodigo.ContratoResiduoPlano:
        if (planoInicial && planoInicial?.veiculo) {
          content = montarResiduoVeiculo(planoInicial?.veiculo, planoCotacaoInicial?.veiculo);
        } else if (!planoInicial) {
          content = montarResiduoVeiculo(undefined, undefined);
        }

        if (content) {
          tabs.push({
            key: 'Veiculo',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Truck size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Veículo
                </Text>
              </Group>
            ),
            children: <>{content}</>,
            forceRender: true,
          });
          content = null;
        }

        if (planoInicial && planoInicial?.tratamento) {
          content = montarResiduoTratamento(planoInicial?.tratamento, planoCotacaoInicial?.tratamento);
        } else if (!planoInicial) {
          content = montarResiduoTratamento(undefined, undefined);
        }

        if (content) {
          tabs.push({
            key: 'Tratamento',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Recycle size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Tratamento
                </Text>
              </Group>
            ),
            children: <>{content}</>,
            forceRender: true,
          });
          content = null;
        }

        if (planoInicial && planoInicial?.destinoFinal) {
          content = montarResiduoDestinoFinal(planoInicial?.destinoFinal, planoCotacaoInicial?.destinoFinal);
        } else if (!planoInicial) {
          content = montarResiduoDestinoFinal(undefined, undefined);
        }

        if (content) {
          tabs.push({
            key: 'DestinoFinal',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Target size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Destino Final
                </Text>
              </Group>
            ),
            children: <>{content}</>,
            forceRender: true,
          });
          content = null;
        }

        veiculoCompartilhadoOriginal =
          (props.event?.relacoes?.findIndex(
            (x) =>
              x.codigoEventoRelacao === Relacao.VeiculoCompartilhado && x.idEvento === props.event?.idEvento
          ) ?? -1) > -1;

        nodes.push(
          <Paper shadow="xs" p="md" withBorder>
            {props.event?.idEventoVeiculoCompartilhado && (
              <Alert icon={<AlertTriangle size={16} />} title="Veículo Compartilhado" color="yellow">
                Esse evento tem o veículo compartilhado
                {!veiculoCompartilhadoOriginal ? ' e a edição principal do frete não é permitida.' : '.'} Veja
                mais detalhes na seção Relações.
              </Alert>
            )}
            <Tabs items={tabs} defaultActiveKey="Veiculo" />
          </Paper>
        );

        break;
      default:
        break;
    }

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart" key={`group-${quotationId?.toString() || '-1'}`}>
          <Group>
            <ProfileCardLink
              id={residuo.idResiduo.toString()}
              name={residuo.residuoCliente}
              nameSize="md"
              description={`Código IBAMA: ${residuo.codigoIBAMA || '-'} | Perigoso: ${
                residuo.residuoClientePerigoso ? 'Sim' : 'Não'
              }`}
              descriptionSize="sm"
              linkPrefix="residues"
              showLink={false}
            />
            <Group>
              <Stack spacing="xs">
                <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Event.color}>
                  {`Estimativa: ${residuo.estimativa} ${residuo.estimativaUnidadeMedida1} / ${residuo.estimativaUnidadeMedida2}`}
                </Badge>
                {residuo.minimoAceitavel ? (
                  <Badge key={newGuid()} variant="outline" size="md" color="orange">
                    {`Mínimo Aceitável: ${residuo.minimoAceitavel} ${residuo.minimoAceitavelUnidadeMedida1} / ${residuo.minimoAceitavelUnidadeMedida2}`}
                  </Badge>
                ) : (
                  <></>
                )}
              </Stack>
              <Stack spacing="xs">
                {residuo.compra && (
                  <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Event.color}>
                    Compra
                  </Badge>
                )}
                {residuo.cobrarTolerancia && (
                  <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
                    Cobrar Tolerâncias
                  </Badge>
                )}
              </Stack>
            </Group>
          </Group>
          {eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano &&
            eventGeneralData.idEventoReferencia2 &&
            !props.event && (
              <Select
                icon={<ZoomMoney size={15} />}
                itemComponent={QuotationSelectItem}
                placeholder="Selecione..."
                // data={
                //   planoInicial?.cotacoes?.map((y) => {
                //     return {
                //       value: y.idPropostaResiduoPlanoCotacao.toString(),
                //       label: `Cotação #${y.idPropostaResiduoPlanoCotacao}`,
                //     };
                //   }) || []
                // }
                data={
                  planoInicial?.cotacoes
                    ?.sort((a, b) => Number(a.primario ? 0 : 1) - Number(b.primario ? 0 : 1))
                    ?.map((y: any) => {
                      return {
                        label: `Cotação #${y.idPropostaResiduoPlanoCotacao}`,
                        value: y.idPropostaResiduoPlanoCotacao!.toString(),
                        group: `Cotação ${y.primario ? 'Primária' : 'Alternativa'}`,
                        extras: [
                          y?.veiculo
                            ? {
                                icone: Feature.Reference.Vehicle.icon,
                                fornecedor:
                                  y.veiculo?.fornecedorNomeFantasia ??
                                  y.veiculo?.fornecedorRazaoSocial ??
                                  y.veiculo?.fornecedorNome ??
                                  '-',
                              }
                            : undefined,
                          y?.tratamento
                            ? {
                                icone: Feature.Reference.Treatment.icon,
                                fornecedor:
                                  y.tratamento?.fornecedorNomeFantasia ??
                                  y.tratamento?.fornecedorRazaoSocial ??
                                  y.tratamento?.fornecedorNome ??
                                  '-',
                              }
                            : undefined,
                          y?.destinoFinal
                            ? {
                                icone: Feature.Reference.Destination.icon,
                                fornecedor:
                                  y.destinoFinal?.fornecedorNomeFantasia ??
                                  y.destinoFinal?.fornecedorRazaoSocial ??
                                  y.destinoFinal?.fornecedorNome ??
                                  '-',
                              }
                            : undefined,
                        ].filter((e) => e),
                      };
                    }) || []
                }
                value={quotationId?.toString()}
                onChange={(value) => {
                  setQuotationId(Number(value));
                }}
                searchable
              />
            )}
        </Group>
        <Space h="sm" />

        <div key={`nodes-${quotationId?.toString() || '-1'}`}>{nodes.map((x) => x)}</div>
      </Paper>
    );
  }, [
    eventGeneralData.codigoEventoReferencia,
    eventGeneralData.idEventoReferencia2,
    eventGeneralData.propostaResiduoData,
    montarResiduoAcondicionamento,
  ]);

  const montarServico = useMemo(() => {
    const servico = eventGeneralData.propostaServicoData;
    if (!servico) {
      return <></>;
    }

    let finalItem = evento?.servico || null;
    if (!finalItem) {
      if (!eventGeneralData.eventoReferenciaPersonalizado) {
        finalItem = {
          ...buildFakeAuditObject(),
          ...servico,
          cotacao: { ...servico.cotacoes.find((x) => x.primario) },
        };
      } else {
        finalItem = {
          ...buildFakeAuditObject(),
          ...servico,
          cotacao: {},
        };
      }
    }

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart" key={`group-${quotationId?.toString() || '-1'}`}>
          <Group>
            <ProfileCardLink
              id={servico.idServico.toString()}
              name={servico.servico}
              nameSize="md"
              description={servico.servicoDescricao}
              descriptionSize="sm"
              linkPrefix="services"
              showLink={false}
            />
            <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Event.color}>
              {`${servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}`}
            </Badge>
          </Group>
        </Group>
        <Space h="sm" />

        <EventServiceFormView
          key={`service-${
            eventGeneralData.eventoReferenciaPersonalizado
              ? '-1'
              : eventGeneralData.idEventoReferencia1?.toString()
          }`}
          ref={refServiceForm}
          origItem={evento?.servico || null}
          item={finalItem}
          referenceData={props.referenceData}
          quotations={eventGeneralData.eventoReferenciaPersonalizado ? [] : servico.cotacoes}
          disabled={evento?.automatico || statusSoftDisabled || false}
        />
      </Paper>
    );
  }, [
    eventGeneralData.codigoEventoReferencia,
    eventGeneralData.idEventoReferencia2,
    eventGeneralData.propostaResiduoData,
    montarResiduoAcondicionamento,
  ]);

  useImperativeHandle(ref, () => ({
    validate() {
      try {
        let response = {};

        if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento) {
          response = { ...response, ...refPackagingForm.current.validate() };
        } else if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento) {
          response = { ...response, ...refEquipmentForm.current.validate() };
        } else if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) {
          const vehicleData = refVehicleForm?.current?.validate();

          if (vehicleData) {
            response = { ...response, ...vehicleData };
          }

          if (!refTreatmentForm.current?.enabled() && !refDestinationForm.current?.enabled()) {
            throw Error('Tratamento ou Destino Final deve ser preenchido.');
          }

          const treatmentData = refTreatmentForm.current?.validate();
          if (treatmentData) {
            response = { ...response, ...treatmentData };
          }

          const destinationData = refDestinationForm.current?.validate();
          if (destinationData) {
            response = { ...response, ...destinationData };
          }
        } else if (eventGeneralData.codigoEventoReferencia === ReferenciaCodigo.ContratoServico) {
          response = { ...response, ...refServiceForm.current.validate() };
        }

        return response;
      } catch (ex: any) {
        throw Error(ex.message || 'Existem pendências a serem corrigidas.');
      }
    },
  }));

  useEffect(() => {
    const { planoInicial } = encontrarPlanoCotacao();
    setQuotationId(planoInicial?.cotacoes?.[0]?.idPropostaResiduoPlanoCotacao || null);
  }, [eventGeneralData]);

  return (
    <div>
      {clienteLocalValido() && (
        <>
          <Paper shadow="xs" p="md" withBorder>
            <PageSection
              size="lg"
              color={Feature.Home.Event.color}
              label="Cliente e Local"
              text="Cliente e local onde a prestação de serviço deverá ocorrer."
            />
            <Space h="xs" />

            <Grid columns={6}>
              <Grid.Col span={2}>
                {clienteLocalData!.clienteCNPJ ? (
                  <ProfileCardLink
                    id={clienteLocalData!.idCliente?.toString() || ''}
                    name={
                      clienteLocalData!.clienteNomeFantasia || clienteLocalData!.clienteRazaoSocial || '-'
                    }
                    nameSize="sm"
                    description={
                      clienteLocalData!.clienteNomeFantasia
                        ? clienteLocalData!.clienteRazaoSocial
                        : clienteLocalData!.clienteCNPJ
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : (
                  <ProfileCardLink
                    id={clienteLocalData!.idCliente?.toString() || ''}
                    name={clienteLocalData!.clienteNome || '-'}
                    nameSize="sm"
                    description={clienteLocalData!.clienteCPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                )}
              </Grid.Col>
              {propostaContato && (
                <Grid.Col span={2}>
                  <ProfileCardLink
                    id={propostaContato?.idEntidadeContato.toString() || '-1'}
                    name={propostaContato?.nome || '-'}
                    nameSize="sm"
                    description={
                      propostaContato?.telefone ||
                      propostaContato?.celular ||
                      propostaContato?.email ||
                      propostaContato?.setor
                    }
                    descriptionSize="xs"
                    showLink={false}
                  />
                </Grid.Col>
              )}
            </Grid>
            <Divider my="sm" />

            <Grid columns={6}>
              <Grid.Col span={2}>
                <PageViewProperty
                  label="Logradouro"
                  text={`${clienteLocalData!.logradouro}, ${clienteLocalData!.numero}${
                    clienteLocalData!.complemento ? ` | ${clienteLocalData!.complemento}` : ''
                  }`}
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <PageViewProperty
                  label="Bairro - Cidade"
                  text={`${clienteLocalData!.bairro} - ${clienteLocalData!.cidade} / ${
                    clienteLocalData!.codigoEstado
                  }`}
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <PageViewProperty label="CEP" text={clienteLocalData!.cep} size="sm" />
              </Grid.Col>
            </Grid>
          </Paper>
          <Space h="lg" />
        </>
      )}

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label={eventGeneralData.eventoTipo || 'Referência'}
          text="Informações detalhadas do evento como resíduo, serviço, documento, cliente, local, fornecedores, ety."
        />
        <Space h="xs" />

        {residuoValido() && montarResiduo}
        {servicoValido() && montarServico}
        {documentoValido() && montarDocumento}
        {comissaoValida() && montarComissao}
      </Paper>
    </div>
  );
});

export default ReferenceFormView;
