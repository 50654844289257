/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Edit, Trash } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { ProposalResidueType } from '../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../theme';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import { formatDateToString } from '../../../../../../../utils/formatter.utils';

type DataViewActionProps = {
  item: ProposalResidueType;
  confirmActionResult(item: ProposalResidueType | null, action: string, confirmed: boolean): void;
};

export default function ResidueFormViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: ProposalResidueType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse resíduo?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Resíduo (Cliente)" text={item.residuoCliente} size="sm" />
            <PageViewProperty
              label="Estimativa"
              text={`${item.estimativa} ${item.estimativaUnidadeMedida1} / ${item.estimativaUnidadeMedida2}`}
              size="sm"
            />
            <PageViewProperty label="Data Inicial" text={formatDateToString(item.dataInicial)} size="sm" />
            <PageViewProperty label="Data Final" text={formatDateToString(item.dataFinal)} size="sm" />
          </SimpleGrid>
          <Space h="xs" />
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
        <ActionIcon
          size="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.accent?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'editar', true);
          }}
        >
          <Edit size={15} color={theme?.colors?.accent?.[6]} />
        </ActionIcon>
      </Tooltip>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
