/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import lodash from 'lodash';
import moment from 'moment';
import 'moment-recur-ts';
import 'moment/locale/pt-br'; // without this line it didn't work
import { ProposalRecurrenceType } from '../models/core/proposals.type';

moment.locale('pt-BR');

export const DATE_FORMAT = 'ddd DD/MM/yyyy';
export type MonthYear = {
  key: number;
  order: number;
  year: number;
  month: number;
};

const everyNDay = (
  initialDate: Date,
  finalDate: Date,
  everyDay: number,
  maxOcurrences: number | null
): string[] => {
  initialDate = moment(initialDate).toDate();
  finalDate = moment(finalDate).toDate();

  const recurrence = moment()
    .recur({
      start: moment(initialDate)
        .clone()
        .add(everyDay * -1, 'day'),
      end: finalDate,
    })
    .every(everyDay)
    .days();

  let dateList: string[] = maxOcurrences
    ? recurrence.next(maxOcurrences, DATE_FORMAT)
    : recurrence.all(DATE_FORMAT);

  dateList = dateList.filter((x) => moment(x, DATE_FORMAT).toDate() >= initialDate);
  return dateList.slice(0, maxOcurrences || dateList.length);
};

const everyNDayDayOfWeek = (
  initialDate: Date,
  finalDate: Date,
  everyDay: number,
  daysOfWeek: number[],
  maxOcurrences: number | null
): string[] => {
  initialDate = moment(initialDate).toDate();
  finalDate = moment(finalDate).toDate();

  const recurrence = moment()
    .recur({
      start: moment(initialDate)
        .clone()
        .add(everyDay * -1, 'day'),
      end: finalDate,
    })
    .every(everyDay)
    .days()
    .every(daysOfWeek)
    .daysOfWeek();

  let dateList: string[] = maxOcurrences
    ? recurrence.next(maxOcurrences, DATE_FORMAT)
    : recurrence.all(DATE_FORMAT);

  dateList = dateList.filter((x) => moment(x, DATE_FORMAT).toDate() >= initialDate);
  return dateList.slice(0, maxOcurrences || dateList.length);
};

const everyNWeekDayOfWeek = (
  initialDate: Date,
  finalDate: Date,
  everyWeek: number,
  daysOfWeek: number[],
  maxOcurrences: number | null
): string[] => {
  initialDate = moment(initialDate).toDate();
  finalDate = moment(finalDate).toDate();

  const weeks = moment()
    .recur({
      start: moment(initialDate)
        .clone()
        .add(everyWeek * -1, 'weeks')
        .toDate(),
      end: finalDate,
    })
    .every(everyWeek)
    .weeks();
  const weekList = maxOcurrences ? weeks.next(maxOcurrences) : weeks.all();
  let dateList: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const week of weekList) {
    let weekFinalDate = moment(week).clone().add(6, 'days').toDate();
    weekFinalDate = weekFinalDate > finalDate ? finalDate : weekFinalDate;
    dateList = dateList.concat(
      everyNDayDayOfWeek(moment(week).toDate(), weekFinalDate, 1, daysOfWeek, maxOcurrences)
    );
  }

  dateList = dateList.filter((x) => moment(x, DATE_FORMAT).toDate() >= initialDate);
  return dateList.slice(0, maxOcurrences || dateList.length);
};

const everyNMonthDayOfMonth = (
  initialDate: Date,
  finalDate: Date,
  everyMonth: number,
  daysOfMonth: number[],
  maxOcurrences: number | null
): string[] => {
  initialDate = moment(initialDate).toDate();
  finalDate = moment(finalDate).toDate();

  const months = moment()
    .recur({
      start: moment(initialDate)
        .clone()
        .add(everyMonth * -1, 'month')
        .toDate(),
      end: finalDate,
    })
    .every(everyMonth)
    .months();

  const monthList = maxOcurrences ? months.next(maxOcurrences) : months.all();
  let dateList: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const month of monthList) {
    let monthFinalDate = moment(month).clone().add(1, 'month').toDate();
    monthFinalDate = monthFinalDate > finalDate ? finalDate : monthFinalDate;

    dateList = Array.from(
      new Set(
        dateList.concat(
          moment()
            .recur({
              start: moment(month).toDate(),
              end: monthFinalDate,
            })
            .every(daysOfMonth)
            .daysOfMonth()
            .all(DATE_FORMAT)
        )
      )
    );
  }

  dateList = dateList.filter((x) => moment(x, DATE_FORMAT).toDate() >= initialDate);
  return dateList.slice(0, maxOcurrences || dateList.length);
};

const everyNYearMonth = (
  initialDate: Date,
  finalDate: Date,
  everyYear: number,
  month: number,
  maxOcurrences: number | null
): string[] => {
  initialDate = moment(initialDate).toDate();
  finalDate = moment(finalDate).toDate();

  const years = moment()
    .recur({
      start: moment(initialDate)
        .clone()
        .add(everyYear * -1, 'year')
        .toDate(),
      end: finalDate,
    })
    .every(everyYear)
    .years();

  const yearList = maxOcurrences ? years.next(maxOcurrences) : years.all();
  let dateList: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const year of yearList) {
    let yearFinalDate = moment(year).clone().add(1, 'year').toDate();
    yearFinalDate = yearFinalDate > finalDate ? finalDate : yearFinalDate;

    dateList = dateList.concat(
      moment()
        .recur({
          start: moment(year).toDate(),
          end: yearFinalDate,
        })
        .every(month)
        .monthsOfYear()
        .every(initialDate.getDate())
        .daysOfMonth()
        .all(DATE_FORMAT)
    );
  }

  dateList = dateList.filter((x) => moment(x, DATE_FORMAT).toDate() >= initialDate);
  return dateList.slice(0, maxOcurrences || dateList.length);
};

const simulate = (recurrence: ProposalRecurrenceType) => {
  let data: string[] = [];

  if (recurrence.cada) {
    if (recurrence.frequencia === 'd') {
      data = everyNDayDayOfWeek(
        recurrence.dataInicial,
        recurrence.dataFinal,
        recurrence.cada!,
        recurrence.diasSemana!.map((x) => Number(x)),
        recurrence.ocorrencia ? recurrence.ocorrencia : null
      );
    } else if (recurrence.frequencia === 'w') {
      data = everyNWeekDayOfWeek(
        recurrence.dataInicial,
        recurrence.dataFinal,
        recurrence.cada,
        recurrence.diasSemana!.map((x) => Number(x)),
        recurrence.ocorrencia ? recurrence.ocorrencia : null
      );
    } else if (recurrence.frequencia === 'm') {
      data = everyNMonthDayOfMonth(
        recurrence.dataInicial,
        recurrence.dataFinal,
        recurrence.cada,
        recurrence.diasMes!.map((x) => Number(x)),
        recurrence.ocorrencia ? recurrence.ocorrencia : null
      );
    } else if (recurrence.frequencia === 'y') {
      data = everyNYearMonth(
        recurrence.dataInicial,
        recurrence.dataFinal,
        recurrence.cada,
        Number(recurrence.mesAno),
        recurrence.ocorrencia ? recurrence.ocorrencia : null
      );
    }
  }

  return data;
};

const getMonths = (initialDate: Date, finalDate: Date): MonthYear[] => {
  const everyMonth = 1;
  const months = moment()
    .recur({
      start: initialDate,
      end: finalDate,
    })
    .every(everyMonth)
    .months();

  return months.all().map((x, i) => {
    return { key: Number(`${x.year()}${x.month()}`), order: i, year: x.year(), month: x.month() };
  });
};

// BUG: App: 002 - if recurrence every 30th day of the month, Feb will not have an entry (perhaps have groupBy count in here to consider it eventually)
const getMonthsFromRecurrence = (recurrence: ProposalRecurrenceType) => {
  const data = simulate(recurrence);
  return lodash.uniqBy(
    data.map((x) => {
      const date = moment(x, DATE_FORMAT);
      return { id: `${date.year()}-${date.month()}`, year: date.year(), month: date.month() };
    }),
    'id'
  );
};

export {
  everyNDay,
  everyNDayDayOfWeek,
  everyNWeekDayOfWeek,
  everyNMonthDayOfMonth,
  everyNYearMonth,
  getMonths,
  getMonthsFromRecurrence,
  simulate,
};
