import { createContext, ReactNode, useContext, useState } from 'react';
import { EntitySearchResponseType } from '../../../models/core/entities.type';
import { ProposalServiceType } from '../../../models/core/proposals.type';

type ProposalGeneralData = {
  cliente: EntitySearchResponseType | null;
  codigoFechamento: string | null;
  dataInicial: Date;
  dataFinal: Date;

  servicos: ProposalServiceType[];
};

interface ProposalGeneralContextProps {
  proposalGeneralData: ProposalGeneralData;
  setProposalGeneralData(proposalGeneralContextData: ProposalGeneralData): void;
}
const ProposalGeneralContext = createContext<ProposalGeneralContextProps>({} as ProposalGeneralContextProps);

// eslint-disable-next-line react/function-component-definition
export const ProposalGeneralProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [proposalGeneralData, setProposalGeneralData] = useState<ProposalGeneralData>(
    {} as ProposalGeneralData
  );
  return (
    <ProposalGeneralContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        proposalGeneralData,
        setProposalGeneralData,
      }}
    >
      {children}
    </ProposalGeneralContext.Provider>
  );
};

export type { ProposalGeneralData };

export function useProposalGeneralContext() {
  return useContext(ProposalGeneralContext);
}
