/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Alert,
  Button,
  Divider,
  Group,
  Table as MantineTable,
  Menu,
  Modal,
  Paper,
  SimpleGrid,
  Space,
  Tooltip,
} from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import lodash from 'lodash';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CircleX, Id, InfoCircle, Plus, ReportMoney, World } from 'tabler-icons-react';
import {
  TipoCodigo,
  gerenciarVeiculoCompartilhadoMovimentacao,
} from '../../../../../../business/events/general';
import {
  MeasurementConversionErrorType,
  calculateHash,
  getMovementGroup,
} from '../../../../../../business/events/measurement';
import { calcularPreco } from '../../../../../../business/proposals/estimate';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  MovementType,
  MovementTypeGroup,
  MovementTypeGroupCode,
  MovementTypeStandard,
  UnitOfMeasureType,
} from '../../../../../../models/core/cache.type';
import { Action } from '../../../../../../models/core/core.type';
import {
  EventMeasurementMovementType,
  EventMeasurementType,
  EventType,
} from '../../../../../../models/core/events.type';
import { ProposalCommissionType } from '../../../../../../models/core/proposals.type';
import theme from '../../../../../../theme';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../utils/formatter.utils';
import MovementFormAddEdit from './MovementFormAddEdit';
import MeasurementMovementFormViewActions from './MovementFormViewActions';
import MovementView from './MovementView';
import { validate } from '../../../../../../utils/permission.utils';
import { Permission } from '../../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    movementTypeData: MovementType[];
  };
  event: EventType;
  data: {
    comissao: ProposalCommissionType | null;
    medicao: EventMeasurementType | null;
    erros: MeasurementConversionErrorType[];
  };
  editable: boolean;
};

const MovementFormView = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const [modalData, setModalData] = useState<{
    opened: boolean;
    item: EventMeasurementMovementType | null;
    group: MovementTypeGroup | null;
    refId: number | null;
    toleranceRefId: number | null;
    supplierId: number | null;
    action: string;
  }>({
    opened: false,
    item: null,
    group: null,
    refId: null,
    toleranceRefId: null,
    supplierId: null,
    action: '',
  });

  const [data, setData] = useState<EventMeasurementMovementType[]>(
    props.data?.medicao?.movimentacoes.map((x) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idEventoMedicaoMovimentacao?.toString() || x.id || '',
        hash: calculateHash(x),
        group: getMovementGroup(x).group,
      };
    }) || []
  );

  const eventoResumoJSON = props.event.resumoJSON!;
  const buildSupplierFromQuotation = (cotacao: any) => {
    return {
      idFornecedor: cotacao?.idFornecedor || cotacao?.idCliente || cotacao?.idEntidade,
      fornecedorCNPJ: cotacao?.fornecedorCNPJ || cotacao?.clienteCNPJ || cotacao?.cnpj,
      fornecedorRazaoSocial:
        cotacao?.fornecedorRazaoSocial || cotacao?.clienteRazaoSocial || cotacao?.razaoSocial,
      fornecedorNomeFantasia:
        cotacao?.fornecedorNomeFantasia || cotacao?.clienteNomeFantasia || cotacao?.nomeFantasia,
      fornecedorCPF: cotacao?.fornecedorCPF || cotacao?.clienteCPF || cotacao?.cpf,
      fornecedorNome: cotacao?.fornecedorNome || cotacao?.clienteNome || cotacao?.nome,
    };
  };
  const suppliers = [
    buildSupplierFromQuotation(eventoResumoJSON.cliente),
    buildSupplierFromQuotation(eventoResumoJSON.acondicionamento?.cotacao),
    buildSupplierFromQuotation(eventoResumoJSON.equipamento?.cotacao),
    buildSupplierFromQuotation(eventoResumoJSON.veiculo?.cotacao),
    buildSupplierFromQuotation(eventoResumoJSON.tratamento?.cotacao),
    buildSupplierFromQuotation(eventoResumoJSON.destinoFinal?.cotacao),
    buildSupplierFromQuotation(eventoResumoJSON.servico?.cotacao),
    buildSupplierFromQuotation(props.event.referencia?.comissao),
  ];

  const getQuotationReference2 = (movement: EventMeasurementMovementType) => {
    let cotacao: any;
    let cotacaoString;

    const resumoJSON =
      props.event.codigoEventoTipo === TipoCodigo.Fechamento
        ? props.event.faturamento?.medicoes.find((x) => x.idEventoMedicao === movement.idEventoMedicao)
            ?.resumoJSON
        : { ...eventoResumoJSON, ...props.event };

    if (movement.idEventoAcondicionamento) {
      cotacao = resumoJSON?.acondicionamento?.cotacao;
    } else if (movement.idEventoEquipamento) {
      cotacao = resumoJSON?.equipamento?.cotacao;
    } else if (movement.idEventoVeiculo) {
      cotacao = resumoJSON?.veiculo?.cotacao;
    } else if (movement.idEventoTratamento) {
      cotacao = resumoJSON?.tratamento?.cotacao;
    } else if (
      movement.idEventoDestinoFinal ||
      movement.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
    ) {
      cotacao = resumoJSON?.destinoFinal!.cotacao!;
    } else if (movement.idEventoServico) {
      cotacao = resumoJSON?.servico?.cotacao;
    }

    if (!cotacao) {
      return null;
    }

    const calcPreco = calcularPreco(
      cotacao.preco,
      cotacao.preco,
      cotacao.margem,
      cotacao.precoFinal,
      cotacao.imposto || 0,
      resumoJSON?.residuo?.compra || false,
      cotacao.receita || false
    );

    let preco1 =
      movement.idFornecedor === props.event.resumoJSON?.cliente?.idCliente
        ? calcPreco.preco
        : calcPreco.novoPrecoComMargem;

    let preco2 =
      movement.idFornecedor !== props.event.resumoJSON?.cliente?.idCliente
        ? calcPreco.preco
        : calcPreco.novoPrecoComMargem;

    let precoString = `${formatCurrency(preco1 > preco2 ? preco1 : preco2)} _ ${formatCurrency(
      preco1 > preco2 ? preco2 : preco1
    )}`;

    if (movement.idEventoAcondicionamento || movement.idEventoEquipamento) {
      cotacaoString = `${precoString} | ${cotacao?.quantidade} ${cotacao?.quantidadeUnidadeMedida} / ${cotacao?.frequenciaUnidadeMedida}`;
    } else if (movement.idEventoVeiculo) {
      cotacaoString = `${precoString} | ${cotacao?.quantidade} ${cotacao?.quantidadeUnidadeMedida} / ${cotacao?.frequenciaUnidadeMedida}`;

      if (
        cotacao?.minimoAceitavelIdUnidadeMedida &&
        movement.codigoMovimentacaoPadrao === MovementTypeStandard.Frete
      ) {
        cotacaoString += ` (Proporcional)`;
      }

      if (movement.codigoMovimentacaoPadrao === MovementTypeStandard.MinimoAceitavel) {
        cotacaoString = `${precoString} | ${cotacao?.minimoAceitavel} ${cotacao?.minimoAceitavelUnidadeMedida} / ${cotacao?.frequenciaUnidadeMedida}`;
      }
    } else if (movement.idEventoTratamento) {
      cotacaoString = `${precoString} | ${cotacao?.quantidade} ${cotacao?.quantidadeUnidadeMedida}`;
    } else if (
      movement.idEventoDestinoFinal ||
      movement.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
    ) {
      preco1 =
        movement.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
          ? calcPreco.preco
          : movement.codigoMovimentacaoPadrao === MovementTypeStandard.Venda
          ? calcPreco.novoPrecoComMargem
          : preco1;

      preco2 =
        movement.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
          ? calcPreco.novoPrecoComMargem
          : movement.codigoMovimentacaoPadrao === MovementTypeStandard.Venda
          ? calcPreco.preco
          : preco2;

      precoString = `${formatCurrency(preco1 > preco2 ? preco1 : preco2)} _ ${formatCurrency(
        preco1 > preco2 ? preco2 : preco1
      )}`;

      cotacaoString = `${precoString} | ${cotacao?.quantidade} ${cotacao?.quantidadeUnidadeMedida}`;
    } else if (movement.idEventoServico) {
      cotacaoString = `${precoString} | ${cotacao?.quantidade} ${cotacao?.frequenciaUnidadeMedida1} / ${cotacao?.frequenciaUnidadeMedida2}`;
    }

    if (movement.idEventoTolerancia) {
      cotacaoString = `${formatCurrency(movement.tolerancia?.precoUnitario || 0)} / ${
        movement.tolerancia?.unidadeMedida
      } (após ${movement.tolerancia?.quantidade} ${movement.tolerancia?.unidadeMedida})`;
    }

    return cotacaoString;
  };

  const [errorOpened, setErrorOpened] = useState(props.data.erros.length > 0);

  const columns: ColumnsType<any> = [
    {
      title: 'Grupo',
      key: 'grupo',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        const aValue = getMovementGroup(a).group || 'Geral';
        const bValue = getMovementGroup(b).group || 'Geral';
        return aValue.localeCompare(bValue);
      },
      render: (row: EventMeasurementMovementType) => {
        const movementGroup = getMovementGroup(row);
        const group = movementGroup.group ? MovementTypeGroupCode[movementGroup.group] : 'Geral';
        return <div>{`${group}`}</div>;
      },
    },
    {
      title: 'Tipo',
      key: 'movimentacaoTipo',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        return (a.outroTipo || a.movimentacaoTipo).localeCompare(b.outroTipo || b.movimentacaoTipo);
      },
      render: (row: EventMeasurementMovementType) => (
        // <div>{`${row.outroTipo ? `(O) ${row.outroTipo}` : row.movimentacaoTipo}`}</div>
        <ProfileCardLink
          id={row.idEventoMedicaoMovimentacao?.toString() || '-'}
          name={row.outroTipo || row.movimentacaoTipo}
          nameSize="sm"
          description={row.movimentacaoFaturamentoTipo}
          descriptionSize="xs"
          avatar={row.outroTipo ? 'O' : undefined}
          showLink={false}
        />
      ),
    },
    {
      title: 'Fornecedor',
      key: 'idFornecedor',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) =>
        (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
        ),
      render: (row: EventMeasurementMovementType) => {
        const fornecedor = suppliers.find((x) => x.idFornecedor === row.idFornecedor) || row;
        if (fornecedor.idFornecedor) {
          if (fornecedor.fornecedorCNPJ) {
            return (
              <ProfileCardLink
                id={fornecedor.idFornecedor.toString()}
                name={fornecedor.fornecedorNomeFantasia || fornecedor.fornecedorRazaoSocial || '-'}
                nameSize="sm"
                description={
                  fornecedor.fornecedorNomeFantasia
                    ? fornecedor.fornecedorRazaoSocial
                    : fornecedor.fornecedorCNPJ
                }
                descriptionSize="xs"
                linkPrefix="entities"
                showLink={false}
              />
            );
          }
          if (fornecedor.fornecedorCPF) {
            return (
              <ProfileCardLink
                id={fornecedor.idFornecedor.toString()}
                name={fornecedor.fornecedorNome || '-'}
                nameSize="sm"
                description={fornecedor.fornecedorCPF}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink={false}
              />
            );
          }
          return '-';
        }
        return '-';
      },
    },
    {
      title: 'Referência',
      key: 'referencia',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        return (getQuotationReference2(a) || '-').localeCompare(getQuotationReference2(b) || '-');
      },
      render: (row: EventMeasurementMovementType) => getQuotationReference2(row) || '-',
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventMeasurementMovementType) => (
        <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Receita',
      key: 'receita',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        const aValue = a.receita || 0;
        const bValue = b.receita || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventMeasurementMovementType) => <div>{formatCurrency(row.receita)}</div>,
    },
    {
      title: 'Despesa',
      key: 'despesa',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        const aValue = a.despesa || 0;
        const bValue = b.despesa || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventMeasurementMovementType) => {
        if (row.codigoMovimentacaoPadrao !== MovementTypeStandard.ComissaoPercentual) {
          return <div>{formatCurrency(row.despesa)}</div>;
        }

        return (
          <Group>
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              label={<div>Valor calculado no fechamento.</div>}
            >
              <div style={{ marginTop: 5, marginRight: -15 }}>
                <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
              </div>
            </Tooltip>
            <div>{`${formatCurrency(row.despesa)} (${props.data.comissao?.porcentagem}%)`}</div>
          </Group>
        );
      },
    },
    {
      title: 'Imposto',
      key: 'imposto',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        const aValue = a.imposto || 0;
        const bValue = b.imposto || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventMeasurementMovementType) => <div>{formatCurrency(row.imposto)}</div>,
    },
    {
      title: 'Balanço',
      key: 'balanco',
      sorter: (a: EventMeasurementMovementType, b: EventMeasurementMovementType) => {
        const aValue = a.balanco;
        const bValue = b.balanco;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventMeasurementMovementType) => {
        const total = row.balanco;
        const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

        return <div style={{ color }}>{formatCurrency(total)}</div>;
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EventMeasurementMovementType) => {
        const movementGroup = getMovementGroup(row);
        return (
          <MeasurementMovementFormViewActions
            event={props.event}
            editable={props.editable}
            item={row}
            movementTypeGroup={movementGroup.group}
            movementTypeGroupRefId={movementGroup.id}
            movementTypeGroupToleranceId={row.idEventoTolerancia}
            confirmActionResult={confirmActionResult}
          />
        );
      },
    },
  ];

  const confirmActionResult = (
    item: EventMeasurementMovementType | null,
    group: MovementTypeGroup | null,
    refId: number | null,
    toleranceRefId: number | null,
    supplierId: number | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      setModalData({ opened: false, item: null, group, refId, toleranceRefId, supplierId, action });
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEventoMedicaoMovimentacao) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
      case 'visualizar':
        setModalData({ opened: true, item: item!, group, refId, toleranceRefId, supplierId, action });
        break;
      case 'callback':
        setModalData({ opened: false, item: null, group, refId, toleranceRefId, supplierId, action });
        // eslint-disable-next-line no-param-reassign
        (item as any).group = getMovementGroup(item!).group;
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(JSON.parse(JSON.stringify(data)));
        break;
      default:
        break;
    }
  };

  const buildItemsSummary = () => {
    const groupedData = lodash.groupBy(
      data.filter((x) => x.action !== Action.Delete),
      'group'
    );
    const items = [];

    for (const key of Object.keys(groupedData)) {
      const item = groupedData[key];
      const itemSummary = {
        icon: World,
        item: key !== 'null' ? MovementTypeGroupCode[key as MovementTypeGroup] : 'Geral',
        receita: lodash.sumBy(item, 'receita'),
        despesa: lodash.sumBy(item, 'despesa'),
        imposto: lodash.sumBy(item, 'imposto'),
        balanco: 0,
      };
      itemSummary.balanco = itemSummary.receita - itemSummary.despesa - itemSummary.imposto;

      switch (key as MovementTypeGroup) {
        case MovementTypeGroup.Acondicionamento:
          itemSummary.icon = Feature.Reference.Packaging.icon;
          break;
        case MovementTypeGroup.Equipamento:
          itemSummary.icon = Feature.Reference.Equipment.icon;
          break;
        case MovementTypeGroup.Veiculo:
          itemSummary.icon = Feature.Reference.Vehicle.icon;
          break;
        case MovementTypeGroup.Tratamento:
          itemSummary.icon = Feature.Reference.Treatment.icon;
          break;
        case MovementTypeGroup.DestinoFinal:
          itemSummary.icon = Feature.Reference.Destination.icon;
          break;
        case MovementTypeGroup.Servico:
          itemSummary.icon = Feature.Reference.Service.icon;
          break;
        default:
          break;
      }

      items.push(itemSummary);
    }

    let grandTotal = {
      receita: 0,
      despesa: 0,
      imposto: 0,
      balanco: 0,
    };
    if (items.length > 0) {
      grandTotal = {
        receita: lodash.sumBy(items, 'receita'),
        despesa: lodash.sumBy(items, 'despesa'),
        imposto: lodash.sumBy(items, 'imposto'),
        balanco: 0,
      };
      grandTotal.balanco = grandTotal.receita - grandTotal.despesa - grandTotal.imposto;
    }

    return { grandTotal, items };
  };

  useImperativeHandle(ref, () => ({
    validate(): any {
      return { grandTotal: buildItemsSummary().grandTotal, data };
    },
    clear() {
      setData([]);
    },
  }));

  const buildSummary = () => {
    const nodes: ReactNode[] = [];
    const { grandTotal, items } = buildItemsSummary();

    // for (const item of items) {
    //   nodes.push(
    //     <tr>
    //       <td>
    //         <item.icon size={16} />
    //       </td>
    //       <td>{formatCurrency(item.receita)}</td>
    //       <td>{formatCurrency(item.despesa)}</td>
    //       <td>{formatCurrency(item.imposto)}</td>
    //       <td>{formatCurrency(item.balanco)}</td>
    //     </tr>
    //   );
    // }

    for (const item of items) {
      nodes.push(
        <div>
          <Group>
            <item.icon size={16} />
            <div>{formatCurrency(item.receita)}</div>
          </Group>
        </div>
      );
      nodes.push(<div>{formatCurrency(item.despesa)}</div>);
      nodes.push(<div>{formatCurrency(item.imposto)}</div>);
      nodes.push(
        <div
          style={{
            color: item.balanco > 0 ? 'green' : item.balanco === 0 ? 'orange' : 'red',
          }}
        >
          {formatCurrency(item.balanco)}
        </div>
      );
    }

    if (items.length === 0) {
      return <></>;
    }

    return (
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={4}>
          <PageViewProperty
            label="Receita"
            text={
              <div>
                <Group>
                  <ReportMoney size={16} />
                  <div>{formatCurrency(grandTotal!.receita)}</div>
                </Group>
              </div>
            }
            size="sm"
          />
          <PageViewProperty label="Despesa" text={formatCurrency(grandTotal!.despesa)} size="sm" />
          <PageViewProperty label="Imposto" text={formatCurrency(grandTotal!.imposto)} size="sm" />
          <PageViewProperty
            label="Balanço"
            text={
              <div
                style={{
                  color: grandTotal!.balanco > 0 ? 'green' : grandTotal!.balanco === 0 ? 'orange' : 'red',
                }}
              >
                {formatCurrency(grandTotal!.balanco)}
              </div>
            }
            size="sm"
          />
        </SimpleGrid>

        {/* <MantineTable>
          <thead>
            <tr>
              <th>Item</th>
              <th>Receita</th>
              <th>Despesa</th>
              <th>Imposto</td>
              <th>Balanço</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ReportMoney size={16} />
              </td>
              <td>{formatCurrency(grandTotal.receita)}</td>
              <td>{formatCurrency(grandTotal.despesa)}</td>
              <td>{formatCurrency(grandTotal.imposto)}</td>
              <td>{formatCurrency(grandTotal.balanco)}</td>
            </tr>
          </tbody>
        </MantineTable> */}

        <Divider my="sm" variant="dotted" />

        {/* <MantineTable withBorder={false} withColumnBorders={false}>
          <tbody>{nodes}</tbody>
        </MantineTable> */}

        <SimpleGrid cols={4} style={{ fontSize: 'smaller' }}>
          {nodes}
        </SimpleGrid>
      </Paper>
    );
  };

  const buildError = () => {
    const nodes: ReactNode[] = [];
    for (const erro of props.data.erros) {
      nodes.push(
        <tr>
          <td>{MovementTypeGroupCode[erro.group as MovementTypeGroup] || 'Geral'}</td>
          <td>{erro.movement.movimentacaoTipo}</td>
          <td>
            {erro.quantity} {erro.source.unidadeMedida}
          </td>
          <td>{erro.target.unidadeMedida}</td>
        </tr>
      );
    }

    if (nodes.length === 0) {
      return <></>;
    }

    return (
      <MantineTable>
        <thead>
          <tr>
            <th>Grupo</th>
            <th>Tipo</th>
            <th>Quantidade</th>
            <th>Converter para</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  useEffect(() => {
    const tempEvent = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEvent) || 'null');
    if (tempEvent) {
      setData(tempEvent.movimentacoes);
    }
  }, []);

  return (
    <>
      {modalData.action !== '' && (
        <Modal
          opened={modalData.opened}
          closeOnClickOutside={false}
          closeOnEscape={false}
          onClose={() =>
            setModalData({
              opened: false,
              item: null,
              group: null,
              refId: null,
              toleranceRefId: null,
              supplierId: props.event.resumoJSON?.cliente?.idCliente || null,
              action: '',
            })
          }
          title={`Movimentação - ${
            modalData.item ? (modalData.action === 'visualizar' ? 'Visualizar' : 'Editar') : 'Adicionar'
          }`}
          size="50%"
        >
          {modalData.action === 'visualizar' ? (
            <MovementView event={props.event} data={modalData.item!} />
          ) : (
            <MovementFormAddEdit
              referenceData={props.referenceData}
              origItem={
                props.data?.medicao?.movimentacoes.find(
                  (x) =>
                    modalData.item &&
                    modalData.item.idEventoMedicaoMovimentacao &&
                    x.idEventoMedicaoMovimentacao === modalData?.item?.idEventoMedicaoMovimentacao
                ) || null
              }
              item={modalData.item}
              idEventoMedicao={props.data?.medicao?.idEventoMedicao || null}
              movementTypeGroup={modalData.group}
              movementTypeGroupRefId={modalData.refId}
              movementTypeGroupToleranceId={modalData.toleranceRefId}
              supplier={suppliers.find((x) => x.idFornecedor === modalData.supplierId)!}
              codigoEventoReferencia={props.event.codigoEventoReferencia!}
              servico={props.event.servico || undefined}
              callback={confirmActionResult}
            />
          )}
        </Modal>
      )}

      <Group position="apart">
        <PageSection
          size="md"
          color={Feature.Home.Event.color}
          label="Movimentações Financeiras"
          text="Lista de movimentações financeiras sobre as medições."
        />
        <Menu shadow="md" withArrow>
          <Menu.Target>
            <Button
              color="primary"
              leftIcon={<Plus size={18} />}
              hidden={!props.editable}
              disabled={!validate(Permission.EventEdit, currentUser.permissoes)}
            >
              Adicionar
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              key="general"
              icon={<Id size={14} />}
              onClick={() => {
                confirmActionResult(
                  null,
                  null,
                  null,
                  null,
                  (props.event.codigoEventoTipo === TipoCodigo.Comissao
                    ? props.event.referencia?.comissao?.idEntidade
                    : props.event.resumoJSON?.cliente?.idCliente) || null,
                  'adicionar',
                  true
                );
              }}
            >
              Geral
            </Menu.Item>
            {props.event.acondicionamento && (
              <Menu.Item
                key="packaging"
                icon={<Feature.Reference.Packaging.icon size={14} />}
                onClick={() => {
                  confirmActionResult(
                    null,
                    MovementTypeGroup.Acondicionamento,
                    props.event.acondicionamento?.idEventoAcondicionamento || null,
                    null,
                    props.event.acondicionamento?.cotacao?.idFornecedor || null,
                    'adicionar',
                    true
                  );
                }}
              >
                Acondicionamento
              </Menu.Item>
            )}
            {props.event.equipamento && (
              <Menu.Item
                key="equipment"
                icon={<Feature.Reference.Equipment.icon size={14} />}
                onClick={() => {
                  confirmActionResult(
                    null,
                    MovementTypeGroup.Equipamento,
                    props.event.equipamento?.idEventoEquipamento || null,
                    null,
                    props.event.equipamento?.cotacao?.idFornecedor || null,
                    'adicionar',
                    true
                  );
                }}
              >
                Equipamento
              </Menu.Item>
            )}
            {gerenciarVeiculoCompartilhadoMovimentacao(props.event) && (
              <Menu.Item
                key="vehicle"
                icon={<Feature.Reference.Vehicle.icon size={14} />}
                onClick={() => {
                  confirmActionResult(
                    null,
                    MovementTypeGroup.Veiculo,
                    props.event.veiculo?.idEventoVeiculo || null,
                    null,
                    props.event.veiculo?.cotacao?.idFornecedor || null,
                    'adicionar',
                    true
                  );
                }}
              >
                Veículo
              </Menu.Item>
            )}
            {props.event.tratamento && (
              <Menu.Item
                key="treatment"
                icon={<Feature.Reference.Treatment.icon size={14} />}
                onClick={() => {
                  confirmActionResult(
                    null,
                    MovementTypeGroup.Tratamento,
                    props.event.tratamento?.idEventoTratamento || null,
                    null,
                    props.event.tratamento?.cotacao?.idFornecedor || null,
                    'adicionar',
                    true
                  );
                }}
              >
                Tratamento
              </Menu.Item>
            )}
            {props.event.destinoFinal && (
              <Menu.Item
                key="destination"
                icon={<Feature.Reference.Destination.icon size={14} />}
                onClick={() => {
                  confirmActionResult(
                    null,
                    MovementTypeGroup.DestinoFinal,
                    props.event.destinoFinal?.idEventoDestinoFinal || null,
                    null,
                    props.event.destinoFinal?.cotacao?.idFornecedor || null,
                    'adicionar',
                    true
                  );
                }}
              >
                Destino Final
              </Menu.Item>
            )}
            {props.event.servico && (
              <Menu.Item
                key="service"
                icon={<Feature.Reference.Service.icon size={14} />}
                onClick={() => {
                  confirmActionResult(
                    null,
                    MovementTypeGroup.Servico,
                    props.event.servico?.idEventoServico || null,
                    null,
                    props.event.servico?.cotacao?.idFornecedor || null,
                    'adicionar',
                    true
                  );
                }}
              >
                Serviço
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Space h="xs" />

      {buildSummary()}
      <Space h="xl" />

      {errorOpened && (
        <>
          <Alert
            icon={<CircleX size={16} />}
            title="Movimentações que não puderam ser calculadas"
            color="red"
            onClose={() => setErrorOpened(false)}
            withCloseButton
          >
            {buildError()}
          </Alert>
          <Space h="xl" />
        </>
      )}

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EventMeasurementMovementType) => item.id || 0}
        expandable={{
          rowExpandable: (row) => (row.idEventoMedicaoMovimentacao || -1) > 0,
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            const calcItem = props.data?.medicao?.movimentacoes.find(
              (x) => x.idEventoMedicaoMovimentacao === item.idEventoMedicaoMovimentacao
            )!;
            return (
              <PageViewAudit
                idCriadoPor={calcItem.idCriadoPor}
                criadoPor={calcItem.criadoPor}
                dataCriacao={calcItem.dataCriacao}
                idModificadoPor={calcItem.idModificadoPor || null}
                modificadoPor={calcItem.modificadoPor || null}
                dataModificacao={calcItem.dataModificacao || null}
                size="sm"
              />
            );
          },
        }}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </>
  );
});

export default MovementFormView;
