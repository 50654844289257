import { createContext, ReactNode, useContext, useState } from 'react';
import { EquipmentType } from '../../../models/core/equipment.type';
import { PackagingType } from '../../../models/core/packaging.type';
import { ServiceType } from '../../../models/core/services.type';
import { EntityItemEnum } from '../../../utils/constants.utils';

type ProposalServiceData = {
  tipo: EntityItemEnum;
  servico: ServiceType | null;
  quantidade: number | null;
  acondicionamento: PackagingType | null;
  equipamento: EquipmentType | null;
  dataInicial: Date;
  dataFinal: Date;
  frequencia: number | null;
  frequenciaIdUnidadeMedida1: number | null;
  frequenciaIdUnidadeMedida2: number | null;
};

interface ProposalServiceContextProps {
  proposalServiceData: ProposalServiceData;
  setProposalServiceData(proposalServiceContextData: ProposalServiceData): void;
}
const ProposalServiceContext = createContext<ProposalServiceContextProps>({} as ProposalServiceContextProps);

// eslint-disable-next-line react/function-component-definition
export const ProposalServiceProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [proposalServiceData, setProposalServiceData] = useState<ProposalServiceData>(
    {} as ProposalServiceData
  );
  return (
    <ProposalServiceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        proposalServiceData,
        setProposalServiceData,
      }}
    >
      {children}
    </ProposalServiceContext.Provider>
  );
};

export type { ProposalServiceData };

export function useProposalServiceContext() {
  return useContext(ProposalServiceContext);
}
