import { ContractStatusEnum } from '../../business/contracts/status';
import { Status, TipoCodigo } from '../../business/events/general';
import { ProposalStatusEnum } from '../../business/proposals/status';

export enum DashboardItem {
  MeusEventos = 'meus-eventos',
  EventosAlerta = 'eventos-alerta',
  ContratosExpiracao = 'contratos-expiracao',
  PropostasExpiracao = 'propostas-expiracao',
  EntidadeDocumentosExpiracao = 'entidade-documentos-expiracao',
}

type DashboardRequestType = {
  idEmpresa?: number | null;
  item: DashboardItem;
};

type MyEventsResponseType = {
  idEvento: number;
  idEmpresa: number;
  empresa: string;
  idContrato: number | null;
  idEntidade: number | null;
  idProposta: number | null;
  codigoEventoAudiencia: string;
  eventoAudiencia: string;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: string;
  codigoEventoStatus: Status;
  eventoStatus: string;
  data: Date;
  descricao: string;
};

type PendingEventsResponseType = {
  idEvento: number;
  idEmpresa: number;
  empresa: string;
  idContrato: number | null;
  idEntidade: number | null;
  idProposta: number | null;
  codigoEventoAudiencia: string;
  eventoAudiencia: string;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: string;
  codigoEventoStatus: Status;
  eventoStatus: string;
  data: Date;
  descricao: string;
};

type ExpiringContractsResponseType = {
  idContrato: number;
  idProposta: number;
  idEmpresa: number;
  empresa: string;
  codigoContratoStatus: ContractStatusEnum;
  contratoStatus: string;
  codigoContratoFechamento: string;
  codigoFechamento: string;
  fechamento: string;
  fechamentoDias: number;
  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;
  dataInicial: Date;
  dataFinal: Date;
  expiraEmDias: number;
};

type ExpiringProposalsResponseType = {
  idProposta: number;
  idPropostaPai: number | null;
  idEmpresa: number;
  empresa: string;
  codigoPropostaStatus: ProposalStatusEnum;
  propostaStatus: string;
  codigoFechamento: string;
  fechamento: string;
  fechamentoDias: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;
  clienteAceitaEncontroContas: boolean;

  idEntidadeContato: number;
  nome: string;
  telefone: string | null;
  celular: string | null;
  email: string | null;
  setor: string | null;

  idGerenciador: number | null;
  gerenciadorCNPJ: string | null;
  gerenciadorRazaoSocial: string | null;
  gerenciadorNomeFantasia: string | null;
  gerenciadorCPF: string | null;
  gerenciadorNome: string | null;
  gerenciadorAceitaEncontroContas: boolean;

  dataInicial: Date;
  dataFinal: Date;
  validade: Date;
  observacao: string | null;
  expiraEmDias: number;
};

type ExpiringEntityDocumentsResponseType = {
  idEntidadeDocumento: number;
  idEntidade: number;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  cpf: string | null;
  nome: string | null;
  idDocumentoTipo: number;
  documentoTipo: string;
  outroTipo: string | null;
  numeroDocumento: string | null;
  validadeLembreteDias: number | null;
  validade: Date;
  observacao: string | null;
  expiraEmDias: number;
};

export type {
  DashboardRequestType,
  MyEventsResponseType,
  PendingEventsResponseType,
  ExpiringContractsResponseType,
  ExpiringProposalsResponseType,
  ExpiringEntityDocumentsResponseType,
};
