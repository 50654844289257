export enum FileDownloadTypeEnum {
  Entity = 'entity',
  Event = 'event',
}

export enum FileDownloadSubTypeEnum {
  Document = 'document',
}

type FileDownloadType = {
  tipo: FileDownloadTypeEnum;
  idTipo: number;
  subTipo?: FileDownloadSubTypeEnum | null;
  idSubTipo?: number | null;
  arquivo: string;
  idArquivo: number;
};

// eslint-disable-next-line import/prefer-default-export
export type { FileDownloadType };
