import React from 'react';

const map = new Map<string, React.RefObject<unknown>>();

function setRef<T>(key: string): React.RefObject<T> | void {
  const ref = React.createRef<T>();
  map.set(key, ref);
  return ref;
}

function getRef<T>(
  key: string | null
): React.RefObject<T> | Map<string, React.RefObject<unknown>> | undefined | void {
  if (!key) {
    return map;
  }
  return map.get(key) as React.RefObject<T>;
}

function useDynamicRefs(): [(key: string | null) => any, (key: string) => any] {
  return [getRef, setRef];
}

export default useDynamicRefs;
