/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import {
  Badge,
  Grid,
  Group,
  Input,
  NumberInput,
  Select,
  SimpleGrid,
  Space,
  Textarea,
  Tooltip,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Building, Calendar, InfoCircle, Notes, Receipt2 } from 'tabler-icons-react';
import { ProposalTypeEnum } from '../../../../../../business/proposals/status';
import EntityContactList from '../../../../../../components/core/EntityContactList/EntityContactList';
import EntitySearch from '../../../../../../components/core/EntitySearch/EntitySearch';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import { useProposalGeneralContext } from '../../../../../../contexts/core/proposals/ProposalGeneral.context';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { ClosingType, CompanyType, EntityTypeType } from '../../../../../../models/core/cache.type';
import { Action } from '../../../../../../models/core/core.type';
import { EntityContactType, EntitySearchResponseType } from '../../../../../../models/core/entities.type';
import { ProposalType } from '../../../../../../models/core/proposals.type';
import theme from '../../../../../../theme';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../../../../utils/formatter.utils';
import { buildFakeAuditObject, tryGetDateISOFormat } from '../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  idEmpresa: string;
  idProposta: number | null;
  codigoFechamento: string;

  clienteData: EntitySearchResponseType | null;
  idCliente: number | null;
  cliente: string;

  contatoData: EntityContactType | null;
  idEntidadeContato: number | null;
  contato: string;

  gerenciadorData: EntitySearchResponseType | null;
  idGerenciador: number | null;
  gerenciador: string;

  dataInicial: Date;
  dataFinal: Date;
  documentacaoClienteRecorrenciaDias: number | null;
  documentacaoCliente: string | null;
  validadeLembreteDias: number | null;
  validade: Date;
  observacao: string | null;
};

type ProposalFormViewProps = {
  referenceData: {
    companies: CompanyType[];
    closingTypeData: ClosingType[];
    entityTypeData: EntityTypeType[];
  };
  proposal: ProposalType | null;
};

const GeneralFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const { setProposalGeneralData } = useProposalGeneralContext();
  const disabled = props.proposal?.codigoPropostaTipo === ProposalTypeEnum.Reajuste;

  const buildClienteDataFromItem = (item: ProposalType | null): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idCliente),
      tipos: [],
      cnpj: item.clienteCNPJ || null,
      razaoSocial: item.clienteRazaoSocial || null,
      nomeFantasia: item.clienteNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.clienteCPF || null,
      nome: item.clienteNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const buildContatoDataFromItem = (item: ProposalType | null): EntityContactType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidadeContato: item.idEntidadeContato,
      idEntidade: item.idCliente,
      nome: item.nome,
      telefone: item.telefone || null,
      email: item.email || null,
    };
  };

  const buildGerenciadorDataFromItem = (item: ProposalType | null): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idGerenciador),
      tipos: [],
      cnpj: item.gerenciadorCNPJ || null,
      razaoSocial: item.gerenciadorRazaoSocial || null,
      nomeFantasia: item.gerenciadorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.gerenciadorCPF || null,
      nome: item.gerenciadorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.proposal?.idProposta ? Action.Nothing : Action.Add,
      idEmpresa: props.proposal?.idEmpresa?.toString() || currentUser.idEmpresa.toString() || '',
      idProposta: props.proposal?.idProposta || null,

      clienteData: buildClienteDataFromItem(props.proposal),
      idCliente: props.proposal?.idCliente || null,
      cliente:
        props.proposal?.clienteNomeFantasia ||
        props.proposal?.clienteRazaoSocial ||
        props.proposal?.clienteNome ||
        '',

      contatoData: buildContatoDataFromItem(props.proposal),
      idEntidadeContato: props.proposal?.idEntidadeContato || null,
      contato: props.proposal?.idEntidadeContato
        ? `${props.proposal.nome} (${
            props.proposal.telefone || props.proposal.celular || props.proposal.email || '-'
          })`
        : '',

      gerenciadorData: buildGerenciadorDataFromItem(props.proposal),
      idGerenciador: props.proposal?.idGerenciador || null,
      gerenciador:
        props.proposal?.gerenciadorNomeFantasia ||
        props.proposal?.gerenciadorRazaoSocial ||
        props.proposal?.gerenciadorNome ||
        '',

      codigoFechamento: props.proposal?.codigoFechamento || '',

      dataInicial: props.proposal
        ? formatDateStringToDate(tryGetDateISOFormat(props.proposal.dataInicial))
        : new Date(),

      dataFinal: props.proposal
        ? formatDateStringToDate(tryGetDateISOFormat(props.proposal.dataFinal))
        : new Date(),

      documentacaoClienteRecorrenciaDias: props.proposal?.documentacaoClienteRecorrenciaDias || null,
      documentacaoCliente: props.proposal?.documentacaoCliente || '',

      validadeLembreteDias: props.proposal?.validadeLembreteDias || null,
      validade: props.proposal
        ? formatDateStringToDate(tryGetDateISOFormat(props.proposal.validade))
        : new Date(),

      observacao: props.proposal?.observacao || '',
    },
    validate: {
      idEmpresa: (value) => {
        return props.referenceData.companies.find((x) => x.idEmpresa === Number(value))
          ? null
          : 'Campo obrigatório';
      },
      cliente: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      contato: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      codigoFechamento: (value) => {
        return props.referenceData.closingTypeData.find((x) => x.codigoFechamento === value)
          ? null
          : 'Campo obrigatório';
      },
      dataInicial: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        return null;
      },
      dataFinal: (value, values: FormViewData) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (value < values.dataInicial) {
          return 'Data final não pode ser anterior à data inicial';
        }
        return null;
      },
      validade: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.proposal?.idEmpresa || ''}
    |${props.proposal?.idCliente || ''}
    |${props.proposal?.idEntidadeContato || ''}
    |${props.proposal?.idGerenciador || ''}
    |${props.proposal?.codigoFechamento || ''}
    |${formatDateToString(props.proposal?.dataInicial || new Date()) || ''}
    |${formatDateToString(props.proposal?.dataFinal || new Date()) || ''}
    |${formatDateToString(props.proposal?.validade || new Date()) || ''}
    |${props.proposal?.validadeLembreteDias || ''}
    |${props.proposal?.documentacaoClienteRecorrenciaDias || ''}
    |${props.proposal?.documentacaoCliente || ''}
    |${props.proposal?.observacao || ''}`;

    const formData = `${form.values.idEmpresa || ''}
    |${form.values.idCliente || ''}
    |${form.values.idEntidadeContato || ''}
    |${form.values.idGerenciador || ''}
    |${form.values.codigoFechamento || ''}
    |${formatDateToString(form.values.dataInicial) || new Date()}
    |${formatDateToString(form.values.dataFinal) || new Date()}
    |${formatDateToString(form.values.validade) || new Date()}
    |${form.values.validadeLembreteDias || ''}
    |${form.values.documentacaoClienteRecorrenciaDias || ''}
    |${form.values.documentacaoCliente || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      if (form.validate().hasErrors) {
        throw Error('Existem pendências a serem corrigidas.');
      }
      const formItem = JSON.parse(JSON.stringify(form.values)) as FormViewData;

      if (props.proposal?.idProposta && isModified()) {
        formItem.action = Action.Modify;
      }

      formItem.documentacaoCliente = formItem.documentacaoCliente?.trim() || null;
      formItem.observacao = formItem.observacao?.trim() || null;

      // Leave this commented out. To be used by the draft.
      // delete (formItem as any).clienteData;

      return formItem;
    },
    clear() {
      form.reset();
      form.setFieldValue('idCliente', -1);
      form.setFieldValue('cliente', '');
      form.setFieldValue('idEntidadeContato', -1);
      form.setFieldValue('contato', '');
      form.setFieldValue('idGerenciador', -1);
      form.setFieldValue('gerenciador', '');
      form.setFieldValue('documentacaoClienteRecorrenciaDias', 0);
    },
  }));

  useEffect(() => {
    const tempProposal = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempProposal) || 'null');
    if (tempProposal && props.proposal === null) {
      form.setValues({
        ...tempProposal,
        clienteData: tempProposal.clienteData,
        gerenciadorData: tempProposal.gerenciadorData,
        dataInicial: formatDateStringToDate(tryGetDateISOFormat(tempProposal.dataInicial)),
        dataFinal: formatDateStringToDate(tryGetDateISOFormat(tempProposal.dataFinal)),
        validade: formatDateStringToDate(tryGetDateISOFormat(tempProposal.validade)),
      });
    }
    setProposalGeneralData({
      cliente: buildClienteDataFromItem(props.proposal),
      codigoFechamento: props.proposal?.codigoFechamento || null,
      dataInicial: props.proposal?.dataInicial || new Date(),
      dataFinal: props.proposal?.dataFinal || new Date(),
      servicos: props.proposal?.servicos || [],
    });
  }, []);

  useEffect(() => {
    setProposalGeneralData({
      cliente: form.values.clienteData,
      codigoFechamento: form.values.codigoFechamento,
      dataInicial: form.values.dataInicial,
      dataFinal: form.values.dataFinal,
      servicos: props.proposal?.servicos || [],
    });
  }, [form.values, setProposalGeneralData]);

  return (
    <div>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Geral"
          text="Informações gerais da proposta como cliente, fechamento, datas, documentação, etc."
        />
        <Badge variant="outline">{props.proposal?.propostaTipo || 'Completa'}</Badge>
      </Group>
      <Space h="xs" />

      <Grid columns={8}>
        <Grid.Col span={1}>
          <Select
            icon={<Building size={15} />}
            label="Empresa"
            placeholder="Selecione..."
            data={props.referenceData.companies.map((x) => {
              return {
                value: x.idEmpresa.toString(),
                label: x.empresa,
                disabled: x.idEmpresa === 0,
              };
            })}
            searchable
            required
            disabled={!currentUser.executivo || disabled}
            {...form.getInputProps('idEmpresa')}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <EntitySearch
            label="Cliente"
            referenceData={props.referenceData}
            formItem={form.getInputProps('cliente')}
            idsToBeDisabled={[]}
            disabled={disabled}
            required
            callback={(item: EntitySearchResponseType | null) => {
              if (item) {
                form.setFieldValue('clienteData', item);
                form.setFieldValue('idCliente', item.idEntidade);
                form.setFieldValue('cliente', item.nomeFantasia || item.razaoSocial || item.nome || '');
                form.setFieldValue('idEntidadeContato', -1);
                form.setFieldValue('contato', '');
              }
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <EntityContactList
            label="Contato"
            referenceData={{ idEntidade: form.values.idCliente! }}
            formItem={form.getInputProps('contato')}
            idEntidadeContato={form.values.idEntidadeContato}
            contato={form.values.contato}
            idsToBeDisabled={[]}
            disabled={false}
            callback={async (item: EntityContactType | null) => {
              if (item) {
                form.setFieldValue('contatoData', item);
                form.setFieldValue('idEntidadeContato', item.idEntidadeContato!);
                form.setFieldValue(
                  'contato',
                  `${item.nome} (${item.telefone || item.celular || item.email || '-'})`
                );
              }
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <EntitySearch
            label="Gerenciador"
            referenceData={props.referenceData}
            formItem={form.getInputProps('gerenciador')}
            idsToBeDisabled={[]}
            disabled={false}
            required={false}
            callback={(item: EntitySearchResponseType | null) => {
              form.setFieldValue('gerenciadorData', item);
              form.setFieldValue('idGerenciador', item?.idEntidade || null);
              form.setFieldValue('gerenciador', item?.nomeFantasia || item?.razaoSocial || item?.nome || '');
            }}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Select
            icon={<Receipt2 size={15} />}
            label="Fechamento"
            placeholder="Selecione..."
            data={props.referenceData.closingTypeData.map((x) => {
              return {
                value: x.codigoFechamento,
                label: x.fechamento,
              };
            })}
            required
            // disabled={disabled}
            {...form.getInputProps('codigoFechamento')}
          />
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <SimpleGrid cols={4}>
        <div>
          <Input.Wrapper label="Data Inicial" required>
            <Group
              style={{
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -2,
                verticalAlign: 'bottom',
              }}
            >
              <Tooltip
                withArrow
                transition="fade"
                transitionDuration={200}
                label="Data prevista de início do contrato"
              >
                <div>
                  <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                </div>
              </Tooltip>
            </Group>
          </Input.Wrapper>
          <DatePicker
            icon={<Calendar size={15} />}
            locale="pt-br"
            inputFormat="DD/MM/YYYY"
            clearable={false}
            allowFreeInput
            required
            {...form.getInputProps('dataInicial')}
          />
        </div>
        <div>
          <Input.Wrapper label="Data Final" required>
            <Group
              style={{
                display: 'inline-block',
                marginLeft: 5,
                marginTop: -2,
                verticalAlign: 'bottom',
              }}
            >
              <Tooltip
                withArrow
                transition="fade"
                transitionDuration={200}
                label="Data prevista de fim do contrato"
              >
                <div>
                  <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                </div>
              </Tooltip>
            </Group>
          </Input.Wrapper>
          <DatePicker
            icon={<Calendar size={15} />}
            locale="pt-br"
            inputFormat="DD/MM/YYYY"
            clearable={false}
            allowFreeInput
            minDate={form.values.dataInicial}
            required
            {...form.getInputProps('dataFinal')}
          />
        </div>
        <DatePicker
          icon={<Calendar size={15} />}
          locale="pt-br"
          label="Validade"
          inputFormat="DD/MM/YYYY"
          clearable={false}
          allowFreeInput
          required
          {...form.getInputProps('validade')}
        />
        <NumberInput
          label="Lembrete da Validade"
          placeholder="Lembrete em dias"
          min={1}
          max={3120}
          {...form.getInputProps('validadeLembreteDias')}
        />
      </SimpleGrid>
      <Space h="xs" />

      <Grid columns={4}>
        <Grid.Col span={1}>
          <NumberInput
            label="Recorrência de Envio da Documentação"
            placeholder="Recorrência em dias"
            min={1}
            max={3120}
            // disabled={disabled}
            {...form.getInputProps('documentacaoClienteRecorrenciaDias')}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Textarea
            icon={<Notes size={15} />}
            label="Documentação"
            placeholder="Detalhes da documentacao"
            autosize
            maxLength={500}
            // disabled={disabled}
            {...form.getInputProps('documentacaoCliente')}
          />
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Observação"
          placeholder="Observação sobre a proposta"
          autosize
          maxLength={500}
          // disabled={disabled}
          {...form.getInputProps('observacao')}
        />
      </SimpleGrid>
    </div>
  );
});

export default GeneralFormView;
