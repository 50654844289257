/* eslint-disable react/destructuring-assignment */
import { Edit, Trash } from 'tabler-icons-react';
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { EntityAddressContactType } from '../../../../../../../models/core/entities.type';
import theme from '../../../../../../../theme';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';

type DataViewActionProps = {
  item: EntityAddressContactType;
  confirmActionResult(item: EntityAddressContactType | null, action: string, confirmed: boolean): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function AddressContactFormViewActions(props: DataViewActionProps) {
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} esse contato?`}
      >
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Nome" text={props.item.nome} size="sm" />
            <PageViewProperty label="Setor" text={props.item.setor || '-'} size="sm" />
            <PageViewProperty label="E-mail" text={props.item.email || '-'} size="sm" />
            <PageViewProperty
              label="Telefone/Celular"
              text={props.item.telefone || props.item.celular || '-'}
              size="sm"
            />
          </SimpleGrid>
        </Paper>
        <Space h="lg" />

        <Group position="right">
          <Button
            color="secondary"
            // leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult(props.item, modalData.action, false);
            }}
          >
            Não
          </Button>
          <Button
            // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
            // leftIcon={<Check size={18} />}
            color="primary"
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult(props.item, modalData.action, true);
            }}
          >
            Sim
          </Button>
        </Group>
      </Modal>

      <Group>
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.accent?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'editar', true);
            }}
          >
            <Edit size={15} color={theme?.colors?.accent?.[6]} />
          </ActionIcon>
        </Tooltip>
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'red' }}
            variant="outline"
            onClick={() => {
              setModalData({ opened: true, action: 'excluir' });
            }}
          >
            <Trash size={15} color="red" />
          </ActionIcon>
        </Tooltip>
      </Group>
    </div>
  );
}
