import { call } from '../core-reference.service';
import {
  DestinationUpdateType,
  DestinationDeleteType,
  DestinationInsertType,
  DestinationSearchType,
  DestinationSelectType,
  DestinationType,
} from '../../models/core/destinations.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const equipmentsEndpoint = '/v1/residuo-destinos-finais';

export default class DestinationsDestination {
  static search = async (searchProps: DestinationSearchType): Promise<DestinationType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: equipmentsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<DestinationType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: DestinationSelectType): Promise<DestinationType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${equipmentsEndpoint}/${selectProps.idResiduoDestinoFinal}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: DestinationUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${equipmentsEndpoint}/${updateProps.idResiduoDestinoFinal}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: DestinationDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${equipmentsEndpoint}/${deleteProps.idResiduoDestinoFinal}`,
    };
    return call(request);
  };

  static insert = async (insertProps: DestinationInsertType): Promise<DestinationType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: equipmentsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
