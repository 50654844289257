/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Badge, Group, Paper, Select, Space, Switch } from '@mantine/core';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { EventVehicleProvider } from '../../../../../../../contexts/core/events/EventVehicle.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { EventVehicleType } from '../../../../../../../models/core/events.type';
import { ProposalResiduePlanQuotationVehicleType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import ResiduePlanVehicleFormAddEdit from './EventVehicleFormAddEdit';
import EventVehicleQuotationFormAddEdit from './EventVehicleQuotationFormAddEdit';
import EventVehiclePackagingFormView from './Packaging/EventVehiclePackagingFormView';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type EventVehicleFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  quotations: ProposalResiduePlanQuotationVehicleType[];
  origItem: EventVehicleType | null;
  item: EventVehicleType | null;
  newEvent: boolean;
  disabled: boolean;
  statusSoftDisabled: boolean;
};

const EventVehicleFormView = forwardRef((props: EventVehicleFormViewProps, ref) => {
  const refVehicleForm = useRef<any>();
  const refVehiclePackagingForm = useRef<any>();
  const refVehicleQuotationForm = useRef<any>();

  const [quotation, setQuotation] = useState<ProposalResiduePlanQuotationVehicleType | null>(
    props.item?.cotacao || null
  );
  const [enabled, setEnabled] = useState<boolean>(props.newEvent || !!props.origItem);

  const { eventGeneralData } = useEventGeneralContext();
  const planoSelecionado = eventGeneralData?.propostaResiduoData?.planos?.find(
    (x) => Number(x.idPropostaResiduoPlano) === Number(eventGeneralData.idEventoReferencia2)
  );
  const planoPrimario = eventGeneralData?.propostaResiduoData?.planos?.find((x) => x.primario);
  const cotacaoPrimaria = planoPrimario?.cotacoes?.find((y) => y.primario)?.veiculo;

  useImperativeHandle(ref, () => ({
    validate(): any {
      try {
        if (!enabled) {
          return null;
        }

        const veiculo = refVehicleForm?.current?.validate();
        const acondicionamentos = refVehiclePackagingForm?.current?.validate() || [];
        const cotacao = refVehicleQuotationForm?.current?.validate();

        if (veiculo === null || cotacao === null) {
          throw Error('Existem pendências a serem corrigidas no formulário do Veículo.');
        }

        return {
          veiculo: {
            ...veiculo,
            acondicionamentos,
            cotacao: {
              ...cotacao,
            },
          },
          idEventoReferencia3: quotation?.idPropostaResiduoPlanoCotacaoVeiculo || null,
        };
      } catch (ex: any) {
        throw Error(ex.message || 'Existem pendências a serem corrigidas.');
      }
    },
    clear() {
      refVehicleForm.current.clear();
      refVehiclePackagingForm.current.clear();
      refVehicleQuotationForm.current.clear();
    },
    enabled() {
      return enabled;
    },
  }));

  return (
    <EventVehicleProvider>
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Veículo"
            text="Detalhes do veículo."
          />
          <Switch
            label="Incluir veículo?"
            checked={enabled}
            onChange={(event) => {
              setEnabled(event.currentTarget.checked);
              refVehicleForm?.current?.clear();
              refVehicleQuotationForm?.current?.clear();
            }}
            disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado || !props.newEvent}
          />
        </Group>
        <Space h="xs" />

        <ResiduePlanVehicleFormAddEdit
          ref={refVehicleForm}
          referenceData={props.referenceData}
          origItem={props.origItem}
          item={props.item}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado || !enabled}
          statusSoftDisabled={props.statusSoftDisabled}
        />
      </Paper>

      {enabled && (
        <>
          <Space h="lg" />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <PageSection
                size="lg"
                color={Feature.Home.Event.color}
                label="Fornecedor"
                text="Detalhes do fornecedor."
              />
              <Group>
                {cotacaoPrimaria && (
                  <Badge variant="outline" size="md" color="orange">
                    {`Preço (Cliente): ${formatCurrency(
                      calcularPreco(
                        cotacaoPrimaria.preco,
                        cotacaoPrimaria.preco,
                        cotacaoPrimaria.margem,
                        cotacaoPrimaria.precoFinal,
                        cotacaoPrimaria.imposto || 0,
                        eventGeneralData.propostaResiduoData!.compra,
                        false
                      ).novoPrecoComMargem
                    )} / ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.frequenciaUnidadeMedida}`}
                  </Badge>
                )}
                {props.quotations.length > 0 && !props.origItem && (
                  <Select
                    icon={<ZoomMoney size={15} />}
                    placeholder="Selecione..."
                    data={props.quotations.map((x) => {
                      return {
                        value: x.idPropostaResiduoPlanoCotacaoVeiculo!.toString(),
                        label: `${formatCurrency(x.preco)} / ${x.frequenciaUnidadeMedida}`,
                        group: `${
                          x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome || '?'
                        }`,
                      };
                    })}
                    value={quotation?.idPropostaResiduoPlanoCotacaoVeiculo?.toString()}
                    onChange={(value) => {
                      let quotationRef =
                        props.quotations.find(
                          (x) => x.idPropostaResiduoPlanoCotacaoVeiculo === Number(value)
                        ) || null;

                      if (quotationRef) {
                        quotationRef = {
                          ...props.item?.cotacao,
                          ...quotationRef,
                        };
                      }
                      setQuotation(quotationRef);
                    }}
                    searchable
                    disabled
                  />
                )}
              </Group>
            </Group>

            <Space h="xs" />
            <EventVehicleQuotationFormAddEdit
              ref={refVehicleQuotationForm}
              key={quotation?.idPropostaResiduoPlanoCotacaoVeiculo || '-1'}
              referenceData={props.referenceData}
              origItem={props.origItem?.cotacao || null}
              item={quotation || null}
              disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado || !enabled}
            />
          </Paper>

          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <EventVehiclePackagingFormView
              ref={refVehiclePackagingForm}
              key={quotation?.idPropostaResiduoPlanoCotacaoVeiculo || '-1'}
              eventVehicle={props.item}
              eventPackagingItems={props.newEvent ? planoSelecionado?.acondicionamentos || [] : []}
              referenceData={props.referenceData}
              disabled={props.statusSoftDisabled}
            />
          </Paper>
        </>
      )}
    </EventVehicleProvider>
  );
});

export default EventVehicleFormView;
