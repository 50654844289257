/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Divider, Group, Paper, SimpleGrid, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { Backhoe, Package, Recycle, Target, Truck } from 'tabler-icons-react';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import {
  ProposalResiduePlanType,
  ProposalServiceType,
} from '../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../utils/constants.utils';
import ResiduePlanGeneralDestinationView from './ResiduePlanGeneralView/ResiduePlanGeneralDestinationView';
import ResiduePlanGeneralEquipmentView from './ResiduePlanGeneralView/ResiduePlanGeneralEquipmentView';
import ResiduePlanGeneralPackagingView from './ResiduePlanGeneralView/ResiduePlanGeneralPackagingView';
import ResiduePlanGeneralTreatmentView from './ResiduePlanGeneralView/ResiduePlanGeneralTreatmentView';
import ResiduePlanGeneralVehicleView from './ResiduePlanGeneralView/ResiduePlanGeneralVehicleView';

type ViewProps = {
  data: ProposalResiduePlanType;
  services: ProposalServiceType[];
};

export default function ResiduePlanGeneralView(props: ViewProps) {
  const tabs = [
    {
      key: 'Acondicionamentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Package size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Acondicionamentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanGeneralPackagingView data={props.data.acondicionamentos} services={props.services} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Equipamentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Backhoe size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Equipamentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanGeneralEquipmentView data={props.data.equipamentos} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Veiculos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Truck size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Veículo
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanGeneralVehicleView data={props.data.veiculo} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tratamentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Recycle size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Tratamento
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanGeneralTreatmentView data={props.data.tratamento} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'DestinoFinal',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Target size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Destino Final
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanGeneralDestinationView data={props.data.destinoFinal} />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Geral"
          text="Informações gerais do plano de coleta do resíduo."
        />
        <Space h="xs" />

        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Primário?" text={props.data.primario ? 'Sim' : 'Não'} size="sm" />
          </SimpleGrid>
          <Divider my="sm" variant="dotted" />

          <SimpleGrid cols={1}>
            <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
          </SimpleGrid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <Tabs items={tabs} defaultActiveKey="Acondicionamentos" />
        </Paper>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
      />
    </div>
  );
}
