/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, SimpleGrid, Space } from '@mantine/core';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { EventDestinationType } from '../../../../../../../models/core/events.type';

type DataViewProps = {
  data: EventDestinationType;
};

export default function EventDestinationItem(props: DataViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Destino Final"
              text={
                <ProfileCardLink
                  id={(props.data.idResiduoDestinoFinal || '').toString()}
                  name={props.data.residuoDestinoFinal || '-'}
                  nameSize="sm"
                  description={props.data.residuoDestinoFinalDescricao}
                  descriptionSize="xs"
                  linkPrefix="destinations"
                  showLink
                />
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Autorização Ambiental?"
              text={props.data.autorizacaoAmbiental ? 'Sim' : 'Não'}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={1}>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
        gridSize={2}
      />
    </div>
  );
}
