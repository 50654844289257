/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Building, Id, Search, User, World } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import {
  ActionIcon,
  Grid,
  MultiSelect,
  Select,
  SimpleGrid,
  Space,
  Switch,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { EntityType, EntityTypeType } from '../../../../../../models/core/entities.type';
import { procurarCNPJ } from '../../../../../../services/utils/brasilapi.service';
import theme from '../../../../../../theme';
import { EntityTypeType as CacheEntityTypeType } from '../../../../../../models/core/cache.type';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../models/core/core.type';
import { buildFakeAuditObject, isCNPJValid, isCPFValid } from '../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  idEntidade: number | null;
  tipos: string[] | EntityTypeType[];
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  incricaoEstadual: string | null;
  incricaoMunicipal: string | null;
  cpf: string | null;
  nome: string | null;
  webSite: string | null;
  aceitaEncontroContas: boolean;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: CacheEntityTypeType[];
  };
  entity: EntityType | null;
};

const GeneralFormView = forwardRef((props: FormViewProps, ref) => {
  const TIPO_PF = 'PF';
  const TIPO_PJ = 'PJ';
  const [tipo, setTipo] = useState(
    props.entity?.idEntidade ? (props.entity.cpf ? TIPO_PF : TIPO_PJ) : TIPO_PJ
  );
  const [searchingCNPJ, setSearchingCNPJ] = useState(false);
  const [enabledCNPJFields, setEnabledCNPJFields] = useState(!props.entity?.cnpj);

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.entity?.idEntidade ? Action.Nothing : Action.Add,
      idEntidade: props.entity?.idEntidade || null,
      tipos: props.entity?.tipos?.map((x) => x.codigoEntidadeTipo) || [],
      cnpj: props.entity?.cnpj || '',
      razaoSocial: props.entity?.razaoSocial || '',
      nomeFantasia: props.entity?.nomeFantasia || '',
      incricaoEstadual: props.entity?.incricaoEstadual || '',
      incricaoMunicipal: props.entity?.incricaoMunicipal || '',
      cpf: props.entity?.cpf || '',
      nome: props.entity?.nome || '',
      webSite: props.entity?.webSite || '',
      aceitaEncontroContas: props.entity?.aceitaEncontroContas || false,
    },
    validate: {
      tipos: (value: string[] | EntityTypeType[]) => (value.length === 0 ? 'Campo obrigatório' : null),
      cnpj: (value: string | null) => {
        if (tipo === TIPO_PJ) {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
          if (!/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value || '')) {
            return 'Formato inválido (Ex: 00.000.000/0000-00)';
          }
          if (value?.trim() !== '' && !isCNPJValid(value || '')) {
            return 'CNPJ inválido';
          }
        }
        return null;
      },
      razaoSocial: (value: string | null) => {
        if (tipo === TIPO_PJ) {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
        }
        return null;
      },
      cpf: (value: string | null) => {
        if (tipo === TIPO_PF) {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
          if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value || '')) {
            return 'Formato inválido (Ex: 000.000.000-00)';
          }
          if (value?.trim() !== '' && !isCPFValid(value || '')) {
            return 'CPF inválido';
          }
        }
        return null;
      },
      nome: (value: string | null) => {
        if (tipo === TIPO_PF) {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
        }
        return null;
      },
    },
  });

  const searchCNPJ = async () => {
    if (!form.validateField('cnpj').hasError) {
      const cnpj = form.values.cnpj || '';
      setSearchingCNPJ(true);
      try {
        const cnpjData = await procurarCNPJ(cnpj.replace(/\D+/g, ''));
        form.values.razaoSocial = cnpjData.razao_social;
        form.values.nomeFantasia = cnpjData.nome_fantasia;
        form.validateField('razaoSocial');
      } catch (error: any) {
        form.values.razaoSocial = '';
        form.values.nomeFantasia = '';
        showNotification({
          title: `Entidade - ${!props.entity?.idEntidade ? 'Adicionar' : 'Editar'}`,
          message: 'CNPJ não encontrado.',
          color: 'red',
        });
      } finally {
        setSearchingCNPJ(false);
        setEnabledCNPJFields(true);
      }
    }
  };

  const isModified = (): boolean => {
    const origData = `${
      props.entity?.tipos
        .map((x) => x.codigoEntidadeTipo)
        .sort()
        .join('-') || ''
    }
    |${props.entity?.cnpj || ''}
    |${props.entity?.razaoSocial || ''}
    |${props.entity?.nomeFantasia || ''}
    |${props.entity?.incricaoEstadual || ''}
    |${props.entity?.incricaoMunicipal || ''}
    |${props.entity?.cpf || ''}
    |${props.entity?.nome || ''}
    |${props.entity?.webSite || ''}
    |${props.entity?.aceitaEncontroContas || ''}`;

    const formData = `${form.values.tipos.sort().join('-') || ''}
    |${form.values.cnpj || ''}
    |${form.values.razaoSocial || ''}
    |${form.values.nomeFantasia || ''}
    |${form.values.incricaoEstadual || ''}
    |${form.values.incricaoMunicipal || ''}
    |${form.values.cpf || ''}
    |${form.values.nome || ''}
    |${form.values.webSite || ''}
    |${form.values.aceitaEncontroContas || ''}`;

    return origData !== formData;
  };

  const buildEntityTypes = (tipos: string[]): EntityTypeType[] => {
    const placeholder = {
      ...buildFakeAuditObject(),
      idEntidade: props.entity?.idEntidade || -1,
    };

    if (props.entity === null) {
      return tipos.map((x) => {
        return {
          ...placeholder,
          codigoEntidadeTipo: x,
          entidadeTipo: '',
          action: Action.Add,
        };
      });
    }

    const delta: EntityTypeType[] = [];
    const currentTypes = props.entity?.tipos || [];

    // handle deletes
    for (const currentType of currentTypes) {
      if (tipos.includes(currentType.codigoEntidadeTipo)) {
        delta.push({ ...currentType, action: Action.Nothing });
      } else {
        delta.push({ ...currentType, action: Action.Delete });
      }
    }
    // handle adds
    for (const t of tipos) {
      if (currentTypes.findIndex((x) => x.codigoEntidadeTipo === t) === -1) {
        delta.push({ ...placeholder, codigoEntidadeTipo: t, entidadeTipo: '', action: Action.Add });
      }
    }

    return delta;
  };

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      if (form.validate().hasErrors) {
        return null;
      }
      const formItem = JSON.parse(JSON.stringify(form.values)) as FormViewData;

      if (tipo === TIPO_PF) {
        formItem.cnpj = null;
        formItem.razaoSocial = null;
        formItem.nomeFantasia = null;
        formItem.incricaoEstadual = null;
        formItem.incricaoMunicipal = null;
      } else {
        formItem.cpf = null;
        formItem.nome = null;
      }

      if (props.entity?.idEntidade && isModified()) {
        formItem.action = Action.Modify;
      }

      formItem.tipos = buildEntityTypes(form.values.tipos as string[]);
      formItem.razaoSocial = formItem.razaoSocial?.trim() || null;
      formItem.nomeFantasia = formItem.nomeFantasia?.trim() || null;
      formItem.incricaoEstadual = formItem.incricaoEstadual?.trim() || null;
      formItem.incricaoMunicipal = formItem.incricaoMunicipal?.trim() || null;
      formItem.nome = formItem.nome?.trim() || null;
      formItem.webSite = formItem.webSite?.trim() || null;

      return formItem;
    },
    clear() {
      form.reset();
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setTipo(tempEntity.cpf ? TIPO_PF : TIPO_PJ);
      form.setValues({
        ...tempEntity,
        tipos: tempEntity.tipos.map((x: any) => x.codigoEntidadeTipo),
      });
    }
  }, []);

  return (
    <div>
      <PageSection size="lg" color={Feature.Reference.Entity.color} label="Geral" text="" />
      <Space h="xs" />
      <Grid columns={5}>
        <Grid.Col span={1}>
          <Select
            label="Tipo"
            placeholder="Selecione..."
            data={[
              { label: 'Pessoa Física', value: TIPO_PF },
              { label: 'Pessoa Jurídica', value: TIPO_PJ },
            ]}
            value={tipo}
            onChange={(event) => setTipo(event || '')}
            required
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <MultiSelect
            label="Escopos"
            placeholder="Selecione..."
            data={props.referenceData.entityTypeData.map((x) => {
              return {
                value: x.codigoEntidadeTipo,
                label: x.entidadeTipo,
              };
            })}
            clearable
            required
            {...form.getInputProps('tipos')}
          />
        </Grid.Col>
      </Grid>
      {tipo === TIPO_PF && (
        <div>
          <Space h="sm" />
          <SimpleGrid cols={5}>
            <NumberFormat
              icon={<Id size={15} />}
              label="CPF"
              placeholder="___.___.___-__"
              mask="_"
              format="###.###.###-##"
              customInput={TextInput}
              {...form.getInputProps('cpf')}
            />
            <TextInput
              icon={<User size={15} />}
              label="Nome"
              placeholder="Digite o nome"
              maxLength={100}
              required
              {...form.getInputProps('nome')}
            />
          </SimpleGrid>
        </div>
      )}
      {tipo === TIPO_PJ && (
        <div>
          <Space h="sm" />
          <SimpleGrid cols={5}>
            <NumberFormat
              icon={<Id size={15} />}
              rightSection={
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="primary"
                  style={{ borderColor: theme?.colors?.primary?.[6] }}
                  variant="outline"
                  loading={searchingCNPJ}
                  onClick={() => {
                    searchCNPJ();
                  }}
                >
                  <Search size={15} color={theme?.colors?.primary?.[6]} />
                </ActionIcon>
              }
              label="CNPJ"
              placeholder="__.___.___/____-__"
              disabled={searchingCNPJ}
              required
              onKeyUp={(event: any) => {
                if (event.key === 'Enter') {
                  searchCNPJ();
                }
              }}
              mask="_"
              format="##.###.###/####-##"
              customInput={TextInput}
              {...form.getInputProps('cnpj')}
            />
            <TextInput
              icon={<Id size={15} />}
              label="Inscrição Estadual"
              placeholder="Digite a incrição estadual"
              maxLength={50}
              {...form.getInputProps('incricaoEstadual')}
            />
            <TextInput
              icon={<Id size={15} />}
              label="Inscrição Municipal"
              placeholder="Digite a incrição municipal"
              maxLength={50}
              {...form.getInputProps('incricaoMunicipal')}
            />
          </SimpleGrid>
          <Space h="sm" />
          <SimpleGrid cols={2}>
            <Textarea
              icon={<Building size={15} />}
              label="Razão Social"
              placeholder="Digite a razão social"
              maxLength={500}
              autosize
              required
              disabled={!enabledCNPJFields}
              {...form.getInputProps('razaoSocial')}
            />
            <Textarea
              icon={<Building size={15} />}
              label="Nome Fantasia"
              placeholder="Digite o nome fantasia"
              maxLength={500}
              autosize
              disabled={!enabledCNPJFields}
              {...form.getInputProps('nomeFantasia')}
            />
          </SimpleGrid>
        </div>
      )}
      <div>
        <Space h="sm" />
        <Grid columns={5}>
          <Grid.Col span={1}>
            <div style={{ marginTop: 30 }}>
              <Switch
                label="Encontro de Contas?"
                {...form.getInputProps('aceitaEncontroContas', { type: 'checkbox' })}
              />
            </div>
          </Grid.Col>
          <Grid.Col span={2}>
            <TextInput
              icon={<World size={15} />}
              label="Website"
              placeholder="Digite o website"
              maxLength={100}
              {...form.getInputProps('webSite')}
            />
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
});

export default GeneralFormView;
