/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, SimpleGrid, Space } from '@mantine/core';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { EventVehicleType } from '../../../../../../../models/core/events.type';

type DataViewProps = {
  data: EventVehicleType;
};

export default function EventVehicleItem(props: DataViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Veículo"
              text={
                <ProfileCardLink
                  id={(props.data.idResiduoVeiculo || '').toString()}
                  name={props.data.residuoVeiculo || '-'}
                  nameSize="sm"
                  description={props.data.residuoVeiculoDescricao}
                  descriptionSize="xs"
                  linkPrefix="vehicles"
                  showLink
                />
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Quantidade"
              text={`${props.data.quantidade} ${props.data.quantidadeUnidadeMedida}`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Carga Média (Coleta)"
              text={`${props.data.cargaMedia} ${props.data.cargaMediaUnidadeMedida}`}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={4}>
          <PageViewProperty label="Frota" text={props.data.frota || '-'} size="sm" />
          <PageViewProperty label="Placa" text={props.data.placa || '-'} size="sm" />
          <PageViewProperty label="Motorista" text={props.data.motorista || '-'} size="sm" />
          <PageViewProperty label="Rota" text={props.data.rota || '-'} size="sm" />
        </SimpleGrid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={1}>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
        gridSize={2}
      />
    </div>
  );
}
