/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Divider, Grid, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Check, Eye, X } from 'tabler-icons-react';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { EventVehiclePackagingType } from '../../../../../../../models/core/events.type';
import theme from '../../../../../../../theme';

type ViewProps = {
  data: EventVehiclePackagingType[];
};

export default function EventVehiclePackagingView(props: ViewProps) {
  const [planItem, setPlanItem] = useState<EventVehiclePackagingType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<EventVehiclePackagingType> = [
    {
      title: 'Acondicionamento',
      key: 'residuoAcondicionamento',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) =>
        a.residuoAcondicionamento.localeCompare(b.residuoAcondicionamento),
      render: (row: EventVehiclePackagingType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoAcondicionamento?.toString() || ''}
            name={row.residuoAcondicionamento || ''}
            nameSize="sm"
            description={row.residuoAcondicionamentoDescricao}
            descriptionSize="xs"
            linkPrefix="packaging"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventVehiclePackagingType) => (
        <div>{`${row.compartilhado ? '% de ' : ''}${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Compartilhado?',
      key: 'compartilhado',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) =>
        a.compartilhado === b.compartilhado ? 0 : a.compartilhado ? -1 : 1,
      render: (row: EventVehiclePackagingType) => (
        <div>{row.compartilhado ? <Check color="green" /> : <X color="red" />}</div>
      ),
    },
    {
      title: 'Tipo',
      key: 'tipo',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) =>
        a.enviar === b.enviar ? 0 : a.enviar ? -1 : 1,
      render: (row: EventVehiclePackagingType) => (
        <Badge variant="outline" size="sm">
          {row.enviar ? 'Enviar' : 'Retirar'}
        </Badge>
      ),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EventVehiclePackagingType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EventVehiclePackagingType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Evento/Veículo/Acondicionamento - Visualizar"
        size="50%"
      >
        {planItem ? (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={5}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Acondicionamento"
                    text={
                      <ProfileCardLink
                        id={(planItem.idResiduoAcondicionamento || '').toString()}
                        name={planItem.residuoAcondicionamento || '-'}
                        nameSize="sm"
                        description={planItem.residuoAcondicionamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="packaging"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={`${planItem.compartilhado ? '% de ' : ''}${planItem.quantidade} ${
                      planItem.quantidadeUnidadeMedida
                    }`}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Compartilhado?"
                    text={planItem.compartilhado ? 'Sim' : 'Não'}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Tipo"
                    text={
                      <Badge variant="outline" size="sm">
                        {planItem.enviar ? 'Enviar' : 'Retirar'}
                      </Badge>
                    }
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planItem.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planItem.idCriadoPor}
              criadoPor={planItem.criadoPor}
              dataCriacao={planItem.dataCriacao}
              idModificadoPor={planItem?.idModificadoPor || null}
              modificadoPor={planItem?.modificadoPor || null}
              dataModificacao={planItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>

      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EventVehiclePackagingType) => item.idEventoVeiculoAcondicionamento || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
