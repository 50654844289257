import { DepartmentPermissionType, Permission } from '../models/core/departments.type';

function validate(
  requiredPermission: Permission | undefined,
  permissions: DepartmentPermissionType[]
): boolean {
  if (!requiredPermission) {
    return true;
  }
  return permissions.findIndex((x) => x.codigoPermissao === requiredPermission) > -1;
}

// eslint-disable-next-line import/prefer-default-export
export { validate };
