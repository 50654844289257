/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { Grid, Space, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useImperativeHandle } from 'react';
import { Notes } from 'tabler-icons-react';
import PageViewProperty from '../../../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import {
  EntityTypeType,
  UnitOfMeasureType,
} from '../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../models/core/core.type';
import { ProposalResiduePlanQuotationType } from '../../../../../../../../../../../../models/core/proposals.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  primario: boolean;
  observacao: string | null;
};

type ProposalFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanQuotationType | null;
  item: ProposalResiduePlanQuotationType | null;
  idPropostaResiduoPlano: number | undefined;
  callback(item: ProposalResiduePlanQuotationType | null, action: string, confirmed: boolean): void;
};

const ResiduePlanQuotationGeneralFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      primario: props.item?.primario || false,
      observacao: props.item?.observacao || '',
    },
    validate: {},
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.primario || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.primario || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanQuotationType | null {
      if (form.validate().hasErrors) {
        throw Error('Existem pendências a serem corrigidas.');
      }

      let { values } = form;

      if (props.origItem) {
        if (isModified()) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
          };
        }
      }

      const formItem: ProposalResiduePlanQuotationType = {
        ...values,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idPropostaResiduoPlano: props.idPropostaResiduoPlano || -1,
      };

      formItem.observacao = formItem.observacao?.trim() || null;
      return formItem;
    },
  }));

  return (
    <div>
      <Grid columns={1}>
        <Grid.Col span={1}>
          <PageViewProperty label="Primário?" text={form.values.primario ? 'Sim' : 'Não'} size="sm" />
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <Grid columns={1}>
        <Grid.Col span={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o plano de coleta do resíduo"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
});

export default ResiduePlanQuotationGeneralFormView;
