/* eslint-disable react/destructuring-assignment */
import { Grid, Paper, Select, Space, Switch, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Notes, Qrcode, Recycle, Ruler2 } from 'tabler-icons-react';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import { IBAMAResidueType, UnitOfMeasureType } from '../../../../../models/core/cache.type';
import { ResidueType } from '../../../../../models/core/residues.type';
import { Feature } from '../../../../../utils/constants.utils';

type FormViewData = {
  idResiduo: number | null;
  residuo: string;
  descricao: string | null;
  idUnidadeMedida: string | null;
  codigoIBAMA: string | null;
  perigoso: boolean;
};

type FormViewProps = {
  save(data: FormViewData): void;
  unitOfMeasures: UnitOfMeasureType[];
  IBAMAResidues: IBAMAResidueType[];
  residue: ResidueType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      idResiduo: props.residue?.idResiduo || null,
      residuo: props.residue?.residuo || '',
      descricao: props.residue?.descricao || '',
      idUnidadeMedida: props.residue?.idUnidadeMedida?.toString() || '',
      codigoIBAMA: props.residue?.codigoIBAMA || '',
      perigoso: props.residue?.perigoso || false,
    },
    validate: {
      residuo: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
      // codigoIBAMA: (value) => {
      //   const noMakedValue = value?.replaceAll('_', '').trim();
      //   return value === '' ||
      //     /^\d{2} \d{2} \d{2}$/.test(noMakedValue || '') ||
      //     /^\d{2} \d{2}$/.test(noMakedValue || '') ||
      //     /^\d{2}$/.test(noMakedValue || '')
      //     ? null
      //     : 'Formato inválido (Ex: 00, 00 00, 00 00 00)';
      // },
    },
  });

  const handleSubmit = async (formData: FormViewData) => {
    props.save({
      ...formData,
      residuo: formData.residuo.trim(),
      descricao: formData.descricao?.trim() || null,
      idUnidadeMedida: formData.idUnidadeMedida || null,
      codigoIBAMA: formData.codigoIBAMA?.replaceAll('_', '').trim() || null,
    });
  };

  return (
    <form id="residue-add-edit" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Residue.color} label="Geral" text="" />
        <Space h="xs" />
        <Textarea
          icon={<Recycle size={15} />}
          label="Resíduo"
          placeholder="Nome do resíduo"
          autosize
          maxLength={500}
          required
          {...form.getInputProps('residuo')}
        />
        <Space h="xs" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <Select
              icon={<Ruler2 size={15} />}
              label="Unidade Medida"
              placeholder="Selecione..."
              data={props.unitOfMeasures.map((x) => {
                return {
                  value: x.idUnidadeMedida.toString(),
                  label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                  group: x.tipo,
                };
              })}
              searchable
              clearable
              {...form.getInputProps('idUnidadeMedida')}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <div style={{ marginTop: 30 }}>
              <Switch label="Perigoso?" {...form.getInputProps('perigoso', { type: 'checkbox' })} />
            </div>
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              icon={<Qrcode size={15} />}
              label="Código IBAMA"
              placeholder="Selecione..."
              data={props.IBAMAResidues.map((x) => {
                return {
                  value: x.codigoIBAMA,
                  label: `${x.codigoIBAMA} | ${x.residuo}`,
                  group: x.codigoIBAMA.substring(0, 5),
                };
              })}
              clearable
              searchable
              {...form.getInputProps('codigoIBAMA')}
            />
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <Textarea
          icon={<Notes size={15} />}
          label="Descrição"
          placeholder="Descrição do resíduo"
          autosize
          maxLength={500}
          {...form.getInputProps('descricao')}
        />
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
