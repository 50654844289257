/* eslint-disable react/destructuring-assignment */
import { Button, Grid, Group, MultiSelect, Paper, Select, SimpleGrid, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Building, ClearAll, Search } from 'tabler-icons-react';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { CompanyType, ContractStatusType } from '../../../../../models/core/cache.type';

type FilterData = {
  idEmpresa: string | null;
  listaStatus: string[];
  dataInicial: string | null;
  dataFinal: string | null;
};

type FilterProps = {
  companies: CompanyType[];
  contractStatuses: ContractStatusType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function Filter(props: FilterProps) {
  const [currentUser] = useCurrentUser();

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
      listaStatus: [],
      dataInicial: null,
      dataFinal: null,
    },
  });

  return (
    <form onSubmit={form.onSubmit(props.filter)}>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={1}>
            <Select
              icon={<Building size={15} />}
              label="Empresa"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.companies.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <MultiSelect
              label="Status"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.contractStatuses.map((x) => {
                return {
                  value: x.codigoContratoStatus,
                  label: x.contratoStatus,
                };
              })}
              clearable
              {...form.getInputProps('listaStatus')}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />
        <SimpleGrid cols={1}>
          <Group>
            <Button
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              disabled={props.loading}
              onClick={() => {
                form.reset();
                props.clear();
              }}
            >
              Limpar
            </Button>
            <Button leftIcon={<Search size={18} />} type="submit" loading={props.loading}>
              Pesquisar
            </Button>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { Filter };
export type { FilterData };
