/* eslint-disable react/destructuring-assignment */
import { Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { Activity, Atom, Businessplan, CurrencyDollar, Id, InfoCircle, Tool } from 'tabler-icons-react';
import { ProposalStatusEnum } from '../../../../../business/proposals/status';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import { UnitOfMeasureType } from '../../../../../models/core/cache.type';
import { ProposalType } from '../../../../../models/core/proposals.type';
import { Feature } from '../../../../../utils/constants.utils';
import CommissionView from './components/ComissionView';
import FinancialView from './components/FinancialView';
import GeneralView from './components/GeneralView';
import ResidueView from './components/ResidueView';
import ServiceView from './components/ServiceView';
import StatusView from './components/StatusView';
import SummaryView from './components/SummaryView';

type DataViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  data: ProposalType;
};

export default function DataView(props: DataViewProps) {
  const tabs = [
    {
      key: 'Resumo',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <InfoCircle size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Resumo
          </Text>
        </Group>
      ),
      children: <SummaryView data={props.data} />,
      forceRender: true,
    },
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <GeneralView data={props.data} />
          </Paper>
          <Space h="lg" />

          <PageViewAudit
            idCriadoPor={props.data.idCriadoPor}
            criadoPor={props.data.criadoPor}
            dataCriacao={props.data.dataCriacao}
            idModificadoPor={props.data?.idModificadoPor || null}
            modificadoPor={props.data?.modificadoPor || null}
            dataModificacao={props.data?.dataModificacao || null}
            size="md"
          />
        </div>
      ),
      forceRender: true,
    },
    {
      key: 'Resíduos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Atom size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Resíduos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResidueView
            data={props.data.residuos}
            referenceData={props.referenceData}
            services={props.data.servicos}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Serviços',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Tool size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Serviços
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ServiceView data={props.data.servicos} proposal={props.data} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Comissoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Businessplan size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Comissões
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <CommissionView data={props.data.comissoes} />
        </Paper>
      ),
      forceRender: true,
    },
    // {
    //   key: 'Eventos',
    //   label: (
    //     <Group>
    //       <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
    //         <CalendarEvent size={18} />
    //       </ThemeIcon>
    //       <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
    //         Eventos (Simulação)
    //       </Text>
    //     </Group>
    //   ),
    //   children: (
    //     <Paper shadow="xs" p="md" withBorder>
    //       <EventView proposal={props.data} />
    //     </Paper>
    //   ),
    //   forceRender: false,
    // },
    {
      key: 'Financeiro',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <CurrencyDollar size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Financeiro
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          {props.data.codigoPropostaStatus !== ProposalStatusEnum.EmEdicao && (
            <FinancialView proposal={props.data} referenceData={props.referenceData} />
          )}
        </Paper>
      ),
      disabled: props.data.codigoPropostaStatus === ProposalStatusEnum.EmEdicao,
      forceRender: false,
    },
    {
      key: 'Status',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Activity size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Status
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <StatusView data={props.data} />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Tabs
        items={tabs}
        defaultActiveKey="Geral"
        className="custom-ant-tabs-nav-list custom-ant-tabs-nav-list-7"
      />
    </Paper>
  );
}
