import {
  ReferenciaCodigo,
  Status,
  StatusCodigo,
  TipoCodigo,
  TipoCodigo2,
} from '../../business/events/general';
import { MovementBillingType } from './cache.type';

export enum ReportItem {
  ContratoGeralTopFinanceiro = 'COG01',
  ContratoGeralTopResiduos = 'COG02',
  ContratoGeralTopServicos = 'COG03',
  ContratoIndividualEstimativas = 'COI01',
  ContratoIndividualAcondicionamentos = 'COI02',
  ContratoIndividualEquipamentos = 'COI03',
  ContratoIndividualFornecedores = 'COI04',
  ContratoIndividualMedicoes = 'COI05',
  EntidadeGeralTopFinanceiro = 'ENG01',
  EntidadeGeralTopComissoes = 'ENG02',
  EntidadeGeral = 'ENG03',
  EntidadeIndividualFaturamento = 'ENI01',
  EventoGeralCorrecoes = 'EVG01',
  EmpresaClientePotencialFinanceiro = 'EMCL01',
  EmpresaClientePotencialQuantitativo = 'EMCL02',
  EmpresaEntidadeFinanceiro = 'EMEN01',
  EmpresaEntidadeAconEquiVeiQuantitativo = 'EMEN02',
  ContratoEntidadeMovimentacoes = 'COEN01',
}

type ReportRequestType = {
  idEmpresa?: number | null;
  idContrato?: number | null;
  idEntidade?: number | null;
  // top?: number | null;
  dias?: number | null;
  dataInicial?: string | undefined;
  dataFinal?: string | undefined;
};

type ContractGeneralTopFinanceResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

type ContractGeneralTopResidueResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  idResiduo: number;
  residuo: string;
  perigoso: boolean;
  codigoIBAMA: string | null;
  codigoResiduoEstadoFisico: string;
  idPropostaResiduo: number;
  residuoCliente: string;
  residuoClientePerigoso: boolean;
  // quantidadeOrigem: number | null;
  // quantidadeOrigemIdUnidadeMedida: number | null;
  // quantidadeOrigemUnidadeMedida: string | null;
  // quantidadeOrigemUnidadeMedidaSigla: string | null;
  // quantidadeDestino: number | null;
  // quantidadeDestinoIdUnidadeMedida: number | null;
  // quantidadeDestinoUnidadeMedida: string | null;
  // quantidadeDestinoUnidadeMedidaSigla: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
  tempo: number | null;
  tempoIdUnidadeMedida: number | null;
  tempoUnidadeMedida: string | null;
  tempoUnidadeMedidaSigla: string | null;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

type ContractGeneralTopServiceResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  idServico: number;
  servico: string;
  idPropostaServico: number;

  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string | null;

  idResiduoEquipamento: number | null;
  residuoEquipamento: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  // quantidadeOrigem: number | null;
  // quantidadeOrigemIdUnidadeMedida: number | null;
  // quantidadeOrigemUnidadeMedida: string | null;
  // quantidadeOrigemUnidadeMedidaSigla: string | null;
  // quantidadeDestino: number | null;
  // quantidadeDestinoIdUnidadeMedida: number | null;
  // quantidadeDestinoUnidadeMedida: string | null;
  // quantidadeDestinoUnidadeMedidaSigla: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
  tempo: number | null;
  tempoIdUnidadeMedida: number | null;
  tempoUnidadeMedida: string | null;
  tempoUnidadeMedidaSigla: string | null;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

type ContractSingleEstimateEstimateType = {
  idPropostaResiduo: number;
  codigoResiduoEstadoFisico: string;
  residuoCliente: string;
  residuoClientePerigoso: boolean;
  idResiduo: number;
  residuo: string;
  perigoso: boolean;
  codigoIBAMA: string | null;
  estimativa: number;
  estimativaIdUnidadeMedida1: number;
  estimativaUnidadeMedida1: string;
  estimativaUnidadeMedidaSigla1: string | null;
  estimativaIdUnidadeMedida2: number;
  estimativaUnidadeMedida2: string;
  estimativaUnidadeMedidaSigla2: string | null;
};
type ContractSingleEstimateActualType = {
  idEvento: number;
  data: Date;
  idPropostaResiduo: number;
  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
};
type ContractSingleEstimateConsolidateType = ContractSingleEstimateActualType &
  ContractSingleEstimateEstimateType;

type ContractSingleEstimateType = {
  estimativas: ContractSingleEstimateEstimateType[];
  atual: ContractSingleEstimateActualType[];
  consolidado: ContractSingleEstimateConsolidateType[]; // to be calculated
};

type ContractSinglePackagingType = {
  idEvento: number;
  codigoeEventoReferencia: ReferenciaCodigo;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  data: Date;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idResiduoAcondicionamento: number;
  residuoAcondicionamento: string;
  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
};

type ContractSingleEquipmentType = {
  idEvento: number;
  codigoeEventoReferencia: ReferenciaCodigo;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  data: Date;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idResiduoEquipamento: number;
  residuoEquipamento: string;
  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
};

type ContractSingleServiceSupplierType = {
  idEvento: number;
  codigoeEventoReferencia: ReferenciaCodigo;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  data: Date;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
};

type ContractSingleMeasurementType = {
  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  data: Date;

  idResiduo: number;
  residuo: string;
  perigoso: boolean;
  codigoIBAMA: string | null;
  codigoResiduoEstadoFisico: string;
  idPropostaResiduo: number;
  residuoCliente: string;
  residuoClientePerigoso: boolean;

  idServico: number;
  servico: string;
  idPropostaServico: number;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

type EntityGeneralTopFinanceResponseType = {
  idEmpresa: number;
  empresa: string;

  idEntidade: number;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  cpf: string | null;
  nome: string | null;

  receita: number;
  despesa: number;
};

type EntityGeneralTopCommissionResponseType = {
  idEmpresa: number;
  empresa: string;

  idEntidade: number;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  cpf: string | null;
  nome: string | null;

  receita: number;
  despesa: number;
};

type EntityGeneralResponseType = {
  idEntidade: number;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  cpf: string | null;
  nome: string | null;
  webSite: string | null;
  tipos: string;
  contatos: string | null;
};

type EventGeneralCorrectionResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  total: number;
};

type CompanyClientFinancialPotentialResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  codigoEventoStatus: Status;
  eventoStatus: StatusCodigo;
  data: Date;
  faturado: boolean;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

type CompanyClientQuantityPotentialResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  codigoEventoStatus: Status;
  eventoStatus: StatusCodigo;
  data: Date;
  faturado: boolean;

  idPropostaResiduo: number | null;
  idResiduo: number | null;
  residuo: string | null;
  perigoso: boolean | null;
  codigoIBAMA: string | null;
  codigoResiduoEstadoFisico: string | null;
  residuoCliente: string | null;
  residuoClientePerigoso: boolean | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
  tempo: number | null;
  tempoIdUnidadeMedida: number | null;
  tempoUnidadeMedida: string | null;
  tempoUnidadeMedidaSigla: string | null;
};

type CompanyEntityFinancialResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  codigoEventoStatus: Status;
  eventoStatus: StatusCodigo;
  data: Date;
  faturado: boolean;

  idPropostaResiduo: number | null;
  idResiduo: number | null;
  residuo: string | null;
  perigoso: boolean | null;
  codigoIBAMA: string | null;
  codigoResiduoEstadoFisico: string | null;
  residuoCliente: string | null;
  residuoClientePerigoso: boolean | null;

  idPropostaServico: number | null;
  idServico: number | null;
  servico: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idMovimentacaoTipo: number;
  codigoMovimentacaoPadrao: string | null;
  codigoMovimentacaoGrupo: string | null;
  movimentacaoTipo: string;
  outroTipo: string | null;
  codigoMovimentacaoFaturamentoTipo: MovementBillingType;
  movimentacaoFaturamentoTipo: string;

  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string | null;
  idResiduoEquipamento: number | null;
  residuoEquipamento: string | null;
  idResiduoVeiculo: number | null;
  residuoVeiculo: string | null;
  idResiduoTratamento: number | null;
  residuoTratamento: string | null;
  idResiduoDestinoFinal: number | null;
  residuoDestinoFinal: string | null;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

type CompanyEntityPackagingEquipmentVehicleQuantityResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoReferencia: ReferenciaCodigo;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  codigoEventoStatus: Status;
  eventoStatus: StatusCodigo;
  data: Date;
  faturado: boolean;

  idPropostaResiduo: number | null;
  idResiduo: number | null;
  residuo: string | null;
  perigoso: boolean | null;
  codigoIBAMA: string | null;
  codigoResiduoEstadoFisico: string | null;
  residuoCliente: string | null;
  residuoClientePerigoso: boolean | null;

  idPropostaServico: number | null;
  idServico: number | null;
  servico: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string | null;
  idResiduoEquipamento: number | null;
  residuoEquipamento: string | null;
  idResiduoVeiculo: number | null;
  residuoVeiculo: string | null;
  veiculoCompartilhado: boolean;

  quantidade: number;
  quantidadeIdUnidadeMedida: number;
  quantidadeUnidadeMedida: string;
  quantidadeUnidadeMedidaSigla: string | null;
};

type ContractEntityFinancialResponseType = {
  idEmpresa: number;
  empresa: string;
  idContrato: number;
  idProposta: number;

  idCliente: number;
  clienteCNPJ: string | null;
  clienteRazaoSocial: string | null;
  clienteNomeFantasia: string | null;
  clienteCPF: string | null;
  clienteNome: string | null;

  idEvento: number;
  codigoEventoTipo: TipoCodigo;
  eventoTipo: TipoCodigo2;
  codigoEventoStatus: Status;
  eventoStatus: StatusCodigo;
  data: Date;
  faturado: boolean;

  idPropostaResiduo: number | null;
  idResiduo: number | null;
  residuo: string | null;
  perigoso: boolean | null;
  codigoIBAMA: string | null;
  codigoResiduoEstadoFisico: string | null;
  residuoCliente: string | null;
  residuoClientePerigoso: boolean | null;

  idPropostaServico: number | null;
  idServico: number | null;
  servico: string | null;

  idFornecedor: number;
  fornecedorCNPJ: string | null;
  fornecedorRazaoSocial: string | null;
  fornecedorNomeFantasia: string | null;
  fornecedorCPF: string | null;
  fornecedorNome: string | null;

  idEventoMedicaoMovimentacao: number;
  idMovimentacaoTipo: number;
  codigoMovimentacaoPadrao: string | null;
  codigoMovimentacaoGrupo: string | null;
  movimentacaoTipo: string;
  outroTipo: string | null;
  codigoMovimentacaoFaturamentoTipo: MovementBillingType;
  movimentacaoFaturamentoTipo: string;

  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string | null;
  idResiduoEquipamento: number | null;
  residuoEquipamento: string | null;
  idResiduoVeiculo: number | null;
  residuoVeiculo: string | null;
  idResiduoTratamento: number | null;
  residuoTratamento: string | null;
  idResiduoDestinoFinal: number | null;
  residuoDestinoFinal: string | null;

  receita: number;
  despesa: number;
  imposto: number;
  balanco: number;
};

export type {
  ReportRequestType,
  ContractGeneralTopFinanceResponseType,
  ContractGeneralTopResidueResponseType,
  ContractGeneralTopServiceResponseType,
  ContractSingleEstimateEstimateType,
  ContractSingleEstimateActualType,
  ContractSingleEstimateConsolidateType,
  ContractSingleEstimateType,
  ContractSinglePackagingType,
  ContractSingleEquipmentType,
  ContractSingleServiceSupplierType,
  ContractSingleMeasurementType,
  EntityGeneralTopFinanceResponseType,
  EntityGeneralTopCommissionResponseType,
  EntityGeneralResponseType,
  EventGeneralCorrectionResponseType,
  CompanyClientFinancialPotentialResponseType,
  CompanyClientQuantityPotentialResponseType,
  CompanyEntityFinancialResponseType,
  CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
  ContractEntityFinancialResponseType,
};
