import { call } from '../core-reference.service';
import {
  EquipmentUpdateType,
  EquipmentDeleteType,
  EquipmentInsertType,
  EquipmentSearchType,
  EquipmentSelectType,
  EquipmentType,
} from '../../models/core/equipment.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const equipmentsEndpoint = '/v1/residuo-equipamentos';

export default class EquipmentsEquipment {
  static search = async (searchProps: EquipmentSearchType): Promise<EquipmentType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: equipmentsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<EquipmentType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: EquipmentSelectType): Promise<EquipmentType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${equipmentsEndpoint}/${selectProps.idResiduoEquipamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: EquipmentUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${equipmentsEndpoint}/${updateProps.idResiduoEquipamento}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: EquipmentDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${equipmentsEndpoint}/${deleteProps.idResiduoEquipamento}`,
    };
    return call(request);
  };

  static insert = async (insertProps: EquipmentInsertType): Promise<EquipmentType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: equipmentsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
