/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Group, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { ProposalCommissionType } from '../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../utils/formatter.utils';

type ViewProps = {
  data: ProposalCommissionType[];
};

// TODO: Proposals: 007 - Add this to estimates (waiting on business approval)
export default function CommissionView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Entidade',
      key: 'idEntidade',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) =>
        (a.nomeFantasia || a.razaoSocial || a.nome || '').localeCompare(
          b.nomeFantasia || b.razaoSocial || b.nome || ''
        ),
      render: (row: ProposalCommissionType) => {
        if (row.idEntidade) {
          if (row.cnpj) {
            return (
              <ProfileCardLink
                id={row.idEntidade.toString()}
                name={row.nomeFantasia || row.razaoSocial || '-'}
                nameSize="sm"
                description={row.nomeFantasia ? row.razaoSocial : row.cnpj}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idEntidade.toString()}
              name={row.nome || '-'}
              nameSize="sm"
              description={row.cpf}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Porcentagem',
      key: 'porcentagem',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        const aValue = a.porcentagem || 0;
        const bValue = b.porcentagem || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalCommissionType) => <div>{row.porcentagem ? `${row.porcentagem}%` : '-'}</div>,
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalCommissionType) => <div>{row.preco ? formatCurrency(row.preco) : '-'}</div>,
    },
    {
      title: 'Ocorrências',
      key: 'ocorrencia',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        const aValue = a.ocorrencia || 0;
        const bValue = b.ocorrencia || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalCommissionType) => <div>{row.ocorrencia || '-'}</div>,
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ProposalCommissionType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />
      ),
    },
  ];

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Comissões"
          text="Todas os comissões associadas à proposta."
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ProposalCommissionType) => item.idPropostaComissao || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => (
            <PageViewAudit
              idCriadoPor={item.idCriadoPor}
              criadoPor={item.criadoPor}
              dataCriacao={item.dataCriacao}
              idModificadoPor={item.idModificadoPor || null}
              modificadoPor={item.modificadoPor || null}
              dataModificacao={item.dataModificacao || null}
              size="sm"
            />
          ),
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
