/* eslint-disable react/destructuring-assignment */
import { AppShell } from '@mantine/core';
import { useRef, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
/* components */
import './App.less';
import Header from './components/layout/Header/Header';
import NavBar from './components/layout/Navbar/NavBar';
/* iam */
import ChangePassword from './views/iam/ChangePassword/ChangePassword';
import ForgotPassword from './views/iam/ForgotPassword/ForgotPassword';
import LogOut from './views/iam/LogOut/LogOut';
import Login from './views/iam/Login/Login';
import SignUp from './views/iam/SignUp/SignUp';
import SignUpConfirm from './views/iam/SignUpConfirm/SignUpConfirm';
/* error */
import Forbidden from './views/error/Forbidden/Forbidden';
import InternalServerError from './views/error/InternalServerError/InternalServerError';
import NotFound from './views/error/NotFound/NotFound';
/* home */
import DashboardGeneral from './views/home/Dashboard/General';
import DashboardManagerial from './views/home/Dashboard/Managerial';
/* routes */
import PrivateRoute from './routes/PrivateRoute/PrivateRoute';
/* core */
import theme from './theme';
import ContractSearch from './views/core/contracts/ContractSearch/ContractSearch';
import ContractView from './views/core/contracts/ContractView/ContractView';
import DepartmentAddEdit from './views/core/departments/DepartmentAddEdit/DepartmentAddEdit';
import DepartmentSearch from './views/core/departments/DepartmentSearch/DepartmentSearch';
import DepartmentView from './views/core/departments/DepartmentView/DepartmentView';
import DestinationAddEdit from './views/core/destinations/DestinationAddEdit/DestinationAddEdit';
import DestinationSearch from './views/core/destinations/DestinationSearch/DestinationSearch';
import DestinationView from './views/core/destinations/DestinationView/DestinationView';
import DismissalSearch from './views/core/dismissals/DismissalSearch/DismissalSearch';
import DismissalView from './views/core/dismissals/DismissalView/DismissalView';
import EntityAddEdit from './views/core/entities/EntityAddEdit/EntityAddEdit';
import EntitySearch from './views/core/entities/EntitySearch/EntitySearch';
import EntityView from './views/core/entities/EntityView/EntityView';
import EquipmentAddEdit from './views/core/equipment/EquipmentAddEdit/EquipmentAddEdit';
import EquipmentSearch from './views/core/equipment/EquipmentSearch/EquipmentSearch';
import EquipmentView from './views/core/equipment/EquipmentView/EquipmentView';
import EventSearch from './views/core/events/EventSearch/EventSearch';
import InvitationAdd from './views/core/invitations/InvitationAdd/InvitationAdd';
import InvitationSearch from './views/core/invitations/InvitationSearch/InvitationSearch';
import InvitationView from './views/core/invitations/InvitationView/InvitationView';
import PackagingAddEdit from './views/core/packaging/PackagingAddEdit/PackagingAddEdit';
import PackagingSearch from './views/core/packaging/PackagingSearch/PackagingSearch';
import PackagingView from './views/core/packaging/PackagingView/PackagingView';
import EventPrint from './views/core/events/EventPrint/EventPrint';
import ProposalAddEdit from './views/core/proposals/ProposalAddEdit/ProposalAddEdit';
import ProposalPrint from './views/core/proposals/ProposalPrint/ProposalPrint';
import ProposalSearch from './views/core/proposals/ProposalSearch/ProposalSearch';
import ProposalView from './views/core/proposals/ProposalView/ProposalView';
import ResidueAddEdit from './views/core/residues/ResidueAddEdit/ResidueAddEdit';
import ResidueSearch from './views/core/residues/ResidueSearch/ResidueSearch';
import ResidueView from './views/core/residues/ResidueView/ResidueView';
import ServiceAddEdit from './views/core/services/ServiceAddEdit/ServiceAddEdit';
import ServiceSearch from './views/core/services/ServiceSearch/ServiceSearch';
import ServiceView from './views/core/services/ServiceView/ServiceView';
import TreatmentAddEdit from './views/core/treatments/TreatmentAddEdit/TreatmentAddEdit';
import TreatmentSearch from './views/core/treatments/TreatmentSearch/TreatmentSearch';
import TreatmentView from './views/core/treatments/TreatmentView/TreatmentView';
import UserEdit from './views/core/users/UserEdit/UserEdit';
import UserSearch from './views/core/users/UserSearch/UserSearch';
import UserView from './views/core/users/UserView/UserView';
import VehicleAddEdit from './views/core/vehicles/VehicleAddEdit/VehicleAddEdit';
import VehicleSearch from './views/core/vehicles/VehicleSearch/VehicleSearch';
import VehicleView from './views/core/vehicles/VehicleView/VehicleView';
import { Permission } from './models/core/departments.type';
import ProtectedRoute from './routes/PrivateRoute/ProtectedRoute';

function Layout(props: { printPage: boolean }) {
  const [, setOpened] = useState(false);
  const refNavBar = useRef<any>();

  const handleNavBarBurguer = (status: boolean) => {
    setOpened(status);
    refNavBar.current.setOpened(status);
  };

  return (
    <PrivateRoute>
      {props.printPage ? (
        <Outlet />
      ) : (
        <AppShell
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          fixed
          padding="md"
          navbar={<NavBar ref={refNavBar} />}
          header={<Header setOpened={handleNavBarBurguer} />}
          styles={{
            main: {
              background: theme.colorScheme === 'dark' ? theme?.colors?.dark?.[8] : theme?.colors?.gray?.[0],
            },
          }}
        >
          {/* temp solution for mobile */}
          <div style={{ minWidth: 1250 }}>
            <Outlet />
          </div>
        </AppShell>
      )}
    </PrivateRoute>
  );
}

function App() {
  return (
    <Routes>
      {/* public */}
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<LogOut />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-up/confirm" element={<SignUpConfirm />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      {/* private */}
      <Route path="/" element={<Layout printPage />}>
        <Route
          path="proposals/:idProposta/print"
          element={<ProtectedRoute element={<ProposalPrint />} permission={Permission.ProposalPrint} />}
        />
        <Route
          path="events/:idEvento/print"
          element={<ProtectedRoute element={<EventPrint />} permission={Permission.EventPrint} />}
        />
      </Route>
      <Route path="/" element={<Layout printPage={false} />}>
        {/* Home */}
        <Route index element={<DashboardGeneral />} />
        <Route
          path="dashboards/managerial"
          element={<ProtectedRoute element={<DashboardManagerial />} managerial />}
        />
        <Route
          path="proposals"
          element={<ProtectedRoute element={<ProposalSearch />} permission={Permission.ProposalSearch} />}
        />
        <Route
          path="proposals/:idProposta"
          element={<ProtectedRoute element={<ProposalView />} permission={Permission.ProposalView} />}
        />
        <Route
          path="proposals/add"
          element={<ProtectedRoute element={<ProposalAddEdit />} permission={Permission.ProposalAdd} />}
        />
        <Route
          path="proposals/:idProposta/edit"
          element={<ProtectedRoute element={<ProposalAddEdit />} permission={Permission.ProposalEdit} />}
        />
        <Route
          path="contracts"
          element={<ProtectedRoute element={<ContractSearch />} permission={Permission.ContractSearch} />}
        />
        <Route
          path="contracts/:idContrato"
          element={<ProtectedRoute element={<ContractView />} permission={Permission.ContractView} />}
        />
        <Route
          path="events"
          element={<ProtectedRoute element={<EventSearch />} permission={Permission.EventSearch} />}
        />
        {/* Data Reference */}
        <Route
          path="entities"
          element={<ProtectedRoute element={<EntitySearch />} permission={Permission.EntitySearch} />}
        />
        <Route
          path="entities/:idEntidade"
          element={<ProtectedRoute element={<EntityView />} permission={Permission.EntityView} />}
        />
        <Route
          path="entities/add"
          element={<ProtectedRoute element={<EntityAddEdit />} permission={Permission.EntityAdd} />}
        />
        <Route
          path="entities/:idEntidade/edit"
          element={<ProtectedRoute element={<EntityAddEdit />} permission={Permission.EntityEdit} />}
        />
        <Route
          path="residues"
          element={<ProtectedRoute element={<ResidueSearch />} permission={Permission.ResidueSearch} />}
        />
        <Route
          path="residues/:idResiduo"
          element={<ProtectedRoute element={<ResidueView />} permission={Permission.ResidueView} />}
        />
        <Route
          path="residues/add"
          element={<ProtectedRoute element={<ResidueAddEdit />} permission={Permission.ResidueAdd} />}
        />
        <Route
          path="residues/:idResiduo/edit"
          element={<ProtectedRoute element={<ResidueAddEdit />} permission={Permission.ResidueEdit} />}
        />
        <Route
          path="services"
          element={<ProtectedRoute element={<ServiceSearch />} permission={Permission.ServiceSearch} />}
        />
        <Route
          path="services/:idServico"
          element={<ProtectedRoute element={<ServiceView />} permission={Permission.ServiceView} />}
        />
        <Route
          path="services/add"
          element={<ProtectedRoute element={<ServiceAddEdit />} permission={Permission.ServiceAdd} />}
        />
        <Route
          path="services/:idServico/edit"
          element={<ProtectedRoute element={<ServiceAddEdit />} permission={Permission.ServiceEdit} />}
        />
        <Route
          path="packaging"
          element={<ProtectedRoute element={<PackagingSearch />} permission={Permission.PackagingSearch} />}
        />
        <Route
          path="packaging/:idResiduoAcondicionamento"
          element={<ProtectedRoute element={<PackagingView />} permission={Permission.PackagingView} />}
        />
        <Route
          path="packaging/add"
          element={<ProtectedRoute element={<PackagingAddEdit />} permission={Permission.PackagingAdd} />}
        />
        <Route
          path="packaging/:idResiduoAcondicionamento/edit"
          element={<ProtectedRoute element={<PackagingAddEdit />} permission={Permission.PackagingEdit} />}
        />
        <Route
          path="destinations"
          element={
            <ProtectedRoute element={<DestinationSearch />} permission={Permission.DestinationSearch} />
          }
        />
        <Route
          path="destinations/:idResiduoDestinoFinal"
          element={<ProtectedRoute element={<DestinationView />} permission={Permission.DestinationView} />}
        />
        <Route
          path="destinations/add"
          element={<ProtectedRoute element={<DestinationAddEdit />} permission={Permission.DestinationAdd} />}
        />
        <Route
          path="destinations/:idResiduoDestinoFinal/edit"
          element={
            <ProtectedRoute element={<DestinationAddEdit />} permission={Permission.DestinationEdit} />
          }
        />
        <Route
          path="equipment"
          element={<ProtectedRoute element={<EquipmentSearch />} permission={Permission.EquipmentSearch} />}
        />
        <Route
          path="equipment/:idResiduoEquipamento"
          element={<ProtectedRoute element={<EquipmentView />} permission={Permission.EquipmentView} />}
        />
        <Route
          path="equipment/add"
          element={<ProtectedRoute element={<EquipmentAddEdit />} permission={Permission.EquipmentAdd} />}
        />
        <Route
          path="equipment/:idResiduoEquipamento/edit"
          element={<ProtectedRoute element={<EquipmentAddEdit />} permission={Permission.EquipmentEdit} />}
        />
        <Route
          path="treatments"
          element={<ProtectedRoute element={<TreatmentSearch />} permission={Permission.TreatmentSearch} />}
        />
        <Route
          path="treatments/:idResiduoTratamento"
          element={<ProtectedRoute element={<TreatmentView />} permission={Permission.TreatmentView} />}
        />
        <Route
          path="treatments/add"
          element={<ProtectedRoute element={<TreatmentAddEdit />} permission={Permission.TreatmentAdd} />}
        />
        <Route
          path="treatments/:idResiduoTratamento/edit"
          element={<ProtectedRoute element={<TreatmentAddEdit />} permission={Permission.TreatmentEdit} />}
        />
        <Route
          path="vehicles"
          element={<ProtectedRoute element={<VehicleSearch />} permission={Permission.VehicleSearch} />}
        />
        <Route
          path="vehicles/:idResiduoVeiculo"
          element={<ProtectedRoute element={<VehicleView />} permission={Permission.VehicleView} />}
        />
        <Route
          path="vehicles/add"
          element={<ProtectedRoute element={<VehicleAddEdit />} permission={Permission.VehicleAdd} />}
        />
        <Route
          path="vehicles/:idResiduoVeiculo/edit"
          element={<ProtectedRoute element={<VehicleAddEdit />} permission={Permission.VehicleEdit} />}
        />
        {/* Managerial */}
        <Route path="departments" element={<ProtectedRoute element={<DepartmentSearch />} managerial />} />
        <Route
          path="departments/:idDepartamento"
          element={<ProtectedRoute element={<DepartmentView />} managerial />}
        />
        <Route
          path="departments/add"
          element={<ProtectedRoute element={<DepartmentAddEdit />} managerial />}
        />
        <Route
          path="departments/:idDepartamento/edit"
          element={<ProtectedRoute element={<DepartmentAddEdit />} managerial />}
        />
        <Route path="dismissals" element={<ProtectedRoute element={<DismissalSearch />} managerial />} />
        <Route
          path="dismissals/:idUsuarioDesligamento"
          element={<ProtectedRoute element={<DismissalView />} managerial />}
        />
        <Route path="invitations" element={<ProtectedRoute element={<InvitationSearch />} managerial />} />
        <Route
          path="invitations/:idUsuarioConvite"
          element={<ProtectedRoute element={<InvitationView />} managerial />}
        />
        <Route path="invitations/add" element={<ProtectedRoute element={<InvitationAdd />} managerial />} />
        <Route path="users" element={<ProtectedRoute element={<UserSearch />} managerial />} />
        <Route path="users/:idUsuario" element={<UserView />} />
        <Route path="users/:idUsuario/edit" element={<UserEdit />} />
      </Route>
      {/* error */}
      <Route path="/403" element={<Forbidden />} />
      <Route path="/500" element={<InternalServerError />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
