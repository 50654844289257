/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Table } from 'antd';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Building, PlugConnectedX, Reload, TableOff } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../../../models/core/cache.type';
import { ContractEntityFinancialResponseType, ReportItem } from '../../../../../../models/core/report.type';
import reportsService from '../../../../../../services/core/report.service';
import theme from '../../../../../../theme';
import cacheUtils from '../../../../../../utils/cache.utils';
import { SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../utils/helper.utils';

type FormViewProps = {
  idContrato: number | undefined;
  idEntidade: number | undefined;
  color: string;
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  clientes: string[];
  fornecedores: string[];
  residuos: string[];
  servicos: string[];
  acondicionamentos: string[];
  equipamentos: string[];
  veiculos: string[];
  tratamentos: string[];
  destinosFinais: string[];
};

type Response = {
  filterConfig: FilterData;
  data: ContractEntityFinancialResponseType[];
  filteredData: ContractEntityFinancialResponseType[];
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
  guid: string;
};

const componentConfig = {
  title: 'Extrato',
  description: 'Lista com todas as movimentações financeiras baseado em eventos com medição definida.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.ContratoEntidadeMovimentacoes}-{ID}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    clientes: [],
    fornecedores: [],
    residuos: [],
    servicos: [],
    acondicionamentos: [],
    equipamentos: [],
    veiculos: [],
    tratamentos: [],
    destinosFinais: [],
  },
  dataConfig: {
    separator: '╗',
  },
};

const Statement = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
    guid: newGuid(),
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
  }>({
    companyData: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idContrato: props.idContrato,
      idEntidade: props.idEntidade,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.contratoEntidadeMovimentacoes(searchCriteria);
    return data;
  };

  const filterMe = (data: ContractEntityFinancialResponseType[]) => {
    const filteredData = data.filter(
      (x) =>
        // idEmpresa
        Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa)) &&
        // clientes
        ((form.values.clientes.length > 0 &&
          form.values.clientes.map((y) => Number(y)).includes(Number(x.idCliente))) ||
          form.values.clientes.length === 0) &&
        // fornecedores
        ((form.values.fornecedores.length > 0 &&
          form.values.fornecedores.map((y) => Number(y)).includes(Number(x.idFornecedor))) ||
          form.values.fornecedores.length === 0) &&
        // residuos
        ((form.values.residuos.length > 0 &&
          form.values.residuos.map((y) => Number(y)).includes(Number(x.idPropostaResiduo))) ||
          form.values.residuos.length === 0) &&
        // servicos
        ((form.values.servicos.length > 0 &&
          form.values.servicos.map((y) => Number(y)).includes(Number(x.idPropostaServico))) ||
          form.values.servicos.length === 0)
    );
    return filteredData;
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);

    return {
      filterConfig: form.values,
      data,
      filteredData,
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null, guid: newGuid() };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message, guid: newGuid() };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', (props.idEntidade || 0).toString());

    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult, guid: newGuid() });
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const clientsChanged = (filterConfig?.clientes || []).join('-') !== form.values.clientes.join('-');

    const suppliersChanged =
      (filterConfig?.fornecedores || []).join('-') !== form.values.fornecedores.join('-');

    const residuosChanged = (filterConfig?.residuos || []).join('-') !== form.values.residuos.join('-');
    const servicosChanged = (filterConfig?.servicos || []).join('-') !== form.values.servicos.join('-');
    const acondicionamentosChanged =
      (filterConfig?.acondicionamentos || []).join('-') !== form.values.acondicionamentos.join('-');
    const equipamentosChanged =
      (filterConfig?.equipamentos || []).join('-') !== form.values.equipamentos.join('-');
    const veiculosChanged = (filterConfig?.veiculos || []).join('-') !== form.values.veiculos.join('-');
    const tratamentosChanged =
      (filterConfig?.tratamentos || []).join('-') !== form.values.tratamentos.join('-');
    const destinosFinaisChanged =
      (filterConfig?.destinosFinais || []).join('-') !== form.values.destinosFinais.join('-');

    if (
      idEmpresaChanged ||
      clientsChanged ||
      suppliersChanged ||
      residuosChanged ||
      servicosChanged ||
      acondicionamentosChanged ||
      equipamentosChanged ||
      veiculosChanged ||
      tratamentosChanged ||
      destinosFinaisChanged
    ) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={props.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={3} />

        <Paper shadow="xs" p="md" withBorder>
          <Group position="apart">
            <Group spacing="md">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Select
                icon={<Building size={15} />}
                label="Empresa"
                description="Filtro: igual"
                placeholder="Todos..."
                data={referenceData.companyData.map((x) => {
                  return {
                    value: x.idEmpresa.toString(),
                    label: x.empresa,
                  };
                })}
                searchable
                clearable={currentUser.executivo}
                disabled={!currentUser.executivo}
                {...form.getInputProps('idEmpresa')}
              />
            </Group>
          </Group>
          {result.loaded && (result.response?.data || []).length > 0 && (
            <div>
              <Space h="md" />
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <MultiSelect
                    label="Clientes"
                    description="Filtro: igual"
                    placeholder="Todos..."
                    data={Array.from(
                      new Set(
                        result.response?.data.map(
                          (x) =>
                            `${x.idCliente}${componentConfig.dataConfig.separator}${
                              x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome
                            }`
                        )
                      )
                    ).map((x) => {
                      return {
                        value: x.split(componentConfig.dataConfig.separator)[0],
                        label: x.split(componentConfig.dataConfig.separator)[1],
                      };
                    })}
                    clearable
                    {...form.getInputProps('clientes')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <MultiSelect
                    label="Fornecedores"
                    description="Filtro: igual"
                    placeholder="Todos..."
                    data={Array.from(
                      new Set(
                        result.response?.data.map(
                          (x) =>
                            `${x.idFornecedor}${componentConfig.dataConfig.separator}${
                              x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome
                            }`
                        )
                      )
                    ).map((x) => {
                      return {
                        value: x.split(componentConfig.dataConfig.separator)[0],
                        label: x.split(componentConfig.dataConfig.separator)[1],
                      };
                    })}
                    clearable
                    {...form.getInputProps('fornecedores')}
                  />
                </Grid.Col>
              </Grid>

              <Space h="md" />
              <Grid columns={2}>
                <Grid.Col span={1}>
                  <MultiSelect
                    label="Resíduos"
                    description="Filtro: igual"
                    placeholder="Todos..."
                    data={Array.from(
                      new Set(
                        result.response?.data
                          .filter((x) => !!x.idPropostaResiduo)
                          .map(
                            (y) =>
                              `${y.idPropostaResiduo}${componentConfig.dataConfig.separator}${y.residuoCliente}`
                          )
                      )
                    ).map((x) => {
                      return {
                        value: x.split(componentConfig.dataConfig.separator)[0],
                        label: x.split(componentConfig.dataConfig.separator)[1],
                      };
                    })}
                    clearable
                    {...form.getInputProps('residuos')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <MultiSelect
                    label="Serviços"
                    description="Filtro: igual"
                    placeholder="Todos..."
                    data={Array.from(
                      new Set(
                        result.response?.data
                          .filter((x) => !!x.idPropostaServico)
                          .map(
                            (y) => `${y.idPropostaServico}${componentConfig.dataConfig.separator}${y.servico}`
                          )
                      )
                    ).map((x) => {
                      return {
                        value: x.split(componentConfig.dataConfig.separator)[0],
                        label: x.split(componentConfig.dataConfig.separator)[1],
                      };
                    })}
                    clearable
                    {...form.getInputProps('servicos')}
                  />
                </Grid.Col>
              </Grid>
            </div>
          )}
        </Paper>
        <Space h="md" />

        {result.error ? (
          <Stack spacing="md" align="center">
            <PlugConnectedX color="red" size={32} />
            <Text>{result.error}</Text>
          </Stack>
        ) : result.loaded ? (
          (result.response?.filteredData || []).length > 0 ? (
            <div>
              <Paper shadow="xs" p="md" withBorder>
                <Table
                  key={result.guid}
                  showSorterTooltip={false}
                  dataSource={result.response?.filteredData || []}
                  columns={[
                    // {
                    //   title: 'Empresa',
                    //   key: 'empresa',
                    //   sorter: (
                    //     a: ContractEntityFinancialResponseType,
                    //     b: ContractEntityFinancialResponseType
                    //   ) => a.empresa.localeCompare(b.empresa),
                    //   render: (row: ContractEntityFinancialResponseType) => (
                    //     <Badge variant="outline">{row.empresa}</Badge>
                    //   ),
                    // },
                    // {
                    //   title: 'Contrato #',
                    //   key: 'idContrato',
                    //   sorter: (
                    //     a: ContractEntityFinancialResponseType,
                    //     b: ContractEntityFinancialResponseType
                    //   ) => {
                    //     const aValue = a.idContrato;
                    //     const bValue = b.idContrato;
                    //     if (aValue === bValue) {
                    //       return 0;
                    //     }
                    //     return aValue > bValue ? 1 : -1;
                    //   },
                    //   render: (row: ContractEntityFinancialResponseType) => (
                    //     <ProfileCardLink
                    //       id={row.idContrato.toString()}
                    //       name="Contrato"
                    //       nameSize="sm"
                    //       description={`# ${row.idContrato}`}
                    //       descriptionSize="xs"
                    //       linkPrefix="contracts"
                    //       showLink
                    //     />
                    //   ),
                    // },
                    // {
                    //   title: 'Proposta #',
                    //   key: 'idProposta',
                    //   sorter: (
                    //     a: ContractEntityFinancialResponseType,
                    //     b: ContractEntityFinancialResponseType
                    //   ) => {
                    //     const aValue = a.idProposta;
                    //     const bValue = b.idProposta;
                    //     if (aValue === bValue) {
                    //       return 0;
                    //     }
                    //     return aValue > bValue ? 1 : -1;
                    //   },
                    //   render: (row: ContractEntityFinancialResponseType) => (
                    //     <ProfileCardLink
                    //       id={row.idProposta.toString()}
                    //       name="Proposta"
                    //       nameSize="sm"
                    //       description={`# ${row.idProposta}`}
                    //       descriptionSize="xs"
                    //       linkPrefix="proposals"
                    //       showLink
                    //     />
                    //   ),
                    // },
                    {
                      title: 'Evento',
                      key: 'idEvento',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => {
                        const aValue = a.idEvento;
                        const bValue = b.idEvento;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: ContractEntityFinancialResponseType) => {
                        return (
                          <ProfileCardLink
                            id={row.idEvento.toString()}
                            name={`# ${row.idEvento}`}
                            nameSize="sm"
                            avatar="E"
                            description={`${row.eventoTipo} | ${row.eventoStatus}`}
                            descriptionSize="xs"
                            linkPrefix="events"
                            showLink
                          />
                        );
                      },
                    },
                    {
                      title: 'Cliente',
                      key: 'idCliente',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) =>
                        (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
                          b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
                        ),
                      render: (row: ContractEntityFinancialResponseType) => {
                        if (row.clienteCNPJ) {
                          return (
                            <ProfileCardLink
                              id={row.idCliente.toString()}
                              name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                              nameSize="sm"
                              description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                              descriptionSize="xs"
                              linkPrefix="entities"
                              showLink
                            />
                          );
                        }
                        return (
                          <ProfileCardLink
                            id={row.idCliente.toString()}
                            name={row.clienteNome || '-'}
                            nameSize="sm"
                            description={row.clienteCPF}
                            descriptionSize="xs"
                            linkPrefix="entities"
                            showLink
                          />
                        );
                      },
                    },
                    {
                      title: 'Fornecedor',
                      key: 'idFornecedor',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) =>
                        (
                          a.fornecedorNomeFantasia ||
                          a.fornecedorRazaoSocial ||
                          a.fornecedorNome ||
                          ''
                        ).localeCompare(
                          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
                        ),
                      render: (row: ContractEntityFinancialResponseType) => {
                        if (row.fornecedorCNPJ) {
                          return (
                            <ProfileCardLink
                              id={row.idFornecedor.toString()}
                              name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
                              nameSize="sm"
                              description={
                                row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ
                              }
                              descriptionSize="xs"
                              linkPrefix="entities"
                              showLink
                            />
                          );
                        }
                        return (
                          <ProfileCardLink
                            id={row.idFornecedor.toString()}
                            name={row.fornecedorNome || '-'}
                            nameSize="sm"
                            description={row.fornecedorCPF}
                            descriptionSize="xs"
                            linkPrefix="entities"
                            showLink
                          />
                        );
                      },
                    },
                    {
                      title: 'Data',
                      key: 'data',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => new Date(a.data).valueOf() - new Date(b.data).valueOf(),
                      render: (row: ContractEntityFinancialResponseType) => formatDateToString(row.data),
                    },
                    {
                      title: 'Resíduo / Serviço',
                      key: 'residuoServico',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) =>
                        (a.residuoCliente || a.servico || '-').localeCompare(
                          b.residuoCliente || b.servico || '-'
                        ),
                      render: (row: ContractEntityFinancialResponseType) => {
                        return row.residuo ? (
                          <ProfileCardLink
                            id={row.idResiduo!.toString()}
                            name={row.residuoCliente!}
                            nameSize="sm"
                            description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
                              row.residuoClientePerigoso ? 'Sim' : 'Não'
                            }`}
                            descriptionSize="xs"
                            descriptionLength={100}
                            linkPrefix="residues"
                            avatar="R"
                            showLink
                          />
                        ) : row.servico ? (
                          <ProfileCardLink
                            id={row.idServico!.toString()}
                            name={row.servico!}
                            nameSize="sm"
                            linkPrefix="services"
                            avatar="S"
                            showLink
                          />
                        ) : (
                          '-'
                        );
                      },
                    },
                    {
                      title: 'Tipo',
                      key: 'movimentacaoTipo',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => {
                        return (a.outroTipo || a.movimentacaoTipo).localeCompare(
                          b.outroTipo || b.movimentacaoTipo
                        );
                      },
                      render: (row: ContractEntityFinancialResponseType) => {
                        return (
                          <ProfileCardLink
                            id={row.idEventoMedicaoMovimentacao?.toString() || '-'}
                            name={row.outroTipo || row.movimentacaoTipo}
                            nameSize="sm"
                            description={
                              row.residuoAcondicionamento ||
                              row.residuoEquipamento ||
                              row.residuoVeiculo ||
                              row.residuoTratamento ||
                              row.residuoDestinoFinal ||
                              undefined
                            }
                            descriptionSize="xs"
                            avatar={row.outroTipo ? 'O' : undefined}
                            showLink={false}
                          />
                        );
                      },
                    },
                    {
                      title: 'Receita',
                      key: 'receita',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => {
                        const aValue = a.receita || 0;
                        const bValue = b.receita || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: ContractEntityFinancialResponseType) => (
                        <div>{formatCurrency(row.receita)}</div>
                      ),
                    },
                    {
                      title: 'Despesa',
                      key: 'despesa',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => {
                        const aValue = a.despesa || 0;
                        const bValue = b.despesa || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: ContractEntityFinancialResponseType) => (
                        <div>{formatCurrency(row.despesa)}</div>
                      ),
                    },
                    {
                      title: 'Imposto',
                      key: 'imposto',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => {
                        const aValue = a.imposto || 0;
                        const bValue = b.imposto || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: ContractEntityFinancialResponseType) => (
                        <div>{formatCurrency(row.imposto)}</div>
                      ),
                    },
                    {
                      title: 'Balanço',
                      key: 'balanco',
                      sorter: (
                        a: ContractEntityFinancialResponseType,
                        b: ContractEntityFinancialResponseType
                      ) => {
                        const aValue = a.receita - a.despesa - a.imposto;
                        const bValue = b.receita - b.despesa - b.imposto;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: ContractEntityFinancialResponseType) => {
                        const total = row.receita - row.despesa - row.imposto;
                        const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

                        return <div style={{ color }}>{formatCurrency(total)}</div>;
                      },
                    },
                    // {
                    //   title: 'Margem',
                    //   key: 'margem',
                    //   sorter: (
                    //     a: ContractEntityFinancialResponseType,
                    //     b: ContractEntityFinancialResponseType
                    //   ) => {
                    //     const aValue = a.balanco / a.receita;
                    //     const bValue = b.balanco / b.receita;
                    //     if (aValue === bValue) {
                    //       return 0;
                    //     }
                    //     return aValue > bValue ? 1 : -1;
                    //   },
                    //   render: (row: ContractEntityFinancialResponseType) => {
                    //     const total = (row.balanco / row.receita) * 100;
                    //     const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

                    //     return <div style={{ color }}>{`${total.toFixed(2)} %`}</div>;
                    //   },
                    // },
                  ]}
                  rowKey="idEventoMedicaoMovimentacao"
                  pagination={{
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                  }}
                />
              </Paper>
            </div>
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            </Paper>
          )
        ) : (
          <Paper shadow="xs" p="md" withBorder>
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Paper>
  );
});

export default Statement;
