/* eslint-disable react/destructuring-assignment */
import { Tooltip } from '@mantine/core';

type PageViewPropertyProps = {
  label: string | undefined | null;
  numberOfChars: number;
  toolTipWidth: number;
};

export default function TableCellEllipsis(props: PageViewPropertyProps) {
  if (!props.label) {
    return <div>-</div>;
  }
  if (props.label.length <= props.numberOfChars) {
    return <div>{props.label}</div>;
  }
  return (
    <div>
      <Tooltip
        withArrow
        transition="fade"
        transitionDuration={200}
        label={props.label}
        multiline
        width={props.toolTipWidth}
      >
        <div>{props.label.substring(0, props.numberOfChars - 1)}...</div>
      </Tooltip>
    </div>
  );
}
