/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { InfoCircle } from 'tabler-icons-react';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractSearchResponseType } from '../../../../../models/core/contracts.type';
import theme from '../../../../../theme';
import { formatDateToString } from '../../../../../utils/formatter.utils';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: ContractSearchResponseType[];
  confirmActionResult(item: ContractSearchResponseType, action: string, confirmed: boolean): void;
};
export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Contrato #',
      key: 'idContrato',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) => {
        const aValue = a.idContrato;
        const bValue = b.idContrato;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ContractSearchResponseType) => (
        <Link to={`./${row.idContrato}`} target="_blank">
          {row.idContrato}
        </Link>
      ),
    },
    {
      title: 'Proposta #',
      key: 'idProposta',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) => {
        const aValue = a.idProposta;
        const bValue = b.idProposta;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ContractSearchResponseType) => (
        <ProfileCardLink
          id={row.idProposta.toString()}
          name="Proposta"
          nameSize="sm"
          description={`# ${row.idProposta}`}
          descriptionSize="xs"
          linkPrefix="proposals"
          showLink
        />
      ),
    },
    {
      title: 'Empresa',
      key: 'empresa',
      dataIndex: 'empresa',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
        a.empresa.localeCompare(b.empresa),
    },
    {
      title: 'Cliente',
      key: 'idCliente',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
        (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
          b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
        ),
      render: (row: ContractSearchResponseType) => {
        if (row.clienteCNPJ) {
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
              nameSize="sm"
              description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        return (
          <ProfileCardLink
            id={row.idCliente.toString()}
            name={row.clienteNome || '-'}
            nameSize="sm"
            description={row.clienteCPF}
            descriptionSize="xs"
            linkPrefix="entities"
            showLink
          />
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
        a.contratoStatus.localeCompare(b.contratoStatus),
      render: (row: ContractSearchResponseType) => <Badge variant="outline">{row.contratoStatus}</Badge>,
    },
    {
      title: 'Data Inicial',
      key: 'dataInicial',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
        new Date(a.dataInicial).valueOf() - new Date(b.dataInicial).valueOf(),
      render: (row: ContractSearchResponseType) => formatDateToString(row.dataInicial),
    },
    {
      title: 'Data Final',
      key: 'dataFinal',
      sorter: (a: ContractSearchResponseType, b: ContractSearchResponseType) =>
        new Date(a.dataFinal).valueOf() - new Date(b.dataFinal).valueOf(),
      render: (row: ContractSearchResponseType) => {
        return (
          <Group>
            <div>{formatDateToString(row.dataFinal)}</div>
            {row.dataFinalOriginal && (
              <Tooltip
                withArrow
                transition="fade"
                transitionDuration={200}
                label={`Data Original: ${formatDateToString(row.dataFinalOriginal || new Date())}`}
              >
                <div>
                  <InfoCircle
                    style={{ marginLeft: -10, verticalAlign: 'top' }}
                    size={18}
                    color={theme?.colors?.accent?.[6]}
                  />
                </div>
              </Tooltip>
            )}
          </Group>
        );
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ContractSearchResponseType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: ContractSearchResponseType) => item.idContrato}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
