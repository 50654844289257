/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { Trash } from 'tabler-icons-react';
import { Relacao, gerenciarVeiculoCompartilhado } from '../../../../../../business/events/general';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import { EventRelationType, EventType } from '../../../../../../models/core/events.type';
import eventsService from '../../../../../../services/core/events.service';

type DataViewActionProps = {
  data: EventType | null;
  item: EventRelationType;
  confirmActionResult(items: EventRelationType[], type: Relacao, action: string, confirmed: boolean): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function RelationFormViewActions(props: DataViewActionProps) {
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });
  const [saving, setSaving] = useState(false);

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} essa relação?`}
      >
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <SimpleGrid cols={3}>
              <PageViewProperty label="#" text={props.item.idEventoReferenciado} size="sm" />
              <PageViewProperty label="Status" text={props.item.eventoReferenciadoEventoStatus} size="sm" />
              <PageViewProperty label="Tipo" text={props.item.eventoReferenciadoEventoTipo} size="sm" />
            </SimpleGrid>
            <Space h="xs" />
          </Paper>
          <Space h="lg" />

          <Group position="right">
            <Button
              color="secondary"
              // leftIcon={<ArrowBackUp size={18} />}
              onClick={() => {
                setModalData({ opened: false, action: '' });
                props.confirmActionResult(
                  [props.item],
                  props.item.codigoEventoRelacao,
                  modalData.action,
                  false
                );
              }}
            >
              Não
            </Button>
            <Button
              // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
              // leftIcon={<Check size={18} />}
              color="primary"
              onClick={async () => {
                try {
                  setSaving(true);

                  await eventsService.deleteRelation({
                    idEvento: props.item!.idEvento,
                    relacoes: [props.item!.idEventoRelacao!],
                  });

                  showNotification({
                    title: `Evento - Visualizar`,
                    message: `Relação excluída com sucesso.`,
                    color: 'green',
                  });

                  setModalData({ opened: false, action: '' });
                  props.confirmActionResult(
                    [props.item],
                    props.item.codigoEventoRelacao,
                    modalData.action,
                    true
                  );
                } catch (exception: any) {
                  showNotification({
                    title: `Evento - Visualizar`,
                    message: exception?.isBusinessException
                      ? exception.description
                      : 'Não foi possível excluir a relação do evento.',
                    color: 'red',
                  });
                } finally {
                  setSaving(false);
                }
              }}
              loading={saving}
            >
              Sim
            </Button>
          </Group>
        </div>
      </Modal>

      <Group>
        {props.item.codigoEventoRelacao === Relacao.Relacionado ||
        gerenciarVeiculoCompartilhado(props.data) ? (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                setModalData({ opened: true, action: 'excluir' });
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        ) : (
          '-'
        )}
      </Group>
    </div>
  );
}
