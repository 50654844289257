import { format } from 'date-fns';

const dateFormat = 'dd/MM/yyyy';
const timeFormat = 'HH:mm';

function fnsFormatDate(date: Date, fnsFormat: string): string {
  return format(date, fnsFormat);
}

function formatDateStringToDate(date: string): Date {
  const isoFormat = date.includes('T') ? date.split('T')[0] : date;
  const dateArray = isoFormat.split('-');
  return new Date(Number(dateArray[0]), Number(dateArray[1]) - 1, Number(dateArray[2]));
}

function formatDateToString(date: Date) {
  const isoFormat = date.toString().includes('GMT') ? date.toISOString().split('T')[0] : date.toString();
  return format(formatDateStringToDate(isoFormat), dateFormat);
}

function formatTimestampStringToDate(date: string): Date {
  const dateArray = date.split(' ')[0].split('-');
  const timeArray = date.split(' ')[1].split(':');
  return new Date(
    Number(dateArray[0]),
    Number(dateArray[1]) - 1,
    Number(dateArray[2]),
    Number(timeArray[0]),
    Number(timeArray[1]),
    Number(timeArray[2])
  );
}

function formatTimestampToString(date: Date) {
  const tempDate = formatTimestampStringToDate(date.toString());
  const tempDateFormat = fnsFormatDate(tempDate, dateFormat);
  const tempTimeFormat = fnsFormatDate(tempDate, timeFormat);
  return `${tempDateFormat} às ${tempTimeFormat}`;
}

function formatCurrency(value: number) {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

export {
  fnsFormatDate,
  formatDateStringToDate,
  formatDateToString,
  formatTimestampStringToDate,
  formatTimestampToString,
  formatCurrency,
};
