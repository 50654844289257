/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Badge, Group, Paper, Select, Space } from '@mantine/core';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { EventPackagingProvider } from '../../../../../../../contexts/core/events/EventPackaging.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { EventPackagingType } from '../../../../../../../models/core/events.type';
import { ProposalResiduePlanQuotationPackagingType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import ResiduePlanPackagingFormAddEdit from './EventPackagingFormAddEdit';
import EventPackagingQuotationFormAddEdit from './EventPackagingQuotationFormAddEdit';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type EventPackagingFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  quotations: ProposalResiduePlanQuotationPackagingType[];
  origItem: EventPackagingType | null;
  item: EventPackagingType | null;
  disabled: boolean;
};

const EventPackagingFormView = forwardRef((props: EventPackagingFormViewProps, ref) => {
  const refPackagingForm = useRef<any>();
  const refPackagingQuotationForm = useRef<any>();
  const [quotation, setQuotation] = useState<ProposalResiduePlanQuotationPackagingType | null>(
    props.item?.cotacao || null
  );
  const { eventGeneralData } = useEventGeneralContext();
  const cotacaoPrimaria = eventGeneralData?.propostaResiduoData?.planos
    ?.find((x) => x.primario)
    ?.cotacoes?.find((y) => y.primario)
    ?.acondicionamentos?.find((z) => z.idResiduoAcondicionamento === props.item?.idResiduoAcondicionamento); // not ideal

  useImperativeHandle(ref, () => ({
    validate(): any {
      try {
        const acondicionamento = refPackagingForm?.current?.validate();
        const cotacao = refPackagingQuotationForm?.current?.validate();

        if (acondicionamento === null || cotacao === null) {
          throw Error();
        }

        return {
          acondicionamento: {
            ...acondicionamento,
            cotacao: {
              ...cotacao,
            },
          },
          idEventoReferencia3: quotation?.idPropostaResiduoPlanoCotacaoAcondicionamento || null,
        };
      } catch (ex: any) {
        throw Error('Existem pendências a serem corrigidas.');
      }
    },
    clear() {
      refPackagingForm.current.clear();
      refPackagingQuotationForm.current.clear();
    },
  }));

  return (
    <EventPackagingProvider>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Acondicionamento"
          text="Detalhes do acondicionamento."
        />
        <Space h="xs" />

        <ResiduePlanPackagingFormAddEdit
          ref={refPackagingForm}
          referenceData={props.referenceData}
          origItem={props.origItem}
          item={props.item}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
        />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Fornecedor"
            text="Detalhes do fornecedor."
          />
          <Group>
            {cotacaoPrimaria && (
              <Badge variant="outline" size="md" color="orange">
                {`Preço (Cliente): ${formatCurrency(
                  calcularPreco(
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.margem,
                    cotacaoPrimaria.precoFinal,
                    cotacaoPrimaria.imposto || 0,
                    eventGeneralData.propostaResiduoData!.compra,
                    false
                  ).novoPrecoComMargem
                )} / ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.frequenciaUnidadeMedida}`}
              </Badge>
            )}
            {props.quotations.length > 0 && !props.origItem && (
              <Select
                icon={<ZoomMoney size={15} />}
                placeholder="Selecione..."
                data={props.quotations.map((x) => {
                  return {
                    value: x.idPropostaResiduoPlanoCotacaoAcondicionamento!.toString(),
                    label: `${formatCurrency(x.preco)} / ${x.frequenciaUnidadeMedida}`,
                    group: `${
                      x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome || '?'
                    }`,
                  };
                })}
                value={quotation?.idPropostaResiduoPlanoCotacaoAcondicionamento?.toString()}
                onChange={(value) => {
                  let quotationRef =
                    props.quotations.find(
                      (x) => x.idPropostaResiduoPlanoCotacaoAcondicionamento === Number(value)
                    ) || null;

                  if (quotationRef) {
                    quotationRef = {
                      ...props.item?.cotacao,
                      ...quotationRef,
                    };
                  }

                  setQuotation(quotationRef);
                }}
                searchable
                disabled={props.disabled}
              />
            )}
          </Group>
        </Group>
        <Space h="xs" />

        <EventPackagingQuotationFormAddEdit
          ref={refPackagingQuotationForm}
          key={quotation?.idPropostaResiduoPlanoCotacaoAcondicionamento || '-1'}
          referenceData={props.referenceData}
          origItem={props.origItem?.cotacao || null}
          item={quotation || null}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
        />
      </Paper>
    </EventPackagingProvider>
  );
});

export default EventPackagingFormView;
