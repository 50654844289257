/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Edit, Trash } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { EntityAddressType } from '../../../../../../../models/core/entities.type';
import theme from '../../../../../../../theme';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';

type DataViewActionProps = {
  item: EntityAddressType;
  confirmActionResult(item: EntityAddressType | null, action: string, confirmed: boolean): void;
};

export default function AddressFormViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: EntityAddressType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse endereço?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Tipo" text={item.enderecoTipo} size="sm" />
            <PageViewProperty label="Cidade / Estado" text={`${item.cidade} / ${item.estado}`} size="sm" />
            <PageViewProperty
              label="Logradouro, Número"
              text={`${item.logradouro}, ${item.numero}`}
              size="sm"
            />
            <PageViewProperty label="CEP" text={item.cep || '-'} size="sm" />
          </SimpleGrid>
          <Space h="xs" />
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
        <ActionIcon
          size="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.accent?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'editar', true);
          }}
        >
          <Edit size={15} color={theme?.colors?.accent?.[6]} />
        </ActionIcon>
      </Tooltip>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
