/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';
import qs from 'qs';
import { Buffer } from 'buffer';
import { CognitoResponseType } from '../../models/iam/cognito.type';
import { LocalStorageKey } from '../../utils/constants.utils';

const buildToken = (user: string, password: string) => {
  const token = Buffer.from(`${user}:${password}`).toString('base64');
  return `Basic ${token}`;
};

const instance = axios.create({
  method: 'post',
  baseURL: `https://${process.env.REACT_APP_GLOBAL_COGNITO_URL}`,
  headers: {
    Authorization: buildToken(
      process.env.REACT_APP_GLOBAL_COGNITO_USER_IAM!,
      process.env.REACT_APP_GLOBAL_COGNITO_PASSWORD_IAM!
    ),
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const authenticate = async (): Promise<CognitoResponseType> => {
  const { data } = await instance.post(
    '/oauth2/token',
    qs.stringify({
      grant_type: 'client_credentials',
      scope: 'iam-domain/internal',
    })
  );
  localStorage.setItem(LocalStorageKey.ApplicationAccessToken, (data as CognitoResponseType).access_token);
  return data as CognitoResponseType;
};

// eslint-disable-next-line import/prefer-default-export
export { authenticate };
