/* eslint-disable react/destructuring-assignment */
import { AlertTriangle, Calendar, Hierarchy, Mail, User } from 'tabler-icons-react';
import {
  Alert,
  Divider,
  Input,
  Paper,
  SegmentedControl,
  Select,
  SimpleGrid,
  Space,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePicker } from '@mantine/dates';
import { UserType } from '../../../../../models/core/users.type';
import { DepartmentType, Permission, PermissionDisabled } from '../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { formatDateStringToDate } from '../../../../../utils/formatter.utils';
import { Feature } from '../../../../../utils/constants.utils';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import DepartmentPermission from '../../../departments/components/DepartmentPermission';

type FormViewData = {
  nome: string;
  sobrenome: string;
  genero: string;
  dataNascimento: Date;
  idDepartamento: string;
};

type FormViewProps = {
  save(data: FormViewData): void;
  departments: DepartmentType[];
  user: UserType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const [currentUser] = useCurrentUser();

  const form = useForm<FormViewData>({
    initialValues: {
      nome: props.user?.nome || '',
      sobrenome: props.user?.sobrenome || '',
      genero: props.user?.genero?.toLocaleLowerCase() || 'f',
      dataNascimento: formatDateStringToDate(props.user?.dataNascimento.toString() || ''),
      idDepartamento: props.user?.idDepartamento?.toString() || '-1',
    },
    validate: {
      nome: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
      sobrenome: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
      idDepartamento: (value) => {
        return props.departments.find((x) => x.idDepartamento === Number(value)) ? null : 'Campo obrigatório';
      },
    },
  });

  return (
    <form id="user-edit" onSubmit={form.onSubmit(props.save)} noValidate>
      {props.user?.idDepartamento?.toString() !== form.values.idDepartamento && (
        <div>
          <Alert icon={<AlertTriangle size={16} />} title="Mudança de departamento" color="yellow">
            {`Para que as permissões sejam aplicadas, ${
              currentUser.idUsuario === props.user?.idUsuario ? 'você' : 'o usuário'
            } deverá fazer o login novamente.`}
          </Alert>
          <Space h="xl" />
        </div>
      )}
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.User.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <TextInput
            icon={<User size={15} />}
            label="Nome"
            placeholder="Digite o nome"
            maxLength={50}
            required
            {...form.getInputProps('nome')}
          />
          <TextInput
            icon={<User size={15} />}
            label="Sobrenome"
            placeholder="Digite o sobrenome"
            maxLength={50}
            required
            {...form.getInputProps('sobrenome')}
          />
          <div>
            <Input.Wrapper label="Gênero" required>
              <div />
            </Input.Wrapper>
            <SegmentedControl
              color="primary"
              data={[
                { label: 'Feminino', value: 'f' },
                { label: 'Masculino', value: 'm' },
              ]}
              {...form.getInputProps('genero')}
            />
          </div>
          <DatePicker
            icon={<Calendar size={15} />}
            locale="pt-br"
            label="Data Nascimento"
            defaultValue={new Date()}
            inputFormat="DD/MM/YYYY"
            maxDate={new Date()}
            clearable={false}
            allowFreeInput
            required
            {...form.getInputProps('dataNascimento')}
          />
        </SimpleGrid>
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.User.color} label="Conta" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <TextInput
            icon={<Mail size={15} />}
            label="E-mail"
            placeholder="Digite e-mail"
            type="email"
            maxLength={100}
            required
            disabled
            value={props.user?.email}
          />
        </SimpleGrid>
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.User.color} label="Acesso" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <Select
            icon={<Hierarchy size={15} />}
            label="Departamento"
            placeholder="Selecione..."
            data={props.departments.map((x) => {
              return {
                value: x.idDepartamento.toString(),
                label: x.departamento,
                group: x.empresa,
                disabled: !!(x.executivo && !currentUser.executivo),
              };
            })}
            searchable
            required
            disabled={!currentUser.gerencial || (props.user?.executivo && !currentUser.executivo)}
            {...form.getInputProps('idDepartamento')}
          />
          <div style={{ marginTop: -5 }}>
            <PageViewProperty
              label="Acesso Executivo?"
              text={
                props.departments.find((x) => Number(x.idDepartamento) === Number(form.values.idDepartamento))
                  ?.executivo
                  ? 'Sim'
                  : 'Não'
              }
              size="sm"
            />
          </div>
          <div style={{ marginTop: -5 }}>
            <PageViewProperty
              label="Acesso Gerencial?"
              text={
                props.departments.find((x) => Number(x.idDepartamento) === Number(form.values.idDepartamento))
                  ?.gerencial
                  ? 'Sim'
                  : 'Não'
              }
              size="sm"
            />
          </div>
          <div style={{ marginTop: -5 }}>
            <PageViewProperty
              label="Acesso de Suporte?"
              text={
                props.departments.find((x) => Number(x.idDepartamento) === Number(form.values.idDepartamento))
                  ?.suporte
                  ? 'Sim'
                  : 'Não'
              }
              size="sm"
            />
          </div>
        </SimpleGrid>
        <Space h="xs" />

        <Paper shadow="xs" p="md" withBorder>
          <DepartmentPermission
            ref={null}
            idDepartamento={Number(form.values.idDepartamento)}
            permissoes={Object.values(Permission)}
            permissoesDesabilidatas={Object.values(PermissionDisabled) as any as Permission[]}
            permissoesAtuais={
              props.departments.find((x) => x.idDepartamento === Number(form.values.idDepartamento))
                ?.permissoes ?? []
            }
            modoEdicao={false}
          />
        </Paper>
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
