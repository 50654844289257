/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import lodash from 'lodash';
import { ProposalServiceType, ProposalType } from '../../models/core/proposals.type';
import { Action } from '../../models/core/core.type';

const validarReferenciaServicoCompartilhado = (
  idResiduoAcondicionamento: number,
  idEntidadeEndereco: number,
  servico: ProposalServiceType | undefined
) => {
  return (
    !!servico &&
    servico.action !== Action.Delete &&
    servico.compartilhado &&
    servico.cotacoes.findIndex((y) => y.primario && y.action !== Action.Delete) > -1 &&
    servico.idResiduoAcondicionamento === idResiduoAcondicionamento &&
    servico.idEntidadeEndereco === idEntidadeEndereco
  );
};

const validarAnaliseFinanceira = (
  proposta: ProposalType
): { isBusinessException: boolean; message: string; list: string[] } | null => {
  if (proposta.residuos.length === 0 && proposta.servicos.length === 0) {
    return {
      isBusinessException: true,
      message: 'A proposta deve possuir ao menos um resíduo ou serviço.',
      list: [],
    };
  }

  let badItems = proposta.residuos.filter((x) => x.planos.length === 0).map((y) => y.residuoCliente);
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) resíduo(s) abaixo não possui(em) um plano:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    const valido = residuo.planos.some((x) => x.tratamento || x.destinoFinal);
    if (!valido) {
      badItems.push(residuo.residuoCliente);
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) resíduo(s) abaixo possui(em) ao menos um plano invalido:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    const primario = residuo.planos.some((x) => x.primario);
    if (!primario) {
      badItems.push(residuo.residuoCliente);
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) resíduo(s) abaixo não possui(em) um plano primário:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    if (residuo.planos.filter((x) => x.primario).length > 1) {
      badItems.push(residuo.residuoCliente);
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) resíduo(s) abaixo possui(em) mais de um plano primário:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    for (const plano of residuo.planos) {
      if (plano.cotacoes.length === 0 || plano.cotacoes.some((x) => !x.completo)) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano})`);
      }
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message:
        'O(s) plano(s) de coleta de resíduo não possui(em) cotação(ões) OU contém cotação(ões) incompletas:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    for (const plano of residuo.planos) {
      if (plano.cotacoes.every((x) => !x.primario)) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano})`);
      }
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) plano(s) de coleta de resíduo não possui(em) uma cotação primária:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    for (const plano of residuo.planos) {
      if (plano.cotacoes.filter((x) => x.primario).length > 1) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano})`);
      }
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) plano(s) de coleta de resíduo possui(em) mais de uma cotação primária:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos) {
    for (const plano of residuo.planos) {
      const planoAcondicionamentos = plano.acondicionamentos.map((x) => x.idResiduoAcondicionamento);
      const cotacaoAcondicionamentos = plano.cotacoes
        .map((x) => x.acondicionamentos)
        .flat()
        .map((y) => y.idResiduoAcondicionamento);

      if (lodash.xor(planoAcondicionamentos, cotacaoAcondicionamentos).length > 0) {
        badItems.push(
          `${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano} - Acondicionamentos)`
        );
      }

      const planoEquipamentos = plano.equipamentos.map((x) => x.idResiduoEquipamento);
      const cotacaoEquipamentos = plano.cotacoes
        .map((x) => x.equipamentos)
        .flat()
        .map((y) => y.idResiduoEquipamento);

      if (lodash.xor(planoEquipamentos, cotacaoEquipamentos).length > 0) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano} - Equipamentos)`);
      }

      const planoVeiculo = plano.veiculo ? [plano.veiculo.idResiduoVeiculo] : [];
      const cotacaoVeiculo =
        plano.cotacoes
          .filter((x) => x.veiculo)
          .map((y) => y.veiculo)
          ?.map((z) => z.idResiduoVeiculo) || [];

      if (lodash.xor(planoVeiculo, cotacaoVeiculo).length > 0) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano} - Veículo)`);
      }

      const planoTratamento = plano.tratamento ? [plano.tratamento.idResiduoTratamento] : [];
      const cotacaoTratamento =
        plano.cotacoes
          .filter((x) => x.tratamento)
          .map((y) => y.tratamento)
          ?.map((z) => z.idResiduoTratamento) || [];

      if (lodash.xor(planoTratamento, cotacaoTratamento).length > 0) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano} - Tratamento)`);
      }

      const planoDestinoFinal = plano.destinoFinal ? [plano.destinoFinal.idResiduoDestinoFinal] : [];
      const cotacaoDestinoFinal =
        plano.cotacoes
          .filter((x) => x.destinoFinal)
          .map((y) => y.destinoFinal)
          ?.map((z) => z.idResiduoDestinoFinal) || [];

      if (lodash.xor(planoDestinoFinal, cotacaoDestinoFinal).length > 0) {
        badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano} - Destino Final)`);
      }
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) plano(s) de coleta de resíduo possui(em) cotação(ões) de referência inválida:',
      list: badItems,
    };
  }

  badItems = [];
  for (const residuo of proposta.residuos.filter((x) => x.compra)) {
    for (const plano of residuo.planos) {
      for (const cotacao of plano.cotacoes) {
        if (!cotacao.destinoFinal?.receita) {
          badItems.push(`${residuo.residuoCliente} (Plano: #${plano.idPropostaResiduoPlano})`);
        }
      }
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) resíduo(s) de "Compra" precisa(m) ter um destino-final de "Receita":',
      list: badItems,
    };
  }

  badItems = [];
  for (const servico of proposta.servicos) {
    if (servico.cotacoes.every((x) => !x.primario)) {
      badItems.push(`${servico.servico}`);
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) serviço(s) não possui(em) uma cotação primária:',
      list: badItems,
    };
  }

  badItems = [];
  for (const servico of proposta.servicos) {
    if (servico.cotacoes.filter((x) => x.primario).length > 1) {
      badItems.push(`${servico.servico}`);
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) serviço(s) possui(em) mais de uma cotação primária:',
      list: badItems,
    };
  }

  badItems = [];
  for (const servico of proposta.servicos) {
    const cotacaoAcondicionamentos = [
      servico.idResiduoAcondicionamento
        ? `A${servico.idResiduoAcondicionamento}`
        : servico.idResiduoEquipamento
        ? `E${servico.idResiduoEquipamento}`
        : 'F0',
    ];
    const planoAcondicionamentos = servico.cotacoes.map((x) =>
      x.idResiduoAcondicionamento
        ? `A${x.idResiduoAcondicionamento}`
        : x.idResiduoEquipamento
        ? `E${x.idResiduoEquipamento}`
        : 'F0'
    );

    if (lodash.xor(planoAcondicionamentos, cotacaoAcondicionamentos).length > 0) {
      badItems.push(`${servico.servico}`);
    }
  }
  if (badItems.length > 0) {
    return {
      isBusinessException: true,
      message: 'O(s) serviço(s) possui(em) cotação(ões) de referência inválida:',
      list: badItems,
    };
  }

  return null;
};

const validarRevisao = (
  proposta: ProposalType
): { isBusinessException: boolean; message: string; list: string[] } | null => {
  let precificacaoPendente = false;
  const residuoCotacoes = proposta.residuos
    .map((x) => x.planos)
    .flat()
    .filter((y) => y.primario)
    .map((z) => z.cotacoes)
    .flat()
    .filter((xx) => xx.primario);

  // acondicionamentos
  if (
    residuoCotacoes
      .map((x) => x.acondicionamentos)
      .flat()
      .some((y) => y.imposto === null || y.imposto === undefined)
  ) {
    precificacaoPendente = true;
  }

  // equipamentos
  if (
    residuoCotacoes
      .map((x) => x.equipamentos)
      .flat()
      .some((y) => y.imposto === null || y.imposto === undefined)
  ) {
    precificacaoPendente = true;
  }

  // veiculos
  if (
    residuoCotacoes
      .filter((x) => x.veiculo)
      .map((y) => y.veiculo)
      .flat()
      .some((z) => z.imposto === null || z.imposto === undefined)
  ) {
    precificacaoPendente = true;
  }

  // tratamentos
  if (
    residuoCotacoes
      .filter((x) => x.tratamento)
      .map((y) => y.tratamento)
      .flat()
      .some((z) => z.imposto === null || z.imposto === undefined)
  ) {
    precificacaoPendente = true;
  }

  // destinos-finais
  if (
    residuoCotacoes
      .filter((x) => x.destinoFinal)
      .map((y) => y.destinoFinal)
      .flat()
      .some((z) => z.imposto === null || z.imposto === undefined)
  ) {
    precificacaoPendente = true;
  }

  // servicos
  if (
    proposta.servicos
      .map((x) => x.cotacoes)
      .flat()
      .filter((y) => y.primario)
      .some((z) => z.imposto === null || z.imposto === undefined)
  ) {
    precificacaoPendente = true;
  }

  if (precificacaoPendente) {
    return {
      isBusinessException: true,
      message: 'Favor definir a precificação da proposta.',
      list: [],
    };
  }

  return null;
};

export { validarReferenciaServicoCompartilhado, validarAnaliseFinanceira, validarRevisao };
