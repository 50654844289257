/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Grid, Group, SimpleGrid, Space, Text, Tooltip } from '@mantine/core';
import { Link } from 'react-router-dom';
import { InfoCircle } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractType } from '../../../../../../models/core/contracts.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import theme from '../../../../../../theme';

type DataViewProps = {
  data: ContractType;
};

export default function GeneralView(props: DataViewProps) {
  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Contract.color}
          label="Geral"
          text="Informações gerais da contrato como cliente, fechamento, datas, documentação, etc."
        />
      </Group>
      <Space h="xs" />
      <SimpleGrid cols={props.data.idContratoPai ? 7 : 6}>
        <PageViewProperty label="Contrato" text={props.data.idContrato} size="md" />
        {props.data.idContratoPai && (
          <PageViewProperty
            label="Contrato de Origem"
            text={
              <Link to={`/contracts/${props.data.idContratoPai}`} target="_blank">
                {props.data.idContratoPai}
              </Link>
            }
            size="md"
          />
        )}
        {/* <PageViewProperty
          label="Proposta"
          text={
            <Link to={`/proposals/${props.data.idProposta}`} target="_blank">
              {props.data.idProposta}
            </Link>
          }
          size="md"
        /> */}
        <PageViewProperty
          label="Status"
          text={<Badge variant="outline">{props.data.contratoStatus}</Badge>}
          size="md"
        />
        <PageViewProperty label="Empresa Responsável" text={props.data.empresa} size="md" />
        <PageViewProperty
          label="Fechamento"
          text={<Badge variant="outline">{props.data.fechamento}</Badge>}
          size="md"
        />
        <PageViewProperty label="Data Inicial" text={formatDateToString(props.data.dataInicial)} size="md" />
        <PageViewProperty
          label="Data Final"
          text={
            <Group>
              <Text size="md">{formatDateToString(props.data.dataFinal)}</Text>
              {props.data.dataFinalOriginal && (
                <Tooltip
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label={`Data Original: ${formatDateToString(props.data.dataFinalOriginal || new Date())}`}
                >
                  <div>
                    <InfoCircle
                      style={{ marginLeft: -10, verticalAlign: 'top' }}
                      size={18}
                      color={theme?.colors?.accent?.[6]}
                    />
                  </div>
                </Tooltip>
              )}
            </Group>
          }
          size="md"
        />
      </SimpleGrid>
      <Divider my="sm" variant="dotted" />

      <Grid columns={6}>
        <Grid.Col span={2}>
          {props.data.clienteCNPJ ? (
            <PageViewProperty
              label="Cliente"
              text={
                <ProfileCardLink
                  id={props.data.idCliente.toString()}
                  name={props.data.clienteNomeFantasia || props.data.clienteRazaoSocial || '-'}
                  nameSize="md"
                  description={
                    props.data.clienteNomeFantasia ? props.data.clienteRazaoSocial : props.data.clienteCNPJ
                  }
                  descriptionSize="sm"
                  linkPrefix="entities"
                  showLink
                />
              }
              size="md"
            />
          ) : (
            <PageViewProperty
              label="Cliente"
              text={
                <ProfileCardLink
                  id={props.data.idCliente.toString()}
                  name={props.data.clienteNome || '-'}
                  nameSize="md"
                  description={props.data.clienteCPF}
                  descriptionSize="sm"
                  linkPrefix="entities"
                  showLink
                />
              }
              size="md"
            />
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <PageViewProperty
            label="Contato"
            text={
              <ProfileCardLink
                id={props.data.idEntidadeContato.toString()}
                name={props.data.nome}
                nameSize="md"
                description={
                  props.data.telefone || props.data.celular || props.data.email || props.data.setor
                }
                descriptionSize="sm"
                showLink={false}
              />
            }
            size="md"
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}
