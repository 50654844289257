/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Paper, SimpleGrid, Space, Text } from '@mantine/core';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { UserType } from '../../../../../models/core/users.type';
import { Feature } from '../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../utils/formatter.utils';
import DepartmentPermission from '../../../departments/components/DepartmentPermission';
import { Permission, PermissionDisabled } from '../../../../../models/core/departments.type';

type DataViewProps = {
  data: UserType;
};

export default function DataView(props: DataViewProps) {
  return (
    <>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.User.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Nome" text={props.data.nome} size="md" />
          <PageViewProperty label="Sobrenome" text={props.data.sobrenome} size="md" />
          <PageViewProperty
            label="Gênero"
            text={
              props.data.genero.toLowerCase() === 'm'
                ? 'Masculino'
                : props.data.genero.toLowerCase() === 'f'
                ? 'Feminino'
                : props.data.genero
            }
            size="md"
          />
          <PageViewProperty
            label="Data Nascimento"
            text={
              props.data.dataNascimento.toString().includes('*')
                ? props.data.dataNascimento.toString()
                : formatDateToString(props.data.dataNascimento)
            }
            size="md"
          />
        </SimpleGrid>
        <Space h="xs" />
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.User.color} label="Conta" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="E-mail" text={props.data.email} size="md" />
          <PageViewProperty
            label="Status"
            text={<Badge variant="outline">{props.data.status === 'active' ? 'Ativo' : 'Inativo'}</Badge>}
            size="md"
          />
        </SimpleGrid>
        <Space h="xs" />
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.User.color} label="Acesso" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Empresa" text={props.data.empresa} size="md" />
          <PageViewProperty
            label="Departamento"
            text={
              <a href={`/departments/${props.data.idDepartamento}`} target="_blank" rel="noreferrer">
                <Text size="md">{props.data.departamento}</Text>
              </a>
            }
            size="md"
          />
          <PageViewProperty label="Acesso Executivo?" text={props.data.executivo ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty label="Acesso Gerencial?" text={props.data.gerencial ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty label="Acesso de Suporte?" text={props.data.suporte ? 'Sim' : 'Não'} size="md" />
        </SimpleGrid>
        <Space h="xs" />

        <Paper shadow="xs" p="md" withBorder>
          <DepartmentPermission
            ref={null}
            idDepartamento={props.data.idDepartamento}
            permissoes={Object.values(Permission)}
            permissoesDesabilidatas={Object.values(PermissionDisabled) as any as Permission[]}
            permissoesAtuais={props.data.permissoes}
            modoEdicao={false}
          />
        </Paper>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
      />
    </>
  );
}
