import { call } from '../core.service';
import {
  InvitationCancelType,
  InvitationDeleteType,
  InvitationInsertType,
  InvitationSearchType,
  InvitationSelectType,
  InvitationType,
} from '../../models/core/invitations.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const invitationsEndpoint = '/v1/usuario-convites';

export default class InvitationsService {
  static search = async (searchProps: InvitationSearchType): Promise<InvitationType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: invitationsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static select = async (selectProps: InvitationSelectType): Promise<InvitationType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${invitationsEndpoint}/${selectProps.idUsuarioConvite}`,
    };
    return (await call(request))?.data?.data;
  };

  static cancel = async (cancelProps: InvitationCancelType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${invitationsEndpoint}/${cancelProps.idUsuarioConvite}`,
      data: cancelProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: InvitationDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${invitationsEndpoint}/${deleteProps.idUsuarioConvite}`,
    };
    return call(request);
  };

  static insert = async (insertProps: InvitationInsertType): Promise<InvitationType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: invitationsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
