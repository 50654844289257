/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Alert,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Switch,
  Text,
  Textarea,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { AlertTriangle, CurrencyReal, Notes, Ruler2, Scale, ZoomMoney } from 'tabler-icons-react';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';
import EntityItemSearch from '../../../../../../../components/core/EntityItemSearch/EntityItemSearch';
import ToleranceForm from '../../../../../../../components/core/Tolerance/ToleranceForm';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { useEventPackagingContext } from '../../../../../../../contexts/core/events/EventPackaging.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../../../models/core/entities.type';
import { EventPackagingQuotationType } from '../../../../../../../models/core/events.type';
import { EntityItemEnum, Feature } from '../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';
import PriceMarginTaxFormAddEdit from '../../../../components/PriceMarginTaxFormAddEdit';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoAcondicionamento: number | null;
  fornecedorData: EntitySearchResponseType | null;
  idFornecedor: number | null;
  fornecedor: string;
  idEntidadeResiduoAcondicionamento: number | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: string | null;
  frequenciaIdUnidadeMedida: string | null;
  preco: number | null;

  cobrarSemUso: boolean;
  condicaoLocacao: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventPackagingQuotationType | null;
  item: EventPackagingQuotationType | null;
  disabled: boolean;
};

const EventPackagingQuotationFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const { eventGeneralData } = useEventGeneralContext();
  const cotacaoProposta = eventGeneralData?.propostaResiduoData?.planos
    ?.find((x) => x.primario)
    ?.cotacoes?.find((y) => y.primario)
    ?.acondicionamentos?.find((z) => z.idResiduoAcondicionamento === props.item?.idResiduoAcondicionamento); // not ideal

  const { eventPackagingData } = useEventPackagingContext();
  const refToleranceForm = useRef<any>();
  const refPriceMarginTaxForm = useRef<any>();

  const buildFornecedorDataFromItem = (
    item: EventPackagingQuotationType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idFornecedor),
      tipos: [],
      cnpj: item.fornecedorCNPJ || null,
      razaoSocial: item.fornecedorRazaoSocial || null,
      nomeFantasia: item.fornecedorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.fornecedorCPF || null,
      nome: item.fornecedorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoAcondicionamento: eventPackagingData.idResiduoAcondicionamento,
      fornecedorData: buildFornecedorDataFromItem(props.item),
      idFornecedor: props.item?.idFornecedor || null,
      fornecedor:
        props.item?.fornecedorNomeFantasia ||
        props.item?.fornecedorRazaoSocial ||
        props.item?.fornecedorNome ||
        '',
      idEntidadeResiduoAcondicionamento: props.item?.idEntidadeResiduoAcondicionamento || null,

      quantidade: props.item?.quantidade || 1,

      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida?.toString() ||
        props.referenceData.unitOfMeasures
          .find((x) => x.unidadeMedidaSigla === 'un')
          ?.idUnidadeMedida?.toString() ||
        null,
      frequenciaIdUnidadeMedida: props.item?.frequenciaIdUnidadeMedida?.toString() || null,
      preco: props.item?.preco === 0 ? 0 : props.item?.preco || null,

      cobrarSemUso: props.item?.cobrarSemUso || false,
      condicaoLocacao: props.item?.condicaoLocacao || '',

      observacao: props.item?.observacao || '',
    },
    validate: {
      fornecedor: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      preco: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });
  const compativel =
    cotacaoProposta?.quantidade === form.values.quantidade &&
    cotacaoProposta?.quantidadeIdUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida) &&
    cotacaoProposta?.frequenciaIdUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida);

  const tabs = [
    {
      key: 'Cotacao',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Cotação
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          {cotacaoProposta && !compativel && (
            <>
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                A quantidade/frequência cotada abaixo não é compatível com a do plano.
              </Alert>
              <Space h="xl" />
            </>
          )}

          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntityItemSearch
                label="Fornecedor"
                referenceData={props.referenceData}
                item={EntityItemEnum.Packaging}
                itemId={eventPackagingData.idResiduoAcondicionamento || -1}
                formItem={form.getInputProps('fornecedor') || undefined}
                idsToBeDisabled={[]}
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('fornecedorData', item);
                    form.setFieldValue('idFornecedor', item.idEntidade);
                    form.setFieldValue(
                      'fornecedor',
                      item.nomeFantasia || item.razaoSocial || item.nome || ''
                    );
                    form.setFieldValue(
                      'idEntidadeResiduoAcondicionamento',
                      (item as any).idEntidadeResiduoAcondicionamento
                    );
                  }
                }}
                disabled={props.disabled || !eventPackagingData.idResiduoAcondicionamento}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={3}>
              <Input.Wrapper label="Quantidade/Frequência" required>
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                <NumberInput
                  style={{ width: 125 }}
                  placeholder="Quantidade"
                  min={1}
                  defaultValue={1}
                  step={1}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  required
                  {...form.getInputProps('quantidade')}
                  disabled={props.disabled}
                />
                <Select
                  icon={<Ruler2 size={15} />}
                  style={{ width: 200 }}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  disabled
                  required
                  {...form.getInputProps('quantidadeIdUnidadeMedida')}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida')}
                  disabled={props.disabled}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Preço"
                placeholder="Preço"
                min={0}
                precision={2}
                hideControls
                {...form.getInputProps('preco')}
                required
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={3}>
            <div>
              <Input.Wrapper label="Cobrar Sem Uso?">
                <div />
              </Input.Wrapper>
              <Switch
                {...form.getInputProps('cobrarSemUso', { type: 'checkbox' })}
                disabled={props.disabled}
              />
            </div>
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Condição da Locação"
              placeholder="Condição da Locação"
              autosize
              maxLength={500}
              {...form.getInputProps('condicaoLocacao')}
              disabled={props.disabled}
            />
          </SimpleGrid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <PriceMarginTaxFormAddEdit
                ref={refPriceMarginTaxForm}
                preco={form.values.preco || 0}
                margem={props.item?.margem}
                precoFinal={props.item?.precoFinal}
                imposto={props.item?.imposto}
                compra={eventGeneralData.propostaResiduoData!.compra}
                receita={false}
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o a cotação do acondicionamento"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
              disabled={props.disabled}
            />
          </SimpleGrid>
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tolerancias',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Tolerâncias
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ToleranceForm
            ref={refToleranceForm}
            refId={props.item?.idEventoAcondicionamentoCotacao}
            title="Tolerâncias"
            description="Tolerâncias para esta cotação."
            color={Feature.Home.Event.color}
            disabled={props.disabled}
            origData={props.origItem?.tolerancias || []}
            data={props.item?.tolerancias || []}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  const isModified = (priceMarginTax: any): boolean => {
    const origData = `${props.origItem?.idFornecedor || ''}
    |${props.origItem?.idEntidadeResiduoAcondicionamento || ''}
    |${props.origItem?.quantidade || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida || ''}
    |${Number(props.origItem?.preco) || ''}
    |${Number(props.origItem?.margem) || ''}
    |${Number(props.origItem?.precoFinal) || ''}
    |${Number(props.origItem?.imposto) || ''}
    |${props.origItem?.cobrarSemUso || ''}
    |${props.origItem?.condicaoLocacao || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idFornecedor || ''}
    |${form.values.idEntidadeResiduoAcondicionamento || ''}
    |${form.values.quantidade || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.frequenciaIdUnidadeMedida || ''}
    |${Number(form.values.preco) || ''}
    |${Number(priceMarginTax.margem) || ''}
    |${Number(priceMarginTax.precoFinal) || ''}
    |${Number(priceMarginTax.imposto) || ''}
    |${form.values.cobrarSemUso || ''}
    |${form.values.condicaoLocacao || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): EventPackagingQuotationType | null {
      const priceMarginTax = refPriceMarginTaxForm?.current?.validate() || {};
      if (form.validate().hasErrors || priceMarginTax === null) {
        return null;
      }

      let { values } = form;
      if (props.origItem) {
        if (isModified(priceMarginTax)) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
            quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
            frequenciaIdUnidadeMedida: form.values.frequenciaIdUnidadeMedida?.toString() || '',
          };
        }
      }

      const formItem: EventPackagingQuotationType = {
        ...values,
        ...priceMarginTax,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idEventoAcondicionamento: props.item?.idEventoAcondicionamento || -1,
        idEventoAcondicionamentoCotacao: props.item?.idEventoAcondicionamentoCotacao || -1,
      };

      formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
      formItem.quantidadeUnidadeMedida =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
        )?.unidadeMedida || '';

      formItem.frequenciaIdUnidadeMedida = Number(formItem.frequenciaIdUnidadeMedida);
      formItem.frequenciaUnidadeMedida =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida)
        )?.unidadeMedida || '';

      formItem.preco = Number(formItem.preco);

      formItem.idFornecedor = Number(form.values.fornecedorData?.idEntidade);
      formItem.fornecedorCNPJ = form.values.fornecedorData?.cnpj || null;
      formItem.fornecedorRazaoSocial = form.values.fornecedorData?.razaoSocial || null;
      formItem.fornecedorNomeFantasia = form.values.fornecedorData?.nomeFantasia || null;
      formItem.fornecedorCPF = form.values.fornecedorData?.cpf || null;
      formItem.fornecedorNome = form.values.fornecedorData?.nome || null;

      formItem.condicaoLocacao = formItem.condicaoLocacao?.trim() || null;
      formItem.observacao = formItem.observacao?.trim() || null;
      formItem.tolerancias = refToleranceForm?.current?.validate() || [];

      delete (formItem as any).fornecedorData;

      // define valores da analise financeira
      if (cotacaoProposta) {
        formItem.precoReferencia = calcularPreco(
          cotacaoProposta.preco,
          cotacaoProposta.preco,
          cotacaoProposta.margem,
          cotacaoProposta.precoFinal,
          cotacaoProposta.imposto || 0,
          eventGeneralData.propostaResiduoData!.compra,
          false
        ).novoPrecoComMargem;
        formItem.precoReferenciaCompativel = compativel;
      } else {
        formItem.precoReferencia = null;
        formItem.precoReferenciaCompativel = false;
      }

      return formItem;
    },
  }));

  useEffect(() => {
    if (!eventPackagingData.idResiduoAcondicionamento) {
      return;
    }

    if (eventPackagingData.idResiduoAcondicionamento && !form.values.idResiduoAcondicionamento) {
      form.values.idResiduoAcondicionamento = eventPackagingData.idResiduoAcondicionamento;
    }

    if (eventPackagingData.idResiduoAcondicionamento !== form.values.idResiduoAcondicionamento) {
      form.values.idResiduoAcondicionamento = null;
      form.values.fornecedorData = null;
      form.values.idFornecedor = null;
      form.values.fornecedor = '';
      form.values.idEntidadeResiduoAcondicionamento = null;
      form.validateField('fornecedor');
    }
  }, [form.values, eventPackagingData, form]);

  return (
    <form id="event-packaging-quotation" noValidate>
      <Tabs items={tabs} defaultActiveKey="Cotacao" />
    </form>
  );
});

export default EventPackagingQuotationFormAddEdit;
