import { MantineThemeOverride } from '@mantine/core';
import { ConfigProvider } from 'antd';
import { CalendarMinus } from 'tabler-icons-react';

const theme: MantineThemeOverride = {
  colorScheme: 'light',
  defaultRadius: 'sm',
  fontFamily: 'Roboto',
  primaryColor: 'primary',
  loader: 'bars',
  components: {
    NumberInput: {
      defaultProps: () => ({
        decimalSeparator: ',',
      }),
    },
    DateRangePicker: {
      defaultProps: () => ({
        style: { minWidth: 225 },
        icon: <CalendarMinus size={15} />,
        locale: 'pt-br',
        inputFormat: 'DD/MM/YYYY',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        dayStyle: (date: Date, modifiers: any) => ({
          color: modifiers.inRange || modifiers.selectedInRange || modifiers.selected ? 'white' : '#2F4A56',
        }),
      }),
    },
    Title: {
      defaultProps: () => ({
        color: 'primary',
      }),
    },
  },
  colors: {
    primary: [
      '#6D797F',
      '#616F75',
      '#56666D',
      '#4B5E66',
      '#415660',
      '#38505A',
      '#2F4A56',
      '#2E414A',
      '#2C3A40',
      '#293338',
    ],
    secondary: [
      '#9D9EA0',
      '#8E9092',
      '#808386',
      '#73777B',
      '#686C71',
      '#5E6267',
      '#54595F',
      '#4D5055',
      '#46484B',
      '#3F4143',
    ],
    text: [
      '#EBEBEB',
      '#D5D6D7',
      '#C1C2C4',
      '#AEB1B3',
      '#9DA1A4',
      '#8D9297',
      '#7F8388',
      '#73767A',
      '#686A6D',
      '#5E6062',
    ],
    accent: [
      '#F2F6F3',
      '#D7E5D9',
      '#BBD8BF',
      '#9FCFA6',
      '#81CC8C',
      '#61CE70',
      '#56BA64',
      '#51A45D',
      '#518C59',
      '#4E7954',
    ],
    dark: [
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5C5F66',
      '#373A40',
      '#2C2E33',
      '#25262B',
      '#1A1B1E',
      '#141517',
      '#101113',
    ],
    gray: [
      '#F8F9FA',
      '#F1F3F5',
      '#E9ECEF',
      '#DEE2E6',
      '#CED4DA',
      '#ADB5BD',
      '#868E96',
      '#495057',
      '#343A40',
      '#2F4A56',
    ],
  },
};

ConfigProvider.config({
  theme: {
    primaryColor: '#2F4A56',
  },
});

export default theme;
