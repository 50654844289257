import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import PageContent from '../../../../components/core/PageContent/PageContent';
import treatmentsTreatment from '../../../../services/core/treatments.service';
import { FormView, FormViewData } from './components/FormView';
import { TreatmentType } from '../../../../models/core/treatments.type';
import { Feature } from '../../../../utils/constants.utils';
import { Permission } from '../../../../models/core/departments.type';

type DataResult = {
  loading: boolean;
  treatmentData: TreatmentType | null;
};

function TreatmentAddEdit() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { idResiduoTratamento } = useParams();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: (!!idResiduoTratamento && !location?.state?.treatment) || false,
    treatmentData: location?.state?.treatment || null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { treatmentData } = dataResult;
      try {
        treatmentData = await treatmentsTreatment.select({
          idResiduoTratamento: Number(idResiduoTratamento),
        });
      } catch (error: any) {
        showNotification({
          title: `Tratamento - ${!idResiduoTratamento ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar o tratamento.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, treatmentData });
      }
    };

    if (idResiduoTratamento && dataResult.treatmentData === null) {
      fetchData();
    }
  }, [idResiduoTratamento, navigate, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      let newModel;
      if (!idResiduoTratamento) {
        newModel = await treatmentsTreatment.insert({
          ...inputFormViewData,
        });
      } else {
        await treatmentsTreatment.update({
          ...inputFormViewData,
          idResiduoTratamento: Number(idResiduoTratamento),
        });
      }
      showNotification({
        title: `Tratamento - ${!idResiduoTratamento ? 'Adicionar' : 'Editar'}`,
        message: 'Tratamento salvo com sucesso.',
        color: 'green',
      });
      navigate(`/treatments/${newModel?.idResiduoTratamento || idResiduoTratamento}`, {
        state: {
          treatment: newModel || null,
        },
      });
    } catch (error: any) {
      showNotification({
        title: `Tratamento - ${!idResiduoTratamento ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar o tratamento.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Treatment}
        title={`Tratamento - ${!idResiduoTratamento ? 'Adicionar' : 'Editar'}`}
        description={`${!idResiduoTratamento ? 'Adicione' : 'Edite'} um tratamento.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="treatment-add-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            data-permission={!idResiduoTratamento ? Permission.TreatmentAdd : Permission.TreatmentEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {idResiduoTratamento && dataResult.treatmentData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView treatment={dataResult.treatmentData} loading={saving} save={save} />
        </PageContent>
      )}
    </Card>
  );
}

export default TreatmentAddEdit;
