import { createContext, ReactNode, useContext, useState } from 'react';
import { EntityItemEnum } from '../../../utils/constants.utils';

type EventServiceData = {
  tipo: EntityItemEnum;
  quantidade: number | null;
  idServico: number | null;
  idResiduoAcondicionamento: number | null;
  idResiduoEquipamento: number | null;
  frequencia: number | null;
  frequenciaIdUnidadeMedida1: number | null;
  frequenciaIdUnidadeMedida2: number | null;
};

interface EventServiceContextProps {
  eventServiceData: EventServiceData;
  setEventServiceData(eventServiceContextData: EventServiceData): void;
}
const EventServiceContext = createContext<EventServiceContextProps>({} as EventServiceContextProps);

// eslint-disable-next-line react/function-component-definition
export const EventServiceProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventServiceData, setEventServiceData] = useState<EventServiceData>({} as EventServiceData);
  return (
    <EventServiceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventServiceData,
        setEventServiceData,
      }}
    >
      {children}
    </EventServiceContext.Provider>
  );
};

export type { EventServiceData };

export function useEventServiceContext() {
  return useContext(EventServiceContext);
}
