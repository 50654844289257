/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { DismissalType } from '../../../../models/core/dismissals.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import dismissalsService from '../../../../services/core/dismissals.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { CompanyType } from '../../../../models/core/cache.type';
import cacheUtils from '../../../../utils/cache.utils';

function DismissalSearch() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
  }>({ companyData: [] });

  const [data, setData] = useState<DismissalType[]>([]);
  const [loading, setLoading] = useState(false);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      const companyData = await cacheUtils.listCompanies();
      const tempCompanyData = JSON.parse(JSON.stringify(companyData));
      if (currentUser.executivo) {
        tempCompanyData.splice(0, 0, { ...currentUser, idEmpresa: 0 });
      }

      setFilterData({
        companyData: tempCompanyData,
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.DismissalSearch) || '[]'
      ) as DismissalType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        filter({ idEmpresa: '', nome: '', email: '' });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.DismissalSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await dismissalsService.search({
        idEmpresa: inputFilterData?.idEmpresa || null,
        nome: inputFilterData?.nome || null,
        email: inputFilterData?.email || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.DismissalSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Desligamentos',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar desligamentos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: DismissalType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);

      switch (action) {
        case 'excluir':
          await dismissalsService.delete({ idUsuarioDesligamento: item.idUsuarioDesligamento });
          setData(data.filter((x) => x.idUsuarioDesligamento !== item.idUsuarioDesligamento));
          break;
        case 'visualizar':
          navigate(`/dismissals/${item.idUsuarioDesligamento}`, {
            state: {
              dismissal: item,
            },
          });
          return;
        default:
          break;
      }

      showNotification({
        title: 'Desligamentos',
        message: `Desligamento ${action === 'cancelar' ? 'cancelado' : 'excluído'} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Desligamentos',
        message: error?.isBusinessException
          ? error.description
          : `Não foi possível ${action} o desligamento.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.Dismissals}
        title="Desligamentos"
        description="Remova pessoas da plataforma e gerencie seus desligamentos."
        buttons={
          [
            // <Button
            //   color="primary"
            //   leftIcon={<Plus size={18} />}
            //   disabled={loading}
            //   onClick={() => navigate('/dismissals/add')}
            // >
            //   Desligar
            // </Button>,
          ]
        }
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter companies={filterData.companyData} filter={filter} clear={clear} loading={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default DismissalSearch;
