import { createContext, ReactNode, useContext, useState } from 'react';

type EventTreatmentData = {
  idResiduoTratamento: number | null;
  autorizacaoAmbiental: boolean;
};

interface EventTreatmentContextProps {
  eventTreatmentData: EventTreatmentData;
  setEventTreatmentData(eventTreatmentContextData: EventTreatmentData): void;
}
const EventTreatmentContext = createContext<EventTreatmentContextProps>({} as EventTreatmentContextProps);

// eslint-disable-next-line react/function-component-definition
export const EventTreatmentProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventTreatmentData, setEventTreatmentData] = useState<EventTreatmentData>({} as EventTreatmentData);
  return (
    <EventTreatmentContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventTreatmentData,
        setEventTreatmentData,
      }}
    >
      {children}
    </EventTreatmentContext.Provider>
  );
};

export type { EventTreatmentData };

export function useEventTreatmentContext() {
  return useContext(EventTreatmentContext);
}
