/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  SegmentedControl,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { useRef } from 'react';
import { CurrencyReal, DeviceFloppy, InfoCircle, Notes, Ruler2, Scale, ZoomMoney } from 'tabler-icons-react';
import EntityAddressList from '../../../../../../../../../../../../../components/core/EntityAddressList/EntityAddressList';
import EntityItemSearch from '../../../../../../../../../../../../../components/core/EntityItemSearch/EntityItemSearch';
import PageSection from '../../../../../../../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ToleranceForm from '../../../../../../../../../../../../../components/core/Tolerance/ToleranceForm';
import {
  EntityTypeType,
  UnitOfMeasureType,
} from '../../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../../models/core/core.type';
import {
  EntityAddressType,
  EntitySearchResponseType,
} from '../../../../../../../../../../../../../models/core/entities.type';
import {
  ProposalResiduePlanDestinationType,
  ProposalResiduePlanQuotationDestinationType,
} from '../../../../../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../../../../../theme';
import { EntityItemEnum, Feature } from '../../../../../../../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  fornecedorData: EntitySearchResponseType | null;
  idFornecedor: number | null;
  fornecedor: string;
  idEntidadeResiduoDestinoFinal: number | null;

  enderecoData: EntityAddressType | null;
  idEntidadeEndereco: number | null;
  endereco: string;

  quantidade: number;
  quantidadeIdUnidadeMedida: string | null;
  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida: string | null;

  preco: number | null;
  receita: string;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanQuotationDestinationType | null;
  item: ProposalResiduePlanQuotationDestinationType | null;
  refItem: ProposalResiduePlanDestinationType;
  idPropostaResiduoPlanoCotacao: number | undefined;
  callback(
    item: ProposalResiduePlanQuotationDestinationType | null,
    refItem: ProposalResiduePlanDestinationType | null,
    action: string,
    confirmed: boolean
  ): void;
};

export default function RPQuotationDestinationFormAddEdit(props: FormViewProps) {
  const refToleranceForm = useRef<any>();

  const buildFornecedorDataFromItem = (
    item: ProposalResiduePlanQuotationDestinationType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idFornecedor),
      tipos: [],
      cnpj: item.fornecedorCNPJ || null,
      razaoSocial: item.fornecedorRazaoSocial || null,
      nomeFantasia: item.fornecedorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.fornecedorCPF || null,
      nome: item.fornecedorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const buildEnderecoDataFromItem = (
    item: ProposalResiduePlanQuotationDestinationType | null
  ): EntityAddressType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidadeEndereco: item.idEntidadeEndereco,
      idEntidade: item.idFornecedor,
      codigoEnderecoTipo: '',
      enderecoTipo: '',
      codigoEstado: item.codigoEstado,
      estado: '',
      cidade: item.cidade,
      cidadeCodigoIBGE: item.cidadeCodigoIBGE || null,
      bairro: item.bairro,
      logradouro: item.logradouro,
      numero: item.numero,
      complemento: item.complemento || null,
      referencia: item.referencia || null,
      cep: item.cep,
      latitude: item.latitude || null,
      longitude: item.longitude || null,
      contatos: [],
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || props.refItem?.id || newGuid(),

      fornecedorData: buildFornecedorDataFromItem(props.item),
      idFornecedor: props.item?.idFornecedor || null,
      fornecedor:
        props.item?.fornecedorNomeFantasia ||
        props.item?.fornecedorRazaoSocial ||
        props.item?.fornecedorNome ||
        '',
      idEntidadeResiduoDestinoFinal: props.item?.idEntidadeResiduoDestinoFinal || null,

      enderecoData: buildEnderecoDataFromItem(props.item),
      idEntidadeEndereco: props.item?.idEntidadeEndereco || null,
      endereco: props.item?.idEntidadeEndereco
        ? `${props.item.logradouro}, ${props.item.numero} | ${props.item.bairro} - ${props.item.cidade}/${props.item.codigoEstado}`
        : '',

      quantidade: props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida: props.item?.quantidadeIdUnidadeMedida?.toString() || null,
      minimoAceitavel: props.item?.minimoAceitavel || null,
      minimoAceitavelIdUnidadeMedida: props.item?.minimoAceitavelIdUnidadeMedida?.toString() || null,

      preco: props.item?.preco === 0 ? 0 : props.item?.preco || null,
      receita: props.item?.receita.toString() || false.toString(),

      observacao: props.item?.observacao || '',
    },
    validate: {
      fornecedor: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      endereco: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      minimoAceitavel: (value: number | null, values: FormViewData) => {
        if ((value === null || value === undefined) && values.minimoAceitavelIdUnidadeMedida) {
          return 'Campo obrigatório';
        }
        if (value === 0 && values.minimoAceitavelIdUnidadeMedida) {
          return 'Mínimo Aceitável deve ser maior do que zero';
        }
        return null;
      },
      minimoAceitavelIdUnidadeMedida: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.minimoAceitavel) {
          return 'Campo obrigatório';
        }
        return null;
      },
      preco: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idFornecedor || ''}
    |${props.origItem?.idEntidadeEndereco || ''}
    |${props.origItem?.idEntidadeResiduoDestinoFinal || ''}
    |${props.origItem?.quantidade || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${Number(props.origItem?.minimoAceitavel) || ''}
    |${props.origItem?.minimoAceitavelIdUnidadeMedida || ''}
    |${Number(props.origItem?.preco) || ''}
    |${props.origItem?.receita.toString() || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idFornecedor || ''}
    |${form.values.idEntidadeEndereco || ''}
    |${form.values.idEntidadeResiduoDestinoFinal || ''}
    |${form.values.quantidade || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${Number(form.values.minimoAceitavel) || ''}
    |${form.values.minimoAceitavelIdUnidadeMedida || ''}
    |${Number(form.values.preco) || ''}
    |${form.values.receita.toString() || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified() && props.origItem.idPropostaResiduoPlanoCotacaoDestinoFinal) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          minimoAceitavelIdUnidadeMedida: form.values.minimoAceitavelIdUnidadeMedida?.toString() || '',
          receita: values.receita,
        };
      }
    }

    const formItem: ProposalResiduePlanQuotationDestinationType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaResiduoPlanoCotacao: props.idPropostaResiduoPlanoCotacao || -1,
    };

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.minimoAceitavel = form.values.minimoAceitavel === 0 ? null : form.values.minimoAceitavel;
    formItem.minimoAceitavelIdUnidadeMedida = Number(form.values.minimoAceitavelIdUnidadeMedida) || null;

    formItem.preco = Number(formItem.preco);
    formItem.receita = form.values.receita === true.toString();

    formItem.idFornecedor = Number(form.values.fornecedorData?.idEntidade);
    formItem.fornecedorCNPJ = form.values.fornecedorData?.cnpj || null;
    formItem.fornecedorRazaoSocial = form.values.fornecedorData?.razaoSocial || null;
    formItem.fornecedorNomeFantasia = form.values.fornecedorData?.nomeFantasia || null;
    formItem.fornecedorCPF = form.values.fornecedorData?.cpf || null;
    formItem.fornecedorNome = form.values.fornecedorData?.nome || null;

    formItem.idEntidadeEndereco = Number(form.values.idEntidadeEndereco);
    formItem.codigoEstado = form.values.enderecoData?.codigoEstado || '';
    formItem.cidade = form.values.enderecoData?.cidade || '';
    formItem.cidadeCodigoIBGE = form.values.enderecoData?.cidadeCodigoIBGE || null;
    formItem.bairro = form.values.enderecoData?.bairro || '';
    formItem.logradouro = form.values.enderecoData?.logradouro || '';
    formItem.numero = form.values.enderecoData?.numero || '';
    formItem.complemento = form.values.enderecoData?.complemento || null;
    formItem.referencia = form.values.enderecoData?.referencia || null;
    formItem.cep = form.values.enderecoData?.cep || '';
    formItem.latitude = form.values.enderecoData?.latitude || null;
    formItem.longitude = form.values.enderecoData?.longitude || null;

    formItem.idResiduoDestinoFinal = props.refItem?.idResiduoDestinoFinal || -1;
    formItem.residuoDestinoFinal = props.refItem?.residuoDestinoFinal || '';
    formItem.residuoDestinoFinalDescricao = props.refItem?.residuoDestinoFinalDescricao || null;

    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.tolerancias = refToleranceForm?.current?.validate() || [];

    props.callback({ ...formItem, action: values.action, id: values.id }, props.refItem, 'callback', true);
  };

  const tabs = [
    {
      key: 'Cotacao',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotação
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <Grid columns={4}>
            <Grid.Col span={2}>
              <EntityItemSearch
                label="Fornecedor"
                referenceData={props.referenceData}
                item={EntityItemEnum.Destination}
                itemId={props.refItem?.idResiduoDestinoFinal || -1}
                formItem={form.getInputProps('fornecedor') || undefined}
                idsToBeDisabled={[]}
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('fornecedorData', item);
                    form.setFieldValue('idFornecedor', item.idEntidade);
                    form.setFieldValue(
                      'fornecedor',
                      item.nomeFantasia || item.razaoSocial || item.nome || ''
                    );
                    form.setFieldValue(
                      'idEntidadeResiduoDestinoFinal',
                      (item as any).idEntidadeResiduoDestinoFinal
                    );
                    form.setFieldValue('idEntidadeEndereco', -1);
                    form.setFieldValue('endereco', '');
                  }
                }}
                disabled={false}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <EntityAddressList
                label="Endereço"
                referenceData={{ idEntidade: form.values.idFornecedor! }}
                formItem={form.getInputProps('endereco')}
                idEntidadeEndereco={form.values.idEntidadeEndereco}
                endereco={form.values.endereco}
                idsToBeDisabled={[]}
                callback={async (item: EntityAddressType | null) => {
                  if (item) {
                    form.setFieldValue('enderecoData', item);
                    form.setFieldValue('idEntidadeEndereco', item.idEntidadeEndereco!);
                    form.setFieldValue(
                      'endereco',
                      `${item.logradouro}, ${item.numero} | ${item.bairro} - ${item.cidade}/${item.codigoEstado}`
                    );
                  }
                }}
                disabled={false}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={2}>
              <Stack>
                <div>
                  <Input.Wrapper label="Quantidade" required>
                    <div />
                  </Input.Wrapper>
                  <Group spacing="xs">
                    <NumberInput
                      style={{ width: 125 }}
                      placeholder="Quantidade"
                      min={0.0}
                      defaultValue={0}
                      precision={2}
                      step={0.5}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      required
                      {...form.getInputProps('quantidade')}
                    />
                    <Select
                      icon={<Ruler2 size={15} />}
                      placeholder="Selecione..."
                      data={props.referenceData.unitOfMeasures
                        .filter(
                          (x) =>
                            x.tipo === 'Massa' ||
                            x.tipo === 'Volume' ||
                            (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                        )
                        .map((x) => {
                          return {
                            value: x.idUnidadeMedida.toString(),
                            label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                            group: x.tipo,
                          };
                        })}
                      searchable
                      required
                      {...form.getInputProps('quantidadeIdUnidadeMedida')}
                    />
                  </Group>
                </div>
                <div>
                  <Input.Wrapper label="Mínimo Aceitável">
                    <div />
                  </Input.Wrapper>
                  <Group spacing="xs">
                    <NumberInput
                      style={{ width: 125 }}
                      placeholder="Mínimo Aceitável"
                      min={0}
                      precision={2}
                      step={0.5}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      {...form.getInputProps('minimoAceitavel')}
                    />
                    <Select
                      icon={<Ruler2 size={15} />}
                      placeholder="Selecione..."
                      data={props.referenceData.unitOfMeasures
                        .filter(
                          (x) =>
                            x.tipo === 'Massa' ||
                            x.tipo === 'Volume' ||
                            (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                        )
                        .map((x) => {
                          return {
                            value: x.idUnidadeMedida.toString(),
                            label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                            group: x.tipo,
                          };
                        })}
                      clearable
                      searchable
                      required
                      {...form.getInputProps('minimoAceitavelIdUnidadeMedida')}
                    />
                  </Group>
                </div>
              </Stack>
            </Grid.Col>
            <Grid.Col span={1} style={{ display: 'flex' }}>
              <Stack justify="center" align="center">
                <NumberInput
                  icon={<CurrencyReal size={15} />}
                  label="Preço"
                  placeholder="Preço"
                  min={0}
                  precision={2}
                  hideControls
                  required
                  {...form.getInputProps('preco')}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={1} style={{ display: 'flex' }}>
              <Stack justify="center" align="center">
                <div>
                  <div>
                    <Input.Wrapper label="Tipo" required>
                      <Group
                        style={{
                          display: 'inline-block',
                          marginLeft: 5,
                          marginTop: -2,
                          verticalAlign: 'bottom',
                        }}
                      >
                        <Tooltip
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label="Despesa refere-se ao fornecedor receber o valor."
                        >
                          <div>
                            <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                          </div>
                        </Tooltip>
                      </Group>
                    </Input.Wrapper>
                  </div>
                  <SegmentedControl
                    color="primary"
                    data={[
                      { label: 'Despesa', value: false.toString() },
                      { label: 'Receita', value: true.toString() },
                    ]}
                    {...form.getInputProps('receita')}
                  />
                </div>
              </Stack>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o a cotação do destino final"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tolerancias',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Tolerâncias
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ToleranceForm
            ref={refToleranceForm}
            refId={props.item?.idPropostaResiduoPlanoDestinoFinal}
            title="Tolerâncias"
            description="Tolerâncias para esta cotação."
            color={Feature.Home.Proposal.color}
            disabled={false}
            origData={props.origItem?.tolerancias || []}
            data={props.item?.tolerancias || []}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <form id="residue-plan-quotation-destination" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Cotação para:"
            text="Este é o destino final a ser cotado."
          />
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={2}>
              <PageViewProperty
                label="Destino Final"
                text={props.refItem?.residuoDestinoFinal || '-'}
                size="sm"
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <PageViewProperty
                label="Autorização Ambiental?"
                text={props.refItem?.autorizacaoAmbiental ? 'Sim' : 'Não'}
                size="sm"
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <Tabs items={tabs} defaultActiveKey="Cotacao" />
        </Paper>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="residue-plan-quotation-destination">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
