/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { Alert, Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { AlertTriangle, Check, Plus, X } from 'tabler-icons-react';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalServiceContext } from '../../../../../../../../contexts/core/proposals/ProposalService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../models/core/core.type';
import {
  ProposalServiceQuotationType,
  ProposalServiceType,
} from '../../../../../../../../models/core/proposals.type';
import { EntityItemEnum, Feature } from '../../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../../utils/helper.utils';
import ServiceQuotationFormAddEdit from './ServiceQuotationFormView/ServiceQuotationFormAddEdit';
import ServiceQuotationFormViewActions from './ServiceQuotationFormView/ServiceQuotationFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalService: ProposalServiceType | null;
};

const ServiceQuotationFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalServiceData } = useProposalServiceContext();

  const [data, setData] = useState<ProposalServiceQuotationType[]>(
    props.proposalService?.cotacoes.map((x: ProposalServiceQuotationType) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idPropostaServicoCotacao?.toString() || x.idTemporaria || newGuid(),
      };
    }) || []
  );

  const [selectedData, setSelectedData] = useState<ProposalServiceQuotationType[]>([]);
  const [quotationItem, setQuotationItem] = useState<ProposalServiceQuotationType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalServiceQuotationType> = [
    {
      title: '#',
      key: 'idPropostaServicoCotacao',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = a.idPropostaServicoCotacao;
        const bValue = b.idPropostaServicoCotacao;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceQuotationType) => <div>{row.idPropostaServicoCotacao || '-'}</div>,
    },
    {
      title: 'Serviço',
      key: 'servico',
      dataIndex: 'servico',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) =>
        a.servico.localeCompare(b.servico),
    },
    {
      title: 'Item',
      key: 'item',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aItem = a.residuoAcondicionamento || a.residuoEquipamento || '-';
        const bItem = b.residuoAcondicionamento || b.residuoEquipamento || '-';
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalServiceQuotationType) => {
        if (row.idResiduoAcondicionamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoAcondicionamento || '').toString()}
              name={row.residuoAcondicionamento || '-'}
              nameSize="sm"
              description={row.residuoAcondicionamentoDescricao}
              descriptionSize="xs"
              linkPrefix="packaging"
              showLink={false}
            />
          );
        }
        if (row.idResiduoEquipamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoEquipamento || '').toString()}
              name={row.residuoEquipamento || '-'}
              nameSize="sm"
              description={row.residuoEquipamentoDescricao}
              descriptionSize="xs"
              linkPrefix="equipment"
              showLink={false}
            />
          );
        }
        return (
          <ProfileCardLink
            id={newGuid()}
            name="Fornecedor"
            nameSize="sm"
            description={null}
            descriptionSize="xs"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Fornecedor',
      key: 'idFornecedor',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) =>
        (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
        ),
      render: (row: ProposalServiceQuotationType) => {
        if (row.fornecedorCNPJ) {
          return (
            <ProfileCardLink
              id={row.idFornecedor.toString()}
              name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
              nameSize="sm"
              description={row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink={false}
            />
          );
        }
        return (
          <ProfileCardLink
            id={row.idFornecedor.toString()}
            name={row.fornecedorNome || '-'}
            nameSize="sm"
            description={row.fornecedorCPF}
            descriptionSize="xs"
            linkPrefix="entities"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceQuotationType) => <div>{formatCurrency(row.preco)}</div>,
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      dataIndex: 'quantidade',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
    },
    {
      title: 'Frequência',
      key: 'frequencia',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = `${
          a.idResiduoAcondicionamento === null || a.idResiduoEquipamento === null ? `${a.frequencia} ` : ''
        }${a.frequenciaUnidadeMedida1} / ${a.frequenciaUnidadeMedida2}`;
        const bValue = `${
          b.idResiduoAcondicionamento === null || b.idResiduoEquipamento === null ? `${b.frequencia} ` : ''
        }${b.frequenciaUnidadeMedida1} / ${b.frequenciaUnidadeMedida2}`;

        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceQuotationType) => {
        return `${
          row.idResiduoAcondicionamento === null && row.idResiduoEquipamento === null
            ? `${row.frequencia} `
            : ''
        }${row.frequenciaUnidadeMedida1} / ${row.frequenciaUnidadeMedida2}`;
      },
    },
    {
      title: 'Primário?',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalServiceQuotationType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ServiceQuotationFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    item: ProposalServiceQuotationType | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex: number;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaServicoCotacao) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setQuotationItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      case 'primario':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        dataItem = data[dataItemIndex];
        data[dataItemIndex] = { ...dataItem, ...item };

        data.forEach((element, index) => {
          if (index !== dataItemIndex && element.primario) {
            element.primario = false;
            if (element.action === Action.Nothing) {
              element.action = Action.Modify;
            }
          }
        });
        setData(JSON.parse(JSON.stringify(data)));
        break;
      default:
        break;
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProposalServiceQuotationType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ProposalServiceQuotationType) => ({
      name: record.id || record.idPropostaServicoCotacao?.toString(),
    }),
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalServiceQuotationType[] {
      data.forEach((x: ProposalServiceQuotationType) => {
        // validate quotation against service
        if (x.idServico !== proposalServiceData.servico?.idServico) {
          throw Error(
            `Existe(m) cotação(ões) que não se enquadram no serviço: (Serviço esperado: ${proposalServiceData.servico?.servico} | Serviço atual: ${x.servico})`
          );
        }
        if (proposalServiceData.tipo === EntityItemEnum.Service) {
          if (x.idResiduoAcondicionamento || x.idResiduoEquipamento) {
            throw Error(
              `Existe(m) cotação(ões) que não se enquadram no serviço: (Tipo esperado: Fornecedor | Tipo atual: ${
                x.idResiduoAcondicionamento ? 'Acondicionamento' : 'Equipamento'
              })`
            );
          }
        } else if (proposalServiceData.tipo === EntityItemEnum.Packaging) {
          if (!x.idResiduoAcondicionamento) {
            throw Error(
              `Existe(m) cotação(ões) que não se enquadram no serviço: (Tipo esperado: Acondicionamento | Tipo atual: ${
                x.idResiduoEquipamento ? 'Equipamento' : 'Fornecedor'
              })`
            );
          } else if (
            x.idResiduoAcondicionamento !== proposalServiceData.acondicionamento?.idResiduoAcondicionamento
          ) {
            throw Error(
              `Existe(m) cotação(ões) que não se enquadram no serviço: (Acondicionamento: ${proposalServiceData.acondicionamento?.residuoAcondicionamento} | Tipo atual: ${x.residuoAcondicionamento})`
            );
          }
        } else if (proposalServiceData.tipo === EntityItemEnum.Equipment) {
          if (!x.idResiduoEquipamento) {
            throw Error(
              `Existe(m) cotação(ões) que não se enquadram no serviço: (Tipo esperado: Equipamento | Tipo atual: ${
                x.idResiduoAcondicionamento ? 'Acondicionamento' : 'Fornecedor'
              })`
            );
          } else if (x.idResiduoEquipamento !== proposalServiceData.equipamento?.idResiduoEquipamento) {
            throw Error(
              `Existe(m) cotação(ões) que não se enquadram no serviço: (Equipamento: ${proposalServiceData.equipamento?.residuoEquipamento} | Tipo atual: ${x.residuoEquipamento})`
            );
          }
        }

        // remove unnecessary data and format it
        delete (x as any).fornecedorData;
      });
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  const hasStaleQuotation = () => {
    for (const item of data) {
      if (
        !(
          proposalServiceData.servico?.idServico === item.idServico &&
          (proposalServiceData.acondicionamento?.idResiduoAcondicionamento || 0) ===
            (item.idResiduoAcondicionamento || 0) &&
          (proposalServiceData.equipamento?.idResiduoEquipamento || 0) === (item.idResiduoEquipamento || 0)
        )
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Serviço/Cotação - ${quotationItem ? 'Editar' : 'Adicionar'}`}
        size="50%"
      >
        <ServiceQuotationFormAddEdit
          referenceData={props.referenceData}
          origItem={
            quotationItem
              ? props.proposalService?.cotacoes?.find(
                  (x) =>
                    x.idPropostaServicoCotacao === quotationItem.idPropostaServicoCotacao &&
                    x.idPropostaServico !== -1
                ) || null
              : null
          }
          item={quotationItem}
          idPropostaServico={props.proposalService?.idPropostaServico}
          callback={confirmActionResult}
        />
      </Modal>
      <div>
        {hasStaleQuotation() && (
          <>
            <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
              O Serviço foi alterado! Entre em cada cotação e salve-a novamente.
            </Alert>
            <Space h="xl" />
          </>
        )}
      </div>

      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Cotações"
          text="Cotações para este serviço."
        />
        <Group>
          <Button
            color="accent"
            leftIcon={<Check size={18} />}
            onClick={() => {
              const item = selectedData[0];
              item.primario = true;
              if (item.action === Action.Nothing) {
                item.action = Action.Modify;
              }
              confirmActionResult(item, 'primario', true);
            }}
            disabled={selectedData.length === 0 || selectedData?.[0].primario}
          >
            Definir como primário
          </Button>
          <Button
            color="primary"
            leftIcon={<Plus size={18} />}
            onClick={() => {
              confirmActionResult(null, 'adicionar', true);
            }}
            disabled={
              proposalServiceData.servico === null ||
              (proposalServiceData.tipo === EntityItemEnum.Service &&
                (!proposalServiceData.frequencia ||
                  proposalServiceData.frequenciaIdUnidadeMedida1 === 0 ||
                  proposalServiceData.frequenciaIdUnidadeMedida2 === 0)) ||
              (proposalServiceData.tipo === EntityItemEnum.Packaging &&
                proposalServiceData.acondicionamento === null) ||
              (proposalServiceData.tipo === EntityItemEnum.Equipment &&
                proposalServiceData.equipamento === null)
            }
          >
            Adicionar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowSelection={{ type: 'radio', ...rowSelection }}
        rowKey={(item: ProposalServiceQuotationType) => item.id || item.idPropostaServicoCotacao || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ServiceQuotationFormView;
