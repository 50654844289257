/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { Check, DeviceFloppy, X } from 'tabler-icons-react';
import { ResidueType } from '../../../models/core/residues.type';
import residuesService from '../../../services/core/residues.service';
import { SessionStorageKey } from '../../../utils/constants.utils';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';
import { FilterData, ResidueSearchModalFilter } from './ResidueSearchModalFilter';

type ResidueFormViewProps = {
  idsToBeDisabled: number[];
  callback(item: ResidueType | null, confirmed: boolean): void;
};

export default function ResidueSearchModal(props: ResidueFormViewProps) {
  const [data, setData] = useState<ResidueType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<ResidueType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.ResidueSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await residuesService.search({
        residuo: inputFilterData?.residuo || null,
        codigoIBAMA: inputFilterData?.codigoIBAMA || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.ResidueSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Resíduo - Pesquisar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar resíduos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ResidueType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ResidueType) => ({
      disabled: props.idsToBeDisabled.includes(record.idResiduo),
      name: record.idResiduo.toString(),
    }),
  };

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.ResidueSearch) || '[]'
    ) as ResidueType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({
        residuo: '',
        codigoIBAMA: '',
      });
    }
  }, []);

  return (
    <div>
      <ResidueSearchModalFilter filter={filter} clear={clear} loading={loading} />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: 'Resíduo',
                key: 'residuo',
                sorter: (a: ResidueType, b: ResidueType) => a.residuo.localeCompare(b.residuo),
                render: (row: ResidueType) => (
                  <TableCellEllipsis label={row?.residuo} numberOfChars={135} toolTipWidth={300} />
                ),
              },
              {
                title: 'Unidade Medida',
                key: 'unidadeMedida',
                sorter: (a: ResidueType, b: ResidueType) => {
                  return (a.unidadeMedida || '').localeCompare(b.unidadeMedida || '');
                },
                render: (row: ResidueType) => (
                  <div>{row?.idUnidadeMedida ? `${row.unidadeMedida} (${row.unidadeMedidaSigla})` : '-'}</div>
                ),
              },
              {
                title: 'Código IBAMA',
                key: 'codigoIBAMA',
                sorter: (a: ResidueType, b: ResidueType) => {
                  return (a.codigoIBAMA || '').localeCompare(b.codigoIBAMA || '');
                },
                render: (row: ResidueType) => <div>{row?.codigoIBAMA || '-'}</div>,
              },
              {
                title: 'Perigoso',
                key: 'perigoso',
                dataIndex: 'perigoso',
                sorter: (a: ResidueType, b: ResidueType) =>
                  a.perigoso === b.perigoso ? 0 : a.perigoso ? -1 : 1,
                render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
              },
            ]}
            rowSelection={{ type: 'radio', ...rowSelection }}
            rowKey={(item: ResidueType) => item.idResiduo}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
