import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock } from 'tabler-icons-react';
import jwtDecode from 'jwt-decode';
import { Button, Space, Text, TextInput, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import { LocalStorageKey } from '../../../utils/constants.utils';
import SplitViewForm from '../../../components/layout/SplitViewForm/SplitViewForm';
import authService from '../../../services/iam/auth.service';
import usersService from '../../../services/core/users.service';
import cacheUtils from '../../../utils/cache.utils';
import { UserType } from '../../../models/core/users.type';
import { encrypt } from '../../../utils/helper.utils';

type FormProps = {
  email: string;
  password: string;
};

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [, setCurrentUser] = useLocalStorage<UserType>({
    key: LocalStorageKey.User,
    getInitialValueInEffect: false,
  });

  const form = useForm<FormProps>({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^\S+@\S+$/.test(value.trim())) {
          return 'Formato inválido (Ex: nome@dominio.com)';
        }
        return null;
      },
      password: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      const { IdToken } = await authService.signIn(values);
      const decoded: any = jwtDecode(IdToken);
      const data = await usersService.select({ idUsuario: decoded.sub });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      localStorage.setItem(LocalStorageKey.ApplicationVersion, process.env.REACT_APP_VERSION!);
      await cacheUtils.loadCache();

      setCurrentUser(encrypt(JSON.stringify(data)) as any);
      setLoading(false);
      navigate('/');
    } catch (error: any) {
      showNotification({
        title: 'Login',
        message: error?.isBusinessException ? error.description : 'Usuário e/ou senha inválido.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SplitViewForm title="Login">
      <form style={{ width: 450 }} onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <Space h="xl" />
        <TextInput
          icon={<Mail size={15} />}
          label="E-mail"
          placeholder="Digite seu e-mail"
          type="email"
          maxLength={100}
          required
          {...form.getInputProps('email')}
        />
        <Space h="xs" />
        <PasswordInput
          icon={<Lock size={15} />}
          label="Senha"
          placeholder="Digite sua senha"
          maxLength={50}
          required
          {...form.getInputProps('password')}
        />
        <Space h="xl" />
        <Button fullWidth type="submit" loading={loading}>
          Entrar
        </Button>
        <Space h="xl" />
        <Text size="sm" align="center">
          <span>Esqueceu a senha? </span>
          <a href="/forgot-password" rel="noreferrer">
            Clique aqui
          </a>
        </Text>
      </form>
    </SplitViewForm>
  );
}
