/* eslint-disable react/destructuring-assignment */
import { Button, Grid, Group, MultiSelect, Paper, Select, SimpleGrid, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Building, ClearAll, Search } from 'tabler-icons-react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { CompanyType, ProposalStatusType } from '../../../models/core/cache.type';

type FilterData = {
  idEmpresa: string | null;
  listaStatus: string[];
};

type FilterProps = {
  companyData: CompanyType[];
  proposalStatusData: ProposalStatusType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function ProposalSearchModalFilter(props: FilterProps) {
  const [currentUser] = useCurrentUser();

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
      listaStatus: [],
    },
  });

  const handleSubmit = async (formData: FilterData) => {
    props.filter(formData);
  };

  return (
    <form id="proposal-search" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={1}>
            <Select
              icon={<Building size={15} />}
              label="Empresa"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.companyData.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <MultiSelect
              label="Status"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.proposalStatusData.map((x) => {
                return {
                  value: x.codigoPropostaStatus,
                  label: x.propostaStatus,
                };
              })}
              clearable
              {...form.getInputProps('listaStatus')}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <SimpleGrid cols={1}>
          <Group>
            <Button
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              disabled={props.loading}
              onClick={() => {
                form.reset();
                props.clear();
              }}
            >
              Limpar
            </Button>
            <Button
              leftIcon={<Search size={18} />}
              type="button"
              form="proposal-search"
              onClick={() => handleSubmit(form.values)}
              loading={props.loading}
            >
              Pesquisar
            </Button>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { ProposalSearchModalFilter };
export type { FilterData };
