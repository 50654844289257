/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Grid, Paper, SimpleGrid, Space } from '@mantine/core';
import { Referencia, ReferenciaCodigo, TipoCodigo } from '../../../../../business/events/general';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { EventType } from '../../../../../models/core/events.type';
import { Feature } from '../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../utils/formatter.utils';

type DataViewProps = {
  data: EventType;
};

export default function GeneralView(props: DataViewProps) {
  const obterMapeamentoReferencia = () => {
    if (props.data.codigoEventoTipo === TipoCodigo.Coleta) {
      return [
        {
          label: Referencia.ContratoResiduoPlano,
          value: ReferenciaCodigo.ContratoResiduoPlano,
          group: 'Resíduo',
        },
      ];
    }

    if (
      props.data.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
      props.data.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar
    ) {
      return [
        {
          label: Referencia.ContratoResiduoAcondicionamento,
          value: ReferenciaCodigo.ContratoResiduoAcondicionamento,
          group: 'Resíduo',
        },
        {
          label: Referencia.ContratoResiduoEquipamento,
          value: ReferenciaCodigo.ContratoResiduoEquipamento,
          group: 'Resíduo',
        },
        {
          label: Referencia.ContratoServico,
          value: ReferenciaCodigo.ContratoServico,
          group: 'Serviço',
        },
      ];
    }

    return [];
  };

  const obterMapeamentoReferencia2 = () => {
    const refId = props.data.idEventoReferencia2;

    if (
      props.data.idContrato &&
      props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento
    ) {
      return (
        props.data.referencia?.contrato?.residuo?.planos
          .map((x) => x.acondicionamentos)
          .flat()
          .find((y) => y.idPropostaResiduoPlanoAcondicionamento === refId)?.residuoAcondicionamento || 'Outro'
      );
    }

    if (
      props.data.idContrato &&
      props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento
    ) {
      return (
        props.data.referencia?.contrato?.residuo?.planos
          .map((x) => x.equipamentos)
          .flat()
          .find((y) => y.idPropostaResiduoPlanoEquipamento === refId)?.residuoEquipamento || 'Outro'
      );
    }
    if (
      props.data.idContrato &&
      props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano
    ) {
      const plano = props.data.referencia?.contrato?.residuo?.planos.find(
        (x) => x.idPropostaResiduoPlano === refId
      );

      let nome = plano
        ? `${plano.veiculo?.residuoVeiculo || '-'}|${plano.tratamento?.residuoTratamento || '-'}|${
            plano.destinoFinal?.residuoDestinoFinal || '-'
          }`
        : 'Outro';
      nome = nome.replaceAll('-|', '');
      nome = nome.replaceAll('-|-', '');
      nome = nome.replaceAll('|-', '');
      nome = nome.replaceAll('|', ' | ');

      return nome;
    }

    return null;
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Geral"
          text="Informações gerais do evento como audiência, data, descrição, etc."
        />
        <Space h="xs" />

        <Grid columns={6}>
          <Grid.Col span={1}>
            <PageViewProperty label="Empresa Responsável" text={props.data.empresa} size="md" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Audiência"
              text={<Badge variant="outline">{props.data.eventoAudiencia}</Badge>}
              size="md"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Tipo"
              text={<Badge variant="outline">{props.data.eventoTipo}</Badge>}
              size="md"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Fonte"
              text={
                props.data.idContrato ? (
                  <ProfileCardLink
                    id={props.data.idContrato.toString()}
                    name="Contrato"
                    nameSize="sm"
                    description={`# ${props.data.idContrato}`}
                    descriptionSize="xs"
                    linkPrefix="contracts"
                    showLink
                  />
                ) : props.data.idEntidade ? (
                  <ProfileCardLink
                    id={props.data.idEntidade.toString()}
                    name="Entidade"
                    nameSize="sm"
                    description={`# ${props.data.idEntidade}`}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : props.data.idProposta ? (
                  <ProfileCardLink
                    id={props.data.idProposta.toString()}
                    name="Proposta"
                    nameSize="sm"
                    description={`# ${props.data.idProposta}`}
                    descriptionSize="xs"
                    linkPrefix="proposals"
                    showLink
                  />
                ) : (
                  '-'
                )
              }
              size="md"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Status"
              text={<Badge variant="outline">{props.data.eventoStatus}</Badge>}
              size="md"
            />
          </Grid.Col>
          {(props.data.codigoEventoTipo === TipoCodigo.Cobranca ||
            props.data.codigoEventoTipo === TipoCodigo.Coleta ||
            props.data.codigoEventoTipo === TipoCodigo.Comissao ||
            props.data.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
            props.data.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar) && (
            <Grid.Col span={1}>
              <PageViewProperty label="Faturado?" text={props.data.faturado ? 'Sim' : 'Não'} size="md" />
            </Grid.Col>
          )}
        </Grid>
        <Divider my="sm" variant="dotted" />

        {props.data.idContrato &&
          (props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
            props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
            props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano ||
            props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoServico) && (
            <div>
              <Grid columns={5}>
                {(props.data.codigoEventoTipo === TipoCodigo.Coleta ||
                  props.data.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
                  props.data.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar) && (
                  <Grid.Col span={1}>
                    <PageViewProperty
                      label="Referência"
                      text={
                        obterMapeamentoReferencia().find((x) => x.value === props.data.codigoEventoReferencia)
                          ?.label
                      }
                      size="md"
                    />
                  </Grid.Col>
                )}
                {(props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
                  props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
                  props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) && (
                  <Grid.Col span={2}>
                    <PageViewProperty
                      label="Resíduo"
                      text={
                        <ProfileCardLink
                          id={props.data.referencia?.contrato?.residuo?.idResiduo.toString() || '-'}
                          name={props.data.referencia?.contrato?.residuo?.residuoCliente || '?'}
                          nameSize="sm"
                          description={`Código IBAMA: ${
                            props.data.referencia?.contrato?.residuo?.codigoIBAMA || '-'
                          } | Perigoso: ${
                            props.data.referencia?.contrato?.residuo?.residuoClientePerigoso ? 'Sim' : 'Não'
                          }`}
                          descriptionSize="xs"
                          showLink={false}
                        />
                      }
                      size="md"
                    />
                  </Grid.Col>
                )}
                {props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoServico && (
                  <Grid.Col span={2}>
                    <PageViewProperty
                      label="Serviço"
                      text={
                        <ProfileCardLink
                          id={props.data.referencia?.contrato?.servico?.idServico.toString() || '-'}
                          name={props.data.referencia?.contrato?.servico?.servico || '?'}
                          nameSize="sm"
                          description={
                            props.data.referencia?.contrato?.servico?.residuoAcondicionamento ||
                            props.data.referencia?.contrato?.servico?.residuoEquipamento ||
                            'Fornecedor'
                          }
                          descriptionSize="xs"
                          showLink={false}
                        />
                      }
                      size="md"
                    />
                  </Grid.Col>
                )}
                {(props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
                  props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
                  props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) && (
                  <Grid.Col span={2}>
                    <PageViewProperty label="Plano" text={obterMapeamentoReferencia2()} size="md" />
                  </Grid.Col>
                )}
                {props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoServico && (
                  <Grid.Col span={1}>
                    <PageViewProperty
                      label="Personalizado?"
                      text={!props.data.idEventoReferencia3 ? 'Sim' : 'Não'}
                      size="md"
                    />
                  </Grid.Col>
                )}
              </Grid>
              <Divider my="sm" variant="dotted" />
            </div>
          )}

        <Grid columns={5}>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Responsável"
              text={
                props.data.idResponsavel ? (
                  <ProfileCardLink
                    id={props.data.idResponsavel.toString()}
                    name={props.data.responsavel!}
                    nameSize="sm"
                    description={null}
                    descriptionSize="xs"
                    linkPrefix="users"
                    showLink
                  />
                ) : (
                  '-'
                )
              }
              size="md"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Data" text={formatDateToString(props.data.data)} size="md" />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={1}>
          <PageViewProperty label="Descrição" text={props.data.descricao} size="md" />
        </SimpleGrid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={1}>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="md" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
        gridSize={2}
      />
    </div>
  );
}
