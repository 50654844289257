import { Group, Box } from '@mantine/core';
import useCurrentUser from '../../../../hooks/useCurrentUser';

export default function Brand() {
  const [currentUser] = useCurrentUser();

  return (
    <Box
      sx={(theme) => ({
        paddingBottom: theme.spacing.lg,
        borderBottom: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
      })}
    >
      <Group position="center">
        {currentUser.executivo ? (
          <>
            <img
              alt="KWM"
              src="https://www.kwm.eco.br/wp-content/uploads/2021/02/post-1.png"
              style={{ maxWidth: 140 }}
            />
            <img
              alt="Performa"
              src="https://performa.eco.br/wp-content/uploads/2020/12/perfoma-servicos-ambientais.png"
              style={{ maxWidth: 140 }}
            />
          </>
        ) : (
          <img
            alt={`${currentUser.empresa}`}
            src={
              currentUser.empresa === 'Performa'
                ? 'https://performa.eco.br/wp-content/uploads/2020/12/perfoma-servicos-ambientais.png'
                : 'https://www.kwm.eco.br/wp-content/uploads/2021/02/post-1.png'
            }
            style={{ maxWidth: 285 }}
          />
        )}
      </Group>
    </Box>
  );
}
