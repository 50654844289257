/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Card, Center, Loader } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, Edit, Trash, X } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { UserType } from '../../../../models/core/users.type';
import dismisslasService from '../../../../services/core/dismissals.service';
import usersService from '../../../../services/core/users.service';
import authService from '../../../../services/iam/auth.service';
import { Feature } from '../../../../utils/constants.utils';
import DataView from './components/DataView';

type Result = {
  loading: boolean;
  data: UserType | null;
};

function UserView() {
  const navigate = useNavigate();
  // const location: any = useLocation();
  const modals = useModals();
  const [currentUser] = useCurrentUser();
  const { idUsuario } = useParams();

  const [result, setResult] = useState<Result>({
    loading: true,
    data: null,
  });
  const [deleting, setDeleting] = useState(false);
  const [dismissing, setDismissing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data: UserType | null = null;
      try {
        data = await usersService.select({ idUsuario: idUsuario! });
      } catch (error: any) {
        showNotification({
          title: 'Usuário - Visualizar',
          message: error?.isBusinessException ? error.description : `Não foi possível carregar o usuário.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idUsuario, navigate, result]);

  const handleAction = async (action: string) => {
    try {
      setResult({ loading: false, data: result.data });

      switch (action) {
        case 'excluir':
          setDeleting(true);
          await authService.signOff();
          break;
        case 'desligar':
          setDismissing(true);
          await dismisslasService.insert({ idUsuario: idUsuario! });
          break;
        default:
          break;
      }
      showNotification({
        title: 'Usuário - Visualizar',
        message: `Usuário ${action === 'excluir' ? 'excluído' : 'desligado'} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Usuário - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} o usuário.`,
        color: 'red',
      });
    } finally {
      if (action === 'excluir') {
        setDeleting(false);
        navigate('/logout');
      } else {
        setDismissing(false);
        navigate('/users');
      }
    }
  };

  const showModal = (action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse usuário?`,
      size: 'sm',
      closeOnClickOutside: false,
      closeOnEscape: false,
      onConfirm: () => {
        handleAction(action);
      },
    });

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.User}
        title="Usuário - Visualizar"
        description="Visualize os detalhes de um usuário."
        buttons={[
          <Button
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={deleting || dismissing || result.loading}
          >
            Voltar
          </Button>,
          <Button
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal('excluir');
            }}
            hidden={currentUser.idUsuario !== idUsuario}
            disabled={dismissing || result.loading}
            loading={deleting}
          >
            Excluir
          </Button>,
          <Button
            color="red"
            leftIcon={<X size={18} />}
            onClick={() => {
              showModal('desligar');
            }}
            hidden={
              result.data?.status === 'inactive' ||
              currentUser.idUsuario === idUsuario ||
              !currentUser.gerencial ||
              (!currentUser.executivo && currentUser.idEmpresa !== result.data?.idEmpresa)
            }
            disabled={deleting || result.loading}
            loading={dismissing}
          >
            Desligar
          </Button>,
          <Button
            color="accent"
            leftIcon={<Edit size={18} />}
            onClick={() => {
              navigate(`/users/${result?.data?.idUsuario}/edit`);
            }}
            hidden={
              result.data?.status === 'inactive' ||
              (currentUser.idUsuario !== idUsuario && !currentUser.gerencial) ||
              (!currentUser.executivo && currentUser.idEmpresa !== result.data?.idEmpresa)
            }
            disabled={deleting || dismissing || result.loading}
          >
            Editar
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <DataView data={result.data} />
        </PageContent>
      )}
    </Card>
  );
}

export default UserView;
