/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Badge, Group, Paper, Select, Space } from '@mantine/core';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { EventEquipmentProvider } from '../../../../../../../contexts/core/events/EventEquipment.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { EventEquipmentType } from '../../../../../../../models/core/events.type';
import { ProposalResiduePlanQuotationEquipmentType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import ResiduePlanEquipmentFormAddEdit from './EventEquipmentFormAddEdit';
import EventEquipmentQuotationFormAddEdit from './EventEquipmentQuotationFormAddEdit';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type EventEquipmentFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  quotations: ProposalResiduePlanQuotationEquipmentType[];
  origItem: EventEquipmentType | null;
  item: EventEquipmentType | null;
  disabled: boolean;
};

const EventEquipmentFormView = forwardRef((props: EventEquipmentFormViewProps, ref) => {
  const refEquipmentForm = useRef<any>();
  const refEquipmentQuotationForm = useRef<any>();
  const [quotation, setQuotation] = useState<ProposalResiduePlanQuotationEquipmentType | null>(
    props.item?.cotacao || null
  );
  const { eventGeneralData } = useEventGeneralContext();
  const cotacaoPrimaria = eventGeneralData?.propostaResiduoData?.planos
    ?.find((x) => x.primario)
    ?.cotacoes?.find((y) => y.primario)
    ?.equipamentos?.find((z) => z.idResiduoEquipamento === props.item?.idResiduoEquipamento); // not ideal

  useImperativeHandle(ref, () => ({
    validate(): any {
      try {
        const equipamento = refEquipmentForm?.current?.validate();
        const cotacao = refEquipmentQuotationForm?.current?.validate();

        if (equipamento === null || cotacao === null) {
          throw Error();
        }

        return {
          equipamento: {
            ...equipamento,
            cotacao: {
              ...cotacao,
            },
          },
          idEventoReferencia3: quotation?.idPropostaResiduoPlanoCotacaoEquipamento || null,
        };
      } catch (ex: any) {
        throw Error('Existem pendências a serem corrigidas.');
      }
    },
    clear() {
      refEquipmentForm.current.clear();
      refEquipmentQuotationForm.current.clear();
    },
  }));

  return (
    <EventEquipmentProvider>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Equipamento"
          text="Detalhes do equipamento."
        />
        <Space h="xs" />

        <ResiduePlanEquipmentFormAddEdit
          ref={refEquipmentForm}
          referenceData={props.referenceData}
          origItem={props.origItem}
          item={props.item}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
        />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Fornecedor"
            text="Detalhes do fornecedor."
          />
          <Group>
            {cotacaoPrimaria && (
              <Badge variant="outline" size="md" color="orange">
                {`Preço (Cliente): ${formatCurrency(
                  calcularPreco(
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.margem,
                    cotacaoPrimaria.precoFinal,
                    cotacaoPrimaria.imposto || 0,
                    eventGeneralData.propostaResiduoData!.compra,
                    false
                  ).novoPrecoComMargem
                )} / ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.frequenciaUnidadeMedida}`}
              </Badge>
            )}
            {props.quotations.length > 0 && !props.origItem && (
              <Select
                icon={<ZoomMoney size={15} />}
                placeholder="Selecione..."
                data={props.quotations.map((x) => {
                  return {
                    value: x.idPropostaResiduoPlanoCotacaoEquipamento!.toString(),
                    label: `${formatCurrency(x.preco)} / ${x.frequenciaUnidadeMedida}`,
                    group: `${
                      x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome || '?'
                    }`,
                  };
                })}
                value={quotation?.idPropostaResiduoPlanoCotacaoEquipamento?.toString()}
                onChange={(value) => {
                  let quotationRef =
                    props.quotations.find(
                      (x) => x.idPropostaResiduoPlanoCotacaoEquipamento === Number(value)
                    ) || null;

                  if (quotationRef) {
                    quotationRef = {
                      ...props.item?.cotacao,
                      ...quotationRef,
                    };
                  }

                  setQuotation(quotationRef);
                }}
                searchable
                disabled={props.disabled}
              />
            )}
          </Group>
        </Group>
        <Space h="xs" />

        <EventEquipmentQuotationFormAddEdit
          ref={refEquipmentQuotationForm}
          key={quotation?.idPropostaResiduoPlanoCotacaoEquipamento || '-1'}
          referenceData={props.referenceData}
          origItem={props.origItem?.cotacao || null}
          item={quotation || null}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
        />
      </Paper>
    </EventEquipmentProvider>
  );
});

export default EventEquipmentFormView;
