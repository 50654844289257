/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Group, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useModals } from '@mantine/modals';
import { EntityVehicleType, EntityType } from '../../../../../../../models/core/entities.type';
import VehicleFormViewActions from './VehicleFormViewActions';
import VehicleFormAddEdit from './VehicleFormAddEdit';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewProps = {
  entity: EntityType | null;
};

const VehicleFormView = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [data, setData] = useState<EntityVehicleType[]>(
    props.entity?.residuoVeiculos.map((x) => {
      return {
        ...x,
        action: Action.Nothing,
        id: x.idEntidadeResiduoVeiculo?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Veículo',
      key: 'residuoVeiculo',
      dataIndex: 'residuoVeiculo',
      sorter: (a: EntityVehicleType, b: EntityVehicleType) =>
        a.residuoVeiculo.localeCompare(b.residuoVeiculo),
    },
    {
      title: 'Descrição',
      key: 'residuoVeiculoDescricao',
      sorter: (a: EntityVehicleType, b: EntityVehicleType) =>
        (a.residuoVeiculoDescricao || '').localeCompare(b.residuoVeiculoDescricao || ''),
      render: (row: EntityVehicleType) => <div>{row?.residuoVeiculoDescricao || '-'}</div>,
    },
    {
      title: 'Tipo',
      key: 'proprio',
      sorter: (a: EntityVehicleType, b: EntityVehicleType) =>
        a.proprio === b.proprio ? 0 : a.proprio ? -1 : 1,
      render: (row: EntityVehicleType) => <div>{row?.proprio ? 'Próprio' : 'Terceirizado'}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityVehicleType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <VehicleFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EntityVehicleType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeResiduoVeiculo) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Veículo - ${item ? 'Editar' : 'Adicionar'}`,
          size: 'xl',
          closeOnClickOutside: false,
          children: (
            <VehicleFormAddEdit
              items={data}
              origItem={
                item
                  ? props.entity?.residuoVeiculos.find(
                      (x) => x.idEntidadeResiduoVeiculo === item.idEntidadeResiduoVeiculo
                    ) || null
                  : null
              }
              item={item}
              idEntidade={props.entity?.idEntidade}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityVehicleType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setData(tempEntity.residuoVeiculos);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Veiculos" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityVehicleType) => item.id || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default VehicleFormView;
