/* eslint-disable react/destructuring-assignment */
import { Group, Paper, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import { EntityAddressType } from '../../../../../../models/core/entities.type';
import { Feature } from '../../../../../../utils/constants.utils';
import ContactView from './ContactView';

type ViewProps = {
  data: EntityAddressType[];
};

export default function AddressView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Tipo',
      key: 'enderecoTipo',
      dataIndex: 'enderecoTipo',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.enderecoTipo.localeCompare(b.enderecoTipo),
    },
    {
      title: 'Estado',
      key: 'estado',
      dataIndex: 'estado',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.estado.localeCompare(b.estado),
    },
    {
      title: 'Cidade',
      key: 'cidade',
      dataIndex: 'cidade',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.cidade.localeCompare(b.cidade),
    },
    {
      title: 'Bairro',
      key: 'bairro',
      dataIndex: 'bairro',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.bairro.localeCompare(b.bairro),
    },
    {
      title: 'Logradouro',
      key: 'logradouro',
      dataIndex: 'logradouro',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.logradouro.localeCompare(b.logradouro),
    },
    {
      title: 'Número',
      key: 'numero',
      dataIndex: 'numero',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.numero.localeCompare(b.numero),
    },
    {
      title: 'Complemento',
      key: 'complemento',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        return (a.complemento || '').localeCompare(b.complemento || '');
      },
      render: (row: EntityAddressType) => <div>{row?.complemento || '-'}</div>,
    },
    {
      title: 'Referência',
      key: 'referencia',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        return (a.referencia || '').localeCompare(b.referencia || '');
      },
      render: (row: EntityAddressType) => <div>{row?.referencia || '-'}</div>,
    },
    {
      title: 'CEP',
      key: 'cep',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        return (a.cep || '').localeCompare(b.cep || '');
      },
      render: (row: EntityAddressType) => <div>{row?.cep || '-'}</div>,
    },
    {
      title: 'Contatos',
      key: 'contatos',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        const aValue = a.contatos.length;
        const bValue = b.contatos.length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityAddressType) => <div>{row.contatos.length}</div>,
    },
  ];

  return (
    <div>
      <Group>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Endereços" text="" />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EntityAddressType) => item.idEntidadeEndereco || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <>
                <PageViewAudit
                  idCriadoPor={item.idCriadoPor}
                  criadoPor={item.criadoPor}
                  dataCriacao={item.dataCriacao}
                  idModificadoPor={item.idModificadoPor || null}
                  modificadoPor={item.modificadoPor || null}
                  dataModificacao={item.dataModificacao || null}
                  size="sm"
                />
                <Space h="md" />
                <Paper shadow="xs" p="md" withBorder>
                  <ContactView data={item.contatos} size="sm" expandable />
                </Paper>
              </>
            );
          },
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
