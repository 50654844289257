/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import { Badge, Grid, Group, Paper, Space, Stack, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { Briefcase, Check, CurrencyReal, Icon, Ruler2, Scale, Share, X } from 'tabler-icons-react';
import ProfileCardLink from '../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  ProposalResiduePlanQuotationType,
  ProposalResiduePlanType,
} from '../../../../models/core/proposals.type';
import { Feature } from '../../../../utils/constants.utils';
import { newGuid } from '../../../../utils/helper.utils';

type PageViewPropertyProps = {
  data: ProposalResiduePlanType;
  quotation: ProposalResiduePlanQuotationType | null;
};

export default function ResiduePlanQuotationSummary(props: PageViewPropertyProps) {
  const quotation = props.quotation ?? props.data.cotacoes.find((y) => y.primario)!;

  const buildSupplierData = (item: {
    idFornecedor: number;
    fornecedorCNPJ: string | null;
    fornecedorNomeFantasia: string | null;
    fornecedorRazaoSocial: string | null;

    fornecedorCPF: string | null;
    fornecedorNome: string | null;
  }) => {
    if (item.fornecedorCNPJ) {
      return (
        <ProfileCardLink
          id={item.idFornecedor.toString()}
          name={item.fornecedorNomeFantasia || item.fornecedorRazaoSocial || '-'}
          nameSize="sm"
          description={item.fornecedorNomeFantasia ? item.fornecedorRazaoSocial : item.fornecedorCNPJ}
          descriptionSize="xs"
          linkPrefix="entities"
          showLink={false}
        />
      );
    }
    return (
      <ProfileCardLink
        id={item.idFornecedor.toString()}
        name={item.fornecedorNome || '-'}
        nameSize="sm"
        description={item.fornecedorCPF}
        descriptionSize="xs"
        linkPrefix="entities"
        showLink={false}
      />
    );
  };

  const buildQuotationCard = (item: {
    icon: Icon;
    item: string;
    gerenciar?: boolean;
    compartilhado?: boolean;
    preco: number;
    receita?: boolean;
    cobrarSemUso?: boolean;
    unidadeMedida: string;
    idFornecedor: number;
    fornecedorCNPJ: string | null;
    fornecedorNomeFantasia: string | null;
    fornecedorRazaoSocial: string | null;
    fornecedorCPF: string | null;
    fornecedorNome: string | null;
    tolerancias: number;
  }) => {
    return (
      <div key={newGuid()}>
        <Space h="xs" />
        <Paper shadow="xs" p="md" withBorder>
          <Group position="apart">
            <Group spacing={3} className="custom-mantine-badge-group-down">
              <item.icon size={16} />
              {item.gerenciar ? <Briefcase size={16} /> : <></>}
              {item.compartilhado ? <Share size={16} /> : <></>}
              <Badge key={newGuid()} variant="outline" size="sm">
                {item.item}
              </Badge>
            </Group>
            {item.tolerancias > 0 && (
              <Group spacing={3}>
                <Scale size={16} />
                <Badge key={newGuid()} variant="outline" size="sm">
                  {item.tolerancias}
                </Badge>
              </Group>
            )}
          </Group>
          <Group style={{ marginTop: 8, marginBottom: 8 }}>{buildSupplierData(item)}</Group>
          <Group position="apart">
            <Group spacing={3}>
              <CurrencyReal size={16} />
              <Text
                size="sm"
                color={
                  item.cobrarSemUso !== undefined && !item.cobrarSemUso
                    ? 'orange'
                    : item.receita
                    ? 'green'
                    : 'red'
                }
                weight="500"
              >
                {`${item.receita ? '+' : '-'}${item.preco}`}
              </Text>
            </Group>
            <Stack spacing={5}>
              <Group spacing={3}>
                <Ruler2 size={16} />
                <Badge key={newGuid()} variant="outline" size="sm">
                  {item.unidadeMedida}
                </Badge>
              </Group>
              {item.cobrarSemUso !== undefined && (
                <Badge key={newGuid()} variant="outline" size="sm">
                  <Group spacing={3} className="custom-mantine-badge-group-up">
                    <div>Cobrar Sem Uso? </div>
                    {item.cobrarSemUso ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                  </Group>
                </Badge>
              )}
            </Stack>
          </Group>
        </Paper>
      </div>
    );
  };

  const buildRentData = () => {
    const items: ReactNode[] = [];

    for (const acondicionamento of quotation.acondicionamentos) {
      const refItem = props.data.acondicionamentos.find(
        (x) =>
          x.idPropostaResiduoPlanoAcondicionamento === acondicionamento.idPropostaResiduoPlanoAcondicionamento
      );
      const item = acondicionamento.residuoAcondicionamento;
      const unidadeMedida = `${acondicionamento.quantidade} ${acondicionamento.quantidadeUnidadeMedida} / ${acondicionamento.frequenciaUnidadeMedida}`;
      items.push(
        buildQuotationCard({
          ...acondicionamento,
          gerenciar: refItem?.gerenciar,
          compartilhado: refItem?.compartilhado,
          icon: Feature.Reference.Packaging.icon,
          item,
          unidadeMedida,
          tolerancias: acondicionamento.tolerancias.length,
        })
      );
    }

    for (const equipamento of quotation.equipamentos) {
      const item = equipamento.residuoEquipamento;
      const unidadeMedida = `${equipamento.quantidade} ${equipamento.quantidadeUnidadeMedida} / ${equipamento.frequenciaUnidadeMedida}`;

      items.push(
        buildQuotationCard({
          ...equipamento,
          icon: Feature.Reference.Equipment.icon,
          item,
          unidadeMedida,
          tolerancias: equipamento.tolerancias.length,
        })
      );
    }

    return items;
  };

  const buildTransportData = () => {
    const items: ReactNode[] = [];

    if (quotation.veiculo) {
      const item = quotation.veiculo.residuoVeiculo;
      const unidadeMedida = `${quotation.veiculo.quantidade} ${quotation.veiculo.quantidadeUnidadeMedida} / ${quotation.veiculo.frequenciaUnidadeMedida}`;

      items.push(
        buildQuotationCard({
          ...quotation.veiculo,
          icon: Feature.Reference.Vehicle.icon,
          item,
          unidadeMedida,
          tolerancias: quotation.veiculo.tolerancias.length,
        })
      );
    }

    return items;
  };

  const buildDestinationData = () => {
    const items: ReactNode[] = [];

    if (quotation.tratamento) {
      const item = quotation.tratamento.residuoTratamento;
      const unidadeMedida = `${quotation.tratamento.quantidade} ${quotation.tratamento.quantidadeUnidadeMedida}`;

      items.push(
        buildQuotationCard({
          ...quotation.tratamento,
          icon: Feature.Reference.Treatment.icon,
          item,
          unidadeMedida,
          tolerancias: quotation.tratamento.tolerancias.length,
        })
      );
    }

    if (quotation.destinoFinal) {
      const item = quotation.destinoFinal.residuoDestinoFinal;
      const unidadeMedida = `${quotation.destinoFinal.quantidade} ${quotation.destinoFinal.quantidadeUnidadeMedida}`;

      items.push(
        buildQuotationCard({
          ...quotation.destinoFinal,
          icon: Feature.Reference.Destination.icon,
          item,
          unidadeMedida,
          tolerancias: quotation.destinoFinal.tolerancias.length,
        })
      );
    }

    return items;
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Grid columns={3}>
        <Grid.Col span={1}>
          <Group position="center">
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Locação
            </Text>
          </Group>
          {buildRentData()}
        </Grid.Col>
        <Grid.Col span={1}>
          <Group position="center">
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Transporte
            </Text>
          </Group>
          {buildTransportData()}
        </Grid.Col>
        <Grid.Col span={1}>
          <Group position="center">
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Destinação
            </Text>
          </Group>
          {buildDestinationData()}
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
