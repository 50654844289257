/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { DeviceFloppy } from 'tabler-icons-react';
import { VehicleType } from '../../../models/core/vehicles.type';
import { SessionStorageKey } from '../../../utils/constants.utils';
import vehiclesService from '../../../services/core/vehicles.service';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';
import { FilterData, VehicleSearchModalFilter } from './VehicleSearchModalFilter';

type VehicleFormViewProps = {
  idsToBeDisabled: number[];
  callback(item: VehicleType | null, confirmed: boolean): void;
};

export default function VehicleSearchModal(props: VehicleFormViewProps) {
  const [data, setData] = useState<VehicleType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<VehicleType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.VehicleSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await vehiclesService.search({
        residuoVeiculo: inputFilterData?.residuoVeiculo || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.VehicleSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Veículo - Pesquisar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar veículos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: VehicleType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: VehicleType) => ({
      disabled: props.idsToBeDisabled.includes(record.idResiduoVeiculo),
      name: record.idResiduoVeiculo.toString(),
    }),
  };

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.VehicleSearch) || '[]'
    ) as VehicleType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({
        residuoVeiculo: null,
      });
    }
  }, []);

  return (
    <div>
      <VehicleSearchModalFilter filter={filter} clear={clear} loading={loading} />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: 'Veículo',
                key: 'veiculo',
                dataIndex: 'residuoVeiculo',
                sorter: (a: VehicleType, b: VehicleType) => a.residuoVeiculo.localeCompare(b.residuoVeiculo),
              },
              {
                title: 'Descrição',
                key: 'descricao',
                sorter: (a: VehicleType, b: VehicleType) => {
                  return (a.descricao || '').localeCompare(b.descricao || '');
                },
                render: (row: VehicleType) => (
                  <TableCellEllipsis label={row?.descricao} numberOfChars={25} toolTipWidth={300} />
                ),
              },
            ]}
            rowSelection={{ type: 'radio', ...rowSelection }}
            rowKey={(item: VehicleType) => item.idResiduoVeiculo}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
