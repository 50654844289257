/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { DepartmentType } from '../../../../models/core/departments.type';
import departmentsService from '../../../../services/core/departments.service';
import invitationsService from '../../../../services/core/invitations.service';
import { Feature } from '../../../../utils/constants.utils';
import { FormView, FormViewData } from './components/FormView';

type DepartmentResult = {
  loading: boolean;
  data: DepartmentType[] | null;
};
function InvitationAdd() {
  const navigate = useNavigate();

  const [departmentResult, setDepartmentResult] = useState<DepartmentResult>({
    loading: true,
    data: null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data: DepartmentType[] = [];
      try {
        data = await departmentsService.list(true);
      } catch (error: any) {
        showNotification({
          title: 'Convite - Adicionar',
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar o convite.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDepartmentResult({ loading: false, data });
      }
    };
    if (departmentResult.data === null) {
      fetchData();
    }
  }, [navigate, departmentResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      const newModel = await invitationsService.insert({
        ...inputFormViewData,
        idDepartamento: Number(inputFormViewData.idDepartamento),
      });
      navigate('/invitations');
      showNotification({
        title: 'Convite - Adicionar',
        message: 'Convite enviado com sucesso.',
        color: 'green',
      });
      navigate(`/invitations/${newModel.idUsuarioConvite}`, {
        state: {
          invitation: newModel,
        },
      });
    } catch (error: any) {
      showNotification({
        title: 'Convite - Adicionar',
        message: error?.isBusinessException ? error.description : 'Não foi possível enviar o convite.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.Invitation}
        title="Convite - Adicionar"
        description="Convide uma pessoa para contribuir na plataforma."
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={departmentResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="invitation-add"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={departmentResult.loading}
            loading={saving}
          >
            Salvar
          </Button>,
        ]}
      />
      {departmentResult.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView departments={departmentResult.data} loading={saving} save={save} />
        </PageContent>
      )}
    </Card>
  );
}

export default InvitationAdd;
