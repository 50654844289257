/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DeviceFloppy, Notes, Ruler2 } from 'tabler-icons-react';
import VehicleSearch from '../../../../../../../../../../../../components/core/VehicleSearch/VehicleSearch';
import { UnitOfMeasureType } from '../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../models/core/core.type';
import { ProposalResiduePlanVehicleType } from '../../../../../../../../../../../../models/core/proposals.type';
import { VehicleType } from '../../../../../../../../../../../../models/core/vehicles.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoVeiculo: number | null;
  residuoVeiculo: string;
  residuoVeiculoDescricao: string | null;

  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;

  cargaMedia: number | null;
  cargaMediaIdUnidadeMedida: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanVehicleType | null;
  item: ProposalResiduePlanVehicleType | null;
  idPropostaResiduoPlano: number | undefined;
  callback(item: ProposalResiduePlanVehicleType | null, action: string, confirmed: boolean): void;
};

export default function ResiduePlanVehicleFormAddEdit(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoVeiculo: props.item?.idResiduoVeiculo || null,
      residuoVeiculo: props.item?.residuoVeiculo || '',
      residuoVeiculoDescricao: props.item?.residuoVeiculoDescricao || null,

      quantidade: props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida.toString() ||
        props.referenceData.unitOfMeasures
          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
          ?.idUnidadeMedida.toString() ||
        null,

      cargaMedia: props.item?.cargaMedia || 0,
      cargaMediaIdUnidadeMedida: props.item?.cargaMediaIdUnidadeMedida?.toString() || null,

      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoVeiculo: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      cargaMedia: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (value <= 0) {
          return 'Valor deve ser maior que zero';
        }
        return null;
      },
      cargaMediaIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoVeiculo || ''}
    |${Number(props.origItem?.quantidade) || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${Number(props.origItem?.cargaMedia) || ''}
    |${props.origItem?.cargaMediaIdUnidadeMedida || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoVeiculo || ''}
    |${Number(form.values.quantidade) || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${Number(form.values.cargaMedia) || ''}
    |${form.values.cargaMediaIdUnidadeMedida || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }
    if (props.origItem) {
      if (isModified() && props.origItem.idPropostaResiduoPlanoVeiculo) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          cargaMediaIdUnidadeMedida: form.values.cargaMediaIdUnidadeMedida?.toString() || null,
        };
      }
    }

    const formItem: ProposalResiduePlanVehicleType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaResiduoPlano: props.idPropostaResiduoPlano || -1,
    };

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.cargaMediaIdUnidadeMedida = Number(formItem.cargaMediaIdUnidadeMedida);
    formItem.cargaMediaUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.cargaMediaIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.observacao = formItem.observacao?.trim() || null;

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form id="residue-plan-vehicle" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={6}>
            <VehicleSearch
              label="Veículo"
              formItem={form.getInputProps('residuoVeiculo')}
              idsToBeDisabled={[]}
              callback={(item: VehicleType | null) => {
                if (item) {
                  form.setFieldValue('idResiduoVeiculo', item.idResiduoVeiculo);
                  form.setFieldValue('residuoVeiculo', item.residuoVeiculo);
                  form.setFieldValue('residuoVeiculoDescricao', item.descricao);
                }
              }}
              disabled={false}
            />
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <Grid columns={6}>
          <Grid.Col span={3}>
            <Input.Wrapper label="Quantidade" required>
              <div />
            </Input.Wrapper>
            <Group spacing="xs">
              <NumberInput
                style={{ width: 125 }}
                placeholder="Quantidade"
                min={1}
                defaultValue={1}
                step={1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                {...form.getInputProps('quantidade')}
              />
              <Select
                icon={<Ruler2 size={15} />}
                placeholder="Selecione..."
                data={props.referenceData.unitOfMeasures
                  .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                  .map((x) => {
                    return {
                      value: x.idUnidadeMedida.toString(),
                      label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                      group: x.tipo,
                    };
                  })}
                searchable
                disabled
                required
                {...form.getInputProps('quantidadeIdUnidadeMedida')}
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Input.Wrapper label="Carga Média (Coleta)" required>
              <div />
            </Input.Wrapper>
            <Group spacing="xs">
              <NumberInput
                style={{ width: 125 }}
                placeholder="Carga Média"
                min={0.01}
                defaultValue={10.01}
                precision={2}
                step={0.5}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                {...form.getInputProps('cargaMedia')}
              />
              <Select
                icon={<Ruler2 size={15} />}
                placeholder="Selecione..."
                data={props.referenceData.unitOfMeasures
                  .filter(
                    (x) =>
                      x.tipo === 'Massa' ||
                      x.tipo === 'Volume' ||
                      (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                  )
                  .map((x) => {
                    return {
                      value: x.idUnidadeMedida.toString(),
                      label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                      group: x.tipo,
                    };
                  })}
                searchable
                required
                {...form.getInputProps('cargaMediaIdUnidadeMedida')}
              />
            </Group>
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o veículo"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="residue-plan-vehicle"
          onClick={() => handleSubmit(form.values)}
        >
          Salvar
        </Button>
      </Group>
    </form>
  );
}
