import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Mail, Key } from 'tabler-icons-react';
import NumberFormat from 'react-number-format';
import { Button, Space, TextInput, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import authService from '../../../services/iam/auth.service';
import SplitViewForm from '../../../components/layout/SplitViewForm/SplitViewForm';

type FormProps = {
  email: string;
  confirmationCode: string;
};

let alreadyLoaded = false;
export default function SignUpConfirm() {
  const queryParams = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<FormProps>({
    initialValues: {
      email: queryParams.get('email') || '',
      confirmationCode: queryParams.get('code') || '',
    },
    validate: {
      confirmationCode: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      await authService.signUpConfirm(values);
      setSuccess(true);
      showNotification({
        title: 'Confirmar cadastro',
        message: 'Cadastro confirmado com sucesso.',
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Confirmar cadastro',
        message: error?.isBusinessException ? error.description : 'Não foi possível confirmar seu cadastro.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!alreadyLoaded && form.values.email !== '' && form.values.confirmationCode !== '') {
      alreadyLoaded = true;
      handleSubmit(form.values);
    }
  });

  return (
    <SplitViewForm title="Confirmar Cadastro">
      <form style={{ width: 450 }} onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <Space h="xl" />
        <TextInput
          icon={<Mail size={15} />}
          label="E-mail"
          placeholder="Digite seu e-mail"
          type="email"
          maxLength={100}
          disabled
          required
          {...form.getInputProps('email')}
        />
        <Space h="xs" />
        <NumberFormat
          icon={<Key size={15} />}
          label="Código de confirmação"
          description="Você receberá este código via e-mail"
          placeholder="______"
          mask="_"
          format="######"
          customInput={TextInput}
          required
          {...form.getInputProps('confirmationCode')}
        />
        <Space h="xl" />
        <Button fullWidth type="submit" loading={loading} disabled={success}>
          Confirmar
        </Button>
        <Space h="xl" />
        {success && (
          <Group position="center">
            <Link to="/">
              <Button variant="outline">Voltar para o Login</Button>
            </Link>
          </Group>
        )}
      </form>
    </SplitViewForm>
  );
}
