/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Paper,
  Select,
  SimpleGrid,
  Space,
  TextInput,
  Textarea,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Calendar, DeviceFloppy, FileDots, FileInfo, Notes, Qrcode, Search } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import { CompanyType, DocumentType } from '../../../../../../models/core/cache.type';
import { Action } from '../../../../../../models/core/core.type';
import { EventDocumentFileType, EventDocumentType } from '../../../../../../models/core/events.type';
import eventsService from '../../../../../../services/core/events.service';
import totvsService from '../../../../../../services/core/totvs.service';
import theme from '../../../../../../theme';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid, tryGetDateISOFormat } from '../../../../../../utils/helper.utils';
import DocumentFileFormView from './DocumentFileFormView';

type FormViewData = {
  action: Action;
  id: string;
  idDocumentoTipo: string;
  documentoTipo: string;
  outroTipo: string | null;
  numeroDocumento: string;
  data: Date | null;
  observacao: string | null;
  arquivos: EventDocumentFileType[];
};

type FormViewProps = {
  referenceData: {
    documentTypeData: DocumentType[];
    companyData: CompanyType[];
  };
  origItem: EventDocumentType | null;
  item: (EventDocumentType & { origFiles?: EventDocumentFileType[] }) | null;
  idEvento: number;
  idEmpresa: number;
  callback(item: EventDocumentType | null, action: string, confirmed: boolean): void;
};

export default function DocumentFormAddEdit(props: FormViewProps) {
  const [searchingNFe, setSearchingNFe] = useState(false);
  const [saving, setSaving] = useState(false);

  const refDocumentFileForm = useRef<any>();
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.idEventoDocumento?.toString() || props.item?.id || newGuid(),
      idDocumentoTipo: props.item?.idDocumentoTipo.toString() || '',
      documentoTipo: props.item?.documentoTipo || '',
      outroTipo: props.item?.outroTipo || '',
      numeroDocumento: props.item?.numeroDocumento || '',
      data: props.item?.data ? formatDateStringToDate(tryGetDateISOFormat(props.item?.data)) : null,
      observacao: props.item?.observacao || '',
      arquivos: [],
    },
    validate: {
      idDocumentoTipo: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      outroTipo: (value: string | null, values: FormViewData) => {
        if (values.idDocumentoTipo === '0' && value?.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      numeroDocumento: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      data: (value: Date | null) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
        }
        return null;
      },
    },
  });

  const searchNFe = async () => {
    if (!form.validateField('numeroDocumento').hasError) {
      setSearchingNFe(true);
      try {
        const company = props.referenceData.companyData.find((x) => x.idEmpresa === props.idEmpresa);
        const response = await totvsService.searchNFe({
          grupo: company?.TOTVSGrupo || '-',
          filial: company?.TOTVSFilial || '-',
          nfe: form.values.numeroDocumento,
        });
        showNotification({
          title: `Evento - Visualizar`,
          message: `NFe ${response ? 'já' : 'não'} disponível no TOTVS.`,
          color: `${response ? 'green' : 'orange'}`,
        });
      } catch (error: any) {
        showNotification({
          title: `Evento - Visualizar`,
          message: 'Não foi possível verificar a existência da NFe no TOTVS.',
          color: 'red',
        });
      } finally {
        setSearchingNFe(false);
      }
    }
  };

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idDocumentoTipo?.toString() || ''}
    |${props.origItem?.outroTipo || ''}
    |${props.origItem?.numeroDocumento || ''}
    |${props.origItem?.numeroDocumento || ''}
    |${formatDateToString(props.origItem?.data || new Date()) || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idDocumentoTipo || ''}
    |${form.values.outroTipo || ''}
    |${form.values.numeroDocumento || ''}
    |${formatDateToString(form.values.data || new Date())}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
          idDocumentoTipo: values.idDocumentoTipo,
        };
      }
    }

    values.documentoTipo =
      props.referenceData.documentTypeData.find(
        (x) => x.idDocumentoTipo.toString() === values.idDocumentoTipo.toString()
      )?.documentoTipo || '';

    const formItem: EventDocumentType = {
      ...values,
      ...buildFakeAuditObject(),
      idDocumentoTipo: Number(values.idDocumentoTipo),
      idEvento: props.idEvento || -1,
    };

    formItem.outroTipo = values.idDocumentoTipo === '0' ? formItem.outroTipo?.trim() || null : null;
    formItem.numeroDocumento = formItem.numeroDocumento?.trim();
    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.arquivos = refDocumentFileForm.current.validate();

    try {
      setSaving(true);

      let response;
      let completedAction;
      const finalForm = {
        ...props.origItem,
        ...formItem,
        action: values.action,
        id: values.id,
      };

      if (values.action === Action.Nothing) {
        if (formItem.arquivos.findIndex((x) => x.action !== Action.Nothing) > -1) {
          values.action = Action.Modify;
        }
      }

      switch (values.action) {
        case 'add':
          response = await eventsService.insertDocument(finalForm);
          response.id = response.idEventoDocumento?.toString();
          completedAction = 'adicionado';
          break;
        case 'modify':
          await eventsService.updateDocument(finalForm);
          completedAction = 'atualizado';
          (finalForm as any).dataCriacao = moment(props.item?.dataCriacao).format('YYYY-MM-DD HH:mm:ss');
          (finalForm as any).dataModificacao = moment().format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'delete':
          await eventsService.deleteDocument({
            idEvento: props.item!.idEvento,
            idEventoDocumento: props.item!.idEventoDocumento!,
          });
          completedAction = 'excluído';
          break;
        default:
          props.callback(null, '', false);
          return;
      }

      showNotification({
        title: `Evento - Visualizar`,
        message: `Documento ${completedAction} com sucesso.`,
        color: 'green',
      });
      props.callback(response || finalForm, 'callback', true);
    } catch (exception: any) {
      showNotification({
        title: `Evento - Visualizar`,
        message: exception?.isBusinessException
          ? exception.description
          : 'Não foi possível adicionar o salvar ao evento.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <form id="event-document" onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <PageSection size="lg" color={Feature.Home.Event.color} label="Documento" text="" />
          <Space h="xs" />
          <Grid columns={3}>
            <Grid.Col span={1}>
              <Select
                icon={<FileDots size={15} />}
                label="Tipo"
                placeholder="Selecione..."
                data={props.referenceData.documentTypeData.map((x) => {
                  return {
                    value: x.idDocumentoTipo.toString(),
                    label: x.documentoTipo,
                  };
                })}
                searchable
                required
                {...form.getInputProps('idDocumentoTipo')}
              />
            </Grid.Col>
            {form.values.idDocumentoTipo === '0' && (
              <Grid.Col span={1}>
                <TextInput
                  icon={<FileInfo size={15} />}
                  label="Outro"
                  placeholder="Outro tipo"
                  maxLength={50}
                  required
                  disabled={form.values.idDocumentoTipo !== '0'}
                  {...form.getInputProps('outroTipo')}
                />
              </Grid.Col>
            )}
            <Grid.Col span={1}>
              <DatePicker
                label="Data"
                placeholder="Data do Documento"
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable
                allowFreeInput
                {...form.getInputProps('data')}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <TextInput
              icon={<Qrcode size={15} />}
              rightSection={
                props.referenceData.documentTypeData.find(
                  (x) => x.idDocumentoTipo.toString() === form.values.idDocumentoTipo
                )?.documentoTipo === 'Nota Fiscal' ? (
                  <ActionIcon
                    size="sm"
                    radius="sm"
                    color="primary"
                    style={{ borderColor: theme?.colors?.primary?.[6] }}
                    variant="outline"
                    loading={searchingNFe}
                    onClick={() => {
                      searchNFe();
                    }}
                  >
                    <Search size={15} color={theme?.colors?.primary?.[6]} />
                  </ActionIcon>
                ) : undefined
              }
              label="Número do Documento"
              placeholder="Número do documento"
              maxLength={50}
              required
              {...form.getInputProps('numeroDocumento')}
            />
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o documento"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </form>
      </Paper>
      <Space h="lg" />
      <Paper shadow="xs" p="md" withBorder>
        <DocumentFileFormView ref={refDocumentFileForm} eventDocument={props.item} />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="event-document" loading={saving}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
