/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Grid, Group, SimpleGrid, Space } from '@mantine/core';
import { Link } from 'react-router-dom';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalType } from '../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';

type DataViewProps = {
  data: ProposalType;
};

export default function GeneralView(props: DataViewProps) {
  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Geral"
          text="Informações gerais da proposta como cliente, fechamento, datas, documentação, etc."
        />
      </Group>
      <Space h="xs" />

      <SimpleGrid cols={5}>
        <PageViewProperty label="Número da Proposta" text={props.data.idProposta} size="md" />
        {props.data.idPropostaPai && (
          <PageViewProperty
            label="Proposta de Origem"
            text={
              <Link to={`/proposals/${props.data.idPropostaPai}`} target="_blank">
                {props.data.idPropostaPai}
              </Link>
            }
            size="md"
          />
        )}
        <PageViewProperty
          label="Tipo"
          text={<Badge variant="outline">{props.data.propostaTipo}</Badge>}
          size="md"
        />
        <PageViewProperty
          label="Status"
          text={<Badge variant="outline">{props.data.propostaStatus}</Badge>}
          size="md"
        />
        <PageViewProperty label="Empresa Responsável" text={props.data.empresa} size="md" />
        {props.data.idGerenciador && props.data.gerenciadorCNPJ ? (
          <PageViewProperty
            label="Gerenciador"
            text={
              <ProfileCardLink
                id={props.data.idGerenciador.toString()}
                name={props.data.gerenciadorNomeFantasia || props.data.gerenciadorRazaoSocial || '-'}
                nameSize="md"
                description={
                  props.data.gerenciadorNomeFantasia
                    ? props.data.gerenciadorRazaoSocial
                    : props.data.gerenciadorCNPJ
                }
                descriptionSize="sm"
                linkPrefix="entities"
                showLink
              />
            }
            size="md"
          />
        ) : props.data.idGerenciador && props.data.gerenciadorCPF ? (
          <PageViewProperty
            label="Gerenciador"
            text={
              <ProfileCardLink
                id={props.data.idGerenciador.toString()}
                name={props.data.gerenciadorNome || '-'}
                nameSize="md"
                description={props.data.gerenciadorCPF}
                descriptionSize="sm"
                linkPrefix="entities"
                showLink
              />
            }
            size="md"
          />
        ) : (
          <></>
        )}
      </SimpleGrid>
      <Divider my="sm" variant="dotted" />

      <Grid columns={7}>
        <Grid.Col span={2}>
          {props.data.clienteCNPJ ? (
            <PageViewProperty
              label="Cliente"
              text={
                <ProfileCardLink
                  id={props.data.idCliente.toString()}
                  name={props.data.clienteNomeFantasia || props.data.clienteRazaoSocial || '-'}
                  nameSize="md"
                  description={
                    props.data.clienteNomeFantasia ? props.data.clienteRazaoSocial : props.data.clienteCNPJ
                  }
                  descriptionSize="sm"
                  linkPrefix="entities"
                  showLink
                />
              }
              size="md"
            />
          ) : (
            <PageViewProperty
              label="Cliente"
              text={
                <ProfileCardLink
                  id={props.data.idCliente.toString()}
                  name={props.data.clienteNome || '-'}
                  nameSize="md"
                  description={props.data.clienteCPF}
                  descriptionSize="sm"
                  linkPrefix="entities"
                  showLink
                />
              }
              size="md"
            />
          )}
        </Grid.Col>
        <Grid.Col span={2}>
          <PageViewProperty
            label="Contato"
            text={
              <ProfileCardLink
                id={props.data.idEntidadeContato.toString()}
                name={props.data.nome}
                nameSize="md"
                description={
                  props.data.telefone || props.data.celular || props.data.email || props.data.setor
                }
                descriptionSize="sm"
                showLink={false}
              />
            }
            size="md"
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Fechamento"
            text={<Badge variant="outline">{props.data.fechamento}</Badge>}
            size="md"
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Data Inicial"
            text={formatDateToString(props.data.dataInicial)}
            size="md"
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty label="Data Final" text={formatDateToString(props.data.dataFinal)} size="md" />
        </Grid.Col>
      </Grid>
      <Divider my="sm" variant="dotted" />

      <SimpleGrid cols={4}>
        <PageViewProperty
          label="Lembrete da Validade"
          text={props.data.validadeLembreteDias ? `${props.data.validadeLembreteDias} dias` : '-'}
          size="md"
        />
        <PageViewProperty label="Validade" text={formatDateToString(props.data.validade)} size="md" />
      </SimpleGrid>
      <Divider my="sm" variant="dotted" />

      <Grid columns={4}>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Lembrete da Documentação"
            text={
              props.data.documentacaoClienteRecorrenciaDias
                ? `${props.data.documentacaoClienteRecorrenciaDias} dias (recorrência)`
                : '-'
            }
            size="md"
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <PageViewProperty
            label="Detalhes da Documentação"
            text={props.data.documentacaoCliente || '-'}
            size="md"
          />
        </Grid.Col>
      </Grid>
      <Divider my="sm" variant="dotted" />

      <SimpleGrid>
        <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="md" />
      </SimpleGrid>
    </div>
  );
}
