import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { IBAMAResidueType, UnitOfMeasureType } from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';
import { ResidueType } from '../../../../models/core/residues.type';
import residuesService from '../../../../services/core/residues.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature } from '../../../../utils/constants.utils';
import { FormView, FormViewData } from './components/FormView';

// update upsert with selectbox, view with details...
type DataResult = {
  loading: boolean;
  unityOfMeasureData: UnitOfMeasureType[] | null;
  IBAMAResidueData: IBAMAResidueType[];
  residueData: ResidueType | null;
};

function ResidueAddEdit() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { idResiduo } = useParams();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    unityOfMeasureData: null,
    IBAMAResidueData: [],
    residueData: location?.state?.residue || null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let unityOfMeasureData: UnitOfMeasureType[] = [];
      let IBAMAResidueData: IBAMAResidueType[] = [];
      let { residueData } = dataResult;
      try {
        unityOfMeasureData = (await cacheUtils.listUnityOfMeasures()).filter((x) => x.residuo);
        IBAMAResidueData = await cacheUtils.listIBAMAResidueTypes();

        if (idResiduo && dataResult.residueData === null) {
          residueData = await residuesService.select({ idResiduo: Number(idResiduo) });
        }
      } catch (error: any) {
        showNotification({
          title: `Resíduo - ${!idResiduo ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar o resíduo.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, unityOfMeasureData, IBAMAResidueData, residueData });
      }
    };

    if (
      dataResult.unityOfMeasureData === null ||
      dataResult.IBAMAResidueData === null ||
      (idResiduo && dataResult.residueData === null)
    ) {
      fetchData();
    }
  }, [idResiduo, navigate, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      let newModel;
      if (!idResiduo) {
        newModel = await residuesService.insert({
          ...inputFormViewData,
          idUnidadeMedida: Number(inputFormViewData.idUnidadeMedida) || null,
        });
        navigate('/residues');
      } else {
        await residuesService.update({
          ...inputFormViewData,
          idResiduo: Number(idResiduo),
          idUnidadeMedida: Number(inputFormViewData.idUnidadeMedida || '') || null,
        });
      }
      showNotification({
        title: `Resíduo - ${!idResiduo ? 'Adicionar' : 'Editar'}`,
        message: 'Resíduo salvo com sucesso.',
        color: 'green',
      });
      navigate(`/residues/${newModel?.idResiduo || idResiduo}`, {
        state: {
          residue: newModel || null,
        },
      });
    } catch (error: any) {
      showNotification({
        title: `Resíduo - ${!idResiduo ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar o resíduo.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Residue}
        title={`Resíduo - ${!idResiduo ? 'Adicionar' : 'Editar'}`}
        description={`${!idResiduo ? 'Adicione' : 'Edite'} um resíduo.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="residue-add-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            data-permission={!idResiduo ? Permission.ResidueAdd : Permission.ResidueEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {dataResult.unityOfMeasureData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView
            unitOfMeasures={dataResult.unityOfMeasureData}
            IBAMAResidues={dataResult.IBAMAResidueData}
            residue={dataResult.residueData}
            loading={saving}
            save={save}
          />
        </PageContent>
      )}
    </Card>
  );
}

export default ResidueAddEdit;
