/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Alert,
  Badge,
  Divider,
  Grid,
  Group,
  Modal,
  Paper,
  SimpleGrid,
  Space,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { AlertTriangle, Check, Eye, Scale, X, ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanQuotationPackagingType,
} from '../../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../../theme';
import { Feature } from '../../../../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../../../../utils/formatter.utils';
import ProposalItemToleranceView from '../../../../components/ProposalItemToleranceView';

type ViewProps = {
  refData: ProposalResiduePlanPackagingType[];
  data: ProposalResiduePlanQuotationPackagingType[];
};

export default function ResiduePlanQuotationPackagingView(props: ViewProps) {
  const [planQuotationItem, setPlanQuotationItem] = useState<ProposalResiduePlanQuotationPackagingType>();
  const [opened, setOpened] = useState(false);

  const planQuotationItemRef = props.refData.find(
    (x) =>
      x.idPropostaResiduoPlanoAcondicionamento === planQuotationItem?.idPropostaResiduoPlanoAcondicionamento
  );

  const columns: ColumnsType<ProposalResiduePlanQuotationPackagingType> = [
    {
      title: 'Acondicionamento',
      key: 'residuoAcondicionamento',
      sorter: (
        a: ProposalResiduePlanQuotationPackagingType,
        b: ProposalResiduePlanQuotationPackagingType
      ) => {
        return a.residuoAcondicionamento.localeCompare(b.residuoAcondicionamento);
      },
      render: (row: ProposalResiduePlanQuotationPackagingType) => {
        return (
          <ProfileCardLink
            id={(row.idResiduoAcondicionamento || '').toString()}
            name={row.residuoAcondicionamento || '-'}
            nameSize="sm"
            description={row.residuoAcondicionamentoDescricao}
            descriptionSize="xs"
            linkPrefix="packaging"
            showLink
          />
        );
      },
    },
    {
      title: 'Fornecedor',
      key: 'idFornecedor',
      sorter: (a: ProposalResiduePlanQuotationPackagingType, b: ProposalResiduePlanQuotationPackagingType) =>
        (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
        ),
      render: (row: ProposalResiduePlanQuotationPackagingType) => {
        if (row.idFornecedor) {
          if (row.fornecedorCNPJ) {
            return (
              <ProfileCardLink
                id={row.idFornecedor.toString()}
                name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
                nameSize="sm"
                description={row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idFornecedor.toString()}
              name={row.fornecedorNome || '-'}
              nameSize="sm"
              description={row.fornecedorCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Quantidade/Frequência',
      key: 'quantidadeFrequencia',
      sorter: (
        a: ProposalResiduePlanQuotationPackagingType,
        b: ProposalResiduePlanQuotationPackagingType
      ) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;

        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationPackagingType) => {
        return `${row.quantidade} ${row.quantidadeUnidadeMedida} / ${row.frequenciaUnidadeMedida}`;
      },
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (
        a: ProposalResiduePlanQuotationPackagingType,
        b: ProposalResiduePlanQuotationPackagingType
      ) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationPackagingType) => <div>{formatCurrency(row.preco)}</div>,
    },
    {
      title: 'Cobrar Sem Uso?',
      key: 'cobrarSemUso',
      dataIndex: 'cobrarSemUso',
      sorter: (a: ProposalResiduePlanQuotationPackagingType, b: ProposalResiduePlanQuotationPackagingType) =>
        a.cobrarSemUso === b.cobrarSemUso ? 0 : a.cobrarSemUso ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanQuotationPackagingType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanQuotationItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  const calculatePendingQuotationItems = () => {
    const allItems = JSON.parse(JSON.stringify(props.refData)) as ProposalResiduePlanPackagingType[];

    const processedItems = props.data;

    for (const item of processedItems) {
      const index = allItems.findIndex(
        (x) =>
          ((x.id && x.id === item?.id) ||
            (x.idTemporaria && x.idTemporaria === item?.idTemporaria) ||
            (x.idPropostaResiduoPlanoAcondicionamento &&
              x.idPropostaResiduoPlanoAcondicionamento === item?.idPropostaResiduoPlanoAcondicionamento)) &&
          x.idResiduoAcondicionamento === item.idResiduoAcondicionamento
      );
      if (index > -1) {
        allItems.splice(index, 1);
      }
    }

    return allItems;
  };
  const pendingQuotationItems = calculatePendingQuotationItems();

  const buildQuotationBadgeList = (list: ProposalResiduePlanPackagingType[]) => {
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x.idPropostaResiduoPlanoAcondicionamento} variant="outline" size="sm">
            {x.residuoAcondicionamento}
          </Badge>
        ))}
      </Group>
    );
  };

  const buildTabs = () => {
    const tabs = [
      {
        key: 'Cotacao',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <ZoomMoney size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Cotação
            </Text>
          </Group>
        ),
        children: (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={5}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Fornecedor"
                    text={
                      planQuotationItem!.fornecedorCNPJ ? (
                        <ProfileCardLink
                          id={planQuotationItem!.idFornecedor.toString()}
                          name={
                            planQuotationItem!.fornecedorNomeFantasia ||
                            planQuotationItem!.fornecedorRazaoSocial ||
                            '-'
                          }
                          nameSize="sm"
                          description={
                            planQuotationItem!.fornecedorNomeFantasia
                              ? planQuotationItem!.fornecedorRazaoSocial
                              : planQuotationItem!.fornecedorCNPJ
                          }
                          descriptionSize="xs"
                          linkPrefix="entities"
                          showLink
                        />
                      ) : (
                        <ProfileCardLink
                          id={planQuotationItem!.idFornecedor.toString()}
                          name={planQuotationItem!.fornecedorNome || '-'}
                          nameSize="sm"
                          description={planQuotationItem!.fornecedorCPF}
                          descriptionSize="xs"
                          linkPrefix="entities"
                          showLink
                        />
                      )
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Quantidade/Frequência"
                    text={`${planQuotationItem!.quantidade} ${planQuotationItem!.quantidadeUnidadeMedida} / ${
                      planQuotationItem!.frequenciaUnidadeMedida
                    }`}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty label="Preço" text={formatCurrency(planQuotationItem!.preco)} size="sm" />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <Grid columns={5}>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Cobrar Sem Uso?"
                    text={planQuotationItem!.cobrarSemUso ? 'Sim' : 'Não'}
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty
                  label="Condição da Locação"
                  text={planQuotationItem!.condicaoLocacao || '-'}
                  size="sm"
                />
              </SimpleGrid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planQuotationItem!.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planQuotationItem!.idCriadoPor}
              criadoPor={planQuotationItem!.criadoPor}
              dataCriacao={planQuotationItem!.dataCriacao}
              idModificadoPor={planQuotationItem?.idModificadoPor || null}
              modificadoPor={planQuotationItem?.modificadoPor || null}
              dataModificacao={planQuotationItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ),
        forceRender: true,
      },
      {
        key: 'Tolerancias',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Scale size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Tolerâncias
            </Text>
          </Group>
        ),
        children: (
          <Paper shadow="xs" p="md" withBorder>
            <ProposalItemToleranceView data={planQuotationItem!.tolerancias} />
          </Paper>
        ),
        forceRender: true,
      },
    ];
    return tabs;
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Plano/Cotação/Acondicionamento - Visualizar"
        size="50%"
      >
        {planQuotationItem ? (
          <Paper shadow="xs" p="md" withBorder>
            <Paper shadow="xs" p="md" withBorder>
              <PageSection
                size="lg"
                color={Feature.Home.Proposal.color}
                label="Cotação para:"
                text="Este é o acondicionamento a ser cotado."
              />
              <Space h="xs" />

              <Grid columns={4}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Acondicionamento"
                    text={
                      <ProfileCardLink
                        id={(planQuotationItemRef?.idResiduoAcondicionamento || '').toString()}
                        name={planQuotationItemRef?.residuoAcondicionamento || '-'}
                        nameSize="sm"
                        description={planQuotationItemRef?.residuoAcondicionamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="packaging"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>

                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={
                      !planQuotationItemRef?.compartilhado
                        ? `${planQuotationItemRef?.quantidade} ${planQuotationItemRef?.quantidadeUnidadeMedida}`
                        : '%'
                    }
                    size="sm"
                  />
                </Grid.Col>

                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Compartilhado?"
                    text={planQuotationItemRef?.compartilhado ? 'Sim' : 'Não'}
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
            </Paper>
            <Space h="lg" />

            <Paper shadow="xs" p="md" withBorder>
              <Tabs items={buildTabs()} defaultActiveKey="Cotacao" />
            </Paper>
          </Paper>
        ) : (
          <div />
        )}
      </Modal>

      {pendingQuotationItems.length > 0 && (
        <div>
          <Alert
            icon={<AlertTriangle size={32} />}
            title="Acondicionamentos Pendentes de Cotação"
            color="yellow"
          >
            {buildQuotationBadgeList(pendingQuotationItems)}
          </Alert>
          <Space h="lg" />
        </div>
      )}

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Acondicionamentos"
          text="Cotação para os acondicionamentos selecionados no plano de coleta do resíduo."
        />
        <Space h="xs" />

        <Table
          showSorterTooltip={false}
          dataSource={props.data}
          columns={columns}
          rowKey={(item: ProposalResiduePlanQuotationPackagingType) =>
            item.idPropostaResiduoPlanoCotacaoAcondicionamento || 0
          }
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
    </div>
  );
}
