/* eslint-disable react/destructuring-assignment */
import { ClearAll, Search, Truck } from 'tabler-icons-react';
import { Button, Group, Paper, SimpleGrid, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

type FilterData = {
  residuoVeiculo: string;
};

type FilterProps = {
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function VehicleFormSearchFilter(props: FilterProps) {
  const form = useForm<FilterData>({
    initialValues: {
      residuoVeiculo: '',
    },
  });

  return (
    <form onSubmit={form.onSubmit(props.filter)}>
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={2}>
          <TextInput
            icon={<Truck size={15} />}
            label="Veículo"
            description="Filtro: contém"
            placeholder="Todos..."
            maxLength={500}
            {...form.getInputProps('residuoVeiculo')}
          />
          <Group>
            <Button
              style={{ marginTop: 44 }}
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              disabled={props.loading}
              onClick={() => {
                form.reset();
                props.clear();
              }}
            >
              Limpar
            </Button>
            <Button
              style={{ marginTop: 44 }}
              leftIcon={<Search size={18} />}
              type="submit"
              loading={props.loading}
            >
              Pesquisar
            </Button>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { VehicleFormSearchFilter };
export type { FilterData };
