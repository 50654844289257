/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Group } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Check, X } from 'tabler-icons-react';
import { EntityTypeType } from '../../../../../models/core/cache.type';
import { EntitySearchResponseType } from '../../../../../models/core/entities.type';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  entityTypeData: EntityTypeType[];
  data: EntitySearchResponseType[];
  confirmActionResult(item: EntitySearchResponseType, action: string, confirmed: boolean): void;
};
export default function DataView(props: DataViewProps) {
  const buildBadges = (list: string[]) => {
    if ((list || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x} variant="outline" size="sm">
            {props.entityTypeData.find((y) => y.codigoEntidadeTipo === x)?.entidadeTipo}
          </Badge>
        ))}
      </Group>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Escopos',
      key: 'tipos',
      render: (row: EntitySearchResponseType) => buildBadges(row?.tipos?.toString().split(',')),
    },
    {
      title: 'CNPJ/CPF',
      key: 'cnpj-cpf',
      width: '180px',
      sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
        return (a.cnpj || a.cpf || '').localeCompare(b.cnpj || b.cpf || '');
      },
      render: (row: EntitySearchResponseType) => <div>{row?.cnpj || row?.cpf}</div>,
    },
    {
      title: 'Razão Social/Nome',
      key: 'razaoSocial-nome',
      sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
        return (a.razaoSocial || a.nome || '').localeCompare(b.razaoSocial || b.nome || '');
      },
      render: (row: EntitySearchResponseType) => <div>{row?.razaoSocial || row?.nome}</div>,
    },
    {
      title: 'Nome Fantasia',
      key: 'nomeFantasia',
      sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
        return (a.nomeFantasia || '').localeCompare(b.nomeFantasia || '');
      },
      render: (row: EntitySearchResponseType) => <div>{row?.nomeFantasia || '-'}</div>,
    },
    {
      title: 'Inscrição Estadual',
      key: 'incricaoEstadual',
      sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
        return (a.incricaoEstadual || '').localeCompare(b.incricaoEstadual || '');
      },
      render: (row: EntitySearchResponseType) => <div>{row?.incricaoEstadual || '-'}</div>,
    },
    {
      title: 'Inscrição Municipal',
      key: 'incricaoMunicipal',
      sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
        return (a.incricaoMunicipal || '').localeCompare(b.incricaoMunicipal || '');
      },
      render: (row: EntitySearchResponseType) => <div>{row?.incricaoMunicipal || '-'}</div>,
    },
    {
      title: 'Encontro de Contas?',
      key: 'aceitaEncontroContas',
      dataIndex: 'aceitaEncontroContas',
      sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) =>
        a.aceitaEncontroContas === b.aceitaEncontroContas ? 0 : a.aceitaEncontroContas ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: EntitySearchResponseType) => (
        <DataViewActions
          entityTypeData={props.entityTypeData}
          item={row}
          confirmActionResult={props.confirmActionResult}
        />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: EntitySearchResponseType) => item.idEntidade}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
