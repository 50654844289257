import React from 'react';
import { Alert, Center, Container, Grid, Group, Title } from '@mantine/core';

import './SplitViewForm.css';
import { BrandCodesandbox } from 'tabler-icons-react';

type SplitViewFormProps = {
  children: any;
  title: string;
};

// eslint-disable-next-line react/function-component-definition
const SplitViewForm: React.FC<SplitViewFormProps> = ({ children, title }) => {
  const environment = process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV;
  return (
    <Container fluid className="login__container" style={{ maxWidth: 1920 }}>
      <Grid className="login__wrapper" gutter={0}>
        <Grid.Col xs={12} md={6} lg={5}>
          <Center>
            <Title
              order={1}
              mt="xl"
              sx={(theme) => ({
                color: theme.colors.primary,
              })}
            >
              {title}
            </Title>
          </Center>
          <Center className="login__form__wrapper">{children}</Center>
        </Grid.Col>
        <Grid.Col xs={0} md={6} lg={7} className="login__image">
          <Group position="right" style={{ top: 6, left: 20, position: 'absolute' }}>
            {environment !== 'production' && (
              <Alert icon={<BrandCodesandbox size={16} />} color="yellow">
                {environment}
              </Alert>
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default SplitViewForm;
