/* eslint-disable react/destructuring-assignment */
import { Edit, Trash } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { EntityVehicleType } from '../../../../../../../models/core/entities.type';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import theme from '../../../../../../../theme';

type DataViewActionProps = {
  item: EntityVehicleType;
  confirmActionResult(items: EntityVehicleType | null, action: string, confirmed: boolean): void;
};

export default function VehicleFormViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: EntityVehicleType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse veículo?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={3}>
            <PageViewProperty label="Veiculo" text={item.residuoVeiculo} size="sm" />
            <PageViewProperty label="Descrição" text={item.residuoVeiculoDescricao || '-'} size="sm" />
            <PageViewProperty label="Tipo" text={item.proprio ? 'Próprio' : 'Terceirizado'} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
        <ActionIcon
          size="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.accent?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'editar', true);
          }}
        >
          <Edit size={15} color={theme?.colors?.accent?.[6]} />
        </ActionIcon>
      </Tooltip>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
