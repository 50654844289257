/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableCellEllipsis from '../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { ServiceType } from '../../../../../models/core/services.type';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: ServiceType[];
  confirmActionResult(item: ServiceType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Serviço',
      key: 'servico',
      dataIndex: 'servico',
      sorter: (a: ServiceType, b: ServiceType) => a.servico.localeCompare(b.servico),
    },
    {
      title: 'Descrição',
      key: 'descricao',
      sorter: (a: ServiceType, b: ServiceType) => {
        return (a.descricao || '').localeCompare(b.descricao || '');
      },
      render: (row: ServiceType) => (
        <TableCellEllipsis label={row?.descricao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: ServiceType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: ServiceType) => item.idServico}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
