/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Edit, Eye, Trash } from 'tabler-icons-react';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { EntityTypeType } from '../../../../../models/core/cache.type';
import { Permission } from '../../../../../models/core/departments.type';
import { EntitySearchResponseType } from '../../../../../models/core/entities.type';
import theme from '../../../../../theme';
import { validate } from '../../../../../utils/permission.utils';

type DataViewActionProps = {
  entityTypeData: EntityTypeType[];
  item: EntitySearchResponseType;
  confirmActionResult(item: EntitySearchResponseType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const buildBadges = (list: string[]) => {
    if ((list || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x} variant="outline" size="sm">
            {props.entityTypeData.find((y) => y.codigoEntidadeTipo === x)?.entidadeTipo}
          </Badge>
        ))}
      </Group>
    );
  };

  const showModal = (item: EntitySearchResponseType, action: string) => {
    modals.openConfirmModal({
      title: `Gostaria de ${action} essa entidade?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={3}>
            <PageViewProperty
              label="Escopos"
              text={buildBadges(item.tipos?.toString().split(','))}
              size="sm"
            />
            <PageViewProperty label="CNPJ/CPF" text={item.cnpj || item.cpf || '-'} size="sm" />
            <PageViewProperty
              label="Nome Fantasia/Nome"
              text={item.nomeFantasia || item.nome || '-'}
              size="sm"
            />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });
  };

  return (
    <Group>
      {validate(Permission.EntityView, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'visualizar', true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {validate(Permission.EntityEdit, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.accent?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'editar', true);
            }}
          >
            <Edit size={15} color={theme?.colors?.accent?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {validate(Permission.EntityDelete, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'red' }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'excluir');
            }}
          >
            <Trash size={15} color="red" />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
}
