/* eslint-disable no-promise-executor-return */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Button, Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { EntitySearchResponseType } from '../../../../models/core/entities.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import entitysService from '../../../../services/core/entities.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import cacheUtils from '../../../../utils/cache.utils';
import { EntityTypeType } from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';

function EntitySearch() {
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState<{
    entityTypeData: EntityTypeType[];
  }>({ entityTypeData: [] });

  const [data, setData] = useState<EntitySearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);
      setFilterData({
        entityTypeData: await cacheUtils.listEntityTypes(),
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.EntitySearch) || '[]'
      ) as EntitySearchResponseType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        filter({
          tipos: [],
          cnpj: null,
          razaoSocial: null,
          nomeFantasia: null,
          incricaoEstadual: null,
          incricaoMunicipal: null,
          cpf: null,
          nome: null,
        });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.EntitySearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await entitysService.search({
        ...inputFilterData,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.EntitySearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Entidades',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar entidades.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: EntitySearchResponseType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);

      switch (action) {
        case 'excluir':
          await entitysService.delete({ idEntidade: item.idEntidade });
          setData(data.filter((x) => x.idEntidade !== item.idEntidade));
          break;
        case 'visualizar':
          navigate(`/entities/${item.idEntidade}`);
          return;
        case 'editar':
          navigate(`/entities/${item.idEntidade}/edit`);
          return;
        default:
          break;
      }

      showNotification({
        title: 'Entidades',
        message: `Entidade ${action === 'cancelar' ? 'cancelado' : 'excluída'} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Entidades',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} a entidade.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Entity}
        title="Entidades"
        description="Gerencie entidades."
        buttons={[
          <Button
            key="Adicionar"
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={() => navigate('/entities/add')}
            data-permission={Permission.EntityAdd}
          >
            Adicionar
          </Button>,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter
            entityTypeData={filterData.entityTypeData}
            filter={filter}
            clear={clear}
            loading={loading}
          />
          <Paper shadow="xs" p="md" withBorder>
            <DataView
              entityTypeData={filterData.entityTypeData}
              data={data}
              confirmActionResult={confirmActionResult}
            />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default EntitySearch;
