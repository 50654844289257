/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
import { UnitOfMeasureType } from '../../models/core/cache.type';
import {
  ProposalEstimateType,
  ProposalFinancialPatchType,
  ProposalItemEnum,
  ProposalType,
} from '../../models/core/proposals.type';
import { EntityItemEnum, RecurrenceData } from '../../utils/constants.utils';
import { convertUnitOfMeasure } from '../../utils/helper.utils';
import { getMonths, getMonthsFromRecurrence, simulate } from '../../utils/moment-recur.utils';

export enum TipoEstimativa {
  Grupo = 'grupo',
  Item = 'item',
}

const montarItens = (
  proposta: ProposalType,
  propostaFinanceiro: ProposalFinancialPatchType | null,
  primario: boolean,
  idCotacao: number | null
) => {
  const data: ProposalEstimateType[] = [];

  // residuos
  for (const residuo of proposta.residuos) {
    for (const plano of residuo.planos.filter((x) => x.primario === (primario ? true : x.primario))) {
      for (const cotacao of plano.cotacoes.filter(
        (x) =>
          x.primario === (primario ? true : x.primario) &&
          x.idPropostaResiduoPlanoCotacao === (idCotacao ?? x.idPropostaResiduoPlanoCotacao)
      )) {
        // acondicionamentos
        for (const cotacaoX of cotacao.acondicionamentos) {
          const planoX = plano.acondicionamentos.find(
            (x) =>
              x.idPropostaResiduoPlanoAcondicionamento === cotacaoX.idPropostaResiduoPlanoAcondicionamento
          )!;
          const { margem, precoFinal, imposto } = propostaFinanceiro
            ? propostaFinanceiro.acondicionamentos.find(
                (x) =>
                  x.idPropostaResiduoPlanoCotacaoAcondicionamento ===
                  cotacaoX.idPropostaResiduoPlanoCotacaoAcondicionamento
              )!
            : cotacaoX;
          data.push({
            id: cotacaoX.idPropostaResiduoPlanoCotacaoAcondicionamento!,
            grupoId: residuo.idPropostaResiduo!,
            grupo: ProposalItemEnum.Residue,
            tipo: EntityItemEnum.Packaging,
            item: residuo.residuoCliente,
            subItem: cotacaoX.residuoAcondicionamento,
            compra: residuo.compra,
            dataInicial: residuo.dataInicial,
            dataFinal: residuo.dataFinal,
            estimativa: residuo.estimativa,
            estimativaIdUnidadeMedida1: residuo.estimativaIdUnidadeMedida1,
            estimativaIdUnidadeMedida2: residuo.estimativaIdUnidadeMedida2,
            recorrencia: residuo.recorrencia,
            plano: {
              quantidade: planoX.quantidade,
              quantidadeIdUnidadeMedida: planoX.quantidadeIdUnidadeMedida,
              frequencia: null,
              frequenciaIdUnidadeMedida: null,
              cargaMedia: null,
              cargaMediaIdUnidadeMedida: null,
            },
            cotacao: {
              preco: cotacaoX.preco,
              receita: false,
              margem: margem === null ? null : margem,
              precoFinal: precoFinal === null ? null : precoFinal,
              imposto: imposto!,
              quantidade: cotacaoX.quantidade,
              quantidadeIdUnidadeMedida: cotacaoX.quantidadeIdUnidadeMedida!,
              frequenciaIdUnidadeMedida: cotacaoX.frequenciaIdUnidadeMedida!,
            },
          });
        }

        // equipamentos
        for (const cotacaoX of cotacao.equipamentos) {
          const planoX = plano.equipamentos.find(
            (x) => x.idPropostaResiduoPlanoEquipamento === cotacaoX.idPropostaResiduoPlanoEquipamento
          )!;
          const { margem, precoFinal, imposto } = propostaFinanceiro
            ? propostaFinanceiro.equipamentos.find(
                (x) =>
                  x.idPropostaResiduoPlanoCotacaoEquipamento ===
                  cotacaoX.idPropostaResiduoPlanoCotacaoEquipamento
              )!
            : cotacaoX;
          data.push({
            id: cotacaoX.idPropostaResiduoPlanoCotacaoEquipamento!,
            grupoId: residuo.idPropostaResiduo!,
            grupo: ProposalItemEnum.Residue,
            tipo: EntityItemEnum.Equipment,
            item: residuo.residuoCliente,
            subItem: cotacaoX.residuoEquipamento,
            compra: residuo.compra,
            dataInicial: residuo.dataInicial,
            dataFinal: residuo.dataFinal,
            estimativa: residuo.estimativa,
            estimativaIdUnidadeMedida1: residuo.estimativaIdUnidadeMedida1,
            estimativaIdUnidadeMedida2: residuo.estimativaIdUnidadeMedida2,
            recorrencia: residuo.recorrencia,
            plano: {
              quantidade: planoX.quantidade,
              quantidadeIdUnidadeMedida: planoX.quantidadeIdUnidadeMedida,
              frequencia: null,
              frequenciaIdUnidadeMedida: null,
              cargaMedia: null,
              cargaMediaIdUnidadeMedida: null,
            },
            cotacao: {
              preco: cotacaoX.preco,
              receita: false,
              margem: margem === null ? null : margem,
              precoFinal: precoFinal === null ? null : precoFinal,
              imposto: imposto!,
              quantidade: cotacaoX.quantidade,
              quantidadeIdUnidadeMedida: cotacaoX.quantidadeIdUnidadeMedida!,
              frequenciaIdUnidadeMedida: cotacaoX.frequenciaIdUnidadeMedida!,
            },
          });
        }

        // veiculos
        if (cotacao.veiculo) {
          const cotacaoX = cotacao.veiculo;
          const { margem, precoFinal, imposto } = propostaFinanceiro
            ? propostaFinanceiro.veiculos.find(
                (x) =>
                  x.idPropostaResiduoPlanoCotacaoVeiculo === cotacaoX.idPropostaResiduoPlanoCotacaoVeiculo
              )!
            : cotacaoX;
          data.push({
            id: cotacaoX.idPropostaResiduoPlanoCotacaoVeiculo!,
            grupoId: residuo.idPropostaResiduo!,
            grupo: ProposalItemEnum.Residue,
            tipo: EntityItemEnum.Vehicle,
            item: residuo.residuoCliente,
            subItem: cotacaoX.residuoVeiculo,
            compra: residuo.compra,
            dataInicial: residuo.dataInicial,
            dataFinal: residuo.dataFinal,
            estimativa: residuo.estimativa,
            estimativaIdUnidadeMedida1: residuo.estimativaIdUnidadeMedida1,
            estimativaIdUnidadeMedida2: residuo.estimativaIdUnidadeMedida2,
            recorrencia: residuo.recorrencia,
            plano: {
              quantidade: plano.veiculo?.quantidade || null,
              quantidadeIdUnidadeMedida: plano.veiculo?.quantidadeIdUnidadeMedida || null,
              frequencia: null,
              frequenciaIdUnidadeMedida: null,
              cargaMedia: plano.veiculo?.cargaMedia || null,
              cargaMediaIdUnidadeMedida: plano.veiculo?.cargaMediaIdUnidadeMedida || null,
            },
            cotacao: {
              preco: cotacaoX.preco,
              receita: false,
              margem: margem === null ? null : margem,
              precoFinal: precoFinal === null ? null : precoFinal,
              imposto: imposto!,
              quantidade: cotacaoX.quantidade,
              quantidadeIdUnidadeMedida: cotacaoX.quantidadeIdUnidadeMedida!,
              frequenciaIdUnidadeMedida: cotacaoX.frequenciaIdUnidadeMedida!,
            },
          });
        }

        // tratamentos
        if (cotacao.tratamento) {
          const cotacaoX = cotacao.tratamento;
          const { margem, precoFinal, imposto } = propostaFinanceiro
            ? propostaFinanceiro.tratamentos.find(
                (x) =>
                  x.idPropostaResiduoPlanoCotacaoTratamento ===
                  cotacaoX.idPropostaResiduoPlanoCotacaoTratamento
              )!
            : cotacaoX;
          data.push({
            id: cotacaoX.idPropostaResiduoPlanoCotacaoTratamento!,
            grupoId: residuo.idPropostaResiduo!,
            grupo: ProposalItemEnum.Residue,
            tipo: EntityItemEnum.Treatment,
            item: residuo.residuoCliente,
            subItem: cotacaoX.residuoTratamento,
            compra: residuo.compra,
            dataInicial: residuo.dataInicial,
            dataFinal: residuo.dataFinal,
            estimativa: residuo.estimativa,
            estimativaIdUnidadeMedida1: residuo.estimativaIdUnidadeMedida1,
            estimativaIdUnidadeMedida2: residuo.estimativaIdUnidadeMedida2,
            recorrencia: residuo.recorrencia,
            plano: {
              quantidade: null,
              quantidadeIdUnidadeMedida: null,
              frequencia: null,
              frequenciaIdUnidadeMedida: null,
              cargaMedia: null,
              cargaMediaIdUnidadeMedida: null,
            },
            cotacao: {
              preco: cotacaoX.preco,
              receita: false,
              margem: margem === null ? null : margem,
              precoFinal: precoFinal === null ? null : precoFinal,
              imposto: imposto!,
              quantidade: cotacaoX.quantidade,
              quantidadeIdUnidadeMedida: cotacaoX.quantidadeIdUnidadeMedida!,
              frequenciaIdUnidadeMedida: null,
            },
          });
        }

        // destinos-finais
        if (cotacao.destinoFinal) {
          const cotacaoX = cotacao.destinoFinal;
          const { margem, precoFinal, imposto } = propostaFinanceiro
            ? propostaFinanceiro.destinosFinais.find(
                (x) =>
                  x.idPropostaResiduoPlanoCotacaoDestinoFinal ===
                  cotacaoX.idPropostaResiduoPlanoCotacaoDestinoFinal
              )!
            : cotacaoX;
          data.push({
            id: cotacaoX.idPropostaResiduoPlanoCotacaoDestinoFinal!,
            grupoId: residuo.idPropostaResiduo!,
            grupo: ProposalItemEnum.Residue,
            tipo: EntityItemEnum.Destination,
            item: residuo.residuoCliente,
            subItem: cotacaoX.residuoDestinoFinal,
            compra: residuo.compra,
            dataInicial: residuo.dataInicial,
            dataFinal: residuo.dataFinal,
            estimativa: residuo.estimativa,
            estimativaIdUnidadeMedida1: residuo.estimativaIdUnidadeMedida1,
            estimativaIdUnidadeMedida2: residuo.estimativaIdUnidadeMedida2,
            recorrencia: residuo.recorrencia,
            plano: {
              quantidade: null,
              quantidadeIdUnidadeMedida: null,
              frequencia: null,
              frequenciaIdUnidadeMedida: null,
              cargaMedia: null,
              cargaMediaIdUnidadeMedida: null,
            },
            cotacao: {
              preco: cotacaoX.preco,
              receita: cotacaoX.receita,
              margem: margem === null ? null : margem,
              precoFinal: precoFinal === null ? null : precoFinal,
              imposto: imposto!,
              quantidade: cotacaoX.quantidade,
              quantidadeIdUnidadeMedida: cotacaoX.quantidadeIdUnidadeMedida!,
              frequenciaIdUnidadeMedida: null,
            },
          });
        }
      }
    }
  }

  // serviços
  for (const servico of proposta.servicos) {
    for (const cotacao of servico.cotacoes.filter(
      (x) =>
        x.primario === (primario ? true : x.primario) &&
        x.idPropostaServicoCotacao === (idCotacao ?? x.idPropostaServicoCotacao)
    )) {
      const { margem, precoFinal, imposto } = propostaFinanceiro
        ? propostaFinanceiro.servicos.find(
            (x) => x.idPropostaServicoCotacao === cotacao.idPropostaServicoCotacao
          )!
        : cotacao;

      data.push({
        id: cotacao.idPropostaServicoCotacao,
        grupo: ProposalItemEnum.Service,
        grupoId: servico.idPropostaServico!,
        tipo: EntityItemEnum.Service,
        item: servico.servico,
        subItem: servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor',
        compra: false,
        dataInicial: servico.dataInicial,
        dataFinal: servico.dataFinal,
        estimativa: null,
        estimativaIdUnidadeMedida1: null,
        estimativaIdUnidadeMedida2: null,
        recorrencia: servico.recorrencia,
        plano: {
          quantidade: servico.quantidade,
          quantidadeIdUnidadeMedida: servico.frequenciaIdUnidadeMedida1,
          frequencia: servico.frequencia,
          frequenciaIdUnidadeMedida: servico.frequenciaIdUnidadeMedida2,
          cargaMedia: null,
          cargaMediaIdUnidadeMedida: null,
        },
        cotacao: {
          preco: cotacao.preco,
          receita: false,
          margem: margem === null ? null : margem,
          precoFinal: precoFinal === null ? null : precoFinal,
          imposto: imposto!,
          quantidade: cotacao.quantidade,
          quantidadeIdUnidadeMedida: cotacao.frequenciaIdUnidadeMedida1!,
          frequenciaIdUnidadeMedida: cotacao.frequenciaIdUnidadeMedida2,
        },
      });
    }
  }

  return data;
};

const calcularPreco = (
  precoOriginal: number,
  preco: number,
  margem: number | null | undefined,
  precoFinal: number | null | undefined,
  imposto: number,
  compra: boolean,
  receita: boolean,
  arredondar = true
) => {
  let calculo = {
    preco: 0,
    imposto: 0,
    novoPrecoComMargem: 0,
    novoPrecoComMargemComImposto: 0,
    novaMargemFinal: 0,
    novaMargemFinalPorcentagem: 0,
  };

  if (compra && receita) {
    const novoPreco =
      precoFinal !== null && precoFinal !== undefined ? precoFinal : preco - preco * (margem! * 0.01);
    const novoImposto = preco - (preco - preco * (imposto / 100));

    const novoPrecoComMargem = novoPreco;
    const novoPrecoComMargemComImposto = novoPrecoComMargem - novoImposto;
    const novaMargemFinal = preco - novoPrecoComMargem - novoImposto;
    const novaMargemFinalPorcentagem = 100 - (novoPrecoComMargem * 100) / (precoOriginal - novoImposto);

    calculo = {
      preco: novoPrecoComMargem,
      imposto: novoImposto,
      novoPrecoComMargem: preco,
      novoPrecoComMargemComImposto,
      novaMargemFinal,
      novaMargemFinalPorcentagem,
    };
  } else if (compra && !receita) {
    const novoPreco =
      precoFinal !== null && precoFinal !== undefined ? precoFinal : preco + preco * (margem! * 0.01);
    const novoImposto = novoPreco - (novoPreco - novoPreco * (imposto * 0.01));

    const novoPrecoComMargem = novoPreco;
    const novoPrecoComMargemComImposto = novoPreco - novoImposto;
    const novaMargemFinal = novoPrecoComMargem - preco - novoImposto;
    const novaMargemFinalPorcentagem = (novoPrecoComMargemComImposto / precoOriginal) * 100 - 100;

    calculo = {
      preco,
      imposto: novoImposto,
      novoPrecoComMargem,
      novoPrecoComMargemComImposto,
      novaMargemFinal,
      novaMargemFinalPorcentagem,
    };
  } else if (!compra && receita) {
    const novoPrecoComMargem = preco;
    const novoPrecoComMargemComImposto = novoPrecoComMargem - novoPrecoComMargem * (imposto / 100);
    const novaMargemFinal = preco - (preco - novoPrecoComMargemComImposto);
    const novaMargemFinalPorcentagem = (preco - (preco - novoPrecoComMargemComImposto) / preco) * 100;

    calculo = {
      preco: 0,
      imposto: novoPrecoComMargem - novoPrecoComMargemComImposto,
      novoPrecoComMargem,
      novoPrecoComMargemComImposto,
      novaMargemFinal,
      novaMargemFinalPorcentagem,
    };
  } else if (!compra && !receita) {
    const novoPrecoComMargem =
      precoFinal !== null && precoFinal !== undefined ? precoFinal : preco + preco * (margem! * 0.01);
    const novoPrecoComMargemComImposto = novoPrecoComMargem - novoPrecoComMargem * (imposto * 0.01);
    const novaMargemFinal =
      novoPrecoComMargem - precoOriginal - (novoPrecoComMargem - novoPrecoComMargemComImposto);
    const novaMargemFinalPorcentagem =
      ((novoPrecoComMargem - preco - (novoPrecoComMargem - novoPrecoComMargemComImposto)) /
        novoPrecoComMargem) *
      100;

    calculo = {
      preco: precoOriginal,
      imposto: novoPrecoComMargem - novoPrecoComMargemComImposto,
      novoPrecoComMargem,
      novoPrecoComMargemComImposto,
      novaMargemFinal,
      novaMargemFinalPorcentagem,
    };
  }

  return arredondar
    ? {
        preco: Number(calculo.preco.toFixed(2)),
        imposto: Number(calculo.imposto.toFixed(2)),
        novoPrecoComMargem: Number(calculo.novoPrecoComMargem.toFixed(2)),
        novoPrecoComMargemComImposto: Number(calculo.novoPrecoComMargemComImposto.toFixed(2)),
        novaMargemFinal: Number(calculo.novaMargemFinal.toFixed(2)),
        novaMargemFinalPorcentagem: Number(calculo.novaMargemFinalPorcentagem.toFixed(2)),
      }
    : calculo;
};

const obterEstimativas = (
  proposta: ProposalType,
  propostaFinanceiro: ProposalFinancialPatchType | null,
  unitOfMeasures: UnitOfMeasureType[],
  primario: boolean,
  idCotacao: number | null
) => {
  const propostaMeses = getMonths(proposta.dataInicial, proposta.dataFinal).map((x) => {
    return `${RecurrenceData.Months[x.month]}-${x.year}`;
  });
  const unidadeMedidaMes = unitOfMeasures.find((x) => x.unidadeMedidaSigla === 'mês')!;
  const estimativas = montarItens(proposta, propostaFinanceiro, primario, idCotacao);

  let data: {
    grupo: string;
    item: string;
    mesAno: string;
    preco: number;
    imposto: number;
    novoPrecoComMargem: number;
    novoPrecoComMargemComImposto: number;
    novaMargemFinal: number;
    novaMargemFinalPorcentagem: number;
    valido: boolean;
    vazio: boolean;
    registro: ProposalEstimateType | null;
  }[] = [];

  for (const estimativa of estimativas) {
    let itemMeses = getMonthsFromRecurrence(estimativa.recorrencia).map((x) => {
      return `${RecurrenceData.Months[x.month]}-${x.year}`;
    });

    if (itemMeses.length === 0) {
      itemMeses = getMonths(
        estimativa.recorrencia.dataInicial || estimativa.dataInicial,
        estimativa.recorrencia.dataFinal || estimativa.dataFinal
      ).map((x) => {
        return `${RecurrenceData.Months[x.month]}-${x.year}`;
      });
    }

    const cotacaoQuantidadeUM = unitOfMeasures.find(
      (x) => x.idUnidadeMedida === estimativa.cotacao.quantidadeIdUnidadeMedida
    );
    const cotacaoFrequenciaUM = unitOfMeasures.find(
      (x) => x.idUnidadeMedida === estimativa.cotacao.frequenciaIdUnidadeMedida
    );

    let grupo = 'Serviço';
    if (estimativa.grupo === ProposalItemEnum.Residue) {
      if (estimativa.tipo === EntityItemEnum.Packaging || estimativa.tipo === EntityItemEnum.Equipment) {
        grupo = 'Locação';
      } else if (estimativa.tipo === EntityItemEnum.Vehicle) {
        grupo = 'Transporte';
      } else {
        grupo = 'Destinação';
      }
    }

    for (const propostaMes of propostaMeses) {
      data.push({
        preco: 0,
        imposto: 0,
        novoPrecoComMargem: 0,
        novoPrecoComMargemComImposto: 0,
        novaMargemFinal: 0,
        novaMargemFinalPorcentagem: 0,
        grupo,
        item: estimativa.tipo,
        mesAno: propostaMes,
        valido: true,
        vazio: true,
        registro: estimativa,
      });
    }

    if (estimativa.grupo === ProposalItemEnum.Residue) {
      if (estimativa.tipo === EntityItemEnum.Packaging || estimativa.tipo === EntityItemEnum.Equipment) {
        itemMeses = getMonths(
          estimativa.recorrencia.dataInicial || estimativa.dataInicial,
          estimativa.recorrencia.dataFinal || estimativa.dataFinal
        ).map((x) => {
          return `${RecurrenceData.Months[x.month]}-${x.year}`;
        });

        const frequenciaConversao = convertUnitOfMeasure(unidadeMedidaMes, cotacaoFrequenciaUM!);
        if (cotacaoFrequenciaUM?.unidadeMedidaSigla === 'per') {
          frequenciaConversao.valid = true;
          // frequenciaConversao.value = 1 / itemMeses.length;
          frequenciaConversao.value = 1;
        }

        const precoMensal = frequenciaConversao.valid
          ? Number(
              ((estimativa.cotacao.preco * frequenciaConversao.value) / estimativa.cotacao.quantidade) *
                estimativa.plano.quantidade!
            )
          : 0;

        const precoFinalMensal =
          estimativa.cotacao.precoFinal !== null && estimativa.cotacao.precoFinal !== undefined
            ? frequenciaConversao.valid
              ? Number(
                  ((estimativa.cotacao.precoFinal * frequenciaConversao.value) /
                    estimativa.cotacao.quantidade) *
                    estimativa.plano.quantidade!
                )
              : null
            : null;

        data = data.concat(
          itemMeses.map((x) => {
            return {
              ...calcularPreco(
                estimativa.cotacao.preco,
                precoMensal,
                estimativa.cotacao.margem,
                precoFinalMensal,
                estimativa.cotacao.imposto,
                estimativa.compra,
                estimativa.cotacao.receita
              ),
              grupo,
              item: estimativa.tipo,
              mesAno: x,
              valido: frequenciaConversao.valid,
              vazio: false,
              registro: estimativa,
            };
          })
        );
      } else if (estimativa.tipo === EntityItemEnum.Vehicle) {
        const estimativaQuantidadeUM = unitOfMeasures.find(
          (x) => x.idUnidadeMedida === estimativa.estimativaIdUnidadeMedida1
        )!;
        const cargaMediaUM = unitOfMeasures.find(
          (x) => x.idUnidadeMedida === estimativa.plano.cargaMediaIdUnidadeMedida
        )!;
        const estimativaFrequenciaUM = unitOfMeasures.find(
          (x) => x.idUnidadeMedida === estimativa.estimativaIdUnidadeMedida2
        )!;

        let quantidadeColetaMes = simulate(estimativa.recorrencia).length / propostaMeses.length || 0;
        let estimativaQuantidadeConversao = { valid: true, value: 0 };
        let estimativaFrequenciaConversao = { valid: true, value: 0 };

        if (quantidadeColetaMes < 1) {
          estimativaQuantidadeConversao = convertUnitOfMeasure(estimativaQuantidadeUM, cargaMediaUM);
          estimativaFrequenciaConversao = convertUnitOfMeasure(unidadeMedidaMes, estimativaFrequenciaUM);

          if (estimativaQuantidadeConversao.valid) {
            quantidadeColetaMes =
              (estimativa.estimativa! / estimativa.plano.cargaMedia!) * estimativaQuantidadeConversao.value;
          } else if (estimativaQuantidadeUM?.unidadeMedidaSigla === 'col') {
            quantidadeColetaMes = estimativa.estimativa!;

            if (estimativaFrequenciaUM?.unidadeMedidaSigla === 'per') {
              estimativaFrequenciaConversao.valid = true;
              estimativaFrequenciaConversao.value = 1 / itemMeses.length;
            }
          }
          quantidadeColetaMes *= estimativaFrequenciaConversao.value;
        }

        const precoMensal = Number(
          (estimativa.cotacao.preco / estimativa.cotacao.quantidade) * quantidadeColetaMes
        );

        const precoFinalMensal =
          estimativa.cotacao.precoFinal !== null && estimativa.cotacao.precoFinal !== undefined
            ? Number((estimativa.cotacao.precoFinal / estimativa.cotacao.quantidade) * quantidadeColetaMes)
            : null;

        data = data.concat(
          itemMeses.map((y) => {
            return {
              ...calcularPreco(
                precoMensal,
                precoMensal,
                estimativa.cotacao.margem,
                precoFinalMensal,
                estimativa.cotacao.imposto,
                estimativa.compra,
                estimativa.cotacao.receita
              ),
              grupo,
              item: estimativa.tipo,
              mesAno: y,
              valido: estimativaQuantidadeConversao.valid || estimativaFrequenciaConversao.valid,
              vazio: false,
              registro: estimativa,
            };
          })
        );
      } else if (
        estimativa.tipo === EntityItemEnum.Treatment ||
        estimativa.tipo === EntityItemEnum.Destination
      ) {
        const estimativaQuantidadeUM = unitOfMeasures.find(
          (x) => x.idUnidadeMedida === estimativa.estimativaIdUnidadeMedida1
        )!;
        const estimativaFrequenciaUM = unitOfMeasures.find(
          (x) => x.idUnidadeMedida === estimativa.estimativaIdUnidadeMedida2
        )!;

        const estimativaQuantidadeConversao = convertUnitOfMeasure(
          estimativaQuantidadeUM,
          cotacaoQuantidadeUM!
        );
        const estimativaFrequenciaConversao = convertUnitOfMeasure(unidadeMedidaMes, estimativaFrequenciaUM);

        const precoMensal = Number(
          (estimativa.cotacao.preco / estimativa.cotacao.quantidade) *
            estimativa.estimativa! *
            estimativaQuantidadeConversao.value
        );

        const precoFinalMensal =
          estimativa.cotacao.precoFinal !== null && estimativa.cotacao.precoFinal !== undefined
            ? Number(
                (estimativa.cotacao.precoFinal / estimativa.cotacao.quantidade) *
                  estimativa.estimativa! *
                  estimativaQuantidadeConversao.value
              )
            : null;

        data = data.concat(
          itemMeses.map((y) => {
            return {
              ...calcularPreco(
                precoMensal,
                precoMensal,
                estimativa.cotacao.margem,
                precoFinalMensal,
                estimativa.cotacao.imposto,
                estimativa.compra,
                estimativa.cotacao.receita
              ),
              grupo: 'Destinação',
              item: estimativa.tipo,
              mesAno: y,
              valido: estimativaQuantidadeConversao.valid || estimativaFrequenciaConversao.valid,
              vazio: false,
              registro: estimativa,
            };
          })
        );
      }
    } else if (estimativa.grupo === ProposalItemEnum.Service) {
      const frequenciaConversao = convertUnitOfMeasure(unidadeMedidaMes, cotacaoFrequenciaUM!);
      if (cotacaoFrequenciaUM?.unidadeMedidaSigla === 'per') {
        frequenciaConversao.valid = true;
        // frequenciaConversao.value = 1 / itemMeses.length;
        frequenciaConversao.value = 1;
      }

      const precoMensal = frequenciaConversao.valid
        ? Number(
            ((estimativa.cotacao.preco * frequenciaConversao.value) / estimativa.cotacao.quantidade) *
              estimativa.plano.quantidade!
          )
        : 0;

      const precoFinalMensal =
        estimativa.cotacao.precoFinal !== null && estimativa.cotacao.precoFinal !== undefined
          ? frequenciaConversao.valid
            ? Number(
                ((estimativa.cotacao.precoFinal * frequenciaConversao.value) /
                  estimativa.cotacao.quantidade) *
                  estimativa.plano.quantidade!
              )
            : null
          : null;

      data = data.concat(
        itemMeses.map((x) => {
          return {
            ...calcularPreco(
              estimativa.cotacao.preco,
              precoMensal,
              estimativa.cotacao.margem,
              precoFinalMensal,
              estimativa.cotacao.imposto,
              estimativa.compra,
              estimativa.cotacao.receita
            ),
            grupo,
            item: estimativa.tipo,
            mesAno: x,
            valido: frequenciaConversao.valid,
            vazio: false,
            registro: estimativa,
          };
        })
      );
    }
  }

  return data;
};

const consolidarEstimativas = (
  proposta: ProposalType,
  propostaFinanceiro: ProposalFinancialPatchType | null,
  unitOfMeasures: UnitOfMeasureType[],
  primario: boolean,
  idCotacao: number | null,
  tipoEstimativa: TipoEstimativa
) => {
  const data = obterEstimativas(proposta, propostaFinanceiro, unitOfMeasures, primario, idCotacao);
  const goodData = data.filter((x) => x.valido);
  const badData = Array.from(new Set(data.filter((x) => !x.valido).map((y) => y.registro)));

  const totalData: {
    grupo: string;
    item: string;
    preco: number;
    imposto: number;
    novoPrecoComMargem: number;
    novoPrecoComMargemComImposto: number;
    novaMargemFinal: number;
  }[] = [];

  goodData.reduce((res: any, value: any) => {
    const x = value[tipoEstimativa];

    if (!res[x]) {
      res[x] = {
        grupo: value.grupo,
        item: value.item,
        preco: value.preco,
        imposto: value.imposto,
        novoPrecoComMargem: value.novoPrecoComMargem,
        novoPrecoComMargemComImposto: value.novoPrecoComMargemComImposto,
        novaMargemFinal: value.novaMargemFinal,
      };
      totalData.push(res[x]);
    }
    res[x].preco += value.preco;
    res[x].imposto += value.imposto;
    res[x].novoPrecoComMargem += value.novoPrecoComMargem;
    res[x].novoPrecoComMargemComImposto += value.novoPrecoComMargemComImposto;
    res[x].novaMargemFinal += value.novaMargemFinal;
    return res;
  }, {});

  const grandTotal = {
    receitaBruta: Number(totalData.reduce((a, b) => a + b.novoPrecoComMargem, 0).toFixed(2)),
    custo: Number(totalData.reduce((a, b) => a + b.preco, 0).toFixed(2)),
    imposto: Number(totalData.reduce((a, b) => a + b.imposto, 0).toFixed(2)),
    margem: Number(totalData.reduce((a, b) => a + b.novaMargemFinal, 0).toFixed(2)),
  };

  return { goodData, badData, totalData, grandTotal };
};

export { calcularPreco, consolidarEstimativas, montarItens, obterEstimativas };
