/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Alert,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Text,
  Textarea,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { AlertTriangle, CurrencyReal, Notes, Ruler2, Scale, ZoomMoney } from 'tabler-icons-react';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';
import EntityItemSearch from '../../../../../../../components/core/EntityItemSearch/EntityItemSearch';
import ToleranceForm from '../../../../../../../components/core/Tolerance/ToleranceForm';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { useEventServiceContext } from '../../../../../../../contexts/core/events/EventService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../../../models/core/entities.type';
import { EventServiceQuotationType } from '../../../../../../../models/core/events.type';
import { EntityItemEnum, Feature } from '../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';
import PriceMarginTaxFormAddEdit from '../../../../components/PriceMarginTaxFormAddEdit';

type FormViewData = {
  action: Action;
  id: string;

  fornecedorData: EntitySearchResponseType | null;
  idFornecedor: number | null;
  fornecedor: string;

  quantidade: number;

  frequencia: number | null;
  frequenciaIdUnidadeMedida1: string | null;
  frequenciaIdUnidadeMedida2: string | null;
  preco: number | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventServiceQuotationType | null;
  item: EventServiceQuotationType | null;
  disabled: boolean;
};

const EventServiceQuotationFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const { eventGeneralData } = useEventGeneralContext();
  const cotacaoProposta = eventGeneralData?.propostaServicoData?.cotacoes?.find((y) => y.primario);
  const { eventServiceData } = useEventServiceContext();
  const refToleranceForm = useRef<any>();
  const refPriceMarginTaxForm = useRef<any>();

  const buildFornecedorDataFromItem = (
    item: EventServiceQuotationType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idFornecedor),
      tipos: [],
      cnpj: item.fornecedorCNPJ || null,
      razaoSocial: item.fornecedorRazaoSocial || null,
      nomeFantasia: item.fornecedorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.fornecedorCPF || null,
      nome: item.fornecedorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      fornecedorData: buildFornecedorDataFromItem(props.item),
      idFornecedor: props.item?.idFornecedor || null,
      fornecedor:
        props.item?.fornecedorNomeFantasia ||
        props.item?.fornecedorRazaoSocial ||
        props.item?.fornecedorNome ||
        '',

      quantidade: props.item?.quantidade || eventServiceData.quantidade || 1,

      frequencia: props.item?.frequencia || eventServiceData.frequencia || 1,
      frequenciaIdUnidadeMedida1:
        props.item?.frequenciaIdUnidadeMedida1?.toString() ||
        eventServiceData.frequenciaIdUnidadeMedida1?.toString() ||
        null,
      frequenciaIdUnidadeMedida2:
        props.item?.frequenciaIdUnidadeMedida2?.toString() ||
        eventServiceData.frequenciaIdUnidadeMedida2?.toString() ||
        null,
      preco: props.item?.preco === 0 ? 0 : props.item?.preco || null,

      observacao: props.item?.observacao || '',
    },
    validate: {
      fornecedor: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequencia: (value: number | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida1: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida2: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      preco: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });
  const compativel =
    cotacaoProposta?.quantidade === form.values.quantidade &&
    cotacaoProposta?.frequencia === form.values.frequencia &&
    cotacaoProposta?.frequenciaIdUnidadeMedida1 === Number(form.values.frequenciaIdUnidadeMedida1) &&
    cotacaoProposta?.frequenciaIdUnidadeMedida2 === Number(form.values.frequenciaIdUnidadeMedida2);

  const tabs = [
    {
      key: 'Cotacao',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Cotação
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          {cotacaoProposta && !compativel && (
            <>
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                A quantidade/frequência cotada abaixo não é compatível com a do plano.
              </Alert>
              <Space h="xl" />
            </>
          )}

          <Grid columns={4}>
            <Grid.Col span={3}>
              <EntityItemSearch
                label="Fornecedor"
                referenceData={props.referenceData}
                item={eventServiceData.tipo}
                itemId={
                  (eventServiceData.tipo === EntityItemEnum.Packaging
                    ? eventServiceData.idResiduoAcondicionamento
                    : eventServiceData.tipo === EntityItemEnum.Equipment
                    ? eventServiceData.idResiduoEquipamento
                    : eventServiceData.idServico) || -1
                }
                formItem={form.getInputProps('fornecedor') || undefined}
                idsToBeDisabled={[]}
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('fornecedorData', item);
                    form.setFieldValue('idFornecedor', item.idEntidade);
                    form.setFieldValue(
                      'fornecedor',
                      item.nomeFantasia || item.razaoSocial || item.nome || ''
                    );
                  }
                }}
                disabled={props.disabled}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                label="Quantidade"
                placeholder="Quantidade"
                min={1}
                defaultValue={1}
                step={1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                {...form.getInputProps('quantidade')}
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={3}>
              <Input.Wrapper label="Frequência" required>
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                {eventServiceData.tipo === EntityItemEnum.Service && (
                  <NumberInput
                    style={{ width: 100 }}
                    placeholder="Frequência"
                    min={0.5}
                    defaultValue={1}
                    precision={2}
                    step={0.5}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    required
                    {...form.getInputProps('frequencia')}
                    disabled={props.disabled}
                  />
                )}
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'h') ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla !== 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida1')}
                  disabled={props.disabled}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida2')}
                  disabled={props.disabled}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Preço"
                placeholder="Preço"
                min={0}
                precision={2}
                hideControls
                {...form.getInputProps('preco')}
                required
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <PriceMarginTaxFormAddEdit
                ref={refPriceMarginTaxForm}
                preco={form.values.preco || 0}
                margem={props.item?.margem}
                precoFinal={props.item?.precoFinal}
                imposto={props.item?.imposto}
                compra={false}
                receita={false}
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre a cotação do serviço"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
              disabled={props.disabled}
            />
          </SimpleGrid>
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tolerancias',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Event.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Event.color} weight={500}>
            Tolerâncias
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ToleranceForm
            ref={refToleranceForm}
            refId={props.item?.idEventoServicoCotacao}
            title="Tolerâncias"
            description="Tolerâncias para esta cotação."
            color={Feature.Home.Event.color}
            disabled={props.disabled}
            origData={props.origItem?.tolerancias || []}
            data={props.item?.tolerancias || []}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  const isModified = (priceMarginTax: any): boolean => {
    const origData = `${props.origItem?.idFornecedor || ''}
    |${props.origItem?.quantidade || ''}
    |${Number(props.origItem?.frequencia) || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida1 || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida2 || ''}
    |${Number(props.origItem?.preco) || ''}
    |${Number(props.origItem?.margem) || ''}
    |${Number(props.origItem?.precoFinal) || ''}
    |${Number(props.origItem?.imposto) || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idFornecedor || ''}
    |${form.values.quantidade || ''}
    |${Number(form.values.frequencia) || ''}
    |${form.values.frequenciaIdUnidadeMedida1 || ''}
    |${form.values.frequenciaIdUnidadeMedida2 || ''}
    |${Number(form.values.preco) || ''}
    |${Number(priceMarginTax.margem) || ''}
    |${Number(priceMarginTax.precoFinal) || ''}
    |${Number(priceMarginTax.imposto) || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): EventServiceQuotationType | null {
      const priceMarginTax = refPriceMarginTaxForm?.current?.validate() || {};
      if (form.validate().hasErrors || priceMarginTax === null) {
        return null;
      }

      let { values } = form;
      if (props.origItem) {
        if (isModified(priceMarginTax)) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
            frequenciaIdUnidadeMedida1: form.values.frequenciaIdUnidadeMedida1?.toString() || '',
            frequenciaIdUnidadeMedida2: form.values.frequenciaIdUnidadeMedida2?.toString() || '',
          };
        }
      }

      const formItem: EventServiceQuotationType = {
        ...values,
        ...priceMarginTax,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idEventoServico: props.item?.idEventoServico || -1,
        idEventoServicoCotacao: props.item?.idEventoServicoCotacao || -1,
      };

      formItem.frequenciaIdUnidadeMedida1 = Number(formItem.frequenciaIdUnidadeMedida1);
      formItem.frequenciaUnidadeMedida1 =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida1)
        )?.unidadeMedida || '';

      formItem.frequenciaIdUnidadeMedida2 = Number(formItem.frequenciaIdUnidadeMedida2);
      formItem.frequenciaUnidadeMedida2 =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida2)
        )?.unidadeMedida || '';

      formItem.preco = Number(formItem.preco);

      formItem.idFornecedor = Number(form.values.fornecedorData?.idEntidade);
      formItem.fornecedorCNPJ = form.values.fornecedorData?.cnpj || null;
      formItem.fornecedorRazaoSocial = form.values.fornecedorData?.razaoSocial || null;
      formItem.fornecedorNomeFantasia = form.values.fornecedorData?.nomeFantasia || null;
      formItem.fornecedorCPF = form.values.fornecedorData?.cpf || null;
      formItem.fornecedorNome = form.values.fornecedorData?.nome || null;

      formItem.observacao = formItem.observacao?.trim() || null;
      formItem.tolerancias = refToleranceForm?.current?.validate() || [];

      delete (formItem as any).fornecedorData;

      // define valores da analise financeira
      if (cotacaoProposta) {
        formItem.precoReferencia = calcularPreco(
          cotacaoProposta.preco,
          cotacaoProposta.preco,
          cotacaoProposta.margem,
          cotacaoProposta.precoFinal,
          cotacaoProposta.imposto || 0,
          false,
          false
        ).novoPrecoComMargem;
        formItem.precoReferenciaCompativel = compativel;
      } else {
        formItem.precoReferencia = null;
        formItem.precoReferenciaCompativel = false;
      }

      return formItem;
    },
  }));

  return (
    <form id="event-service-quotation" noValidate>
      <Tabs items={tabs} defaultActiveKey="Cotacao" />
    </form>
  );
});

export default EventServiceQuotationFormAddEdit;
