/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  TextInput,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Building, Calendar, ClearAll, FileDots, Qrcode, Search } from 'tabler-icons-react';
import { getPageFilterInitialValues } from '../../../business/events/filter';
import { Audiencia, AudienciaCodigo, Fonte, Tipo, TipoCodigo } from '../../../business/events/general';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { CompanyType, DocumentType, EventStatusType } from '../../../models/core/cache.type';
import { UserType } from '../../../models/core/users.type';

type FilterData = {
  idEmpresa: string | null;
  listaAudiencia: string[];
  listaTipo: string[];
  listaStatus: string[];
  listaResponsavel: string[];
  fonte: string | undefined;
  idContrato: number | undefined;
  idEntidade: number | undefined;
  idProposta: number | undefined;
  dataInicial: Date;
  dataFinal: Date;
  idDocumentoTipo: string | null;
  numeroDocumento: string | null;
};

type FilterProps = {
  eventStatusData: EventStatusType[];
  companies: CompanyType[];
  users: UserType[];
  documents: DocumentType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
  filterData: {
    idContrato: number | undefined;
    idEntidade: number | undefined;
    idProposta: number | undefined;
    dataInicial: Date | undefined;
    dataFinal: Date | undefined;
    listaAudiencia: string[];
    listaTipo: string[];
    listaStatus: string[];
  } | null;
};

function EventSearchFilter(props: FilterProps) {
  const [currentUser] = useCurrentUser();
  const pageFilter = '';
  const pageFilterInitialValues = getPageFilterInitialValues(pageFilter);

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
      listaResponsavel: [],
      fonte: props.filterData?.idContrato
        ? Fonte.Contrato
        : props.filterData?.idEntidade
        ? Fonte.Entidade
        : props.filterData?.idProposta
        ? Fonte.Entidade
        : undefined,
      idContrato: props.filterData?.idContrato,
      idEntidade: props.filterData?.idEntidade,
      idProposta: props.filterData?.idProposta,
      idDocumentoTipo: null,
      numeroDocumento: null,
      ...pageFilterInitialValues,
      dataInicial: props.filterData?.dataInicial ?? pageFilterInitialValues.dataInicial,
      dataFinal: props.filterData?.dataFinal ?? pageFilterInitialValues.dataFinal,
      listaAudiencia: props.filterData?.listaAudiencia ?? pageFilterInitialValues.listaAudiencia ?? [],
      listaTipo: props.filterData?.listaTipo ?? pageFilterInitialValues.listaTipo ?? [],
      listaStatus: props.filterData?.listaStatus ?? pageFilterInitialValues.listaStatus ?? [],
    },
    validate: {
      dataInicial: (value: Date | undefined, values: FilterData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
        } else if (values.dataFinal) {
          return 'Campo obrigatório se data final estiver preenchida';
        }

        return null;
      },
      dataFinal: (value: Date | undefined, values: FilterData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
          if (values.dataInicial && value < values.dataInicial) {
            return 'Data final não pode ser anterior à data inicial';
          }
        } else if (values.dataInicial) {
          return 'Campo obrigatório se data inicial estiver preenchida';
        }
        return null;
      },
      idContrato: (value: number | undefined, values: FilterData) => {
        if (values.fonte === Fonte.Contrato && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      idEntidade: (value: number | undefined, values: FilterData) => {
        if (values.fonte === Fonte.Entidade && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      idProposta: (value: number | undefined, values: FilterData) => {
        if (values.fonte === Fonte.Proposta && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      numeroDocumento: (value: string | null, values: FilterData) => {
        if (values.idDocumentoTipo && (!value || value.trim() === '')) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const handleSubmit = async (values: FilterData) => {
    if (form.validate().hasErrors) {
      return;
    }

    const formItem: FilterData = {
      ...values,
    };

    if (formItem.fonte === Fonte.Contrato) {
      formItem.idEntidade = undefined;
      formItem.idProposta = undefined;
    } else if (formItem.fonte === Fonte.Entidade) {
      formItem.idContrato = undefined;
      formItem.idProposta = undefined;
    } else if (formItem.fonte === Fonte.Proposta) {
      formItem.idContrato = undefined;
      formItem.idEntidade = undefined;
    } else {
      formItem.idContrato = undefined;
      formItem.idEntidade = undefined;
      formItem.idProposta = undefined;
    }

    if (!formItem.fonte) {
      delete (formItem as any).fonte;
    }

    props.filter(formItem);
  };

  return (
    <form noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={1}>
            <Select
              icon={<Building size={15} />}
              label="Empresa"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.companies.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <MultiSelect
              label="Responsável"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.users.map((x) => {
                return {
                  label: x.idUsuario === currentUser.idUsuario ? `${x.nomeCompleto} (eu)` : x.nomeCompleto,
                  value: x.idUsuario,
                  group: x.empresa,
                };
              })}
              clearable
              searchable
              {...form.getInputProps('listaResponsavel')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <div>
              <Input.Wrapper
                label="Data Inicial / Final"
                description="Filtro: entre"
                style={{ marginBottom: 5 }}
              >
                <div />
              </Input.Wrapper>
              <Group>
                <DatePicker
                  placeholder="Todas..."
                  icon={<Calendar size={15} />}
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  clearable
                  allowFreeInput
                  {...form.getInputProps('dataInicial')}
                />
                <DatePicker
                  placeholder="Todas..."
                  icon={<Calendar size={15} />}
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  clearable
                  allowFreeInput
                  minDate={form.values.dataInicial}
                  {...form.getInputProps('dataFinal')}
                />
              </Group>
            </div>
          </Grid.Col>
          <Grid.Col span={1}>
            <Select
              label="Fonte"
              description="Filtro: -"
              placeholder="Todos..."
              data={[
                { label: Fonte.Contrato, value: Fonte.Contrato },
                { label: Fonte.Entidade, value: Fonte.Entidade },
                { label: Fonte.Proposta, value: Fonte.Proposta },
              ]}
              clearable
              {...form.getInputProps('fonte')}
            />
          </Grid.Col>
          {form.values.fonte === Fonte.Contrato && (
            <Grid.Col span={1}>
              <NumberInput
                label="Contrato #"
                description="Filtro: igual"
                placeholder="Todos..."
                min={1}
                required
                {...form.getInputProps('idContrato')}
              />
            </Grid.Col>
          )}
          {form.values.fonte === Fonte.Entidade && (
            <Grid.Col span={1}>
              <NumberInput
                label="Entidade #"
                description="Filtro: igual"
                placeholder="Todos..."
                min={1}
                required
                {...form.getInputProps('idEntidade')}
              />
            </Grid.Col>
          )}
          {form.values.fonte === Fonte.Proposta && (
            <Grid.Col span={1}>
              <NumberInput
                label="Proposta #"
                description="Filtro: igual"
                placeholder="Todos..."
                min={1}
                required
                {...form.getInputProps('idProposta')}
              />
            </Grid.Col>
          )}
        </Grid>
        <Space h="md" />

        <Grid columns={6}>
          <Grid.Col span={2}>
            <MultiSelect
              label="Audiência"
              description="Filtro: igual"
              placeholder="Todos..."
              data={[
                { label: Audiencia.Comercial, value: AudienciaCodigo.Comercial },
                { label: Audiencia.Financeiro, value: AudienciaCodigo.Financeiro },
                { label: Audiencia.Operacional, value: AudienciaCodigo.Operacional },
              ]}
              clearable
              searchable
              {...form.getInputProps('listaAudiencia')}
              disabled={!!pageFilter || (props.filterData?.listaAudiencia.length ?? 0) > 0}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <MultiSelect
              label="Tipo"
              description="Filtro: igual"
              placeholder="Todos..."
              data={[
                { label: Tipo.Cobranca, value: TipoCodigo.Cobranca },
                { label: Tipo.Coleta, value: TipoCodigo.Coleta },
                { label: Tipo.Comissao, value: TipoCodigo.Comissao },
                { label: Tipo.Documetacao, value: TipoCodigo.Documetacao },
                { label: Tipo.Expiracao, value: TipoCodigo.Expiracao },
                { label: Tipo.Fechamento, value: TipoCodigo.Fechamento },
                { label: Tipo.Lembrete, value: TipoCodigo.Lembrete },
                { label: Tipo.MobilizacaoEnviar, value: TipoCodigo.MobilizacaoEnviar },
                { label: Tipo.MobilizacaoRetirar, value: TipoCodigo.MobilizacaoRetirar },
              ]}
              clearable
              searchable
              {...form.getInputProps('listaTipo')}
              disabled={!!pageFilter || (props.filterData?.listaTipo.length ?? 0) > 0}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <MultiSelect
              label="Status"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.eventStatusData.map((x) => {
                return {
                  value: x.codigoEventoStatus,
                  label: x.eventoStatus,
                };
              })}
              clearable
              searchable
              {...form.getInputProps('listaStatus')}
              disabled={(props.filterData?.listaStatus.length ?? 0) > 0}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <Grid columns={6}>
          <Grid.Col span={2}>
            <div>
              <Input.Wrapper label="Documento" description="Filtro: igual" style={{ marginBottom: 5 }}>
                <div />
              </Input.Wrapper>
              <Group>
                <Select
                  icon={<FileDots size={15} />}
                  placeholder="Todos..."
                  data={props.documents.map((x) => {
                    return {
                      value: x.idDocumentoTipo.toString(),
                      label: x.documentoTipo,
                    };
                  })}
                  searchable
                  clearable
                  {...form.getInputProps('idDocumentoTipo')}
                />
                <TextInput
                  icon={<Qrcode size={15} />}
                  placeholder="Número do documento"
                  maxLength={50}
                  {...form.getInputProps('numeroDocumento')}
                />
              </Group>
            </div>
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <SimpleGrid cols={1}>
          <Group position="apart">
            <Group>
              <Button
                color="secondary"
                leftIcon={<ClearAll size={18} />}
                disabled={props.loading}
                onClick={() => {
                  form.values.fonte = '';
                  form.reset();
                  (form.values.dataInicial as any) = null;
                  (form.values.dataFinal as any) = null;
                  props.clear();
                }}
              >
                Limpar
              </Button>
              <Button
                leftIcon={<Search size={18} />}
                loading={props.loading}
                onClick={() => {
                  handleSubmit(form.values);
                }}
              >
                Pesquisar
              </Button>
            </Group>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { EventSearchFilter };
export type { FilterData };
