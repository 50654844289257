/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Check, X } from 'tabler-icons-react';
import TableCellEllipsis from '../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { ResidueType } from '../../../../../models/core/residues.type';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: ResidueType[];
  confirmActionResult(item: ResidueType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Resíduo',
      key: 'residuo',
      sorter: (a: ResidueType, b: ResidueType) => a.residuo.localeCompare(b.residuo),
      render: (row: ResidueType) => (
        <TableCellEllipsis label={row?.residuo} numberOfChars={135} toolTipWidth={300} />
      ),
    },
    {
      title: 'Unidade Medida',
      key: 'unidadeMedida',
      sorter: (a: ResidueType, b: ResidueType) => {
        return (a.unidadeMedida || '').localeCompare(b.unidadeMedida || '');
      },
      render: (row: ResidueType) => (
        <div>{row?.idUnidadeMedida ? `${row.unidadeMedida} (${row.unidadeMedidaSigla})` : '-'}</div>
      ),
    },
    {
      title: 'Código IBAMA',
      key: 'codigoIBAMA',
      sorter: (a: ResidueType, b: ResidueType) => {
        return (a.codigoIBAMA || '').localeCompare(b.codigoIBAMA || '');
      },
      render: (row: ResidueType) => <div>{row?.codigoIBAMA || '-'}</div>,
    },
    {
      title: 'Perigoso',
      key: 'perigoso',
      dataIndex: 'perigoso',
      sorter: (a: ResidueType, b: ResidueType) => (a.perigoso === b.perigoso ? 0 : a.perigoso ? -1 : 1),
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: ResidueType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: ResidueType) => item.idResiduo}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
