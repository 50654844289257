/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import { Group, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Check, X } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractProposalType } from '../../../../../../models/core/contracts.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateToString, formatTimestampToString } from '../../../../../../utils/formatter.utils';

type ViewProps = {
  data: ContractProposalType[];
};

export default function ProposalView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Proposta #',
      key: 'idProposta',
      sorter: (a: ContractProposalType, b: ContractProposalType) => {
        const aValue = a.idProposta;
        const bValue = b.idProposta;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ContractProposalType) => (
        <ProfileCardLink
          id={row.idProposta.toString()}
          name="Proposta"
          nameSize="sm"
          description={`# ${row.idProposta}`}
          descriptionSize="xs"
          linkPrefix="proposals"
          showLink
        />
      ),
    },
    {
      title: 'Data Inicial de Junção',
      key: 'dataInicialJunção',
      sorter: (a: ContractProposalType, b: ContractProposalType) => {
        const date = new Date();
        return (
          new Date(a.dataInicialJuncao || date).valueOf() - new Date(b.dataInicialJuncao || date).valueOf()
        );
      },
      render: (row: ContractProposalType) =>
        row.dataInicialJuncao ? formatDateToString(row.dataInicialJuncao) : '-',
    },
    {
      title: 'Primário?',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ContractProposalType, b: ContractProposalType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: ContractProposalType, b: ContractProposalType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: ContractProposalType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Data Criação',
      key: 'dataCriacao',
      sorter: (a: ContractProposalType, b: ContractProposalType) =>
        new Date(a.dataCriacao).valueOf() - new Date(b.dataCriacao).valueOf(),
      render: (row: ContractProposalType) => formatTimestampToString(row.dataCriacao),
    },
    {
      title: 'Modificado Por',
      key: 'modificadoPor',
      sorter: (a: ContractProposalType, b: ContractProposalType) =>
        (a.modificadoPor || '').localeCompare(b.modificadoPor || ''),
      render: (row: ContractProposalType) => {
        if (row.idModificadoPor) {
          return (
            <ProfileCardLink id={row.idModificadoPor || ''} name={row.modificadoPor || ''} nameSize="sm" />
          );
        }
        return '-';
      },
    },
    {
      title: 'Data Modificação',
      key: 'dataModificacao',
      sorter: (a: ContractProposalType, b: ContractProposalType) => {
        const date = new Date();
        return new Date(a.dataModificacao || date).valueOf() - new Date(b.dataModificacao || date).valueOf();
      },
      render: (row: ContractProposalType) => {
        if (row.dataModificacao) {
          return formatTimestampToString(row.dataModificacao);
        }
        return '-';
      },
    },
  ];

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Contract.color}
          label="Propostas"
          text="Todos as propostas associadas à este contrato"
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ContractProposalType) => item.idContratoProposta || 0}
        // expandable={{
        //   // eslint-disable-next-line react/no-unstable-nested-components
        //   expandedRowRender: (item) => {
        //     return (
        //       <PageViewAudit
        //         idCriadoPor={item.idCriadoPor}
        //         criadoPor={item.criadoPor}
        //         dataCriacao={item.dataCriacao}
        //         idModificadoPor={item.idModificadoPor || null}
        //         modificadoPor={item.modificadoPor || null}
        //         dataModificacao={item.dataModificacao || null}
        //         size="sm"
        //         gridSize={2}
        //       />
        //     );
        //   },
        // }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
