import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Logout } from 'tabler-icons-react';
import { Menu as MantineMenu, Divider, Group } from '@mantine/core';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import ProfileCardLink from '../../../core/ProfileCardLink/ProfileCardLink';

function Menu() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const onLogOutClickHandler = useCallback(() => {
    navigate('/logout', {
      replace: true,
    });
  }, [navigate]);

  const onProfileClickHandler = useCallback(() => {
    navigate(`/users/${currentUser.idUsuario}`, {
      replace: true,
    });
  }, [navigate, currentUser.idUsuario]);

  return (
    <MantineMenu withArrow trigger="click" position="bottom-end">
      <MantineMenu.Target>
        <Group>
          <ProfileCardLink
            id={currentUser.idUsuario}
            name={currentUser.nomeCompleto}
            nameSize="sm"
            description={currentUser.departamento}
            descriptionSize="xs"
            descriptionLength={50}
            avatarSize="md"
            showLink={false}
          />
        </Group>
      </MantineMenu.Target>
      <MantineMenu.Dropdown style={{ minWidth: 150 }}>
        <MantineMenu.Label>{currentUser.nomeCompleto}</MantineMenu.Label>
        <MantineMenu.Item icon={<User size={14} />} onClick={onProfileClickHandler}>
          Perfil
        </MantineMenu.Item>
        <Divider />
        <MantineMenu.Item color="red" icon={<Logout size={14} />} onClick={onLogOutClickHandler}>
          Sair
        </MantineMenu.Item>
      </MantineMenu.Dropdown>
    </MantineMenu>
  );
}

export default Menu;
