/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Group, Table as MantineTable } from '@mantine/core';
import { ReactNode } from 'react';
import { Status, TipoCodigo } from '../../../../business/events/general';
import { EventSummaryType } from '../../../../models/core/events.type';
import { formatCurrency, formatDateToString } from '../../../../utils/formatter.utils';

type DataViewProps = {
  data: {
    idEvento: number;
    idContrato: number | null;
    idEntidade: number | null;
    idProposta: number | null;
    idEventoVeiculoCompartilhado: number | null;
    codigoEventoStatus: Status;
    codigoEventoTipo: TipoCodigo;
    data: Date;
    resumoJSON: EventSummaryType | null;
  }[];
};

export default function Summary(props: DataViewProps) {
  const buildColeta = () => {
    const nodes: ReactNode[] = [];

    for (const evento of props.data) {
      let { residuo, veiculo, veiculoCompartilhado, tratamento, destinoFinal, cliente } =
        evento.resumoJSON || {};

      veiculo = veiculoCompartilhado ?? veiculo;
      residuo = residuo!;
      cliente = cliente!;

      nodes.push(
        <tr>
          <td>
            {residuo.cliente.clienteNomeFantasia ||
              residuo.cliente.clienteRazaoSocial ||
              residuo.cliente.clienteNome}
          </td>
          <td>{`${residuo.local.logradouro}, ${residuo.local.numero}${
            residuo.local.complemento ? ` - ${residuo.local.complemento}` : ''
          } | ${residuo.local.bairro} - ${residuo.local.cidade} / ${residuo.local.codigoEstado} (${
            residuo.local.cep
          })`}</td>
          <td>{residuo.residuoCliente}</td>
          <td>{formatDateToString(evento.data)}</td>

          <td>
            <Group spacing={2}>
              <div>
                {veiculo?.residuoVeiculo
                  ? `${veiculo?.residuoVeiculo}${
                      evento.idEventoVeiculoCompartilhado ? ` (F${evento.idEventoVeiculoCompartilhado})` : ''
                    }`
                  : '-'}
              </div>
            </Group>
          </td>
          <td>
            {veiculo?.cotacao.fornecedorNomeFantasia ||
              veiculo?.cotacao.fornecedorRazaoSocial ||
              veiculo?.cotacao.fornecedorNome ||
              '-'}
          </td>
          <td>
            {veiculo?.acondicionamentos && veiculo?.acondicionamentos?.length > 0 ? (
              <div>
                {veiculo?.acondicionamentos.map((y) => {
                  return (
                    <div>{`(${y.enviar ? 'E' : 'R'}) ${y.residuoAcondicionamento} (${y.quantidade})`}</div>
                  );
                })}
              </div>
            ) : (
              <>-</>
            )}
          </td>

          <td>{tratamento?.residuoTratamento || '-'}</td>
          <td>
            {tratamento?.cotacao.fornecedorNomeFantasia ||
              tratamento?.cotacao.fornecedorRazaoSocial ||
              tratamento?.cotacao.fornecedorNome ||
              '-'}
          </td>

          <td>{destinoFinal?.residuoDestinoFinal || '-'}</td>
          <td>
            {destinoFinal?.cotacao.fornecedorNomeFantasia ||
              destinoFinal?.cotacao.fornecedorRazaoSocial ||
              destinoFinal?.cotacao.fornecedorNome ||
              '-'}
          </td>
        </tr>
      );
    }

    return (
      <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
        <thead>
          <tr>
            <th colSpan={4}>Geral</th>
            <th colSpan={3}>Veículo</th>
            <th colSpan={2}>Tratamento</th>
            <th colSpan={2}>Destino Final</th>
          </tr>
          <tr>
            <th>Cliente</th>
            <th>Local</th>
            <th>Resíduo</th>
            <th>Data</th>

            <th>Tipo</th>
            <th>Fornecedor</th>
            <th>Acondicionamento(s)</th>

            <th>Tipo</th>
            <th>Fornecedor</th>

            <th>Tipo</th>
            <th>Fornecedor</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  const buildMobilizacao = () => {
    const nodes: ReactNode[] = [];

    for (const evento of props.data) {
      let { residuo, acondicionamento, equipamento, servico, cliente } = evento.resumoJSON || {};

      cliente = servico ? (servico?.cliente as any) : (residuo?.cliente as any) || cliente!;
      const local = servico ? servico?.local : residuo?.local || null;

      nodes.push(
        <tr>
          <td>{cliente?.clienteNomeFantasia || cliente?.clienteRazaoSocial || cliente?.clienteNome}</td>
          <td>
            {local
              ? `${local.logradouro}, ${local.numero}${
                  local.complemento ? ` - ${local.complemento}` : ''
                } | ${local.bairro} - ${local.cidade} / ${local.codigoEstado} (${local.cep})`
              : '-'}
          </td>
          <td>{residuo?.residuoCliente || servico?.servico}</td>
          <td>{formatDateToString(evento.data)}</td>

          <td>{evento.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ? 'Enviar' : 'Retirar'}</td>
          <td>
            {acondicionamento ? 'Acondicionamento' : equipamento ? 'Equipamento' : 'Prestador de Serviço'}
          </td>
          <td>
            {acondicionamento?.residuoAcondicionamento ||
              equipamento?.residuoEquipamento ||
              servico?.cotacao.fornecedorNomeFantasia ||
              servico?.cotacao.fornecedorRazaoSocial ||
              servico?.cotacao.fornecedorNome}
          </td>
          <td>{acondicionamento?.quantidade || equipamento?.quantidade || servico?.quantidade}</td>
        </tr>
      );
    }

    return (
      <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
        <thead>
          <tr>
            <th colSpan={4}>Geral</th>
            <th colSpan={4}>Produto/Serviço</th>
          </tr>
          <tr>
            <th>Cliente</th>
            <th>Local</th>
            <th>Resíduo / Serviço</th>
            <th>Data</th>

            <th>Ação</th>
            <th>Tipo</th>
            <th>Item</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  const buildDocumentacao = () => {
    const nodes: ReactNode[] = [];

    for (const evento of props.data) {
      let { cliente, documentacao } = evento.resumoJSON || {};
      cliente = cliente!;

      nodes.push(
        <tr>
          <td>{cliente.clienteNomeFantasia || cliente.clienteRazaoSocial || cliente.clienteNome}</td>
          <td>{cliente.contato.nome || '-'}</td>
          <td>{formatDateToString(evento.data)}</td>

          <td>{documentacao || '-'}</td>
        </tr>
      );
    }

    return (
      <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
        <thead>
          <tr>
            <th colSpan={3}>Geral</th>
            <th colSpan={1}>Documentação</th>
          </tr>
          <tr>
            <th>Cliente</th>
            <th>Contato</th>
            <th>Data</th>

            <th>Item</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  const buildFechamento = () => {
    const nodes: ReactNode[] = [];

    for (const evento of props.data) {
      let { cliente, entidade, faturamento } = evento.resumoJSON || {};
      cliente = cliente!;

      nodes.push(
        <tr>
          <td>{`Contrato (#${evento.idContrato})`}</td>
          <td>
            {cliente?.clienteNomeFantasia ||
              cliente?.clienteRazaoSocial ||
              cliente?.clienteNome ||
              entidade?.nomeFantasia ||
              entidade?.razaoSocial ||
              entidade?.nome}
          </td>
          <td>{formatDateToString(evento.data)}</td>

          <td>
            {faturamento
              ? `${formatDateToString(faturamento.dataInicial)} - ${formatDateToString(
                  faturamento.dataFinal
                )}`
              : '-'}
          </td>
        </tr>
      );
    }

    return (
      <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
        <thead>
          <tr>
            <th>Fonte</th>
            <th>Cliente</th>
            <th>Data do Fechamento</th>
            <th>Período de Referência</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  const buildComissao = () => {
    const nodes: ReactNode[] = [];

    for (const evento of props.data) {
      let { comissao } = evento.resumoJSON || {};

      nodes.push(
        <tr>
          <td>
            {evento.idContrato
              ? `Contrato (#${evento.idContrato})`
              : evento.idProposta
              ? `Proposta (#${evento.idProposta})`
              : evento.idEntidade
              ? `Entidade (#${evento.idEntidade})`
              : '-'}
          </td>
          <td>{comissao?.nomeFantasia || comissao?.razaoSocial || comissao?.nome}</td>
          <td>{comissao?.porcentagem ? `${comissao.porcentagem}%` : '-'}</td>
          <td>{comissao?.preco ? formatCurrency(comissao.preco || 0) : '-'}</td>
          <td>{comissao?.ocorrencia || '-'}</td>
        </tr>
      );
    }

    return (
      <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
        <thead>
          <tr>
            <th colSpan={2}>Geral</th>
            <th colSpan={3}>Referência</th>
          </tr>
          <tr>
            <th>Fonte</th>
            <th>Entidade</th>
            <th>Porcentagem</th>
            <th>Preço</th>
            <th>Ocorrências</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  const buildLembreteExpiracaoFechamento = () => {
    const nodes: ReactNode[] = [];

    for (const evento of props.data) {
      let { cliente, entidade, documento } = evento.resumoJSON || {};
      cliente = cliente!;

      nodes.push(
        <tr>
          <td>
            {evento.idContrato
              ? `Contrato (#${evento.idContrato})`
              : evento.idProposta
              ? `Proposta (#${evento.idProposta})`
              : evento.idEntidade
              ? `Entidade (#${evento.idEntidade})`
              : '-'}
          </td>
          <td>
            {cliente?.clienteNomeFantasia ||
              cliente?.clienteRazaoSocial ||
              cliente?.clienteNome ||
              entidade?.nomeFantasia ||
              entidade?.razaoSocial ||
              entidade?.nome}
          </td>
          <td>{formatDateToString(evento.data)}</td>

          <td>{documento ? documento.documentoTipo || documento.outroTipo : '-'}</td>
        </tr>
      );
    }

    return (
      <MantineTable className="custom-mantine-table" withBorder withColumnBorders>
        <thead>
          <tr>
            <th colSpan={3}>Geral</th>
            <th colSpan={1}>Referência</th>
          </tr>
          <tr>
            <th>Fonte</th>
            <th>Cliente</th>
            <th>Data</th>

            <th>Item</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </MantineTable>
    );
  };

  const buildExpandableContentByEventoTipo = (): ReactNode => {
    const evento = props.data?.[0];

    switch (evento.codigoEventoTipo) {
      case TipoCodigo.Coleta:
        return buildColeta();
      case TipoCodigo.MobilizacaoEnviar:
      case TipoCodigo.MobilizacaoRetirar:
      case TipoCodigo.Cobranca:
        return buildMobilizacao();
      case TipoCodigo.Comissao:
        return buildComissao();
      case TipoCodigo.Documetacao:
        return buildDocumentacao();
      case TipoCodigo.Lembrete:
      case TipoCodigo.Expiracao:
        return buildLembreteExpiracaoFechamento();
      case TipoCodigo.Fechamento:
        return buildFechamento();
      default:
        return <></>;
    }
  };

  return <>{buildExpandableContentByEventoTipo()}</>;
}
