import axios from 'axios';
import { AxiosCustomRequestType } from '../models/utils/axios.type';
import { authenticate } from './iam/cognito.service';
import { LocalStorageKey } from '../utils/constants.utils';

const instance = axios.create({
  method: 'post',
  baseURL: `https://${process.env.REACT_APP_API_GATEWAY_IAM_AUTH}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getAccessToken = () => {
  return {
    Authorization: `Bearer ${localStorage
      .getItem(LocalStorageKey.ApplicationAccessToken)
      ?.replaceAll('"', '')}`,
  };
};

let isRefreshingToken = false;
const refreshUserAccessToken = async () => {
  isRefreshingToken = true;
  await authenticate();
};

const call = async (request: AxiosCustomRequestType): Promise<any> => {
  const accessToken = getAccessToken();
  try {
    const { status, data } = await instance.request({
      method: request.method,
      url: request.url,
      timeout: Number(request.timeout || 15000),
      data: request?.data,
      params: request?.params,
      headers: {
        ...(request.headers || {}),
        ...accessToken,
        'Content-Type': 'application/json',
      },
    });
    return { status, data };
  } catch (error: any) {
    const statusCode: number = error?.response?.status || error?.code;
    if (statusCode === 401) {
      if (!isRefreshingToken) {
        await refreshUserAccessToken();
        return call(request);
      }
    }
    throw error?.response?.data || error;
  } finally {
    isRefreshingToken = false;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { call };
