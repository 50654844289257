/* eslint-disable react/destructuring-assignment */
import { Building, ClearAll, Hierarchy, Search } from 'tabler-icons-react';
import { Button, Group, Paper, Select, SimpleGrid, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../../models/core/cache.type';

type FilterData = {
  idEmpresa: string;
  departamento: string;
};

type FilterProps = {
  companies: CompanyType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function Filter(props: FilterProps) {
  const [currentUser] = useCurrentUser();

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? '' : currentUser.idEmpresa.toString(),
      departamento: '',
    },
  });

  return (
    <form onSubmit={form.onSubmit(props.filter)}>
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={5}>
          <Select
            icon={<Building size={15} />}
            label="Empresa"
            description="Filtro: igual"
            placeholder="Todos..."
            data={props.companies.map((x) => {
              return {
                value: x.idEmpresa.toString(),
                label: x.empresa,
              };
            })}
            searchable
            clearable={currentUser.executivo}
            disabled={!currentUser.executivo}
            {...form.getInputProps('idEmpresa')}
          />
          <TextInput
            icon={<Hierarchy size={15} />}
            label="Departamento"
            description="Filtro: contém"
            placeholder="Todos..."
            maxLength={50}
            {...form.getInputProps('departamento')}
          />
          <Group>
            <Button
              style={{ marginTop: 44 }}
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              disabled={props.loading}
              onClick={() => {
                form.reset();
                props.clear();
              }}
            >
              Limpar
            </Button>
            <Button
              style={{ marginTop: 44 }}
              leftIcon={<Search size={18} />}
              type="submit"
              loading={props.loading}
            >
              Pesquisar
            </Button>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { Filter };
export type { FilterData };
