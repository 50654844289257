import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Calendar, Lock, Mail, User } from 'tabler-icons-react';
import { Button, PasswordInput, Popover, Progress, Radio, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { DatePicker } from '@mantine/dates';
import SplitViewForm from '../../../components/layout/SplitViewForm/SplitViewForm';
import authService from '../../../services/iam/auth.service';
import PasswordRequirement from '../../../components/iam/PasswordRequirement/PasswordRequirement';

type FormProps = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthdate: Date;
  extras: {
    locale: string;
    invitationId: string;
  };
};

export default function SignUp() {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<FormProps>({
    initialValues: {
      email: queryParams.get('email') || '',
      password: '',
      firstName: '',
      lastName: '',
      gender: 'female',
      birthdate: undefined as any,
      extras: {
        locale: 'pt-BR',
        invitationId: queryParams.get('invitationId') || '',
      },
    },
    validate: {
      firstName: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
      lastName: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
      email: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^\S+@\S+$/.test(value.trim())) {
          return 'Formato inválido (Ex: nome@dominio.com)';
        }
        return null;
      },
      password: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value.trim())) {
          return 'Formato inválido';
        }
        return null;
      },
      birthdate: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }

        return null;
      },
    },
  });

  const handleSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      await authService.signUp(values);
      setSuccess(true);
      showNotification({
        title: 'Cadastre-se',
        message: 'Cadastro realizado com sucesso. Verifique seu e-mail.',
        color: 'green',
      });
      navigate(`/sign-up/confirm?email=${values.email}&code=`);
    } catch (error: any) {
      showNotification({
        title: 'Cadastre-se',
        message: error?.isBusinessException ? error.description : 'Não foi possível se cadastrar.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const requirements = [
    { re: /\d/, label: 'Ao menos um número' },
    { re: /[a-z]/, label: 'Ao menos uma letra minúscula' },
    { re: /[A-Z]/, label: 'Ao menos uma letra maiúscula' },
  ];

  function getStrength(password: string) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
  }

  const [popoverOpened, setPopoverOpened] = useState(false);
  const [popoverValue, setPopoverValue] = useState('');
  const checks = requirements.map((requirement) => (
    <PasswordRequirement
      key={requirement.label}
      label={requirement.label}
      meets={requirement.re.test(popoverValue)}
    />
  ));

  const strength = getStrength(popoverValue);
  const mediumStrength = strength > 50 ? 'yellow' : 'red';
  const color = strength === 100 ? 'teal' : mediumStrength;
  /*
  END - Password PopOver
  */

  return (
    <SplitViewForm title="Cadastre-se">
      <form style={{ width: 450 }} onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <Space h="xl" />
        <TextInput
          icon={<User size={15} />}
          label="Nome"
          placeholder="Digite seu nome"
          maxLength={50}
          required
          {...form.getInputProps('firstName')}
        />
        <Space h="xs" />
        <TextInput
          icon={<User size={15} />}
          label="Sobrenome"
          placeholder="Digite seu sobrenome"
          maxLength={50}
          required
          {...form.getInputProps('lastName')}
        />
        <Space h="xs" />
        <TextInput
          icon={<Mail size={15} />}
          label="E-mail"
          placeholder="Digite seu e-mail"
          type="email"
          maxLength={100}
          disabled
          required
          {...form.getInputProps('email')}
        />
        <Space h="xs" />

        <Popover opened={popoverOpened} position="bottom-start" withArrow transition="pop-top-left">
          <Popover.Target>
            <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
              <PasswordInput
                style={{ width: 450 }}
                icon={<Lock size={15} />}
                label="Senha"
                placeholder="Digite sua senha"
                maxLength={50}
                required
                {...form.getInputProps('password')}
                value={popoverValue}
                onChange={(event) => {
                  setPopoverValue(event.currentTarget.value);
                  form.setFieldValue('password', event.currentTarget.value);
                }}
              />
            </div>
          </Popover.Target>
          <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
            <div>
              <Progress color={color} value={strength} size={5} style={{ marginBottom: 10 }} />
              <PasswordRequirement label="Mínimo de 8 caracteres" meets={popoverValue.length > 7} />
              {checks}
            </div>
          </Popover.Dropdown>
        </Popover>
        <Space h="xs" />

        <Radio.Group label="Gênero" required {...form.getInputProps('gender')}>
          <Radio value="female" label="Feminino" />
          <Radio value="male" label="Masculino" />
        </Radio.Group>
        <Space h="xs" />
        <DatePicker
          icon={<Calendar size={15} />}
          locale="pt-br"
          label="Data nascimento"
          inputFormat="DD/MM/YYYY"
          maxDate={new Date()}
          clearable={false}
          allowFreeInput
          required
          {...form.getInputProps('birthdate')}
        />
        <Space h="xl" />
        <Button fullWidth type="submit" disabled={success} loading={loading}>
          Cadastrar
        </Button>
      </form>
    </SplitViewForm>
  );
}
