import { Relacao } from '../../business/events/general';

type ClosingType = {
  codigoFechamento: string;
  fechamento: string;
  fechamentoDias: number;
};

type ProposalStatusType = {
  codigoPropostaStatus: string;
  propostaStatus: string;
  descricao: string;
  ordem: number;
};

type ContractStatusType = {
  codigoContratoStatus: string;
  contratoStatus: string;
  descricao: string;
  ordem: number;
};

type EventStatusType = {
  codigoEventoStatus: string;
  eventoStatus: string;
  descricao: string;
  ordem: number;
};

type CompanyType = {
  idEmpresa: number;
  empresa: string;
  TOTVSGrupo: string;
  TOTVSFilial: string;
};

type AddressTypeType = {
  codigoEnderecoTipo: string;
  enderecoTipo: string;
};

type EntityTypeType = {
  codigoEntidadeTipo: string;
  entidadeTipo: string;
};

type PaymentMethodType = {
  codigoFormaPagamento: string;
  formaPagamento: string;
};

type ResidueStateOfMatterType = {
  codigoResiduoEstadoFisico: string;
  residuoEstadoFisico: string;
};

type ResidueTreatmentType = {
  codigoResiduoTratamento: string;
  residuoTratamento: string;
  descricao: string;
};

type UnitOfMeasureType = {
  idUnidadeMedida: number;
  unidadeMedida: string;
  unidadeMedidaSigla: string;
  tipo: string;
  tipoOrdem: number;
  residuo: boolean;
  servico: boolean;
};

type InvitationStatusType = {
  codigoUsuarioConviteStatus: string;
  usuarioConviteStatus: string;
};

type DismissalStatusType = {
  codigoUsuarioDesligamentoStatus: string;
  usuarioDesligamentoStatus: string;
};

export enum DocumentTypeGroup {
  Entity = 'ENT',
  Event = 'EVT',
}

type DocumentType = {
  idDocumentoTipo: number;
  codigoDocumentoGrupo: DocumentTypeGroup;
  documentoTipo: string;
};

export enum MovementTypeGroup {
  Acondicionamento = 'ACO',
  Equipamento = 'EQU',
  Veiculo = 'VEI',
  Tratamento = 'TRA',
  DestinoFinal = 'DES',
  Servico = 'SER',
}

export enum MovementTypeGroupCode {
  ACO = 'Acondicionamento',
  EQU = 'Equipamento',
  VEI = 'Veículo',
  TRA = 'Tratamento',
  DES = 'Destino Final',
  SER = 'Serviço',
}

export enum MovementTypeStandard {
  ComissaoFixa = 'COMIF',
  ComissaoPercentual = 'COMIP',
  Depreciacao = 'DEPRE',
  Frete = 'FRETE',
  Imposto = 'IMPOS',
  Locacao = 'LOCAC',
  PrestacaoServico = 'PREST',
  MinimoAceitavel = 'MINAC',
  Servico = 'SERVI',
  Tolerancia = 'TOLER',
  Compra = 'COMPR',
  Venda = 'VENDA',
}

export enum MovementTypeStandardCode {
  COMIF = 'Comissão Fixa',
  COMIP = 'Comissão Percentual',
  DEPRE = 'Depreciação',
  FRETE = 'Frete',
  IMPOS = 'Imposto',
  LOCAC = 'Locação',
  PREST = 'Prestação de Serviço',
  MINAC = 'Mínimo Aceitável',
  SERVI = 'Serviço',
  TOLER = 'Tolerância',
  COMPR = 'Compra',
  VENDA = 'Venda',
}

export enum MovementBillingType {
  Commissao = 'CO',
  GerenciamentoResiduo = 'GR',
  Locacao = 'LO',
  PrestacaoServico = 'PS',
}

export enum MovementBillingTypeCode {
  CO = 'Comissão',
  GR = 'Gerenciamento de Resíduo',
  LO = 'Locação',
  PS = 'Prestação de Serviço',
}

type MovementType = {
  idMovimentacaoTipo: number;
  codigoMovimentacaoPadrao: MovementTypeStandard | null;
  codigoMovimentacaoGrupo: MovementTypeGroup | null;
  movimentacaoTipo: string;
};

type EventMovementBillingType = {
  codigoMovimentacaoFaturamentoTipo: MovementBillingType;
  movimentacaoFaturamentoTipo: string;
};

type EventRelationTypeData = {
  codigoEventoRelacao: Relacao;
  eventoRelacao: string;
};

type IBAMAResidueType = {
  codigoIBAMA: string;
  residuo: string;
  perigoso: boolean;
};

type EventStatusReasonType = {
  codigoEventoStatusMotivo: string;
  eventoStatusMotivo: string;
};

export type {
  ClosingType,
  ProposalStatusType,
  ContractStatusType,
  EventStatusType,
  CompanyType,
  AddressTypeType,
  EntityTypeType,
  PaymentMethodType,
  ResidueStateOfMatterType,
  ResidueTreatmentType,
  UnitOfMeasureType,
  InvitationStatusType,
  DismissalStatusType,
  DocumentType,
  MovementType,
  EventMovementBillingType,
  EventRelationTypeData,
  IBAMAResidueType,
  EventStatusReasonType,
};
