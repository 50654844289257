/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Icon } from 'tabler-icons-react';
import { UnitOfMeasureType } from '../../models/core/cache.type';
import { Action } from '../../models/core/core.type';
import { ProposalResiduePlanQuotationType, ProposalResiduePlanType } from '../../models/core/proposals.type';
import { Feature } from '../../utils/constants.utils';
import { convertUnitOfMeasure } from '../../utils/helper.utils';

type EstimateType = {
  valido: boolean;
  icon: Icon;
  item: string;
  preco: number;
  cobrarSemUso?: boolean;
  receita?: boolean;
  gerenciar?: boolean;
  compartilhado?: boolean;
};

const getUnityOfMeasure = (
  unitOfMeasures: UnitOfMeasureType[],
  idUnidadeMedida: string
): UnitOfMeasureType => {
  return unitOfMeasures.find((x) => x.idUnidadeMedida === Number(idUnidadeMedida))!;
};

const converterLocacao = (
  unitOfMeasures: UnitOfMeasureType[],
  residuoEstimativaIdUnidadeMedida2: string,
  plan: ProposalResiduePlanType,
  quotation: ProposalResiduePlanQuotationType
): EstimateType[] => {
  const residuo = {
    estimativaUnidadeMedida: getUnityOfMeasure(unitOfMeasures, residuoEstimativaIdUnidadeMedida2),
  };

  const packagingIcon = Feature.Reference.Packaging.icon;
  const equipmentIcon = Feature.Reference.Equipment.icon;

  const quotationPackaging: EstimateType[] =
    quotation.acondicionamentos
      .filter((x) => x.action !== Action.Delete)
      .map((y) => {
        const item = y.residuoAcondicionamento;
        const { cobrarSemUso } = y;
        const quotationPlan = plan.acondicionamentos.find((z) =>
          y.idPropostaResiduoPlanoAcondicionamento
            ? z.idPropostaResiduoPlanoAcondicionamento === y.idPropostaResiduoPlanoAcondicionamento
            : (y as any).refItem
            ? z.idPropostaResiduoPlanoAcondicionamento ===
              (y as any).refItem.idPropostaResiduoPlanoAcondicionamento
            : z.id === y.id
        )!;

        if (!quotationPlan) {
          return {
            valido: false,
            icon: packagingIcon,
            item,
            preco: 0,
            cobrarSemUso,
          };
        }

        if (y.frequenciaIdUnidadeMedida === residuo.estimativaUnidadeMedida.idUnidadeMedida) {
          return {
            valido: true,
            icon: packagingIcon,
            item,
            preco: Number(
              quotationPlan.quantidade === y.quantidade
                ? y.preco
                : (y.preco / y.quantidade) * quotationPlan.quantidade
            ),
            cobrarSemUso,
            gerenciar: (quotationPlan as any).gerenciar,
            compartilhado: (quotationPlan as any).compartilhado,
          };
        }

        const conversionResult = convertUnitOfMeasure(
          residuo.estimativaUnidadeMedida,
          getUnityOfMeasure(unitOfMeasures, y.frequenciaIdUnidadeMedida!.toString())
        );

        return {
          valido: conversionResult.valid,
          icon: packagingIcon,
          item,
          preco: conversionResult.valid
            ? Number(((y.preco * conversionResult.value) / y.quantidade) * quotationPlan.quantidade)
            : 0,
          cobrarSemUso,
          gerenciar: (quotationPlan as any).gerenciar,
          compartilhado: (quotationPlan as any).compartilhado,
        };
      }) || [];

  const quotationEquipment =
    quotation.equipamentos
      .filter((x) => x.action !== Action.Delete)
      .map((y) => {
        const item = y.residuoEquipamento;
        const { cobrarSemUso } = y;
        const quotationPlan = plan.equipamentos.find((z) =>
          y.idPropostaResiduoPlanoEquipamento
            ? z.idPropostaResiduoPlanoEquipamento === y.idPropostaResiduoPlanoEquipamento
            : (y as any).refItem
            ? z.idPropostaResiduoPlanoEquipamento === (y as any).refItem.idPropostaResiduoPlanoEquipamento
            : z.id === y.id
        )!;

        if (!quotationPlan) {
          return {
            valido: false,
            icon: packagingIcon,
            item,
            preco: 0,
            cobrarSemUso,
          };
        }

        if (y.frequenciaIdUnidadeMedida === residuo.estimativaUnidadeMedida.idUnidadeMedida) {
          return {
            valido: true,
            icon: equipmentIcon,
            item,
            preco: Number(
              quotationPlan.quantidade === y.quantidade
                ? y.preco
                : (y.preco / y.quantidade) * quotationPlan.quantidade
            ),
            cobrarSemUso,
          };
        }

        const conversionResult = convertUnitOfMeasure(
          residuo.estimativaUnidadeMedida,
          getUnityOfMeasure(unitOfMeasures, y.frequenciaIdUnidadeMedida!.toString())
        );

        return {
          valido: conversionResult.valid,
          icon: equipmentIcon,
          item,
          preco: conversionResult.valid
            ? Number(((y.preco * conversionResult.value) / y.quantidade) * quotationPlan.quantidade)
            : 0,
          cobrarSemUso,
        };
      }) || [];

  return quotationPackaging.concat(quotationEquipment);
};

const converterTransporte = (
  plan: ProposalResiduePlanType,
  quotation: ProposalResiduePlanQuotationType
): EstimateType => {
  const vehicleIcon = Feature.Reference.Vehicle.icon;

  const { veiculo } = quotation;
  const item = veiculo.residuoVeiculo;
  const quotationPlan = plan.veiculo!;

  if (!quotationPlan) {
    return {
      valido: false,
      icon: vehicleIcon,
      item,
      preco: 0,
    };
  }

  return {
    valido: true,
    icon: vehicleIcon,
    item,
    preco: Number(
      quotationPlan.quantidade === veiculo.quantidade
        ? veiculo.preco
        : (veiculo.preco / veiculo.quantidade) * quotationPlan.quantidade
    ),
  };
};

const converterDestinacao = (
  unitOfMeasures: UnitOfMeasureType[],
  residuoEstimativa: number,
  residuoEstimativaIdUnidadeMedida1: string,
  quotation: ProposalResiduePlanQuotationType
): EstimateType[] => {
  const response: EstimateType[] = [];

  const residuo = {
    estimativaUnidadeMedida: getUnityOfMeasure(unitOfMeasures, residuoEstimativaIdUnidadeMedida1),
    quantidade: residuoEstimativa,
  };

  const treatmentIcon = Feature.Reference.Treatment.icon;
  const { tratamento } = quotation;
  if (tratamento && tratamento.action !== Action.Delete) {
    let treatmentAdded = false;
    const tratamentoItem = tratamento.residuoTratamento;

    if (tratamento.quantidadeIdUnidadeMedida === residuo.estimativaUnidadeMedida.idUnidadeMedida) {
      response.push({
        valido: true,
        icon: treatmentIcon,
        item: tratamentoItem,
        preco: Number((tratamento.preco / tratamento.quantidade) * residuo.quantidade),
      });
      treatmentAdded = true;
    }

    if (!treatmentAdded) {
      const conversionResult = convertUnitOfMeasure(
        residuo.estimativaUnidadeMedida,
        getUnityOfMeasure(unitOfMeasures, tratamento.quantidadeIdUnidadeMedida.toString())
      );

      response.push({
        valido: conversionResult.valid,
        icon: treatmentIcon,
        item: tratamentoItem,
        preco: conversionResult.valid
          ? Number(((tratamento.preco * conversionResult.value) / tratamento.quantidade) * residuo.quantidade)
          : 0,
      });
    }
  }

  const destinationIcon = Feature.Reference.Destination.icon;
  const { destinoFinal } = quotation;
  if (destinoFinal && destinoFinal.action !== Action.Delete) {
    let destinationAdded = false;
    const destinoFinalItem = destinoFinal.residuoDestinoFinal;
    const { receita } = destinoFinal;

    if (destinoFinal.quantidadeIdUnidadeMedida === residuo.estimativaUnidadeMedida.idUnidadeMedida) {
      response.push({
        valido: true,
        icon: destinationIcon,
        item: destinoFinalItem,
        preco: Number((destinoFinal.preco / destinoFinal.quantidade) * residuo.quantidade),
        receita,
      });
      destinationAdded = true;
    }

    if (!destinationAdded) {
      const conversionResult = convertUnitOfMeasure(
        residuo.estimativaUnidadeMedida,
        getUnityOfMeasure(unitOfMeasures, destinoFinal.quantidadeIdUnidadeMedida.toString())
      );

      response.push({
        valido: conversionResult.valid,
        icon: destinationIcon,
        item: destinoFinalItem,
        preco: conversionResult.valid
          ? Number(
              ((destinoFinal.preco * conversionResult.value) / destinoFinal.quantidade) * residuo.quantidade
            )
          : 0,
        receita,
      });
    }
  }

  return response;
};

export { converterDestinacao, converterLocacao, converterTransporte };
