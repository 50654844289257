/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { Badge, Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Check, Plus, X } from 'tabler-icons-react';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import { useProposalResidueContext } from '../../../../../../../../contexts/core/proposals/ProposalResidue.context';
import { ProposalResiduePlanProvider } from '../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import {
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanType,
  ProposalResidueType,
  ProposalServiceType,
} from '../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../../../utils/helper.utils';
import ResiduePlanFormAddEdit from './ResiduePlanFormView/ResiduePlanFormAddEdit';
import ResiduePlanFormViewActions from './ResiduePlanFormView/ResiduePlanFormViewActions';

type ProposalFormViewProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
  };
  proposalResidue: ProposalResidueType | null;
  services: ProposalServiceType[];
};

const ResiduePlanFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalResidueData, setProposalResidueData } = useProposalResidueContext();

  const [data, setData] = useState<ProposalResiduePlanType[]>(
    props.proposalResidue?.planos.map((x: ProposalResiduePlanType) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idPropostaResiduoPlano?.toString() || x.idTemporaria || newGuid(),
      };
    }) || []
  );

  const [selectedData, setSelectedData] = useState<ProposalResiduePlanType[]>([]);
  const [quotationItem, setQuotationItem] = useState<ProposalResiduePlanType | null>(null);
  const [opened, setOpened] = useState(false);

  const buildBadges = (list: string[]) => {
    if ((list || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x} variant="outline" size="sm">
            {x}
          </Badge>
        ))}
      </Group>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: '#',
      key: 'idPropostaResiduoPlano',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) => {
        const aValue = a.idPropostaResiduoPlano;
        const bValue = b.idPropostaResiduoPlano;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanType) => <div>{row.idPropostaResiduoPlano || '-'}</div>,
    },
    {
      title: 'Acondicionamentos',
      key: 'acondicionamentos',
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.acondicionamentos?.map((x) => x.residuoAcondicionamento)),
    },
    {
      title: 'Equipamentos',
      key: 'equipamentos',
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.equipamentos?.map((x) => x.residuoEquipamento)),
    },
    {
      title: 'Veiculo',
      key: 'residuoVeiculo',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        (a.veiculo?.residuoVeiculo || '').localeCompare(b.veiculo?.residuoVeiculo || ''),
      render: (row: ProposalResiduePlanType) => buildBadges(row.veiculo ? [row.veiculo?.residuoVeiculo] : []),
    },
    {
      title: 'Tratamento',
      key: 'tratamento',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        (a?.tratamento?.residuoTratamento || '').localeCompare(b?.tratamento?.residuoTratamento || ''),
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.tratamento ? [row.tratamento?.residuoTratamento] : []),
    },
    {
      title: 'Destino Final',
      key: 'destinoFinal',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        (a?.destinoFinal?.residuoDestinoFinal || '').localeCompare(
          b?.destinoFinal?.residuoDestinoFinal || ''
        ),
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.destinoFinal ? [row.destinoFinal?.residuoDestinoFinal] : []),
    },
    {
      title: 'Primário?',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Cotações',
      key: 'cotacoes',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) => {
        const aValue = a.cotacoes.filter((x) => x.action !== Action.Delete).length;
        const bValue = b.cotacoes.filter((x) => x.action !== Action.Delete).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanType) => <div>{row.cotacoes.length}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ResiduePlanFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: ProposalResiduePlanType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex: number;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduoPlano) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
          setProposalResidueData({ ...proposalResidueData, planos: data });
        }
        break;
      case 'editar':
      case 'adicionar':
        setQuotationItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        setProposalResidueData({ ...proposalResidueData, planos: data });
        break;
      case 'primario':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        dataItem = data[dataItemIndex];
        data[dataItemIndex] = { ...dataItem, ...item };

        data.forEach((element, index) => {
          if (index !== dataItemIndex && element.primario) {
            element.primario = false;
            if (element.action === Action.Nothing) {
              element.action = Action.Modify;
            }
          }
        });
        setData(data);
        setProposalResidueData({ ...proposalResidueData, planos: data });
        break;
      default:
        break;
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProposalResiduePlanType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ProposalResiduePlanType) => ({
      name: record.id || record.idPropostaResiduoPlano?.toString(),
    }),
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Resíduo/Plano - ${quotationItem ? 'Editar' : 'Adicionar'}`}
        size="70%"
      >
        <ProposalResiduePlanProvider>
          <ResiduePlanFormAddEdit
            referenceData={props.referenceData}
            origItem={
              quotationItem
                ? props.proposalResidue?.planos?.find(
                    (x) =>
                      x.idPropostaResiduoPlano === quotationItem.idPropostaResiduoPlano &&
                      x.idPropostaResiduo !== -1
                  ) || null
                : null
            }
            item={quotationItem}
            idPropostaResiduo={props.proposalResidue?.idPropostaResiduo || undefined}
            services={props.services}
            callback={confirmActionResult}
          />
        </ProposalResiduePlanProvider>
      </Modal>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Planos"
          text="Planos para este resíduo."
        />
        <Group>
          <Button
            color="accent"
            leftIcon={<Check size={18} />}
            onClick={() => {
              const item = selectedData[0];
              item.primario = true;
              if (item.action === Action.Nothing) {
                item.action = Action.Modify;
              }
              confirmActionResult(item, 'primario', true);
            }}
            disabled={selectedData.length === 0 || selectedData?.[0].primario}
          >
            Definir como primário
          </Button>
          <Button
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={
              !proposalResidueData.residuo ||
              !proposalResidueData.codigoResiduoEstadoFisico ||
              !proposalResidueData.residuoCliente ||
              !proposalResidueData.estimativa ||
              !proposalResidueData.estimativaIdUnidadeMedida1 ||
              !proposalResidueData.estimativaIdUnidadeMedida2 ||
              !proposalResidueData.idEntidadeEndereco
            }
            onClick={() => {
              confirmActionResult(null, 'adicionar', true);
            }}
          >
            Adicionar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowSelection={{ type: 'radio', ...rowSelection }}
        rowKey={(item: ProposalResiduePlanType) => item.id || item.idPropostaResiduoPlano || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ResiduePlanFormView;
