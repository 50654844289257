/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Group, Space } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Check, Plus, Share, X } from 'tabler-icons-react';
import { validarReferenciaServicoCompartilhado } from '../../../../../../business/proposals/validation';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalGeneralContext } from '../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { ProposalResidueProvider } from '../../../../../../contexts/core/proposals/ProposalResidue.context';
import {
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../../../models/core/cache.type';
import { Action, ItemToleranceType } from '../../../../../../models/core/core.type';
import {
  ProposalResiduePlanEquipmentType,
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanQuotationEquipmentType,
  ProposalResiduePlanQuotationPackagingType,
  ProposalResiduePlanQuotationType,
  ProposalResiduePlanType,
  ProposalResidueType,
  ProposalType,
} from '../../../../../../models/core/proposals.type';
import { montarRecorrenciaString } from '../../../../../../utils/business.utils';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import { dateDiffInDaysDisregardTime } from '../../../../../../utils/helper.utils';
import ResidueFormAddEdit from './ResidueFormView/ResidueFormAddEdit';
import ResidueFormViewActions from './ResidueFormView/ResidueFormViewActions';

type ProposalResidueFormViewProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
  };
  proposal: ProposalType | null;
};

const ResidueFormView = forwardRef((props: ProposalResidueFormViewProps, ref) => {
  const { proposalGeneralData } = useProposalGeneralContext();
  const modals = useModals();

  const [data, setData] = useState<ProposalResidueType[]>(
    props.proposal?.residuos.map((x) => {
      return {
        ...x,
        action: Action.Nothing,
        id: x.idPropostaResiduo?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Resíduo',
      key: 'residuo',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
        a.residuoCliente.localeCompare(b.residuoCliente),
      render: (row: ProposalResidueType) => {
        return (
          <ProfileCardLink
            id={row.idResiduo.toString()}
            name={row.residuoCliente}
            nameSize="sm"
            description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
              row.residuoClientePerigoso ? 'Sim' : 'Não'
            }`}
            descriptionSize="xs"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Mínimo Aceitável',
      key: 'minimoAceitavel',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
        const aItem = a.minimoAceitavel
          ? `${a.minimoAceitavel} ${a.minimoAceitavelUnidadeMedida1} / ${a.minimoAceitavelUnidadeMedida2}`
          : '';
        const bItem = a.minimoAceitavel
          ? `${b.minimoAceitavel} ${b.minimoAceitavelUnidadeMedida1} / ${b.minimoAceitavelUnidadeMedida2}`
          : '';
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalResidueType) => {
        if (!row.minimoAceitavel) {
          return '-';
        }
        return (
          <div>{`${row.minimoAceitavel} ${row.minimoAceitavelUnidadeMedida1} / ${row.minimoAceitavelUnidadeMedida2}`}</div>
        );
      },
    },
    {
      title: 'Estimativa',
      key: 'estimativa',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
        const aItem = `${a.estimativa} ${a.estimativaUnidadeMedida1} / ${a.estimativaUnidadeMedida2}`;
        const bItem = `${b.estimativa} ${b.estimativaUnidadeMedida1} / ${b.estimativaUnidadeMedida2}`;
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalResidueType) => {
        return (
          <div>{`${row.estimativa} ${row.estimativaUnidadeMedida1} / ${row.estimativaUnidadeMedida2}`}</div>
        );
      },
    },
    {
      title: 'Recorrência',
      key: 'recorrencia',
      render: (row: ProposalResidueType) => {
        const recorrencia = montarRecorrenciaString(row.recorrencia);
        if (!recorrencia) {
          return '-';
        }
        return (
          <div>
            <div>{recorrencia.str1}</div>
            {recorrencia.str2 ? <div>{recorrencia.str2}</div> : <></>}
          </div>
        );
      },
    },
    {
      title: 'Data Inicial',
      key: 'dataInicial',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
        new Date(a.recorrencia.dataInicial || a.dataInicial).valueOf() -
        new Date(b.recorrencia.dataInicial || b.dataInicial).valueOf(),
      render: (row: ProposalResidueType) =>
        formatDateToString(row.recorrencia.dataInicial || row.dataInicial),
    },
    {
      title: 'Data Final',
      key: 'dataFinal',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
        new Date(a.recorrencia.dataFinal || a.dataFinal).valueOf() -
        new Date(b.recorrencia.dataFinal || b.dataFinal).valueOf(),
      render: (row: ProposalResidueType) => formatDateToString(row.recorrencia.dataFinal || row.dataFinal),
    },
    {
      title: 'Planos',
      key: 'planos',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
        const aValue = a.planos.filter((x) => x.action !== Action.Delete).length;
        const bValue = b.planos.filter((x) => x.action !== Action.Delete).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResidueType) => {
        let isComplete = false;
        const primaryPlan = row.planos.find((x) => x.primario);
        if (primaryPlan) {
          isComplete = primaryPlan.cotacoes.some((x) => x.primario);
        }
        return (
          <Group spacing="xs">
            <div>{row.planos.length || '-'}</div>
            <div>{isComplete ? <Check color="green" /> : <X color="red" />}</div>
            {primaryPlan?.acondicionamentos?.find((x) => x.compartilhado) && (
              <div>
                <Share size={16} />
              </div>
            )}
          </Group>
        );
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResidueType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ResidueFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: ProposalResidueType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduo) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Resíduo - ${item ? 'Editar' : 'Adicionar'}`,
          size: '70%',
          closeOnClickOutside: false,
          children: (
            <ProposalResidueProvider>
              <ResidueFormAddEdit
                referenceData={props.referenceData}
                proposalGeneralData={proposalGeneralData}
                origItem={
                  item
                    ? props.proposal?.residuos.find((x) => x.idPropostaResiduo === item.idPropostaResiduo) ||
                      null
                    : null
                }
                item={item}
                idProposta={props.proposal?.idProposta}
                services={proposalGeneralData.servicos}
                callback={confirmActionResult}
              />
            </ProposalResidueProvider>
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResidueType[] {
      data.forEach((x: ProposalResidueType) => {
        // validate residues against proposal initial and final dates
        if (
          dateDiffInDaysDisregardTime(
            typeof x.dataInicial === 'string' ? moment(x.dataInicial, 'YYYY-MM-DD').toDate() : x.dataInicial,
            proposalGeneralData.dataInicial,
            true
          ) < 0 ||
          dateDiffInDaysDisregardTime(
            typeof x.dataInicial === 'string' ? moment(x.dataInicial, 'YYYY-MM-DD').toDate() : x.dataInicial,
            proposalGeneralData.dataFinal,
            true
          ) > 0
        ) {
          throw Error(
            `Existe(m) resíduo(s) que não se enquadram na proposta: (Data Inicial esperada: ${formatDateToString(
              proposalGeneralData.dataInicial
            )} - ${formatDateToString(
              proposalGeneralData.dataFinal
            )} | Data Inicial atual: ${formatDateToString(x.dataInicial)})`
          );
        }

        if (
          dateDiffInDaysDisregardTime(
            typeof x.dataFinal === 'string' ? moment(x.dataFinal, 'YYYY-MM-DD').toDate() : x.dataFinal,
            proposalGeneralData.dataInicial,
            true
          ) < 0 ||
          dateDiffInDaysDisregardTime(
            typeof x.dataFinal === 'string' ? moment(x.dataFinal, 'YYYY-MM-DD').toDate() : x.dataFinal,
            proposalGeneralData.dataFinal,
            true
          ) > 0
        ) {
          throw Error(
            `Existe(m) resíduo(s) que não se enquadram na proposta: (Data Final esperada: ${formatDateToString(
              proposalGeneralData.dataInicial
            )} - ${formatDateToString(
              proposalGeneralData.dataFinal
            )} | Data Final atual: ${formatDateToString(x.dataFinal)})`
          );
        }

        // remove unnecessary data and format it
        delete (x as any).residuoData;
        delete (x as any).clienteData;
        delete (x as any).enderecoData;

        // set default actions & custom validations
        x.documento.action = x.documento?.action || Action.Nothing;
        x.recorrencia.action = x.recorrencia?.action || Action.Nothing;
        x.planos.forEach((plano: ProposalResiduePlanType) => {
          plano.action = plano.action || Action.Nothing;

          plano.acondicionamentos.forEach((acondicionamento: ProposalResiduePlanPackagingType) => {
            acondicionamento.action = acondicionamento.action || Action.Nothing;

            if (acondicionamento.compartilhado && acondicionamento.action !== Action.Delete) {
              const refSharedService = proposalGeneralData.servicos.find(
                (xs) =>
                  (acondicionamento.idPropostaServico &&
                    xs.idPropostaServico === acondicionamento.idPropostaServico) ||
                  (acondicionamento.idPropostaServicoTemp && xs.id === acondicionamento.idPropostaServicoTemp)
              );

              if (
                !validarReferenciaServicoCompartilhado(
                  acondicionamento.idResiduoAcondicionamento,
                  x.idEntidadeEndereco,
                  refSharedService
                )
              ) {
                throw Error(
                  `Existe(m) resíduo(s) com acondicionamento(s) compartilhado(s) associado(s) com um serviço inválido: Resíduo (Cliente): ${x.residuoCliente} | Acondicionamento: ${acondicionamento.residuoAcondicionamento}`
                );
              }
            }
          });
          plano.equipamentos.forEach((equipamento: ProposalResiduePlanEquipmentType) => {
            equipamento.action = equipamento.action || Action.Nothing;
          });
          if (plano.veiculo) {
            plano.veiculo.action = plano.veiculo.action || Action.Nothing;
          }
          if (plano.tratamento) {
            plano.tratamento.action = plano.tratamento.action || Action.Nothing;
          }
          if (plano.destinoFinal) {
            plano.destinoFinal.action = plano.destinoFinal.action || Action.Nothing;
          }

          plano.cotacoes.forEach((cotacao: ProposalResiduePlanQuotationType) => {
            cotacao.action = cotacao.action || Action.Nothing;

            cotacao.acondicionamentos.forEach(
              (acondicionamento: ProposalResiduePlanQuotationPackagingType) => {
                acondicionamento.action = acondicionamento.action || Action.Nothing;

                acondicionamento.tolerancias.forEach((y: ItemToleranceType) => {
                  y.action = y.action || Action.Nothing;
                });

                // sync shared-service quotation with packaging
                const refSharedPackaging = plano.acondicionamentos.find(
                  (xa) =>
                    acondicionamento.action !== Action.Delete &&
                    xa.compartilhado &&
                    ((xa.idPropostaResiduoPlanoAcondicionamento &&
                      xa.idPropostaResiduoPlanoAcondicionamento ===
                        acondicionamento.idPropostaResiduoPlanoAcondicionamento) ||
                      (xa.id && xa.id === acondicionamento.id))
                );

                if (refSharedPackaging) {
                  // service is valid as per the packaging validation logic above
                  const refSharedService = proposalGeneralData.servicos.find(
                    (xs) =>
                      (refSharedPackaging.idPropostaServico &&
                        xs.idPropostaServico === refSharedPackaging.idPropostaServico) ||
                      (refSharedPackaging.idPropostaServicoTemp &&
                        xs.id === refSharedPackaging.idPropostaServicoTemp)
                  );
                  const refSharedServiceQuotation = refSharedService?.cotacoes.find(
                    (xsq) => xsq.primario && xsq.action !== Action.Delete
                  )!;

                  if (
                    refSharedServiceQuotation?.action === Action.Add ||
                    refSharedServiceQuotation?.action === Action.Modify
                  ) {
                    acondicionamento.action = Action.Modify;

                    acondicionamento.idFornecedor = refSharedServiceQuotation.idFornecedor;
                    acondicionamento.idEntidadeResiduoAcondicionamento =
                      refSharedServiceQuotation.idEntidadeResiduoAcondicionamento!;
                    acondicionamento.quantidade = refSharedServiceQuotation.quantidade;
                    acondicionamento.quantidadeIdUnidadeMedida =
                      refSharedServiceQuotation.frequenciaIdUnidadeMedida1;
                    acondicionamento.frequenciaIdUnidadeMedida =
                      refSharedServiceQuotation.frequenciaIdUnidadeMedida2;
                    acondicionamento.preco = 0;
                    acondicionamento.observacao = refSharedServiceQuotation.observacao;
                  }
                }
              }
            );
            cotacao.equipamentos.forEach((equipamento: ProposalResiduePlanQuotationEquipmentType) => {
              equipamento.action = equipamento.action || Action.Nothing;

              equipamento.tolerancias.forEach((y: ItemToleranceType) => {
                y.action = y.action || Action.Nothing;
              });
            });
            if (cotacao.veiculo) {
              cotacao.veiculo.action = cotacao.veiculo.action || Action.Nothing;

              cotacao.veiculo.tolerancias.forEach((y: ItemToleranceType) => {
                y.action = y.action || Action.Nothing;
              });
            }
            if (cotacao.tratamento) {
              cotacao.tratamento.action = cotacao.tratamento.action || Action.Nothing;

              cotacao.tratamento.tolerancias.forEach((y: ItemToleranceType) => {
                y.action = y.action || Action.Nothing;
              });
            }
            if (cotacao.destinoFinal) {
              cotacao.destinoFinal.action = cotacao.destinoFinal.action || Action.Nothing;

              cotacao.destinoFinal.tolerancias.forEach((y: ItemToleranceType) => {
                y.action = y.action || Action.Nothing;
              });
            }
          });
        });
      });
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempProposal = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempProposal) || 'null');
    if (tempProposal && props.proposal === null) {
      setData(tempProposal.residuos);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Resíduos"
          text="Todos os resíduos a serem gerenciados e requisitados pelo cliente juntamente com seus planos de coleta, tratamento e/ou destinação e cotações."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ProposalResidueType) => item.id || 0}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ResidueFormView;
