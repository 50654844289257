/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Group, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useModals } from '@mantine/modals';
import { EntityContactType, EntityType } from '../../../../../../../models/core/entities.type';
import ContactFormViewActions from './ContactFormViewActions';
import ContactFormAddEdit from './ContactFormAddEdit';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewProps = {
  entity: EntityType | null;
};

const ContactFormView = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [data, setData] = useState<EntityContactType[]>(
    props.entity?.contatos.map((x) => {
      return { ...x, action: Action.Nothing, id: x.idEntidadeContato?.toString() || '' };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: EntityContactType, b: EntityContactType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Setor',
      key: 'setor',
      sorter: (a: EntityContactType, b: EntityContactType) => {
        return (a.setor || '').localeCompare(b.setor || '');
      },
      render: (row: EntityContactType) => <div>{row?.setor || '-'}</div>,
    },
    {
      title: 'E-mail',
      key: 'email',
      sorter: (a: EntityContactType, b: EntityContactType) => {
        return (a.email || '').localeCompare(b.email || '');
      },
      render: (row: EntityContactType) => <div>{row?.email || '-'}</div>,
    },
    {
      title: 'Telefone',
      key: 'telefone',
      sorter: (a: EntityContactType, b: EntityContactType) => {
        return (a.telefone || '').localeCompare(b.telefone || '');
      },
      render: (row: EntityContactType) => <div>{row?.telefone || '-'}</div>,
    },
    {
      title: 'Ramal',
      key: 'telefoneRamal',
      sorter: (a: EntityContactType, b: EntityContactType) => {
        return (a.telefoneRamal || '').localeCompare(b.telefoneRamal || '');
      },
      render: (row: EntityContactType) => <div>{row?.telefoneRamal || '-'}</div>,
    },
    {
      title: 'Celular',
      key: 'celular',
      sorter: (a: EntityContactType, b: EntityContactType) => {
        return (a.celular || '').localeCompare(b.celular || '');
      },
      render: (row: EntityContactType) => <div>{row?.celular || '-'}</div>,
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EntityContactType, b: EntityContactType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EntityContactType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityContactType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ContactFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EntityContactType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeContato) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Contato - ${item ? 'Editar' : 'Adicionar'}`,
          size: 'xl',
          closeOnClickOutside: false,
          children: (
            <ContactFormAddEdit
              origItem={
                item
                  ? props.entity?.contatos.find((x) => x.idEntidadeContato === item.idEntidadeContato) || null
                  : null
              }
              item={item}
              idEntidade={props.entity?.idEntidade}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityContactType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setData(tempEntity.contatos);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Contatos" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityContactType) => item.id || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ContactFormView;
