import { Button, Card, Center, Loader } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, Edit, Trash } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { Permission } from '../../../../models/core/departments.type';
import { ServiceType } from '../../../../models/core/services.type';
import servicesService from '../../../../services/core/services.service';
import { Feature } from '../../../../utils/constants.utils';
import DataView from './components/DataView';

type Result = {
  loading: boolean;
  data: ServiceType | null;
};

function ServiceView() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const modals = useModals();
  const { idServico } = useParams();

  const [result, setResult] = useState<Result>({
    loading: location?.state?.service === undefined,
    data: location?.state?.service || null,
  });
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data: ServiceType | null = null;
      try {
        data = await servicesService.select({ idServico: Number(idServico) });
      } catch (error: any) {
        showNotification({
          title: 'Serviço - Visualizar',
          message: error?.isBusinessException ? error.description : `Não foi possível carregar o serviço.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idServico, navigate, result]);

  const handleAction = async () => {
    try {
      setResult({ loading: false, data: result.data });

      setDeleting(true);
      await servicesService.delete({ idServico: Number(idServico) });
      navigate('/services');

      showNotification({
        title: 'Serviço - Visualizar',
        message: `Serviço excluído com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Serviço - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível excluir o serviço.`,
        color: 'red',
      });
    } finally {
      setDeleting(false);
    }
  };

  const showModal = () =>
    modals.openConfirmModal({
      title: `Gostaria de excluir esse serviço?`,
      size: 'sm',
      closeOnClickOutside: false,
      closeOnEscape: false,
      onConfirm: () => {
        handleAction();
      },
    });

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Service}
        title="Serviço - Visualizar"
        description="Visualize os detalhes de um serviço."
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={deleting || result.loading}
          >
            Voltar
          </Button>,
          <Button
            key="Excluir"
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal();
            }}
            disabled={deleting || result.loading}
            loading={deleting}
            data-permission={Permission.ServiceDelete}
          >
            Excluir
          </Button>,
          <Button
            key="Editar"
            color="accent"
            leftIcon={<Edit size={18} />}
            onClick={() => {
              navigate(`/services/${result?.data?.idServico}/edit`, {
                state: {
                  service: result.data,
                },
              });
            }}
            disabled={deleting || result.loading}
            data-permission={Permission.ServiceEdit}
          >
            Editar
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <DataView data={result.data} />
        </PageContent>
      )}
    </Card>
  );
}

export default ServiceView;
