/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Text,
  Textarea,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { useMemo, useRef } from 'react';
import { CurrencyReal, DeviceFloppy, Notes, Ruler2, Scale, ZoomMoney } from 'tabler-icons-react';
import EntityItemSearch from '../../../../../../../../../components/core/EntityItemSearch/EntityItemSearch';
import PageSection from '../../../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ToleranceForm from '../../../../../../../../../components/core/Tolerance/ToleranceForm';
import { useProposalServiceContext } from '../../../../../../../../../contexts/core/proposals/ProposalService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../../../../../models/core/entities.type';
import { ProposalServiceQuotationType } from '../../../../../../../../../models/core/proposals.type';
import { EntityItemEnum, Feature } from '../../../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  fornecedorData: EntitySearchResponseType | null;
  idFornecedor: number | null;
  fornecedor: string;

  quantidade: number;

  frequencia: number | null;
  frequenciaIdUnidadeMedida1: string | null;
  frequenciaIdUnidadeMedida2: string | null;
  preco: number | null;

  primario: boolean;
  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalServiceQuotationType | null;
  item: ProposalServiceQuotationType | null;
  idPropostaServico: number | undefined;
  callback(item: ProposalServiceQuotationType | null, action: string, confirmed: boolean): void;
};

export default function ServiceQuotationFormAddEdit(props: FormViewProps) {
  const { proposalServiceData } = useProposalServiceContext();

  const refToleranceForm = useRef<any>();

  const buildFornecedorDataFromItem = (
    item: ProposalServiceQuotationType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }

    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idFornecedor),
      idEntidadeServico: (item as any)?.idEntidadeServico,
      idEntidadeResiduoAcondicionamento: (item as any)?.idEntidadeResiduoAcondicionamento,
      idEntidadeResiduoEquipamento: (item as any)?.idEntidadeResiduoEquipamento,
      tipos: [],
      cnpj: item.fornecedorCNPJ || null,
      razaoSocial: item.fornecedorRazaoSocial || null,
      nomeFantasia: item.fornecedorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.fornecedorCPF || null,
      nome: item.fornecedorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const builFrequencyFromContext = useMemo(() => {
    const um1 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === proposalServiceData.frequenciaIdUnidadeMedida1
      )?.unidadeMedida || '';

    const um2 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === proposalServiceData.frequenciaIdUnidadeMedida2
      )?.unidadeMedida || '';

    return `${
      proposalServiceData.tipo === EntityItemEnum.Service ? `${proposalServiceData.frequencia} ` : ''
    }${um1} / ${um2}`;
  }, [
    proposalServiceData.frequencia,
    proposalServiceData.frequenciaIdUnidadeMedida1,
    proposalServiceData.frequenciaIdUnidadeMedida2,
    proposalServiceData.tipo,
    props.referenceData.unitOfMeasures,
  ]);

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      fornecedorData: buildFornecedorDataFromItem(props.item),
      idFornecedor: props.item?.idFornecedor || null,
      fornecedor:
        props.item?.fornecedorNomeFantasia ||
        props.item?.fornecedorRazaoSocial ||
        props.item?.fornecedorNome ||
        '',

      quantidade: props.item?.quantidade || proposalServiceData.quantidade || 1,

      frequencia: props.item?.frequencia || proposalServiceData.frequencia || 1,
      frequenciaIdUnidadeMedida1:
        props.item?.frequenciaIdUnidadeMedida1?.toString() ||
        proposalServiceData.frequenciaIdUnidadeMedida1?.toString() ||
        null,
      frequenciaIdUnidadeMedida2:
        props.item?.frequenciaIdUnidadeMedida2?.toString() ||
        proposalServiceData.frequenciaIdUnidadeMedida2?.toString() ||
        null,
      preco: props.item?.preco === 0 ? 0 : props.item?.preco || null,

      primario: props.item?.primario || false,
      observacao: props.item?.observacao || '',
    },
    validate: {
      fornecedor: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequencia: (value: number | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida1: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida2: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      preco: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idFornecedor || ''}
    |${props.origItem?.quantidade || ''}
    |${Number(props.origItem?.frequencia) || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida1 || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida2 || ''}
    |${Number(props.origItem?.preco) || ''}
    |${props.origItem?.primario || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idFornecedor || ''}
    |${form.values.quantidade || ''}
    |${Number(form.values.frequencia) || ''}
    |${form.values.frequenciaIdUnidadeMedida1 || ''}
    |${form.values.frequenciaIdUnidadeMedida2 || ''}
    |${Number(form.values.preco) || ''}
    |${form.values.primario || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
          frequenciaIdUnidadeMedida1: form.values.frequenciaIdUnidadeMedida1?.toString() || '',
          frequenciaIdUnidadeMedida2: form.values.frequenciaIdUnidadeMedida2?.toString() || '',
        };
      }
    }

    const formItem: ProposalServiceQuotationType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaServico: props.idPropostaServico || -1,
    };

    formItem.frequenciaIdUnidadeMedida1 = Number(formItem.frequenciaIdUnidadeMedida1);
    formItem.frequenciaUnidadeMedida1 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida1)
      )?.unidadeMedida || '';

    formItem.frequenciaIdUnidadeMedida2 = Number(formItem.frequenciaIdUnidadeMedida2);
    formItem.frequenciaUnidadeMedida2 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida2)
      )?.unidadeMedida || '';

    formItem.preco = Number(formItem.preco);

    formItem.idEntidadeServico = (form.values.fornecedorData as any)?.idEntidadeServico;
    (formItem as any).idEntidadeResiduoAcondicionamento = (
      form.values.fornecedorData as any
    )?.idEntidadeResiduoAcondicionamento;
    (formItem as any).idEntidadeResiduoEquipamento = (
      form.values.fornecedorData as any
    )?.idEntidadeResiduoEquipamento;

    formItem.idFornecedor = Number(form.values.fornecedorData?.idEntidade);
    formItem.fornecedorCNPJ = form.values.fornecedorData?.cnpj || null;
    formItem.fornecedorRazaoSocial = form.values.fornecedorData?.razaoSocial || null;
    formItem.fornecedorNomeFantasia = form.values.fornecedorData?.nomeFantasia || null;
    formItem.fornecedorCPF = form.values.fornecedorData?.cpf || null;
    formItem.fornecedorNome = form.values.fornecedorData?.nome || null;

    formItem.idServico = proposalServiceData.servico!.idServico;
    formItem.servico = proposalServiceData.servico!.servico;
    formItem.servicoDescricao = proposalServiceData?.servico?.descricao || null;

    formItem.idResiduoAcondicionamento =
      proposalServiceData.acondicionamento?.idResiduoAcondicionamento || null;
    formItem.residuoAcondicionamento = proposalServiceData.acondicionamento?.residuoAcondicionamento || null;
    formItem.residuoAcondicionamentoDescricao = proposalServiceData?.acondicionamento?.descricao || null;

    formItem.idResiduoEquipamento = proposalServiceData.equipamento?.idResiduoEquipamento || null;
    formItem.residuoEquipamento = proposalServiceData.equipamento?.residuoEquipamento || null;
    formItem.residuoEquipamentoDescricao = proposalServiceData?.equipamento?.descricao || null;

    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.tolerancias = refToleranceForm?.current?.validate() || [];

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  const tabs = [
    {
      key: 'Cotacao',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotação
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <Grid columns={4}>
            <Grid.Col span={3}>
              <EntityItemSearch
                label="Fornecedor"
                referenceData={props.referenceData}
                item={proposalServiceData.tipo}
                itemId={
                  (proposalServiceData.tipo === EntityItemEnum.Packaging
                    ? proposalServiceData.acondicionamento?.idResiduoAcondicionamento
                    : proposalServiceData.tipo === EntityItemEnum.Equipment
                    ? proposalServiceData.equipamento?.idResiduoEquipamento
                    : proposalServiceData.servico?.idServico) || -1
                }
                formItem={form.getInputProps('fornecedor') || undefined}
                idsToBeDisabled={[]}
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('fornecedorData', item);
                    form.setFieldValue('idFornecedor', item.idEntidade);
                    form.setFieldValue(
                      'fornecedor',
                      item.nomeFantasia || item.razaoSocial || item.nome || ''
                    );
                  }
                }}
                disabled={false}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                label="Quantidade"
                placeholder="Quantidade"
                min={1}
                defaultValue={1}
                step={1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                {...form.getInputProps('quantidade')}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={3}>
              <Input.Wrapper label="Frequência" required>
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                {proposalServiceData.tipo === EntityItemEnum.Service && (
                  <NumberInput
                    style={{ width: 100 }}
                    placeholder="Frequência"
                    min={0.5}
                    defaultValue={1}
                    precision={2}
                    step={0.5}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    required
                    {...form.getInputProps('frequencia')}
                  />
                )}
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (proposalServiceData.tipo === EntityItemEnum.Service &&
                          ((x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'h') ||
                            (x.tipo === 'Outro' && x.unidadeMedidaSigla !== 'per'))) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida1')}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida2')}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Preço"
                placeholder="Preço"
                min={0}
                precision={2}
                hideControls
                required
                {...form.getInputProps('preco')}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <PageViewProperty label="Primário?" text={form.values.primario ? 'Sim' : 'Não'} size="sm" />
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre a cotação do serviço"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tolerancias',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Tolerâncias
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ToleranceForm
            ref={refToleranceForm}
            refId={props.item?.idPropostaServicoCotacao}
            title="Tolerâncias"
            description="Tolerâncias para esta cotação."
            color={Feature.Home.Proposal.color}
            disabled={false}
            origData={props.origItem?.tolerancias || []}
            data={props.item?.tolerancias || []}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <form id="service-quotation" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Cotação para:"
            text="Este é o serviço a ser cotado e poderá estar acompanhado de um tipo de acondicionamento ou equipamento."
          />
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={1}>
              <PageViewProperty
                label="Serviço"
                text={proposalServiceData.servico?.servico || '-'}
                size="sm"
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <PageViewProperty label="Quantidade" text={proposalServiceData.quantidade || '-'} size="sm" />
            </Grid.Col>

            {proposalServiceData.tipo === EntityItemEnum.Packaging ? (
              <Grid.Col span={1}>
                <PageViewProperty
                  label="Acondicionamento"
                  text={proposalServiceData.acondicionamento?.residuoAcondicionamento || '-'}
                  size="sm"
                />
              </Grid.Col>
            ) : proposalServiceData.tipo === EntityItemEnum.Equipment ? (
              <Grid.Col span={1}>
                <PageViewProperty
                  label="Equipamento"
                  text={proposalServiceData.equipamento?.residuoEquipamento || '-'}
                  size="sm"
                />
              </Grid.Col>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}

            <Grid.Col span={1}>
              <PageViewProperty label="Frequência" text={builFrequencyFromContext} size="sm" />
            </Grid.Col>
          </Grid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <Tabs items={tabs} defaultActiveKey="Cotacao" />
        </Paper>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="service-quotation">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
