/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Badge, Group, Paper, Select, Space, Switch } from '@mantine/core';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { EventTreatmentProvider } from '../../../../../../../contexts/core/events/EventTreatment.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { EventTreatmentType } from '../../../../../../../models/core/events.type';
import { ProposalResiduePlanQuotationTreatmentType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import ResiduePlanTreatmentFormAddEdit from './EventTreatmentFormAddEdit';
import EventTreatmentQuotationFormAddEdit from './EventTreatmentQuotationFormAddEdit';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type EventTreatmentFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  quotations: ProposalResiduePlanQuotationTreatmentType[];
  origItem: EventTreatmentType | null;
  item: EventTreatmentType | null;
  newEvent: boolean;
  disabled: boolean;
};

const EventTreatmentFormView = forwardRef((props: EventTreatmentFormViewProps, ref) => {
  const refTreatmentForm = useRef<any>();
  const refTreatmentQuotationForm = useRef<any>();

  const [quotation, setQuotation] = useState<ProposalResiduePlanQuotationTreatmentType | null>(
    props.item?.cotacao || null
  );
  const [enabled, setEnabled] = useState<boolean>(props.newEvent || !!props.origItem);

  const { eventGeneralData } = useEventGeneralContext();
  const cotacaoPrimaria = eventGeneralData?.propostaResiduoData?.planos
    ?.find((x) => x.primario)
    ?.cotacoes?.find((y) => y.primario)?.tratamento;

  useImperativeHandle(ref, () => ({
    validate(): any {
      try {
        if (!enabled) {
          return null;
        }

        const tratamento = refTreatmentForm?.current?.validate();
        const cotacao = refTreatmentQuotationForm?.current?.validate();

        if (tratamento === null || cotacao === null) {
          throw Error('Existem pendências a serem corrigidas no formulário do Tratamento.');
        }

        return {
          tratamento: {
            ...tratamento,
            cotacao: {
              ...cotacao,
            },
          },
          idEventoReferencia3: quotation?.idPropostaResiduoPlanoCotacaoTratamento || null,
        };
      } catch (ex: any) {
        throw Error(ex.message || 'Existem pendências a serem corrigidas.');
      }
    },
    clear() {
      refTreatmentForm.current.clear();
      refTreatmentQuotationForm.current.clear();
    },
    enabled() {
      return enabled;
    },
  }));

  return (
    <EventTreatmentProvider>
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Tratamento"
            text="Detalhes do tratamento."
          />
          <Switch
            label="Incluir tratamento?"
            checked={enabled}
            onChange={(event) => {
              setEnabled(event.currentTarget.checked);
              refTreatmentForm?.current?.clear();
              refTreatmentQuotationForm?.current?.clear();
            }}
            disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado || !props.newEvent}
          />
        </Group>
        <Space h="xs" />

        <ResiduePlanTreatmentFormAddEdit
          ref={refTreatmentForm}
          referenceData={props.referenceData}
          origItem={props.origItem}
          item={props.item}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado || !enabled}
        />
      </Paper>

      {enabled && (
        <>
          <Space h="lg" />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <PageSection
                size="lg"
                color={Feature.Home.Event.color}
                label="Fornecedor"
                text="Detalhes do fornecedor."
              />
              <Group>
                {cotacaoPrimaria && (
                  <Badge variant="outline" size="md" color="orange">
                    {`Preço (Cliente): ${formatCurrency(
                      calcularPreco(
                        cotacaoPrimaria.preco,
                        cotacaoPrimaria.preco,
                        cotacaoPrimaria.margem,
                        cotacaoPrimaria.precoFinal,
                        cotacaoPrimaria.imposto || 0,
                        eventGeneralData.propostaResiduoData!.compra,
                        false
                      ).novoPrecoComMargem
                    )} / ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.quantidadeUnidadeMedida}`}
                  </Badge>
                )}
                {props.quotations.length > 0 && !props.origItem && (
                  <Select
                    icon={<ZoomMoney size={15} />}
                    placeholder="Selecione..."
                    data={props.quotations.map((x) => {
                      return {
                        value: x.idPropostaResiduoPlanoCotacaoTratamento!.toString(),
                        label: `${formatCurrency(x.preco)} / ${x.quantidadeUnidadeMedida}`,
                        group: `${
                          x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome || '?'
                        }`,
                      };
                    })}
                    value={quotation?.idPropostaResiduoPlanoCotacaoTratamento?.toString()}
                    onChange={(value) => {
                      let quotationRef =
                        props.quotations.find(
                          (x) => x.idPropostaResiduoPlanoCotacaoTratamento === Number(value)
                        ) || null;

                      if (quotationRef) {
                        quotationRef = {
                          ...props.item?.cotacao,
                          ...quotationRef,
                        };
                      }

                      setQuotation(quotationRef);
                    }}
                    searchable
                    disabled
                  />
                )}
              </Group>
            </Group>
            <Space h="xs" />

            <EventTreatmentQuotationFormAddEdit
              ref={refTreatmentQuotationForm}
              key={quotation?.idPropostaResiduoPlanoCotacaoTratamento || '-1'}
              referenceData={props.referenceData}
              origItem={props.origItem?.cotacao || null}
              item={quotation || null}
              disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
            />
          </Paper>
        </>
      )}
    </EventTreatmentProvider>
  );
});

export default EventTreatmentFormView;
