/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Button, Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { InvitationType } from '../../../../models/core/invitations.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import invitationsService from '../../../../services/core/invitations.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { CompanyType } from '../../../../models/core/cache.type';
import cacheUtils from '../../../../utils/cache.utils';

function InvitationSearch() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [filterResult, setFilterResult] = useState<FilterData>();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
  }>({ companyData: [] });

  const [data, setData] = useState<InvitationType[]>([]);
  const [loading, setLoading] = useState(false);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      const companyData = await cacheUtils.listCompanies();
      const tempCompanyData = JSON.parse(JSON.stringify(companyData));
      if (currentUser.executivo) {
        tempCompanyData.splice(0, 0, { ...currentUser, idEmpresa: 0 });
      }

      setFilterData({
        companyData: tempCompanyData,
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.InvitationSearch) || '[]'
      ) as InvitationType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        filter({ idEmpresa: '', nome: '', email: '' });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.InvitationSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setFilterResult(inputFilterData);
      setLoading(true);
      const result = await invitationsService.search({
        idEmpresa: inputFilterData?.idEmpresa || null,
        nome: inputFilterData?.nome || null,
        email: inputFilterData?.email || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.InvitationSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Convites',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar convites.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: InvitationType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);

      switch (action) {
        case 'excluir':
          await invitationsService.delete({ idUsuarioConvite: item.idUsuarioConvite });
          setData(data.filter((x) => x.idUsuarioConvite !== item.idUsuarioConvite));
          break;
        case 'cancelar':
          await invitationsService.cancel({ idUsuarioConvite: item.idUsuarioConvite });
          await filter(filterResult!);
          break;
        case 'visualizar':
          navigate(`/invitations/${item.idUsuarioConvite}`, {
            state: {
              invitation: item,
            },
          });
          return;
        default:
          break;
      }

      showNotification({
        title: 'Convites',
        message: `Convite ${action === 'cancelar' ? 'cancelado' : 'excluído'} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Convites',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} o convite.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.Invitation}
        title="Convites"
        description="Convide pessoas para contribuir na plataforma e gerencie seus convites."
        buttons={[
          <Button
            key="Convidar"
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={() => navigate('/invitations/add')}
          >
            Convidar
          </Button>,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter companies={filterData.companyData} filter={filter} clear={clear} loading={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default InvitationSearch;
