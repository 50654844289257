/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
import { Alert, Badge, Button, Divider, Group, Menu, Modal, Paper, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Target, Plus, Check, X, AlertTriangle } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalResiduePlanContext } from '../../../../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import {
  EntityTypeType,
  UnitOfMeasureType,
} from '../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanDestinationType,
  ProposalResiduePlanQuotationDestinationType,
  ProposalResiduePlanQuotationType,
} from '../../../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../../../../../../utils/helper.utils';
import RPQuotationDestinationFormAddEdit from './Destination/RPQuotationDestinationFormAddEdit';
import RPQuotationDestinationFormViewActions from './Destination/RPQuotationDestinationFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalResiduePlanQuotation: ProposalResiduePlanQuotationType | null;
};

const ResiduePlanDestinationQuotationFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalResiduePlanData } = useProposalResiduePlanContext();

  const [data, setData] = useState<
    (ProposalResiduePlanQuotationDestinationType & { refItem: ProposalResiduePlanDestinationType | null })[]
  >(
    props.proposalResiduePlanQuotation?.destinoFinal
      ? [
          {
            ...props.proposalResiduePlanQuotation?.destinoFinal,
            refItem: proposalResiduePlanData.destinoFinal || null,
            action: props.proposalResiduePlanQuotation?.destinoFinal?.action || Action.Nothing,
            id:
              props.proposalResiduePlanQuotation?.destinoFinal?.idPropostaResiduoPlanoDestinoFinal?.toString() ||
              props.proposalResiduePlanQuotation?.destinoFinal?.idTemporaria ||
              newGuid(),
          },
        ]
      : []
  );

  const [quotationDestinationItem, setQuotationDestinationItem] =
    useState<ProposalResiduePlanQuotationDestinationType | null>(null);
  const [destinationItem, setDestinationItem] = useState<ProposalResiduePlanDestinationType>();

  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanQuotationDestinationType> = [
    {
      title: 'Destino Final',
      key: 'residuoDestinoFinal',
      sorter: (
        a: ProposalResiduePlanQuotationDestinationType,
        b: ProposalResiduePlanQuotationDestinationType
      ) => {
        return a.residuoDestinoFinal.localeCompare(b.residuoDestinoFinal);
      },
      render: (row: ProposalResiduePlanQuotationDestinationType) => {
        return (
          <ProfileCardLink
            id={(row.idResiduoDestinoFinal || '').toString()}
            name={row.residuoDestinoFinal || '-'}
            nameSize="sm"
            description={row.residuoDestinoFinalDescricao}
            descriptionSize="xs"
            linkPrefix="destinations"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Fornecedor',
      key: 'idFornecedor',
      sorter: (
        a: ProposalResiduePlanQuotationDestinationType,
        b: ProposalResiduePlanQuotationDestinationType
      ) =>
        (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
        ),
      render: (row: ProposalResiduePlanQuotationDestinationType) => {
        if (row.idFornecedor) {
          if (row.fornecedorCNPJ) {
            return (
              <ProfileCardLink
                id={row.idFornecedor.toString()}
                name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
                nameSize="sm"
                description={row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink={false}
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idFornecedor.toString()}
              name={row.fornecedorNome || '-'}
              nameSize="sm"
              description={row.fornecedorCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink={false}
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (
        a: ProposalResiduePlanQuotationDestinationType,
        b: ProposalResiduePlanQuotationDestinationType
      ) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;

        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationDestinationType) => {
        return `${row.quantidade} ${row.quantidadeUnidadeMedida}`;
      },
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (
        a: ProposalResiduePlanQuotationDestinationType,
        b: ProposalResiduePlanQuotationDestinationType
      ) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationDestinationType) => <div>{formatCurrency(row.preco)}</div>,
    },
    {
      title: 'Receita?',
      key: 'receita',
      dataIndex: 'receita',
      sorter: (
        a: ProposalResiduePlanQuotationDestinationType,
        b: ProposalResiduePlanQuotationDestinationType
      ) => (a.receita === b.receita ? 0 : a.receita ? -1 : 1),
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanQuotationDestinationType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <RPQuotationDestinationFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    item: ProposalResiduePlanQuotationDestinationType | null,
    refItem: ProposalResiduePlanDestinationType,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduoPlanoCotacaoDestinoFinal) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setDestinationItem(refItem);
        setQuotationDestinationItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push({ ...item!, refItem });
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  const calculatePendingQuotationItems = () => {
    let allItems = proposalResiduePlanData.destinoFinal ? [proposalResiduePlanData.destinoFinal] : [];
    allItems = allItems.filter((x) => x.action !== Action.Delete);

    const processedItems = data
      .filter((x) => x.action !== Action.Delete)
      .map((y) => {
        return { ...y.refItem, idResiduoDestinoFinal: y.idResiduoDestinoFinal };
      });

    for (const item of processedItems) {
      const index = allItems.findIndex(
        (x) =>
          ((x.id && x.id === item?.id) ||
            (x.idTemporaria && x.idTemporaria === item?.idTemporaria) ||
            (x.idPropostaResiduoPlanoDestinoFinal &&
              x.idPropostaResiduoPlanoDestinoFinal === item?.idPropostaResiduoPlanoDestinoFinal)) &&
          x.idResiduoDestinoFinal === item.idResiduoDestinoFinal
      );
      if (index > -1) {
        allItems.splice(index, 1);
      }
    }

    return allItems;
  };
  const pendingQuotationItems = calculatePendingQuotationItems();
  const quotationItemsToBeDeleted = data.filter(
    (x) =>
      (!(x as any).refItem && x.action !== Action.Delete) ||
      ((x as any).refItem && (x as any).refItem.action === Action.Delete && x.action !== Action.Delete)
  );

  const buildQuotationMenuItemList = (list: ProposalResiduePlanDestinationType[]) => {
    return (
      <>
        {list.map((x) => (
          <div key={newGuid()}>
            <Menu.Item
              key={newGuid()}
              icon={<Target size={14} />}
              onClick={() => {
                confirmActionResult(null, x, 'adicionar', true);
              }}
            >
              {x.residuoDestinoFinal}
            </Menu.Item>
            <Divider key={newGuid()} />
          </div>
        ))}
      </>
    );
  };

  const buildQuotationBadgeList = (list: ProposalResiduePlanDestinationType[]) => {
    return (
      <Group>
        {list.map((x) => (
          <Badge key={newGuid()} variant="outline" size="sm">
            {x.residuoDestinoFinal}
          </Badge>
        ))}
      </Group>
    );
  };

  useImperativeHandle(ref, () => ({
    validate(): { data: ProposalResiduePlanQuotationDestinationType[]; isComplete: boolean } {
      return { data, isComplete: pendingQuotationItems.length === 0 };
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      {pendingQuotationItems.length > 0 && (
        <div>
          <Alert icon={<AlertTriangle size={32} />} title="Destino Final Pendente de Cotação" color="yellow">
            {buildQuotationBadgeList(pendingQuotationItems)}
          </Alert>
          <Space h="lg" />
        </div>
      )}
      {quotationItemsToBeDeleted.length > 0 && (
        <div>
          <Alert icon={<AlertTriangle size={32} />} title="Destino Final a ser excluído:" color="red">
            {buildQuotationBadgeList(JSON.parse(JSON.stringify(quotationItemsToBeDeleted)))}
          </Alert>
          <Space h="lg" />
        </div>
      )}

      <Paper shadow="xs" p="md" withBorder>
        <Modal
          opened={opened}
          closeOnClickOutside={false}
          closeOnEscape={false}
          onClose={() => setOpened(false)}
          title={`Plano/Cotação/Destino Final - ${quotationDestinationItem ? 'Editar' : 'Adicionar'}`}
          size="50%"
        >
          <RPQuotationDestinationFormAddEdit
            referenceData={props.referenceData}
            origItem={
              quotationDestinationItem ? props.proposalResiduePlanQuotation?.destinoFinal || null : null
            }
            item={quotationDestinationItem}
            refItem={destinationItem!}
            idPropostaResiduoPlanoCotacao={props.proposalResiduePlanQuotation?.idPropostaResiduoPlanoCotacao}
            callback={confirmActionResult}
          />
        </Modal>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Destino Final"
            text="Cotação para o destino final selecionado no plano de coleta do resíduo."
          />
          {pendingQuotationItems.length > 0 && (
            <Menu withArrow>
              <Menu.Target>
                <Button color="primary" leftIcon={<Plus size={18} />}>
                  Adicionar
                </Button>
              </Menu.Target>

              <Menu.Dropdown style={{ minWidth: 150 }}>
                {buildQuotationMenuItemList(pendingQuotationItems)}
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
        <Space h="xs" />

        <Table
          showSorterTooltip={false}
          dataSource={data.filter((x) => x.action !== Action.Delete)}
          columns={columns}
          rowKey={(item: ProposalResiduePlanQuotationDestinationType) =>
            item.idPropostaResiduoPlanoCotacaoDestinoFinal || item.id || 0
          }
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
    </div>
  );
});

export default ResiduePlanDestinationQuotationFormView;
