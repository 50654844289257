import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import PageContent from '../../../../components/core/PageContent/PageContent';
import packagingsPackaging from '../../../../services/core/packaging.service';
import { FormView, FormViewData } from './components/FormView';
import { PackagingType } from '../../../../models/core/packaging.type';
import { Feature } from '../../../../utils/constants.utils';
import { Permission } from '../../../../models/core/departments.type';

type DataResult = {
  loading: boolean;
  packagingData: PackagingType | null;
};

function PackagingAddEdit() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { idResiduoAcondicionamento } = useParams();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: (!!idResiduoAcondicionamento && !location?.state?.packaging) || false,
    packagingData: location?.state?.packaging || null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { packagingData } = dataResult;
      try {
        packagingData = await packagingsPackaging.select({
          idResiduoAcondicionamento: Number(idResiduoAcondicionamento),
        });
      } catch (error: any) {
        showNotification({
          title: `Acondicionamento - ${!idResiduoAcondicionamento ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException
            ? error.description
            : 'Não foi possível carregar o acondicionamento.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, packagingData });
      }
    };

    if (idResiduoAcondicionamento && dataResult.packagingData === null) {
      fetchData();
    }
  }, [idResiduoAcondicionamento, navigate, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      let newModel;
      if (!idResiduoAcondicionamento) {
        newModel = await packagingsPackaging.insert({
          ...inputFormViewData,
        });
        navigate('/packaging');
      } else {
        await packagingsPackaging.update({
          ...inputFormViewData,
          idResiduoAcondicionamento: Number(idResiduoAcondicionamento),
        });
      }
      showNotification({
        title: `Acondicionamento - ${!idResiduoAcondicionamento ? 'Adicionar' : 'Editar'}`,
        message: 'Acondicionamento salvo com sucesso.',
        color: 'green',
      });
      navigate(`/packaging/${newModel?.idResiduoAcondicionamento || idResiduoAcondicionamento}`, {
        state: {
          packaging: newModel || null,
        },
      });
    } catch (error: any) {
      showNotification({
        title: `Acondicionamento - ${!idResiduoAcondicionamento ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível salvar o acondicionamento.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Packaging}
        title={`Acondicionamento - ${!idResiduoAcondicionamento ? 'Adicionar' : 'Editar'}`}
        description={`${!idResiduoAcondicionamento ? 'Adicione' : 'Edite'} um acondicionamento.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="packaging-add-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            data-permission={!idResiduoAcondicionamento ? Permission.PackagingAdd : Permission.PackagingEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {idResiduoAcondicionamento && dataResult.packagingData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView packaging={dataResult.packagingData} loading={saving} save={save} />
        </PageContent>
      )}
    </Card>
  );
}

export default PackagingAddEdit;
