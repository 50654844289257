/* eslint-disable import/prefer-default-export */
import { MantineSize } from '@mantine/core';

type MantineSizeMap = {
  size: MantineSize;
  value: number;
};

type MantineSizeExtra = {
  current: MantineSize;
  smaller: MantineSize;
  bigger: MantineSize;
};

function calculateMantineSize(size: MantineSize): MantineSizeExtra {
  const mappings: MantineSizeMap[] = [
    { size: 'xs', value: 0 },
    { size: 'sm', value: 1 },
    { size: 'md', value: 2 },
    { size: 'lg', value: 3 },
    { size: 'xl', value: 4 },
  ];

  const currentSize = mappings.find((x) => x.size === size) || mappings[2];
  return {
    current: currentSize.size,
    smaller: mappings.find((x) => x.value === currentSize.value - 1)?.size || currentSize.size,
    bigger: mappings.find((x) => x.value === currentSize.value + 1)?.size || currentSize.size,
  };
}

export { calculateMantineSize };
