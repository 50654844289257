/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Edit, Eye, Trash } from 'tabler-icons-react';
import { getMovementGroup } from '../../../../../../business/events/measurement';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import {
  MovementTypeGroup,
  MovementTypeGroupCode,
  MovementTypeStandard,
} from '../../../../../../models/core/cache.type';
import { EventMeasurementMovementType, EventType } from '../../../../../../models/core/events.type';
import theme from '../../../../../../theme';
import { formatCurrency } from '../../../../../../utils/formatter.utils';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { validate } from '../../../../../../utils/permission.utils';
import { Permission } from '../../../../../../models/core/departments.type';

type DataViewActionProps = {
  event: EventType;
  editable: boolean;
  item: EventMeasurementMovementType;
  movementTypeGroup: MovementTypeGroup | null;
  movementTypeGroupRefId: number | null;
  movementTypeGroupToleranceId: number | null;

  confirmActionResult(
    item: EventMeasurementMovementType | null,
    group: MovementTypeGroup | null,
    refId: number | null,
    toleranceRefId: number | null,
    supplierId: number | null,
    action: string,
    confirmed: boolean
  ): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function MovementFormViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });

  const getGroup = (row: EventMeasurementMovementType) => {
    const movementGroup = getMovementGroup(row);
    return movementGroup.group ? MovementTypeGroupCode[movementGroup.group] : 'Geral';
  };

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} essa movimentação?`}
      >
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <SimpleGrid cols={5}>
              <PageViewProperty label="Grupo" text={getGroup(props.item)} size="sm" />
              <PageViewProperty
                label="Tipo"
                text={`${props.item.outroTipo ? `(O) ${props.item.outroTipo}` : props.item.movimentacaoTipo}`}
                size="sm"
              />
              <PageViewProperty
                label="Quantidade"
                text={`${props.item.quantidade} ${props.item.quantidadeUnidadeMedida}`}
                size="sm"
              />
              <PageViewProperty label="Receita" text={formatCurrency(props.item.receita)} size="sm" />
              <PageViewProperty
                label="Despesa"
                text={
                  props.item.codigoMovimentacaoPadrao !== MovementTypeStandard.ComissaoPercentual
                    ? formatCurrency(props.item.despesa)
                    : `${formatCurrency(props.item.despesa)} (${
                        props.event.referencia?.comissao?.porcentagem
                      }%)`
                }
                size="sm"
              />
            </SimpleGrid>
          </Paper>
          <Space h="lg" />

          <Group position="right">
            <Button
              color="secondary"
              // leftIcon={<ArrowBackUp size={18} />}
              onClick={() => {
                setModalData({ opened: false, action: '' });
                props.confirmActionResult(
                  props.item,
                  props.movementTypeGroup,
                  props.movementTypeGroupRefId,
                  props.movementTypeGroupToleranceId,
                  props.item.idFornecedor,
                  modalData.action,
                  false
                );
              }}
            >
              Não
            </Button>
            <Button
              // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
              // leftIcon={<Check size={18} />}
              color="primary"
              onClick={() => {
                setModalData({ opened: false, action: '' });
                props.confirmActionResult(
                  props.item,
                  props.movementTypeGroup,
                  props.movementTypeGroupRefId,
                  props.movementTypeGroupToleranceId,
                  props.item.idFornecedor,
                  modalData.action,
                  true
                );
              }}
            >
              Sim
            </Button>
          </Group>
        </div>
      </Modal>

      <Group>
        {!props.editable && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.primary?.[6] }}
              variant="outline"
              onClick={() => {
                props.confirmActionResult(
                  props.item,
                  props.movementTypeGroup,
                  props.movementTypeGroupRefId,
                  props.movementTypeGroupToleranceId,
                  props.item.idFornecedor,
                  'visualizar',
                  true
                );
              }}
            >
              <Eye size={15} color={theme?.colors?.primary?.[6]} />
            </ActionIcon>
          </Tooltip>
        )}
        {props.editable &&
          props.item.codigoMovimentacaoPadrao !== MovementTypeStandard.ComissaoFixa &&
          props.item.codigoMovimentacaoPadrao !== MovementTypeStandard.ComissaoPercentual &&
          validate(Permission.EventEdit, currentUser.permissoes) && (
            <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
              <ActionIcon
                size="sm"
                color="primary"
                style={{ borderColor: theme?.colors?.accent?.[6] }}
                variant="outline"
                onClick={() => {
                  props.confirmActionResult(
                    props.item,
                    props.movementTypeGroup,
                    props.movementTypeGroupRefId,
                    props.movementTypeGroupToleranceId,
                    props.item.idFornecedor,
                    'editar',
                    true
                  );
                }}
              >
                <Edit size={15} color={theme?.colors?.accent?.[6]} />
              </ActionIcon>
            </Tooltip>
          )}
        {props.editable && validate(Permission.EventEdit, currentUser.permissoes) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                setModalData({ opened: true, action: 'excluir' });
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
    </div>
  );
}
