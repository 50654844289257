/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import {
  Accordion,
  Affix,
  Badge,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  Table as MantineTable,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Transition,
} from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowNarrowUp, Briefcase, Share } from 'tabler-icons-react';
import { calcularPreco } from '../../../../business/proposals/estimate';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import PageSection from '../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalStatusType, UnitOfMeasureType } from '../../../../models/core/cache.type';
import { EntityType } from '../../../../models/core/entities.type';
import {
  ProposalResidueType,
  ProposalServiceType,
  ProposalType,
} from '../../../../models/core/proposals.type';
import entitiesService from '../../../../services/core/entities.service';
import proposalsService from '../../../../services/core/proposals.service';
import { montarRecorrenciaString } from '../../../../utils/business.utils';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature } from '../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../utils/formatter.utils';
import { newGuid } from '../../../../utils/helper.utils';

type Result = {
  loading: boolean;
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    proposalStatusTypes: ProposalStatusType[];
  } | null;
  data: { proposal: ProposalType; client: EntityType } | null;
};

function ProposalView() {
  const navigate = useNavigate();
  const { idProposta } = useParams();
  const [scroll, scrollTo] = useWindowScroll();
  const refContent = useRef<any>();

  const [result, setResult] = useState<Result>({
    loading: true,
    referenceData: null,
    data: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      let { referenceData, data } = result;
      try {
        if (result.referenceData === null) {
          referenceData = {
            unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
            proposalStatusTypes: await cacheUtils.listProposalStatuses(),
          };
        }
        const proposal = await proposalsService.select({ idProposta: Number(idProposta) });
        const client = await entitiesService.select({ idEntidade: Number(proposal.idCliente) });
        data = { proposal, client };

        setTimeout(() => window.print(), 1500);
      } catch (error: any) {
        showNotification({
          title: 'Proposta - Imprimir',
          message: error?.isBusinessException ? error.description : `Não foi possível carregar a proposta.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, referenceData, data });
      }
    };

    if (result.data === null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildSectionHeader = (description: string) => {
    return (
      <PageHeader
        feature={Feature.Home.Proposal}
        title={`Proposta #${result.data!.proposal.idProposta}`}
        description={description}
        buttons={[]}
        padding={0}
      />
    );
  };

  const buildGeneral = () => {
    const { proposal } = result.data!;

    return (
      <div style={{ pageBreakAfter: 'always' }}>
        {buildSectionHeader('Informações gerais.')}
        <Paper shadow="xs" p="md" withBorder>
          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Geral" text="" />
          <Space h="xs" />

          <MantineTable withBorder withColumnBorders striped>
            <tbody>
              <tr>
                <td>Proposta de Origem</td>
                <td>{proposal.idPropostaPai || '-'}</td>
                <td>Empresa Responsável</td>
                <td>
                  <Badge variant="outline">{proposal.empresa}</Badge>
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  <Badge variant="outline">{proposal.propostaStatus}</Badge>
                </td>
                <td>Gerenciador</td>
                <td>
                  {!proposal.idGerenciador
                    ? '-'
                    : `${
                        proposal.gerenciadorNomeFantasia ||
                        proposal.gerenciadorRazaoSocial ||
                        proposal.gerenciadorNome
                      } (${proposal.gerenciadorCNPJ || proposal.gerenciadorCPF})`}
                </td>
              </tr>
              <tr>
                <td>Data Inicial</td>
                <td>{formatDateToString(proposal.dataInicial)}</td>
                <td>Contato</td>
                <td>{`${proposal.nome} (${
                  proposal.telefone || proposal.celular || proposal.email || proposal.setor || '-'
                })`}</td>
              </tr>
              <tr>
                <td>Data Final</td>
                <td>{formatDateToString(proposal.dataFinal)}</td>
                <td>Fechamento</td>
                <td>
                  <Badge variant="outline">{proposal.fechamento}</Badge>
                </td>
              </tr>
              <tr>
                <td>Validade</td>
                <td>
                  <Badge variant="outline">{formatDateToString(proposal.validade)}</Badge>
                </td>
                <td>Lembrete da Validade</td>
                <td>{proposal.validadeLembreteDias ? `${proposal.validadeLembreteDias} dia(s)` : '-'}</td>
              </tr>
              <tr>
                <td>Documentação</td>
                <td>{proposal.documentacaoCliente || '-'}</td>
                <td>Lembrete da Documentação</td>
                <td>
                  {proposal.documentacaoClienteRecorrenciaDias
                    ? `${proposal.documentacaoClienteRecorrenciaDias} dia(s)`
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>Observação</td>
                <td colSpan={3}>{proposal.observacao || '-'}</td>
              </tr>
            </tbody>
          </MantineTable>
        </Paper>
      </div>
    );
  };

  const buildClient = () => {
    const { client } = result.data!;

    const buildClientGeneral = () => {
      // const address = client.enderecos.find((x) => x.codigoEnderecoTipo === 'PR');

      return (
        <MantineTable withBorder withColumnBorders striped>
          <tbody>
            <tr>
              <td>Razão Social / Nome</td>
              <td>{client.razaoSocial || client.nome}</td>
              <td>CNPJ / CPF</td>
              <td>
                <Badge variant="outline">{client.cnpj || client.cpf}</Badge>
              </td>
            </tr>
            <tr>
              <td>Nome Fantasia</td>
              <td>{client.nomeFantasia || '-'}</td>
              <td>Inscrição Estadual</td>
              <td>{client.incricaoEstadual || '-'}</td>
            </tr>
            <tr>
              <td>Encontro de Contas?</td>
              <td>
                <Badge variant="outline">{client.aceitaEncontroContas ? 'Sim' : 'Não'}</Badge>
              </td>
              <td>Web Site</td>
              <td>{client.webSite || '-'}</td>
            </tr>
            {/* <tr>
            <td>Endereço Principal</td>
            <td>
              {!address
                ? '-'
                : `${address?.logradouro}, ${address.numero}${
                    address.complemento ? ` - ${address.complemento}` : ''
                  }`}
            </td>
            <td>Cidade / UF</td>
            <td>{!address ? '-' : `${address.cidade} / ${address.codigoEstado}`}</td>
          </tr>
          <tr>
            <td>Bairro</td>
            <td>{!address ? '-' : address?.bairro}</td>
            <td>CEP</td>
            <td>{!address ? '-' : address?.cep}</td>
          </tr> */}
          </tbody>
        </MantineTable>
      );
    };

    const buildPaymentMethod = () => {
      const nodes: ReactNode[] = [];

      for (const item of client.formasPagamento) {
        nodes.push(
          <tr>
            <td>{item.formaPagamento}</td>
            <td>{!item.prazoPagamentoDias ? '-' : `${item.prazoPagamentoDias} dia(s)`}</td>
            <td>{item.recebimento ? 'Recebimento' : 'Pagamento'}</td>
            <td>{item.cnpj || item.cpf}</td>
            <td>{item.nome}</td>
            <td>
              {item.codigoFormaPagamento === 'PX' && item.recebimento ? (
                `Chave PIX: ${item.chavePix}`
              ) : item.codigoFormaPagamento === 'TB' && item.recebimento ? (
                <div>
                  <div>Banco: {item.banco}</div>
                  <div>
                    Agência: {item.agencia} | Conta corrente: {item.contaCorrente}
                    {`${!item.digitoVerificador ? '' : `-${item.digitoVerificador}`}`}
                  </div>
                </div>
              ) : (
                '-'
              )}
            </td>
          </tr>
        );
      }

      return (
        <MantineTable withBorder withColumnBorders striped>
          <thead>
            <tr>
              <th>Forma</th>
              <th>Prazo</th>
              <th>Tipo</th>
              <th>CNPJ / CPF</th>
              <th>Beneficiário</th>
              <th>Dados do Pagamento</th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </MantineTable>
      );
    };

    const buildContact = () => {
      const nodes: ReactNode[] = [];

      for (const item of client.contatos) {
        nodes.push(
          <tr>
            <td>{item.nome}</td>
            <td>{item.setor || '-'}</td>
            <td>{item.email || '-'}</td>
            <td>{item.telefone || '-'}</td>
            <td>{item.telefoneRamal || '-'}</td>
            <td>{item.celular || '-'}</td>
          </tr>
        );
      }

      return (
        <MantineTable withBorder withColumnBorders striped>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Setor</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Ramal</th>
              <th>Celular</th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </MantineTable>
      );
    };

    const buildAddress = () => {
      const nodes: ReactNode[] = [];

      for (const item of client.enderecos) {
        nodes.push(
          <tr>
            <td>{item.enderecoTipo}</td>
            <td>{`${item?.logradouro}, ${item.numero}${
              item.complemento ? ` - ${item.complemento}` : ''
            }`}</td>
            <td>{`${item.cidade} / ${item.codigoEstado}`}</td>
            <td>{item.bairro}</td>
            <td>{item.cep}</td>
            <td>
              {item.contatos.map((x) => {
                return (
                  <div>
                    {x.nome} ({x.telefone || x.celular || x.email || x.setor || '-'})
                  </div>
                );
              }) || '-'}
            </td>
          </tr>
        );
      }

      return (
        <MantineTable withBorder withColumnBorders striped>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Endereço</th>
              <th>Cidade / UF</th>
              <th>Bairro</th>
              <th>CEP</th>
              <th>Contato(s)</th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </MantineTable>
      );
    };

    return (
      <div style={{ pageBreakAfter: 'always' }}>
        {buildSectionHeader('Informações do cliente.')}
        <Paper shadow="xs" p="md" withBorder>
          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Cliente" text="" />
          <Space h="xs" />

          {buildClientGeneral()}
          <Space h="xs" />
          <Divider my="sm" variant="dashed" />

          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Endereços" text="" />
          <Space h="xs" />
          {buildAddress()}
          <Space h="xs" />
          <Divider my="sm" variant="dashed" />

          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Formas de Pagamento" text="" />
          <Space h="xs" />
          {buildPaymentMethod()}
          <Space h="xs" />
          <Divider my="sm" variant="dashed" />

          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Contatos" text="" />
          <Space h="xs" />
          {buildContact()}
        </Paper>
      </div>
    );
  };

  const buildResidues = (): ReactNode[] => {
    const { proposal } = result.data!;

    const residueNodes: ReactNode[] = [];

    const buildResidueClient = (residuo: ProposalResidueType) => {
      const cliente = {
        cnpj: residuo.clienteCNPJ,
        razaoSocial: residuo.clienteRazaoSocial,
        nomeFantasia: residuo.clienteNomeFantasia,
        cpf: residuo.clienteCPF,
        nome: residuo.clienteNome,
      };
      const endereco = residuo;

      return (
        <div>
          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Cliente e Local" text="" />
          <Space h="xs" />
          <MantineTable withBorder withColumnBorders striped>
            <tbody>
              <tr>
                <td>Nome Fantasia / Razão Social / Nome</td>
                <td>{cliente.nomeFantasia || cliente.razaoSocial || cliente.nome}</td>
                <td>CNPJ / CPF</td>
                <td>
                  <Badge variant="outline">{cliente.cnpj || cliente.cpf}</Badge>
                </td>
              </tr>
              <tr>
                <td>Endereço</td>
                <td>
                  {`${endereco?.logradouro}, ${endereco.numero}${
                    endereco.complemento ? ` - ${endereco.complemento}` : ''
                  }`}
                </td>
                <td>Cidade / UF</td>
                <td>{`${endereco.cidade} / ${endereco.codigoEstado}`}</td>
              </tr>
              <tr>
                <td>Bairro</td>
                <td>{endereco?.bairro}</td>
                <td>CEP</td>
                <td>{endereco?.cep}</td>
              </tr>
            </tbody>
          </MantineTable>
        </div>
      );
    };

    const buildResidueDocuments = (residuo: ProposalResidueType) => {
      return (
        <div>
          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Documentos" text="" />
          <Space h="xs" />
          {/* <Group>
            {residuo.documento.notaFiscal && <Badge variant="outline">Nota Fiscal</Badge>}
            {residuo.documento.xml && <Badge variant="outline">XML</Badge>}
            {residuo.documento.mtr && <Badge variant="outline">MTR</Badge>}
            {residuo.documento.sga && <Badge variant="outline">SGA</Badge>}
            {residuo.documento.romaneioColeta && <Badge variant="outline">Romaneio de coleta</Badge>}
            {residuo.documento.pesoOrigem && <Badge variant="outline">Peso Origem</Badge>}
            {residuo.documento.pesoDestino && <Badge variant="outline">Peso Destino</Badge>}
          </Group> */}
          <SimpleGrid cols={7}>
            <PageViewProperty
              label="Nota Fiscal"
              text={residuo.documento.notaFiscal ? 'Sim' : 'Não'}
              size="sm"
            />
            <PageViewProperty label="XML" text={residuo.documento.xml ? 'Sim' : 'Não'} size="sm" />
            <PageViewProperty label="MTR" text={residuo.documento.mtr ? 'Sim' : 'Não'} size="sm" />
            <PageViewProperty label="SGA" text={residuo.documento.sga ? 'Sim' : 'Não'} size="sm" />
            <PageViewProperty
              label="Romaneio de coleta"
              text={residuo.documento.romaneioColeta ? 'Sim' : 'Não'}
              size="sm"
            />
            <PageViewProperty
              label="Peso Origem"
              text={residuo.documento.pesoOrigem ? 'Sim' : 'Não'}
              size="sm"
            />
            <PageViewProperty
              label="Peso Destino"
              text={residuo.documento.pesoDestino ? 'Sim' : 'Não'}
              size="sm"
            />
          </SimpleGrid>
        </div>
      );
    };

    const buildPlan = (residuo: ProposalResidueType) => {
      const tableNodes: ReactNode[] = [];
      let quotationNodes: ReactNode[] = [];

      const buildPricingItem = (receita: boolean, x: any, price: any) => {
        const marginColor =
          price.novaMargemFinalPorcentagem > 0
            ? 'green'
            : price.novaMargemFinalPorcentagem < 0
            ? 'red'
            : 'orange';

        const finalPrice = receita ? price.preco : price.novoPrecoComMargem;
        const finalMargin = (price.novaMargemFinalPorcentagem || 0).toFixed(2);

        const isPricingDefined =
          (x.precoFinal !== null && x.precoFinal !== undefined) ||
          (x.margem !== null && x.margem !== undefined) ||
          (x.imposto !== null && x.imposto !== undefined);

        const isQuotationFinalPrice = x.precoFinal !== null && x.precoFinal !== undefined;

        return !isPricingDefined ? (
          <>-</>
        ) : (
          <>
            <div
              style={{
                color: marginColor,
              }}
            >{`${formatCurrency(finalPrice)} (${finalMargin}%)`}</div>
            <div style={{ fontSize: 'small' }}>{`${isQuotationFinalPrice ? 'Preço:' : 'Margem:'} ${
              isQuotationFinalPrice ? `${formatCurrency(Number(x.precoFinal))}` : `${x.margem}%`
            }`}</div>
            <div style={{ fontSize: 'small' }}>{`Imposto: ${x.imposto}%`}</div>
          </>
        );
      };

      for (const plano of residuo.planos.filter((x) => x.primario)) {
        quotationNodes = [];

        for (const cotacao of plano.cotacoes.filter((x) => x.primario)) {
          for (const x of cotacao.acondicionamentos) {
            const price = calcularPreco(
              x.preco,
              x.preco,
              x.margem,
              x.precoFinal,
              x.imposto || 0,
              residuo.compra,
              false
            );

            const planoX = plano.acondicionamentos.find(
              (y) => y.idPropostaResiduoPlanoAcondicionamento === x.idPropostaResiduoPlanoAcondicionamento
            )!;
            quotationNodes.push(
              <tr key={`ra_${x.idPropostaResiduoPlanoCotacaoAcondicionamento}`}>
                <td>
                  <Feature.Reference.Packaging.icon size={16} />
                  {planoX.gerenciar ? <Briefcase size={16} /> : <></>}
                  {planoX.compartilhado ? <Share size={16} /> : <></>}
                </td>
                <td>{x.residuoAcondicionamento}</td>
                <td>
                  {planoX.compartilhado ? '%' : `${planoX.quantidade} ${planoX.quantidadeUnidadeMedida}`}
                </td>
                <td>{x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}</td>
                <td>-</td>
                <td>
                  {x.quantidade} {x.quantidadeUnidadeMedida} / {x.frequenciaUnidadeMedida}
                </td>
                <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                <td>{buildPricingItem(false, x, price)}</td>
              </tr>
            );
          }

          for (const x of cotacao.equipamentos) {
            const price = calcularPreco(
              x.preco,
              x.preco,
              x.margem,
              x.precoFinal,
              x.imposto || 0,
              residuo.compra,
              false
            );

            const planoX = plano.equipamentos.find(
              (y) => y.idPropostaResiduoPlanoEquipamento === x.idPropostaResiduoPlanoEquipamento
            )!;
            quotationNodes.push(
              <tr key={`re_${x.idPropostaResiduoPlanoCotacaoEquipamento}`}>
                <td>
                  <Feature.Reference.Equipment.icon size={16} />
                </td>
                <td>{x.residuoEquipamento}</td>
                <td>
                  {planoX.quantidade} {planoX.quantidadeUnidadeMedida}
                </td>
                <td>{x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}</td>
                <td>-</td>
                <td>
                  {x.quantidade} {x.quantidadeUnidadeMedida} / {x.frequenciaUnidadeMedida}
                </td>
                <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                <td>{buildPricingItem(false, x, price)}</td>
              </tr>
            );
          }

          if (cotacao.veiculo) {
            const x = cotacao.veiculo;
            const price = calcularPreco(
              x.preco,
              x.preco,
              x.margem,
              x.precoFinal,
              x.imposto || 0,
              residuo.compra,
              false
            );
            const planoX = plano.veiculo!;
            quotationNodes.push(
              <tr key={`rv_${x.idPropostaResiduoPlanoCotacaoVeiculo}`}>
                <td>
                  <Feature.Reference.Vehicle.icon size={16} />
                </td>
                <td>{x.residuoVeiculo}</td>
                <td>
                  {planoX.quantidade} {planoX.quantidadeUnidadeMedida}
                </td>
                <td>{x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}</td>
                <td>-</td>
                <td>
                  {x.quantidade} {x.quantidadeUnidadeMedida} / {x.frequenciaUnidadeMedida}
                </td>
                <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                <td>{buildPricingItem(false, x, price)}</td>
              </tr>
            );
          }

          if (cotacao.tratamento) {
            const x = cotacao.tratamento;
            const price = calcularPreco(
              x.preco,
              x.preco,
              x.margem,
              x.precoFinal,
              x.imposto || 0,
              residuo.compra,
              false
            );

            quotationNodes.push(
              <tr key={`rt_${x.idPropostaResiduoPlanoCotacaoTratamento}`}>
                <td>
                  <Feature.Reference.Treatment.icon size={16} />
                </td>
                <td>{x.residuoTratamento}</td>
                <td>-</td>
                <td>{x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}</td>
                <td>
                  <div>{`${x.logradouro}, ${x.numero}${x.complemento ? ` - ${x.complemento}` : ''}`}</div>
                  <div>{`${x.bairro}, ${x.cidade} / ${x.codigoEstado} (${x.cep})`}</div>
                </td>
                <td>
                  {x.quantidade} {x.quantidadeUnidadeMedida}
                </td>
                <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                <td>{buildPricingItem(false, x, price)}</td>
              </tr>
            );
          }

          if (cotacao.destinoFinal) {
            const x = cotacao.destinoFinal;
            const price = calcularPreco(
              x.preco,
              x.preco,
              x.margem,
              x.precoFinal,
              x.imposto || 0,
              residuo.compra,
              x.receita
            );

            quotationNodes.push(
              <tr key={`rdf_${x.idPropostaResiduoPlanoCotacaoDestinoFinal}`}>
                <td>
                  <Feature.Reference.Destination.icon size={16} />
                </td>
                <td>{x.residuoDestinoFinal}</td>
                <td>-</td>
                <td>{x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}</td>
                <td>
                  <div>{`${x.logradouro}, ${x.numero}${x.complemento ? ` - ${x.complemento}` : ''}`}</div>
                  <div>{`${x.bairro}, ${x.cidade} / ${x.codigoEstado} (${x.cep})`}</div>
                </td>
                <td>
                  {x.quantidade} {x.quantidadeUnidadeMedida}
                </td>
                <td style={{ color: x.receita ? 'green' : 'red' }}>{formatCurrency(x.preco)}</td>
                <td>{buildPricingItem(x.receita, x, price)}</td>
              </tr>
            );
          }
        }

        if (quotationNodes.length === 0) {
          break;
        }

        tableNodes.push(
          <MantineTable
            key={`table_s_${residuo.idPropostaResiduo?.toString()}`}
            className="custom-mantine-table"
            withBorder
            withColumnBorders
            striped
          >
            <thead>
              <tr>
                <th colSpan={2}>Geral</th>
                <th colSpan={1}>Plano</th>
                <th colSpan={4}>Cotação</th>
                <th colSpan={1}>Financeiro</th>
              </tr>
              <tr>
                <th>Tipo</th>
                <th>Item</th>
                <th>Quantidade</th>
                <th>Fornecedor</th>
                <th>Local</th>
                <th>Quantidade / Frequência</th>
                <th>Preço</th>
                <th>Precificação</th>
              </tr>
            </thead>
            <tbody>{quotationNodes}</tbody>
          </MantineTable>
        );
      }

      return tableNodes;
    };

    for (let index = 0; index < proposal.residuos.length; index++) {
      const residuo = proposal.residuos[index];
      const residuoRecorrencia = montarRecorrenciaString(residuo.recorrencia);
      const plano = buildPlan(residuo);

      residueNodes.push(
        <div style={{ pageBreakAfter: index < proposal.residuos.length - 1 ? 'always' : 'avoid' }}>
          {buildSectionHeader('Informações do resíduo.')}
          <Accordion
            key={`accordion_r_${residuo.idPropostaResiduo!.toString()}`}
            chevronSize={0}
            variant="contained"
            defaultValue={residuo.idPropostaResiduo!.toString()}
          >
            <Accordion.Item
              key={`accordion_item_r_${residuo.idPropostaResiduo!.toString()}`}
              value={residuo.idPropostaResiduo!.toString()}
            >
              <Accordion.Control>
                <Group position="apart">
                  <Group>
                    <ProfileCardLink
                      id={residuo.idResiduo.toString()}
                      name={residuo.residuoCliente}
                      nameSize="md"
                      description={`Código IBAMA: ${residuo.codigoIBAMA || '-'} | Perigoso: ${
                        residuo.residuoClientePerigoso ? 'Sim' : 'Não'
                      }`}
                      descriptionLength={100}
                      descriptionSize="sm"
                      linkPrefix="residues"
                      showLink={false}
                    />
                    <Group>
                      <Stack spacing="xs">
                        <Badge
                          key={newGuid()}
                          variant="outline"
                          size="md"
                          color={Feature.Home.Proposal.color}
                        >
                          {`Estimativa: ${residuo.estimativa} ${residuo.estimativaUnidadeMedida1} / ${residuo.estimativaUnidadeMedida2}`}
                        </Badge>
                        {residuo.minimoAceitavel ? (
                          <Badge key={newGuid()} variant="outline" size="md" color="orange">
                            {`Mínimo Aceitável: ${residuo.minimoAceitavel} ${residuo.minimoAceitavelUnidadeMedida1} / ${residuo.minimoAceitavelUnidadeMedida2}`}
                          </Badge>
                        ) : (
                          <></>
                        )}
                      </Stack>
                      <Stack spacing="xs">
                        {residuo.compra && (
                          <Badge
                            key={newGuid()}
                            variant="outline"
                            size="md"
                            color={Feature.Home.Proposal.color}
                          >
                            Compra
                          </Badge>
                        )}
                        {residuo.cobrarTolerancia && (
                          <Badge
                            key={newGuid()}
                            variant="outline"
                            size="md"
                            color={Feature.Home.Proposal.color}
                          >
                            Cobrar Tolerâncias
                          </Badge>
                        )}
                      </Stack>
                      <Stack spacing="xs">
                        {residuoRecorrencia && (
                          <Badge
                            key={newGuid()}
                            variant="outline"
                            size="md"
                            color={Feature.Home.Proposal.color}
                          >
                            <div>
                              <div>
                                Recorrência: {residuoRecorrencia.str1}
                                {residuoRecorrencia.str2 ? ` (${residuoRecorrencia.str2})` : ''}
                              </div>
                            </div>
                          </Badge>
                        )}
                      </Stack>
                    </Group>
                  </Group>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <div key={`r_${residuo.idPropostaResiduo}`}>
                  <Paper shadow="xs" p="md" withBorder>
                    {buildResidueClient(residuo)}
                    <Space h="xs" />
                    <Divider my="sm" variant="dashed" />

                    {buildResidueDocuments(residuo)}

                    {plano.length > 0 && (
                      <>
                        <Space h="xs" />
                        <Divider my="sm" variant="dashed" />

                        <PageSection size="lg" color={Feature.Home.Proposal.color} label="Plano" text="" />
                        <Space h="xs" />
                        {plano}
                      </>
                    )}
                  </Paper>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      );

      if (index < proposal.residuos.length - 1) {
        residueNodes.push(<Space key={newGuid()} h="lg" />);
      }
    }

    return residueNodes;
  };

  const buildServices = (): ReactNode[] => {
    const { proposal } = result.data!;

    const serviceNodes: ReactNode[] = [];

    const buildServiceClient = (servico: ProposalServiceType) => {
      const cliente = {
        cnpj: servico.clienteCNPJ,
        razaoSocial: servico.clienteRazaoSocial,
        nomeFantasia: servico.clienteNomeFantasia,
        cpf: servico.clienteCPF,
        nome: servico.clienteNome,
      };
      const endereco = servico;

      return (
        <div>
          <PageSection size="lg" color={Feature.Home.Proposal.color} label="Cliente e Local" text="" />
          <Space h="xs" />
          <MantineTable withBorder withColumnBorders striped>
            <tbody>
              <tr>
                <td>Nome Fantasia / Razão Social / Nome</td>
                <td>{cliente.nomeFantasia || cliente.razaoSocial || cliente.nome}</td>
                <td>CNPJ / CPF</td>
                <td>
                  <Badge variant="outline">{cliente.cnpj || cliente.cpf}</Badge>
                </td>
              </tr>
              <tr>
                <td>Endereço</td>
                <td>
                  {`${endereco?.logradouro}, ${endereco.numero}${
                    endereco.complemento ? ` - ${endereco.complemento}` : ''
                  }`}
                </td>
                <td>Cidade / UF</td>
                <td>{`${endereco.cidade} / ${endereco.codigoEstado}`}</td>
              </tr>
              <tr>
                <td>Bairro</td>
                <td>{endereco?.bairro}</td>
                <td>CEP</td>
                <td>{endereco?.cep}</td>
              </tr>
            </tbody>
          </MantineTable>
        </div>
      );
    };

    const buildPlan = (servico: ProposalServiceType) => {
      const tableNodes: ReactNode[] = [];
      const quotationNodes: ReactNode[] = [];

      const buildPricingItem = (receita: boolean, x: any, price: any) => {
        const marginColor =
          price.novaMargemFinalPorcentagem > 0
            ? 'green'
            : price.novaMargemFinalPorcentagem < 0
            ? 'red'
            : 'orange';

        const finalPrice = receita ? price.preco : price.novoPrecoComMargem;
        const finalMargin = (price.novaMargemFinalPorcentagem || 0).toFixed(2);

        const isPricingDefined =
          (x.precoFinal !== null && x.precoFinal !== undefined) ||
          (x.margem !== null && x.margem !== undefined) ||
          (x.imposto !== null && x.imposto !== undefined);

        const isQuotationFinalPrice = x.precoFinal !== null && x.precoFinal !== undefined;

        return !isPricingDefined ? (
          <>-</>
        ) : (
          <>
            <div
              style={{
                color: marginColor,
              }}
            >{`${formatCurrency(finalPrice)} (${finalMargin}%)`}</div>
            <div style={{ fontSize: 'small' }}>{`${isQuotationFinalPrice ? 'Preço:' : 'Margem:'} ${
              isQuotationFinalPrice ? `${formatCurrency(Number(x.precoFinal))}` : `${x.margem}%`
            }`}</div>
            <div style={{ fontSize: 'small' }}>{`Imposto: ${x.imposto}%`}</div>
          </>
        );
      };

      for (const cotacao of servico.cotacoes.filter((x) => x.primario)) {
        const price = calcularPreco(
          cotacao.preco,
          cotacao.preco,
          cotacao.margem,
          cotacao.precoFinal,
          cotacao.imposto || 0,
          false,
          false
        );

        quotationNodes.push(
          <tr key={`sc_${cotacao.idPropostaServicoCotacao}`}>
            <td>
              {servico.idResiduoAcondicionamento ? (
                <>
                  <Feature.Reference.Packaging.icon size={16} />
                  {servico.gerenciar ? <Briefcase size={16} /> : <></>}
                  {servico.compartilhado ? <Share size={16} /> : <></>}
                </>
              ) : servico.idResiduoEquipamento ? (
                <Feature.Reference.Equipment.icon size={16} />
              ) : (
                <Feature.Reference.Entity.icon size={16} />
              )}
            </td>
            <td>{servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}</td>
            <td>{servico.quantidade}</td>
            <td>
              {!(servico.idResiduoAcondicionamento || servico.idResiduoEquipamento)
                ? `${servico.frequencia} `
                : ''}
              {servico.frequenciaUnidadeMedida1} / {servico.frequenciaUnidadeMedida2}
            </td>
            <td>
              {cotacao.fornecedorNomeFantasia || cotacao.fornecedorRazaoSocial || cotacao.fornecedorNome}
            </td>
            <td>{cotacao.quantidade}</td>
            <td>
              {!(cotacao.idResiduoAcondicionamento || cotacao.idResiduoEquipamento)
                ? `${cotacao.frequencia} `
                : ''}
              {cotacao.frequenciaUnidadeMedida1} / {cotacao.frequenciaUnidadeMedida2}
            </td>
            <td style={{ color: 'red' }}>{formatCurrency(cotacao.preco)}</td>
            <td>{buildPricingItem(false, cotacao, price)}</td>
          </tr>
        );
      }

      if (quotationNodes.length === 0) {
        return tableNodes;
      }

      tableNodes.push(
        <MantineTable
          key={`table_s_${servico.idPropostaServico?.toString()}`}
          className="custom-mantine-table"
          withBorder
          withColumnBorders
          striped
        >
          <thead>
            <tr>
              <th colSpan={2}>Geral</th>
              <th colSpan={2}>Plano</th>
              <th colSpan={4}>Cotação</th>
              <th colSpan={1}>Financeiro</th>
            </tr>
            <tr>
              <th>Tipo</th>
              <th>Item</th>
              <th>Quantidade</th>
              <th>Frequência</th>
              <th>Fornecedor</th>
              <th>Quantidade</th>
              <th>Frequência</th>
              <th>Preço</th>
              <th>Precificação</th>
            </tr>
          </thead>
          <tbody>{quotationNodes}</tbody>
        </MantineTable>
      );

      return tableNodes;
    };

    for (let index = 0; index < proposal.servicos.length; index++) {
      const servico = proposal.servicos[index];
      const servicoRecorrencia = montarRecorrenciaString(servico.recorrencia);
      const plano = buildPlan(servico);

      serviceNodes.push(
        <div style={{ pageBreakAfter: index < proposal.servicos.length - 1 ? 'always' : 'avoid' }}>
          {buildSectionHeader('Informações do serviço.')}
          <Accordion
            key={`accordion_s_${servico.idPropostaServico!.toString()}`}
            chevronSize={0}
            variant="contained"
            defaultValue={servico.idPropostaServico!.toString()}
          >
            <Accordion.Item
              key={`accordion_s_${servico.idPropostaServico!.toString()}`}
              value={servico.idPropostaServico!.toString()}
            >
              <Accordion.Control>
                <Group>
                  <ProfileCardLink
                    id={servico.idServico.toString()}
                    name={servico.servico}
                    nameSize="md"
                    description={servico.servicoDescricao}
                    descriptionSize="sm"
                    linkPrefix="services"
                    showLink={false}
                  />
                  <div>
                    <Group>
                      <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                        {`${servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}`}
                      </Badge>
                      {servicoRecorrencia && (
                        <Badge
                          key={newGuid()}
                          variant="outline"
                          size="md"
                          color={Feature.Home.Proposal.color}
                        >
                          <div>
                            <div>
                              Recorrência: {servicoRecorrencia.str1}
                              {servicoRecorrencia.str2 ? ` (${servicoRecorrencia.str2})` : ''}
                            </div>
                          </div>
                        </Badge>
                      )}
                    </Group>
                  </div>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <div key={`r_${servico.idPropostaServico}`}>
                  <Paper shadow="xs" p="md" withBorder>
                    {buildServiceClient(servico)}

                    {plano.length > 0 && (
                      <>
                        <Space h="xs" />
                        <Divider my="sm" variant="dashed" />

                        <PageSection size="lg" color={Feature.Home.Proposal.color} label="Plano" text="" />
                        <Space h="xs" />
                        {plano}
                      </>
                    )}
                  </Paper>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      );

      if (index < proposal.servicos.length - 1) {
        serviceNodes.push(<Space key={newGuid()} h="lg" />);
      }
    }

    return serviceNodes;
  };

  let residueNodes: ReactNode[] = [];
  let serviceNodes: ReactNode[] = [];

  if (result.data !== null) {
    residueNodes = buildResidues();
    serviceNodes = buildServices();
  }

  return result.data === null ? (
    <Center>
      <Loader size="xl" />
    </Center>
  ) : (
    <Card ref={refContent} className="print" style={{ margin: 0, padding: 0 }}>
      <style>{`@media print {.print{zoom: 75%;}}`}</style>
      <div>
        {buildGeneral()}
        <Space h="lg" />

        {buildClient()}
        <Space h="lg" />

        {residueNodes.length > 0 && <>{residueNodes}</>}

        {serviceNodes.length > 0 && (
          <div
            style={{
              pageBreakBefore: residueNodes.length > 0 && serviceNodes.length > 0 ? 'always' : 'avoid',
            }}
          >
            <Space h="lg" />
            {serviceNodes}
          </div>
        )}
      </div>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default ProposalView;
