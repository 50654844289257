/* eslint-disable react/destructuring-assignment */
import { Notes, Recycle } from 'tabler-icons-react';
import { Paper, SimpleGrid, Space, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { TreatmentType } from '../../../../../models/core/treatments.type';
import { Feature } from '../../../../../utils/constants.utils';
import PageSection from '../../../../../components/core/PageSection/PageSection';

type FormViewData = {
  idResiduoTratamento: number | null;
  residuoTratamento: string;
  descricao: string | null;
};

type FormViewProps = {
  save(data: FormViewData): void;
  treatment: TreatmentType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      idResiduoTratamento: props.treatment?.idResiduoTratamento || null,
      residuoTratamento: props.treatment?.residuoTratamento || '',
      descricao: props.treatment?.descricao || '',
    },
    validate: {
      residuoTratamento: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = async (formData: FormViewData) => {
    props.save({
      ...formData,
      residuoTratamento: formData.residuoTratamento.trim(),
      descricao: formData.descricao?.trim() || null,
    });
  };

  return (
    <form id="treatment-add-edit" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Treatment.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <TextInput
            icon={<Recycle size={15} />}
            label="Tratamento"
            placeholder="Nome do tratamento"
            maxLength={50}
            required
            {...form.getInputProps('residuoTratamento')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <Textarea
          icon={<Notes size={15} />}
          label="Descrição"
          placeholder="Descrição do tratamento"
          autosize
          maxLength={500}
          {...form.getInputProps('descricao')}
        />
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
