/* eslint-disable react/destructuring-assignment */
import { Activity, Building, ClearAll, Mail, Search, User } from 'tabler-icons-react';
import { Button, Group, Paper, Select, SimpleGrid, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../../models/core/cache.type';

type FilterData = {
  idEmpresa: string;
  status: string;
  nome: string;
  sobrenome: string;
  email: string;
};

type FilterProps = {
  companies: CompanyType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function Filter(props: FilterProps) {
  const [currentUser] = useCurrentUser();

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? '' : currentUser.idEmpresa.toString(),
      status: '',
      nome: '',
      sobrenome: '',
      email: '',
    },
  });

  return (
    <form onSubmit={form.onSubmit(props.filter)}>
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={5}>
          <Select
            icon={<Building size={15} />}
            label="Empresa"
            description="Filtro: igual"
            placeholder="Todos..."
            data={props.companies.map((x) => {
              return {
                value: x.idEmpresa.toString(),
                label: x.empresa,
              };
            })}
            searchable
            clearable={currentUser.executivo}
            disabled={!currentUser.executivo}
            {...form.getInputProps('idEmpresa')}
          />
          <TextInput
            icon={<User size={15} />}
            label="Nome"
            description="Filtro: contém"
            placeholder="Todos..."
            maxLength={50}
            {...form.getInputProps('nome')}
          />
          <TextInput
            icon={<User size={15} />}
            label="Sobrenome"
            description="Filtro: contém"
            placeholder="Todos..."
            maxLength={50}
            {...form.getInputProps('sobrenome')}
          />
          <TextInput
            icon={<Mail size={15} />}
            label="E-mail"
            description="Filtro: contém"
            placeholder="Todos..."
            maxLength={100}
            {...form.getInputProps('email')}
          />
          <Select
            icon={<Activity size={15} />}
            label="Status"
            placeholder="Todos..."
            description="Filtro: igual"
            data={[
              { value: 'active', label: 'Ativo' },
              { value: 'inactive', label: 'Inativo' },
            ]}
            clearable
            {...form.getInputProps('status')}
          />
        </SimpleGrid>
        <Space h="md" />
        <SimpleGrid cols={1}>
          <Group>
            <Button
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              disabled={props.loading}
              onClick={() => {
                form.reset();
                props.clear();
              }}
            >
              Limpar
            </Button>
            <Button leftIcon={<Search size={18} />} type="submit" loading={props.loading}>
              Pesquisar
            </Button>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { Filter };
export type { FilterData };
