import {
  Check,
  CurrencyDollar,
  Edit,
  EyeCheck,
  FileDislike,
  FileLike,
  Icon,
  QuestionMark,
  Send,
  ThumbDown,
  ThumbUp,
  X,
} from 'tabler-icons-react';

export enum ProposalTypeEnum {
  Completa = 'CO',
  Reajuste = 'RE',
}

export enum ProposalStatusEnum {
  EmEdicao = 'EE',
  Cancelado = 'CA',
  EmAnaliseFinanceira = 'AF',
  EmRevisao = 'ER',
  AprovadoInternamente = 'AI',
  ReprovadoInternamente = 'RI',
  Enviado = 'EN',
  AprovadoExternamente = 'AE',
  ReprovadoExternamente = 'RE',
  Finalizado = 'FI',
}

const map = [
  {
    currentStatus: ProposalStatusEnum.EmEdicao,
    validStatuses: [ProposalStatusEnum.Cancelado, ProposalStatusEnum.EmAnaliseFinanceira],
  },
  {
    currentStatus: ProposalStatusEnum.Cancelado,
    validStatuses: [],
  },
  {
    currentStatus: ProposalStatusEnum.EmAnaliseFinanceira,
    validStatuses: [ProposalStatusEnum.EmEdicao, ProposalStatusEnum.Cancelado, ProposalStatusEnum.EmRevisao],
  },
  {
    currentStatus: ProposalStatusEnum.EmRevisao,
    validStatuses: [
      ProposalStatusEnum.Cancelado,
      ProposalStatusEnum.EmEdicao,
      ProposalStatusEnum.EmAnaliseFinanceira,
      ProposalStatusEnum.AprovadoInternamente,
      ProposalStatusEnum.ReprovadoInternamente,
    ],
  },
  {
    currentStatus: ProposalStatusEnum.AprovadoInternamente,
    validStatuses: [
      ProposalStatusEnum.Cancelado,
      ProposalStatusEnum.EmEdicao,
      ProposalStatusEnum.EmAnaliseFinanceira,
      ProposalStatusEnum.Enviado,
    ],
  },
  {
    currentStatus: ProposalStatusEnum.ReprovadoInternamente,
    validStatuses: [
      ProposalStatusEnum.Cancelado,
      ProposalStatusEnum.EmEdicao,
      ProposalStatusEnum.EmAnaliseFinanceira,
    ],
  },
  {
    currentStatus: ProposalStatusEnum.Enviado,
    validStatuses: [
      ProposalStatusEnum.Cancelado,
      ProposalStatusEnum.EmEdicao,
      ProposalStatusEnum.EmAnaliseFinanceira,
      ProposalStatusEnum.AprovadoExternamente,
      ProposalStatusEnum.ReprovadoExternamente,
    ],
  },
  {
    currentStatus: ProposalStatusEnum.AprovadoExternamente,
    validStatuses: [ProposalStatusEnum.Cancelado, ProposalStatusEnum.Finalizado],
  },
  {
    currentStatus: ProposalStatusEnum.ReprovadoExternamente,
    validStatuses: [],
  },
  {
    currentStatus: ProposalStatusEnum.Finalizado,
    validStatuses: [],
  },
];

const getValidStatuses = (currentStatusCode: ProposalStatusEnum): ProposalStatusEnum[] => {
  return map.find((x) => x.currentStatus === currentStatusCode)?.validStatuses || [];
};

const getStatusAction = (currentStatusCode: ProposalStatusEnum): { action: string; icon: Icon } => {
  switch (currentStatusCode) {
    case ProposalStatusEnum.EmEdicao:
      return { action: 'Editar', icon: Edit };
    case ProposalStatusEnum.Cancelado:
      return { action: 'Cancelar', icon: X };
    case ProposalStatusEnum.EmAnaliseFinanceira:
      return { action: 'Enviar para Análise Financeira', icon: CurrencyDollar };
    case ProposalStatusEnum.EmRevisao:
      return { action: 'Enviar para Revisão', icon: EyeCheck };
    case ProposalStatusEnum.AprovadoInternamente:
      return { action: 'Aprovar Internamente', icon: ThumbUp };
    case ProposalStatusEnum.ReprovadoInternamente:
      return { action: 'Reprovar Internamente', icon: ThumbDown };
    case ProposalStatusEnum.Enviado:
      return { action: 'Enviar', icon: Send };
    case ProposalStatusEnum.AprovadoExternamente:
      return { action: 'Aprovar Externamente', icon: FileLike };
    case ProposalStatusEnum.ReprovadoExternamente:
      return { action: 'Reprovar Externamente', icon: FileDislike };
    case ProposalStatusEnum.Finalizado:
      return { action: 'Finalizar', icon: Check };
    default:
      return { action: '?', icon: QuestionMark };
  }
};

const validarStatusWorkflow = (newStatusCode: ProposalStatusEnum, currentStatusCode: ProposalStatusEnum) => {
  if (
    map
      .find((x) => x.currentStatus === currentStatusCode)
      ?.validStatuses.findIndex((y) => y === newStatusCode) === -1
  ) {
    throw Error('Não é possível atualizar essa proposta com esse novo status.');
  }
};

export { getValidStatuses, getStatusAction, validarStatusWorkflow };
