/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import convertUnits, { Unit } from 'convert-units';
import hash from 'object-hash';
import { UnitOfMeasureType } from '../models/core/cache.type';

const newGuid = () => uuidv4();

const encrypt = (value: string): string => {
  return CryptoJS.AES.encrypt(value, process.env.REACT_APP_SECRET_KEY!).toString();
};

const decrypt = (value: string): string => {
  const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_SECRET_KEY!);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const getHash = (value: any): string => {
  return hash({
    value,
  });
};

const tryGetDateISOFormat = (value: Date): string => {
  try {
    return value.toISOString();
  } catch (error) {
    return value.toString();
  }
};

const buildFakeAuditObject = (): any => {
  return {
    idCriadoPor: '',
    criadoPor: '',
    dataCriacao: new Date(),
    idModificadoPor: null,
    modificadoPor: null,
    dataModificacao: null,
  };
};

const dateDiffInDaysDisregardTime = (a: Date, b: Date, returnInteger: boolean) => {
  return moment(a.toDateString()).diff(moment(b.toDateString()), 'days', returnInteger);
};

const isCPFValid = (cpfString: string): boolean => {
  return cpf.isValid(cpfString);
};

const isCNPJValid = (cnpjString: string): boolean => {
  return cnpj.isValid(cnpjString);
};

const convertUnitOfMeasure = (
  sourceUoM: UnitOfMeasureType,
  targetUoM: UnitOfMeasureType,
  value: number | null = null
): { valid: boolean; value: number; calc: number | null } => {
  let index;
  const getUnitOfMeasureAcronym = (uom: UnitOfMeasureType) => {
    let acronym = uom.unidadeMedidaSigla;

    switch (acronym) {
      case 'sem':
        acronym = 'week';
        break;
      case 'mês':
        acronym = 'month';
        break;
      case 'ano':
        acronym = 'year';
        break;
      default:
        break;
    }

    return acronym;
  };

  if (sourceUoM.tipo !== targetUoM.tipo) {
    return {
      valid: false,
      value: 0,
      calc: null,
    };
  }

  if (sourceUoM.unidadeMedidaSigla === targetUoM.unidadeMedidaSigla) {
    index = 1;
    return {
      valid: true,
      value: index,
      calc: value ? value * index : null,
    };
  }

  // TODO: Proposals: 019 - consider 'período'
  // if (sourceUoM.tipo === 'Outro' && sourceUoM.unidadeMedidaSigla === 'per' && targetUoM.tipo === 'Tempo') {}

  if (sourceUoM.tipo === 'Massa') {
    if (sourceUoM.unidadeMedidaSigla === 'kg' && targetUoM.unidadeMedidaSigla === 'ton') {
      index = 0.001;
      return {
        valid: true,
        value: index,
        calc: value ? value * index : null,
      };
    }
    if (sourceUoM.unidadeMedidaSigla === 'ton' && targetUoM.unidadeMedidaSigla === 'kg') {
      index = 1000;
      return {
        valid: true,
        value: index,
        calc: value ? value * index : null,
      };
    }
  }

  if (sourceUoM.tipo === 'Tempo') {
    try {
      const source = getUnitOfMeasureAcronym(sourceUoM);
      const target = getUnitOfMeasureAcronym(targetUoM);
      index = convertUnits(1)
        .from(source as Unit)
        .to(target as Unit);

      return {
        valid: true,
        value: index,
        calc: value ? value * index : null,
      };
    } catch (error) {
      return {
        valid: false,
        value: 0,
        calc: null,
      };
    }
  }

  return {
    valid: false,
    value: 0,
    calc: null,
  };
};

const now = () => {
  return moment().subtract(3, 'hours');
};

export {
  buildFakeAuditObject,
  dateDiffInDaysDisregardTime,
  encrypt,
  decrypt,
  getHash,
  isCPFValid,
  isCNPJValid,
  newGuid,
  now,
  tryGetDateISOFormat,
  convertUnitOfMeasure,
};
