/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Alert, Badge, Divider, Grid, Group, Paper, Space, Stack, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { ReactNode } from 'react';
import { AlertTriangle, Recycle, Target, Truck } from 'tabler-icons-react';
import { ReferenciaCodigo } from '../../../../../business/events/general';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  EventDestinationType,
  EventEquipmentType,
  EventPackagingType,
  EventTreatmentType,
  EventType,
  EventVehicleType,
} from '../../../../../models/core/events.type';
import { Feature } from '../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../utils/formatter.utils';
import EventDestinationView from './ReferenceView/Destination/EventDestinationView';
import EventEquipmentView from './ReferenceView/Equipment/EventEquipmentView';
import EventPackagingView from './ReferenceView/Packaging/EventPackagingView';
import EventServiceView from './ReferenceView/Service/EventServiceView';
import EventTreatmentView from './ReferenceView/Treatment/EventTreatmentView';
import EventVehicleView from './ReferenceView/Vehicle/EventVehicleView';

type DataViewProps = {
  data: EventType;
};

export default function ReferenceView(props: DataViewProps) {
  const clienteLocalData =
    props.data.referencia?.contrato?.residuo || props.data.referencia?.contrato?.servico || null;
  const propostaContato = props.data.referencia?.contrato?.proposta || null;

  const montarResiduoAcondicionamento = (item: EventPackagingType) => {
    return <EventPackagingView data={item} event={props.data} />;
  };

  const montarResiduoEquipamento = (item: EventEquipmentType) => {
    return <EventEquipmentView data={item} event={props.data} />;
  };

  const montarResiduoVeiculo = (item: EventVehicleType) => {
    return <EventVehicleView data={item} event={props.data} />;
  };

  const montarResiduoTratamento = (item: EventTreatmentType) => {
    return <EventTreatmentView data={item} event={props.data} />;
  };

  const montarResiduoDestinoFinal = (item: EventDestinationType) => {
    return <EventDestinationView data={item} event={props.data} />;
  };

  const montarDocumento = () => {
    const documento = props.data.referencia!.entidade.documento!;
    const entidade = props.data!;

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={2}>
            {entidade!.CNPJ ? (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={entidade.idEntidade?.toString() || ''}
                    name={entidade.nomeFantasia || entidade.razaoSocial || '-'}
                    nameSize="sm"
                    description={entidade!.nomeFantasia ? entidade!.razaoSocial : entidade!.CNPJ}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={entidade.idEntidade?.toString() || ''}
                    name={entidade.nome || '-'}
                    nameSize="sm"
                    description={entidade!.CPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Documento"
              text={
                <ProfileCardLink
                  id={documento.idEntidadeDocumento?.toString() || '-1'}
                  name={documento?.documentoTipo || documento?.outroTipo || '-'}
                  nameSize="sm"
                  description={documento.numeroDocumento || documento.observacao}
                  descriptionSize="xs"
                  showLink={false}
                />
              }
              size="sm"
            />
          </Grid.Col>
        </Grid>
      </Paper>
    );
  };

  const montarComissao = () => {
    const comissao = props.data.referencia!.comissao!;

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={2}>
            {comissao.cnpj ? (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={comissao.idEntidade?.toString() || ''}
                    name={comissao.nomeFantasia || comissao.razaoSocial || '-'}
                    nameSize="sm"
                    description={comissao!.nomeFantasia ? comissao!.razaoSocial : comissao!.cnpj}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Entidade"
                text={
                  <ProfileCardLink
                    id={comissao.idEntidade?.toString() || ''}
                    name={comissao.nome || '-'}
                    nameSize="sm"
                    description={comissao!.cpf}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Porcentagem"
              text={comissao?.porcentagem ? `${comissao.porcentagem}%` : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Preço"
              text={comissao?.preco ? formatCurrency(comissao.preco || 0) : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Ocorrências" text={comissao?.ocorrencia || '-'} size="sm" />
          </Grid.Col>
        </Grid>
      </Paper>
    );
  };

  const montarResiduo = () => {
    const residuo = props.data.referencia!.contrato.residuo!;
    const nodes: ReactNode[] = [];
    const tabs = [];

    switch (props.data.codigoEventoReferencia) {
      case ReferenciaCodigo.ContratoResiduoAcondicionamento:
        nodes.push(montarResiduoAcondicionamento(props.data.acondicionamento!));
        break;
      case ReferenciaCodigo.ContratoResiduoEquipamento:
        nodes.push(montarResiduoEquipamento(props.data.equipamento!));
        break;
      case ReferenciaCodigo.ContratoResiduoPlano:
        if (props.data.resumoJSON!.veiculoCompartilhado) {
          tabs.push({
            key: 'VeiculoCompartilhado',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Truck size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Veículo Compartilhado
                </Text>
              </Group>
            ),
            children: <>{montarResiduoVeiculo(props.data.resumoJSON!.veiculoCompartilhado!)}</>,
            forceRender: true,
          });
        }
        if (props.data.veiculo) {
          tabs.push({
            key: 'Veiculo',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Truck size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Veículo
                </Text>
              </Group>
            ),
            children: <>{montarResiduoVeiculo(props.data.veiculo!)}</>,
            forceRender: true,
          });
        }

        if (props.data.tratamento) {
          tabs.push({
            key: 'Tratamento',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Recycle size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Tratamento
                </Text>
              </Group>
            ),
            children: <>{montarResiduoTratamento(props.data.tratamento)}</>,
            forceRender: true,
          });
        }

        if (props.data.destinoFinal) {
          tabs.push({
            key: 'DestinoFinal',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Event.color} variant="outline">
                  <Target size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Event.color} weight={500}>
                  Destino Final
                </Text>
              </Group>
            ),
            children: <>{montarResiduoDestinoFinal(props.data.destinoFinal)}</>,
            forceRender: true,
          });
        }

        // TODO: 087 - not updated after removing relation (context api needed)
        nodes.push(
          <Paper shadow="xs" p="md" withBorder>
            {props.data.idEventoVeiculoCompartilhado && (
              <Alert icon={<AlertTriangle size={16} />} title="Veículo Compartilhado" color="yellow">
                Esse evento tem o veículo compartilhado. Veja mais detalhes na seção Relações.
              </Alert>
            )}
            <Tabs items={tabs} />
          </Paper>
        );

        break;
      default:
        break;
    }

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <Group>
            <ProfileCardLink
              id={residuo.idResiduo.toString()}
              name={residuo.residuoCliente}
              nameSize="md"
              description={`Código IBAMA: ${residuo.codigoIBAMA || '-'} | Perigoso: ${
                residuo.residuoClientePerigoso ? 'Sim' : 'Não'
              }`}
              descriptionSize="sm"
              linkPrefix="residues"
              showLink={false}
            />
            <Group>
              <Stack spacing="xs">
                <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
                  {`Estimativa: ${residuo.estimativa} ${residuo.estimativaUnidadeMedida1} / ${residuo.estimativaUnidadeMedida2}`}
                </Badge>
                {residuo.minimoAceitavel ? (
                  <Badge variant="outline" size="md" color="orange">
                    {`Mínimo Aceitável: ${residuo.minimoAceitavel} ${residuo.minimoAceitavelUnidadeMedida1} / ${residuo.minimoAceitavelUnidadeMedida2}`}
                  </Badge>
                ) : (
                  <></>
                )}
              </Stack>
              <Stack spacing="xs">
                {residuo.compra && (
                  <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
                    Compra
                  </Badge>
                )}
                {residuo.cobrarTolerancia && (
                  <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
                    Cobrar Tolerâncias
                  </Badge>
                )}
              </Stack>
            </Group>
          </Group>
        </Group>
        <Space h="sm" />

        <div>{nodes.map((x) => x)}</div>
      </Paper>
    );
  };

  const montarServico = () => {
    const servico = props.data.referencia!.contrato.servico!;
    const item = props.data.servico!;

    return (
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <Group>
            <ProfileCardLink
              id={servico.idServico.toString()}
              name={servico.servico}
              nameSize="md"
              description={servico.servicoDescricao}
              descriptionSize="sm"
              linkPrefix="services"
              showLink={false}
            />
            <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
              {`${servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}`}
            </Badge>
          </Group>
        </Group>
        <Space h="sm" />

        <EventServiceView data={item} event={props.data} />
      </Paper>
    );
  };

  return (
    <div>
      {clienteLocalData && (
        <>
          <Paper shadow="xs" p="md" withBorder>
            <PageSection
              size="lg"
              color={Feature.Home.Event.color}
              label="Cliente e Local"
              text="Cliente e local onde a prestação de serviço deverá ocorrer."
            />
            <Space h="xs" />

            <Grid columns={6}>
              <Grid.Col span={2}>
                {clienteLocalData.clienteCNPJ ? (
                  <ProfileCardLink
                    id={clienteLocalData.idCliente?.toString() || ''}
                    name={clienteLocalData.clienteNomeFantasia || clienteLocalData.clienteRazaoSocial || '-'}
                    nameSize="sm"
                    description={
                      clienteLocalData.clienteNomeFantasia
                        ? clienteLocalData.clienteRazaoSocial
                        : clienteLocalData.clienteCNPJ
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : (
                  <ProfileCardLink
                    id={clienteLocalData.idCliente?.toString() || ''}
                    name={clienteLocalData.clienteNome || '-'}
                    nameSize="sm"
                    description={clienteLocalData.clienteCPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                )}
              </Grid.Col>
              {propostaContato && (
                <Grid.Col span={2}>
                  <ProfileCardLink
                    id={propostaContato?.idEntidadeContato.toString() || '-1'}
                    name={propostaContato?.nome || '-'}
                    nameSize="sm"
                    description={
                      propostaContato?.telefone ||
                      propostaContato?.celular ||
                      propostaContato?.email ||
                      propostaContato?.setor
                    }
                    descriptionSize="xs"
                    showLink={false}
                  />
                </Grid.Col>
              )}
            </Grid>
            <Divider my="sm" />

            <Grid columns={6}>
              <Grid.Col span={2}>
                <PageViewProperty
                  label="Logradouro"
                  text={`${clienteLocalData.logradouro}, ${clienteLocalData.numero}${
                    clienteLocalData.complemento ? ` | ${clienteLocalData.complemento}` : ''
                  }`}
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <PageViewProperty
                  label="Bairro - Cidade"
                  text={`${clienteLocalData.bairro} - ${clienteLocalData.cidade} / ${clienteLocalData.codigoEstado}`}
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <PageViewProperty label="CEP" text={clienteLocalData.cep} size="sm" />
              </Grid.Col>
            </Grid>
          </Paper>
          <Space h="lg" />
        </>
      )}

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label={props.data.eventoTipo || 'Referência'}
          text="Informações detalhadas do evento como resíduo, serviço, documento, cliente, local, fornecedores, etc."
        />
        <Space h="xs" />

        {(props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
          props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
          props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) &&
          montarResiduo()}

        {props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoServico && montarServico()}

        {props.data.codigoEventoReferencia === ReferenciaCodigo.EntidadeDocumento && montarDocumento()}

        {props.data.codigoEventoReferencia === ReferenciaCodigo.ContratoComissao && montarComissao()}
      </Paper>
    </div>
  );
}
