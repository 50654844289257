/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Card, Center, Loader } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, Trash, X } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { InvitationType } from '../../../../models/core/invitations.type';
import invitationsService from '../../../../services/core/invitations.service';
import { Feature } from '../../../../utils/constants.utils';
import DataView from './components/DataView';

type Result = {
  loading: boolean;
  data: InvitationType | null;
};

function InvitationView() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const modals = useModals();
  const { idUsuarioConvite } = useParams();

  const [result, setResult] = useState<Result>({
    loading: location?.state?.invitation === undefined,
    data: location?.state?.invitation || null,
  });
  const [deleting, setDeleting] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data: InvitationType | null = null;
      try {
        data = await invitationsService.select({ idUsuarioConvite: idUsuarioConvite! });
      } catch (error: any) {
        showNotification({
          title: 'Convite - Visualizar',
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar o convite.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idUsuarioConvite, navigate, result]);

  const handleAction = async (action: string) => {
    try {
      setResult({ loading: false, data: result.data });

      switch (action) {
        case 'excluir':
          setDeleting(true);
          await invitationsService.delete({ idUsuarioConvite: idUsuarioConvite! });
          navigate('/invitations');
          break;
        case 'cancelar':
          setCancelling(true);
          await invitationsService.cancel({ idUsuarioConvite: idUsuarioConvite! });
          break;
        default:
          break;
      }

      showNotification({
        title: 'Convite - Visualizar',
        message: `Convite ${action === 'cancelar' ? 'cancelado' : 'excluído'} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Convite - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} o convite.`,
        color: 'red',
      });
    } finally {
      if (action === 'excluir') {
        setDeleting(false);
      } else {
        setCancelling(false);
        setResult({ loading: false, data: null });
      }
    }
  };

  const showModal = (action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse convite?`,
      size: 'sm',
      closeOnClickOutside: false,
      closeOnEscape: false,
      onConfirm: () => {
        handleAction(action);
      },
    });

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.Invitation}
        title="Convite - Visualizar"
        description="Visualize os detalhes de um convite."
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={cancelling || deleting || result.loading}
          >
            Voltar
          </Button>,
          <Button
            key="Cancelar"
            color="orange"
            leftIcon={<X size={18} />}
            onClick={() => {
              showModal('cancelar');
            }}
            hidden={result.data?.codigoUsuarioConviteStatus !== 'E'}
            disabled={deleting || result.loading}
            loading={cancelling}
          >
            Cancelar
          </Button>,
          <Button
            key="Excluir"
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal('excluir');
            }}
            disabled={cancelling || result.loading}
            loading={deleting}
          >
            Excluir
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <DataView data={result.data} />
        </PageContent>
      )}
    </Card>
  );
}

export default InvitationView;
