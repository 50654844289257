/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Group, Space } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { useProposalGeneralContext } from '../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { ClosingType, EntityTypeType, UnitOfMeasureType } from '../../../../../../models/core/cache.type';
import { Action } from '../../../../../../models/core/core.type';
import { ProposalCommissionType, ProposalType } from '../../../../../../models/core/proposals.type';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../utils/formatter.utils';
import CommissionFormAddEdit from './CommissionFormView/CommissionFormAddEdit';
import CommissionFormViewActions from './CommissionFormView/CommissionFormViewActions';

type ProposalCommissionFormViewProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  referenceData: {
    closingTypeData: ClosingType[];
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
  };
  proposal: ProposalType | null;
};

const CommissionFormView = forwardRef((props: ProposalCommissionFormViewProps, ref) => {
  const modals = useModals();
  const { proposalGeneralData } = useProposalGeneralContext();

  const [data, setData] = useState<ProposalCommissionType[]>(
    props.proposal?.comissoes.map((x) => {
      return {
        ...x,
        action: Action.Nothing,
        id: x.idPropostaComissao?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Entidade',
      key: 'idEntidade',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) =>
        (a.nomeFantasia || a.razaoSocial || a.nome || '').localeCompare(
          b.nomeFantasia || b.razaoSocial || b.nome || ''
        ),
      render: (row: ProposalCommissionType) => {
        if (row.idEntidade) {
          if (row.cnpj) {
            return (
              <ProfileCardLink
                id={row.idEntidade.toString()}
                name={row.nomeFantasia || row.razaoSocial || '-'}
                nameSize="sm"
                description={row.nomeFantasia ? row.razaoSocial : row.cnpj}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink={false}
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idEntidade.toString()}
              name={row.nome || '-'}
              nameSize="sm"
              description={row.cpf}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink={false}
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Porcentagem',
      key: 'porcentagem',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        const aValue = a.porcentagem || 0;
        const bValue = b.porcentagem || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalCommissionType) => <div>{row.porcentagem ? `${row.porcentagem}%` : '-'}</div>,
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalCommissionType) => <div>{row.preco ? formatCurrency(row.preco) : '-'}</div>,
    },
    {
      title: 'Ocorrências',
      key: 'ocorrencia',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        const aValue = a.ocorrencia || 0;
        const bValue = b.ocorrencia || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalCommissionType) => <div>{row.ocorrencia || '-'}</div>,
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ProposalCommissionType, b: ProposalCommissionType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ProposalCommissionType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalCommissionType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <CommissionFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: ProposalCommissionType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaComissao) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Comissão - ${item ? 'Editar' : 'Adicionar'}`,
          size: '50%',
          closeOnClickOutside: false,
          children: (
            <CommissionFormAddEdit
              referenceData={props.referenceData}
              proposalGeneralData={proposalGeneralData}
              origItem={
                item
                  ? props.proposal?.comissoes.find((x) => x.idPropostaComissao === item.idPropostaComissao) ||
                    null
                  : null
              }
              item={item}
              idProposta={props.proposal?.idProposta}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalCommissionType[] {
      data.forEach((x: ProposalCommissionType) => {
        // remove unnecessary data and format it
        delete (x as any).entidadeData;
      });
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempProposal = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempProposal) || 'null');
    if (tempProposal && props.proposal === null) {
      setData(tempProposal.comissoes);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Comissões"
          text="Todas os comissões associadas à proposta."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={!proposalGeneralData.codigoFechamento}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ProposalCommissionType) => item.id || 0}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default CommissionFormView;
