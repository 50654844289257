/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, MantineSize, Space, Text, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import filesize from 'filesize';
import { Download, Eye } from 'tabler-icons-react';
import { Buffer } from 'buffer';
import { showNotification, cleanNotifications } from '@mantine/notifications';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import { EventDocumentFileType } from '../../../../../../models/core/events.type';
import theme from '../../../../../../theme';
import { Feature } from '../../../../../../utils/constants.utils';
import filesService from '../../../../../../services/core/files.service';
import { FileDownloadSubTypeEnum, FileDownloadTypeEnum } from '../../../../../../models/core/files.type';

// TODO: App: 018 - Move this to a common area (same as entity file view)
type FileViewProps = {
  typeId: number | null;
  data: EventDocumentFileType[];
  size: MantineSize;
  expandable: boolean;
};

export default function FileView(props: FileViewProps) {
  const download = async (
    row: EventDocumentFileType,
    notificationTitleLoading: string,
    notificationTitleDone: string,
    notificationTitleFailed: string
  ) => {
    try {
      showNotification({
        title: notificationTitleLoading,
        message: (
          <Text size="sm" color="dimmed">
            {`${row.nome} (${filesize(row.tamanho, { locale: 'pt-BR' })})`}
          </Text>
        ),
        loading: true,
        color: 'primary',
        autoClose: false,
      });

      const response = await filesService.download({
        tipo: FileDownloadTypeEnum.Event,
        idTipo: props.typeId!,
        subTipo: FileDownloadSubTypeEnum.Document,
        idSubTipo: row.idEventoDocumento,
        arquivo: row.nome,
        idArquivo: row.idEventoDocumentoArquivo!,
      });

      cleanNotifications();

      const blob = new Blob([Buffer.from(response.data, 'base64')], { type: row.tipo });

      showNotification({
        title: notificationTitleDone,
        message: (
          <Text size="sm" color="dimmed">
            {`${row.nome} (${filesize(row.tamanho, { locale: 'pt-BR' })})`}
          </Text>
        ),
        color: 'green',
      });

      return blob;
    } catch (error: any) {
      cleanNotifications();
      showNotification({
        title: error?.isBusinessException ? error.description : notificationTitleFailed,
        message: (
          <Text size="sm" color="dimmed">
            {`${row.nome} (${filesize(row.tamanho, { locale: 'pt-BR' })})`}
          </Text>
        ),
        color: 'red',
      });
      throw error;
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: EventDocumentFileType, b: EventDocumentFileType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Tamanho',
      key: 'tamanho',
      sorter: (a: EventDocumentFileType, b: EventDocumentFileType) => {
        const aValue = a.tamanho;
        const bValue = b.tamanho;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventDocumentFileType) => <div>{filesize(row.tamanho, { locale: 'pt-BR' })}</div>,
    },
  ];

  if (props.expandable) {
    columns.push({
      title: 'Ações',
      width: '100px',
      render: (row: EventDocumentFileType) => (
        <Group>
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.primary?.[6] }}
              variant="outline"
              onClick={async () => {
                const blob = await download(
                  row,
                  'Preparando arquivo para visualização.',
                  'Arquivo preparado para visualização com sucesso.',
                  'Não foi possível preparar o arquivo para visualização.'
                );
                window.open(window.URL.createObjectURL(blob));
              }}
            >
              <Eye size={15} color={theme?.colors?.primary?.[6]} />
            </ActionIcon>
          </Tooltip>
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Baixar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.accent?.[6] }}
              variant="outline"
              onClick={async () => {
                const blob = await download(
                  row,
                  'Baixando arquivo.',
                  'Arquivo baixado com sucesso.',
                  'Não foi possível baixar o arquivo.'
                );
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = row.nome;
                link.click();
              }}
            >
              <Download size={15} color={theme?.colors?.accent?.[6]} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    });
  }

  return (
    <div>
      <Group>
        <PageSection
          size={props.size === 'lg' ? 'lg' : 'md'}
          color={Feature.Home.Event.color}
          label="Arquivos"
          text=""
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        size={props.size === 'lg' ? 'middle' : 'small'}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EventDocumentFileType) => item.idEventoDocumentoArquivo || item.id || 0}
        expandable={
          !props.expandable
            ? undefined
            : {
                // eslint-disable-next-line react/no-unstable-nested-components
                expandedRowRender: (item) => (
                  <PageViewAudit
                    idCriadoPor={item.idCriadoPor}
                    criadoPor={item.criadoPor}
                    dataCriacao={item.dataCriacao}
                    idModificadoPor={item.idModificadoPor || null}
                    modificadoPor={item.modificadoPor || null}
                    dataModificacao={item.dataModificacao || null}
                    size="sm"
                  />
                ),
              }
        }
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
