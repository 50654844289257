/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Bar, BarConfig } from '@ant-design/plots';
import { Button, Grid, Group, LoadingOverlay, Paper, Select, Space, Stack, Text } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table as ITable, PlugConnectedX, Reload, SortDescendingNumbers, TableOff } from 'tabler-icons-react';
import { TipoCodigo } from '../../../../../../../business/events/general';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractType } from '../../../../../../../models/core/contracts.type';
import { ContractSingleEquipmentType, ReportItem } from '../../../../../../../models/core/report.type';
import reportsService from '../../../../../../../services/core/report.service';
import theme from '../../../../../../../theme';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../../utils/formatter.utils';

type FormViewProps = {
  data: ContractType;
  height: number | undefined;
};

type FilterData = {
  periodo: Date[];
  ordem: string;
};

type Response = {
  filterConfig: FilterData;
  data: ContractSingleEquipmentType[];
  chartConfig: BarConfig;
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Alocação de Equipamentos',
  description: 'Quantidade total de equipamentos alocados no cliente por fornecedor.',
  cacheKey: `${SessionStorageKey.ContractStats}-{ID}-${ReportItem.ContratoIndividualEquipamentos}`,
  filterConfig: {
    ordemData: [
      {
        value: 'referencia',
        label: `Equipamento`,
      },
      {
        value: 'fornecedor',
        label: `Fornecedor`,
      },
    ],
    ordem: 'referencia',
  },
  dataConfig: {
    exportable: true,
    rowKey: '',
    columns: [
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (a: ContractSingleEquipmentType, b: ContractSingleEquipmentType) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleEquipmentType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (a: ContractSingleEquipmentType, b: ContractSingleEquipmentType) =>
          new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: ContractSingleEquipmentType) => formatDateToString(row.data),
      },
      {
        title: 'Fornecedor',
        key: 'idFornecedor',
        sorter: (a: ContractSingleEquipmentType, b: ContractSingleEquipmentType) =>
          (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
            b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
          ),
        render: (row: ContractSingleEquipmentType) => {
          if (row.idFornecedor) {
            if (row.fornecedorCNPJ) {
              return (
                <ProfileCardLink
                  id={row.idFornecedor.toString()}
                  name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
                  nameSize="sm"
                  description={row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ}
                  descriptionSize="xs"
                  linkPrefix="entities"
                  showLink
                />
              );
            }
            return (
              <ProfileCardLink
                id={row.idFornecedor.toString()}
                name={row.fornecedorNome || '-'}
                nameSize="sm"
                description={row.fornecedorCPF}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return '-';
        },
      },
      {
        title: 'Equipamento',
        key: 'residuoEquipamento',
        sorter: (a: ContractSingleEquipmentType, b: ContractSingleEquipmentType) => {
          return a.residuoEquipamento.localeCompare(b.residuoEquipamento);
        },
        render: (row: ContractSingleEquipmentType) => {
          return (
            <ProfileCardLink
              id={(row.idResiduoEquipamento || '').toString()}
              name={row.residuoEquipamento || '-'}
              nameSize="sm"
              linkPrefix="equipment"
              showLink
            />
          );
        },
      },
      {
        title: 'Quantidade',
        key: 'quantidade',
        sorter: (a: ContractSingleEquipmentType, b: ContractSingleEquipmentType) => {
          const aValue = a.quantidade;
          const bValue = b.quantidade;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleEquipmentType) => (
          <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
        ),
      },
    ],
  },

  chartConfig: {
    data: [],
    isStack: true,
    xField: 'quantidade',
    yField: 'referencia',
    seriesField: 'fornecedor',
    label: {
      position: 'middle',
    },
    legend: {
      position: 'right',
    },
  } as BarConfig,
};

export default function ContractSingleEquipament(props: FormViewProps) {
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          periodo: [
            moment(props.data.dataInicial, 'yyyy-MM-DD').toDate(),
            moment(
              props.data.dataFinal > props.data.dataInicial
                ? props.data.dataFinal
                : props.data.dataFinalOriginal,
              'yyyy-MM-DD'
            ).toDate(),
          ],
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idContrato: props.data.idContrato,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.contratoIndividualEquipamentos(searchCriteria);
    return data;
  };

  const sortMe = (data: any[]) => {
    return data.sort((a: any, b: any) => a[form.values.ordem].localeCompare(b[form.values.ordem]));
  };

  const buildChartData = (data: ContractSingleEquipmentType[]) => {
    const calcData = data.map((x) => {
      return {
        ...x,
        grupo: `${x.idResiduoEquipamento}-${x.idFornecedor}`,
        total: x.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ? x.quantidade : x.quantidade * -1,
      };
    });

    const finalData = [];
    const groupedData = lodash.groupBy(calcData, 'grupo');
    for (const key of Object.keys(groupedData)) {
      const items = groupedData[key];
      const item = items[0];
      const referencia = item.residuoEquipamento;
      const total = lodash.sumBy(items, 'total');

      finalData.push({
        referencia,
        quantidade: total,
        fornecedor: item.fornecedorNomeFantasia || item.fornecedorRazaoSocial || item.fornecedorNome || '?',
      });
    }
    return sortMe(finalData);
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const chartData = buildChartData(data);

    return {
      filterConfig: form.values,
      data,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: chartData,
      },
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', props.data.idContrato.toString());
    try {
      load(() => {}, true);
    } catch (error) {}

    return () => {
      sessionStorage.removeItem(componentConfig.cacheKey);
    };
  }, []);

  useEffect(() => {
    const filterConfig = result.response?.filterConfig;
    const ordemChanged = form.values.ordem !== filterConfig?.ordem;

    if (ordemChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  const noDataView = () => {
    return (
      <Stack spacing="md" align="center">
        <TableOff color={theme?.colors?.primary?.[6]} size={32} />
        <Text>Sem dados para mostrar.</Text>
      </Stack>
    );
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Contract.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Contract.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Contrato - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Contract.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} overlayBlur={3} />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Select
                icon={<SortDescendingNumbers size={15} />}
                label="Ordem"
                description="Filtro: Igual"
                placeholder="Selecione..."
                data={componentConfig.filterConfig.ordemData}
                {...form.getInputProps('ordem')}
              />
            </Group>
          </Paper>
          <Space h="md" />

          {result.error ? (
            <Stack spacing="md" align="center">
              <PlugConnectedX color="red" size={32} />
              <Text>{result.error}</Text>
            </Stack>
          ) : result.loaded ? (
            (result.response?.data || []).length > 0 ? (
              <Grid columns={4}>
                <Grid.Col span={4}>
                  <Paper shadow="xs" p="md" withBorder>
                    {(result.response?.data || []).length === 0 ? (
                      noDataView()
                    ) : (
                      <Bar {...result.response?.chartConfig!} height={props.height} />
                    )}
                  </Paper>
                </Grid.Col>
              </Grid>
            ) : (
              <Paper shadow="xs" p="md" withBorder>
                {noDataView()}
              </Paper>
            )
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <Reload color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Esperando atualização</Text>
              </Stack>
            </Paper>
          )}
        </div>
      </Paper>
    </Paper>
  );
}
