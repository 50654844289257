/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Button, Grid, Group, Paper, SimpleGrid, Space, Switch, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DeviceFloppy, Notes } from 'tabler-icons-react';
import DestinationSearch from '../../../../../../../../../../../../components/core/DestinationSearch/DestinationSearch';
import { UnitOfMeasureType } from '../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../models/core/core.type';
import { DestinationType } from '../../../../../../../../../../../../models/core/destinations.type';
import { ProposalResiduePlanDestinationType } from '../../../../../../../../../../../../models/core/proposals.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoDestinoFinal: number | null;
  residuoDestinoFinal: string;
  residuoDestinoFinalDescricao: string | null;

  autorizacaoAmbiental: boolean;
  observacao: string | null;
};

type EntityFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanDestinationType | null;
  item: ProposalResiduePlanDestinationType | null;
  idPropostaResiduoPlano: number | undefined;
  callback(item: ProposalResiduePlanDestinationType | null, action: string, confirmed: boolean): void;
};

export default function ResiduePlanDestinationFormAddEdit(props: EntityFormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoDestinoFinal: props.item?.idResiduoDestinoFinal || null,
      residuoDestinoFinal: props.item?.residuoDestinoFinal || '',
      residuoDestinoFinalDescricao: props.item?.residuoDestinoFinalDescricao || null,

      autorizacaoAmbiental: props.item?.autorizacaoAmbiental || false,
      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoDestinoFinal: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoDestinoFinal || ''}
    |${props.origItem?.autorizacaoAmbiental || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoDestinoFinal || ''}
    |${form.values.autorizacaoAmbiental || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }
    if (props.origItem) {
      if (isModified() && props.origItem.idPropostaResiduoPlanoDestinoFinal) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
        };
      }
    }

    const formItem: ProposalResiduePlanDestinationType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaResiduoPlano: props.idPropostaResiduoPlano || -1,
    };

    formItem.observacao = formItem.observacao?.trim() || null;

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form id="residue-plan-destination" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={4}>
            <DestinationSearch
              label="Destino Final"
              formItem={form.getInputProps('residuoDestinoFinal')}
              idsToBeDisabled={[]}
              callback={(item: DestinationType | null) => {
                if (item) {
                  form.setFieldValue('idResiduoDestinoFinal', item.idResiduoDestinoFinal);
                  form.setFieldValue('residuoDestinoFinal', item.residuoDestinoFinal);
                  form.setFieldValue('residuoDestinoFinalDescricao', item.descricao);
                }
              }}
              disabled={false}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <div style={{ marginTop: 29 }}>
              <Switch
                label="Autorização Ambiental?"
                {...form.getInputProps('autorizacaoAmbiental', { type: 'checkbox' })}
              />
            </div>
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o destino final"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="residue-plan-destination"
          onClick={() => handleSubmit(form.values)}
        >
          Salvar
        </Button>
      </Group>
    </form>
  );
}
