/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Anchor, MantineSize, Text } from '@mantine/core';
import { Check, X } from 'tabler-icons-react';

type PageViewPropertyProps = {
  label: string;
  text: string | React.ReactNode;
  size: MantineSize;
};

export default function PageViewProperty(props: PageViewPropertyProps) {
  const isTextString = typeof props.text === 'string';
  const textString = props.text!.toString();

  const isTextBoolean = textString === 'Sim' || textString === 'Não';

  let textRendered = isTextString ? (
    <Text size={props.size} color="primary">
      {textString}
    </Text>
  ) : (
    props.text
  );

  if (isTextString) {
    if (isTextBoolean) {
      textRendered = textString === 'Sim' ? <Check color="green" /> : <X color="red" />;
    } else if (textString.startsWith('http') || textString.startsWith('www.')) {
      textRendered = (
        <Anchor href={`//${textString}`} target="_blank" color="blue">
          {textString}
        </Anchor>
      );
    }
  }

  return (
    <div>
      <Text size={props.size} color="primary" weight={500}>
        {props.label}
      </Text>
      {textRendered}
    </div>
  );
}
