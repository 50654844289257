/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { DestinationType } from '../../../../../models/core/destinations.type';
import { formatTimestampToString } from '../../../../../utils/formatter.utils';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: DestinationType[];
  confirmActionResult(item: DestinationType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Destino Final',
      key: 'residuoDestinoFinal',
      dataIndex: 'residuoDestinoFinal',
      sorter: (a: DestinationType, b: DestinationType) =>
        a.residuoDestinoFinal.localeCompare(b.residuoDestinoFinal),
    },
    {
      title: 'Descrição',
      key: 'descricao',
      sorter: (a: DestinationType, b: DestinationType) => {
        return (a.descricao || '').localeCompare(b.descricao || '');
      },
      render: (row: DestinationType) => (
        <TableCellEllipsis label={row?.descricao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: DestinationType, b: DestinationType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: DestinationType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Data Criação',
      key: 'dataCriacao',
      sorter: (a: DestinationType, b: DestinationType) =>
        new Date(a.dataCriacao).valueOf() - new Date(b.dataCriacao).valueOf(),
      render: (row: DestinationType) => formatTimestampToString(row.dataCriacao),
    },
    {
      title: 'Modificado Por',
      key: 'modificadoPor',
      sorter: (a: DestinationType, b: DestinationType) =>
        (a.modificadoPor || '').localeCompare(b.modificadoPor || ''),
      render: (row: DestinationType) => {
        if (row.idModificadoPor) {
          return (
            <ProfileCardLink id={row.idModificadoPor || ''} name={row.modificadoPor || ''} nameSize="sm" />
          );
        }
        return '-';
      },
    },
    {
      title: 'Data Modificação',
      key: 'dataModificacao',
      sorter: (a: DestinationType, b: DestinationType) => {
        const date = new Date();
        return new Date(a.dataModificacao || date).valueOf() - new Date(b.dataModificacao || date).valueOf();
      },
      render: (row: DestinationType) => {
        if (row.dataModificacao) {
          return formatTimestampToString(row.dataModificacao);
        }
        return '-';
      },
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: DestinationType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: DestinationType) => item.idResiduoDestinoFinal}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
