/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { DeviceFloppy, Refresh } from 'tabler-icons-react';
import { EntityContactType } from '../../../models/core/entities.type';
import entitiesService from '../../../services/core/entities.service';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';

type FormViewProps = {
  referenceData: {
    idEntidade: number;
  };
  idsToBeDisabled: number[];
  callback(item: EntityContactType | null, confirmed: boolean): void;
};

export default function EntityContactListModal(props: FormViewProps) {
  const [data, setData] = useState<EntityContactType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<EntityContactType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const filter = async (idEntidade: number) => {
    try {
      setLoading(true);
      const result = await entitiesService.listContacts({
        idEntidade,
      });
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Entidade - Contatos`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível listar os contatos dessa entidade.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EntityContactType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: EntityContactType) => ({
      disabled: props.idsToBeDisabled.includes(record.idEntidade),
      name: record.idEntidade.toString(),
    }),
  };

  useEffect(() => {
    filter(props.referenceData.idEntidade);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Paper shadow="xs" p="md" withBorder>
        <Table
          showSorterTooltip={false}
          size="small"
          dataSource={data}
          columns={[
            {
              title: 'Nome',
              key: 'nome',
              dataIndex: 'nome',
              sorter: (a: EntityContactType, b: EntityContactType) => a.nome.localeCompare(b.nome),
            },
            {
              title: 'Setor',
              key: 'setor',
              sorter: (a: EntityContactType, b: EntityContactType) => {
                return (a.setor || '').localeCompare(b.setor || '');
              },
              render: (row: EntityContactType) => <div>{row?.setor || '-'}</div>,
            },
            {
              title: 'E-mail',
              key: 'email',
              sorter: (a: EntityContactType, b: EntityContactType) => {
                return (a.email || '').localeCompare(b.email || '');
              },
              render: (row: EntityContactType) => <div>{row?.email || '-'}</div>,
            },
            {
              title: 'Telefone',
              key: 'telefone',
              sorter: (a: EntityContactType, b: EntityContactType) => {
                return (a.telefone || '').localeCompare(b.telefone || '');
              },
              render: (row: EntityContactType) => <div>{row?.telefone || '-'}</div>,
            },
            {
              title: 'Ramal',
              key: 'telefoneRamal',
              sorter: (a: EntityContactType, b: EntityContactType) => {
                return (a.telefoneRamal || '').localeCompare(b.telefoneRamal || '');
              },
              render: (row: EntityContactType) => <div>{row?.telefoneRamal || '-'}</div>,
            },
            {
              title: 'Celular',
              key: 'celular',
              sorter: (a: EntityContactType, b: EntityContactType) => {
                return (a.celular || '').localeCompare(b.celular || '');
              },
              render: (row: EntityContactType) => <div>{row?.celular || '-'}</div>,
            },
            {
              title: 'Observação',
              key: 'observacao',
              sorter: (a: EntityContactType, b: EntityContactType) => {
                return (a.observacao || '').localeCompare(b.observacao || '');
              },
              render: (row: EntityContactType) => (
                <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />
              ),
            },
          ]}
          rowSelection={{ type: 'radio', ...rowSelection }}
          rowKey={(item: EntityContactType) => item.idEntidadeContato || 0}
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button
          color="accent"
          leftIcon={<Refresh size={18} />}
          onClick={() => {
            filter(props.referenceData.idEntidade);
          }}
        >
          Atualizar
        </Button>
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
