import { Affix, Button, Card, Grid, LoadingOverlay, Select, Space, Tooltip, Transition } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useWindowScroll } from '@mantine/hooks';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { ArrowNarrowUp, Building, Reload } from 'tabler-icons-react';
import ChartCard from '../../../components/core/ChartCard/ChartCard';
import PageContent from '../../../components/core/PageContent/PageContent';
import PageHeader from '../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { CompanyType } from '../../../models/core/cache.type';
import { DashboardItem } from '../../../models/core/dashboard.type';
import cacheUtils from '../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../utils/constants.utils';

type FilterData = {
  idEmpresa: string | null;
};

function DashboardGeneral() {
  const FORMAT = 'DD/MM/YYYY HH:mm:ss';
  const [currentUser] = useCurrentUser();
  const refMyEvents = useRef<any>();
  const refPendingEvents = useRef<any>();
  const refExpiringContracts = useRef<any>();
  const refExpiringProposals = useRef<any>();
  const refExpiringEntityDocuments = useRef<any>();
  const [scroll, scrollTo] = useWindowScroll();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
  }>({
    companyData: [],
  });

  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);

  const form = useForm<FilterData>({
    initialValues: {
      idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
    },
  });
  const idEmpresa =
    !form.values.idEmpresa || form.values.idEmpresa === '0' ? null : Number(form.values.idEmpresa);

  const refresh = () => {
    Promise.all([
      refMyEvents.current.load(),
      refPendingEvents.current.load(),
      refExpiringContracts.current.load(),
      refExpiringProposals.current.load(),
      refExpiringEntityDocuments.current.load(),
    ]).then(() => {
      const now = moment().format(FORMAT);
      sessionStorage.setItem(SessionStorageKey.DashboardGeneral, now);
      setData(now);
    });
  };

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      setFilterData({
        companyData: await cacheUtils.listCompanies(),
      });
      setLoading(false);

      const cacheResult = sessionStorage.getItem(SessionStorageKey.DashboardGeneral);
      if (cacheResult) {
        setData(cacheResult);
      } else {
        refresh();
      }
    };

    loadFilterData();
  }, []);

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Dashboard}
        title="Dashboard Geral"
        description="Resumo de pendências gerais e individuais juntamente com indicadores."
        buttons={[
          (
            <Select
              icon={<Building size={15} />}
              placeholder="Empresa"
              data={filterData.companyData.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
          ) as any,
          (
            <Tooltip label={`Atualizado em ${data}`}>
              <Button color="accent" leftIcon={<Reload size={18} />} disabled={loading} onClick={refresh}>
                Atualizar
              </Button>
            </Tooltip>
          ) as any,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Grid columns={4}>
            <Grid.Col span={2}>
              <ChartCard
                ref={refMyEvents}
                item={DashboardItem.MeusEventos}
                filter={{ idEmpresa }}
                title="Meus Eventos"
                description="Eventos ativos atualmente assinalados para mim agrupados por status."
                exportable
                height={300}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <ChartCard
                ref={refPendingEvents}
                item={DashboardItem.EventosAlerta}
                filter={{ idEmpresa }}
                title="Eventos Pendentes"
                description="Eventos com 7+ dias passados de hoje e ainda ativos."
                exportable
                height={300}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={3}>
            <Grid.Col span={1}>
              <ChartCard
                ref={refExpiringContracts}
                item={DashboardItem.ContratosExpiracao}
                filter={{ idEmpresa }}
                title="Contratos Expirando"
                description="Contratos a serem expirados em até 90 dias."
                exportable
                height={300}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <ChartCard
                ref={refExpiringProposals}
                item={DashboardItem.PropostasExpiracao}
                filter={{ idEmpresa }}
                title="Propostas Expirando"
                description="Propostas a serem expiradas em até 90 dias."
                exportable
                height={300}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <ChartCard
                ref={refExpiringEntityDocuments}
                item={DashboardItem.EntidadeDocumentosExpiracao}
                filter={undefined}
                title="Documentos Expirando"
                description="Documentos de entidades a serem expirados em até 90 dias."
                exportable
                height={300}
              />
            </Grid.Col>
          </Grid>
        </div>
      </PageContent>

      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default DashboardGeneral;
