/* eslint-disable react/destructuring-assignment */
import { Badge } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { UserType } from '../../../../../models/core/users.type';
import { formatDateToString } from '../../../../../utils/formatter.utils';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: UserType[];
  confirmActionResult(item: UserType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: UserType, b: UserType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Sobrenome',
      key: 'sobrenome',
      dataIndex: 'sobrenome',
      sorter: (a: UserType, b: UserType) => a.sobrenome.localeCompare(b.sobrenome),
    },
    {
      title: 'Gênero',
      key: 'genero',
      sorter: (a: UserType, b: UserType) => a.genero.localeCompare(b.genero),
      render: (row: UserType) => (row.genero.toLowerCase() === 'm' ? 'Masculino' : 'Feminino'),
    },
    {
      title: 'Data Nascimento',
      key: 'dataNascimento',
      sorter: (a: UserType, b: UserType) =>
        new Date(a.dataNascimento.toString().split('T')[0]).valueOf() -
        new Date(b.dataNascimento.toString().split('T')[0]).valueOf(),
      render: (row: UserType) => formatDateToString(row.dataNascimento),
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      sorter: (a: UserType, b: UserType) => a.email.localeCompare(b.email),
    },
    {
      title: 'Empresa',
      key: 'empresa',
      dataIndex: 'empresa',
      sorter: (a: UserType, b: UserType) => a.empresa.localeCompare(b.empresa),
    },
    {
      title: 'Departamento',
      key: 'departamento',
      dataIndex: 'departamento',
      sorter: (a: UserType, b: UserType) => a.departamento.localeCompare(b.departamento),
    },
    {
      title: 'Status',
      key: 'status',
      sorter: (a: UserType, b: UserType) => a.status.localeCompare(b.status),
      render: (row: UserType) => (
        <Badge color={row.status === 'active' ? 'primary' : 'red'} variant="outline">
          {row.status === 'active' ? 'Ativo' : 'Inativo'}
        </Badge>
      ),
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: UserType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: UserType) => item.idUsuario}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
