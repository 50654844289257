/* eslint-disable react/destructuring-assignment */
import { Badge } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { InvitationType } from '../../../../../models/core/invitations.type';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: InvitationType[];
  confirmActionResult(item: InvitationType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: InvitationType, b: InvitationType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      sorter: (a: InvitationType, b: InvitationType) => a.email.localeCompare(b.email),
    },
    {
      title: 'Empresa',
      key: 'empresa',
      dataIndex: 'empresa',
      sorter: (a: InvitationType, b: InvitationType) => a.empresa.localeCompare(b.empresa),
    },
    {
      title: 'Departamento',
      key: 'departamento',
      dataIndex: 'departamento',
      sorter: (a: InvitationType, b: InvitationType) => a.departamento.localeCompare(b.departamento),
    },
    {
      title: 'Status',
      key: 'usuarioConviteStatus',
      sorter: (a: InvitationType, b: InvitationType) =>
        a.usuarioConviteStatus.localeCompare(b.usuarioConviteStatus),
      render: (row: InvitationType) => <Badge variant="outline">{row.usuarioConviteStatus}</Badge>,
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: InvitationType, b: InvitationType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: InvitationType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: InvitationType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: InvitationType) => item.idUsuarioConvite}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
