/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Grid, Paper } from '@mantine/core';
import { calcularPreco } from '../../../../business/proposals/estimate';
import PageViewProperty from '../../../../components/core/PageViewProperty/PageViewProperty';
import { formatCurrency } from '../../../../utils/formatter.utils';

type FormViewProps = {
  preco: number;
  margem: number | null | undefined;
  precoFinal: number | null | undefined;
  imposto: number | null | undefined;
  compra: boolean;
  receita: boolean;
};

function PriceMarginTaxFormView(props: FormViewProps) {
  const tipo = props.precoFinal === null || props.precoFinal === undefined ? '%' : '$';

  const precoCalculado = calcularPreco(
    Number(props.preco),
    Number(props.preco),
    tipo === '%' ? props.margem : null,
    tipo === '$' ? props.precoFinal : null,
    props.imposto || 0,
    props.compra,
    props.receita,
    false
  );

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Grid columns={4}>
        <Grid.Col span={1}>
          <PageViewProperty
            label={tipo === '%' ? 'Precificação - Margem' : 'Precificação - Preço'}
            text={tipo === '%' ? `${(props.margem || 0).toFixed(2)}%` : formatCurrency(props.precoFinal || 0)}
            size="sm"
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty label="Imposto" text={`${(props.imposto || 0).toFixed(2)}%`} size="sm" />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Preço (Cliente)"
            text={
              <div
                style={{
                  paddingTop: 5,
                  fontSize: 'medium',
                }}
              >
                {formatCurrency(props.receita ? precoCalculado.preco : precoCalculado.novoPrecoComMargem)}
              </div>
            }
            size="sm"
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Resultado"
            text={
              <div
                style={{
                  paddingTop: 5,
                  fontSize: 'medium',
                  color:
                    precoCalculado.novaMargemFinal > 0
                      ? 'green'
                      : precoCalculado.novaMargemFinal === 0
                      ? 'orange'
                      : 'red',
                }}
              >
                {formatCurrency(precoCalculado.novaMargemFinal)}
                {precoCalculado.novaMargemFinalPorcentagem !== Infinity &&
                precoCalculado.novaMargemFinalPorcentagem !== -Infinity
                  ? ` (${(precoCalculado.novaMargemFinalPorcentagem || 0).toFixed(2)}%)`
                  : ''}
              </div>
            }
            size="sm"
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

export default PriceMarginTaxFormView;
