/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Button, Group, LoadingOverlay, Paper, Space, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { Check, DeviceFloppy, ExternalLink, Refresh, X } from 'tabler-icons-react';
import { EntityTypeType } from '../../../models/core/cache.type';
import { EntitySearchResponseType } from '../../../models/core/entities.type';
import entitiesService from '../../../services/core/entities.service';
import theme from '../../../theme';
import { EntityItemEnum } from '../../../utils/constants.utils';

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
  };
  item: EntityItemEnum;
  itemId: number;
  idsToBeDisabled: number[];
  callback(item: EntitySearchResponseType | null, confirmed: boolean): void;
};

export default function EntityItemSearchModal(props: FormViewProps) {
  const [data, setData] = useState<EntitySearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<EntitySearchResponseType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const filter = async () => {
    try {
      setLoading(true);
      let result: EntitySearchResponseType[] = [];

      switch (props.item) {
        case EntityItemEnum.Packaging:
          result = await entitiesService.searchByPackagingId({
            idResiduoAcondicionamento: props.itemId,
          });
          break;
        case EntityItemEnum.Destination:
          result = await entitiesService.searchByDestinationId({
            idResiduoDestinoFinal: props.itemId,
          });
          break;
        case EntityItemEnum.Equipment:
          result = await entitiesService.searchByEquipmentId({
            idResiduoEquipamento: props.itemId,
          });
          break;
        case EntityItemEnum.Service:
          result = await entitiesService.searchByServiceId({
            idServico: props.itemId,
          });
          break;
        case EntityItemEnum.Treatment:
          result = await entitiesService.searchByTreatmentId({
            idResiduoTratamento: props.itemId,
          });
          break;
        case EntityItemEnum.Vehicle:
          result = await entitiesService.searchByVehicleId({
            idResiduoVeiculo: props.itemId,
          });
          break;
        default:
          break;
      }

      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Fornecedor - Pesquisar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar entidades.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EntitySearchResponseType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: EntitySearchResponseType) => ({
      disabled: props.idsToBeDisabled.includes(record.idEntidade),
      name: record.idEntidade.toString(),
    }),
  };

  useEffect(() => {
    filter();
  }, []);

  const buildBadges = (list: string[]) => {
    if ((list || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x} variant="outline" size="sm">
            {props.referenceData.entityTypeData.find((y) => y.codigoEntidadeTipo === x)?.entidadeTipo}
          </Badge>
        ))}
      </Group>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Paper shadow="xs" p="md" withBorder>
        <Table
          showSorterTooltip={false}
          size="small"
          dataSource={data}
          columns={[
            {
              title: 'Escopos',
              key: 'tipos',
              render: (row: EntitySearchResponseType) => buildBadges(row?.tipos?.toString().split(',')),
            },
            {
              title: 'CNPJ/CPF',
              key: 'cnpj-cpf',
              width: '180px',
              sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
                return (a.cnpj || a.cpf || '').localeCompare(b.cnpj || b.cpf || '');
              },
              render: (row: EntitySearchResponseType) => <div>{row?.cnpj || row?.cpf}</div>,
            },
            {
              title: 'Razão Social/Nome',
              key: 'razaoSocial-nome',
              sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
                return (a.razaoSocial || a.nome || '').localeCompare(b.razaoSocial || b.nome || '');
              },
              render: (row: EntitySearchResponseType) => <div>{row?.razaoSocial || row?.nome}</div>,
            },
            {
              title: 'Nome Fantasia',
              key: 'nomeFantasia',
              sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) => {
                return (a.nomeFantasia || '').localeCompare(b.nomeFantasia || '');
              },
              render: (row: EntitySearchResponseType) => <div>{row?.nomeFantasia || '-'}</div>,
            },
            {
              title: 'Encontro de Contas?',
              key: 'aceitaEncontroContas',
              dataIndex: 'aceitaEncontroContas',
              sorter: (a: EntitySearchResponseType, b: EntitySearchResponseType) =>
                // eslint-disable-next-line no-nested-ternary
                a.aceitaEncontroContas === b.aceitaEncontroContas ? 0 : a.aceitaEncontroContas ? -1 : 1,
              render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
            },
            {
              title: 'Ações',
              width: '100px',
              render: (row: EntitySearchResponseType) => (
                <Tooltip withArrow transition="fade" transitionDuration={200} label="Abrir">
                  <ActionIcon
                    size="sm"
                    color="primary"
                    style={{ borderColor: theme?.colors?.primary?.[6] }}
                    variant="outline"
                    onClick={() => {
                      const link = document.createElement('a');
                      link.setAttribute('href', `/entities/${row.idEntidade}`);
                      link.setAttribute('target', '_blank');
                      link.click();
                    }}
                  >
                    <ExternalLink size={15} color={theme?.colors?.primary?.[6]} />
                  </ActionIcon>
                </Tooltip>
              ),
            },
          ]}
          rowSelection={{ type: 'radio', ...rowSelection }}
          rowKey={(item: EntitySearchResponseType) => item.idEntidade}
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button color="accent" leftIcon={<Refresh size={18} />} onClick={filter}>
          Atualizar
        </Button>
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
