/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { Action } from '../../models/core/core.type';
import { ProposalType } from '../../models/core/proposals.type';
import { ProposalTypeEnum } from './status';

const duplicar = (proposta: ProposalType, reajuste: boolean) => {
  proposta.idPropostaPai = proposta.idProposta;
  proposta.action = Action.Add;

  for (const residuo of proposta.residuos) {
    residuo.idPropostaResiduoPai = reajuste ? residuo.idPropostaResiduo : null;
    residuo.action = Action.Add;
    residuo.documento.action = Action.Add;
    residuo.recorrencia.action = Action.Add;

    for (const plano of residuo.planos) {
      plano.action = Action.Add;

      for (const x of plano.acondicionamentos) {
        x.action = Action.Add;
        (x as any).id = x.idPropostaResiduoPlanoAcondicionamento;
        (x as any).idPropostaServicoTemp = x.idPropostaServico?.toString();
        (x as any).idPropostaResiduoPlanoAcondicionamentoPai = reajuste
          ? x.idPropostaResiduoPlanoAcondicionamento
          : null;
      }
      for (const x of plano.equipamentos) {
        x.action = Action.Add;
        (x as any).id = x.idPropostaResiduoPlanoEquipamento;
        (x as any).idPropostaResiduoPlanoEquipamentoPai = reajuste
          ? x.idPropostaResiduoPlanoEquipamento
          : null;
      }
      if (plano.veiculo) {
        const x = plano.veiculo;
        x.action = Action.Add;
        (x as any).id = x.idPropostaResiduoPlanoVeiculo;
      }
      if (plano.tratamento) {
        const x = plano.tratamento;
        x.action = Action.Add;
        (x as any).id = x.idPropostaResiduoPlanoTratamento;
      }
      if (plano.destinoFinal) {
        const x = plano.destinoFinal;
        x.action = Action.Add;
        (x as any).id = x.idPropostaResiduoPlanoDestinoFinal;
      }

      for (const cotacao of plano.cotacoes) {
        cotacao.action = Action.Add;

        for (const x of cotacao.acondicionamentos) {
          x.action = Action.Add;
          (x as any).refItem = {
            id: x.idPropostaResiduoPlanoAcondicionamento,
          };

          for (const y of x.tolerancias) {
            y.action = Action.Add;
          }
        }
        for (const x of cotacao.equipamentos) {
          x.action = Action.Add;
          (x as any).refItem = {
            id: x.idPropostaResiduoPlanoEquipamento,
          };

          for (const y of x.tolerancias) {
            y.action = Action.Add;
          }
        }
        if (cotacao.veiculo) {
          const x = cotacao.veiculo;
          x.action = Action.Add;
          (x as any).refItem = {
            id: x.idPropostaResiduoPlanoVeiculo,
          };

          for (const y of cotacao.veiculo.tolerancias) {
            y.action = Action.Add;
          }
        }
        if (cotacao.tratamento) {
          const x = cotacao.tratamento;
          x.action = Action.Add;
          (x as any).refItem = {
            id: x.idPropostaResiduoPlanoTratamento,
          };

          for (const y of cotacao.tratamento.tolerancias) {
            y.action = Action.Add;
          }
        }
        if (cotacao.destinoFinal) {
          const x = cotacao.destinoFinal;
          x.action = Action.Add;
          (x as any).refItem = {
            id: x.idPropostaResiduoPlanoDestinoFinal,
          };

          for (const y of cotacao.destinoFinal.tolerancias) {
            y.action = Action.Add;
          }
        }
      }
    }
  }

  for (const servico of proposta.servicos) {
    servico.idPropostaServicoPai = reajuste ? servico.idPropostaServico : null;
    servico.id = servico.idPropostaServico?.toString();
    servico.action = Action.Add;
    servico.recorrencia.action = Action.Add;

    for (const cotacao of servico.cotacoes) {
      cotacao.action = Action.Add;

      for (const tolerancia of cotacao.tolerancias) {
        tolerancia.action = Action.Add;
      }
    }
  }

  for (const comissao of proposta.comissoes) {
    comissao.idPropostaComissaoPai = reajuste ? comissao.idPropostaComissao : null;
    comissao.action = Action.Add;
  }

  proposta.codigoPropostaTipo = reajuste ? ProposalTypeEnum.Reajuste : ProposalTypeEnum.Completa;
  return proposta;
};

// eslint-disable-next-line import/prefer-default-export
export { duplicar };
