/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { Line, LineConfig } from '@ant-design/plots';
import {
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  BoxMultiple,
  Building,
  Check,
  Table as ITable,
  PlugConnectedX,
  Reload,
  SortDescendingNumbers,
  TableOff,
  X,
} from 'tabler-icons-react';
import { TipoCodigo } from '../../../../../../business/events/general';
import Gauge from '../../../../../../components/charts/Gauge/Gauge';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../../../models/core/cache.type';
import {
  CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
  ReportItem,
} from '../../../../../../models/core/report.type';
import reportsService from '../../../../../../services/core/report.service';
import theme from '../../../../../../theme';
import cacheUtils from '../../../../../../utils/cache.utils';
import { Chart, RecurrenceData, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../utils/helper.utils';
import { getMonths } from '../../../../../../utils/moment-recur.utils';

type FormViewProps = {
  color: string;
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  agrupador: string;
  indicador: string;
  clientes: string[];
};

type Response = {
  filterConfig: FilterData;
  data: CompanyEntityPackagingEquipmentVehicleQuantityResponseType[];
  filteredData: CompanyEntityPackagingEquipmentVehicleQuantityResponseType[];
  chartConfig: LineConfig;
  grandTotal: {
    acondicionamentoPotencial: number;
    acondicionamento: number;
    equipamentoPotencial: number;
    equipamento: number;
    veiculoPotencial: number;
    veiculo: number;
  };
  chartDataSummary: {
    agrupador: string;
    ref1: string;
    ref2: string;
    quantidade: number;
  }[];
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
  guid: string;
};

const componentConfig = {
  title: 'Quantidade de Acondicionamentos, Equipamentos e Veículos',
  description:
    'Análise quantitativa de acondicionamentos, equipamentos e veículos mobilizados baseado em eventos com medição definida.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.EmpresaEntidadeAconEquiVeiQuantitativo}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    agrupadorData: [
      // Geral
      {
        value: 'nenhum',
        label: `Nenhum`,
        group: 'Geral',
        reference: 'calcNenhum',
      },
      {
        value: 'idEmpresa',
        label: `Empresa`,
        group: 'Geral',
        reference: 'empresa',
      },
      {
        value: 'idContrato',
        label: `Contrato`,
        group: 'Geral',
        reference: 'calcContrato',
      },
      {
        value: 'codigoEventoTipo',
        label: `Tipo de Evento`,
        group: 'Geral',
        reference: 'eventoTipo',
      },
      {
        value: 'calcIdGrupo',
        label: `Grupo`,
        group: 'Geral',
        reference: 'calcGrupo',
      },
      {
        value: 'calcIdItem',
        label: `Item`,
        group: 'Geral',
        reference: 'calcItem',
      },
      // Entidade
      {
        value: 'idCliente',
        label: `Cliente`,
        group: 'Entidade',
        reference: 'calcCliente',
      },
      {
        value: 'idFornecedor',
        label: `Fornecedor`,
        group: 'Entidade',
        reference: 'calcFornecedor',
      },
      {
        value: 'calcIdFornecedorItem',
        label: `Fornecedor + Item`,
        group: 'Entidade',
        reference: 'calcFornecedorItem',
      },
      // Resíduo
      {
        value: 'idResiduo',
        label: `Resíduo`,
        group: 'Resíduo',
        reference: 'residuo',
      },
      {
        value: 'idPropostaResiduo',
        label: `Resíduo (Cliente)`,
        group: 'Resíduo',
        reference: 'residuoCliente',
      },
      {
        value: 'calcIdResiduoGrupo',
        label: `Resíduo (Cliente) + Grupo`,
        group: 'Resíduo',
        reference: 'calcResiduoGrupo',
      },
      {
        value: 'calcIdResiduoItem',
        label: `Resíduo (Cliente) + Item`,
        group: 'Resíduo',
        reference: 'calcResiduoItem',
      },
      // Serviço
      {
        value: 'idServico',
        label: `Serviço`,
        group: 'Serviço',
        reference: 'servico',
      },
      {
        value: 'calcIdServicoGrupo',
        label: `Serviço + Grupo`,
        group: 'Serviço',
        reference: 'calcServicoGrupo',
      },
      {
        value: 'calcIdServicoTipo',
        label: `Serviço + Tipo`,
        group: 'Serviço',
        reference: 'calcServicoTipo',
      },
      {
        value: 'calcIdServicoFornecedor',
        label: `Serviço + Fornecedor`,
        group: 'Serviço',
        reference: 'calcServicoFornecedor',
      },
      {
        value: 'calcIdServicoTipoFornecedor',
        label: `Serviço + Tipo + Fornecedor`,
        group: 'Serviço',
        reference: 'calcServicoTipoFornecedor',
      },
      // Item
      {
        value: 'idResiduoAcondicionamento',
        label: `Acondicionamento`,
        group: 'Item',
        reference: 'residuoAcondicionamento',
      },
      {
        value: 'idResiduoEquipamento',
        label: `Equipamento`,
        group: 'Item',
        reference: 'residuoEquipamento',
      },
      {
        value: 'calcIdResiduoVeiculo',
        label: `Veiculo`,
        group: 'Item',
        reference: 'calcResiduoVeiculo',
      },
    ],
    agrupador: 'nenhum',
    indicadorData: [
      {
        value: 'quantidade',
        label: `Quantidade`,
      },
    ],
    indicador: 'quantidade',
    clientes: [],
  },
  dataConfig: {
    exportable: true,
    separator: '╗',
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Empresa',
        key: 'empresa',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => a.empresa.localeCompare(b.empresa),
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => (
          <Badge variant="outline">{row.empresa}</Badge>
        ),
      },
      {
        title: 'Contrato #',
        key: 'idContrato',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => {
          const aValue = a.idContrato;
          const bValue = b.idContrato;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => (
          <ProfileCardLink
            id={row.idContrato.toString()}
            name="Contrato"
            nameSize="sm"
            description={`# ${row.idContrato}`}
            descriptionSize="xs"
            linkPrefix="contracts"
            showLink
          />
        ),
      },
      {
        title: 'Proposta #',
        key: 'idProposta',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => {
          const aValue = a.idProposta;
          const bValue = b.idProposta;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => (
          <ProfileCardLink
            id={row.idProposta.toString()}
            name="Proposta"
            nameSize="sm"
            description={`# ${row.idProposta}`}
            descriptionSize="xs"
            linkPrefix="proposals"
            showLink
          />
        ),
      },
      {
        title: 'Cliente',
        key: 'idCliente',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) =>
          (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
            b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
          ),
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => {
          if (row.clienteCNPJ) {
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                nameSize="sm"
                description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNome || '-'}
              nameSize="sm"
              description={row.clienteCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        },
      },
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo} | ${row.eventoStatus}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) =>
          formatDateToString(row.data),
      },
      {
        title: 'Quantidade',
        key: 'quantidade',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => {
          const aValue = a.quantidade;
          const bValue = b.quantidade;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => (
          <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
        ),
      },
      {
        title: 'Acondicionamento',
        key: 'residuoAcondicionamento',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => (a.residuoAcondicionamento || '-').localeCompare(b.residuoAcondicionamento || '-'),
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => {
          if (!row.idResiduoAcondicionamento) {
            return '-';
          }
          return (
            <ProfileCardLink
              id={row.idResiduoAcondicionamento?.toString() || ''}
              name={row.residuoAcondicionamento || ''}
              nameSize="sm"
              linkPrefix="packaging"
              showLink
            />
          );
        },
      },
      {
        title: 'Equipamento',
        key: 'residuoEquipamento',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => (a.residuoEquipamento || '-').localeCompare(b.residuoEquipamento || '-'),
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => {
          if (!row.idResiduoEquipamento) {
            return '-';
          }
          return (
            <ProfileCardLink
              id={row.idResiduoEquipamento?.toString() || ''}
              name={row.residuoEquipamento || ''}
              nameSize="sm"
              linkPrefix="equipment"
              showLink
            />
          );
        },
      },
      {
        title: 'Veículo',
        key: 'residuoVeiculo',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => (a.residuoVeiculo || '-').localeCompare(b.residuoVeiculo || '-'),
        render: (row: CompanyEntityPackagingEquipmentVehicleQuantityResponseType) => {
          if (!row.idResiduoVeiculo) {
            return '-';
          }
          return (
            <ProfileCardLink
              id={row.idResiduoVeiculo?.toString() || ''}
              name={row.residuoVeiculo || ''}
              nameSize="sm"
              description={row.veiculoCompartilhado ? 'Compartilhado' : ''}
              descriptionSize="xs"
              linkPrefix="vehicles"
              showLink
            />
          );
        },
      },
      {
        title: 'Faturado?',
        key: 'faturado',
        dataIndex: 'faturado',
        sorter: (
          a: CompanyEntityPackagingEquipmentVehicleQuantityResponseType,
          b: CompanyEntityPackagingEquipmentVehicleQuantityResponseType
        ) => (a.faturado === b.faturado ? 0 : a.faturado ? -1 : 1),
        render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
      },
    ],
  },
  chartConfig: {
    data: [],
    xField: 'mesAno',
    yField: 'indicador',
    seriesField: 'referencia',
    yAxis: {
      label: {
        formatter: (value) => {
          return `${Number(value)} un`;
        },
      },
      nice: true,
    },
    legend: {
      position: 'right',
    },
    tooltip: {
      formatter: (el) => {
        return {
          name: el.referencia,
          value: `${el.indicador} un`,
        };
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  } as LineConfig,
};

const PackagingEquipmentVehicleQuantity = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
    guid: newGuid(),
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
  }>({
    companyData: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idEmpresa:
        form.values.idEmpresa !== null && form.values.idEmpresa !== undefined
          ? Number(form.values.idEmpresa)
          : null,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.empresaEntidadeAconEquiVeiQuantitativo(searchCriteria);
    return data;
  };

  const filterMe = (data: CompanyEntityPackagingEquipmentVehicleQuantityResponseType[]) => {
    let filteredData = data.filter(
      (x) => Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa))
    );

    if (form.values.clientes.length > 0) {
      filteredData = data.filter((x) =>
        form.values.clientes.map((y) => Number(y)).includes(Number(x.idCliente))
      );
    }
    return filteredData;
  };

  const buildChartData = async (data: CompanyEntityPackagingEquipmentVehicleQuantityResponseType[]) => {
    referenceData = {
      companyData: await cacheUtils.listCompanies(),
    };

    const dataInicial = form.values.periodo[0];
    const dataFinal = form.values.periodo[1];
    const meses = getMonths(dataInicial, dataFinal);

    const calcData = data.map((x) => {
      const mesAno = getMonths(x.data, x.data)[0];
      return {
        ...x,
        quantidade: x.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar ? x.quantidade * -1 : x.quantidade,
        mesAno,
        calcNenhum:
          componentConfig.filterConfig.indicadorData.find((x) => x.value === form.values.indicador)?.label ||
          '-',
        calcContrato: `Contrato #${x.idContrato}`,
        calcCliente: `${x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome}`,
        calcFornecedor: `${x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}`,

        calcIdGrupo: `${
          x.idResiduoAcondicionamento
            ? 'A'
            : x.idResiduoEquipamento
            ? 'E'
            : x.idResiduoVeiculo
            ? `${!x.veiculoCompartilhado ? 'V' : 'VC'} `
            : '?'
        }`,
        calcGrupo: `${
          x.idResiduoAcondicionamento
            ? 'Acondicionamento'
            : x.idResiduoEquipamento
            ? 'Equipamento'
            : x.idResiduoVeiculo
            ? `${!x.veiculoCompartilhado ? 'Veículo' : 'Veículo Compartilhado'} `
            : '?'
        }`,

        calcIdItem: `${x.idResiduoAcondicionamento}-${x.idResiduoEquipamento}-${
          x.idResiduoVeiculo ? `${x.idResiduoVeiculo}${x.veiculoCompartilhado}` : ''
        }`,
        calcItem: `${x.residuoAcondicionamento || x.residuoEquipamento || x.residuoVeiculo || '?'}${
          x.idResiduoVeiculo && x.veiculoCompartilhado
            ? `${componentConfig.dataConfig.separator}Compartilhado`
            : ''
        }`,

        calcIdFornecedorItem: `${x.idFornecedor}-${x.idResiduoAcondicionamento}-${x.idResiduoEquipamento}-${
          x.idResiduoVeiculo ? `${x.idResiduoVeiculo}${x.veiculoCompartilhado}` : ''
        }`,
        calcFornecedorItem: `${x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}${
          componentConfig.dataConfig.separator
        }${x.residuoAcondicionamento || x.residuoEquipamento || x.residuoVeiculo || 'Geral'}${
          x.idResiduoVeiculo && x.veiculoCompartilhado
            ? `${componentConfig.dataConfig.separator}Compartilhado`
            : ''
        }`,

        calcIdResiduoGrupo: `${x.idPropostaResiduo}-${x.idResiduoAcondicionamento}-${
          x.idResiduoEquipamento
        }-${x.idResiduoVeiculo ? `${x.idResiduoVeiculo}${x.veiculoCompartilhado}` : ''}`,
        calcResiduoGrupo: x.idPropostaResiduo
          ? `${x.residuoCliente}${componentConfig.dataConfig.separator}${
              x.idResiduoAcondicionamento
                ? 'Acondicionamento'
                : x.idResiduoEquipamento
                ? 'Equipamento'
                : 'Veículo' // || 'Geral'
            }${
              x.idResiduoVeiculo && x.veiculoCompartilhado
                ? `${componentConfig.dataConfig.separator}Compartilhado`
                : ''
            }`
          : null,

        calcIdResiduoItem: x.idPropostaResiduo
          ? `${x.idPropostaResiduo}-${x.idResiduoAcondicionamento}-${x.idResiduoEquipamento}-${
              x.idResiduoVeiculo ? `${x.idResiduoVeiculo}${x.veiculoCompartilhado}` : ''
            }`
          : null,
        calcResiduoItem: x.idPropostaResiduo
          ? `${x.residuoCliente}${componentConfig.dataConfig.separator}${
              x.residuoAcondicionamento || x.residuoEquipamento || x.residuoVeiculo || 'Geral'
            }${
              x.idResiduoVeiculo && x.veiculoCompartilhado
                ? `${componentConfig.dataConfig.separator}Compartilhado`
                : ''
            }`
          : null,

        calcIdServicoGrupo: x.idServico ? `${x.idServico}-${x.idResiduoAcondicionamento ? 'A' : 'E'}` : null,
        calcServicoGrupo: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.idResiduoAcondicionamento ? 'Acondicionamento' : 'Equipamento'
            }`
          : null,

        calcIdServicoTipo: x.idServico
          ? `${x.idServico}-${
              x.idResiduoAcondicionamento
                ? `A${x.idResiduoAcondicionamento}`
                : x.idResiduoEquipamento
                ? `E${x.idResiduoEquipamento}`
                : '?'
            }`
          : null,
        calcServicoTipo: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.residuoAcondicionamento || x.residuoEquipamento || 'Fornecedor'
            }`
          : null,

        calcIdServicoFornecedor: x.idServico ? `${x.idServico}-${x.idFornecedor}` : null,
        calcServicoFornecedor: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome
            }`
          : null,

        calcIdServicoTipoFornecedor: x.idServico
          ? `${x.idServico}-${x.idResiduoAcondicionamento || x.idResiduoEquipamento || '?'}-${x.idFornecedor}`
          : null,
        calcServicoTipoFornecedor: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.residuoAcondicionamento || x.residuoEquipamento || 'Fornecedor'
            }${componentConfig.dataConfig.separator}${
              x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome
            }`
          : null,

        calcIdResiduoVeiculo: x.idResiduoVeiculo ? `${x.idResiduoVeiculo}-${x.veiculoCompartilhado}` : null,
        calcResiduoVeiculo: x.idResiduoVeiculo
          ? `${x.residuoVeiculo}${componentConfig.dataConfig.separator}${
              x.veiculoCompartilhado ? 'Compartilhado' : '-'
            }`
          : null,
      };
    });

    const agrupador = componentConfig.filterConfig.agrupadorData.find(
      (x) => x.value === form.values.agrupador
    )!;

    const chartData = [];
    const calcAgrupador = agrupador.value === 'nenhum' ? undefined : agrupador.value;
    const chartDataGroup = lodash.groupBy(calcData, calcAgrupador);

    for (const key of Object.keys(chartDataGroup).filter((x) => x !== 'null')) {
      try {
        const items = chartDataGroup[key];
        const item = items[0];
        const referencia = (item as any)[agrupador.reference].replaceAll(
          componentConfig.dataConfig.separator,
          ' - '
        );

        for (const mesAno of meses) {
          const quantidade = items
            .filter((x) => x.mesAno.key === mesAno.key)
            .reduce((a, b: any) => a + b.quantidade, 0);

          const finalItem = {
            ordem: mesAno.order,
            mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
            referencia,
            quantidade,
            indicador: 0,
          };
          finalItem.indicador = (finalItem as any)[form.values.indicador];

          chartData.push(finalItem);
        }
      } catch (error) {}
    }

    const grandTotal = {
      acondicionamentoPotencial: data
        .filter((x) => !!x.idResiduoAcondicionamento)
        .reduce((a, b: any) => a + b.quantidade, 0),
      equipamentoPotencial: data
        .filter((x) => !!x.idResiduoEquipamento)
        .reduce((a, b: any) => a + b.quantidade, 0),
      veiculoPotencial: data
        .filter((x) => !!x.idResiduoVeiculo && !x.veiculoCompartilhado)
        .reduce((a, b: any) => a + b.quantidade, 0),
      acondicionamento: data
        .filter((x) => !!x.idResiduoAcondicionamento && !!x.faturado)
        .reduce((a, b: any) => a + b.quantidade, 0),
      equipamento: data
        .filter((x) => !!x.idResiduoEquipamento && !!x.faturado)
        .reduce((a, b: any) => a + b.quantidade, 0),
      veiculo: data
        .filter((x) => !!x.idResiduoVeiculo && !x.veiculoCompartilhado && !!x.faturado)
        .reduce((a, b: any) => a + b.quantidade, 0),
    };

    const chartDataSummary = [];
    const chartDataSummaryGroup = lodash.groupBy(calcData, calcAgrupador);
    for (const key of Object.keys(chartDataSummaryGroup).filter((x) => x !== 'null')) {
      try {
        const items = chartDataSummaryGroup[key];
        const item = items[0];
        const agrupadorValue = (item as any)[agrupador.reference].split(componentConfig.dataConfig.separator);

        chartDataSummary.push({
          agrupador: agrupadorValue[0],
          ref1: agrupadorValue?.[1] || '-',
          ref2: agrupadorValue?.[2] || '-',
          quantidade: items.reduce((a, b: any) => a + b.quantidade, 0),
          quantidadeUnidadeMedida: 'Unidade(s)',
        });
      } catch (error) {}
    }

    return {
      chartData: chartData.sort((a, b) => a.ordem - b.ordem),
      grandTotal,
      chartDataSummary: chartDataSummary.sort(
        (a: any, b: any) => b[form.values.indicador] - a[form.values.indicador]
      ),
    };
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);
    const chartData = await buildChartData(filteredData);

    return {
      filterConfig: form.values,
      data,
      filteredData,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: chartData.chartData,
      },
      grandTotal: chartData.grandTotal,
      chartDataSummary: chartData.chartDataSummary,
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null, guid: newGuid() };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message, guid: newGuid() };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult, guid: newGuid() });
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const clientsChanged = (filterConfig?.clientes || []).join('-') !== form.values.clientes.join('-');
    const agrupadorChanged = form.values.agrupador !== filterConfig?.agrupador;
    const indicadorChanged = form.values.indicador !== filterConfig?.indicador;

    if (idEmpresaChanged || clientsChanged || agrupadorChanged || indicadorChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={props.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={props.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={props.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={3} />

        <Paper shadow="xs" p="md" withBorder>
          <Group position="apart">
            <Group spacing="md">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Select
                icon={<Building size={15} />}
                label="Empresa"
                description="Filtro: igual"
                placeholder="Todos..."
                data={referenceData.companyData.map((x) => {
                  return {
                    value: x.idEmpresa.toString(),
                    label: x.empresa,
                  };
                })}
                searchable
                clearable={currentUser.executivo}
                disabled={!currentUser.executivo}
                {...form.getInputProps('idEmpresa')}
              />
            </Group>
            <Group spacing="md">
              <Select
                style={{ width: 300 }}
                icon={<BoxMultiple size={15} />}
                label="Agrupador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                searchable
                data={componentConfig.filterConfig.agrupadorData}
                {...form.getInputProps('agrupador')}
              />
              <Select
                icon={<SortDescendingNumbers size={15} />}
                style={{ width: 150 }}
                label="Indicador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                data={componentConfig.filterConfig.indicadorData}
                {...form.getInputProps('indicador')}
              />
            </Group>
          </Group>
          {result.loaded && (result.response?.data || []).length > 0 && (
            <div>
              <Space h="md" />
              <MultiSelect
                label="Clientes"
                description="Filtro: igual"
                placeholder="Todos..."
                data={Array.from(
                  new Set(
                    result.response?.data.map(
                      (x) =>
                        `${x.idCliente}${componentConfig.dataConfig.separator}${
                          x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome
                        }`
                    )
                  )
                ).map((x) => {
                  return {
                    value: x.split(componentConfig.dataConfig.separator)[0],
                    label: x.split(componentConfig.dataConfig.separator)[1],
                  };
                })}
                clearable
                {...form.getInputProps('clientes')}
              />
            </div>
          )}
        </Paper>
        <Space h="md" />

        {result.error ? (
          <Stack spacing="md" align="center">
            <PlugConnectedX color="red" size={32} />
            <Text>{result.error}</Text>
          </Stack>
        ) : result.loaded ? (
          (result.response?.filteredData || []).length > 0 ? (
            <div>
              <Paper shadow="xs" p="md" withBorder>
                <Grid columns={3}>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.acondicionamentoPotencial || 0}
                      maxString={`${result.response?.grandTotal.acondicionamentoPotencial || 0} un`}
                      actual={result.response?.grandTotal.acondicionamento || 0}
                      actualString={`${result.response?.grandTotal.acondicionamento || 0} un`}
                      title="Acondicionamento(s)"
                      color={{
                        text: Chart.Color.Acondicionamento,
                        min: Chart.Color['Acondicionamento (Potencial)'],
                        max: Chart.Color.Acondicionamento,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.equipamentoPotencial || 0}
                      maxString={`${result.response?.grandTotal.equipamentoPotencial || 0} un`}
                      actual={result.response?.grandTotal.equipamento || 0}
                      actualString={`${result.response?.grandTotal.equipamento || 0} un`}
                      title="Equipamento(s)"
                      color={{
                        text: Chart.Color.Equipamento,
                        min: Chart.Color['Equipamento (Potencial)'],
                        max: Chart.Color.Equipamento,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.veiculoPotencial || 0}
                      maxString={`${result.response?.grandTotal.veiculoPotencial || 0} un`}
                      actual={result.response?.grandTotal.veiculo || 0}
                      actualString={`${result.response?.grandTotal.veiculo || 0} un`}
                      title="Veículo(s)"
                      color={{
                        text: Chart.Color.Veículo,
                        min: Chart.Color['Veículo (Potencial)'],
                        max: Chart.Color.Veículo,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Line {...result.response?.chartConfig!} height={props.height} />
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Table
                  key={result.guid}
                  showSorterTooltip={false}
                  size="small"
                  dataSource={result.response?.chartDataSummary || []}
                  columns={[
                    {
                      title: 'Agrupador',
                      key: 'agrupador',
                      sorter: (a: any, b: any) => a.agrupador.localeCompare(b.agrupador),
                      render: (row: any) => row.agrupador,
                    },
                    {
                      title: 'Referência 1',
                      key: 'ref1',
                      sorter: (a: any, b: any) => a.ref1.localeCompare(b.ref1),
                      render: (row: any) => row.ref1,
                    },
                    {
                      title: 'Referência 2',
                      key: 'ref2',
                      sorter: (a: any, b: any) => a.ref2.localeCompare(b.ref2),
                      render: (row: any) => row.ref2,
                    },
                    ...(componentConfig.dataConfig.columns.filter((x) =>
                      ['quantidade'].includes(x.key)
                    ) as any),
                  ]}
                  rowKey={(item) =>
                    `${item.agrupador}-${item.ref1 || '0'}-${item.ref2 || '0'}-${item.quantidade}`
                  }
                  pagination={{
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                  }}
                />
              </Paper>
            </div>
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            </Paper>
          )
        ) : (
          <Paper shadow="xs" p="md" withBorder>
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Paper>
  );
});

export default PackagingEquipmentVehicleQuantity;
