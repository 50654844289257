import { call } from '../core-reference.service';
import {
  PackagingUpdateType,
  PackagingDeleteType,
  PackagingInsertType,
  PackagingSearchType,
  PackagingSelectType,
  PackagingType,
} from '../../models/core/packaging.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const packagingsEndpoint = '/v1/residuo-acondicionamentos';

export default class PackagingsPackaging {
  static search = async (searchProps: PackagingSearchType): Promise<PackagingType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: packagingsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<PackagingType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: PackagingSelectType): Promise<PackagingType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${packagingsEndpoint}/${selectProps.idResiduoAcondicionamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: PackagingUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${packagingsEndpoint}/${updateProps.idResiduoAcondicionamento}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: PackagingDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${packagingsEndpoint}/${deleteProps.idResiduoAcondicionamento}`,
    };
    return call(request);
  };

  static insert = async (insertProps: PackagingInsertType): Promise<PackagingType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: packagingsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
