import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import PageContent from '../../../../components/core/PageContent/PageContent';
import destinationsDestination from '../../../../services/core/destinations.service';
import { FormView, FormViewData } from './components/FormView';
import { DestinationType } from '../../../../models/core/destinations.type';
import { Feature } from '../../../../utils/constants.utils';
import { Permission } from '../../../../models/core/departments.type';

type DataResult = {
  loading: boolean;
  destinationData: DestinationType | null;
};

function DestinationAddEdit() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { idResiduoDestinoFinal } = useParams();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: (!!idResiduoDestinoFinal && !location?.state?.destination) || false,
    destinationData: location?.state?.destination || null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { destinationData } = dataResult;
      try {
        destinationData = await destinationsDestination.select({
          idResiduoDestinoFinal: Number(idResiduoDestinoFinal),
        });
      } catch (error: any) {
        showNotification({
          title: `Destino Final - ${!idResiduoDestinoFinal ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException
            ? error.description
            : 'Não foi possível carregar o destino final.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, destinationData });
      }
    };

    if (idResiduoDestinoFinal && dataResult.destinationData === null) {
      fetchData();
    }
  }, [idResiduoDestinoFinal, navigate, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      let newModel;
      if (!idResiduoDestinoFinal) {
        newModel = await destinationsDestination.insert({
          ...inputFormViewData,
        });
      } else {
        await destinationsDestination.update({
          ...inputFormViewData,
          idResiduoDestinoFinal: Number(idResiduoDestinoFinal),
        });
      }
      showNotification({
        title: `Destino Final - ${!idResiduoDestinoFinal ? 'Adicionar' : 'Editar'}`,
        message: 'Destino final salvo com sucesso.',
        color: 'green',
      });
      navigate(`/destinations/${newModel?.idResiduoDestinoFinal || idResiduoDestinoFinal}`, {
        state: {
          destination: newModel || null,
        },
      });
    } catch (error: any) {
      showNotification({
        title: `Destino Final - ${!idResiduoDestinoFinal ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar o destino final.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Destination}
        title={`Destino Final - ${!idResiduoDestinoFinal ? 'Adicionar' : 'Editar'}`}
        description={`${!idResiduoDestinoFinal ? 'Adicione' : 'Edite'} um destino final.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="destination-add-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            data-permission={!idResiduoDestinoFinal ? Permission.DestinationAdd : Permission.DestinationEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {idResiduoDestinoFinal && dataResult.destinationData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView destination={dataResult.destinationData} loading={saving} save={save} />
        </PageContent>
      )}
    </Card>
  );
}

export default DestinationAddEdit;
