import { createContext, ReactNode, useContext, useState } from 'react';
import {
  ProposalResiduePlanDestinationType,
  ProposalResiduePlanEquipmentType,
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanTreatmentType,
  ProposalResiduePlanVehicleType,
  ProposalServiceType,
} from '../../../models/core/proposals.type';

type ProposalResiduePlanData = {
  acondicionamentos: ProposalResiduePlanPackagingType[];
  equipamentos: ProposalResiduePlanEquipmentType[];
  veiculo: ProposalResiduePlanVehicleType | null;
  tratamento: ProposalResiduePlanTreatmentType | null;
  destinoFinal: ProposalResiduePlanDestinationType | null;

  servicos: ProposalServiceType[];
};

interface ProposalResiduePlanContextProps {
  proposalResiduePlanData: ProposalResiduePlanData;
  setProposalResiduePlanData(proposalResiduePlanContextData: ProposalResiduePlanData): void;
}
const ProposalResiduePlanContext = createContext<ProposalResiduePlanContextProps>(
  {} as ProposalResiduePlanContextProps
);

// eslint-disable-next-line react/function-component-definition
export const ProposalResiduePlanProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [proposalResiduePlanData, setProposalResiduePlanData] = useState<ProposalResiduePlanData>(
    {} as ProposalResiduePlanData
  );
  return (
    <ProposalResiduePlanContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        proposalResiduePlanData,
        setProposalResiduePlanData,
      }}
    >
      {children}
    </ProposalResiduePlanContext.Provider>
  );
};

export type { ProposalResiduePlanData };

export function useProposalResiduePlanContext() {
  return useContext(ProposalResiduePlanContext);
}
