/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Space, Text, Timeline } from '@mantine/core';
import { useMemo } from 'react';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { EventType } from '../../../../../models/core/events.type';
import { Feature } from '../../../../../utils/constants.utils';
import { formatTimestampToString } from '../../../../../utils/formatter.utils';

type DataViewProps = {
  data: EventType;
};

export default function StatusView(props: DataViewProps) {
  const buildStatuses = useMemo(() => {
    const list = props.data.status || [];
    if (list.length === 0) {
      return <div />;
    }
    return (
      <Timeline active={0} bulletSize={30} lineWidth={2} radius="sm" color={Feature.Home.Event.color}>
        {list.reverse().map((x) => (
          <Timeline.Item
            key={x.idEventoStatus.toString()}
            bullet={<Text size="sm">{x.codigoEventoStatus}</Text>}
            title={x.eventoStatus}
            color="primary"
          >
            {x.codigoEventoStatusMotivo && <Badge variant="outline">{x.eventoStatusMotivo}</Badge>}
            {x.observacao && (
              <Text color="dimmed" size="sm">
                {x.observacao || '-'}
              </Text>
            )}
            <Text size="xs" mt={4}>
              <ProfileCardLink
                id={x.idCriadoPor}
                name={x.criadoPor}
                nameSize="sm"
                description={`Modificado em ${formatTimestampToString(x.dataCriacao)}`}
                descriptionSize="xs"
              />
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }, [props.data.status]);

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Status"
          text="Histórico de todas as mudanças de status do evento."
        />
      </Group>
      <Space h="xs" />
      {buildStatuses}
    </div>
  );
}
