/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Grid, Group, Input, NumberInput, Paper, SegmentedControl } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useImperativeHandle } from 'react';
import { CurrencyReal, Percentage } from 'tabler-icons-react';
import { calcularPreco } from '../../../../business/proposals/estimate';
import PageViewProperty from '../../../../components/core/PageViewProperty/PageViewProperty';
import { Financial } from '../../../../utils/constants.utils';
import { formatCurrency } from '../../../../utils/formatter.utils';

type FormViewData = {
  tipo: string;
  margem: number;
  precoFinal: number;
  imposto: number;
};

type FormViewProps = {
  preco: number;
  margem: number | null | undefined;
  precoFinal: number | null | undefined;
  imposto: number | null | undefined;
  compra: boolean;
  receita: boolean;
  disabled: boolean;
};

const PriceMarginTaxFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const form = useForm<FormViewData>({
    initialValues: {
      tipo: props.precoFinal === null || props.precoFinal === undefined ? '%' : '$',
      margem: props.margem === null || props.margem === undefined ? Financial.Margin : props.margem!,
      precoFinal: props.precoFinal || 0,
      imposto: props.imposto === null || props.imposto === undefined ? Financial.Tax : props.imposto!,
    },
    validate: {
      margem: (value, values: FormViewData) => {
        if (values.tipo === '%') {
          if (value === null || value === undefined || value?.toString().trim() === '') {
            return 'Campo obrigatório';
          }
          if (value < 0) {
            return 'Valor deve ser maior que zero';
          }
        }

        return null;
      },
      precoFinal: (value, values: FormViewData) => {
        if (values.tipo === '$') {
          if (value === null || value === undefined || value?.toString().trim() === '') {
            return 'Campo obrigatório';
          }
          if (value < 0) {
            return 'Valor deve ser maior que zero';
          }
        }

        return null;
      },
    },
  });

  useImperativeHandle(ref, () => ({
    validate() {
      if (form.validate().hasErrors) {
        return null;
      }

      return {
        margem: form.values.tipo === '%' ? form.values.margem : null,
        precoFinal: form.values.tipo === '$' ? form.values.precoFinal : null,
        imposto: form.values.imposto,
      };
    },
    clear() {
      form.reset();
    },
  }));

  const precoCalculado = calcularPreco(
    Number(props.preco),
    Number(props.preco),
    form.values.tipo === '%' ? form.values.margem : null,
    form.values.tipo === '$' ? form.values.precoFinal : null,
    form.values.imposto,
    props.compra,
    props.receita,
    false
  );

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Grid columns={5}>
        <Grid.Col span={2}>
          <div>
            <Input.Wrapper label="Precificação" required>
              <div />
            </Input.Wrapper>
            <Group spacing="xs">
              <SegmentedControl
                color="primary"
                data={[
                  { label: 'Margem', value: '%' },
                  { label: 'Preço', value: '$' },
                ]}
                disabled={props.disabled}
                {...form.getInputProps('tipo')}
              />
              {form.values.tipo === '%' ? (
                <NumberInput
                  style={{ width: 150 }}
                  icon={<Percentage size={15} />}
                  placeholder="Margem"
                  precision={2}
                  step={0.1}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  required
                  disabled={props.disabled || (!props.compra && props.receita)}
                  {...form.getInputProps('margem')}
                />
              ) : (
                <NumberInput
                  icon={<CurrencyReal size={15} />}
                  style={{ width: 150 }}
                  placeholder="Preço"
                  precision={2}
                  step={0.1}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  required
                  disabled={props.disabled || (!props.compra && props.receita)}
                  {...form.getInputProps('precoFinal')}
                />
              )}
            </Group>
          </div>
        </Grid.Col>
        <Grid.Col span={1}>
          <NumberInput
            icon={<Percentage size={15} />}
            label="Imposto"
            placeholder="Imposto"
            precision={2}
            step={0.1}
            stepHoldDelay={500}
            stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
            required
            disabled={props.disabled}
            {...form.getInputProps('imposto')}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Preço (Cliente)"
            text={
              <div
                style={{
                  paddingTop: 5,
                  fontSize: 'medium',
                }}
              >
                {formatCurrency(props.receita ? precoCalculado.preco : precoCalculado.novoPrecoComMargem)}
              </div>
            }
            size="sm"
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <PageViewProperty
            label="Resultado"
            text={
              <div
                style={{
                  paddingTop: 5,
                  fontSize: 'medium',
                  color:
                    precoCalculado.novaMargemFinal > 0
                      ? 'green'
                      : precoCalculado.novaMargemFinal === 0
                      ? 'orange'
                      : 'red',
                }}
              >
                {formatCurrency(precoCalculado.novaMargemFinal)}
                {precoCalculado.novaMargemFinalPorcentagem !== Infinity &&
                precoCalculado.novaMargemFinalPorcentagem !== -Infinity
                  ? ` (${(precoCalculado.novaMargemFinalPorcentagem || 0).toFixed(2)}%)`
                  : ''}
              </div>
            }
            size="sm"
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
});

export default PriceMarginTaxFormAddEdit;
