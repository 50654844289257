/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Button, Grid, Group, Input, MultiSelect, Paper, SimpleGrid, Space } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Calendar, ClearAll, Search } from 'tabler-icons-react';
import { Tipo, TipoCodigo } from '../../../business/events/general';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { EventStatusType } from '../../../models/core/cache.type';
import { UserType } from '../../../models/core/users.type';

type FilterData = {
  listaTipo: string[];
  listaResponsavel: string[];
  dataInicial: Date | undefined;
  dataFinal: Date | undefined;
};

type FilterProps = {
  eventStatusData: EventStatusType[];
  users: UserType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
  dataInicial: Date | undefined;
  dataFinal: Date | undefined;
};

function EventMeasurementSearchFilter(props: FilterProps) {
  const [currentUser] = useCurrentUser();
  const pageFilter = '';

  const form = useForm<FilterData>({
    initialValues: {
      listaTipo: [],
      listaResponsavel: [],
      dataInicial: props.dataInicial,
      dataFinal: props.dataFinal,
    },
    validate: {
      dataInicial: (value, values: FilterData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
        } else if (values.dataFinal) {
          return 'Campo obrigatório se data final estiver preenchida';
        }

        return null;
      },
      dataFinal: (value, values: FilterData) => {
        if (value) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
          if (values.dataInicial && value < values.dataInicial) {
            return 'Data final não pode ser anterior à data inicial';
          }
        } else if (values.dataInicial) {
          return 'Campo obrigatório se data inicial estiver preenchida';
        }
        return null;
      },
    },
  });

  const handleSubmit = async (values: FilterData) => {
    props.filter(values);
  };

  return (
    <form noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={3}>
          <Grid.Col span={1}>
            <MultiSelect
              label="Responsável"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.users.map((x) => {
                return {
                  label: x.idUsuario === currentUser.idUsuario ? `${x.nomeCompleto} (eu)` : x.nomeCompleto,
                  value: x.idUsuario,
                  group: x.empresa,
                };
              })}
              clearable
              searchable
              {...form.getInputProps('listaResponsavel')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <div>
              <Input.Wrapper
                label="Data Inicial / Final"
                description="Filtro: entre"
                style={{ marginBottom: 5 }}
              >
                <div />
              </Input.Wrapper>
              <Group>
                <DatePicker
                  placeholder="Todas..."
                  icon={<Calendar size={15} />}
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  clearable
                  allowFreeInput
                  {...form.getInputProps('dataInicial')}
                />
                <DatePicker
                  placeholder="Todas..."
                  icon={<Calendar size={15} />}
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  clearable
                  allowFreeInput
                  minDate={form.values.dataInicial}
                  {...form.getInputProps('dataFinal')}
                />
              </Group>
            </div>
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <Grid columns={3}>
          <Grid.Col span={3}>
            <MultiSelect
              label="Tipo"
              description="Filtro: igual"
              placeholder="Todos..."
              data={[
                { label: Tipo.Cobranca, value: TipoCodigo.Cobranca },
                { label: Tipo.Coleta, value: TipoCodigo.Coleta },
                { label: Tipo.Comissao, value: TipoCodigo.Comissao },
                { label: Tipo.MobilizacaoEnviar, value: TipoCodigo.MobilizacaoEnviar },
                { label: Tipo.MobilizacaoRetirar, value: TipoCodigo.MobilizacaoRetirar },
              ]}
              clearable
              searchable
              {...form.getInputProps('listaTipo')}
              disabled={!!pageFilter}
            />
          </Grid.Col>
        </Grid>
        <Space h="md" />

        <SimpleGrid cols={1}>
          <Group position="apart">
            <Group>
              <Button
                color="secondary"
                leftIcon={<ClearAll size={18} />}
                disabled={props.loading}
                onClick={() => {
                  form.reset();
                  (form.values.dataInicial as any) = null;
                  (form.values.dataFinal as any) = null;
                  props.clear();
                }}
              >
                Limpar
              </Button>
              <Button
                leftIcon={<Search size={18} />}
                loading={props.loading}
                onClick={() => {
                  handleSubmit(form.values);
                }}
              >
                Pesquisar
              </Button>
            </Group>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { EventMeasurementSearchFilter };
export type { FilterData };
