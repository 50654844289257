/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Group, Paper, SimpleGrid, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { Scale, ZoomMoney } from 'tabler-icons-react';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { EventTreatmentQuotationType } from '../../../../../../../models/core/events.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../utils/helper.utils';
import ProposalItemToleranceView from '../../../../../proposals/ProposalView/components/components/components/ProposalItemToleranceView';
import PriceMarginTaxFormView from '../../../../components/PriceMarginTaxFormView';

type DataViewProps = {
  data: EventTreatmentQuotationType;
  compra: boolean;
};

export default function EventTreatmentItem(props: DataViewProps) {
  const buildTabs = () => {
    const tabs = [
      {
        key: 'Cotacao',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Event.color} variant="outline">
              <ZoomMoney size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Event.color} weight={500}>
              Cotação
            </Text>
          </Group>
        ),
        children: (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={4}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Fornecedor"
                    text={
                      props.data.fornecedorCNPJ ? (
                        <ProfileCardLink
                          id={props.data.idFornecedor.toString()}
                          name={props.data.fornecedorNomeFantasia || props.data.fornecedorRazaoSocial || '-'}
                          nameSize="sm"
                          description={
                            props.data.fornecedorNomeFantasia
                              ? props.data.fornecedorRazaoSocial
                              : props.data.fornecedorCNPJ
                          }
                          descriptionSize="xs"
                          linkPrefix="entities"
                          showLink
                        />
                      ) : (
                        <ProfileCardLink
                          id={props.data.idFornecedor.toString()}
                          name={props.data.fornecedorNome || '-'}
                          nameSize="sm"
                          description={props.data.fornecedorCPF}
                          descriptionSize="xs"
                          linkPrefix="entities"
                          showLink
                        />
                      )
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Local"
                    text={
                      <ProfileCardLink
                        id={newGuid()}
                        name={`${props.data.logradouro}, ${props.data.numero}${
                          props.data.complemento ? ` | ${props.data.complemento}` : ''
                        }`}
                        nameSize="sm"
                        description={`${props.data.bairro} - ${props.data.cidade} / ${props.data.codigoEstado} (${props.data.cep})`}
                        descriptionSize="xs"
                        showLink={false}
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <Grid columns={4}>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={`${props.data.quantidade} ${props.data.quantidadeUnidadeMedida}`}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Mínimo Aceitável"
                    text={
                      !props.data.minimoAceitavel
                        ? '-'
                        : `${props.data.minimoAceitavel} ${props.data.minimoAceitavelUnidadeMedida}`
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty label="Preço" text={formatCurrency(props.data.preco)} size="sm" />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <Grid columns={4}>
                <Grid.Col span={4}>
                  <PriceMarginTaxFormView
                    preco={props.data.preco}
                    margem={props.data.margem}
                    precoFinal={props.data.precoFinal}
                    imposto={props.data.imposto}
                    compra={props.compra}
                    receita={false}
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={props.data.idCriadoPor}
              criadoPor={props.data.criadoPor}
              dataCriacao={props.data.dataCriacao}
              idModificadoPor={props.data?.idModificadoPor || null}
              modificadoPor={props.data?.modificadoPor || null}
              dataModificacao={props.data?.dataModificacao || null}
              size="sm"
              gridSize={2}
            />
          </div>
        ),
        forceRender: true,
      },
      {
        key: 'Tolerancias',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Event.color} variant="outline">
              <Scale size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Event.color} weight={500}>
              Tolerâncias
            </Text>
          </Group>
        ),
        children: (
          <Paper shadow="xs" p="md" withBorder>
            <ProposalItemToleranceView data={props.data.tolerancias} />
          </Paper>
        ),
        forceRender: true,
      },
    ];
    return tabs;
  };

  return (
    <div>
      {' '}
      <Paper shadow="xs" p="md" withBorder>
        <Tabs items={buildTabs()} defaultActiveKey="Cotacao" />
      </Paper>
    </div>
  );
}
