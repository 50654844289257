/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import { Accordion, ActionIcon, Group, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { Home, Plus } from 'tabler-icons-react';
import { DepartmentPermissionType } from '../../../../models/core/departments.type';
import { FeatureType } from '../../../../models/utils/constants.type';
import theme from '../../../../theme';
import { Feature } from '../../../../utils/constants.utils';
import { validate } from '../../../../utils/permission.utils';

type DefaultLinksProps = {
  visible: boolean;
  userPermissions: DepartmentPermissionType[];
};

function DefaultLinks(props: DefaultLinksProps) {
  const navigate = useNavigate();

  const data: FeatureType[] = [
    Feature.Home.Dashboard,
    Feature.Home.Proposal,
    Feature.Home.Contract,
    Feature.Home.Event,
  ].filter((x) => validate(x.permissions?.Search, props.userPermissions));

  const buildSingleItem = (item: FeatureType) => {
    return (
      <Link key={item.label} to={item.link}>
        <Group
          position="apart"
          sx={(innerTheme) => ({
            padding: innerTheme.spacing.xs,
            '&:hover': {
              backgroundColor: innerTheme.colors.gray[0],
            },
          })}
        >
          <UnstyledButton>
            <Group>
              <ThemeIcon color={item.color} variant="outline">
                <item.icon size={16} />
              </ThemeIcon>
              <Text size="sm">{item.label}</Text>
            </Group>
          </UnstyledButton>
          {item.hasAddLink && validate(item.permissions?.Add, props.userPermissions) && (
            <ActionIcon
              size="sm"
              radius="sm"
              color={item.color}
              variant="outline"
              onClick={(event: any) => {
                event.preventDefault();
                const goTo = `${item.link}/add`;
                if (window.location.href.includes(item.link) && window.location.href.endsWith('edit')) {
                  window.location.href = goTo;
                } else {
                  navigate(goTo);
                }
              }}
            >
              <Plus size={15} />
            </ActionIcon>
          )}
        </Group>
      </Link>
    );
  };

  const buildGroupedItem = (item: FeatureType) => {
    return (
      <Accordion
        key={item.label}
        chevronPosition="right"
        defaultValue="default"
        className="custom-mantine-accordion"
      >
        <Accordion.Item value="default" style={{ marginLeft: -6 }}>
          <Accordion.Control>
            <Group>
              <ThemeIcon color={item.color} variant="outline">
                <item.icon size={16} />
              </ThemeIcon>
              <Text size="sm" weight={500} color={item.color}>
                {item.label}
              </Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            {item.subItems
              .filter((x) => (x.restricted && props.visible) || !x.restricted)
              .map((subItem) => buildSingleItem({ ...item, ...subItem }))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  };

  return (
    <Accordion chevronPosition="right" defaultValue="default">
      <Accordion.Item value="default">
        <Accordion.Control>
          <Group>
            <ThemeIcon color={theme?.colors?.primary?.[6]} variant="outline">
              <Home size={16} />
            </ThemeIcon>
            <Text size="sm" weight={500} color={theme?.colors?.primary?.[6]}>
              Home
            </Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          {data.map((item) => (item.subItems.length === 0 ? buildSingleItem(item) : buildGroupedItem(item)))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export default DefaultLinks;
