/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { SimpleGrid, Space, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ProposalResidueType } from '../../../../../../../../models/core/proposals.type';
import { Feature, SessionStorageKey } from '../../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../../models/core/core.type';

type FormViewData = {
  action: Action;
  idPropostaResiduoDocumento: number | null;
  notaFiscal: boolean;
  xml: boolean;
  mtr: boolean;
  sga: boolean;
  romaneioColeta: boolean;
  pesoOrigem: boolean;
  pesoDestino: boolean;
};

type ProposalFormViewProps = {
  proposalResidue: ProposalResidueType | null;
};

const ResidueDocumentFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.proposalResidue?.documento?.idPropostaResiduoDocumento ? Action.Nothing : Action.Add,
      idPropostaResiduoDocumento: props.proposalResidue?.documento.idPropostaResiduoDocumento || null,
      notaFiscal: props.proposalResidue?.documento.notaFiscal || false,
      xml: props.proposalResidue?.documento.xml || false,
      mtr: props.proposalResidue?.documento.mtr || false,
      sga: props.proposalResidue?.documento.sga || false,
      romaneioColeta: props.proposalResidue?.documento.romaneioColeta || false,
      pesoOrigem: props.proposalResidue?.documento.pesoOrigem || false,
      pesoDestino: props.proposalResidue?.documento.pesoDestino || false,
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.proposalResidue?.documento?.notaFiscal || ''}
    |${props.proposalResidue?.documento?.xml || ''}
    |${props.proposalResidue?.documento?.mtr || ''}
    |${props.proposalResidue?.documento?.sga || ''}
    |${props.proposalResidue?.documento?.romaneioColeta || ''}
    |${props.proposalResidue?.documento?.pesoOrigem || ''}
    |${props.proposalResidue?.documento?.pesoDestino || ''}`;

    const formData = `${form.values.notaFiscal || ''}
    |${form.values.xml || ''}
    |${form.values.mtr || ''}
    |${form.values.sga || ''}
    |${form.values.romaneioColeta || ''}
    |${form.values.pesoOrigem || ''}
    |${form.values.pesoDestino || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      if (form.validate().hasErrors) {
        return null;
      }
      const formItem = JSON.parse(JSON.stringify(form.values)) as FormViewData;

      if (props.proposalResidue?.documento?.idPropostaResiduoDocumento && isModified()) {
        formItem.action = Action.Modify;
      }

      return formItem;
    },
    clear() {
      form.reset();
    },
  }));

  useEffect(() => {
    const tempProposal = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempProposal) || 'null');
    if (tempProposal && props.proposalResidue === null) {
      form.setValues({
        ...tempProposal.documento,
      });
    }
  }, []);

  return (
    <div>
      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Documentos"
        text="Documentos que estarão disponíveis e acordados entre as partes."
      />
      <Space h="xs" />

      <SimpleGrid cols={7}>
        <Switch label="Nota Fiscal" {...form.getInputProps('notaFiscal', { type: 'checkbox' })} />
        <Switch label="XML" {...form.getInputProps('xml', { type: 'checkbox' })} />
        <Switch label="MTR" {...form.getInputProps('mtr', { type: 'checkbox' })} />
        <Switch label="SGA" {...form.getInputProps('sga', { type: 'checkbox' })} />
        <Switch label="Romaneio de Coleta" {...form.getInputProps('romaneioColeta', { type: 'checkbox' })} />
        <Switch label="Peso de Origem" {...form.getInputProps('pesoOrigem', { type: 'checkbox' })} />
        <Switch label="Peso de Destino" {...form.getInputProps('pesoDestino', { type: 'checkbox' })} />
      </SimpleGrid>
    </div>
  );
});

export default ResidueDocumentFormView;
