import { call } from '../core-reference.service';
import {
  VehicleUpdateType,
  VehicleDeleteType,
  VehicleInsertType,
  VehicleSearchType,
  VehicleSelectType,
  VehicleType,
} from '../../models/core/vehicles.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const vehiclesEndpoint = '/v1/residuo-veiculos';

export default class VehiclesVehicle {
  static search = async (searchProps: VehicleSearchType): Promise<VehicleType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: vehiclesEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<VehicleType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: VehicleSelectType): Promise<VehicleType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${vehiclesEndpoint}/${selectProps.idResiduoVeiculo}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: VehicleUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${vehiclesEndpoint}/${updateProps.idResiduoVeiculo}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: VehicleDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${vehiclesEndpoint}/${deleteProps.idResiduoVeiculo}`,
    };
    return call(request);
  };

  static insert = async (insertProps: VehicleInsertType): Promise<VehicleType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: vehiclesEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
