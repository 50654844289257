/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Trash } from 'tabler-icons-react';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import { EventBillingMeasurementType } from '../../../../../../../models/core/events.type';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';

type DataViewActionProps = {
  editable: boolean;
  item: EventBillingMeasurementType;
  confirmActionResult(items: EventBillingMeasurementType[], action: string, confirmed: boolean): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function BillingMeasurementFormViewActions(props: DataViewActionProps) {
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} essa medição?`}
      >
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <SimpleGrid cols={5}>
              <PageViewProperty label="Evento #" text={props.item.idEvento} size="sm" />
              <PageViewProperty label="Receita" text={formatCurrency(props.item.receita)} size="sm" />
              <PageViewProperty label="Despesa" text={formatCurrency(props.item.despesa)} size="sm" />
              <PageViewProperty label="Imposto" text={formatCurrency(props.item.imposto)} size="sm" />
              <PageViewProperty label="Balanço" text={formatCurrency(props.item.despesa)} size="sm" />
            </SimpleGrid>
            <Space h="xs" />
          </Paper>
          <Space h="lg" />

          <Group position="right">
            <Button
              color="secondary"
              // leftIcon={<ArrowBackUp size={18} />}
              onClick={() => {
                setModalData({ opened: false, action: '' });
                props.confirmActionResult([props.item], modalData.action, false);
              }}
            >
              Não
            </Button>
            <Button
              // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
              // leftIcon={<Check size={18} />}
              color="primary"
              onClick={async () => {
                setModalData({ opened: false, action: '' });
                props.confirmActionResult([props.item], modalData.action, true);
              }}
            >
              Sim
            </Button>
          </Group>
        </div>
      </Modal>

      <Group>
        {props.editable ? (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                setModalData({ opened: true, action: 'excluir' });
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        ) : (
          '-'
        )}
      </Group>
    </div>
  );
}
