import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { call } from '../core.service';

const totvsEndpoint = '/v1/totvs';

export default class TOTVSService {
  static searchNFe = async (searchProps: {
    grupo: string;
    filial: string;
    nfe: string;
  }): Promise<boolean> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: totvsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };
}
