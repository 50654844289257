/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-syntax */
import {
  MovementBillingType,
  MovementBillingTypeCode,
  MovementType,
  MovementTypeGroup,
  MovementTypeStandard,
  UnitOfMeasureType,
} from '../../models/core/cache.type';
import { ItemToleranceType } from '../../models/core/core.type';
import {
  EventDestinationType,
  EventEquipmentType,
  EventMeasurementMovementType,
  EventPackagingType,
  EventServiceType,
  EventTreatmentType,
  EventVehicleType,
} from '../../models/core/events.type';
import {
  ProposalCommissionType,
  ProposalResiduePlanQuotationDestinationType,
  ProposalResiduePlanQuotationTreatmentType,
  ProposalResiduePlanQuotationVehicleType,
} from '../../models/core/proposals.type';
import { buildFakeAuditObject, convertUnitOfMeasure, getHash } from '../../utils/helper.utils';
import { calcularPreco } from '../proposals/estimate';
import { ReferenciaCodigo } from './general';

export type MeasurementConversionErrorType = {
  movement: MovementType;
  group: string | null;
  source: UnitOfMeasureType;
  quantity: number;
  target: UnitOfMeasureType;
};

const getMovementGroup = (event: EventMeasurementMovementType) => {
  if (event.idEventoAcondicionamento) {
    return { id: event.idEventoAcondicionamento, group: MovementTypeGroup.Acondicionamento };
  }
  if (event.idEventoEquipamento) {
    return { id: event.idEventoEquipamento, group: MovementTypeGroup.Equipamento };
  }
  if (event.idEventoVeiculo) {
    return { id: event.idEventoVeiculo, group: MovementTypeGroup.Veiculo };
  }
  if (event.idEventoTratamento) {
    return { id: event.idEventoTratamento, group: MovementTypeGroup.Tratamento };
  }
  if (event.idEventoDestinoFinal) {
    return { id: event.idEventoDestinoFinal, group: MovementTypeGroup.DestinoFinal };
  }
  if (event.idEventoServico) {
    return { id: event.idEventoServico, group: MovementTypeGroup.Servico };
  }

  return { id: null, group: null };
};

const getMovementType = (
  codigoMovimentacaoPadrao: MovementTypeStandard,
  codigoMovimentacaoGrupo: MovementTypeGroup | null,
  movementTypeData: MovementType[]
) => {
  const data = movementTypeData;
  return data.find(
    (x) =>
      x.codigoMovimentacaoPadrao === codigoMovimentacaoPadrao &&
      x.codigoMovimentacaoGrupo === codigoMovimentacaoGrupo
  );
};

const getMovementBillingType = (codigoEventoReferencia: ReferenciaCodigo, servico?: EventServiceType) => {
  let codigoMovimentacaoFaturamentoTipo;

  if (codigoEventoReferencia === ReferenciaCodigo.ContratoComissao) {
    codigoMovimentacaoFaturamentoTipo = MovementBillingType.Commissao;
  } else if (
    codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
    codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento
  ) {
    codigoMovimentacaoFaturamentoTipo = MovementBillingType.Locacao;
  } else if (codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) {
    codigoMovimentacaoFaturamentoTipo = MovementBillingType.GerenciamentoResiduo;
  } else if (codigoEventoReferencia === ReferenciaCodigo.ContratoServico) {
    if (servico?.idResiduoAcondicionamento || servico?.idResiduoEquipamento) {
      codigoMovimentacaoFaturamentoTipo = MovementBillingType.Locacao;
    } else {
      codigoMovimentacaoFaturamentoTipo = MovementBillingType.PrestacaoServico;
    }
  }

  return {
    codigoMovimentacaoFaturamentoTipo,
    movimentacaoFaturamentoTipo:
      MovementBillingTypeCode[codigoMovimentacaoFaturamentoTipo as MovementBillingType],
  };
};

const calculateBasicMovement = (
  codigoMovimentacaoPadrao: MovementTypeStandard,
  codigoMovimentacaoGrupo: MovementTypeGroup | null,

  quantidade: number,
  quantidadeUM: UnitOfMeasureType,

  receita: number,
  despesa: number,
  imposto: number,

  refId: {
    idEvento: number;
    idFornecedor: number;
    idEventoAcondicionamento?: number | null;
    idEventoEquipamento?: number | null;
    idEventoVeiculo?: number | null;
    idEventoTratamento?: number | null;
    idEventoDestinoFinal?: number | null;
    idEventoServico?: number | null;
    idEventoTolerancia?: number | null;
  },
  codigoEventoReferencia: ReferenciaCodigo,
  movementTypeData: MovementType[],
  servico?: EventServiceType
) => {
  return {
    ...getMovementType(codigoMovimentacaoPadrao, codigoMovimentacaoGrupo, movementTypeData),
    ...getMovementBillingType(codigoEventoReferencia, servico),
    ...buildFakeAuditObject(),
    ...refId,

    outroTipo: null,

    quantidade,
    quantidadeIdUnidadeMedida: quantidadeUM.idUnidadeMedida,
    quantidadeUnidadeMedida: quantidadeUM.unidadeMedida,
    quantidadeUnidadeMedidaSigla: quantidadeUM.unidadeMedidaSigla,
    receita,
    despesa,
    imposto,
    balanco: receita - despesa - imposto,
    observacao: null,
    tolerancia: null,
  };
};

const calculateQuotationMovement = (
  codigoMovimentacaoPadrao: MovementTypeStandard,
  codigoMovimentacaoGrupo: MovementTypeGroup | null,
  cotacao:
    | ProposalResiduePlanQuotationVehicleType
    | ProposalResiduePlanQuotationTreatmentType
    | ProposalResiduePlanQuotationDestinationType,
  compra: boolean,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  refId: {
    idEvento: number;
    idFornecedor: number;
    idEventoAcondicionamento?: number | null;
    idEventoEquipamento?: number | null;
    idEventoVeiculo?: number | null;
    idEventoTratamento?: number | null;
    idEventoDestinoFinal?: number | null;
    idEventoServico?: number | null;
  },
  codigoEventoReferencia: ReferenciaCodigo,
  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[]
) => {
  let movimentacao;
  const erros: MeasurementConversionErrorType[] = [];

  const cotacaoQuantidadeUM = unitOfMeasures.find(
    (x) => x.idUnidadeMedida === cotacao.quantidadeIdUnidadeMedida
  )!;

  const precoCalculado = calcularPreco(
    cotacao.preco,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto || 0,
    compra,
    (cotacao as any)?.receita || false,
    false
  );

  const conversao = convertUnitOfMeasure(cotacaoQuantidadeUM, quantidadeUM, cotacao.quantidade);
  if (conversao.valid) {
    const receita = precoCalculado.novoPrecoComMargem * (quantidade / conversao.calc!);
    const despesa = precoCalculado.preco * (quantidade / conversao.calc!);
    const imposto = precoCalculado.imposto * (quantidade / conversao.calc!);

    // servico
    movimentacao = calculateBasicMovement(
      codigoMovimentacaoPadrao,
      codigoMovimentacaoGrupo,
      quantidade,
      quantidadeUM,
      receita,
      despesa,
      imposto,
      refId,
      codigoEventoReferencia,
      movementTypeData
    );
  } else {
    erros.push({
      movement: getMovementType(codigoMovimentacaoPadrao, codigoMovimentacaoGrupo, movementTypeData)!,
      group: getMovementGroup(JSON.parse(JSON.stringify(refId))).group,
      source: cotacaoQuantidadeUM,
      quantity: cotacao.quantidade,
      target: quantidadeUM,
    });
  }

  return { movimentacao, erros };
};

const calculateToleranceMovements = (
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  tempo: number | null,
  tempoColetaUM: UnitOfMeasureType | null,

  cotacaoPreco: number,
  cotacaoMargem: number | null | undefined,
  cotacaoPrecoFinal: number | null | undefined,
  cotacaoImposto: number | null | undefined,

  compra: boolean,
  receita: boolean,
  cobrarTolerancia: boolean,
  refId: {
    idEvento: number;
    idFornecedor: number;
    idEventoAcondicionamento?: number | null;
    idEventoEquipamento?: number | null;
    idEventoVeiculo?: number | null;
    idEventoTratamento?: number | null;
    idEventoDestinoFinal?: number | null;
    idEventoServico?: number | null;
  },
  codigoEventoReferencia: ReferenciaCodigo,

  tolerancias: ItemToleranceType[],
  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[]
) => {
  const movimentacoes: EventMeasurementMovementType[] = [];
  const erros: MeasurementConversionErrorType[] = [];

  for (const tolerancia of tolerancias) {
    const toleranciaQuantidadeUM = unitOfMeasures.find(
      (x) => x.idUnidadeMedida === tolerancia.idUnidadeMedida
    )!;

    let conversao = convertUnitOfMeasure(quantidadeUM, toleranciaQuantidadeUM, quantidade);
    if (refId.idEventoVeiculo && toleranciaQuantidadeUM.tipo === 'Tempo') {
      conversao = convertUnitOfMeasure(tempoColetaUM!, toleranciaQuantidadeUM, tempo!);
    }

    if (conversao.valid) {
      const excendente = conversao.calc! - tolerancia.quantidade;
      if (excendente > 0) {
        const precoExcendente = excendente * tolerancia.precoUnitario;

        let margem = cotacaoMargem;
        if (cotacaoMargem === null || cotacaoMargem === undefined) {
          margem = (cotacaoPrecoFinal! * 100) / cotacaoPreco - 100;
        }

        const precoCalculado = calcularPreco(
          precoExcendente,
          precoExcendente,
          margem,
          null,
          cotacaoImposto || 0,
          compra,
          receita
        );

        movimentacoes.push({
          ...calculateBasicMovement(
            MovementTypeStandard.Tolerancia,
            null,
            excendente,
            toleranciaQuantidadeUM,
            cobrarTolerancia ? precoCalculado.novoPrecoComMargem : 0,
            precoCalculado.preco,
            cobrarTolerancia ? precoCalculado.imposto : 0,
            { ...refId, idEventoTolerancia: tolerancia.idEventoTolerancia },
            codigoEventoReferencia,
            movementTypeData
          ),
          tolerancia,
        });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (refId.idEventoVeiculo && toleranciaQuantidadeUM.tipo === 'Tempo') {
        erros.push({
          movement: getMovementType(MovementTypeStandard.Tolerancia, null, movementTypeData)!,
          group: getMovementGroup(JSON.parse(JSON.stringify(refId))).group,
          source: tempoColetaUM!,
          quantity: tempo!,
          target: toleranciaQuantidadeUM,
        });
      } else {
        erros.push({
          movement: getMovementType(MovementTypeStandard.Tolerancia, null, movementTypeData)!,
          group: getMovementGroup(JSON.parse(JSON.stringify(refId))).group,
          source: quantidadeUM,
          quantity: quantidade,
          target: toleranciaQuantidadeUM,
        });
      }
    }
  }

  return { movimentacoes, erros };
};

const calculateMinThresholdMovements = (
  codigoMovimentacaoGrupo: MovementTypeGroup,

  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  minimoAceitavel: number | null,
  minimoAceitavelUM: UnitOfMeasureType | null,

  novoPrecoComMargem: number,
  imposto: number,

  cotacaoQuantidade: number,
  cotacaoQuantidadeIdUnidadeMedida: number,

  refId: {
    idEvento: number;
    idFornecedor: number;
    idEventoAcondicionamento?: number | null;
    idEventoEquipamento?: number | null;
    idEventoVeiculo?: number | null;
    idEventoTratamento?: number | null;
    idEventoDestinoFinal?: number | null;
    idEventoServico?: number | null;
  },
  codigoEventoReferencia: ReferenciaCodigo,

  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[],

  cotacaoPreco = null,
  calcularReceita = false,
  calcularDespesa = false
) => {
  let movimentacao;
  const erros: MeasurementConversionErrorType[] = [];

  if (minimoAceitavel && minimoAceitavelUM) {
    let conversao = convertUnitOfMeasure(quantidadeUM, minimoAceitavelUM, quantidade);

    if (conversao.valid) {
      const excendente = minimoAceitavel - conversao.calc!;
      if (excendente > 0) {
        const cotacaoQuantidadeUM = unitOfMeasures.find(
          (x) => x.idUnidadeMedida === cotacaoQuantidadeIdUnidadeMedida
        )!;

        conversao = convertUnitOfMeasure(cotacaoQuantidadeUM, minimoAceitavelUM, cotacaoQuantidade);

        if (conversao.valid) {
          const precoExcendente = calcularReceita ? novoPrecoComMargem * (excendente / conversao.calc!) : 0;
          const despesaExcendente = calcularDespesa ? cotacaoPreco! * (excendente / conversao.calc!) : 0;
          const impostoExcendente = imposto * 0.01 * precoExcendente;

          movimentacao = calculateBasicMovement(
            MovementTypeStandard.MinimoAceitavel,
            codigoMovimentacaoGrupo,
            excendente,
            minimoAceitavelUM,
            precoExcendente,
            despesaExcendente,
            impostoExcendente,
            refId,
            codigoEventoReferencia,
            movementTypeData
          );
        } else {
          erros.push({
            movement: getMovementType(
              MovementTypeStandard.MinimoAceitavel,
              codigoMovimentacaoGrupo,
              movementTypeData
            )!,
            group: getMovementGroup(JSON.parse(JSON.stringify(refId))).group,
            source: cotacaoQuantidadeUM,
            quantity: cotacaoQuantidade,
            target: minimoAceitavelUM,
          });
        }
      }
    } else {
      erros.push({
        movement: getMovementType(
          MovementTypeStandard.MinimoAceitavel,
          codigoMovimentacaoGrupo,
          movementTypeData
        )!,
        group: getMovementGroup(JSON.parse(JSON.stringify(refId))).group,
        source: quantidadeUM,
        quantity: quantidade,
        target: minimoAceitavelUM,
      });
    }
  }

  return { movimentacao, erros };
};

const calculatePackagingMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idFornecedor: number,
  acondicionamento: EventPackagingType,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  compra: boolean,
  movementTypeData: MovementType[]
) => {
  const movimentacoes: EventMeasurementMovementType[] = [];
  const erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
    idEventoAcondicionamento: acondicionamento.idEventoAcondicionamento,
  };

  const { cotacao } = acondicionamento;

  const precoCalculado = calcularPreco(
    cotacao.preco,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto || 0,
    compra,
    false
  );

  // locacao
  movimentacoes.push(
    calculateBasicMovement(
      MovementTypeStandard.Locacao,
      MovementTypeGroup.Acondicionamento,
      quantidade,
      quantidadeUM,
      (precoCalculado.novoPrecoComMargem / cotacao.quantidade) * acondicionamento.quantidade,
      (precoCalculado.preco / cotacao.quantidade) * acondicionamento.quantidade,
      (precoCalculado.imposto / cotacao.quantidade) * acondicionamento.quantidade,
      refId,
      codigoEventoReferencia,
      movementTypeData
    )
  );

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateEquipmentMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idFornecedor: number,
  equipamento: EventEquipmentType,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  compra: boolean,
  movementTypeData: MovementType[]
) => {
  const movimentacoes: EventMeasurementMovementType[] = [];
  const erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
    idEventoEquipamento: equipamento.idEventoEquipamento,
  };

  const { cotacao } = equipamento;

  const precoCalculado = calcularPreco(
    cotacao.preco,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto || 0,
    compra,
    false
  );

  // locacao
  movimentacoes.push(
    calculateBasicMovement(
      MovementTypeStandard.Locacao,
      MovementTypeGroup.Equipamento,
      quantidade,
      quantidadeUM,
      (precoCalculado.novoPrecoComMargem / cotacao.quantidade) * equipamento.quantidade,
      (precoCalculado.preco / cotacao.quantidade) * equipamento.quantidade,
      (precoCalculado.imposto / cotacao.quantidade) * equipamento.quantidade,
      refId,
      codigoEventoReferencia,
      movementTypeData
    )
  );

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateVehicleMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idFornecedor: number,
  veiculo: EventVehicleType,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  tempo: number | null,
  tempoColetaUM: UnitOfMeasureType | null,
  minimoAceitavel: number | null,
  minimoAceitavelUM: UnitOfMeasureType | null,
  compra: boolean,
  cobrarTolerancia: boolean,
  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[]
) => {
  let movimentacoes: EventMeasurementMovementType[] = [];
  let erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
    idEventoVeiculo: veiculo.idEventoVeiculo,
  };

  const { cotacao } = veiculo;
  const cotacaoQuantidadeUM = unitOfMeasures.find(
    (x) => x.idUnidadeMedida === cotacao.frequenciaIdUnidadeMedida
  )!;

  const precoCalculado = calcularPreco(
    cotacao.preco,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto || 0,
    compra,
    false
  );

  // frete
  if (!cotacao.minimoAceitavelIdUnidadeMedida) {
    movimentacoes.push(
      calculateBasicMovement(
        MovementTypeStandard.Frete,
        MovementTypeGroup.Veiculo,
        1,
        cotacaoQuantidadeUM,
        (precoCalculado.novoPrecoComMargem / cotacao.quantidade) * veiculo.quantidade,
        (precoCalculado.preco / cotacao.quantidade) * veiculo.quantidade,
        (precoCalculado.imposto / cotacao.quantidade) * veiculo.quantidade,
        refId,
        codigoEventoReferencia,
        movementTypeData
      )
    );
  } else {
    movimentacoes.push(
      calculateBasicMovement(
        MovementTypeStandard.Frete,
        MovementTypeGroup.Veiculo,
        1,
        cotacaoQuantidadeUM,
        (((precoCalculado.novoPrecoComMargem / cotacao.quantidade) * veiculo.quantidade) /
          cotacao.minimoAceitavel!) *
          quantidade,
        (((precoCalculado.preco / cotacao.quantidade) * veiculo.quantidade) / cotacao.minimoAceitavel!) *
          quantidade,
        (((precoCalculado.imposto / cotacao.quantidade) * veiculo.quantidade) / cotacao.minimoAceitavel!) *
          quantidade,
        refId,
        codigoEventoReferencia,
        movementTypeData
      )
    );
  }

  // tolerancias
  const calcTolerancia = calculateToleranceMovements(
    quantidade,
    quantidadeUM,
    tempo,
    tempoColetaUM,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto,
    compra,
    false,
    cobrarTolerancia,
    refId,
    codigoEventoReferencia,
    cotacao.tolerancias,
    movementTypeData,
    unitOfMeasures
  );
  movimentacoes = movimentacoes.concat(calcTolerancia.movimentacoes);
  erros = erros.concat(calcTolerancia.erros);

  // minimo aceitavel
  const minimoAceitavelEquivalente =
    minimoAceitavel === cotacao.minimoAceitavel &&
    minimoAceitavelUM?.idUnidadeMedida === cotacao.minimoAceitavelIdUnidadeMedida;

  const calcMinimoAceitavel = calculateMinThresholdMovements(
    MovementTypeGroup.Veiculo,
    quantidade,
    quantidadeUM,
    cotacao.minimoAceitavel,
    unitOfMeasures.find((x) => x.idUnidadeMedida === cotacao.minimoAceitavelIdUnidadeMedida)!,
    precoCalculado.novoPrecoComMargem,
    cotacao.imposto || 0,
    cotacao.minimoAceitavel!,
    cotacao.minimoAceitavelIdUnidadeMedida!,
    refId,
    codigoEventoReferencia,
    movementTypeData,
    unitOfMeasures,
    cotacao.preco as any,
    minimoAceitavelEquivalente,
    true
  );
  movimentacoes.push(calcMinimoAceitavel.movimentacao);
  erros = erros.concat(calcMinimoAceitavel.erros);

  if (!minimoAceitavelEquivalente && cotacao.minimoAceitavelIdUnidadeMedida) {
    const calcMinimoAceitavelCliente = calculateMinThresholdMovements(
      MovementTypeGroup.Veiculo,
      quantidade,
      quantidadeUM,
      minimoAceitavel,
      minimoAceitavelUM,
      precoCalculado.novoPrecoComMargem,
      cotacao.imposto || 0,
      cotacao.minimoAceitavel!,
      cotacao.minimoAceitavelIdUnidadeMedida!,
      refId,
      codigoEventoReferencia,
      movementTypeData,
      unitOfMeasures,
      cotacao.preco as any,
      true,
      false
    );
    movimentacoes.push(calcMinimoAceitavelCliente.movimentacao);
    erros = erros.concat(calcMinimoAceitavelCliente.erros);
  }

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateTreatmentMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idFornecedor: number,
  tratamento: EventTreatmentType,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  minimoAceitavel: number | null,
  minimoAceitavelUM: UnitOfMeasureType | null,
  compra: boolean,
  cobrarTolerancia: boolean,
  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[]
) => {
  let movimentacoes: EventMeasurementMovementType[] = [];
  let erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
    idEventoTratamento: tratamento.idEventoTratamento,
  };

  const { cotacao } = tratamento;

  // servico
  const calcCotacao = calculateQuotationMovement(
    MovementTypeStandard.Servico,
    MovementTypeGroup.Tratamento,
    cotacao,
    compra,
    quantidade,
    quantidadeUM,
    refId,
    codigoEventoReferencia,
    movementTypeData,
    unitOfMeasures
  );
  movimentacoes.push(calcCotacao.movimentacao);
  erros = erros.concat(calcCotacao.erros);

  // tolerancias
  const calcTolerancia = calculateToleranceMovements(
    quantidade,
    quantidadeUM,
    null,
    null,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto,
    compra,
    false,
    cobrarTolerancia,
    refId,
    codigoEventoReferencia,
    cotacao.tolerancias,
    movementTypeData,
    unitOfMeasures
  );
  movimentacoes = movimentacoes.concat(calcTolerancia.movimentacoes);
  erros = erros.concat(calcTolerancia.erros);

  // minimo aceitavel
  const minimoAceitavelEquivalente =
    minimoAceitavel === cotacao.minimoAceitavel &&
    minimoAceitavelUM?.idUnidadeMedida === cotacao.minimoAceitavelIdUnidadeMedida;

  const precoCalculado = calcularPreco(
    cotacao.preco,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto || 0,
    compra,
    false
  );

  const calcMinimoAceitavel = calculateMinThresholdMovements(
    MovementTypeGroup.Tratamento,
    quantidade,
    quantidadeUM,
    cotacao.minimoAceitavel,
    unitOfMeasures.find((x) => x.idUnidadeMedida === cotacao.minimoAceitavelIdUnidadeMedida)!,
    precoCalculado.novoPrecoComMargem,
    cotacao.imposto || 0,
    cotacao.quantidade,
    cotacao.quantidadeIdUnidadeMedida,
    refId,
    codigoEventoReferencia,
    movementTypeData,
    unitOfMeasures,
    cotacao.preco as any,
    minimoAceitavelEquivalente,
    true
  );
  movimentacoes.push(calcMinimoAceitavel.movimentacao);
  erros = erros.concat(calcMinimoAceitavel.erros);

  if (!minimoAceitavelEquivalente) {
    const calcMinimoAceitavelCliente = calculateMinThresholdMovements(
      MovementTypeGroup.Tratamento,
      quantidade,
      quantidadeUM,
      minimoAceitavel,
      minimoAceitavelUM,
      precoCalculado.novoPrecoComMargem,
      cotacao.imposto || 0,
      cotacao.quantidade,
      cotacao.quantidadeIdUnidadeMedida,
      refId,
      codigoEventoReferencia,
      movementTypeData,
      unitOfMeasures,
      cotacao.preco as any,
      true,
      false
    );
    movimentacoes.push(calcMinimoAceitavelCliente.movimentacao);
    erros = erros.concat(calcMinimoAceitavelCliente.erros);
  }

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateDestinationMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idCliente: number,
  idFornecedor: number,
  destinoFinal: EventDestinationType,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  minimoAceitavel: number | null,
  minimoAceitavelUM: UnitOfMeasureType | null,
  compra: boolean,
  cobrarTolerancia: boolean,
  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[]
) => {
  let movimentacoes: EventMeasurementMovementType[] = [];
  let erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
    idEventoDestinoFinal: destinoFinal.idEventoDestinoFinal,
  };

  const { cotacao } = destinoFinal;

  // servico
  const calcCotacao = calculateQuotationMovement(
    MovementTypeStandard.Servico,
    MovementTypeGroup.DestinoFinal,
    cotacao,
    compra,
    quantidade,
    quantidadeUM,
    refId,
    codigoEventoReferencia,
    movementTypeData,
    unitOfMeasures
  );

  if (compra) {
    let tempCompra = JSON.parse(JSON.stringify(calcCotacao.movimentacao));
    tempCompra = {
      ...tempCompra,
      ...getMovementType(MovementTypeStandard.Compra, null, movementTypeData),
      idEventoDestinoFinal: null,
      idFornecedor: idCliente,
      balanco: 0 - tempCompra.despesa,
      receita: 0,
      imposto: 0,
      cotacao,
    };

    movimentacoes.push(tempCompra);
  }

  if (cotacao.receita && calcCotacao.movimentacao) {
    calcCotacao.movimentacao = {
      ...calcCotacao.movimentacao,
      ...getMovementType(MovementTypeStandard.Venda, MovementTypeGroup.DestinoFinal, movementTypeData),
      balanco: calcCotacao.movimentacao.receita - calcCotacao.movimentacao.imposto,
      despesa: 0,
    };
  }

  movimentacoes.push(calcCotacao.movimentacao);
  erros = erros.concat(calcCotacao.erros);

  // tolerancias
  const calcTolerancia = calculateToleranceMovements(
    quantidade,
    quantidadeUM,
    null,
    null,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto,
    compra,
    false,
    cobrarTolerancia,
    refId,
    codigoEventoReferencia,
    cotacao.tolerancias,
    movementTypeData,
    unitOfMeasures
  );
  movimentacoes = movimentacoes.concat(calcTolerancia.movimentacoes);
  erros = erros.concat(calcTolerancia.erros);

  // minimo aceitavel
  if (!cotacao.receita) {
    const minimoAceitavelEquivalente =
      minimoAceitavel === cotacao.minimoAceitavel &&
      minimoAceitavelUM?.idUnidadeMedida === cotacao.minimoAceitavelIdUnidadeMedida;

    const precoCalculado = calcularPreco(
      cotacao.preco,
      cotacao.preco,
      cotacao.margem,
      cotacao.precoFinal,
      cotacao.imposto || 0,
      compra,
      cotacao.receita
    );

    const calcMinimoAceitavel = calculateMinThresholdMovements(
      MovementTypeGroup.DestinoFinal,
      quantidade,
      quantidadeUM,
      cotacao.minimoAceitavel,
      unitOfMeasures.find((x) => x.idUnidadeMedida === cotacao.minimoAceitavelIdUnidadeMedida)!,
      precoCalculado.novoPrecoComMargem,
      cotacao.imposto || 0,
      cotacao.quantidade,
      cotacao.quantidadeIdUnidadeMedida,
      refId,
      codigoEventoReferencia,
      movementTypeData,
      unitOfMeasures,
      cotacao.preco as any,
      minimoAceitavelEquivalente,
      true
    );
    movimentacoes.push(calcMinimoAceitavel.movimentacao);
    erros = erros.concat(calcMinimoAceitavel.erros);

    if (!minimoAceitavelEquivalente) {
      const calcMinimoAceitavelCliente = calculateMinThresholdMovements(
        MovementTypeGroup.DestinoFinal,
        quantidade,
        quantidadeUM,
        minimoAceitavel,
        minimoAceitavelUM,
        precoCalculado.novoPrecoComMargem,
        cotacao.imposto || 0,
        cotacao.quantidade,
        cotacao.quantidadeIdUnidadeMedida,
        refId,
        codigoEventoReferencia,
        movementTypeData,
        unitOfMeasures,
        cotacao.preco as any,
        true,
        false
      );
      movimentacoes.push(calcMinimoAceitavelCliente.movimentacao);
      erros = erros.concat(calcMinimoAceitavelCliente.erros);
    }
  }

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateServiceMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idFornecedor: number,
  servico: EventServiceType,
  quantidade: number,
  quantidadeUM: UnitOfMeasureType,
  compra: boolean,
  movementTypeData: MovementType[]
) => {
  const movimentacoes: EventMeasurementMovementType[] = [];
  const erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
    idEventoServico: servico.idEventoServico,
  };

  const { cotacao } = servico;

  const precoCalculado = calcularPreco(
    cotacao.preco,
    cotacao.preco,
    cotacao.margem,
    cotacao.precoFinal,
    cotacao.imposto || 0,
    compra,
    false
  );

  // locacao
  movimentacoes.push(
    calculateBasicMovement(
      servico.idResiduoAcondicionamento || servico.idResiduoEquipamento
        ? MovementTypeStandard.Locacao
        : MovementTypeStandard.PrestacaoServico,
      MovementTypeGroup.Servico,
      quantidade,
      quantidadeUM,
      (precoCalculado.novoPrecoComMargem / cotacao.quantidade) * servico.quantidade,
      (precoCalculado.preco / cotacao.quantidade) * servico.quantidade,
      (precoCalculado.imposto / cotacao.quantidade) * servico.quantidade,
      refId,
      codigoEventoReferencia,
      movementTypeData,
      servico
    )
  );

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateCommissionMovements = (
  idEvento: number,
  codigoEventoReferencia: ReferenciaCodigo,
  idFornecedor: number,
  comissao: ProposalCommissionType,
  movementTypeData: MovementType[],
  unitOfMeasures: UnitOfMeasureType[]
) => {
  const movimentacoes: EventMeasurementMovementType[] = [];
  const erros: MeasurementConversionErrorType[] = [];

  const refId = {
    idEvento,
    idFornecedor,
  };

  const unidadeUoM = unitOfMeasures.find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')!;

  // comissão
  movimentacoes.push({
    ...getMovementType(
      comissao.preco ? MovementTypeStandard.ComissaoFixa : MovementTypeStandard.ComissaoPercentual,
      null,
      movementTypeData
    ),
    ...getMovementBillingType(codigoEventoReferencia),
    ...buildFakeAuditObject(),
    ...refId,

    outroTipo: null,

    quantidade: 1,
    quantidadeIdUnidadeMedida: unidadeUoM.idUnidadeMedida,
    quantidadeUnidadeMedida: unidadeUoM.unidadeMedida,
    quantidadeUnidadeMedidaSigla: unidadeUoM.unidadeMedidaSigla,
    receita: 0,
    despesa: comissao.preco || 0,
    imposto: 0,
    balanco: comissao.preco ? comissao.preco * -1 : 0,
    observacao: null,
    tolerancia: null,
  });

  return {
    movimentacoes: movimentacoes.filter((x) => x !== undefined),
    erros: erros.filter((x) => x !== undefined),
  };
};

const calculateHash = (x: EventMeasurementMovementType) => {
  return getHash({
    idMovimentacaoTipo: x.idMovimentacaoTipo,
    outroTipo: x.outroTipo,
    codigoMovimentacaoFaturamentoTipo: x.codigoMovimentacaoFaturamentoTipo,
    idEventoAcondicionamento: x.idEventoAcondicionamento || null,
    idEventoEquipamento: x.idEventoEquipamento || null,
    idEventoVeiculo: x.idEventoVeiculo || null,
    idEventoTratamento: x.idEventoTratamento || null,
    idEventoDestinoFinal: x.idEventoDestinoFinal || null,
    idEventoServico: x.idEventoServico || null,
    idEventoTolerancia: x.idEventoTolerancia || null,
    quantidade: x.quantidade.toFixed(3),
    receita: x.receita.toFixed(2),
    despesa: x.despesa.toFixed(2),
    imposto: x.imposto.toFixed(2),
    balanco: x.balanco.toFixed(2),
  });
};

export {
  calculateCommissionMovements,
  calculateDestinationMovements,
  calculateEquipmentMovements,
  calculateHash,
  calculatePackagingMovements,
  calculateServiceMovements,
  calculateTreatmentMovements,
  calculateVehicleMovements,
  getMovementBillingType,
  getMovementGroup,
};
