/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Edit, Trash } from 'tabler-icons-react';
import PageViewProperty from '../../../../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import {
  ProposalResiduePlanVehicleType,
  ProposalResiduePlanQuotationVehicleType,
} from '../../../../../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../../../../../theme';
import { formatCurrency } from '../../../../../../../../../../../../../utils/formatter.utils';

type DataViewActionProps = {
  item: ProposalResiduePlanQuotationVehicleType & { refItem?: ProposalResiduePlanVehicleType };
  confirmActionResult(
    item: ProposalResiduePlanQuotationVehicleType | null,
    refItem: ProposalResiduePlanVehicleType | null,
    action: string,
    confirmed: boolean
  ): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function RPQuotationVehicleFormViewActions(props: DataViewActionProps) {
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} essa cotação de veículo?`}
      >
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={3}>
            <PageViewProperty label="Veículo" text={props.item.residuoVeiculo} size="sm" />
            <PageViewProperty
              label="Quantidade/Frequência"
              text={`${props.item.quantidade} ${props.item.quantidadeUnidadeMedida} / ${props.item.frequenciaUnidadeMedida}`}
              size="sm"
            />
            <PageViewProperty label="Preço" text={formatCurrency(props.item.preco)} size="sm" />
          </SimpleGrid>
        </Paper>
        <Space h="lg" />

        <Group position="right">
          <Button
            color="secondary"
            // leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult(props.item, props.item.refItem || null, modalData.action, false);
            }}
          >
            Não
          </Button>
          <Button
            // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
            // leftIcon={<Check size={18} />}
            color="primary"
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult(props.item, props.item.refItem || null, modalData.action, true);
            }}
          >
            Sim
          </Button>
        </Group>
      </Modal>

      <Group>
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.accent?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, props.item.refItem || null, 'editar', true);
            }}
          >
            <Edit size={15} color={theme?.colors?.accent?.[6]} />
          </ActionIcon>
        </Tooltip>
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'red' }}
            variant="outline"
            onClick={() => {
              setModalData({ opened: true, action: 'excluir' });
            }}
          >
            <Trash size={15} color="red" />
          </ActionIcon>
        </Tooltip>
      </Group>
    </div>
  );
}
