/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { Group, MantineSize, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { AuditType } from '../../../../../../models/core/core.type';
import { Feature } from '../../../../../../utils/constants.utils';

type ContactType = {
  idEntidadeContato?: number;
  idEntidade?: number;
  idEntidadeEnderecoContato?: number;
  idEntidadeEndereco?: number;
  nome: string;
  setor: string | null;
  email: string | null;
  telefone: string | null;
  telefoneRamal: string | null;
  celular: string | null;
  observacao: string | null;
} & AuditType;

type ViewProps = {
  data: ContactType[];
  size: MantineSize;
  expandable: boolean;
};

export default function ContactView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: ContactType, b: ContactType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Setor',
      key: 'setor',
      sorter: (a: ContactType, b: ContactType) => {
        return (a.setor || '').localeCompare(b.setor || '');
      },
      render: (row: ContactType) => <div>{row?.setor || '-'}</div>,
    },
    {
      title: 'E-mail',
      key: 'email',
      sorter: (a: ContactType, b: ContactType) => {
        return (a.email || '').localeCompare(b.email || '');
      },
      render: (row: ContactType) => <div>{row?.email || '-'}</div>,
    },
    {
      title: 'Telefone',
      key: 'telefone',
      sorter: (a: ContactType, b: ContactType) => {
        return (a.telefone || '').localeCompare(b.telefone || '');
      },
      render: (row: ContactType) => <div>{row?.telefone || '-'}</div>,
    },
    {
      title: 'Ramal',
      key: 'telefoneRamal',
      sorter: (a: ContactType, b: ContactType) => {
        return (a.telefoneRamal || '').localeCompare(b.telefoneRamal || '');
      },
      render: (row: ContactType) => <div>{row?.telefoneRamal || '-'}</div>,
    },
    {
      title: 'Celular',
      key: 'celular',
      sorter: (a: ContactType, b: ContactType) => {
        return (a.celular || '').localeCompare(b.celular || '');
      },
      render: (row: ContactType) => <div>{row?.celular || '-'}</div>,
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ContactType, b: ContactType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ContactType) => {
        return <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />;
      },
    },
  ];

  return (
    <div>
      <Group>
        <PageSection
          size={props.size === 'lg' ? 'lg' : 'md'}
          color={Feature.Reference.Entity.color}
          label="Contatos"
          text=""
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        size={props.size === 'lg' ? 'middle' : 'small'}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ContactType) => item.idEntidadeContato || item.idEntidadeEnderecoContato || 0}
        expandable={
          !props.expandable
            ? undefined
            : {
                // eslint-disable-next-line react/no-unstable-nested-components
                expandedRowRender: (item) => (
                  <PageViewAudit
                    idCriadoPor={item.idCriadoPor}
                    criadoPor={item.criadoPor}
                    dataCriacao={item.dataCriacao}
                    idModificadoPor={item.idModificadoPor || null}
                    modificadoPor={item.modificadoPor || null}
                    dataModificacao={item.dataModificacao || null}
                    size="sm"
                  />
                ),
              }
        }
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
