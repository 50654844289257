import { call } from '../core.service';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

export default class ReferencesService {
  static listReferences = async (): Promise<any[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: '/v1/referencias',
    };
    return (await call(request))?.data?.data;
  };
}
