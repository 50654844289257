import { call } from '../core.service';
import {
  EntityUpdateType,
  EntityDeleteType,
  EntityInsertType,
  EntitySelectType,
  EntityType,
  EntitySearchRequestType,
  EntitySearchResponseType,
  EntityAddressType,
  EntityContactType,
} from '../../models/core/entities.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const entitiesEndpoint = '/v1/entidades';

export default class EntitysService {
  static search = async (searchProps: EntitySearchRequestType): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: entitiesEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<EntitySearchResponseType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: EntitySelectType): Promise<EntityType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/${selectProps.idEntidade}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: EntityUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${entitiesEndpoint}/${updateProps.idEntidade}`,
      data: updateProps,
      timeout: 60000,
    };
    return call(request);
  };

  static delete = async (deleteProps: EntityDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${entitiesEndpoint}/${deleteProps.idEntidade}`,
    };
    return call(request);
  };

  static insert = async (insertProps: EntityInsertType): Promise<EntityType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: entitiesEndpoint,
      data: insertProps,
      timeout: 60000,
    };
    return (await call(request))?.data?.data;
  };

  static listContacts = async (selectProps: EntitySelectType): Promise<EntityContactType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/${selectProps.idEntidade}/contatos`,
    };
    return (await call(request))?.data?.data;
  };

  static listAddresses = async (selectProps: EntitySelectType): Promise<EntityAddressType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/${selectProps.idEntidade}/enderecos`,
    };
    return (await call(request))?.data?.data;
  };

  static searchByPackagingId = async (selectProps: {
    idResiduoAcondicionamento: number;
  }): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/itens/residuo-acondicionamentos/${selectProps.idResiduoAcondicionamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static searchByDestinationId = async (selectProps: {
    idResiduoDestinoFinal: number;
  }): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/itens/residuo-destinos-finais/${selectProps.idResiduoDestinoFinal}`,
    };
    return (await call(request))?.data?.data;
  };

  static searchByEquipmentId = async (selectProps: {
    idResiduoEquipamento: number;
  }): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/itens/residuo-equipamentos/${selectProps.idResiduoEquipamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static searchByTreatmentId = async (selectProps: {
    idResiduoTratamento: number;
  }): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/itens/residuo-tratamentos/${selectProps.idResiduoTratamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static searchByVehicleId = async (selectProps: {
    idResiduoVeiculo: number;
  }): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/itens/residuo-veiculos/${selectProps.idResiduoVeiculo}`,
    };
    return (await call(request))?.data?.data;
  };

  static searchByServiceId = async (selectProps: {
    idServico: number;
  }): Promise<EntitySearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitiesEndpoint}/itens/servicos/${selectProps.idServico}`,
    };
    return (await call(request))?.data?.data;
  };
}
