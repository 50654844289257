/* eslint-disable no-nested-ternary */
import { Affix, Button, Card, Center, Loader, Text, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, ArrowNarrowUp, ClearAll, DeviceFloppy, Pencil } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import {
  AddressTypeType,
  DocumentType,
  DocumentTypeGroup,
  EntityTypeType,
  PaymentMethodType,
} from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';
import { EntityInsertType, EntityType } from '../../../../models/core/entities.type';
import { BancoType, EstadoType } from '../../../../models/utils/brasilapi.type';
import entitiesService from '../../../../services/core/entities.service';
import { listarBancos, listarEstados } from '../../../../services/utils/brasilapi.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import FormView from './components/FormView';

type DataResult = {
  loading: boolean;
  referenceData: {
    entityTypeData: EntityTypeType[];
    paymentMethodTypeData: PaymentMethodType[];
    bancoTypeData: BancoType[];
    addressTypeData: AddressTypeType[];
    estadoTypeData: EstadoType[];
    documentTypeData: DocumentType[];
  } | null;
  entityData: EntityType | null;
};

function EntityAddEdit() {
  const modals = useModals();
  const navigate = useNavigate();
  const { idEntidade } = useParams();
  const [scroll, scrollTo] = useWindowScroll();
  const refForm = useRef<any>();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    referenceData: null,
    entityData: null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { referenceData, entityData } = dataResult;
      try {
        if (dataResult.referenceData === null) {
          referenceData = {
            entityTypeData: await cacheUtils.listEntityTypes(),
            paymentMethodTypeData: await cacheUtils.listPaymentMethods(),
            bancoTypeData: await listarBancos(),
            addressTypeData: await cacheUtils.listAddressTypes(),
            estadoTypeData: await listarEstados(),
            documentTypeData: (await cacheUtils.listDocumentTypes()).filter(
              (x) => x.codigoDocumentoGrupo === DocumentTypeGroup.Entity || !x.codigoDocumentoGrupo
            ),
          };
        }
        if (idEntidade && dataResult.entityData === null) {
          entityData = await entitiesService.select({ idEntidade: Number(idEntidade) });
        }
      } catch (error: any) {
        showNotification({
          title: `Entidade - ${!idEntidade ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar a entidade.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({
          loading: false,
          referenceData,
          entityData,
        });
      }
    };

    if (dataResult.referenceData === null || (idEntidade && dataResult.entityData === null)) {
      fetchData();
    }
  }, [idEntidade, navigate, dataResult]);

  const save = async () => {
    try {
      const formData: EntityInsertType | null = refForm.current.validate();
      if (formData === null) {
        return;
      }

      setSaving(true);
      let newModel;
      if (!idEntidade) {
        newModel = await entitiesService.insert({
          ...formData,
        });
      } else {
        await entitiesService.update({
          ...formData,
          idEntidade: Number(idEntidade),
        });
      }
      showNotification({
        title: `Entidade - ${!idEntidade ? 'Adicionar' : 'Editar'}`,
        message: 'Entidade salva com sucesso.',
        color: 'green',
      });
      sessionStorage.removeItem(SessionStorageKey.TempEntity);
      navigate(`/entities/${newModel?.idEntidade || idEntidade}`);
    } catch (error: any) {
      showNotification({
        title: `Entidade - ${!idEntidade ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar a entidade.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  const saveSession = () => {
    try {
      const formData: EntityInsertType | null = refForm.current.validate();
      if (formData === null) {
        return;
      }
      setSaving(true);
      sessionStorage.setItem(SessionStorageKey.TempEntity, JSON.stringify(formData));
      showNotification({
        title: `Entidade - ${!idEntidade ? 'Adicionar' : 'Editar'}`,
        message: 'Entidade temporariamente salva com sucesso.',
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: `Entidade - ${!idEntidade ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível salvar temporariamente a entidade.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Entity}
        title={`Entidade - ${!idEntidade ? 'Adicionar' : 'Editar'}`}
        description={`${!idEntidade ? 'Adicione' : 'Edite'} uma entidade.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Limpar"
            color="secondary"
            leftIcon={<ClearAll size={18} />}
            disabled={dataResult.loading || saving}
            hidden={!!idEntidade}
            onClick={() => {
              modals.openConfirmModal({
                title: `Gostaria de limpar todo o formulário?`,
                children: <Text size="sm">Todas as informações preenchidas serão descartadas.</Text>,
                onConfirm: () => {
                  refForm.current.clear();
                },
              });
            }}
          >
            Limpar
          </Button>,
          <Button
            key="Rascunho"
            color="accent"
            leftIcon={<Pencil size={18} />}
            disabled={dataResult.loading || saving}
            hidden={!!idEntidade}
            onClick={saveSession}
          >
            Rascunho
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            onClick={save}
            data-permission={!idEntidade ? Permission.EntityAdd : Permission.EntityEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {dataResult.referenceData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView ref={refForm} referenceData={dataResult.referenceData} entity={dataResult.entityData} />
        </PageContent>
      )}
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default EntityAddEdit;
