/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalResiduePlanContext } from '../../../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import { UnitOfMeasureType } from '../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanType,
  ProposalResiduePlanVehicleType,
} from '../../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../../../../../../utils/helper.utils';
import ResiduePlanVehicleFormAddEdit from './ResiduePlanVehicleForm/ResiduePlanVehicleFormAddEdit';
import ResiduePlanVehicleFormViewActions from './ResiduePlanVehicleForm/ResiduePlanVehicleFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalResiduePlan: ProposalResiduePlanType | null;
};

const ResiduePlanVehicleFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalResiduePlanData, setProposalResiduePlanData } = useProposalResiduePlanContext();

  const [data, setData] = useState<ProposalResiduePlanVehicleType[]>(
    props.proposalResiduePlan?.veiculo
      ? [
          {
            ...props.proposalResiduePlan?.veiculo,
            action: props.proposalResiduePlan?.veiculo.action || Action.Nothing,
            id:
              props.proposalResiduePlan?.veiculo.idPropostaResiduoPlanoVeiculo?.toString() ||
              props.proposalResiduePlan?.veiculo.idTemporaria ||
              newGuid(),
          },
        ]
      : []
  );

  const [vehicleItem, setVehicleItem] = useState<ProposalResiduePlanVehicleType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanVehicleType> = [
    {
      title: 'Veiculo',
      key: 'residuoVeiculo',
      sorter: (a: ProposalResiduePlanVehicleType, b: ProposalResiduePlanVehicleType) =>
        (a.residuoVeiculo || '').localeCompare(b.residuoVeiculo || ''),
      render: (row: ProposalResiduePlanVehicleType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoVeiculo?.toString() || ''}
            name={row.residuoVeiculo || ''}
            nameSize="sm"
            description={row.residuoVeiculoDescricao}
            descriptionSize="xs"
            linkPrefix="vehicles"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalResiduePlanVehicleType, b: ProposalResiduePlanVehicleType) => {
        const aValue = a.quantidadeUnidadeMedidaSigla === 'ton' ? a.quantidade : a.quantidade * 1000;
        const bValue = b.quantidadeUnidadeMedidaSigla === 'ton' ? b.quantidade : b.quantidade * 1000;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanVehicleType) => (
        <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Carga Média (Coleta)',
      key: 'cargaMedia',
      sorter: (a: ProposalResiduePlanVehicleType, b: ProposalResiduePlanVehicleType) => {
        const aValue = a.cargaMediaUnidadeMedidaSigla === 'ton' ? a.cargaMedia : a.cargaMedia * 1000;
        const bValue = b.cargaMediaUnidadeMedidaSigla === 'ton' ? b.cargaMedia : b.cargaMedia * 1000;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanVehicleType) => (
        <div>{`${row.cargaMedia} ${row.cargaMediaUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanVehicleType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ResiduePlanVehicleFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    item: ProposalResiduePlanVehicleType | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduoPlanoVeiculo) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setVehicleItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }

    setProposalResiduePlanData({ ...proposalResiduePlanData, veiculo: data?.[0] || null });
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanVehicleType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Plano/Veículo - ${vehicleItem ? 'Editar' : 'Adicionar'}`}
        size="50%"
      >
        <ResiduePlanVehicleFormAddEdit
          referenceData={props.referenceData}
          origItem={props.proposalResiduePlan?.veiculo || null}
          item={vehicleItem}
          idPropostaResiduoPlano={props.proposalResiduePlan?.idPropostaResiduoPlano}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Veículo"
          text="Veículo para este plano."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={data.filter((x) => x.action !== Action.Delete).length > 0}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ProposalResiduePlanVehicleType) => item.id || item.idPropostaResiduoPlanoVeiculo || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ResiduePlanVehicleFormView;
