/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Badge,
  Divider,
  Grid,
  Group,
  Paper,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  Briefcase,
  CalendarEvent,
  Check,
  CurrencyReal,
  Eye,
  Id,
  Ruler2,
  Scale,
  Share,
  X,
  ZoomMoney,
} from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import RecurrenceView from '../../../../../../components/core/Recurrence/RecurrenceView';
import { ProposalServiceType, ProposalType } from '../../../../../../models/core/proposals.type';
import theme from '../../../../../../theme';
import { montarRecorrenciaString } from '../../../../../../utils/business.utils';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../utils/helper.utils';
import ServiceGeneralView from './ServiceView/ServiceGeneralView';
import ServiceQuotationView from './ServiceView/ServiceQuotationView';

type ViewProps = {
  data: ProposalServiceType[];
  proposal: ProposalType;
};

export default function ServiceView(props: ViewProps) {
  const modals = useModals();

  const columns: ColumnsType<any> = [
    {
      title: 'Serviço',
      key: 'servico',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => a.servico.localeCompare(b.servico),
      render: (row: ProposalServiceType) => {
        return (
          <ProfileCardLink
            id={row.idServico.toString()}
            name={row.servico}
            nameSize="sm"
            description={row.servicoDescricao}
            descriptionSize="xs"
            linkPrefix="services"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Item',
      key: 'item',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aItem = a.residuoAcondicionamento || a.residuoEquipamento || '-';
        const bItem = b.residuoAcondicionamento || b.residuoEquipamento || '-';
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalServiceType) => {
        if (row.idResiduoAcondicionamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoAcondicionamento || '').toString()}
              name={row.residuoAcondicionamento || '-'}
              nameSize="sm"
              description={row.residuoAcondicionamentoDescricao}
              descriptionSize="xs"
              linkPrefix="packaging"
              showLink
            />
          );
        }
        if (row.idResiduoEquipamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoEquipamento || '').toString()}
              name={row.residuoEquipamento || '-'}
              nameSize="sm"
              description={row.residuoEquipamentoDescricao}
              descriptionSize="xs"
              linkPrefix="equipment"
              showLink
            />
          );
        }
        return (
          <ProfileCardLink
            id={newGuid.toString()}
            name="Fornecedor"
            nameSize="sm"
            description={null}
            descriptionSize="xs"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceType) => {
        return <div>{row.quantidade.toString()}</div>;
      },
    },
    {
      title: 'Frequência',
      key: 'frequencia',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aIsService = !(a.idResiduoAcondicionamento || a.idResiduoEquipamento);
        const bIsService = !(b.idResiduoAcondicionamento || b.idResiduoEquipamento);

        const aItem = `${aIsService ? `${a.frequencia} ` : ''}${a.frequenciaUnidadeMedida1} / ${
          a.frequenciaUnidadeMedida2
        }`;
        const bItem = `${bIsService ? `${b.frequencia} ` : ''}${b.frequenciaUnidadeMedida1} / ${
          b.frequenciaUnidadeMedida2
        }`;
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalServiceType) => {
        const isService = !(row.idResiduoAcondicionamento || row.idResiduoEquipamento);
        return (
          <div>{`${isService ? `${row.frequencia} ` : ''}${row.frequenciaUnidadeMedida1} / ${
            row.frequenciaUnidadeMedida2
          }`}</div>
        );
      },
    },
    {
      title: 'Recorrência',
      key: 'recorrencia',
      render: (row: ProposalServiceType) => {
        const recorrencia = montarRecorrenciaString(row.recorrencia);
        if (!recorrencia) {
          return '-';
        }
        return (
          <div>
            <div>{recorrencia.str1}</div>
            {recorrencia.str2 ? <div>{recorrencia.str2}</div> : <></>}
          </div>
        );
      },
    },
    {
      title: 'Data Inicial',
      key: 'dataInicial',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) =>
        new Date(a.recorrencia.dataInicial || a.dataInicial).valueOf() -
        new Date(b.recorrencia.dataInicial || b.dataInicial).valueOf(),
      render: (row: ProposalServiceType) =>
        formatDateToString(row.recorrencia.dataInicial || row.dataInicial),
    },
    {
      title: 'Data Final',
      key: 'dataFinal',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) =>
        new Date(a.recorrencia.dataFinal || a.dataFinal).valueOf() -
        new Date(b.recorrencia.dataFinal || b.dataFinal).valueOf(),
      render: (row: ProposalServiceType) => formatDateToString(row.recorrencia.dataFinal || row.dataFinal),
    },
    {
      title: 'Cotações',
      key: 'cotacoes',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aValue = a.cotacoes.length;
        const bValue = b.cotacoes.length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceType) => {
        return (
          <Group spacing="xs">
            <div>{row.cotacoes.length || '-'}</div>
            <div>{row.cotacoes.some((x) => x.primario) ? <Check color="green" /> : <X color="red" />}</div>
            {row.compartilhado && (
              <div>
                <Share size={16} />
              </div>
            )}
          </Group>
        );
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalServiceType) => {
        const tabs = [
          {
            key: 'Geral',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <Id size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Geral
                </Text>
              </Group>
            ),
            children: <ServiceGeneralView data={row} />,
            forceRender: true,
          },
          {
            key: 'Recorrencia',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <CalendarEvent size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Recorrência
                </Text>
              </Group>
            ),
            children: (
              <RecurrenceView
                title="Recorrência"
                description="Eventos personalizados a serem gerados para este serviço."
                color={Feature.Home.Proposal.color}
                data={row.recorrencia}
              />
            ),
            forceRender: true,
          },
          {
            key: 'Cotacoes',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <ZoomMoney size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Cotações
                </Text>
              </Group>
            ),
            children: (
              <Paper shadow="xs" p="md" withBorder>
                <ServiceQuotationView data={row} />
              </Paper>
            ),
            forceRender: true,
          },
        ];

        return (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.primary?.[6] }}
              variant="outline"
              onClick={() => {
                modals.openModal({
                  title: `Serviço - Visualizar (#${row.idPropostaServico})`,
                  size: '70%',
                  closeOnClickOutside: false,
                  children: (
                    <Paper shadow="xs" p="md" withBorder>
                      <Tabs items={tabs} defaultActiveKey="Geral" />
                    </Paper>
                  ),
                });
              }}
            >
              <Eye size={15} color={theme?.colors?.primary?.[6]} />
            </ActionIcon>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Serviços"
          text="Todos os serviços requisitados pelo cliente juntamente com seus suas cotações."
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ProposalServiceType) => item.idPropostaServico || 0}
        expandable={{
          rowExpandable: (row) => row.cotacoes.some((x) => x.primario),
          expandedRowRender: (row) => {
            const sharedResidues = [];

            for (const residuo of props.proposal.residuos) {
              if (
                residuo.planos
                  .filter((x) => x.primario)
                  .map((y) => y.acondicionamentos)
                  .flat()
                  .findIndex((z) => z.idPropostaServico === row.idPropostaServico) > -1
              ) {
                sharedResidues.push(residuo.residuoCliente);
              }
            }

            const buildSupplierData = (item: {
              idFornecedor: number;
              fornecedorCNPJ: string | null;
              fornecedorNomeFantasia: string | null;
              fornecedorRazaoSocial: string | null;

              fornecedorCPF: string | null;
              fornecedorNome: string | null;
            }) => {
              if (item.fornecedorCNPJ) {
                return (
                  <ProfileCardLink
                    id={item.idFornecedor.toString()}
                    name={item.fornecedorNomeFantasia || item.fornecedorRazaoSocial || '-'}
                    nameSize="sm"
                    description={
                      item.fornecedorNomeFantasia ? item.fornecedorRazaoSocial : item.fornecedorCNPJ
                    }
                    descriptionSize="xs"
                    descriptionLength={250}
                    linkPrefix="entities"
                    showLink={false}
                  />
                );
              }
              return (
                <ProfileCardLink
                  id={item.idFornecedor.toString()}
                  name={item.fornecedorNome || '-'}
                  nameSize="sm"
                  description={item.fornecedorCPF}
                  descriptionSize="xs"
                  linkPrefix="entities"
                  showLink={false}
                />
              );
            };

            const item = {
              ...row.cotacoes.find((x) => x.primario)!,
              gerenciar: row.gerenciar,
              compartilhado: row.compartilhado,
              residuos: sharedResidues,
              item: row.residuoAcondicionamento || row.residuoEquipamento || 'Fornecedor',
              icon: row.residuoAcondicionamento
                ? Feature.Reference.Packaging.icon
                : row.residuoEquipamento
                ? Feature.Reference.Equipment.icon
                : Feature.Reference.Entity.icon,
              unidadeMedida: `${
                row.idResiduoAcondicionamento === null && row.idResiduoEquipamento === null
                  ? `${row.frequencia} `
                  : ''
              }${row.frequenciaUnidadeMedida1} / ${row.frequenciaUnidadeMedida2}`,
            };

            return (
              <div key={newGuid()}>
                <Grid columns={3}>
                  <Grid.Col span={1}>
                    <Paper shadow="xs" p="md" withBorder>
                      <Group position="apart">
                        <Group spacing={3} className="custom-mantine-badge-group-down">
                          <item.icon size={16} />
                          {item.gerenciar ? <Briefcase size={16} /> : <></>}
                          {item.compartilhado ? <Share size={16} /> : <></>}
                          <Badge key={newGuid()} variant="outline" size="sm">
                            {item.item}
                          </Badge>
                        </Group>
                        {item.tolerancias.length > 0 && (
                          <Group spacing={3}>
                            <Scale size={16} />
                            <Badge key={newGuid()} variant="outline" size="sm">
                              {item.tolerancias.length}
                            </Badge>
                          </Group>
                        )}
                      </Group>
                      <Group style={{ marginTop: 8, marginBottom: 8 }}>{buildSupplierData(item)}</Group>
                      <Group position="apart">
                        <Group spacing={3}>
                          <CurrencyReal size={16} />
                          <Text size="sm" color="red" weight="500">
                            {item.preco}
                          </Text>
                        </Group>
                        <Stack spacing={5}>
                          <Group spacing={3}>
                            <Ruler2 size={16} />
                            <Badge key={newGuid()} variant="outline" size="sm">
                              {item.unidadeMedida}
                            </Badge>
                          </Group>
                        </Stack>
                      </Group>
                      {item.residuos.length > 0 && (
                        <>
                          <Divider my="sm" variant="dashed" />
                          <Group>
                            {item.residuos.map((x) => (
                              <Badge key={x} variant="outline" size="sm">
                                {x}
                              </Badge>
                            ))}
                          </Group>
                        </>
                      )}
                    </Paper>
                  </Grid.Col>
                </Grid>
              </div>
            );
          },
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
