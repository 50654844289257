/* eslint-disable react/destructuring-assignment */
import { Edit, Eye, Trash } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import theme from '../../../../../theme';
import { DepartmentType } from '../../../../../models/core/departments.type';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';

type DataViewActionProps = {
  item: DepartmentType;
  confirmActionResult(item: DepartmentType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: DepartmentType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse departamento?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={5}>
            <PageViewProperty label="Departamento" text={item.departamento} size="sm" />
            <PageViewProperty label="Acesso Executivo?" text={item.executivo ? 'Sim' : 'Não'} size="sm" />
            <PageViewProperty label="Acesso Gerencial?" text={item.gerencial ? 'Sim' : 'Não'} size="sm" />
            <PageViewProperty label="Acesso de Suporte?" text={item.suporte ? 'Sim' : 'Não'} size="sm" />
            <PageViewProperty label="Empresa" text={item.empresa} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.primary?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'visualizar', true);
          }}
        >
          <Eye size={15} color={theme?.colors?.primary?.[6]} />
        </ActionIcon>
      </Tooltip>
      {!props.item.executivo && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.accent?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'editar', true);
            }}
          >
            <Edit size={15} color={theme?.colors?.accent?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {!props.item.executivo && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'red' }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'excluir');
            }}
          >
            <Trash size={15} color="red" />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
}
