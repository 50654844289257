/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Alert,
  Badge,
  Divider,
  Grid,
  Group,
  Modal,
  Paper,
  SimpleGrid,
  Space,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { AlertTriangle, Eye, Scale, ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  ProposalResiduePlanQuotationTreatmentType,
  ProposalResiduePlanTreatmentType,
} from '../../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../../theme';
import { Feature } from '../../../../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../../../../utils/helper.utils';
import ProposalItemToleranceView from '../../../../components/ProposalItemToleranceView';

type ViewProps = {
  refData: ProposalResiduePlanTreatmentType | null;
  data: ProposalResiduePlanQuotationTreatmentType | null;
};

export default function ResiduePlanQuotationTreatmentView(props: ViewProps) {
  const [planQuotationItem, setPlanQuotationItem] = useState<ProposalResiduePlanQuotationTreatmentType>();
  const [opened, setOpened] = useState(false);

  const planQuotationItemRef = props.refData;

  const columns: ColumnsType<ProposalResiduePlanQuotationTreatmentType> = [
    {
      title: 'Tratamento',
      key: 'residuoTratamento',
      sorter: (
        a: ProposalResiduePlanQuotationTreatmentType,
        b: ProposalResiduePlanQuotationTreatmentType
      ) => {
        return a.residuoTratamento.localeCompare(b.residuoTratamento);
      },
      render: (row: ProposalResiduePlanQuotationTreatmentType) => {
        return (
          <ProfileCardLink
            id={(row.idResiduoTratamento || '').toString()}
            name={row.residuoTratamento || '-'}
            nameSize="sm"
            description={row.residuoTratamentoDescricao}
            descriptionSize="xs"
            linkPrefix="treatments"
            showLink
          />
        );
      },
    },
    {
      title: 'Fornecedor',
      key: 'idFornecedor',
      sorter: (a: ProposalResiduePlanQuotationTreatmentType, b: ProposalResiduePlanQuotationTreatmentType) =>
        (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
        ),
      render: (row: ProposalResiduePlanQuotationTreatmentType) => {
        if (row.idFornecedor) {
          if (row.fornecedorCNPJ) {
            return (
              <ProfileCardLink
                id={row.idFornecedor.toString()}
                name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
                nameSize="sm"
                description={row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idFornecedor.toString()}
              name={row.fornecedorNome || '-'}
              nameSize="sm"
              description={row.fornecedorCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        return '-';
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (
        a: ProposalResiduePlanQuotationTreatmentType,
        b: ProposalResiduePlanQuotationTreatmentType
      ) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;

        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationTreatmentType) => {
        return `${row.quantidade} ${row.quantidadeUnidadeMedida}`;
      },
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (
        a: ProposalResiduePlanQuotationTreatmentType,
        b: ProposalResiduePlanQuotationTreatmentType
      ) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationTreatmentType) => <div>{formatCurrency(row.preco)}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanQuotationTreatmentType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanQuotationItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  const calculatePendingQuotationItems = () => {
    const allItems = JSON.parse(
      JSON.stringify(props.refData ? [props.refData] : [])
    ) as ProposalResiduePlanTreatmentType[];

    const processedItems = props.data ? [props.data] : [];

    for (const item of processedItems) {
      const index = allItems.findIndex(
        (x) =>
          ((x.id && x.id === item?.id) ||
            (x.idTemporaria && x.idTemporaria === item?.idTemporaria) ||
            (x.idPropostaResiduoPlanoTratamento &&
              x.idPropostaResiduoPlanoTratamento === item?.idPropostaResiduoPlanoTratamento)) &&
          x.idResiduoTratamento === item.idResiduoTratamento
      );
      if (index > -1) {
        allItems.splice(index, 1);
      }
    }

    return allItems;
  };
  const pendingQuotationItems = calculatePendingQuotationItems();

  const buildQuotationBadgeList = (list: ProposalResiduePlanTreatmentType[]) => {
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x.idPropostaResiduoPlanoTratamento} variant="outline" size="sm">
            {x.residuoTratamento}
          </Badge>
        ))}
      </Group>
    );
  };

  const buildTabs = () => {
    const tabs = [
      {
        key: 'Cotacao',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <ZoomMoney size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Cotação
            </Text>
          </Group>
        ),
        children: (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={4}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Fornecedor"
                    text={
                      planQuotationItem!.fornecedorCNPJ ? (
                        <ProfileCardLink
                          id={planQuotationItem!.idFornecedor.toString()}
                          name={
                            planQuotationItem!.fornecedorNomeFantasia ||
                            planQuotationItem!.fornecedorRazaoSocial ||
                            '-'
                          }
                          nameSize="sm"
                          description={
                            planQuotationItem!.fornecedorNomeFantasia
                              ? planQuotationItem!.fornecedorRazaoSocial
                              : planQuotationItem!.fornecedorCNPJ
                          }
                          descriptionSize="xs"
                          linkPrefix="entities"
                          showLink
                        />
                      ) : (
                        <ProfileCardLink
                          id={planQuotationItem!.idFornecedor.toString()}
                          name={planQuotationItem!.fornecedorNome || '-'}
                          nameSize="sm"
                          description={planQuotationItem!.fornecedorCPF}
                          descriptionSize="xs"
                          linkPrefix="entities"
                          showLink
                        />
                      )
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Local"
                    text={
                      <ProfileCardLink
                        id={newGuid()}
                        name={`${planQuotationItem!.logradouro}, ${planQuotationItem!.numero}${
                          planQuotationItem!.complemento ? ` | ${planQuotationItem!.complemento}` : ''
                        }`}
                        nameSize="sm"
                        description={`${planQuotationItem!.bairro} - ${planQuotationItem!.cidade} / ${
                          planQuotationItem!.codigoEstado
                        } (${planQuotationItem!.cep})`}
                        descriptionSize="xs"
                        showLink={false}
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <Grid columns={4}>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={`${planQuotationItem!.quantidade} ${planQuotationItem!.quantidadeUnidadeMedida}`}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Mínimo Aceitável"
                    text={
                      !planQuotationItem!.minimoAceitavel
                        ? '-'
                        : `${planQuotationItem!.minimoAceitavel} ${
                            planQuotationItem!.minimoAceitavelUnidadeMedida
                          }`
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty label="Preço" text={formatCurrency(planQuotationItem!.preco)} size="sm" />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planQuotationItem!.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planQuotationItem!.idCriadoPor}
              criadoPor={planQuotationItem!.criadoPor}
              dataCriacao={planQuotationItem!.dataCriacao}
              idModificadoPor={planQuotationItem?.idModificadoPor || null}
              modificadoPor={planQuotationItem?.modificadoPor || null}
              dataModificacao={planQuotationItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ),
        forceRender: true,
      },
      {
        key: 'Tolerancias',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Scale size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Tolerâncias
            </Text>
          </Group>
        ),
        children: (
          <Paper shadow="xs" p="md" withBorder>
            <ProposalItemToleranceView data={planQuotationItem!.tolerancias} />
          </Paper>
        ),
        forceRender: true,
      },
    ];
    return tabs;
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Plano/Cotação/Tratamento - Visualizar"
        size="50%"
      >
        {planQuotationItem ? (
          <Paper shadow="xs" p="md" withBorder>
            <Paper shadow="xs" p="md" withBorder>
              <PageSection
                size="lg"
                color={Feature.Home.Proposal.color}
                label="Cotação para:"
                text="Este é o tratamento a ser cotado."
              />
              <Space h="xs" />

              <Grid columns={4}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Tratamento"
                    text={
                      <ProfileCardLink
                        id={(planQuotationItemRef?.idResiduoTratamento || '').toString()}
                        name={planQuotationItemRef?.residuoTratamento || '-'}
                        nameSize="sm"
                        description={planQuotationItemRef?.residuoTratamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="treatments"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>

                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Autorização Ambiental?"
                    text={planQuotationItemRef?.autorizacaoAmbiental ? 'Sim' : 'Não'}
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
            </Paper>
            <Space h="lg" />

            <Paper shadow="xs" p="md" withBorder>
              <Tabs items={buildTabs()} defaultActiveKey="Cotacao" />
            </Paper>
          </Paper>
        ) : (
          <div />
        )}
      </Modal>

      {pendingQuotationItems.length > 0 && (
        <div>
          <Alert icon={<AlertTriangle size={32} />} title="Tratamento Pendente de Cotação" color="yellow">
            {buildQuotationBadgeList(pendingQuotationItems)}
          </Alert>
          <Space h="lg" />
        </div>
      )}

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Tratamento"
          text="Cotação para o tratamento selecionado no plano de coleta do resíduo."
        />
        <Space h="xs" />

        <Table
          showSorterTooltip={false}
          dataSource={props.data ? [props.data] : []}
          columns={columns}
          rowKey={(item: ProposalResiduePlanQuotationTreatmentType) =>
            item.idPropostaResiduoPlanoCotacaoTratamento || 0
          }
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
    </div>
  );
}
