/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import { Gauge as GaugePlot, GaugeConfig } from '@ant-design/plots';

type GaugeProps = {
  max: number;
  maxString: string;
  actual: number;
  actualString: string;
  title: string;
  color: {
    text: string | undefined;
    min: string | undefined;
    max: string | undefined;
  };
  height: number | undefined;
};

export default function Gauge(props: GaugeProps) {
  const percent = props.actual === props.max ? 1 : props.actual / props.max;

  const config: GaugeConfig = {
    percent: percent > 1 ? percent - 1 : percent <= 0 ? 0.001 : percent,
    range: {
      color: `l(0) 0:${props.color.min || '#FF6B6B'} 1:${props.color.max || '#51CF66'}`,
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: undefined,
    statistic: {
      title: {
        offsetY: -55,
        style: {
          fontSize: '18px',
          color: '#4B535E',
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        customHtml: (container: HTMLElement, view: any, datum: any, data: any) => {
          const porcentagem = Number((percent || 0) * 100).toFixed(0);
          return `
            <div style='text-align: center;'>
              <div style='color: ${props.color.text};'>${porcentagem}%</div>
              <div style='font-size: 12px; font-style: italic; display: flex;'>
                <div style='color: ${props.color.min};'>
                  ${props.actualString}
                </div>
                <div style='color: ${props.color.text};'>&nbsp;/&nbsp;</div>
                <div style='color: ${props.color.max};'>
                ${props.maxString}
                </div>
              </div>
            </div>`;
        },
      },
      content: {
        offsetY: -15,
        style: {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '44px',
          color: props.color.text || '#4B535E',
        },
        formatter: () => props.title,
      },
    },
  };

  return <GaugePlot {...config} height={props.height} />;
}
