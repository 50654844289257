/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Check, X } from 'tabler-icons-react';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { DepartmentType } from '../../../../../models/core/departments.type';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: DepartmentType[];
  confirmActionResult(item: DepartmentType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Departamento',
      key: 'departamento',
      dataIndex: 'departamento',
      // eslint-disable-next-line prettier/prettier
      sorter: (a: DepartmentType, b: DepartmentType) => a.departamento.localeCompare(b.departamento),
    },
    {
      title: 'Acesso Executivo?',
      key: 'executivo',
      dataIndex: 'executivo',
      sorter: (a: DepartmentType, b: DepartmentType) =>
        a.gerencial === b.executivo ? 0 : a.executivo ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Acesso Gerencial?',
      key: 'gerencial',
      dataIndex: 'gerencial',
      sorter: (a: DepartmentType, b: DepartmentType) =>
        a.gerencial === b.gerencial ? 0 : a.gerencial ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Acesso de Suporte?',
      key: 'suporte',
      dataIndex: 'suporte',
      sorter: (a: DepartmentType, b: DepartmentType) => (a.suporte === b.suporte ? 0 : a.suporte ? -1 : 1),
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Empresa',
      key: 'empresa',
      dataIndex: 'empresa',
      sorter: (a: DepartmentType, b: DepartmentType) => a.empresa.localeCompare(b.empresa),
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: DepartmentType, b: DepartmentType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: DepartmentType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Ações',
      width: '130px',
      render: (row: DepartmentType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: DepartmentType) => item.idDepartamento}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
