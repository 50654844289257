/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Edit, Eye, Trash } from 'tabler-icons-react';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { EquipmentType } from '../../../../../models/core/equipment.type';
import theme from '../../../../../theme';
import { validate } from '../../../../../utils/permission.utils';
import { Permission } from '../../../../../models/core/departments.type';

type DataViewActionProps = {
  item: EquipmentType;
  confirmActionResult(item: EquipmentType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const showModal = (item: EquipmentType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse equipamento?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={2}>
            <PageViewProperty label="Equipamento" text={item.residuoEquipamento} size="sm" />
            <PageViewProperty label="Descrição" text={item.descricao || '-'} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      {validate(Permission.EquipmentView, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'visualizar', true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {validate(Permission.EquipmentEdit, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.accent?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'editar', true);
            }}
          >
            <Edit size={15} color={theme?.colors?.accent?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {validate(Permission.EquipmentDelete, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'red' }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'excluir');
            }}
          >
            <Trash size={15} color="red" />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
}
