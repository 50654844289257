/* eslint-disable react/destructuring-assignment */
import { Paper, Select, SimpleGrid, Space, Switch, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Building, Hierarchy } from 'tabler-icons-react';
import { useRef } from 'react';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../../models/core/cache.type';
import {
  DepartmentPermissionType,
  DepartmentType,
  Permission,
  PermissionDisabled,
} from '../../../../../models/core/departments.type';
import { Feature } from '../../../../../utils/constants.utils';
import DepartmentPermission from '../../components/DepartmentPermission';

type FormViewData = {
  idEmpresa: string;
  departamento: string;
  gerencial: boolean;
  suporte: boolean;
  permissoes: DepartmentPermissionType[];
};

type FormViewProps = {
  save(data: FormViewData): void;
  companies: CompanyType[];
  department: DepartmentType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const refDeparmentPermission = useRef<any>();
  const [currentUser] = useCurrentUser();

  const form = useForm<FormViewData>({
    initialValues: {
      idEmpresa: props.department?.idEmpresa?.toString() || currentUser.idEmpresa.toString() || '',
      departamento: props.department?.departamento || '',
      gerencial: props.department?.gerencial || false,
      suporte: props.department?.suporte || false,
      permissoes: props.department?.permissoes || [],
    },
    validate: {
      idEmpresa: (value) => {
        return props.companies.find((x) => x.idEmpresa === Number(value)) ? null : 'Campo obrigatório';
      },
      departamento: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = (inputFormViewData: FormViewData) => {
    const permissions = refDeparmentPermission?.current?.validate() ?? [];
    props.save({ ...inputFormViewData, permissoes: permissions });
  };

  return (
    <form id="department-add-edit" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.Department.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <Select
            icon={<Building size={15} />}
            label="Empresa"
            placeholder="Selecione..."
            data={props.companies.map((x) => {
              return {
                value: x.idEmpresa.toString(),
                label: x.empresa,
                disabled: x.idEmpresa === 0,
              };
            })}
            searchable
            required
            disabled={!currentUser.executivo}
            {...form.getInputProps('idEmpresa')}
          />
          <TextInput
            icon={<Hierarchy size={15} />}
            label="Departamento"
            placeholder="Digite o departamento"
            maxLength={50}
            required
            {...form.getInputProps('departamento')}
          />
          <div style={{ marginTop: 30 }}>
            <Switch label="Acesso Gerencial?" {...form.getInputProps('gerencial', { type: 'checkbox' })} />
          </div>
          <div style={{ marginTop: 30 }}>
            <Switch label="Acesso de Suporte?" {...form.getInputProps('suporte', { type: 'checkbox' })} />
          </div>
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Managerial.Department.color}
          label="Permissões"
          text="Lista de permissões em que os usuários pertencentes a este departamento terão acesso."
        />
        <Space h="xs" />

        <DepartmentPermission
          ref={refDeparmentPermission}
          idDepartamento={props.department?.idDepartamento ?? null}
          permissoes={Object.values(Permission)}
          permissoesDesabilidatas={Object.values(PermissionDisabled) as any as Permission[]}
          permissoesAtuais={props.department?.permissoes ?? []}
          modoEdicao
        />
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
