import { call } from '../core.service';
import { UserSearchType, UserSelectType, UserType } from '../../models/core/users.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const usersEndpoint = '/v1/usuarios';

export default class UsersService {
  static search = async (searchProps: UserSearchType): Promise<UserType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: usersEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<UserType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: UserSelectType): Promise<UserType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${usersEndpoint}/${selectProps.idUsuario}`,
    };
    return (await call(request))?.data?.data;
  };
}
