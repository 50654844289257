/* eslint-disable no-restricted-syntax */
import { Divider, Group, Text } from '@mantine/core';
import { ReactNode, forwardRef } from 'react';
import { Check, Icon } from 'tabler-icons-react';

interface PlanSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  extras: {
    cotacao: string;
    primary: boolean;
    hidePrimaryHeader?: boolean;
    itens: {
      icone: Icon;
      fornecedor: string;
    }[];
  }[];
}

interface QuotationSelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  extras: {
    icone: Icon;
    fornecedor: string;
  }[];
}

const PlanSelectItem = forwardRef<HTMLDivElement, PlanSelectItemProps>(
  ({ label, extras, ...others }: PlanSelectItemProps, ref) => {
    const nodes: ReactNode[] = [];

    for (const extra of extras) {
      if (!extra.hidePrimaryHeader) {
        nodes.push(
          <Group spacing={1}>
            <div>{`Cotação ${extra.cotacao}`}</div>
            {extra.primary && (
              <div>
                <Check color="green" size={15} />
              </div>
            )}
          </Group>
        );
      }

      for (const item of extra.itens) {
        nodes.push(
          <Group spacing="xs" noWrap>
            <item.icone size={15} />
            <div>{item.fornecedor}</div>
          </Group>
        );
      }
      nodes.push(<Divider my="xs" />);
    }
    nodes.pop();

    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {nodes.length > 0 && (
              <Text size="xs" opacity={0.65}>
                {nodes}
              </Text>
            )}
          </div>
        </Group>
      </div>
    );
  }
);

const QuotationSelectItem = forwardRef<HTMLDivElement, QuotationSelectItemProps>(
  ({ label, extras, ...others }: QuotationSelectItemProps, ref) => {
    const nodes: ReactNode[] = [];

    for (const extra of extras) {
      nodes.push(
        <Group spacing="xs" noWrap>
          <extra.icone size={15} />
          <div>{extra.fornecedor}</div>
        </Group>
      );
    }

    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            {nodes.length > 0 && (
              <Text size="xs" opacity={0.65}>
                {nodes}
              </Text>
            )}
          </div>
        </Group>
      </div>
    );
  }
);

export { PlanSelectItem, QuotationSelectItem };
