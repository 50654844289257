/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Divider, Grid, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Check, Eye, X } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { ProposalResiduePlanDestinationType } from '../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../theme';
import { Feature } from '../../../../../../../../../utils/constants.utils';

type ViewProps = {
  data: ProposalResiduePlanDestinationType | null;
};

export default function ResiduePlanGeneralDestinationView(props: ViewProps) {
  const [planItem, setPlanItem] = useState<ProposalResiduePlanDestinationType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanDestinationType> = [
    {
      title: 'Destino Final',
      key: 'residuoDestinoFinal',
      sorter: (a: ProposalResiduePlanDestinationType, b: ProposalResiduePlanDestinationType) =>
        (a.residuoDestinoFinal || '').localeCompare(b.residuoDestinoFinal || ''),
      render: (row: ProposalResiduePlanDestinationType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoDestinoFinal?.toString() || ''}
            name={row.residuoDestinoFinal || ''}
            nameSize="sm"
            description={row.residuoDestinoFinalDescricao}
            descriptionSize="xs"
            linkPrefix="destinations"
            showLink
          />
        );
      },
    },
    {
      title: 'Autorização Ambiental?',
      key: 'autorizacaoAmbiental',
      dataIndex: 'autorizacaoAmbiental',
      sorter: (a: ProposalResiduePlanDestinationType, b: ProposalResiduePlanDestinationType) =>
        a.autorizacaoAmbiental === b.autorizacaoAmbiental ? 0 : a.autorizacaoAmbiental ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ProposalResiduePlanDestinationType, b: ProposalResiduePlanDestinationType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ProposalResiduePlanDestinationType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanDestinationType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Plano/Destino Final - Visualizar"
        size="50%"
      >
        {planItem ? (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={5}>
                <Grid.Col span={3}>
                  <PageViewProperty
                    label="Destino Final"
                    text={
                      <ProfileCardLink
                        id={(planItem.idResiduoDestinoFinal || '').toString()}
                        name={planItem.residuoDestinoFinal || '-'}
                        nameSize="sm"
                        description={planItem.residuoDestinoFinalDescricao}
                        descriptionSize="xs"
                        linkPrefix="destinations"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Autorização Ambiental?"
                    text={planItem.autorizacaoAmbiental ? 'Sim' : 'Não'}
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planItem.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planItem.idCriadoPor}
              criadoPor={planItem.criadoPor}
              dataCriacao={planItem.dataCriacao}
              idModificadoPor={planItem?.idModificadoPor || null}
              modificadoPor={planItem?.modificadoPor || null}
              dataModificacao={planItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Destino Final"
        text="Destino Final para este plano."
      />
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={props.data ? [props.data] : []}
        columns={columns}
        rowKey={(item: ProposalResiduePlanDestinationType) => item.idPropostaResiduoPlanoDestinoFinal || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
