import { Alert, Paper, Select, SimpleGrid, Space, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useImperativeHandle } from 'react';
import { AlertTriangle, Notes, Sticker } from 'tabler-icons-react';
import { AudienciaCodigo, Status } from '../../../../../business/events/general';
import { EventStatusReasonType } from '../../../../../models/core/cache.type';
import { EventType } from '../../../../../models/core/events.type';

type FormViewData = {
  codigoEventoStatusMotivo: string | null;
  observacao: string | null;
};

type FormViewProps = {
  event: EventType;
  newStatusCode: Status;
  eventStatusReasonData: EventStatusReasonType[];
};

const StatusChangeForm = forwardRef((props: FormViewProps, ref) => {
  const form = useForm<FormViewData>({
    initialValues: {
      codigoEventoStatusMotivo: '',
      observacao: '',
    },
    validate: {
      codigoEventoStatusMotivo: (value) => {
        if (props.newStatusCode === Status.Cancelado && (!value || value === '')) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      if (form.validate().hasErrors) {
        return null;
      }

      return {
        codigoEventoStatusMotivo: form.values.codigoEventoStatusMotivo?.trim() || null,
        eventoStatusMotivo: props.eventStatusReasonData.find(
          (x) => x.codigoEventoStatusMotivo === form.values.codigoEventoStatusMotivo?.trim()
        )?.eventoStatusMotivo,
        observacao: form.values.observacao?.trim() || null,
      } as any;
    },
  }));

  return (
    <form id="status-change-form" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        {props.newStatusCode === Status.PendenteMedicao &&
          props.event.codigoEventoAudiencia !== AudienciaCodigo.Financeiro && (
            <>
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                Após a confirmação, o evento será enviado para o setor Financeiro.
              </Alert>
              <Space h="md" />
            </>
          )}
        {props.newStatusCode === Status.Pendente &&
          props.event.codigoEventoStatus === Status.PendenteMedicao &&
          props.event.codigoEventoAudiencia === AudienciaCodigo.Financeiro && (
            <>
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                Após a confirmação, o evento será enviado para o setor Operacional.
              </Alert>
              <Space h="md" />
            </>
          )}

        {props.newStatusCode === Status.Cancelado && (
          <>
            <SimpleGrid cols={1}>
              <Select
                icon={<Sticker size={15} />}
                label="Motivo"
                placeholder="Selecione..."
                data={props.eventStatusReasonData.map((x) => {
                  return {
                    value: x.codigoEventoStatusMotivo,
                    label: x.eventoStatusMotivo,
                  };
                })}
                required
                {...form.getInputProps('codigoEventoStatusMotivo')}
              />
            </SimpleGrid>
            <Space h="md" />
          </>
        )}

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o status"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
    </form>
  );
});

export default StatusChangeForm;
