/* eslint-disable no-plusplus */
import { call } from '../iam-auth.service';
import {
  SignInRequestType,
  SignInResponseType,
  SignUpRequestConfirmType,
  SignUpRequestType,
  SignUpResponseType,
  ForgotPasswordType,
  ForgotPasswordConfirmType,
  ChangeEmailType,
  ChangeEmailConfirmType,
} from '../../models/iam/auth.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { LocalStorageKey } from '../../utils/constants.utils';
import { encrypt } from '../../utils/helper.utils';

export default class AuthService {
  static signIn = async (signInProps: SignInRequestType): Promise<SignInResponseType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/sign-in',
      data: signInProps,
    };
    const data = (await call(request))?.data?.data as SignInResponseType;
    localStorage.setItem(LocalStorageKey.UserCredentials, encrypt(JSON.stringify(signInProps)));
    localStorage.setItem(LocalStorageKey.UserAccessToken, data.AccessToken);
    return data;
  };

  static signOut = async (): Promise<void> => {
    try {
      const accessToken = localStorage.getItem(LocalStorageKey.UserAccessToken)?.replaceAll('"', '');
      const request: AxiosCustomRequestType = {
        method: 'POST',
        url: '/v1/sign-out',
        data: {
          accessToken,
        },
      };
      await call(request);
    } catch (error: any) {
      throw error?.response?.data || error;
    } finally {
      localStorage.removeItem(LocalStorageKey.UserAccessToken);
      localStorage.removeItem(LocalStorageKey.UserCredentials);
      localStorage.removeItem(LocalStorageKey.User);
      localStorage.removeItem(LocalStorageKey.CoreDataReference);
      localStorage.removeItem(LocalStorageKey.BrasilAPIBanks);
      localStorage.removeItem(LocalStorageKey.BrasilAPIStates);

      sessionStorage.clear();
    }
  };

  static signUp = async (signUpProps: SignUpRequestType): Promise<SignUpResponseType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/sign-up',
      data: signUpProps,
    };
    return (await call(request))?.data?.data;
  };

  static signUpConfirm = async (signUpConfirmProps: SignUpRequestConfirmType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/sign-up/confirm',
      data: signUpConfirmProps,
    };
    return (await call(request))?.data?.data;
  };

  static forgotPassword = async (forgotPasswordProps: ForgotPasswordType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/forgot-password',
      data: forgotPasswordProps,
    };
    return (await call(request))?.data?.data;
  };

  static forgotPasswordConfirm = async (
    forgotPasswordConfirmProps: ForgotPasswordConfirmType
  ): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/forgot-password/confirm',
      data: forgotPasswordConfirmProps,
    };
    return (await call(request))?.data?.data;
  };

  static changeEmail = async (changeEmailProps: ChangeEmailType): Promise<void> => {
    const accessToken = localStorage.getItem(LocalStorageKey.UserAccessToken)?.replaceAll('"', '');
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/change-email',
      data: { ...changeEmailProps, accessToken },
    };
    return (await call(request))?.data?.data;
  };

  static changeEmailConfirm = async (changeEmailConfirmProps: ChangeEmailConfirmType): Promise<void> => {
    const accessToken = localStorage.getItem(LocalStorageKey.UserAccessToken)?.replaceAll('"', '');
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/change-email/confirm',
      data: { ...changeEmailConfirmProps, accessToken },
    };
    return (await call(request))?.data?.data;
  };

  static signOff = async (): Promise<void> => {
    const accessToken = localStorage.getItem(LocalStorageKey.UserAccessToken)?.replaceAll('"', '');
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: '/v1/sign-off',
      data: { accessToken },
    };
    return (await call(request))?.data?.data;
  };
}
