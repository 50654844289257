import { call } from '../core.service';
import {
  DismissalDeleteType,
  DismissalInsertType,
  DismissalSearchType,
  DismissalSelectType,
  DismissalType,
} from '../../models/core/dismissals.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const dismissalsEndpoint = '/v1/usuario-desligamentos';

export default class DismissalsService {
  static search = async (searchProps: DismissalSearchType): Promise<DismissalType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: dismissalsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static select = async (selectProps: DismissalSelectType): Promise<DismissalType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${dismissalsEndpoint}/${selectProps.idUsuarioDesligamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static delete = async (deleteProps: DismissalDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${dismissalsEndpoint}/${deleteProps.idUsuarioDesligamento}`,
    };
    return call(request);
  };

  static insert = async (insertProps: DismissalInsertType): Promise<DismissalType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: dismissalsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
