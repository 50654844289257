/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import { Calendar, DeviceFloppy, FileDots, FileInfo, Notes, Qrcode } from 'tabler-icons-react';
import {
  Button,
  Group,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePicker } from '@mantine/dates';
import { useRef } from 'react';
import { EntityDocumentType, EntityDocumentFileType } from '../../../../../../../models/core/entities.type';
import { buildFakeAuditObject, newGuid, tryGetDateISOFormat } from '../../../../../../../utils/helper.utils';
import { DocumentType } from '../../../../../../../models/core/cache.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { formatDateStringToDate, formatDateToString } from '../../../../../../../utils/formatter.utils';
import DocumentFileFormView from './DocumentFileFormView';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewData = {
  action: Action;
  id: string;
  idDocumentoTipo: string;
  documentoTipo: string;
  outroTipo: string | null;
  numeroDocumento: string | null;
  validadeLembreteDias: number | null;
  validade: Date;
  observacao: string | null;
  arquivos: EntityDocumentFileType[];
};

type FormViewProps = {
  referenceData: {
    documentTypeData: DocumentType[];
  };
  origItem: EntityDocumentType | null;
  item: (EntityDocumentType & { origFiles?: EntityDocumentFileType[] }) | null;
  idEntidade: number | undefined;
  callback(item: EntityDocumentType | null, action: string, confirmed: boolean): void;
};

export default function DocumentFormAddEdit(props: FormViewProps) {
  const refDocumentFileForm = useRef<any>();
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),
      idDocumentoTipo: props.item?.idDocumentoTipo.toString() || '',
      documentoTipo: props.item?.documentoTipo || '',
      outroTipo: props.item?.outroTipo || '',
      numeroDocumento: props.item?.numeroDocumento || '',
      validadeLembreteDias: props.item?.validadeLembreteDias || null,
      validade: props.item ? formatDateStringToDate(tryGetDateISOFormat(props.item.validade)) : new Date(),
      observacao: props.item?.observacao || '',
      arquivos: [],
    },
    validate: {
      idDocumentoTipo: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      outroTipo: (value: string | null, values: FormViewData) => {
        if (values.idDocumentoTipo === '0' && value?.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const now = new Date();
    const origData = `${props.origItem?.idDocumentoTipo?.toString() || ''}
    |${props.origItem?.outroTipo || ''}
    |${props.origItem?.numeroDocumento || ''}
    |${props.origItem?.validadeLembreteDias || ''}
    |${formatDateToString(props.origItem?.validade || now)}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idDocumentoTipo || ''}
    |${form.values.outroTipo || ''}
    |${form.values.numeroDocumento || ''}
    |${form.values.validadeLembreteDias || ''}
    |${formatDateToString(form.values.validade)}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
          idDocumentoTipo: values.idDocumentoTipo,
        };
      }
    }

    values.documentoTipo =
      props.referenceData.documentTypeData.find(
        (x) => x.idDocumentoTipo.toString() === values.idDocumentoTipo.toString()
      )?.documentoTipo || '';

    const formItem: EntityDocumentType = {
      ...values,
      ...buildFakeAuditObject(),
      idDocumentoTipo: Number(values.idDocumentoTipo),
      idEntidade: props.idEntidade || -1,
    };

    formItem.outroTipo = values.idDocumentoTipo === '0' ? formItem.outroTipo?.trim() || null : null;
    formItem.numeroDocumento = formItem.numeroDocumento?.trim() || null;
    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.arquivos = refDocumentFileForm.current.validate();
    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <form id="entity-document" onSubmit={form.onSubmit(handleSubmit)} noValidate>
          <PageSection size="lg" color={Feature.Reference.Entity.color} label="Documento" text="" />
          <Space h="xs" />
          <SimpleGrid cols={2}>
            <Select
              icon={<FileDots size={15} />}
              label="Tipo"
              placeholder="Selecione..."
              data={props.referenceData.documentTypeData.map((x) => {
                return {
                  value: x.idDocumentoTipo.toString(),
                  label: x.documentoTipo,
                };
              })}
              searchable
              required
              {...form.getInputProps('idDocumentoTipo')}
            />
            {form.values.idDocumentoTipo === '0' && (
              <TextInput
                icon={<FileInfo size={15} />}
                label="Outro"
                placeholder="Digite o outro tipo"
                maxLength={50}
                required
                {...form.getInputProps('outroTipo')}
              />
            )}
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={3}>
            <TextInput
              icon={<Qrcode size={15} />}
              label="Número do Documento"
              placeholder="Digite o número do documento"
              maxLength={50}
              {...form.getInputProps('numeroDocumento')}
            />
            <NumberInput
              label="Lembrete da Validade"
              placeholder="Lembrete em dias"
              min={1}
              max={3120}
              {...form.getInputProps('validadeLembreteDias')}
            />
            <DatePicker
              icon={<Calendar size={15} />}
              locale="pt-br"
              label="Validade"
              inputFormat="DD/MM/YYYY"
              clearable={false}
              allowFreeInput
              required
              {...form.getInputProps('validade')}
            />
          </SimpleGrid>

          <Space h="xs" />
          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o documento"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </form>
      </Paper>
      <Space h="lg" />
      <Paper shadow="xs" p="md" withBorder>
        <DocumentFileFormView ref={refDocumentFileForm} entityDocument={props.item} />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="entity-document">
          Salvar
        </Button>
      </Group>
    </div>
  );
}
