/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import { EventMeasurementMovementType, EventType } from '../../../../../../models/core/events.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../utils/formatter.utils';
import { MovementTypeStandard } from '../../../../../../models/core/cache.type';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';

type ViewProps = {
  event: EventType;
  data: EventMeasurementMovementType;
};

export default function MovementView(props: ViewProps) {
  const balanco = props.data.receita - props.data.despesa - props.data.imposto;

  return (
    <Paper shadow="xs" p="md" withBorder>
      <PageSection
        size="lg"
        color={Feature.Home.Event.color}
        label="Movimentação"
        text="Visualize uma movimentação."
      />
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        {props.data.fornecedorCNPJ ? (
          <PageViewProperty
            label="Fornecedor"
            text={
              <ProfileCardLink
                id={props.data.idFornecedor?.toString() || ''}
                name={props.data.fornecedorNomeFantasia || props.data.fornecedorRazaoSocial || '-'}
                nameSize="sm"
                description={
                  props.data.fornecedorNomeFantasia
                    ? props.data.fornecedorRazaoSocial
                    : props.data.fornecedorCNPJ
                }
                descriptionSize="xs"
                linkPrefix="entities"
                showLink={false}
              />
            }
            size="sm"
          />
        ) : (
          <PageViewProperty
            label="Entidade"
            text={
              <ProfileCardLink
                id={props.data.idFornecedor?.toString() || ''}
                name={props.data.fornecedorNome || '-'}
                nameSize="sm"
                description={props.data.fornecedorCPF}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink={false}
              />
            }
            size="sm"
          />
        )}
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={4}>
          <Grid.Col span={1}>
            <PageViewProperty label="Tipo" text={props.data.movimentacaoTipo} size="sm" />
          </Grid.Col>
          {props.data.idMovimentacaoTipo === 0 && (
            <Grid.Col span={1}>
              <PageViewProperty label="Outro Tipo" text={props.data.outroTipo} size="sm" />
            </Grid.Col>
          )}
        </Grid>
        <Divider my="sm" variant="dotted" />

        <Grid columns={7}>
          <Grid.Col span={3}>
            <PageViewProperty
              label="Quantidade"
              text={`${props.data.quantidade} ${props.data.quantidadeUnidadeMedida}`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Receita" text={formatCurrency(props.data.receita)} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Despesa"
              text={
                props.data.codigoMovimentacaoPadrao !== MovementTypeStandard.ComissaoPercentual
                  ? formatCurrency(props.data.despesa)
                  : `${formatCurrency(props.data.despesa)} (${
                      props.event.referencia?.comissao?.porcentagem
                    }%)`
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Imposto" text={formatCurrency(props.data.imposto)} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Balanço"
              text={
                <div
                  style={{
                    color: balanco > 0 ? 'green' : balanco === 0 ? 'orange' : 'red',
                  }}
                >
                  {formatCurrency(balanco)}
                </div>
              }
              size="sm"
            />
          </Grid.Col>
        </Grid>

        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={1}>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
        </SimpleGrid>
      </Paper>
    </Paper>
  );
}
