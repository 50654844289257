/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { Group, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { EntityPaymentMethodType } from '../../../../../../models/core/entities.type';
import { Feature } from '../../../../../../utils/constants.utils';

type ViewProps = {
  data: EntityPaymentMethodType[];
};

export default function PaymentMethodView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Forma',
      key: 'formaPagamento',
      dataIndex: 'formaPagamento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) =>
        a.formaPagamento.localeCompare(b.formaPagamento),
    },
    {
      title: 'Prazo (dias)',
      key: 'prazoPagamentoDias',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        const aValue = a.prazoPagamentoDias || 0;
        const bValue = b.prazoPagamentoDias || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityPaymentMethodType) => <div>{row?.prazoPagamentoDias || '-'}</div>,
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'recebimento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) =>
        a.recebimento === b.recebimento ? 0 : a.recebimento ? -1 : 1,
      render: (row: boolean) => <div>{row ? 'Recebimento' : 'Pagamento'}</div>,
    },
    {
      title: 'CNPJ/CPF',
      key: 'cnpj-cpf',
      width: '180px',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.cnpj || a.cpf || '').localeCompare(b.cnpj || b.cpf || '');
      },
      render: (row: EntityPaymentMethodType) => <div>{row?.cnpj || row?.cpf}</div>,
    },
    {
      title: 'Beneficiário',
      key: 'nome',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.nome || '').localeCompare(b.nome || '');
      },
      render: (row: EntityPaymentMethodType) => <div>{row?.nome || '-'}</div>,
    },
    {
      title: 'Dados do Pagamento',
      key: 'dadosPagamento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.banco || a.chavePix || '').localeCompare(b.banco || b.chavePix || '');
      },
      render: (row: EntityPaymentMethodType) => {
        if (row.codigoFormaPagamento === 'PX' && row.recebimento) {
          return <div>Chave PIX: {row.chavePix}</div>;
        }
        if (row.codigoFormaPagamento === 'TB' && row.recebimento) {
          return (
            <div>
              <div>Banco: {row.banco}</div>
              <div>
                Agência: {row.agencia} | Conta corrente: {row.contaCorrente}
                {`${!row.digitoVerificador ? '' : `-${row.digitoVerificador}`}`}
              </div>
            </div>
          );
        }
        return <div>-</div>;
      },
    },
    {
      title: 'Condição de Pagamento',
      key: 'condicaoPagamento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.condicaoPagamento || '').localeCompare(b.condicaoPagamento || '');
      },
      render: (row: EntityPaymentMethodType) => (
        <TableCellEllipsis label={row?.condicaoPagamento} numberOfChars={20} toolTipWidth={300} />
      ),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EntityPaymentMethodType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />
      ),
    },
  ];

  return (
    <div>
      <Group>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Formas de Pagamento" text="" />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        // size="small"
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EntityPaymentMethodType) => item.idEntidadeFormaPagamento || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <PageViewAudit
                idCriadoPor={item.idCriadoPor}
                criadoPor={item.criadoPor}
                dataCriacao={item.dataCriacao}
                idModificadoPor={item.idModificadoPor || null}
                modificadoPor={item.modificadoPor || null}
                dataModificacao={item.dataModificacao || null}
                size="sm"
              />
            );
          },
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
