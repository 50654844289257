/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Divider, Grid, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Eye } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalResiduePlanVehicleType } from '../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../theme';
import { Feature } from '../../../../../../../../../utils/constants.utils';

type ViewProps = {
  data: ProposalResiduePlanVehicleType | null;
};

export default function ResiduePlanGeneralVehicleView(props: ViewProps) {
  const [planItem, setPlanItem] = useState<ProposalResiduePlanVehicleType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanVehicleType> = [
    {
      title: 'Veiculo',
      key: 'residuoVeiculo',
      sorter: (a: ProposalResiduePlanVehicleType, b: ProposalResiduePlanVehicleType) =>
        (a.residuoVeiculo || '').localeCompare(b.residuoVeiculo || ''),
      render: (row: ProposalResiduePlanVehicleType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoVeiculo?.toString() || ''}
            name={row.residuoVeiculo || ''}
            nameSize="sm"
            description={row.residuoVeiculoDescricao}
            descriptionSize="xs"
            linkPrefix="vehicles"
            showLink
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalResiduePlanVehicleType, b: ProposalResiduePlanVehicleType) => {
        const aValue = a.quantidadeUnidadeMedidaSigla === 'ton' ? a.quantidade : a.quantidade * 1000;
        const bValue = b.quantidadeUnidadeMedidaSigla === 'ton' ? b.quantidade : b.quantidade * 1000;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanVehicleType) => (
        <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Carga Média (Coleta)',
      key: 'cargaMedia',
      sorter: (a: ProposalResiduePlanVehicleType, b: ProposalResiduePlanVehicleType) => {
        const aValue = a.cargaMediaUnidadeMedidaSigla === 'ton' ? a.cargaMedia : a.cargaMedia * 1000;
        const bValue = b.cargaMediaUnidadeMedidaSigla === 'ton' ? b.cargaMedia : b.cargaMedia * 1000;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanVehicleType) => (
        <div>{`${row.cargaMedia} ${row.cargaMediaUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanVehicleType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Plano/Veículo - Visualizar"
        size="50%"
      >
        {planItem ? (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={5}>
                <Grid.Col span={3}>
                  <PageViewProperty
                    label="Veículo"
                    text={
                      <ProfileCardLink
                        id={(planItem.idResiduoVeiculo || '').toString()}
                        name={planItem.residuoVeiculo || '-'}
                        nameSize="sm"
                        description={planItem.residuoVeiculoDescricao}
                        descriptionSize="xs"
                        linkPrefix="vehicles"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={`${planItem.quantidade} ${planItem.quantidadeUnidadeMedida}`}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Carga Média (Coleta)"
                    text={`${planItem.cargaMedia} ${planItem.cargaMediaUnidadeMedida}`}
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planItem.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planItem.idCriadoPor}
              criadoPor={planItem.criadoPor}
              dataCriacao={planItem.dataCriacao}
              idModificadoPor={planItem?.idModificadoPor || null}
              modificadoPor={planItem?.modificadoPor || null}
              dataModificacao={planItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Veículo"
        text="Veículo para este plano."
      />
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={props.data ? [props.data] : []}
        columns={columns}
        rowKey={(item: ProposalResiduePlanVehicleType) => item.idPropostaResiduoPlanoVeiculo || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
