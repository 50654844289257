/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Group, Space } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Check, Plus, Share, X } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalGeneralContext } from '../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { ProposalServiceProvider } from '../../../../../../contexts/core/proposals/ProposalService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../models/core/cache.type';
import { Action, ItemToleranceType } from '../../../../../../models/core/core.type';
import {
  ProposalServiceQuotationType,
  ProposalServiceType,
  ProposalType,
} from '../../../../../../models/core/proposals.type';
import { montarRecorrenciaString } from '../../../../../../utils/business.utils';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import { dateDiffInDaysDisregardTime, newGuid } from '../../../../../../utils/helper.utils';
import ServiceFormAddEdit from './ServiceFormView/ServiceFormAddEdit';
import ServiceFormViewActions from './ServiceFormView/ServiceFormViewActions';

type ProposalServiceFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
  };
  proposal: ProposalType | null;
};

const ServiceFormView = forwardRef((props: ProposalServiceFormViewProps, ref) => {
  const { proposalGeneralData, setProposalGeneralData } = useProposalGeneralContext();
  const modals = useModals();

  const [data, setData] = useState<ProposalServiceType[]>(
    props.proposal?.servicos.map((x) => {
      return {
        ...x,
        action: Action.Nothing,
        id: x.idPropostaServico?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Serviço',
      key: 'servico',
      dataIndex: 'servico',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => a.servico.localeCompare(b.servico),
    },
    {
      title: 'Item',
      key: 'item',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aItem = a.residuoAcondicionamento || a.residuoEquipamento || '-';
        const bItem = b.residuoAcondicionamento || b.residuoEquipamento || '-';
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalServiceType) => {
        if (row.idResiduoAcondicionamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoAcondicionamento || '').toString()}
              name={row.residuoAcondicionamento || '-'}
              nameSize="sm"
              description={row.residuoAcondicionamentoDescricao}
              descriptionSize="xs"
              linkPrefix="packaging"
              showLink
            />
          );
        }
        if (row.idResiduoEquipamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoEquipamento || '').toString()}
              name={row.residuoEquipamento || '-'}
              nameSize="sm"
              description={row.residuoEquipamentoDescricao}
              descriptionSize="xs"
              linkPrefix="equipment"
              showLink
            />
          );
        }
        return (
          <ProfileCardLink
            id={newGuid.toString()}
            name="Fornecedor"
            nameSize="sm"
            description={null}
            descriptionSize="xs"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceType) => {
        return <div>{row.quantidade.toString()}</div>;
      },
    },
    {
      title: 'Frequência',
      key: 'frequencia',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aIsService = !(a.idResiduoAcondicionamento || a.idResiduoEquipamento);
        const bIsService = !(b.idResiduoAcondicionamento || b.idResiduoEquipamento);

        const aItem = `${aIsService ? `${a.frequencia} ` : ''}${a.frequenciaUnidadeMedida1} / ${
          a.frequenciaUnidadeMedida2
        }`;
        const bItem = `${bIsService ? `${b.frequencia} ` : ''}${b.frequenciaUnidadeMedida1} / ${
          b.frequenciaUnidadeMedida2
        }`;
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalServiceType) => {
        const isService = !(row.idResiduoAcondicionamento || row.idResiduoEquipamento);
        return (
          <div>{`${isService ? `${row.frequencia} ` : ''}${row.frequenciaUnidadeMedida1} / ${
            row.frequenciaUnidadeMedida2
          }`}</div>
        );
      },
    },
    {
      title: 'Recorrência',
      key: 'recorrencia',
      render: (row: ProposalServiceType) => {
        const recorrencia = montarRecorrenciaString(row.recorrencia);
        if (!recorrencia) {
          return '-';
        }
        return (
          <div>
            <div>{recorrencia.str1}</div>
            {recorrencia.str2 ? <div>{recorrencia.str2}</div> : <></>}
          </div>
        );
      },
    },
    {
      title: 'Data Inicial',
      key: 'dataInicial',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) =>
        new Date(a.recorrencia.dataInicial || a.dataInicial).valueOf() -
        new Date(b.recorrencia.dataInicial || b.dataInicial).valueOf(),
      render: (row: ProposalServiceType) =>
        formatDateToString(row.recorrencia.dataInicial || row.dataInicial),
    },
    {
      title: 'Data Final',
      key: 'dataFinal',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) =>
        new Date(a.recorrencia.dataFinal || a.dataFinal).valueOf() -
        new Date(b.recorrencia.dataFinal || b.dataFinal).valueOf(),
      render: (row: ProposalServiceType) => formatDateToString(row.recorrencia.dataFinal || row.dataFinal),
    },
    {
      title: 'Cotações',
      key: 'cotacoes',
      sorter: (a: ProposalServiceType, b: ProposalServiceType) => {
        const aValue = a.cotacoes.filter((x) => x.action !== Action.Delete).length;
        const bValue = b.cotacoes.filter((x) => x.action !== Action.Delete).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceType) => {
        return (
          <Group spacing="xs">
            <div>{row.cotacoes.length || '-'}</div>
            <div>{row.cotacoes.some((x) => x.primario) ? <Check color="green" /> : <X color="red" />}</div>
            {row.compartilhado && (
              <div>
                <Share size={16} />
              </div>
            )}
          </Group>
        );
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalServiceType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ServiceFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: ProposalServiceType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaServico) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setProposalGeneralData({ ...proposalGeneralData, servicos: data });
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Serviço - ${item ? 'Editar' : 'Adicionar'}`,
          size: '70%',
          closeOnClickOutside: false,
          children: (
            <ProposalServiceProvider>
              <ServiceFormAddEdit
                referenceData={props.referenceData}
                proposalGeneralData={proposalGeneralData}
                origItem={
                  item
                    ? props.proposal?.servicos.find((x) => x.idPropostaServico === item.idPropostaServico) ||
                      null
                    : null
                }
                item={item}
                idProposta={props.proposal?.idProposta}
                callback={confirmActionResult}
              />
            </ProposalServiceProvider>
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setProposalGeneralData({ ...proposalGeneralData, servicos: data });
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalServiceType[] {
      data.forEach((x: ProposalServiceType) => {
        // validate services against proposal initial and final dates
        if (
          dateDiffInDaysDisregardTime(
            typeof x.dataInicial === 'string' ? moment(x.dataInicial, 'YYYY-MM-DD').toDate() : x.dataInicial,
            proposalGeneralData.dataInicial,
            true
          ) < 0 ||
          dateDiffInDaysDisregardTime(
            typeof x.dataInicial === 'string' ? moment(x.dataInicial, 'YYYY-MM-DD').toDate() : x.dataInicial,
            proposalGeneralData.dataFinal,
            true
          ) > 0
        ) {
          throw Error(
            `Existe(m) serviço(s) que não se enquadram na proposta: (Data Inicial esperada: ${formatDateToString(
              proposalGeneralData.dataInicial
            )} - ${formatDateToString(
              proposalGeneralData.dataFinal
            )} | Data Inicial atual: ${formatDateToString(x.dataInicial)})`
          );
        }

        if (
          dateDiffInDaysDisregardTime(
            typeof x.dataFinal === 'string' ? moment(x.dataFinal, 'YYYY-MM-DD').toDate() : x.dataFinal,
            proposalGeneralData.dataInicial,
            true
          ) < 0 ||
          dateDiffInDaysDisregardTime(
            typeof x.dataFinal === 'string' ? moment(x.dataFinal, 'YYYY-MM-DD').toDate() : x.dataFinal,
            proposalGeneralData.dataFinal,
            true
          ) > 0
        ) {
          throw Error(
            `Existe(m) serviço(s) que não se enquadram na proposta: (Data Final esperada: ${formatDateToString(
              proposalGeneralData.dataInicial
            )} - ${formatDateToString(
              proposalGeneralData.dataFinal
            )} | Data Final atual: ${formatDateToString(x.dataFinal)})`
          );
        }

        // remove unnecessary data and format it
        delete (x as any).clienteData;
        delete (x as any).enderecoData;
        (x as any).turno = x.turno ? Number(x.turno) : null;

        // set default actions
        x.recorrencia.action = x.recorrencia?.action || Action.Nothing;
        x.cotacoes.forEach((y: ProposalServiceQuotationType) => {
          y.action = y.action || Action.Nothing;
          y.tolerancias.forEach((z: ItemToleranceType) => {
            z.action = z.action || Action.Nothing;
          });
        });
      });
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempProposal = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempProposal) || 'null');
    if (tempProposal && props.proposal === null) {
      setData(tempProposal.servicos);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Serviços"
          text="Todos os serviços requisitados pelo cliente juntamente com suas cotações."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ProposalServiceType) => item.id || 0}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ServiceFormView;
