/* eslint-disable react/destructuring-assignment */
import { Eye, Trash, X } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import theme from '../../../../../theme';
import { InvitationType } from '../../../../../models/core/invitations.type';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';

type DataViewActionProps = {
  item: InvitationType;
  confirmActionResult(item: InvitationType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: InvitationType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse convite?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Nome" text={item.nome} size="sm" />
            <PageViewProperty label="E-mail" text={item.email} size="sm" />
            <PageViewProperty label="Empresa" text={item.empresa} size="sm" />
            <PageViewProperty label="Departamento" text={item.departamento} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.primary?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'visualizar', true);
          }}
        >
          <Eye size={15} color={theme?.colors?.primary?.[6]} />
        </ActionIcon>
      </Tooltip>
      {props.item.codigoUsuarioConviteStatus === 'E' && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Cancelar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: 'orange' }}
            variant="outline"
            onClick={() => {
              showModal(props.item, 'cancelar');
            }}
          >
            <X size={15} color="orange" />
          </ActionIcon>
        </Tooltip>
      )}
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
