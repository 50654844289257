/* eslint-disable react/destructuring-assignment */
import { MantineSize, Text } from '@mantine/core';

type PageViewPropertyProps = {
  label: string;
  text: string;
  size: MantineSize;
  color: string;
};

export default function PageSection(props: PageViewPropertyProps) {
  return (
    <div>
      <Text size={props.size} color={props.color} weight={500}>
        {props.label}
      </Text>
      {props.text !== '' && (
        <Text color="dimmed" size="sm">
          {props.text}
        </Text>
      )}
    </div>
  );
}
