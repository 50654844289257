/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Affix, Alert, Button, Card, Center, Loader, Space, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowNarrowUp, CircleX, InfoCircle, Trash, X } from 'tabler-icons-react';
import { ContractStatusEnum } from '../../../../business/contracts/status';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { ContractStatusType } from '../../../../models/core/cache.type';
import { ContractType } from '../../../../models/core/contracts.type';
import { Permission } from '../../../../models/core/departments.type';
import contractsService from '../../../../services/core/contracts.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature } from '../../../../utils/constants.utils';
import { buildFakeAuditObject } from '../../../../utils/helper.utils';
import DataView from './components/DataView';
import StatusChangeForm from './components/StatusChangeForm';

type Result = {
  loading: boolean;
  referenceData: {
    contractStatusTypes: ContractStatusType[];
  } | null;
  data: ContractType | null;
};

function ContractView() {
  const navigate = useNavigate();
  const modals = useModals();
  const [currentUser] = useCurrentUser();
  const { idContrato } = useParams();
  const [scroll, scrollTo] = useWindowScroll();

  const refStatusForm = useRef<any>(null);

  const [exception, setException] = useState<any | null>(null);
  const [result, setResult] = useState<Result>({
    loading: true,
    referenceData: null,
    data: null,
  });
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { referenceData, data } = result;
      try {
        if (result.referenceData === null) {
          referenceData = {
            contractStatusTypes: await cacheUtils.listContractStatuses(),
          };
        }
        data = await contractsService.select({ idContrato: Number(idContrato) });
      } catch (error: any) {
        showNotification({
          title: 'Contrato - Visualizar',
          message: error?.isBusinessException ? error.description : `Não foi possível carregar o contrato.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, referenceData, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idContrato, navigate, result]);

  const handleAction = async (action: string) => {
    try {
      setResult({ loading: false, referenceData: result.referenceData, data: result.data });

      let completedAction;
      setUpdating(true);

      switch (action) {
        case 'excluir':
          await contractsService.delete({ idContrato: Number(idContrato) });
          completedAction = 'excluído';
          navigate('/contracts');
          break;
        case 'cancelar':
          const statusData = refStatusForm.current?.validate() || null;
          const newStatusCode = ContractStatusEnum.Cancelado;
          completedAction = 'cancelado';

          await contractsService.updateStatus({
            ...statusData,
            idContrato: Number(idContrato),
            codigoContratoStatus: newStatusCode,
          });

          const newContratoStatus = result.referenceData!.contractStatusTypes.find(
            (x) => x.codigoContratoStatus === newStatusCode
          )!.contratoStatus;

          setResult({
            ...result,
            data: {
              ...result.data!,
              codigoContratoStatus: newStatusCode,
              contratoStatus: newContratoStatus,
              status: [
                ...result.data!.status,
                {
                  ...buildFakeAuditObject(),
                  idContratoStatus: new Date().valueOf(),
                  codigoContratoStatus: newStatusCode,
                  contratoStatus: newContratoStatus,
                  codigoContratoStatusMotivo: null,
                  observacao: statusData.observacao,
                  idCriadoPor: currentUser.idUsuario,
                  criadoPor: currentUser.nomeCompleto,
                  dataCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
                },
              ],
              modificadoPor: currentUser.nomeCompleto,
              dataModificacao: moment().format('YYYY-MM-DD HH:mm:ss') as any,
            },
          });
          break;
        default:
          break;
      }

      showNotification({
        title: 'Contrato - Visualizar',
        message: `Contrato ${completedAction} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Contrato - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível excluir o contrato.`,
        color: 'red',
      });
    } finally {
      setUpdating(false);
    }
  };

  const showModal = (action: string) => {
    if (action === 'cancelar') {
      modals.openConfirmModal({
        title: `Confirma o cancelamento do contrato"?`,
        size: 'xl',
        closeOnClickOutside: false,
        closeOnEscape: false,
        closeOnConfirm: false,
        children: <StatusChangeForm ref={refStatusForm} contract={result.data!} />,
        onConfirm: async () => {
          closeAllModals();
          handleAction(action);
        },
      });
    } else {
      modals.openConfirmModal({
        title: `Gostaria de ${action} esso contrato?`,
        size: 'sm',
        closeOnClickOutside: false,
        closeOnEscape: false,
        onConfirm: () => {
          handleAction(action);
        },
      });
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Contract}
        title="Contrato - Visualizar"
        description="Visualize os detalhes de um contrato."
        buttons={[
          <Button
            key="Cancelar"
            color="orange"
            leftIcon={<X size={18} />}
            onClick={() => {
              showModal('cancelar');
            }}
            disabled={updating || result.loading}
            loading={updating}
            hidden={
              result.data?.codigoContratoStatus === ContractStatusEnum.Cancelado ||
              result.data?.codigoContratoStatus === ContractStatusEnum.EmSubstituicao ||
              result.data?.codigoContratoStatus === ContractStatusEnum.Substituido ||
              result.data?.codigoContratoStatus === ContractStatusEnum.Finalizado
            }
            data-permission={Permission.ContractCancel}
          >
            Cancelar
          </Button>,
          <Button
            key="Excluir"
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal('excluir');
            }}
            disabled={updating || result.loading}
            loading={updating}
            hidden={result.data?.codigoContratoStatus !== ContractStatusEnum.Cancelado}
            data-permission={Permission.ContractDelete}
          >
            Excluir
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          {[ContractStatusEnum.EmProcessamento, ContractStatusEnum.Reconciliando].includes(
            result.data.codigoContratoStatus
          ) && (
            <>
              {/* TODO: QA: processo em andamento por mais de 1 hora */}
              <Alert icon={<InfoCircle size={16} />} title="Informação" color="yellow">
                Os eventos deste contrato ainda estão sendo processados. Em até 5 minutos o processo deverá
                ser finalizado.
              </Alert>
              <Space h="xl" />
            </>
          )}
          {exception && (
            <div>
              <Alert
                icon={<CircleX size={16} />}
                title={exception.title}
                color="red"
                withCloseButton
                onClose={() => setException(null)}
              >
                {exception?.data || <></>}
              </Alert>
              <Space h="lg" />
            </div>
          )}
          <DataView data={result.data} />
        </PageContent>
      )}
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default ContractView;
