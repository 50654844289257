/* eslint-disable react/destructuring-assignment */
import { Group, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { ItemToleranceType } from '../../../../../../../models/core/core.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';

type ViewProps = {
  data: ItemToleranceType[];
};

// TODO: App: 018 - move this to components (tolerance)
export default function ProposalItemToleranceView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Unidade de Medida',
      key: 'unidadeMedida',
      dataIndex: 'unidadeMedida',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => a.unidadeMedida.localeCompare(b.unidadeMedida),
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      dataIndex: 'quantidade',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
    },
    {
      title: 'Preço Unitário',
      key: 'preco',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => {
        const aValue = a.precoUnitario || 0;
        const bValue = b.precoUnitario || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ItemToleranceType) => <div>{formatCurrency(row.precoUnitario)}</div>,
    },

    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ItemToleranceType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
  ];

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={
            props.data?.[0]?.idPropostaTolerancia ? Feature.Home.Proposal.color : Feature.Home.Event.color
          }
          label="Tolerâncias"
          text="Todas as tolerâncias relacionadas à um produto ou serviço."
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        // eslint-disable-next-line dot-notation
        rowKey={(item: ItemToleranceType) => item.idPropostaTolerancia || item.idEventoTolerancia || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <PageViewAudit
                idCriadoPor={item.idCriadoPor}
                criadoPor={item.criadoPor}
                dataCriacao={item.dataCriacao}
                idModificadoPor={item.idModificadoPor || null}
                modificadoPor={item.modificadoPor || null}
                dataModificacao={item.dataModificacao || null}
                size="sm"
                gridSize={2}
              />
            );
          },
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
