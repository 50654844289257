import { Alert, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Atom, Businessplan, CircleX, Id, Tool } from 'tabler-icons-react';
import { ProposalGeneralProvider } from '../../../../../contexts/core/proposals/ProposalGeneral.context';
import {
  ClosingType,
  CompanyType,
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../../models/core/cache.type';
import { ProposalType } from '../../../../../models/core/proposals.type';
import { Feature, SessionStorageKey } from '../../../../../utils/constants.utils';
import CommissionFormView from './components/CommissionFormView';
import GeneralFormView from './components/GeneralFormView';
import ResidueFormView from './components/ResidueFormView';
import ServiceFormView from './components/ServiceFormView';

type ProposalFormViewProps = {
  referenceData: {
    companies: CompanyType[];
    closingTypeData: ClosingType[];
    entityTypeData: EntityTypeType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposal: ProposalType | null;
};

const FormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const refGeneralForm = useRef<any>();
  const refResidueForm = useRef<any>();
  const refServiceForm = useRef<any>();
  const refCommissionForm = useRef<any>();

  const [error, setError] = useState<any | null>(null);

  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <GeneralFormView
            ref={refGeneralForm}
            proposal={props.proposal}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Resíduos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Atom size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Resíduos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResidueFormView
            ref={refResidueForm}
            proposal={props.proposal}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Serviços',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Tool size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Serviços
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ServiceFormView
            ref={refServiceForm}
            proposal={props.proposal}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Commissoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Businessplan size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Comissões
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <CommissionFormView
            ref={refCommissionForm}
            proposal={props.proposal}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  useImperativeHandle(ref, () => ({
    validate(): any {
      let step = 'Geral';
      try {
        const generalData = refGeneralForm.current.validate();
        if (generalData === null) {
          return null;
        }

        // retrieve components data
        step = 'Resíduos';
        const residuos = refResidueForm.current.validate();
        step = 'Serviços';
        const servicos = refServiceForm.current.validate();
        step = 'Comissões';
        const comissoes = refCommissionForm.current.validate();

        return {
          ...generalData,
          residuos,
          servicos,
          comissoes,
        };
      } catch (ex: any) {
        setError({
          title: step,
          message: ex.message,
        });
        return null;
      }
    },
    clear() {
      sessionStorage.removeItem(SessionStorageKey.TempProposal);
      refGeneralForm.current.clear();
      refResidueForm.current.clear();
      refServiceForm.current.clear();
      refCommissionForm.current.clear();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      {error && (
        <>
          <Alert
            icon={<CircleX size={18} />}
            title={error.title}
            color="red"
            withCloseButton
            onClose={() => setError(null)}
          >
            {error.message}
          </Alert>
          <Space h="xl" />
        </>
      )}

      <ProposalGeneralProvider>
        <Tabs items={tabs} defaultActiveKey="Geral" />
      </ProposalGeneralProvider>
    </Paper>
  );
});

export default FormView;
