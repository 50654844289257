/* eslint-disable react/destructuring-assignment */
import { Paper, SimpleGrid, Space, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Notes, Tool } from 'tabler-icons-react';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import { ServiceType } from '../../../../../models/core/services.type';
import { Feature } from '../../../../../utils/constants.utils';

type FormViewData = {
  idServico: number | null;
  servico: string;
  descricao: string | null;
};

type FormViewProps = {
  save(data: FormViewData): void;
  service: ServiceType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      idServico: props.service?.idServico || null,
      servico: props.service?.servico || '',
      descricao: props.service?.descricao || '',
    },
    validate: {
      servico: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = async (formData: FormViewData) => {
    props.save({
      ...formData,
      servico: formData.servico.trim(),
      descricao: formData.descricao?.trim() || null,
    });
  };

  return (
    <form id="service-add-edit" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Service.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <TextInput
            icon={<Tool size={15} />}
            label="Serviço"
            placeholder="Nome do serviço"
            maxLength={50}
            required
            {...form.getInputProps('servico')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <Textarea
          icon={<Notes size={15} />}
          label="Descrição"
          placeholder="Descrição do serviço"
          autosize
          maxLength={500}
          {...form.getInputProps('descricao')}
        />
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
