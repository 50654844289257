/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import lodash from 'lodash';
import { MovementTypeGroup, MovementTypeGroupCode, MovementTypeStandard } from '../../models/core/cache.type';
import {
  EventDocumentType,
  EventMeasurementMovementType,
  EventMeasurementType,
  EventType,
} from '../../models/core/events.type';
import {
  ProposalCommissionType,
  ProposalResidueType,
  ProposalServiceType,
} from '../../models/core/proposals.type';
import { TipoCodigo2 } from './general';

type ClientSupplier = {
  idEntidade: number;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  cpf: string | null;
  nome: string | null;
  aceitaEncontroContas: boolean;
};

export type Statement = {
  key: number;
  idEvento: number;
  eventoTipo: string;
  residuo: ProposalResidueType | undefined;
  servico: ProposalServiceType | undefined;
  comissao: ProposalCommissionType | undefined;
  residuoServico: string;
  cotacao: any;
  item: string;
  grupo: string;
  descricao: string;
  documentos: EventDocumentType[] | undefined;
  data: Date;
  cliente: ClientSupplier;
  fornecedor: ClientSupplier;
  medicao: EventMeasurementType;
  movimentacao: EventMeasurementMovementType;
};

export type BillingSummary = {
  key: string;
  clienteFornecedor: ClientSupplier;
  tipo: string;
  despesa: number;
  receita: number;
  balanco: number;
  extrato: Statement[];
  faturamento: {
    tipo: string;
    despesa: number;
    receita: number;
    balanco: number;
  }[];
};

export type BillingStatement = {
  cliente: ClientSupplier;
  extrato: Statement[];
};

const buildStatementData = (event: EventType): BillingStatement => {
  const extrato: Statement[] = [];

  const buildClienteFornecedor = (item: any) => {
    return {
      idEntidade: item?.idFornecedor || item?.idCliente || item?.idEntidade,
      cnpj: item?.fornecedorCNPJ || item?.clienteCNPJ || item?.cnpj,
      razaoSocial: item?.fornecedorRazaoSocial || item?.clienteRazaoSocial || item?.razaoSocial,
      nomeFantasia: item?.fornecedorNomeFantasia || item?.clienteNomeFantasia || item?.nomeFantasia,
      cpf: item?.fornecedorCPF || item?.clienteCPF || item?.cpf,
      nome: item?.fornecedorNome || item?.clienteNome || item?.nome,
      aceitaEncontroContas: item?.fornecedorAceitaEncontroContas || item?.clienteAceitaEncontroContas,
    };
  };

  const cliente = buildClienteFornecedor(event.resumoJSON?.cliente);
  for (const fMedicao of event.faturamento!.medicoes.filter((x) => x.resumoJSON)) {
    const fResumoJSON = fMedicao.resumoJSON!;
    const { data } = fMedicao;
    const { residuo, servico, medicao, documentos, comissao } = fResumoJSON;

    for (const movimentacao of medicao!.movimentacoes) {
      const fornecedor = buildClienteFornecedor(movimentacao);

      let item = 'Geral';
      let cotacao;
      if (movimentacao?.idEventoAcondicionamento) {
        item = fResumoJSON.acondicionamento!.residuoAcondicionamento;
        cotacao = fResumoJSON.acondicionamento?.cotacao;
      } else if (movimentacao?.idEventoEquipamento) {
        item = fResumoJSON.equipamento!.residuoEquipamento;
        cotacao = fResumoJSON.equipamento?.cotacao;
      } else if (movimentacao?.idEventoVeiculo) {
        item = fResumoJSON.veiculo!.residuoVeiculo;
        cotacao = fResumoJSON.veiculo?.cotacao;
      } else if (movimentacao?.idEventoTratamento) {
        item = fResumoJSON.tratamento!.residuoTratamento;
        cotacao = fResumoJSON.tratamento?.cotacao;
      } else if (
        movimentacao?.idEventoDestinoFinal ||
        movimentacao.codigoMovimentacaoPadrao === MovementTypeStandard.Compra
      ) {
        item = fResumoJSON.destinoFinal!.residuoDestinoFinal;
        cotacao = fResumoJSON.destinoFinal?.cotacao;
      } else if (movimentacao?.idEventoServico) {
        item =
          fResumoJSON.servico!.residuoAcondicionamento ||
          fResumoJSON.servico!.residuoEquipamento ||
          'Fornecedor';
        cotacao = fResumoJSON.servico?.cotacao;
      }

      extrato.push({
        key: movimentacao.idEventoMedicaoMovimentacao || -1,
        idEvento: fMedicao.idEvento!,
        eventoTipo: TipoCodigo2[fMedicao.codigoEventoTipo],
        residuo: residuo as any,
        servico: servico as any,
        comissao: comissao as any,
        residuoServico: String(residuo?.idPropostaResiduo || servico?.idPropostaServico || '-'),
        grupo: movimentacao.movimentacaoFaturamentoTipo,
        item: `${
          movimentacao.codigoMovimentacaoGrupo
            ? `${MovementTypeGroupCode[movimentacao.codigoMovimentacaoGrupo as MovementTypeGroup]} - `
            : 'Geral - '
        }${item}`,
        cotacao,
        descricao: `${
          movimentacao.outroTipo ? `(O) ${movimentacao.outroTipo}` : movimentacao.movimentacaoTipo
        }`,
        documentos,
        data,
        cliente: movimentacao.codigoMovimentacaoPadrao === MovementTypeStandard.Venda ? fornecedor : cliente,
        fornecedor,
        medicao: medicao!,
        movimentacao,
      });
    }
  }

  return {
    cliente,
    extrato: extrato.sort((a: Statement, b: Statement) => {
      return new Date(a.data).valueOf() - new Date(b.data).valueOf();
    }),
  };
};

const buildSummaryData = (statementData: BillingStatement) => {
  const { cliente, extrato } = statementData;

  const fornecedorData = lodash.groupBy(extrato, 'fornecedor.idEntidade');
  const clienteData = lodash.groupBy(extrato, 'cliente.idEntidade');
  const clienteFornecedor = [];

  for (const key of Object.keys(clienteData)) {
    const items = clienteData[key];
    const movimentacoes = items.map((y) => y.movimentacao);
    clienteFornecedor.push({
      key,
      despesa: 0,
      receita: movimentacoes.reduce((a, b: any) => a + b.receita, 0),
    });
  }

  for (const key of Object.keys(fornecedorData)) {
    const items = fornecedorData[key];
    const movimentacoes = items.map((y) => y.movimentacao);
    clienteFornecedor.push({
      key,
      despesa: movimentacoes.reduce((a, b: any) => a + b.despesa, 0),
      receita: 0,
    });
  }

  const clienteFornecedorData = lodash.groupBy(clienteFornecedor, 'key');
  const resumo: BillingSummary[] = [];

  for (const key of Object.keys(clienteFornecedorData)) {
    const items = clienteFornecedorData[key];
    const despesa = items.reduce((a, b: any) => a + b.despesa, 0);
    const receita = items.reduce((a, b: any) => a + b.receita, 0);

    const novoResumo = {
      key,
      clienteFornecedor:
        Number(key) === cliente.idEntidade
          ? cliente
          : extrato.find((x) => x.fornecedor.idEntidade! === Number(key))!.fornecedor,
      despesa,
      receita,
      balanco: receita - despesa,
      extrato: statementData.extrato.filter(
        (x) => x.cliente.idEntidade === Number(key) || x.fornecedor.idEntidade === Number(key)
      ),
    };

    resumo.push({
      ...novoResumo,
      tipo:
        novoResumo.clienteFornecedor.idEntidade === Number(statementData.cliente.idEntidade)
          ? 'Cliente'
          : 'Fornecedor',
      faturamento: [],
    });
  }

  for (const item of resumo) {
    const grupoData = lodash.groupBy(item.extrato, 'grupo');
    const faturamento = [];

    for (const key of Object.keys(grupoData)) {
      const items = grupoData[key]; // .map((x) => x.movimentacao);
      const despesa = items
        .filter((x) => x.fornecedor.idEntidade === item.clienteFornecedor.idEntidade)
        .map((y) => y.movimentacao)
        .reduce((a, b: any) => a + b.despesa, 0);
      const receita = items
        .filter((x) => x.cliente.idEntidade === item.clienteFornecedor.idEntidade)
        .map((y) => y.movimentacao)
        .reduce((a, b: any) => a + b.receita, 0);

      faturamento.push({
        tipo: key,
        despesa,
        receita,
        balanco: receita - despesa,
      });
    }

    item.faturamento = faturamento;
  }

  return resumo.sort((a: BillingSummary, b: BillingSummary) => {
    return a.tipo.localeCompare(b.tipo);
  });
};

export { buildStatementData, buildSummaryData };
