/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Grid, SimpleGrid, Space, Switch, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Notes } from 'tabler-icons-react';
import DestinationSearch from '../../../../../../../components/core/DestinationSearch/DestinationSearch';
import { useEventDestinationContext } from '../../../../../../../contexts/core/events/EventDestination.context';
import { UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EventDestinationType } from '../../../../../../../models/core/events.type';
import { DestinationType } from '../../../../../../../models/core/destinations.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoDestinoFinal: number | null;
  residuoDestinoFinal: string;
  residuoDestinoFinalDescricao: string | null;

  autorizacaoAmbiental: boolean;
  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventDestinationType | null;
  item: EventDestinationType | null;
  disabled: boolean;
};

const EventDestinationFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const { setEventDestinationData } = useEventDestinationContext();
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoDestinoFinal: props.item?.idResiduoDestinoFinal || null,
      residuoDestinoFinal: props.item?.residuoDestinoFinal || '',
      residuoDestinoFinalDescricao: props.item?.residuoDestinoFinalDescricao || null,

      autorizacaoAmbiental: props.item?.autorizacaoAmbiental || false,
      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoDestinoFinal: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoDestinoFinal || ''}
    |${props.origItem?.autorizacaoAmbiental || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoDestinoFinal || ''}
    |${form.values.autorizacaoAmbiental || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): EventDestinationType | null {
      if (form.validate().hasErrors) {
        return null;
      }

      let { values } = form;
      if (props.origItem) {
        if (isModified()) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
          };
        }
      }

      const formItem: EventDestinationType = {
        ...values,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idEventoDestinoFinal: props.item?.idEventoDestinoFinal || -1,
      };

      formItem.observacao = formItem.observacao?.trim() || null;

      return formItem;
    },
    clear() {
      form.reset();
    },
  }));

  useEffect(() => {
    setEventDestinationData({
      idResiduoDestinoFinal: form.values.idResiduoDestinoFinal,
      autorizacaoAmbiental: form.values.autorizacaoAmbiental,
    });
  }, [form.values, setEventDestinationData]);

  return (
    <form id="event-destination" noValidate>
      <Grid columns={6}>
        <Grid.Col span={4}>
          <DestinationSearch
            label="Destino Final"
            formItem={form.getInputProps('residuoDestinoFinal')}
            idsToBeDisabled={[]}
            callback={(item: DestinationType | null) => {
              if (item) {
                form.setFieldValue('idResiduoDestinoFinal', item.idResiduoDestinoFinal);
                form.setFieldValue('residuoDestinoFinal', item.residuoDestinoFinal);
                form.setFieldValue('residuoEquipamentoDescricao', item.descricao);
              }
            }}
            disabled={props.disabled}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <div style={{ marginTop: 30 }}>
            <Switch
              label="Autorização Ambiental?"
              {...form.getInputProps('autorizacaoAmbiental', { type: 'checkbox' })}
              disabled={props.disabled}
            />
          </div>
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Observação"
          placeholder="Observação sobre o destino final"
          autosize
          maxLength={500}
          {...form.getInputProps('observacao')}
          disabled={props.disabled}
        />
      </SimpleGrid>
    </form>
  );
});

export default EventDestinationFormAddEdit;
