/* eslint-disable react/destructuring-assignment */
import { Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { VehicleType } from '../../../../../models/core/vehicles.type';
import { Feature } from '../../../../../utils/constants.utils';

type DataViewProps = {
  data: VehicleType;
};

export default function DataView(props: DataViewProps) {
  return (
    <>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Vehicle.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Veículo" text={props.data.residuoVeiculo} size="md" />
        </SimpleGrid>
        <Space h="xs" />
        <PageViewProperty label="Descrição" text={props.data.descricao || '-'} size="md" />
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
      />
    </>
  );
}
