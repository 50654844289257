/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { UserType } from '../../../../models/core/users.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import usersService from '../../../../services/core/users.service';
import dismisslasService from '../../../../services/core/dismissals.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { CompanyType } from '../../../../models/core/cache.type';
import cacheUtils from '../../../../utils/cache.utils';

function UserSearch() {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
  }>({ companyData: [] });

  const [data, setData] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      const companyData = await cacheUtils.listCompanies();
      const tempCompanyData = JSON.parse(JSON.stringify(companyData));
      if (currentUser.executivo) {
        tempCompanyData.splice(0, 0, { ...currentUser, idEmpresa: 0 });
      }

      setFilterData({
        companyData: tempCompanyData,
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(SessionStorageKey.UserSearch) || '[]'
      ) as UserType[];

      if (cacheResult.length > 0) {
        setData(cacheResult);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        filter({ idEmpresa: '', nome: '', sobrenome: '', email: '', status: '' });
      }
    };

    loadFilterData();
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.UserSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await usersService.search({
        idEmpresa: inputFilterData?.idEmpresa || null,
        nome: inputFilterData?.nome || null,
        sobrenome: inputFilterData?.sobrenome || null,
        email: inputFilterData?.email || null,
        status: inputFilterData?.status || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.UserSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Usuários',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar usuários.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: UserType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);
      let dismissal;

      switch (action) {
        case 'desligar':
          dismissal = await dismisslasService.insert({ idUsuario: item.idUsuario });
          // await filter(filterData!);
          navigate(`/dismissals/${dismissal.idUsuarioDesligamento}`, {
            state: {
              dismissal,
            },
          });
          showNotification({
            title: 'Usuários',
            message: `Usuário desligado com sucesso.`,
            color: 'green',
          });
          break;
        case 'visualizar':
          navigate(`/users/${item.idUsuario}`);
          return;
        case 'editar':
          navigate(`/users/${item.idUsuario}/edit`);
          return;
        default:
          break;
      }
    } catch (error: any) {
      showNotification({
        title: 'Usuários',
        message: error?.isBusinessException ? error.description : `Não foi possível desligar o usuário.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.User}
        title="Usuários"
        description="Procure, visualize, edite e desligue usuários."
        buttons={[]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter companies={filterData.companyData} filter={filter} clear={clear} loading={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default UserSearch;
