/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Line, LineConfig } from '@ant-design/plots';
import { Button, Grid, Group, LoadingOverlay, Paper, Select, Space, Stack, Text } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table as ITable, PlugConnectedX, Reload, SortDescendingNumbers, TableOff } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractType } from '../../../../../../../models/core/contracts.type';
import { ContractSingleMeasurementType, ReportItem } from '../../../../../../../models/core/report.type';
import reportsService from '../../../../../../../services/core/report.service';
import theme from '../../../../../../../theme';
import { Feature, RecurrenceData, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../../utils/formatter.utils';
import { getMonths } from '../../../../../../../utils/moment-recur.utils';

type FormViewProps = {
  data: ContractType;
  height: number | undefined;
};

type FilterData = {
  periodo: Date[];
  indicador: string;
};

type Response = {
  filterConfig: FilterData;
  data: ContractSingleMeasurementType[];
  chartConfig: LineConfig;
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Medições',
  description: 'Indicadores financeiros de resíduos e serviços com medição por mês.',
  cacheKey: `${SessionStorageKey.ContractStats}-{ID}-${ReportItem.ContratoIndividualMedicoes}`,
  filterConfig: {
    indicadorData: [
      {
        value: 'receita',
        label: `Receita`,
      },
      {
        value: 'despesa',
        label: `Despesa`,
      },
      {
        value: 'imposto',
        label: `Imposto`,
      },
      {
        value: 'balanco',
        label: `Balanço`,
      },
    ],
    indicador: 'balanco',
  },
  dataConfig: {
    exportable: true,
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleMeasurementType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) =>
          new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: ContractSingleMeasurementType) => formatDateToString(row.data),
      },
      {
        title: 'Resíduo',
        key: 'residuo',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) =>
          (a.residuoCliente || '-').localeCompare(b.residuoCliente || '-'),
        render: (row: ContractSingleMeasurementType) => {
          if (!row.idPropostaResiduo) {
            return '-';
          }

          return (
            <ProfileCardLink
              id={row.idResiduo.toString()}
              name={row.residuoCliente}
              nameSize="sm"
              description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
                row.residuoClientePerigoso ? 'Sim' : 'Não'
              }`}
              descriptionSize="xs"
              linkPrefix="residues"
              showLink
            />
          );
        },
      },
      {
        title: 'Serviço',
        key: 'servico',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) =>
          (a.servico || '-').localeCompare(b.servico || '-'),
        render: (row: ContractSingleMeasurementType) => {
          if (!row.idPropostaServico) {
            return '-';
          }

          return (
            <ProfileCardLink
              id={row.idServico.toString()}
              name={row.servico}
              nameSize="sm"
              linkPrefix="services"
              showLink
            />
          );
        },
      },
      {
        title: 'Receita',
        key: 'receita',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) => {
          const aValue = a.receita || 0;
          const bValue = b.receita || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleMeasurementType) => <div>{formatCurrency(row.receita)}</div>,
      },
      {
        title: 'Despesa',
        key: 'despesa',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) => {
          const aValue = a.despesa || 0;
          const bValue = b.despesa || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleMeasurementType) => <div>{formatCurrency(row.despesa)}</div>,
      },
      {
        title: 'Imposto',
        key: 'imposto',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) => {
          const aValue = a.imposto || 0;
          const bValue = b.imposto || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleMeasurementType) => <div>{formatCurrency(row.imposto)}</div>,
      },
      {
        title: 'Balanço',
        key: 'balanco',
        sorter: (a: ContractSingleMeasurementType, b: ContractSingleMeasurementType) => {
          const aValue = a.receita - a.despesa - a.imposto;
          const bValue = b.receita - b.despesa - b.imposto;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleMeasurementType) => {
          const total = row.receita - row.despesa - row.imposto;
          const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

          return <div style={{ color }}>{formatCurrency(total)}</div>;
        },
      },
    ],
  },
  chartConfig: {
    data: [],
    xField: 'mesAno',
    yField: 'indicador',
    seriesField: 'referencia',
    yAxis: {
      label: {
        formatter: (value) => {
          return formatCurrency(Number(value));
        },
      },
      nice: true,
    },
    smooth: true,
    legend: {
      position: 'right',
    },
    tooltip: {
      formatter: (el) => ({
        name: el.referencia,
        value: formatCurrency(el.indicador),
      }),
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  } as LineConfig,
};

export default function ContractSingleMeasurement(props: FormViewProps) {
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          periodo: [
            moment(props.data.dataInicial, 'yyyy-MM-DD').toDate(),
            moment(
              props.data.dataFinal > props.data.dataInicial
                ? props.data.dataFinal
                : props.data.dataFinalOriginal,
              'yyyy-MM-DD'
            ).toDate(),
          ],
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idContrato: props.data.idContrato,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.contratoIndividualMedicoes(searchCriteria);
    return data;
  };

  const buildChartData = async (data: ContractSingleMeasurementType[]) => {
    const dataInicial = form.values.periodo[0];
    const dataFinal = form.values.periodo[1]; // props.data.dataFinal);
    const meses = getMonths(dataInicial, dataFinal);

    const calcData: (ContractSingleMeasurementType & {
      mesAno: {
        key: number;
        order: number;
        year: number;
        month: number;
      };
      grupo: string;
    })[] = data.map((x) => {
      return {
        ...x,
        mesAno: getMonths(x.data, x.data)[0],
        grupo: `${x.idPropostaResiduo || '0'}-${x.idPropostaServico || '0'}`,
      };
    });

    // group by
    const finalData = [];
    const groupedData = lodash.groupBy(calcData, 'grupo');
    for (const key of Object.keys(groupedData)) {
      for (const mesAno of meses) {
        const items = groupedData[key];
        const item = items[0];
        const referencia = item.residuoCliente || item.servico;

        const receita = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.receita, 0);
        const despesa = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.despesa, 0);
        const imposto = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.imposto, 0);
        const balanco = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.balanco, 0);

        const finalItem = {
          ordem: mesAno.order,
          mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
          referencia,
          receita,
          despesa,
          imposto,
          balanco,
          indicador: 0,
        };
        finalItem.indicador = (finalItem as any)[form.values.indicador];

        finalData.push(finalItem);
      }
    }
    return finalData.sort((a, b) => a.ordem - b.ordem);
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const chartData = await buildChartData(data);

    return {
      filterConfig: form.values,
      data,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: chartData,
      },
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', props.data.idContrato.toString());
    try {
      load(() => {}, true);
    } catch (error) {}

    return () => {
      sessionStorage.removeItem(componentConfig.cacheKey);
    };
  }, []);

  useEffect(() => {
    const filterConfig = result.response?.filterConfig;
    const indicadorChanged = form.values.indicador !== filterConfig?.indicador;

    if (indicadorChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  const noDataView = () => {
    return (
      <Stack spacing="md" align="center">
        <TableOff color={theme?.colors?.primary?.[6]} size={32} />
        <Text>Sem dados para mostrar.</Text>
      </Stack>
    );
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Contract.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Contract.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Contrato - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Contract.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} overlayBlur={3} />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Select
                icon={<SortDescendingNumbers size={15} />}
                style={{ width: 150 }}
                label="Indicador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                data={componentConfig.filterConfig.indicadorData}
                {...form.getInputProps('indicador')}
              />
            </Group>
          </Paper>
          <Space h="md" />

          {result.error ? (
            <Stack spacing="md" align="center">
              <PlugConnectedX color="red" size={32} />
              <Text>{result.error}</Text>
            </Stack>
          ) : result.loaded ? (
            (result.response?.data || []).length > 0 ? (
              <Grid columns={4}>
                <Grid.Col span={4}>
                  <Paper shadow="xs" p="md" withBorder>
                    {(result.response?.data || []).length === 0 ? (
                      noDataView()
                    ) : (
                      <Line {...result.response?.chartConfig!} height={props.height} />
                    )}
                  </Paper>
                </Grid.Col>
              </Grid>
            ) : (
              <Paper shadow="xs" p="md" withBorder>
                {noDataView()}
              </Paper>
            )
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <Reload color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Esperando atualização</Text>
              </Stack>
            </Paper>
          )}
        </div>
      </Paper>
    </Paper>
  );
}
