import { call } from '../core-reference.service';
import {
  ServiceUpdateType,
  ServiceDeleteType,
  ServiceInsertType,
  ServiceSearchType,
  ServiceSelectType,
  ServiceType,
} from '../../models/core/services.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const servicesEndpoint = '/v1/servicos';

export default class ServicesService {
  static search = async (searchProps: ServiceSearchType): Promise<ServiceType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: servicesEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<ServiceType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: ServiceSelectType): Promise<ServiceType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${servicesEndpoint}/${selectProps.idServico}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: ServiceUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${servicesEndpoint}/${updateProps.idServico}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: ServiceDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${servicesEndpoint}/${deleteProps.idServico}`,
    };
    return call(request);
  };

  static insert = async (insertProps: ServiceInsertType): Promise<ServiceType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: servicesEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
