/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  Accordion,
  ActionIcon,
  Badge,
  Divider,
  Group,
  Modal,
  Paper,
  SimpleGrid,
  Space,
  Text,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import {
  Backhoe,
  Briefcase,
  Check,
  CurrencyReal,
  Eye,
  Id,
  Package,
  Recycle,
  Share,
  Target,
  Truck,
  X,
} from 'tabler-icons-react';
import {
  converterDestinacao,
  converterLocacao,
  converterTransporte,
} from '../../../../../../../../business/proposals/residue-plan';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import { UnitOfMeasureType } from '../../../../../../../../models/core/cache.type';
import {
  ProposalResiduePlanQuotationType,
  ProposalResiduePlanType,
  ProposalResidueType,
} from '../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../theme';
import { Feature } from '../../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../../utils/helper.utils';
import ResiduePlanQuotationSummary from '../../../../../components/ResiduePlanQuotationSummary';
import ResiduePlanQuotationDestinationView from './ResiduePlanGeneralView/ResiduePlanQuotationView/ResiduePlanQuotationDestinationView';
import ResiduePlanQuotationEquipmentView from './ResiduePlanGeneralView/ResiduePlanQuotationView/ResiduePlanQuotationEquipmentView';
import ResiduePlanQuotationPackagingView from './ResiduePlanGeneralView/ResiduePlanQuotationView/ResiduePlanQuotationPackagingView';
import ResiduePlanQuotationTreatmentView from './ResiduePlanGeneralView/ResiduePlanQuotationView/ResiduePlanQuotationTreatmentView';
import ResiduePlanQuotationVehicleView from './ResiduePlanGeneralView/ResiduePlanQuotationView/ResiduePlanQuotationVehicleView';

type ViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  data: ProposalResiduePlanType;
  residueData: ProposalResidueType;
};

export default function ResiduePlanQuotationView(props: ViewProps) {
  const [planQuotationItem, setPlanQuotationItem] = useState<ProposalResiduePlanQuotationType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanQuotationType> = [
    {
      title: '#',
      key: 'idPropostaResiduoPlanoCotacao',
      sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) => {
        const aValue = a.idPropostaResiduoPlanoCotacao;
        const bValue = b.idPropostaResiduoPlanoCotacao;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationType) => (
        <div>{row.idPropostaResiduoPlanoCotacao || '-'}</div>
      ),
    },
    {
      title: `Locação / ${props.residueData.estimativaUnidadeMedida2}`,
      key: 'locacao',
      render: (row: ProposalResiduePlanQuotationType) => {
        if (row.acondicionamentos.length === 0 && row.equipamentos.length === 0) {
          return '-';
        }

        const locacao = converterLocacao(
          props.referenceData.unitOfMeasures,
          props.residueData.estimativaIdUnidadeMedida2.toString(),
          props.data,
          row
        );

        const valido = locacao.every((x) => x.valido);
        const cobrarSemUso = locacao.some((x) => x.cobrarSemUso !== undefined && !x.cobrarSemUso);

        return (
          <Accordion chevronPosition="right">
            <Accordion.Item value="rent">
              <Accordion.Control>
                {valido && locacao.length > 0 ? (
                  <Text size="sm" color={cobrarSemUso ? 'orange' : 'primary'} weight="500">
                    {formatCurrency(locacao.map((x) => x.preco).reduce((a, b) => a + b, 0))}
                  </Text>
                ) : (
                  <Text size="sm" weight="500">
                    R$ ?
                  </Text>
                )}
              </Accordion.Control>
              <Accordion.Panel>
                {locacao.map((x) => {
                  return (
                    <div>
                      <Divider my="sm" variant="dotted" />
                      <Group spacing={3} className="custom-mantine-badge-group-down">
                        <x.icon size={16} />
                        {x.gerenciar ? <Briefcase size={16} /> : <></>}
                        {x.compartilhado ? <Share size={16} /> : <></>}
                        <Badge key={newGuid()} variant="outline" size="sm">
                          <Group spacing={3} className="custom-mantine-badge-group-up">
                            <div>{x.item}</div>
                            {x.valido ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                          </Group>
                        </Badge>
                      </Group>
                      <Group spacing={3}>
                        <CurrencyReal size={16} />
                        <Text size="sm">
                          {`${x.valido ? x.preco.toFixed(2) : '?'} / ${
                            props.residueData.estimativaUnidadeMedida2
                          }`}
                        </Text>
                      </Group>
                    </div>
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );
      },
    },
    {
      title: `Transporte`,
      key: 'transporte',
      render: (row: ProposalResiduePlanQuotationType) => {
        if (!row.veiculo) {
          return '-';
        }

        const transporte = converterTransporte(props.data, row);

        return (
          <Accordion chevronPosition="right">
            <Accordion.Item value="rent">
              <Accordion.Control>
                {transporte.valido ? (
                  <Text size="sm" weight="500">
                    {`${formatCurrency(transporte.preco)} / ${row.veiculo.frequenciaUnidadeMedida}`}
                  </Text>
                ) : (
                  <Text size="sm" weight="500">
                    R$ ?
                  </Text>
                )}
              </Accordion.Control>
              <Accordion.Panel>
                <div>
                  <Divider my="sm" variant="dotted" />
                  <Group spacing={3} className="custom-mantine-badge-group-down">
                    <transporte.icon size={16} />
                    <Badge key={newGuid()} variant="outline" size="sm">
                      <Group spacing={3} className="custom-mantine-badge-group-up">
                        <div>{transporte.item}</div>
                        {transporte.valido ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                      </Group>
                    </Badge>
                  </Group>
                  <Group spacing={3}>
                    <CurrencyReal size={16} />
                    <Text size="sm">
                      {`${transporte.valido ? transporte.preco.toFixed(2) : '?'} / ${
                        row.veiculo.frequenciaUnidadeMedida
                      }`}
                    </Text>
                  </Group>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );
      },
    },
    {
      title: `Destinação / ${props.residueData.estimativaUnidadeMedida1}`,
      key: 'destinacao',
      render: (row: ProposalResiduePlanQuotationType) => {
        if (!row.tratamento && !row.destinoFinal) {
          return '-';
        }

        const destinacao = converterDestinacao(
          props.referenceData.unitOfMeasures,
          props.residueData.estimativa,
          props.residueData.estimativaIdUnidadeMedida1.toString(),
          row
        );

        const valido = destinacao.every((x) => x.valido);

        return (
          <Accordion chevronPosition="right">
            <Accordion.Item value="rent">
              <Accordion.Control>
                {valido && destinacao.length > 0 ? (
                  <Text size="sm" weight="500">
                    {formatCurrency(
                      destinacao
                        .map((x) => x.preco)
                        .reduce(
                          (a, b, i) =>
                            a + (destinacao[i].receita !== undefined && destinacao[i].receita ? b * -1 : b),
                          0
                        )
                    )}
                  </Text>
                ) : (
                  <Text size="sm" weight="500">
                    R$ ?
                  </Text>
                )}
              </Accordion.Control>
              <Accordion.Panel>
                {destinacao.map((x) => {
                  return (
                    <div>
                      <Divider my="sm" variant="dotted" />
                      <Group spacing={3} className="custom-mantine-badge-group-down">
                        <x.icon size={16} />
                        <Badge key={newGuid()} variant="outline" size="sm">
                          <Group spacing={3} className="custom-mantine-badge-group-up">
                            <div>{x.item}</div>
                            {x.valido ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                          </Group>
                        </Badge>
                      </Group>
                      <Group spacing={3}>
                        <CurrencyReal size={16} />
                        <Text color={x.receita !== undefined && x.receita ? 'green' : 'red'} size="sm">
                          {`${x.valido ? x.preco.toFixed(2) : '?'} / ${
                            props.residueData.estimativaUnidadeMedida1
                          }`}
                        </Text>
                      </Group>
                    </div>
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );
      },
    },
    {
      title: 'Completo?',
      key: 'completo',
      dataIndex: 'completo',
      sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) =>
        a.completo === b.completo ? 0 : a.completo ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Primário?',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    // {
    //   title: 'Observação',
    //   key: 'observacao',
    //   sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) => {
    //     return (a.observacao || '').localeCompare(b.observacao || '');
    //   },
    //   render: (row: ProposalResiduePlanQuotationType) => (
    //     <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
    //   ),
    // },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanQuotationType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanQuotationItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  const buildTabs = () => {
    const tabs = [
      {
        key: 'Geral',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Id size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Geral
            </Text>
          </Group>
        ),
        children: (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <SimpleGrid cols={4}>
                <PageViewProperty
                  label="Completo?"
                  text={planQuotationItem!.completo ? 'Sim' : 'Não'}
                  size="sm"
                />
                <PageViewProperty
                  label="Primário?"
                  text={planQuotationItem!.primario ? 'Sim' : 'Não'}
                  size="sm"
                />
              </SimpleGrid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planQuotationItem!.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planQuotationItem!.idCriadoPor}
              criadoPor={planQuotationItem!.criadoPor}
              dataCriacao={planQuotationItem!.dataCriacao}
              idModificadoPor={planQuotationItem?.idModificadoPor || null}
              modificadoPor={planQuotationItem?.modificadoPor || null}
              dataModificacao={planQuotationItem?.dataModificacao || null}
              size="sm"
            />
          </div>
        ),
        forceRender: true,
      },
      {
        key: 'Acondicionamentos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Package size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Acondicionamentos
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanQuotationPackagingView
            refData={props.data.acondicionamentos}
            data={planQuotationItem?.acondicionamentos || []}
          />
        ),
        forceRender: true,
      },
      {
        key: 'Equipamentos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Backhoe size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Equipamentos
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanQuotationEquipmentView
            refData={props.data.equipamentos}
            data={planQuotationItem?.equipamentos || []}
          />
        ),
        forceRender: true,
      },
      {
        key: 'Veiculos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Truck size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Veículo
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanQuotationVehicleView
            refData={props.data.veiculo}
            data={planQuotationItem?.veiculo || null}
          />
        ),
        forceRender: true,
      },
      {
        key: 'Tratamentos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Recycle size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Tratamento
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanQuotationTreatmentView
            refData={props.data.tratamento}
            data={planQuotationItem?.tratamento || null}
          />
        ),
        forceRender: true,
      },
      {
        key: 'DestinoFinal',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Target size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Destino Final
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanQuotationDestinationView
            refData={props.data.destinoFinal}
            data={planQuotationItem?.destinoFinal || null}
          />
        ),
        forceRender: true,
      },
    ];
    return tabs;
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Resíduo/Plano/Cotação - Visualizar"
        size="60%"
      >
        {planQuotationItem ? (
          <Paper shadow="xs" p="md" withBorder>
            <PageSection
              size="lg"
              color={Feature.Home.Proposal.color}
              label="Cotação"
              text="Cotação para cada item do plano de coleta de resíduo."
            />
            <Space h="xs" />

            <Paper shadow="xs" p="md" withBorder>
              <Tabs items={buildTabs()} defaultActiveKey="Geral" />
            </Paper>
          </Paper>
        ) : (
          <div />
        )}
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Cotações"
        text="Cotações para este plano de coleta de resíduo."
      />
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data.cotacoes}
        columns={columns}
        rowKey={(item: ProposalResiduePlanQuotationType) => item.idPropostaResiduoPlanoCotacao || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (row) => <ResiduePlanQuotationSummary data={props.data} quotation={row} />,
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
