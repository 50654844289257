/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-promise-executor-return */
import { Affix, Badge, Button, Card, Group, LoadingOverlay, Paper, Text, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { closeAllModals, useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrowNarrowUp, Plus } from 'tabler-icons-react';
import { getPageFilterInitialValues } from '../../../../business/events/filter';
import {
  Audiencia,
  AudienciaCodigo,
  EventBulkUpdateEnum,
  PageFilter,
  Status,
  TipoCodigo,
} from '../../../../business/events/general';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { EventGeneralProvider } from '../../../../contexts/core/events/EventGeneral.context';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import {
  CompanyType,
  ContractStatusType,
  DocumentType,
  DocumentTypeGroup,
  EntityTypeType,
  EventRelationTypeData,
  EventStatusReasonType,
  EventStatusType,
  MovementType,
  ProposalStatusType,
  UnitOfMeasureType,
} from '../../../../models/core/cache.type';
import { EventSearchResponseType } from '../../../../models/core/events.type';
import { UserType } from '../../../../models/core/users.type';
import eventsService from '../../../../services/core/events.service';
import usersService from '../../../../services/core/users.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import EventAddEdit from '../EventAddEdit/EventAddEdit';
import EventView from '../EventView/EventView';
import DataView from './components/DataView';
import { Filter, FilterData } from './components/Filter';
import { Permission } from '../../../../models/core/departments.type';
import { validate } from '../../../../utils/permission.utils';

moment.locale('pt-BR');

function EventSearch() {
  const queryParams = new URLSearchParams(useLocation().search);
  const pageFilter = queryParams.get('filter') || '';
  const id = queryParams.get('id') || '';

  const modals = useModals();
  const [currentUser] = useCurrentUser();
  const [scroll, scrollTo] = useWindowScroll();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
    proposalStatusData: ProposalStatusType[];
    contractStatusData: ContractStatusType[];
    eventStatusData: EventStatusType[];
    userData: UserType[];
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
    documentTypeData: DocumentType[];
    movementTypeData: MovementType[];
    eventRelationTypeData: EventRelationTypeData[];
    eventStatusReasonData: EventStatusReasonType[];
  }>({
    companyData: [],
    proposalStatusData: [],
    contractStatusData: [],
    eventStatusData: [],
    userData: [],
    entityTypeData: [],
    unitOfMeasures: [],
    documentTypeData: [],
    movementTypeData: [],
    eventRelationTypeData: [],
    eventStatusReasonData: [],
  });

  const [data, setData] = useState<{ searchProps: FilterData | null; data: EventSearchResponseType[] }>({
    searchProps: null,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);

  const searchForUsers = async () => {
    // let resultData = JSON.parse(sessionStorage.getItem(SessionStorageKey.UserSearch) || '[]') as UserType[];
    // if (resultData.length === 0) {
    //   try {
    //     resultData = await usersService.search({
    //       limit: 1000,
    //       offset: 0,
    //     });
    //     sessionStorage.setItem(SessionStorageKey.UserSearch, JSON.stringify(resultData));
    //   } catch (error) {
    //     resultData = [currentUser];
    //   }
    // }
    // return resultData;
    const resultData = await usersService.search({
      limit: 1000,
      offset: 0,
    });
    return resultData;
  };

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      setFilterData({
        companyData: await cacheUtils.listCompanies(),
        proposalStatusData: await cacheUtils.listProposalStatuses(),
        contractStatusData: await cacheUtils.listContractStatuses(),
        eventStatusData: await cacheUtils.listEventStatuses(),
        userData: await searchForUsers(),
        entityTypeData: await cacheUtils.listEntityTypes(),
        unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
        documentTypeData: (await cacheUtils.listDocumentTypes()).filter(
          (x) => x.codigoDocumentoGrupo === DocumentTypeGroup.Event || !x.codigoDocumentoGrupo
        ),
        movementTypeData: await cacheUtils.listMovementTypes(),
        eventRelationTypeData: await cacheUtils.listEventRelationTypes(),
        eventStatusReasonData: await cacheUtils.listEventStatusReasonTypes(),
      });
      setLoading(false);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(`${SessionStorageKey.EventSearch}-${pageFilter}`) || '[]'
      ) as EventSearchResponseType[];

      if (cacheResult.length > 0) {
        setData({ searchProps: null, data: cacheResult });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        filter(
          {
            idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
            listaAudiencia: [],
            listaTipo: [],
            listaStatus: [],
            listaResponsavel: [],
            fonte: undefined,
            idContrato: undefined,
            idEntidade: undefined,
            idProposta: undefined,
            // dataInicial: null,
            // dataFinal: null,
            idDocumentoTipo: null,
            numeroDocumento: null,
            ...getPageFilterInitialValues(pageFilter),
          },
          100
        );
      }

      setPendingModal(id !== '');
    };

    loadFilterData();
  }, []);

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(`${SessionStorageKey.EventSearch}-${pageFilter}`) || '[]'
    ) as EventSearchResponseType[];

    if (cacheResult.length > 0) {
      setData({ searchProps: null, data: cacheResult });
    } else {
      setData({ searchProps: null, data: [] });
    }
  }, [pageFilter]);

  const clear = () => {
    sessionStorage.removeItem(`${SessionStorageKey.EventSearch}-${pageFilter}`);
    setData({ searchProps: data.searchProps, data: [] });
  };

  const filter = async (inputFilterData: FilterData, totalItems: number | null) => {
    const defaultLimit = 100;

    try {
      setLoading(true);

      const searchProps = {
        ...inputFilterData,
        idContrato: inputFilterData.idContrato?.toString() || null,
        idEntidade: inputFilterData.idEntidade?.toString() || null,
        idProposta: inputFilterData.idProposta?.toString() || null,
        dataInicial: inputFilterData.dataInicial
          ? moment(inputFilterData.dataInicial).format('yyyy-MM-DD')
          : null,
        dataFinal: inputFilterData.dataFinal ? moment(inputFilterData.dataFinal).format('yyyy-MM-DD') : null,
        numeroDocumento:
          !inputFilterData.numeroDocumento || inputFilterData.numeroDocumento.trim() === ''
            ? null
            : inputFilterData.numeroDocumento,
        limit: totalItems || defaultLimit,
        offset: 0,
      };

      const result = await eventsService.search(searchProps);

      clear();
      if ((totalItems || defaultLimit) <= defaultLimit) {
        sessionStorage.setItem(`${SessionStorageKey.EventSearch}-${pageFilter}`, JSON.stringify(result));
      }
      setData({ searchProps: inputFilterData, data: result });
    } catch (error: any) {
      showNotification({
        title: 'Eventos',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar eventos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (
    items: EventSearchResponseType[],
    action: string,
    value: string | null,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    const item = items?.[0];
    let dataItemIndex;
    let dataItem;

    try {
      let tempData;
      let completedAction;
      setLoading(true);

      switch (action) {
        case 'excluir':
          await eventsService.delete({ idEvento: item.idEvento });
          setData({ ...data, data: data.data.filter((x) => x.idEvento !== item.idEvento) });
          completedAction = 'excluído';
          break;
        case 'importar':
          tempData = await eventsService.select({ idEvento: item.idEvento });
          if (
            tempData.codigoEventoAudiencia === AudienciaCodigo.Financeiro &&
            (tempData.codigoEventoTipo === TipoCodigo.Coleta ||
              tempData.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
              tempData.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar)
          ) {
            tempData.codigoEventoAudiencia = AudienciaCodigo.Operacional;
          }
          tempData.codigoEventoStatus = Status.Pendente;
          delete tempData.resumoJSON?.veiculoCompartilhado;

          sessionStorage.setItem(SessionStorageKey.TempEvent, JSON.stringify(tempData));
          newEvent();
          completedAction = 'importado';
          break;
        case 'callback-importar':
          closeAllModals();
          newEvent();
          completedAction = 'importado';
          break;
        case 'visualizar':
          closeAllModals();
          viewEvent(item);
          return;
        case 'editar':
          closeAllModals();
          modals.openModal({
            title: (
              <Group>
                <Text>Evento - Editar (#{item.idEvento})</Text>
                {item.obsoleto ? (
                  <Badge variant="outline" color="red" size="sm">
                    obsoleto
                  </Badge>
                ) : (
                  ''
                )}
              </Group>
            ),
            size: '70%',
            closeOnClickOutside: false,
            children: (
              <EventGeneralProvider>
                <EventAddEdit
                  idEvento={item.idEvento}
                  callback={confirmActionResult}
                  referenceData={filterData}
                />
              </EventGeneralProvider>
            ),
          });
          return;
        case 'callback':
          closeAllModals();
          dataItemIndex = data.data.findIndex((x) => x.idEvento === item.idEvento);
          if (dataItemIndex > -1) {
            dataItem = data.data[dataItemIndex];
            data.data[dataItemIndex] = { ...dataItem, ...item };
          } else {
            data.data.push(item);
          }
          if (validate(Permission.EventView, currentUser.permissoes)) {
            viewEvent(item);
          }
          setData(JSON.parse(JSON.stringify(data)));
          return;
        case 'imprimir':
          window.open(`${Feature.Home.Event.link}/${item.idEvento}/print`, '_blank');
          completedAction = 'enviado para impressão';
          return;
        case 'callback-remover':
          closeAllModals();
          if (!item) {
            return;
          }
          dataItemIndex = data.data.findIndex((x) => x.idEvento === item.idEvento);
          if (dataItemIndex > -1) {
            dataItem = data.data[dataItemIndex];
            data.data[dataItemIndex] = { ...dataItem, ...item };
          }
          setData({
            ...data,
            data: data.data.filter((x) => (pageFilter !== '' ? x.idEvento !== item.idEvento : true)),
          });
          return;
        case 'bulk-excluir':
        case 'bulk-responsavel':
        case 'bulk-status':
          try {
            await eventsService.bulkUpdate({
              acao: action.split('-')[1] as EventBulkUpdateEnum,
              listaIdEvento: items.map((y) => y.idEvento),
              idResponsavel: action === 'bulk-responsavel' ? value : null,
              codigoEventoStatus: action === 'bulk-status' ? (value as Status) : null,
            });
            if (
              action === 'bulk-excluir' ||
              ((value as Status) === Status.PendenteMedicao && pageFilter === PageFilter.Operacional) ||
              ((value as Status) === Status.Pendente && pageFilter === PageFilter.Financeiro)
            ) {
              setData({
                ...data,
                data: JSON.parse(
                  JSON.stringify(data.data.filter((x) => !items.map((y) => y.idEvento).includes(x.idEvento)))
                ),
              });
              showNotification({
                title: 'Eventos',
                message: `Evento(s) atualizados com sucesso.`,
                color: 'green',
              });
            } else {
              for (const x of data.data) {
                if (items.map((y) => y.idEvento).includes(x.idEvento)) {
                  if (action === 'bulk-responsavel') {
                    x.idResponsavel = value;
                    x.responsavel =
                      filterData.userData.find((u) => u.idUsuario === value)?.nomeCompleto || '?';
                  } else if (action === 'bulk-status') {
                    x.codigoEventoStatus = value as Status;
                    x.eventoStatus =
                      filterData.eventStatusData.find((s) => s.codigoEventoStatus === value)?.eventoStatus ||
                      '';
                  } else {
                    return;
                  }
                }
              }
              setData(JSON.parse(JSON.stringify(data)));
              showNotification({
                title: 'Eventos',
                message: `Evento(s) atualizado(s) com sucesso.`,
                color: 'green',
              });
            }
          } catch (error: any) {
            showNotification({
              title: 'Eventos',
              message: error?.isBusinessException
                ? error.description
                : `Não foi possível alterar o(s) evento(s).`,
              color: 'red',
            });
          }

          return;
        default:
          break;
      }

      showNotification({
        title: 'Eventos',
        message: `Evento ${completedAction} com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Eventos',
        message: error?.isBusinessException ? error.description : `Não foi possível ${action} o evento.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const newEvent = () => {
    modals.openModal({
      title: 'Evento - Adicionar',
      size: '70%',
      closeOnClickOutside: false,
      children: (
        <EventGeneralProvider>
          <EventAddEdit idEvento={undefined} callback={confirmActionResult} referenceData={filterData} />
        </EventGeneralProvider>
      ),
      onClose: () => sessionStorage.removeItem(SessionStorageKey.TempEvent),
    });
  };

  // TODO: 000 - if id set by query param, obsoleto badge is not showing up as the data is loaded in the event-view component
  const viewEvent = (item: EventSearchResponseType) => {
    modals.openModal({
      title: (
        <Group>
          <Text>Evento - Visualizar (#{item.idEvento})</Text>
          {item.obsoleto ? (
            <Badge variant="outline" color="red" size="sm">
              obsoleto
            </Badge>
          ) : (
            ''
          )}
        </Group>
      ),
      size: '70%',
      closeOnClickOutside: false,
      children: (
        <EventView referenceData={filterData} idEvento={item.idEvento} callback={confirmActionResult} />
      ),
    });
  };

  if (pendingModal) {
    setPendingModal(false);
    if (!Number.isNaN(Number(id))) {
      confirmActionResult([JSON.parse(JSON.stringify({ idEvento: id }))], 'visualizar', null, true);
    }
  }

  return (
    <Card key={`data-view-${pageFilter || ''}`}>
      <PageHeader
        feature={Feature.Home.Event}
        title={`Eventos${
          !pageFilter
            ? ''
            : pageFilter === PageFilter.Comercial
            ? ` (${Audiencia.Comercial})`
            : pageFilter === PageFilter.Financeiro
            ? ` (${Audiencia.Financeiro})`
            : ` (${Audiencia.Operacional})`
        }`}
        description="Gerencie eventos."
        buttons={[
          <Button
            key="Adicionar"
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={newEvent}
            data-permission={Permission.EventAdd}
          >
            Adicionar
          </Button>,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Filter
            companies={filterData.companyData}
            eventStatuses={filterData.eventStatusData}
            users={filterData.userData}
            documents={filterData.documentTypeData}
            filter={filter}
            clear={clear}
            loading={loading}
            open={(idEvento: number) => {
              confirmActionResult([JSON.parse(JSON.stringify({ idEvento }))], 'visualizar', null, true);
            }}
          />
          <Paper shadow="xs" p="md" withBorder>
            <DataView
              // key={getHash(data.searchProps)}
              data={data.data}
              // showDocs={!!data.searchProps?.numeroDocumento}
              showDocs
              confirmActionResult={confirmActionResult}
              users={filterData.userData}
            />
          </Paper>
        </div>
      </PageContent>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default EventSearch;
