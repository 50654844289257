import axios from 'axios';
import lodash from 'lodash';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { BancoType, CEPType, CNPJType, EstadoType, MunicipioType } from '../../models/utils/brasilapi.type';
import { LocalStorageKey, SessionStorageKey } from '../../utils/constants.utils';

const instance = axios.create({
  method: 'get',
  baseURL: `https://brasilapi.com.br/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const call = async (request: AxiosCustomRequestType): Promise<any> => {
  try {
    const { status, data } = await instance.request({
      method: request.method,
      url: request.url,
      timeout: Number(request.timeout || 15000),
      data: request?.data,
      params: request?.params,
      headers: {
        ...(request.headers || {}),
      },
    });
    return { status, data };
  } catch (error: any) {
    throw error?.response?.data || error;
  }
};

const procurarCEP = async (cep: string): Promise<CEPType> => {
  const request: AxiosCustomRequestType = {
    method: 'GET',
    url: `cep/v2/${cep}`,
  };
  return (await call(request))?.data;
};

const procurarCNPJ = async (cnpj: string): Promise<CNPJType> => {
  const request: AxiosCustomRequestType = {
    method: 'GET',
    url: `cnpj/v1/${cnpj}`,
  };
  return (await call(request))?.data;
};

const procurarMunicipios = async (codigoEstado: string): Promise<MunicipioType[]> => {
  const sessionStorageKey = SessionStorageKey.BrasilAPICities.replace('{stateCode}', codigoEstado);
  const cities = JSON.parse(sessionStorage.getItem(sessionStorageKey) || 'null') as MunicipioType[];
  if (cities) {
    return cities;
  }

  const request: AxiosCustomRequestType = {
    method: 'GET',
    url: `ibge/municipios/v1/${codigoEstado}`,
  };
  const data = (await call(request))?.data as MunicipioType[];
  const transformedData = data
    .map((x) => ({ ...x, nome: lodash.startCase(x.nome.toLowerCase()) }))
    .sort((a: MunicipioType, b: MunicipioType) => {
      return a.nome.localeCompare(b.nome);
    });

  sessionStorage.setItem(sessionStorageKey, JSON.stringify(transformedData));
  return transformedData;
};

const listarBancos = async (): Promise<BancoType[]> => {
  const banks = JSON.parse(localStorage.getItem(LocalStorageKey.BrasilAPIBanks) || 'null') as BancoType[];
  if (banks) {
    return banks;
  }

  const request: AxiosCustomRequestType = {
    method: 'GET',
    url: `banks/v1`,
  };

  const data = (await call(request))?.data as BancoType[];
  const sortedData = data.sort((a: BancoType, b: BancoType) => {
    const aValue = a.code || 999;
    const bValue = b.code || 999;
    if (aValue === bValue) {
      return 0;
    }
    return aValue > bValue ? 1 : -1;
  });

  localStorage.setItem(LocalStorageKey.BrasilAPIBanks, JSON.stringify(sortedData));
  return sortedData;
};

const listarEstados = async (): Promise<EstadoType[]> => {
  const states = JSON.parse(localStorage.getItem(LocalStorageKey.BrasilAPIStates) || 'null') as EstadoType[];
  if (states) {
    return states;
  }

  const request: AxiosCustomRequestType = {
    method: 'GET',
    url: `ibge/uf/v1`,
  };

  const data = (await call(request))?.data as EstadoType[];
  const sortedData = data.sort((a: EstadoType, b: EstadoType) => {
    return a.nome.localeCompare(b.nome);
  });
  localStorage.setItem(LocalStorageKey.BrasilAPIStates, JSON.stringify(sortedData));
  return sortedData;
};

export { procurarCEP, procurarCNPJ, procurarMunicipios, listarBancos, listarEstados };
