/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { Group, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { EntityTreatmentType } from '../../../../../../models/core/entities.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatTimestampToString } from '../../../../../../utils/formatter.utils';

type ViewProps = {
  data: EntityTreatmentType[];
};

export default function TreatmentView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Tratamento',
      key: 'residuoTratamento',
      dataIndex: 'residuoTratamento',
      sorter: (a: EntityTreatmentType, b: EntityTreatmentType) =>
        a.residuoTratamento.localeCompare(b.residuoTratamento),
    },
    {
      title: 'Descrição',
      key: 'residuoTratamentoDescricao',
      sorter: (a: EntityTreatmentType, b: EntityTreatmentType) =>
        (a.residuoTratamentoDescricao || '').localeCompare(b.residuoTratamentoDescricao || ''),
      render: (row: EntityTreatmentType) => (
        <TableCellEllipsis label={row?.residuoTratamentoDescricao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: EntityTreatmentType, b: EntityTreatmentType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: EntityTreatmentType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Data Criação',
      key: 'dataCriacao',
      sorter: (a: EntityTreatmentType, b: EntityTreatmentType) => {
        const aValue = a.dataCriacao.valueOf();
        const bValue = b.dataCriacao.valueOf();
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityTreatmentType) => formatTimestampToString(row.dataCriacao),
    },
  ];

  return (
    <div>
      <Group>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Tratamentos" text="" />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        // size="small"
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EntityTreatmentType) => item.idEntidadeResiduoTratamento || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
