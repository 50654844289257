/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';
import { BrandCodesandbox } from 'tabler-icons-react';
import { Alert, Burger, Group, Header as MantineHeader, MediaQuery } from '@mantine/core';
import Menu from './Menu/Menu';
import theme from '../../../theme';

type HeaderProps = {
  setOpened(status: boolean): void;
};

function Header(props: HeaderProps) {
  const [opened, setOpened] = useState(false);
  const environment = process.env.REACT_APP_CUSTOM_NODE_ENV || process.env.NODE_ENV;

  return (
    <MantineHeader height={60}>
      <Group sx={{ height: '100%' }} px={20} position={environment === 'production' ? 'right' : 'apart'}>
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={() => {
              setOpened(!opened);
              props.setOpened(!opened);
            }}
            size="sm"
            color={theme?.colors?.primary?.[6] || 'gray'}
            mr="xl"
          />
        </MediaQuery>
        {environment !== 'production' && (
          <Alert icon={<BrandCodesandbox size={16} />} color="yellow">
            {environment}
          </Alert>
        )}
        <Menu />
      </Group>
    </MantineHeader>
  );
}

export default Header;
