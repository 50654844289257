import { createContext, ReactNode, useContext, useState } from 'react';
import { Fonte, ReferenciaCodigo, TipoCodigo } from '../../../business/events/general';
import { ProposalResidueType, ProposalServiceType } from '../../../models/core/proposals.type';

type EventGeneralData = {
  codigoEventoTipo: TipoCodigo | null;
  eventoTipo: string | null;
  fonte: Fonte | null;
  codigoEventoReferencia: ReferenciaCodigo | null;
  idEventoReferencia1: number | null;
  idEventoReferencia2: number | null;
  eventoReferenciaPersonalizado: boolean;
  propostaResiduoData: ProposalResidueType | null;
  propostaServicoData: ProposalServiceType | null;
};

interface EventGeneralContextProps {
  eventGeneralData: EventGeneralData;
  setEventGeneralData(eventGeneralContextData: EventGeneralData): void;
}
const EventGeneralContext = createContext<EventGeneralContextProps>({} as EventGeneralContextProps);

// eslint-disable-next-line react/function-component-definition
export const EventGeneralProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventGeneralData, setEventGeneralData] = useState<EventGeneralData>({} as EventGeneralData);
  return (
    <EventGeneralContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventGeneralData,
        setEventGeneralData,
      }}
    >
      {children}
    </EventGeneralContext.Provider>
  );
};

export type { EventGeneralData };

export function useEventGeneralContext() {
  return useContext(EventGeneralContext);
}
