type AuditType = {
  idCriadoPor: string;
  criadoPor: string;
  dataCriacao: Date;
  idModificadoPor: string | null;
  modificadoPor: string | null;
  dataModificacao: Date | null;
};

export enum Action {
  Nothing = 'nothing',
  Add = 'add',
  Modify = 'modify',
  Delete = 'delete',
}

type ItemToleranceType = {
  id?: string;
  action?: Action;
  idPropostaTolerancia?: number;
  idEventoTolerancia?: number;
  idProposta?: number;
  idEvento?: number;
  idTemporaria?: string;
  idUnidadeMedida: number;
  unidadeMedida: string;
  unidadeMedidaSigla: string | null;
  quantidade: number;
  precoUnitario: number;
  observacao: string | null;
} & AuditType;

export type { AuditType, ItemToleranceType };
