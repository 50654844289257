/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Building,
  BuildingBank,
  DeviceFloppy,
  DeviceMobile,
  Id,
  InfoCircle,
  Key,
  Mail,
  Notes,
  QuestionMark,
  ReportMoney,
  Search,
  User,
  Wallet,
} from 'tabler-icons-react';
import {
  ActionIcon,
  Button,
  Divider,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  SegmentedControl,
  Select,
  SimpleGrid,
  Space,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { EntityPaymentMethodType } from '../../../../../../../models/core/entities.type';
import {
  buildFakeAuditObject,
  isCNPJValid,
  isCPFValid,
  newGuid,
} from '../../../../../../../utils/helper.utils';
import { PaymentMethodType } from '../../../../../../../models/core/cache.type';
import theme from '../../../../../../../theme';
import { procurarCNPJ } from '../../../../../../../services/utils/brasilapi.service';
import { BancoType } from '../../../../../../../models/utils/brasilapi.type';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Feature } from '../../../../../../../utils/constants.utils';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewData = {
  action: Action;
  id: string;
  recebimento: string;
  codigoFormaPagamento: string;
  formaPagamento: string;
  prazoPagamentoDias: number | null;
  condicaoPagamento: string | null;
  cnpj: string | null;
  cpf: string | null;
  nome: string | null;
  banco: string | null;
  agencia: string | null;
  contaCorrente: string | null;
  digitoVerificador: string | null;
  tipoChavePIX?: string | null;
  chavePix: string | null;
  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    paymentMethodTypeData: PaymentMethodType[];
    bancoTypeData: BancoType[];
  };
  origItem: EntityPaymentMethodType | null;
  item: EntityPaymentMethodType | null;
  idEntidade: number | undefined;
  callback(item: EntityPaymentMethodType | null, action: string, confirmed: boolean): void;
};

export default function PaymentMethodFormAddEdit(props: FormViewProps) {
  const TIPO_PF = 'PF';
  const TIPO_PJ = 'PJ';
  const [tipo, setTipo] = useState(props.item?.cpf ? TIPO_PF : TIPO_PJ);
  const [searchingCNPJ, setSearchingCNPJ] = useState(false);
  const [enabledCNPJFields, setEnabledCNPJFields] = useState(!!props.item?.nome);

  const getTipoChavePIX = (chavePix: string) => {
    if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(chavePix)) {
      return 'cnpj';
    }
    if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(chavePix)) {
      return 'cpf';
    }
    if (/^\(\d{2}\) \d{4,5}-\d{4}$/.test(chavePix)) {
      return 'celular';
    }
    if (/^\S+@\S+$/.test(chavePix)) {
      return 'email';
    }
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(chavePix)) {
      return 'chave';
    }
    return null;
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),
      recebimento: props.item?.recebimento.toString() || true.toString(),
      codigoFormaPagamento: props.item?.codigoFormaPagamento || '',
      formaPagamento: props.item?.formaPagamento || '',
      prazoPagamentoDias: props.item?.prazoPagamentoDias || null,
      condicaoPagamento: props.item?.condicaoPagamento || '',
      cnpj: props.item?.cnpj || '',
      cpf: props.item?.cpf || '',
      nome: props.item?.nome || '',
      banco: props.item?.banco || '',
      agencia: props.item?.agencia || '',
      contaCorrente: props.item?.contaCorrente || '',
      digitoVerificador: props.item?.digitoVerificador || '',
      tipoChavePIX: getTipoChavePIX(props.item?.chavePix || ''),
      chavePix: props.item?.chavePix || '',
      observacao: props.item?.observacao || '',
    },
    validate: {
      codigoFormaPagamento: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      cnpj: (value: string | null) => {
        if (tipo === TIPO_PJ) {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
          if (!/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value || '')) {
            return 'Formato inválido (Ex: 00.000.000/0000-00)';
          }
          if (value?.trim() !== '' && !isCNPJValid(value || '')) {
            return 'CNPJ inválido';
          }
        }
        return null;
      },
      cpf: (value: string | null) => {
        if (tipo === TIPO_PF) {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
          if (!/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value || '')) {
            return 'Formato inválido (Ex: 000.000.000-00)';
          }
          if (value?.trim() !== '' && !isCPFValid(value || '')) {
            return 'CPF inválido';
          }
        }
        return null;
      },
      nome: (value: string | null) => {
        if (value?.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      tipoChavePIX: (value, values: FormViewData) => {
        if (values.codigoFormaPagamento === 'PX' && values.recebimento === 'true') {
          if (!value || value === '') {
            return 'Campo obrigatório';
          }
        }
        return null;
      },
      chavePix: (value: string | null, values: FormViewData) => {
        if (values.codigoFormaPagamento === 'PX' && values.recebimento === 'true') {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
          const calculatedTipoChavePIX = getTipoChavePIX(value || '');
          if (values.tipoChavePIX !== calculatedTipoChavePIX) {
            let format = '?';
            if (values.tipoChavePIX === 'cnpj') {
              format = '00.000.000/0000-00';
            } else if (values.tipoChavePIX === 'cpf') {
              format = '000.000.000-00';
            } else if (values.tipoChavePIX === 'celular') {
              format = '(00) 00000-0000';
            } else if (values.tipoChavePIX === 'email') {
              format = 'nome@dominio.com';
            } else if (values.tipoChavePIX === 'chave') {
              format = '6a2f41a3-c54c-fce8-32d2-0324e1c32e22';
            }
            return `Formato inválido (Ex: ${format})`;
          }

          if (values.tipoChavePIX === 'cnpj' && !isCNPJValid(value || '')) {
            return 'CNPJ inválido';
          }
          if (values.tipoChavePIX === 'cpf' && !isCPFValid(value || '')) {
            return 'CPF inválido';
          }
        }
        return null;
      },
      banco: (value: string | null, values: FormViewData) => {
        if (values.codigoFormaPagamento === 'TB' && values.recebimento === 'true') {
          if (value === '') {
            return 'Campo obrigatório';
          }
        }
        return null;
      },
      agencia: (value: string | null, values: FormViewData) => {
        if (values.codigoFormaPagamento === 'TB' && values.recebimento === 'true') {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
          if (!/^\d{4}$/.test(value || '')) {
            return 'Formato inválido (Ex: 0000)';
          }
        }
        return null;
      },
      contaCorrente: (value: string | null, values: FormViewData) => {
        if (values.codigoFormaPagamento === 'TB' && values.recebimento === 'true') {
          if (value?.trim() === '') {
            return 'Campo obrigatório';
          }
        }
        return null;
      },
    },
  });

  const searchCNPJ = async () => {
    if (!form.validateField('cnpj').hasError) {
      const cnpj = form.values.cnpj || '';
      setSearchingCNPJ(true);
      try {
        const cnpjData = await procurarCNPJ(cnpj.replace(/\D+/g, ''));
        form.values.nome = cnpjData.razao_social;
        form.validateField('nome');
      } catch (error: any) {
        form.values.nome = '';
        showNotification({
          title: `Entidade - ${!props.item?.idEntidadeFormaPagamento ? 'Adicionar' : 'Editar'}`,
          message: 'CNPJ não encontrado.',
          color: 'red',
        });
      } finally {
        setSearchingCNPJ(false);
        setEnabledCNPJFields(true);
      }
    }
  };

  const isModified = (): boolean => {
    const origData = `${props.origItem?.codigoFormaPagamento || ''}
    |${props.origItem?.prazoPagamentoDias || ''}
    |${props.origItem?.recebimento || ''}
    |${props.origItem?.condicaoPagamento || ''}
    |${props.origItem?.cnpj || ''}
    |${props.origItem?.cpf || ''}
    |${props.origItem?.nome || ''}
    |${props.origItem?.banco || ''}
    |${props.origItem?.agencia || ''}
    |${props.origItem?.contaCorrente || ''}
    |${props.origItem?.digitoVerificador || ''}
    |${props.origItem?.chavePix || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.codigoFormaPagamento || ''}
    |${form.values.prazoPagamentoDias || ''}
    |${form.values.recebimento || ''}
    |${form.values.condicaoPagamento || ''}
    |${form.values.cnpj || ''}
    |${form.values.cpf || ''}
    |${form.values.nome || ''}
    |${form.values.banco || ''}
    |${form.values.agencia || ''}
    |${form.values.contaCorrente || ''}
    |${form.values.digitoVerificador || ''}
    |${form.values.chavePix || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          recebimento: values.recebimento,
          action: Action.Nothing,
        };
      }
    }

    values.formaPagamento =
      props.referenceData.paymentMethodTypeData.find(
        (x) => x.codigoFormaPagamento === values.codigoFormaPagamento
      )?.formaPagamento || '';

    const formItem: EntityPaymentMethodType = {
      ...values,
      ...buildFakeAuditObject(),
      recebimento: values.recebimento === true.toString(),
      idEntidade: props.idEntidade || -1,
    };

    if (tipo === TIPO_PF) {
      formItem.cnpj = null;
    } else {
      formItem.cpf = null;
    }

    if (
      formItem.codigoFormaPagamento === 'BO' ||
      formItem.codigoFormaPagamento === 'ES' ||
      !formItem.recebimento
    ) {
      formItem.chavePix = null;
      formItem.banco = null;
      formItem.agencia = null;
      formItem.contaCorrente = null;
      formItem.digitoVerificador = null;
    } else if (formItem.codigoFormaPagamento === 'PX') {
      formItem.banco = null;
      formItem.agencia = null;
      formItem.contaCorrente = null;
      formItem.digitoVerificador = null;
    } else if (formItem.codigoFormaPagamento === 'TB') {
      formItem.chavePix = null;
    }

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Beneficiário" text="" />
        <Space h="xs" />
        <SimpleGrid cols={3}>
          <Select
            label="Tipo do beneficiário"
            placeholder="Selecione..."
            data={[
              { label: 'Pessoa Física', value: TIPO_PF },
              { label: 'Pessoa Jurídica', value: TIPO_PJ },
            ]}
            value={tipo}
            onChange={(event) => setTipo(event || '')}
            required
          />
          {tipo === TIPO_PF ? (
            <NumberFormat
              icon={<Id size={15} />}
              label="CPF"
              placeholder="___.___.___-__"
              mask="_"
              format="###.###.###-##"
              customInput={TextInput}
              required
              {...form.getInputProps('cpf')}
            />
          ) : (
            <NumberFormat
              icon={<Id size={15} />}
              rightSection={
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="primary"
                  style={{ borderColor: theme?.colors?.primary?.[6] }}
                  variant="outline"
                  loading={searchingCNPJ}
                  onClick={() => {
                    searchCNPJ();
                  }}
                >
                  <Search size={15} color={theme?.colors?.primary?.[6]} />
                </ActionIcon>
              }
              label="CNPJ"
              placeholder="__.___.___/____-__"
              disabled={searchingCNPJ}
              required
              onKeyUp={(event: any) => {
                if (event.key === 'Enter') {
                  searchCNPJ();
                }
              }}
              mask="_"
              format="##.###.###/####-##"
              customInput={TextInput}
              {...form.getInputProps('cnpj')}
            />
          )}
          <div>
            <Input.Wrapper label="Tipo" required>
              <Group
                style={{ display: 'inline-block', marginLeft: 5, marginTop: -2, verticalAlign: 'bottom' }}
              >
                <Tooltip
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Recebimento refere-se a maneira que a Entidade receberá o pagamento do contratante."
                  multiline
                  width={350}
                >
                  <div>
                    <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                  </div>
                </Tooltip>
              </Group>
            </Input.Wrapper>
            <SegmentedControl
              color="primary"
              data={[
                { label: 'Recebimento', value: true.toString() },
                { label: 'Pagamento', value: false.toString() },
              ]}
              {...form.getInputProps('recebimento')}
            />
          </div>
        </SimpleGrid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          {tipo === TIPO_PF ? (
            <TextInput
              icon={<User size={15} />}
              label="Nome"
              placeholder="Digite o nome"
              maxLength={100}
              required
              {...form.getInputProps('nome')}
            />
          ) : (
            <Textarea
              icon={<Building size={15} />}
              label="Razão Social"
              placeholder="Digite a razão social"
              maxLength={500}
              autosize
              required
              disabled={!enabledCNPJFields}
              {...form.getInputProps('nome')}
            />
          )}
        </SimpleGrid>
        <Space h="xs" />
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Detalhes" text="" />
        <Space h="xs" />
        <SimpleGrid cols={3}>
          <Select
            icon={<Wallet size={15} />}
            label="Forma"
            placeholder="Selecione..."
            data={props.referenceData.paymentMethodTypeData.map((x) => {
              return {
                value: x.codigoFormaPagamento,
                label: x.formaPagamento,
              };
            })}
            required
            {...form.getInputProps('codigoFormaPagamento')}
          />
          <NumberInput
            label="Prazo em dias"
            placeholder="Prazo em dias"
            min={1}
            max={3120}
            {...form.getInputProps('prazoPagamentoDias')}
          />
        </SimpleGrid>
        <Space h="xs" />

        {form.values.codigoFormaPagamento === 'PX' && form.values.recebimento === 'true' && (
          <>
            <Grid columns={3}>
              <Grid.Col span={1}>
                <Select
                  label="Tipo da chave PIX"
                  placeholder="Selecione..."
                  data={[
                    { label: 'CNPJ', value: 'cnpj' },
                    { label: 'CPF', value: 'cpf' },
                    { label: 'Celular', value: 'celular' },
                    { label: 'E-mail', value: 'email' },
                    { label: 'Chave aleatória', value: 'chave' },
                  ]}
                  required
                  {...form.getInputProps('tipoChavePIX')}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                {form.values.tipoChavePIX === 'email' ||
                form.values.tipoChavePIX === 'chave' ||
                !form.values.tipoChavePIX ? (
                  <TextInput
                    icon={
                      form.values.tipoChavePIX === 'email' ? (
                        <Mail size={15} />
                      ) : form.values.tipoChavePIX === 'chave' ? (
                        <Key size={15} />
                      ) : (
                        <QuestionMark size={15} />
                      )
                    }
                    label="Chave PIX"
                    placeholder={
                      form.values.tipoChavePIX === 'email'
                        ? 'nome@dominio.com'
                        : form.values.tipoChavePIX === 'chave'
                        ? '6a2f41a3-c54c-fce8-32d2-0324e1c32e22'
                        : 'Digite a chave PIX'
                    }
                    maxLength={100}
                    disabled={!form.values.tipoChavePIX}
                    required
                    {...form.getInputProps('chavePix')}
                  />
                ) : (
                  <NumberFormat
                    icon={
                      form.values.tipoChavePIX === 'cnpj' || form.values.tipoChavePIX === 'cpf' ? (
                        <Id size={15} />
                      ) : (
                        <DeviceMobile size={15} />
                      )
                    }
                    label="Chave PIX"
                    placeholder={
                      form.values.tipoChavePIX === 'cnpj'
                        ? '__.___.___/____-__'
                        : form.values.tipoChavePIX === 'cpf'
                        ? '___.___.___-__'
                        : '(__) _____-____'
                    }
                    mask="_"
                    format={
                      form.values.tipoChavePIX === 'cnpj'
                        ? '##.###.###/####-##'
                        : form.values.tipoChavePIX === 'cpf'
                        ? '###.###.###-##'
                        : '(##) #####-####'
                    }
                    customInput={TextInput}
                    required
                    {...form.getInputProps('chavePix')}
                  />
                )}
              </Grid.Col>
            </Grid>
            <Space h="xs" />
          </>
        )}

        {form.values.codigoFormaPagamento === 'TB' && form.values.recebimento === 'true' && (
          <>
            <SimpleGrid cols={1}>
              <Select
                icon={<BuildingBank size={15} />}
                label="Banco"
                placeholder="Selecione..."
                data={props.referenceData.bancoTypeData.map((x) => {
                  return {
                    value: `${String(x.code || '???').padStart(3, '0')} - ${x.fullName}`,
                    label: `${String(x.code || '???').padStart(3, '0')} - ${x.fullName}`,
                  };
                })}
                searchable
                required
                {...form.getInputProps('banco')}
              />
            </SimpleGrid>
            <Space h="xs" />

            <SimpleGrid cols={3}>
              <NumberFormat
                icon={<Id size={15} />}
                label="Agência"
                placeholder="____"
                mask="_"
                format="####"
                customInput={TextInput}
                required
                {...form.getInputProps('agencia')}
              />
              <TextInput
                icon={<Id size={15} />}
                label="Conta corrente"
                placeholder="Digite a conta corrente"
                maxLength={50}
                required
                {...form.getInputProps('contaCorrente')}
              />
              <TextInput
                icon={<Id size={15} />}
                label="Dígito verificador"
                placeholder="Digite o dígito verificador"
                maxLength={50}
                {...form.getInputProps('digitoVerificador')}
              />
            </SimpleGrid>
            <Space h="xs" />
          </>
        )}

        <SimpleGrid cols={1}>
          <Textarea
            icon={<ReportMoney size={15} />}
            label="Condição de pagamento"
            placeholder="Condição de pagamento"
            autosize
            maxLength={500}
            {...form.getInputProps('condicaoPagamento')}
          />
        </SimpleGrid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre a forma de pagamento"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
