/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Pie, PieConfig } from '@ant-design/plots';
import { Badge, Button, Group, LoadingOverlay, Paper, SimpleGrid, Space, Stack, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Table as ITable, PlugConnectedX, Reload, TableOff } from 'tabler-icons-react';
import PageSection from '../../../../components/core/PageSection/PageSection';
import { EntityGeneralResponseType, ReportItem } from '../../../../models/core/report.type';
import reportsService from '../../../../services/core/report.service';
import theme from '../../../../theme';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { newGuid } from '../../../../utils/helper.utils';

type FormViewProps = {
  height: number | undefined;
};

type Response = {
  data: EntityGeneralResponseType[];
  chartConfigGeneral: PieConfig;
  chartConfigScope: PieConfig;
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Entidades Gerais',
  description: 'Todas as entidades.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.EntidadeGeral}`,
  filterConfig: {
    ordemData: [
      {
        value: 'receita',
        label: `Receita`,
      },
      {
        value: 'despesa',
        label: `Despesa`,
      },
    ],
    ordem: 'receita',
  },
  dataConfig: {
    exportable: true,
    rowKey: 'idEntidade',
    columns: [
      {
        title: 'Escopos',
        key: 'tipos',
        render: (row: EntityGeneralResponseType) => {
          const list = row.tipos?.split(',') || [];

          if (list.length === 0) {
            return '-';
          }
          return (
            <Group>
              {list.map((x) => (
                <Badge key={x} variant="outline" size="sm">
                  {x}
                </Badge>
              ))}
            </Group>
          );
        },
      },
      {
        title: 'CNPJ/CPF',
        key: 'cnpj-cpf',
        width: '180px',
        sorter: (a: EntityGeneralResponseType, b: EntityGeneralResponseType) => {
          return (a.cnpj || a.cpf || '').localeCompare(b.cnpj || b.cpf || '');
        },
        render: (row: EntityGeneralResponseType) => <div>{row?.cnpj || row?.cpf}</div>,
      },
      {
        title: 'Razão Social/Nome',
        key: 'razaoSocial-nome',
        sorter: (a: EntityGeneralResponseType, b: EntityGeneralResponseType) => {
          return (a.razaoSocial || a.nome || '').localeCompare(b.razaoSocial || b.nome || '');
        },
        render: (row: EntityGeneralResponseType) => <div>{row?.razaoSocial || row?.nome}</div>,
      },
      {
        title: 'Nome Fantasia',
        key: 'nomeFantasia',
        sorter: (a: EntityGeneralResponseType, b: EntityGeneralResponseType) => {
          return (a.nomeFantasia || '').localeCompare(b.nomeFantasia || '');
        },
        render: (row: EntityGeneralResponseType) => <div>{row?.nomeFantasia || '-'}</div>,
      },
      {
        title: 'Contatos',
        key: 'contatos',
        render: (row: EntityGeneralResponseType) => {
          const list = row.contatos?.split('╜') || [];

          if (list.length === 0) {
            return '-';
          }

          const contacts = list.map((x) => {
            const contactData = x.split('╗');
            return {
              nome: contactData[0],
              setor: contactData[1],
              email: contactData[2],
            };
          });

          return (
            <Stack spacing="xs">
              {contacts.map((x) => (
                <Badge
                  variant="outline"
                  key={newGuid()}
                  style={{ textTransform: 'none' }}
                >{`${x.nome} <${x.email}>`}</Badge>
              ))}
            </Stack>
          );
        },
      },
    ],
  },
  chartConfigGeneral: {
    data: [],
    angleField: 'total',
    colorField: 'tipo',
    radius: 1,
    innerRadius: 0.64,
    appendPadding: 10,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}\n{percentage}',
    },
    statistic: {
      title: {
        offsetY: -4,
        content: 'Total',
        // style: {
        //   fontSize: props.height ? props.height / 20 : undefined,
        // },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  } as PieConfig,
  chartConfigScope: {
    data: [],
    angleField: 'total',
    colorField: 'escopo',
    radius: 1,
    innerRadius: 0.64,
    appendPadding: 10,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}\n{percentage}',
    },
    statistic: {
      title: {
        offsetY: -4,
        content: 'Total',
        // style: {
        //   fontSize: props.height ? props.height / 20 : undefined,
        // },
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  } as PieConfig,
};

const EntityGeneral = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);

  const searchMe = async () => reportsService.entidadeGeral();

  const buildChartData = (data: EntityGeneralResponseType[]) => {
    const generalChartData: any[] = [];

    data.reduce((res: any, value) => {
      const tipo = value.cnpj ? 'Pessoa Jurídica' : 'Pessoa Física';
      if (!res[tipo]) {
        res[tipo] = {
          tipo,
          total: 0,
        };
        generalChartData.push(res[tipo]);
      }
      res[tipo].total += 1;
      return res;
    }, {});

    const scopeChartData = [];
    const scopeData = lodash.countBy(data.map((x) => x.tipos?.split(',') || []).flat());
    for (const key of Object.keys(scopeData)) {
      scopeChartData.push({ escopo: key, total: scopeData[key] });
    }

    return { generalChartData, scopeChartData };
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = result.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const chartData = buildChartData(data);

    return {
      data,
      chartConfigGeneral: {
        ...componentConfig.chartConfigGeneral,
        data: chartData.generalChartData,
      },
      chartConfigScope: {
        ...componentConfig.chartConfigScope,
        data: chartData.scopeChartData,
      },
    };
  };

  const load = async (resolve: any, force: boolean) => {
    let tempResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      tempResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      tempResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (tempResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(tempResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(tempResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        setResult({ ...cacheResult });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useImperativeHandle(ref, () => ({
    load() {
      load(() => {}, (result.response?.data || []).length === 0);
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Dashboard.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Dashboard.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Dashboard.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={3} />

        {result.error ? (
          <Stack spacing="md" align="center">
            <PlugConnectedX color="red" size={32} />
            <Text>{result.error}</Text>
          </Stack>
        ) : result.loaded ? (
          (result.response?.data || []).length > 0 ? (
            <Paper shadow="xs" p="md" withBorder>
              <SimpleGrid cols={2}>
                <Pie {...result.response?.chartConfigGeneral!} height={props.height} />
                <Pie {...result.response?.chartConfigScope!} height={props.height} />
              </SimpleGrid>
            </Paper>
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            </Paper>
          )
        ) : (
          <Paper shadow="xs" p="md" withBorder>
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Paper>
  );
});

export default EntityGeneral;
