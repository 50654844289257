import { Button, Card, Center, Loader } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, Edit, Trash } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { Permission } from '../../../../models/core/departments.type';
import { EquipmentType } from '../../../../models/core/equipment.type';
import equipmentsEquipment from '../../../../services/core/equipment.service';
import { Feature } from '../../../../utils/constants.utils';
import DataView from './components/DataView';

type Result = {
  loading: boolean;
  data: EquipmentType | null;
};

function EquipmentView() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const modals = useModals();
  const { idResiduoEquipamento } = useParams();

  const [result, setResult] = useState<Result>({
    loading: location?.state?.equipment === undefined,
    data: location?.state?.equipment || null,
  });
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data: EquipmentType | null = null;
      try {
        data = await equipmentsEquipment.select({
          idResiduoEquipamento: Number(idResiduoEquipamento),
        });
      } catch (error: any) {
        showNotification({
          title: 'Equipamento - Visualizar',
          message: error?.isBusinessException
            ? error.description
            : `Não foi possível carregar o equipamento.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idResiduoEquipamento, navigate, result]);

  const handleAction = async () => {
    try {
      setResult({ loading: false, data: result.data });

      setDeleting(true);
      await equipmentsEquipment.delete({ idResiduoEquipamento: Number(idResiduoEquipamento) });
      navigate('/equipment');

      showNotification({
        title: 'Equipamento - Visualizar',
        message: `Equipamento excluído com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Equipamento - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível excluir o equipamento.`,
        color: 'red',
      });
    } finally {
      setDeleting(false);
    }
  };

  const showModal = () =>
    modals.openConfirmModal({
      title: `Gostaria de excluir esse equipamento?`,
      size: 'sm',
      closeOnClickOutside: false,
      closeOnEscape: false,
      onConfirm: () => {
        handleAction();
      },
    });

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Equipment}
        title="Equipamento - Visualizar"
        description="Visualize os detalhes de um equipamento."
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={deleting || result.loading}
          >
            Voltar
          </Button>,
          <Button
            key="Excluir"
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal();
            }}
            disabled={deleting || result.loading}
            loading={deleting}
            data-permission={Permission.EquipmentDelete}
          >
            Excluir
          </Button>,
          <Button
            key="Editar"
            color="accent"
            leftIcon={<Edit size={18} />}
            onClick={() => {
              navigate(`/equipment/${result?.data?.idResiduoEquipamento}/edit`, {
                state: {
                  equipment: result.data,
                },
              });
            }}
            disabled={deleting || result.loading}
            data-permission={Permission.EquipmentEdit}
          >
            Editar
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <DataView data={result.data} />
        </PageContent>
      )}
    </Card>
  );
}

export default EquipmentView;
