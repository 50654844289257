/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { DismissalType } from '../../../../../models/core/dismissals.type';
import { Feature } from '../../../../../utils/constants.utils';

type DataViewProps = {
  data: DismissalType;
};

export default function DataView(props: DataViewProps) {
  return (
    <>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.Dismissals.color} label="Identificação" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Nome" text={props.data.nomeCompleto} size="md" />
          <PageViewProperty label="E-mail" text={props.data.email} size="md" />
          <PageViewProperty label="Empresa" text={props.data.empresa} size="md" />
          <PageViewProperty label="Departamento" text={props.data.departamento} size="md" />
        </SimpleGrid>
        <Space h="xs" />
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.Dismissals.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty
            label="Status do Desligamento"
            text={<Badge variant="outline">{props.data.usuarioDesligamentoStatus}</Badge>}
            size="md"
          />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
      />
    </>
  );
}
