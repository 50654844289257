/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { Line, LineConfig } from '@ant-design/plots';
import {
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  BoxMultiple,
  Building,
  Check,
  Table as ITable,
  PlugConnectedX,
  Reload,
  SortDescendingNumbers,
  TableOff,
  X,
} from 'tabler-icons-react';
import Gauge from '../../../../../../components/charts/Gauge/Gauge';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import {
  CompanyType,
  MovementTypeGroup,
  MovementTypeGroupCode,
  MovementTypeStandard,
  MovementTypeStandardCode,
} from '../../../../../../models/core/cache.type';
import { CompanyEntityFinancialResponseType, ReportItem } from '../../../../../../models/core/report.type';
import reportsService from '../../../../../../services/core/report.service';
import theme from '../../../../../../theme';
import cacheUtils from '../../../../../../utils/cache.utils';
import { Chart, RecurrenceData, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../utils/helper.utils';
import { getMonths } from '../../../../../../utils/moment-recur.utils';

type FormViewProps = {
  idEntidade: number | undefined;
  color: string;
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  agrupador: string;
  indicador: string;
  clientes: string[];
};

type Response = {
  filterConfig: FilterData;
  data: CompanyEntityFinancialResponseType[];
  filteredData: CompanyEntityFinancialResponseType[];
  chartConfig: LineConfig;
  grandTotal: {
    receitaPotencial: number;
    receita: number;
    despesaPotencial: number;
    despesa: number;
    impostoPotencial: number;
    imposto: number;
    balancoPotencial: number;
    balanco: number;
  };
  chartDataSummary: {
    agrupador: string;
    ref1: string;
    ref2: string;
    receita: number;
    despesa: number;
    imposto: number;
    balanco: number;
  }[];
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
  guid: string;
};

const componentConfig = {
  title: 'Movimentações Financeiras',
  description: 'Análise de movimentações financeiras baseado em eventos com medição definida.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.EmpresaEntidadeFinanceiro}-{ID}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    agrupadorData: [
      // Geral
      {
        value: 'nenhum',
        label: `Nenhum`,
        group: 'Geral',
        reference: 'calcNenhum',
      },
      {
        value: 'idEmpresa',
        label: `Empresa`,
        group: 'Geral',
        reference: 'empresa',
      },
      {
        value: 'idContrato',
        label: `Contrato`,
        group: 'Geral',
        reference: 'calcContrato',
      },
      {
        value: 'codigoEventoTipo',
        label: `Tipo de Evento`,
        group: 'Geral',
        reference: 'eventoTipo',
      },
      // Entidade
      {
        value: 'idCliente',
        label: `Cliente`,
        group: 'Entidade',
        reference: 'calcCliente',
      },
      {
        value: 'idFornecedor',
        label: `Fornecedor`,
        group: 'Entidade',
        reference: 'calcFornecedor',
      },
      {
        value: 'calcIdFornecedorItem',
        label: `Fornecedor + Item`,
        group: 'Entidade',
        reference: 'calcFornecedorItem',
      },
      // Movimentação
      {
        value: 'calcCodigoMovimentacaoGrupo',
        label: `Grupo de Movimentação`,
        group: 'Movimentação',
        reference: 'calcMovimentacaoGrupo',
      },
      {
        value: 'calcCodigoMovimentacaoPadrao',
        label: `Movimentação Padrao`,
        group: 'Movimentação',
        reference: 'calcMovimentacaoPadrao',
      },
      {
        value: 'calcIdMovimentacao',
        label: `Movimentação`,
        group: 'Movimentação',
        reference: 'calcMovimentacao',
      },
      {
        value: 'codigoMovimentacaoFaturamentoTipo',
        label: `Tipo de Faturamento`,
        group: 'Movimentação',
        reference: 'movimentacaoFaturamentoTipo',
      },
      // Resíduo
      {
        value: 'idResiduo',
        label: `Resíduo`,
        group: 'Resíduo',
        reference: 'residuo',
      },
      {
        value: 'idPropostaResiduo',
        label: `Resíduo (Cliente)`,
        group: 'Resíduo',
        reference: 'residuoCliente',
      },
      {
        value: 'calcIdResiduoGrupo',
        label: `Resíduo (Cliente) + Grupo`,
        group: 'Resíduo',
        reference: 'calcResiduoGrupo',
      },
      {
        value: 'calcIdResiduoItem',
        label: `Resíduo (Cliente) + Item`,
        group: 'Resíduo',
        reference: 'calcResiduoItem',
      },
      // Serviço
      {
        value: 'idServico',
        label: `Serviço`,
        group: 'Serviço',
        reference: 'servico',
      },
      {
        value: 'calcIdServicoGrupo',
        label: `Serviço + Grupo`,
        group: 'Serviço',
        reference: 'calcServicoGrupo',
      },
      {
        value: 'calcIdServicoTipo',
        label: `Serviço + Tipo`,
        group: 'Serviço',
        reference: 'calcServicoTipo',
      },
      {
        value: 'calcIdServicoFornecedor',
        label: `Serviço + Fornecedor`,
        group: 'Serviço',
        reference: 'calcServicoFornecedor',
      },
      {
        value: 'calcIdServicoTipoFornecedor',
        label: `Serviço + Tipo + Fornecedor`,
        group: 'Serviço',
        reference: 'calcServicoTipoFornecedor',
      },
      // Item
      {
        value: 'idResiduoAcondicionamento',
        label: `Acondicionamento`,
        group: 'Item',
        reference: 'residuoAcondicionamento',
      },
      {
        value: 'idResiduoEquipamento',
        label: `Equipamento`,
        group: 'Item',
        reference: 'residuoEquipamento',
      },
      {
        value: 'idResiduoVeiculo',
        label: `Veiculo`,
        group: 'Item',
        reference: 'residuoVeiculo',
      },
      {
        value: 'idResiduoTratamento',
        label: `Tratamento`,
        group: 'Item',
        reference: 'residuoTratamento',
      },
      {
        value: 'idResiduoDestinoFinal',
        label: `Destino Final`,
        group: 'Item',
        reference: 'residuoDestinoFinal',
      },
    ],
    agrupador: 'nenhum',
    indicadorData: [
      {
        value: 'receita',
        label: `Receita`,
      },
      {
        value: 'despesa',
        label: `Despesa`,
      },
      {
        value: 'imposto',
        label: `Imposto`,
      },
      {
        value: 'balanco',
        label: `Balanço`,
      },
    ],
    indicador: 'balanco',
    clientes: [],
  },
  dataConfig: {
    exportable: true,
    separator: '╗',
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Empresa',
        key: 'empresa',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) =>
          a.empresa.localeCompare(b.empresa),
        render: (row: CompanyEntityFinancialResponseType) => <Badge variant="outline">{row.empresa}</Badge>,
      },
      {
        title: 'Contrato #',
        key: 'idContrato',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.idContrato;
          const bValue = b.idContrato;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => (
          <ProfileCardLink
            id={row.idContrato.toString()}
            name="Contrato"
            nameSize="sm"
            description={`# ${row.idContrato}`}
            descriptionSize="xs"
            linkPrefix="contracts"
            showLink
          />
        ),
      },
      {
        title: 'Proposta #',
        key: 'idProposta',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.idProposta;
          const bValue = b.idProposta;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => (
          <ProfileCardLink
            id={row.idProposta.toString()}
            name="Proposta"
            nameSize="sm"
            description={`# ${row.idProposta}`}
            descriptionSize="xs"
            linkPrefix="proposals"
            showLink
          />
        ),
      },
      {
        title: 'Cliente',
        key: 'idCliente',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) =>
          (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
            b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
          ),
        render: (row: CompanyEntityFinancialResponseType) => {
          if (row.clienteCNPJ) {
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                nameSize="sm"
                description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNome || '-'}
              nameSize="sm"
              description={row.clienteCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        },
      },
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo} | ${row.eventoStatus}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) =>
          new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: CompanyEntityFinancialResponseType) => formatDateToString(row.data),
      },
      {
        title: 'Receita',
        key: 'receita',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.receita || 0;
          const bValue = b.receita || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => <div>{formatCurrency(row.receita)}</div>,
      },
      {
        title: 'Despesa',
        key: 'despesa',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.despesa || 0;
          const bValue = b.despesa || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => <div>{formatCurrency(row.despesa)}</div>,
      },
      {
        title: 'Imposto',
        key: 'imposto',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.imposto || 0;
          const bValue = b.imposto || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => <div>{formatCurrency(row.imposto)}</div>,
      },
      {
        title: 'Balanço',
        key: 'balanco',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) => {
          const aValue = a.receita - a.despesa - a.imposto;
          const bValue = b.receita - b.despesa - b.imposto;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyEntityFinancialResponseType) => {
          const total = row.receita - row.despesa - row.imposto;
          const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

          return <div style={{ color }}>{formatCurrency(total)}</div>;
        },
      },
      {
        title: 'Faturado?',
        key: 'faturado',
        dataIndex: 'faturado',
        sorter: (a: CompanyEntityFinancialResponseType, b: CompanyEntityFinancialResponseType) =>
          a.faturado === b.faturado ? 0 : a.faturado ? -1 : 1,
        render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
      },
    ],
  },
  chartConfig: {
    data: [],
    xField: 'mesAno',
    yField: 'indicador',
    seriesField: 'referencia',
    yAxis: {
      label: {
        formatter: (value) => {
          return formatCurrency(Number(value));
        },
      },
      nice: true,
    },
    legend: {
      position: 'right',
    },
    tooltip: {
      formatter: (el) => {
        return {
          name: el.referencia,
          value: formatCurrency(el.indicador),
        };
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  } as LineConfig,
};

const FinancialMovement = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
    guid: newGuid(),
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
  }>({
    companyData: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idEntidade: props.idEntidade,
      idEmpresa:
        form.values.idEmpresa !== null && form.values.idEmpresa !== undefined
          ? Number(form.values.idEmpresa)
          : null,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.empresaEntidadeFinanceiro(searchCriteria);
    return data;
  };

  const filterMe = (data: CompanyEntityFinancialResponseType[]) => {
    let filteredData = data.filter(
      (x) => Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa))
    );

    if (form.values.clientes.length > 0) {
      filteredData = data.filter((x) =>
        form.values.clientes.map((y) => Number(y)).includes(Number(x.idCliente))
      );
    }
    return filteredData;
  };

  const buildChartData = async (data: CompanyEntityFinancialResponseType[]) => {
    referenceData = {
      companyData: await cacheUtils.listCompanies(),
    };

    const dataInicial = form.values.periodo[0];
    const dataFinal = form.values.periodo[1];
    const meses = getMonths(dataInicial, dataFinal);

    const calcData = data.map((x) => {
      const mesAno = getMonths(x.data, x.data)[0];
      return {
        ...x,
        mesAno,
        calcNenhum:
          componentConfig.filterConfig.indicadorData.find((x) => x.value === form.values.indicador)?.label ||
          '-',
        calcContrato: `Contrato #${x.idContrato}`,
        calcCliente: `${x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome}`,
        calcFornecedor: `${x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}`,

        calcIdFornecedorItem: `${x.idFornecedor}-${x.idResiduoAcondicionamento}-${x.idResiduoEquipamento}-${x.idResiduoVeiculo}-${x.idResiduoTratamento}-${x.idResiduoDestinoFinal}`,
        calcFornecedorItem: `${x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome}${
          componentConfig.dataConfig.separator
        }${
          x.residuoAcondicionamento ||
          x.residuoEquipamento ||
          x.residuoVeiculo ||
          x.residuoTratamento ||
          x.residuoDestinoFinal ||
          'Geral'
        }`,

        calcCodigoMovimentacaoGrupo: `${x.codigoMovimentacaoGrupo || '?'}`,
        calcMovimentacaoGrupo:
          MovementTypeGroupCode[x.codigoMovimentacaoGrupo as MovementTypeGroup] || 'Geral',

        calcCodigoMovimentacaoPadrao: `${x.codigoMovimentacaoPadrao || '?'}`,
        calcMovimentacaoPadrao:
          MovementTypeStandardCode[x.codigoMovimentacaoPadrao as MovementTypeStandard] || 'Outro',

        calcIdMovimentacao: `${x.idMovimentacaoTipo}-${x.outroTipo || '?'}`,
        calcMovimentacao: x.outroTipo ? `(O) ${x.outroTipo}` : x.movimentacaoTipo,

        calcIdResiduoGrupo: x.idPropostaResiduo
          ? `${x.idPropostaResiduo}-${x.codigoMovimentacaoGrupo || '?'}`
          : null,
        calcResiduoGrupo: x.idPropostaResiduo
          ? `${x.residuoCliente}${componentConfig.dataConfig.separator}${
              MovementTypeGroupCode[x.codigoMovimentacaoGrupo as MovementTypeGroup] || 'Geral'
            }`
          : null,

        calcIdResiduoItem: x.idPropostaResiduo
          ? `${x.idPropostaResiduo}-${x.idResiduoAcondicionamento}-${x.idResiduoEquipamento}-${x.idResiduoVeiculo}-${x.idResiduoTratamento}-${x.idResiduoDestinoFinal}`
          : null,
        calcResiduoItem: x.idPropostaResiduo
          ? `${x.residuoCliente}${componentConfig.dataConfig.separator}${
              x.residuoAcondicionamento ||
              x.residuoEquipamento ||
              x.residuoVeiculo ||
              x.residuoTratamento ||
              x.residuoDestinoFinal ||
              'Geral'
            }`
          : null,

        calcIdServicoGrupo: x.idServico
          ? `${x.idServico}-${x.idResiduoAcondicionamento ? 'A' : x.idResiduoEquipamento ? 'E' : 'F'}`
          : null,
        calcServicoGrupo: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.idResiduoAcondicionamento
                ? 'Acondicionamento'
                : x.idResiduoEquipamento
                ? 'Equipamento'
                : 'Fornecedor'
            }`
          : null,

        calcIdServicoTipo: x.idServico
          ? `${x.idServico}-${x.idResiduoAcondicionamento || x.idResiduoEquipamento || '?'}`
          : null,
        calcServicoTipo: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.residuoAcondicionamento || x.residuoEquipamento || 'Fornecedor'
            }`
          : null,

        calcIdServicoFornecedor: x.idServico ? `${x.idServico}-${x.idFornecedor}` : null,
        calcServicoFornecedor: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome
            }`
          : null,

        calcIdServicoTipoFornecedor: x.idServico
          ? `${x.idServico}-${x.idResiduoAcondicionamento || x.idResiduoEquipamento || '?'}-${x.idFornecedor}`
          : null,
        calcServicoTipoFornecedor: x.idServico
          ? `${x.servico}${componentConfig.dataConfig.separator}${
              x.residuoAcondicionamento || x.residuoEquipamento || 'Fornecedor'
            }${componentConfig.dataConfig.separator}${
              x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome
            }`
          : null,
      };
    });

    const agrupador = componentConfig.filterConfig.agrupadorData.find(
      (x) => x.value === form.values.agrupador
    )!;

    const chartData = [];
    const calcAgrupador = agrupador.value === 'nenhum' ? undefined : agrupador.value;
    const chartDataGroup = lodash.groupBy(calcData, calcAgrupador);

    for (const key of Object.keys(chartDataGroup).filter((x) => x !== 'null')) {
      const items = chartDataGroup[key];
      const item = items[0];
      const referencia = (item as any)[agrupador.reference!].replaceAll(
        componentConfig.dataConfig.separator,
        ' - '
      );

      for (const mesAno of meses) {
        const receita = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.receita, 0);
        const despesa = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.despesa, 0);
        const imposto = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.imposto, 0);
        const balanco = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.balanco, 0);

        const finalItem = {
          ordem: mesAno.order,
          mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
          referencia,
          receita,
          despesa,
          imposto,
          balanco,
          indicador: 0,
        };
        finalItem.indicador = (finalItem as any)[form.values.indicador];

        chartData.push(finalItem);
      }
    }

    const grandTotal = {
      receitaPotencial: data.reduce((a, b: any) => a + b.receita, 0),
      despesaPotencial: data.reduce((a, b: any) => a + b.despesa, 0),
      impostoPotencial: data.reduce((a, b: any) => a + b.imposto, 0),
      balancoPotencial: data.reduce((a, b: any) => a + b.balanco, 0),
      receita: data.filter((x) => !!x.faturado).reduce((a, b: any) => a + b.receita, 0),
      despesa: data.filter((x) => !!x.faturado).reduce((a, b: any) => a + b.despesa, 0),
      imposto: data.filter((x) => !!x.faturado).reduce((a, b: any) => a + b.imposto, 0),
      balanco: data.filter((x) => !!x.faturado).reduce((a, b: any) => a + b.balanco, 0),
    };

    const chartDataSummary = [];
    const chartDataSummaryGroup = lodash.groupBy(calcData, calcAgrupador);
    for (const key of Object.keys(chartDataSummaryGroup).filter((x) => x !== 'null')) {
      const items = chartDataSummaryGroup[key];
      const item = items[0];
      const agrupadorValue = (item as any)[agrupador.reference!].split(componentConfig.dataConfig.separator);

      chartDataSummary.push({
        agrupador: agrupadorValue[0],
        ref1: agrupadorValue?.[1] || '-',
        ref2: agrupadorValue?.[2] || '-',
        receita: items.reduce((a, b: any) => a + b.receita, 0),
        despesa: items.reduce((a, b: any) => a + b.despesa, 0),
        imposto: items.reduce((a, b: any) => a + b.imposto, 0),
        balanco: items.reduce((a, b: any) => a + b.balanco, 0),
      });
    }

    return {
      chartData: chartData.sort((a, b) => a.ordem - b.ordem),
      grandTotal,
      chartDataSummary: chartDataSummary.sort(
        (a: any, b: any) => b[form.values.indicador] - a[form.values.indicador]
      ),
    };
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);
    const chartData = await buildChartData(filteredData);

    return {
      filterConfig: form.values,
      data,
      filteredData,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: chartData.chartData,
      },
      grandTotal: chartData.grandTotal,
      chartDataSummary: chartData.chartDataSummary,
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null, guid: newGuid() };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message, guid: newGuid() };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', (props.idEntidade || 0).toString());

    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult, guid: newGuid() });
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const clientsChanged = (filterConfig?.clientes || []).join('-') !== form.values.clientes.join('-');
    const agrupadorChanged = form.values.agrupador !== filterConfig?.agrupador;
    const indicadorChanged = form.values.indicador !== filterConfig?.indicador;

    if (idEmpresaChanged || clientsChanged || agrupadorChanged || indicadorChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={props.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={props.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={props.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={3} />

        <Paper shadow="xs" p="md" withBorder>
          <Group position="apart">
            <Group spacing="md">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Select
                icon={<Building size={15} />}
                label="Empresa"
                description="Filtro: igual"
                placeholder="Todos..."
                data={referenceData.companyData.map((x) => {
                  return {
                    value: x.idEmpresa.toString(),
                    label: x.empresa,
                  };
                })}
                searchable
                clearable={currentUser.executivo}
                disabled={!currentUser.executivo}
                {...form.getInputProps('idEmpresa')}
              />
            </Group>
            <Group spacing="md">
              <Select
                style={{ width: 300 }}
                icon={<BoxMultiple size={15} />}
                label="Agrupador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                searchable
                data={componentConfig.filterConfig.agrupadorData}
                {...form.getInputProps('agrupador')}
              />
              <Select
                icon={<SortDescendingNumbers size={15} />}
                style={{ width: 150 }}
                label="Indicador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                data={componentConfig.filterConfig.indicadorData}
                {...form.getInputProps('indicador')}
              />
            </Group>
          </Group>
          {result.loaded && (result.response?.data || []).length > 0 && (
            <div>
              <Space h="md" />
              <MultiSelect
                label="Clientes"
                description="Filtro: igual"
                placeholder="Todos..."
                data={Array.from(
                  new Set(
                    result.response?.data.map(
                      (x) =>
                        `${x.idCliente}${componentConfig.dataConfig.separator}${
                          x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome
                        }`
                    )
                  )
                ).map((x) => {
                  return {
                    value: x.split(componentConfig.dataConfig.separator)[0],
                    label: x.split(componentConfig.dataConfig.separator)[1],
                  };
                })}
                clearable
                {...form.getInputProps('clientes')}
              />
            </div>
          )}
        </Paper>
        <Space h="md" />

        {result.error ? (
          <Stack spacing="md" align="center">
            <PlugConnectedX color="red" size={32} />
            <Text>{result.error}</Text>
          </Stack>
        ) : result.loaded ? (
          (result.response?.filteredData || []).length > 0 ? (
            <div>
              <Paper shadow="xs" p="md" withBorder>
                <Grid columns={4}>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.receitaPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.receitaPotencial || 0)}
                      actual={result.response?.grandTotal.receita || 0}
                      actualString={formatCurrency(result.response?.grandTotal.receita || 0 || 0)}
                      title="Receita"
                      color={{
                        text: Chart.Color.Receita,
                        min: Chart.Color['Receita (Potencial)'],
                        max: Chart.Color.Receita,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.despesaPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.despesaPotencial || 0)}
                      actual={result.response?.grandTotal.despesa || 0}
                      actualString={formatCurrency(result.response?.grandTotal.despesa || 0 || 0)}
                      title="Despesa"
                      color={{
                        text: Chart.Color.Despesa,
                        min: Chart.Color['Despesa (Potencial)'],
                        max: Chart.Color.Despesa,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.impostoPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.impostoPotencial || 0)}
                      actual={result.response?.grandTotal.imposto || 0}
                      actualString={formatCurrency(result.response?.grandTotal.imposto || 0 || 0)}
                      title="Imposto"
                      color={{
                        text: Chart.Color.Imposto,
                        min: Chart.Color['Imposto (Potencial)'],
                        max: Chart.Color.Imposto,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.balancoPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.balancoPotencial || 0)}
                      actual={result.response?.grandTotal.balanco || 0}
                      actualString={formatCurrency(result.response?.grandTotal.balanco || 0 || 0)}
                      title="Balanço"
                      color={{
                        text: Chart.Color.Balanço,
                        min: Chart.Color['Balanço (Potencial)'],
                        max: Chart.Color.Balanço,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Line {...result.response?.chartConfig!} height={props.height} />
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Table
                  key={result.guid}
                  showSorterTooltip={false}
                  size="small"
                  dataSource={result.response?.chartDataSummary || []}
                  columns={[
                    {
                      title: 'Agrupador',
                      key: 'agrupador',
                      sorter: (a: any, b: any) => a.agrupador.localeCompare(b.agrupador),
                      render: (row: any) => row.agrupador,
                    },
                    {
                      title: 'Referência 1',
                      key: 'ref1',
                      sorter: (a: any, b: any) => a.ref1.localeCompare(b.ref1),
                      render: (row: any) => row.ref1,
                    },
                    {
                      title: 'Referência 2',
                      key: 'ref2',
                      sorter: (a: any, b: any) => a.ref2.localeCompare(b.ref2),
                      render: (row: any) => row.ref2,
                    },
                    ...(componentConfig.dataConfig.columns.filter((x) =>
                      ['receita', 'despesa', 'imposto', 'balanco'].includes(x.key)
                    ) as any),
                  ]}
                  rowKey={(item) =>
                    `${item.agrupador}-${item.ref1 || '0'}-${item.ref2 || '0'}-${item.balanco}`
                  }
                  pagination={{
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                  }}
                />
              </Paper>
            </div>
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            </Paper>
          )
        ) : (
          <Paper shadow="xs" p="md" withBorder>
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Paper>
  );
});

export default FinancialMovement;
