/* eslint-disable react/destructuring-assignment */
import { Trash } from 'tabler-icons-react';
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import filesize from 'filesize';
import { EventDocumentFileType } from '../../../../../../models/core/events.type';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import { validate } from '../../../../../../utils/permission.utils';
import { Permission } from '../../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';

type DataViewActionProps = {
  item: EventDocumentFileType;
  confirmActionResult(items: EventDocumentFileType[] | null, action: string, confirmed: boolean): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function DocumentFileFormViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} esse arquivo?`}
      >
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={2}>
            <PageViewProperty label="Nome" text={props.item.nome} size="sm" />
            <PageViewProperty
              label="Tamanho"
              text={filesize(props.item.tamanho, { locale: 'pt-BR' })}
              size="sm"
            />
          </SimpleGrid>
        </Paper>
        <Space h="lg" />

        <Group position="right">
          <Button
            color="secondary"
            // leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult([props.item], modalData.action, false);
            }}
          >
            Não
          </Button>
          <Button
            // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
            // leftIcon={<Check size={18} />}
            color="primary"
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult([props.item], modalData.action, true);
            }}
          >
            Sim
          </Button>
        </Group>
      </Modal>

      <Group>
        {validate(Permission.EventEdit, currentUser.permissoes) ? (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                setModalData({ opened: true, action: 'excluir' });
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        ) : (
          '-'
        )}
      </Group>
    </div>
  );
}
