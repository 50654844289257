/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Badge, Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Check, Plus, X } from 'tabler-icons-react';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { UnitOfMeasureType } from '../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../models/core/core.type';
import { EventVehiclePackagingType, EventVehicleType } from '../../../../../../../../models/core/events.type';
import { ProposalResiduePlanPackagingType } from '../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../../../utils/helper.utils';
import EventVehiclePackagingFormAddEdit from './EventVehiclePackagingForm/EventVehiclePackagingFormAddEdit';
import EventVehiclePackagingFormViewActions from './EventVehiclePackagingForm/EventVehiclePackagingFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  eventVehicle: EventVehicleType | null;
  eventPackagingItems: ProposalResiduePlanPackagingType[];
  disabled: boolean;
};

const EventVehiclePackagingFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const tempVehiclePackaging = props.eventPackagingItems.map((x) => {
    return {
      ...x,
      idEventoVeiculoAcondicionamento: undefined,
      idEventoVeiculo: -1,
      action: Action.Add,
      id: x.idTemporaria ?? newGuid(),
    };
  });

  const [data, setData] = useState<EventVehiclePackagingType[]>(
    tempVehiclePackaging.length > 0
      ? tempVehiclePackaging
          .map((x) => {
            return { ...x, enviar: true, id: newGuid() };
          })
          .concat(
            tempVehiclePackaging.map((x) => {
              return { ...x, enviar: false, id: newGuid() };
            })
          )
      : props.eventVehicle?.acondicionamentos?.map((x: EventVehiclePackagingType) => {
          return {
            ...x,
            action: x.action || Action.Nothing,
            id: x.idEventoVeiculoAcondicionamento?.toString() ?? x.idTemporaria ?? newGuid(),
          };
        }) || []
  );
  const [packagingItem, setPackagingItem] = useState<EventVehiclePackagingType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<EventVehiclePackagingType> = [
    {
      title: 'Acondicionamento',
      key: 'residuoAcondicionamento',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) =>
        a.residuoAcondicionamento.localeCompare(b.residuoAcondicionamento),
      render: (row: EventVehiclePackagingType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoAcondicionamento?.toString() || ''}
            name={row.residuoAcondicionamento || ''}
            nameSize="sm"
            description={row.residuoAcondicionamentoDescricao}
            descriptionSize="xs"
            linkPrefix="packaging"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventVehiclePackagingType) => (
        <div>{`${row.compartilhado ? '% de ' : ''}${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
      ),
    },
    {
      title: 'Compartilhado?',
      key: 'compartilhado',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) =>
        a.compartilhado === b.compartilhado ? 0 : a.compartilhado ? -1 : 1,
      render: (row: EventVehiclePackagingType) => (
        <div>{row.compartilhado ? <Check color="green" /> : <X color="red" />}</div>
      ),
    },
    {
      title: 'Tipo',
      key: 'tipo',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) =>
        a.enviar === b.enviar ? 0 : a.enviar ? -1 : 1,
      render: (row: EventVehiclePackagingType) => (
        <Badge variant="outline" size="sm">
          {row.enviar ? 'Enviar' : 'Retirar'}
        </Badge>
      ),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EventVehiclePackagingType, b: EventVehiclePackagingType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EventVehiclePackagingType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
  ];

  if (!props.disabled) {
    columns.push({
      title: 'Ações',
      width: '100px',
      render: (row: EventVehiclePackagingType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <EventVehiclePackagingFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    });
  }

  const confirmActionResult = (
    item: EventVehiclePackagingType | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEventoVeiculoAcondicionamento) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setPackagingItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(JSON.parse(JSON.stringify(data)));
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EventVehiclePackagingType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Evento/Veículo/Acondicionamento - ${packagingItem ? 'Editar' : 'Adicionar'}`}
        size="50%"
      >
        <EventVehiclePackagingFormAddEdit
          referenceData={props.referenceData}
          origItem={
            packagingItem
              ? props.eventVehicle?.acondicionamentos?.find(
                  (x) =>
                    x.idEventoVeiculoAcondicionamento === packagingItem.idEventoVeiculoAcondicionamento &&
                    x.idEventoVeiculo !== -1
                ) || null
              : null
          }
          item={packagingItem}
          idEventoVeiculo={props.eventVehicle?.idEventoVeiculo}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Acondicionamentos"
          text="Lista de acondicionamentos para este veículo."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={props.disabled}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EventVehiclePackagingType) => item.id || item.idEventoVeiculoAcondicionamento || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default EventVehiclePackagingFormView;
