/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Switch,
  Text,
  Textarea,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { useMemo, useRef } from 'react';
import { CurrencyReal, DeviceFloppy, Notes, Ruler2, Scale, ZoomMoney } from 'tabler-icons-react';
import EntityItemSearch from '../../../../../../../../../../../../../components/core/EntityItemSearch/EntityItemSearch';
import PageSection from '../../../../../../../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ToleranceForm from '../../../../../../../../../../../../../components/core/Tolerance/ToleranceForm';
import {
  EntityTypeType,
  UnitOfMeasureType,
} from '../../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../../../../../../../../../models/core/entities.type';
import {
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanQuotationPackagingType,
  ProposalServiceType,
} from '../../../../../../../../../../../../../models/core/proposals.type';
import { EntityItemEnum, Feature } from '../../../../../../../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  fornecedorData: EntitySearchResponseType | null;
  idFornecedor: number | null;
  fornecedor: string;
  idEntidadeResiduoAcondicionamento: number | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: string | null;
  frequenciaIdUnidadeMedida: string | null;
  preco: number | null;

  cobrarSemUso: boolean;
  condicaoLocacao: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanQuotationPackagingType | null;
  item: ProposalResiduePlanQuotationPackagingType | null;
  refItem: ProposalResiduePlanPackagingType;
  idPropostaResiduoPlanoCotacao: number | undefined;

  services: ProposalServiceType[];
  callback(
    item: ProposalResiduePlanQuotationPackagingType | null,
    refItem: ProposalResiduePlanPackagingType | null,
    action: string,
    confirmed: boolean
  ): void;
};

// TODO: Proposals: 005 - If change plan item (vehicle/packaging..), then go to the quotation with the previous item settled, add the missing item. The old record will be maintened but with action: add (FIX: make the action: modify)
export default function RPQuotationPackagingFormAddEdit(props: FormViewProps) {
  const refToleranceForm = useRef<any>();
  const service = props.services?.find(
    (x) =>
      x.action !== Action.Delete &&
      ((props.refItem.idPropostaServico && x.idPropostaServico === props.refItem.idPropostaServico) ||
        (props.refItem.idPropostaServicoTemp && x.id === props.refItem.idPropostaServicoTemp))
  );
  const serviceQuotation = service?.cotacoes?.find((x) => x.primario && x.action !== Action.Delete);

  const disabled = !!service;

  const buildFornecedorDataFromItem = (
    item: ProposalResiduePlanQuotationPackagingType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidadeResiduoAcondicionamento: item.idEntidadeResiduoAcondicionamento,
      idEntidade: Number(item.idFornecedor),
      tipos: [],
      cnpj: item.fornecedorCNPJ || null,
      razaoSocial: item.fornecedorRazaoSocial || null,
      nomeFantasia: item.fornecedorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.fornecedorCPF || null,
      nome: item.fornecedorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const builQuantityFromContext = useMemo(() => {
    const um1 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(props.refItem?.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    if (!props.refItem.compartilhado) {
      return `${props.refItem?.quantidade} ${um1}`;
    }

    return `% de ${service?.quantidade} ${um1}`;
  }, [
    props.refItem.compartilhado,
    props.refItem.quantidade,
    props.refItem?.quantidadeIdUnidadeMedida,
    props.referenceData.unitOfMeasures,
    service?.quantidade,
  ]);

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || props.refItem?.id || newGuid(),

      fornecedorData: buildFornecedorDataFromItem((serviceQuotation as any) || props.item),
      idFornecedor: serviceQuotation?.idFornecedor || props.item?.idFornecedor || null,
      fornecedor:
        serviceQuotation?.fornecedorNomeFantasia ||
        serviceQuotation?.fornecedorRazaoSocial ||
        serviceQuotation?.fornecedorNome ||
        props.item?.fornecedorNomeFantasia ||
        props.item?.fornecedorRazaoSocial ||
        props.item?.fornecedorNome ||
        '',
      idEntidadeResiduoAcondicionamento:
        serviceQuotation?.idEntidadeResiduoAcondicionamento ||
        props.item?.idEntidadeResiduoAcondicionamento ||
        null,

      quantidade: serviceQuotation?.quantidade || props.item?.quantidade || props.refItem?.quantidade || 1,

      quantidadeIdUnidadeMedida:
        serviceQuotation?.frequenciaIdUnidadeMedida1?.toString() ||
        props.item?.quantidadeIdUnidadeMedida?.toString() ||
        props.refItem?.quantidadeIdUnidadeMedida.toString() ||
        null,
      frequenciaIdUnidadeMedida:
        serviceQuotation?.frequenciaIdUnidadeMedida2?.toString() ||
        props.item?.frequenciaIdUnidadeMedida?.toString() ||
        null,
      preco: serviceQuotation || props.item?.preco === 0 ? 0 : props.item?.preco || null,

      cobrarSemUso: props.item?.cobrarSemUso || false,
      condicaoLocacao: props.item?.condicaoLocacao || '',

      observacao: serviceQuotation?.observacao || props.item?.observacao || '',
    },
    validate: {
      fornecedor: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      preco: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idFornecedor || ''}
    |${props.origItem?.idEntidadeResiduoAcondicionamento || ''}
    |${props.origItem?.quantidade || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida || ''}
    |${Number(props.origItem?.preco) || ''}
    |${props.origItem?.cobrarSemUso || ''}
    |${props.origItem?.condicaoLocacao || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idFornecedor || ''}
    |${form.values.idEntidadeResiduoAcondicionamento || ''}
    |${form.values.quantidade || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.frequenciaIdUnidadeMedida || ''}
    |${Number(form.values.preco) || ''}
    |${form.values.cobrarSemUso || ''}
    |${form.values.condicaoLocacao || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified() && props.origItem.idPropostaResiduoPlanoCotacaoAcondicionamento) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          frequenciaIdUnidadeMedida: form.values.frequenciaIdUnidadeMedida?.toString() || '',
        };
      }
    }

    const formItem: ProposalResiduePlanQuotationPackagingType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaResiduoPlanoCotacao: props.idPropostaResiduoPlanoCotacao || -1,
    };

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.frequenciaIdUnidadeMedida = Number(formItem.frequenciaIdUnidadeMedida);
    formItem.frequenciaUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.preco = Number(formItem.preco);

    formItem.idFornecedor = Number(form.values.fornecedorData?.idEntidade);
    formItem.fornecedorCNPJ = form.values.fornecedorData?.cnpj || null;
    formItem.fornecedorRazaoSocial = form.values.fornecedorData?.razaoSocial || null;
    formItem.fornecedorNomeFantasia = form.values.fornecedorData?.nomeFantasia || null;
    formItem.fornecedorCPF = form.values.fornecedorData?.cpf || null;
    formItem.fornecedorNome = form.values.fornecedorData?.nome || null;

    formItem.idResiduoAcondicionamento = props.refItem?.idResiduoAcondicionamento || -1;
    formItem.residuoAcondicionamento = props.refItem?.residuoAcondicionamento || '';
    formItem.residuoAcondicionamentoDescricao = props.refItem?.residuoAcondicionamentoDescricao || null;

    formItem.condicaoLocacao = formItem.condicaoLocacao?.trim() || null;

    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.tolerancias = refToleranceForm?.current?.validate() || [];

    props.callback({ ...formItem, action: values.action, id: values.id }, props.refItem, 'callback', true);
  };

  const tabs = [
    {
      key: 'Cotacao',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotação
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntityItemSearch
                label="Fornecedor"
                referenceData={props.referenceData}
                item={EntityItemEnum.Packaging}
                itemId={props.refItem?.idResiduoAcondicionamento || -1}
                formItem={form.getInputProps('fornecedor') || undefined}
                idsToBeDisabled={[]}
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('fornecedorData', item);
                    form.setFieldValue('idFornecedor', item.idEntidade);
                    form.setFieldValue(
                      'fornecedor',
                      item.nomeFantasia || item.razaoSocial || item.nome || ''
                    );
                    form.setFieldValue(
                      'idEntidadeResiduoAcondicionamento',
                      (item as any).idEntidadeResiduoAcondicionamento
                    );
                  }
                }}
                disabled={disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={3}>
              <Input.Wrapper label="Quantidade/Frequência" required>
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                <NumberInput
                  style={{ width: 125 }}
                  placeholder="Quantidade"
                  min={1}
                  defaultValue={1}
                  step={1}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  required
                  disabled={disabled}
                  {...form.getInputProps('quantidade')}
                />
                <Select
                  icon={<Ruler2 size={15} />}
                  style={{ width: 200 }}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  disabled
                  {...form.getInputProps('quantidadeIdUnidadeMedida')}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  disabled={disabled}
                  {...form.getInputProps('frequenciaIdUnidadeMedida')}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={1}>
              <NumberInput
                icon={<CurrencyReal size={15} />}
                label="Preço"
                placeholder="Preço"
                min={0}
                precision={2}
                hideControls
                required
                disabled={disabled}
                {...form.getInputProps('preco')}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={3}>
            <div>
              <Input.Wrapper label="Cobrar Sem Uso?">
                <div />
              </Input.Wrapper>
              <Switch {...form.getInputProps('cobrarSemUso', { type: 'checkbox' })} disabled={disabled} />
            </div>
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Condição da Locação"
              placeholder="Condição da Locação"
              autosize
              maxLength={500}
              disabled={disabled}
              {...form.getInputProps('condicaoLocacao')}
            />
          </SimpleGrid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o a cotação do acondicionamento"
              autosize
              maxLength={500}
              disabled={disabled}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tolerancias',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Tolerâncias
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ToleranceForm
            ref={refToleranceForm}
            refId={props.item?.idPropostaResiduoPlanoAcondicionamento}
            title="Tolerâncias"
            description="Tolerâncias para esta cotação."
            color={Feature.Home.Proposal.color}
            disabled={disabled}
            origData={props.origItem?.tolerancias || []}
            // data={serviceQuotation?.tolerancias || props.item?.tolerancias || []} // not needed
            data={props.item?.tolerancias || []}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <form id="residue-plan-quotation-packaging" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Cotação para:"
            text="Este é o acondicionamento a ser cotado."
          />
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={2}>
              <PageViewProperty
                label="Acondicionamento"
                text={props.refItem?.residuoAcondicionamento || '-'}
                size="sm"
              />
            </Grid.Col>

            <Grid.Col span={1}>
              <PageViewProperty label="Quantidade" text={builQuantityFromContext} size="sm" />
            </Grid.Col>

            <Grid.Col span={1}>
              <PageViewProperty
                label="Compartilhado?"
                text={props.refItem.compartilhado ? 'Sim' : 'Não'}
                size="sm"
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <Tabs items={tabs} defaultActiveKey="Cotacao" />
        </Paper>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="residue-plan-quotation-packaging">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
