/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Button, Group, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Trash } from 'tabler-icons-react';
import { Relacao } from '../../../../../../business/events/general';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import { EventRelationType } from '../../../../../../models/core/events.type';

type DataViewActionProps = {
  item: EventRelationType;
  confirmActionResult(items: EventRelationType[], type: Relacao, action: string, confirmed: boolean): void;
};

type ModalData = {
  opened: boolean;
  action: string;
};

export default function RelationFormViewActions(props: DataViewActionProps) {
  const [modalData, setModalData] = useState<ModalData>({ opened: false, action: '' });

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
        // centered={true}
        onClose={() => setModalData({ opened: false, action: '' })}
        title={`Gostaria de ${modalData.action} essa relação?`}
      >
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={3}>
            <PageViewProperty label="#" text={props.item.idEventoReferenciado} size="sm" />
            <PageViewProperty label="Status" text={props.item.eventoReferenciadoEventoStatus} size="sm" />
            <PageViewProperty label="Tipo" text={props.item.eventoReferenciadoEventoTipo} size="sm" />
          </SimpleGrid>
        </Paper>
        <Space h="lg" />

        <Group position="right">
          <Button
            color="secondary"
            // leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult(
                [props.item],
                props.item.codigoEventoRelacao,
                modalData.action,
                false
              );
            }}
          >
            Não
          </Button>
          <Button
            // color={modalData?.action === 'cancelar' ? 'orange' : 'red'}
            // leftIcon={<Check size={18} />}
            color="primary"
            onClick={() => {
              setModalData({ opened: false, action: '' });
              props.confirmActionResult([props.item], props.item.codigoEventoRelacao, modalData.action, true);
            }}
          >
            Sim
          </Button>
        </Group>
      </Modal>

      <Group>
        {props.item.codigoEventoRelacao === Relacao.Relacionado ? (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                setModalData({ opened: true, action: 'excluir' });
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        ) : (
          '-'
        )}
      </Group>
    </div>
  );
}
