import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Button, Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { DestinationType } from '../../../../models/core/destinations.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import destinationsDestination from '../../../../services/core/destinations.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { Permission } from '../../../../models/core/departments.type';

function DestinationSearch() {
  const navigate = useNavigate();

  const [data, setData] = useState<DestinationType[]>([]);
  const [loading, setLoading] = useState(false);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.DestinationSearch) || '[]'
    ) as DestinationType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({ residuoDestinoFinal: '' });
    }
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.DestinationSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await destinationsDestination.search({
        residuoDestinoFinal: inputFilterData?.residuoDestinoFinal || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.DestinationSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Destinos Finais',
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível pesquisar destinos finais.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: DestinationType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);

      switch (action) {
        case 'excluir':
          await destinationsDestination.delete({ idResiduoDestinoFinal: item.idResiduoDestinoFinal });
          setData(data.filter((x) => x.idResiduoDestinoFinal !== item.idResiduoDestinoFinal));
          break;
        case 'visualizar':
          navigate(`/destinations/${item.idResiduoDestinoFinal}`, {
            state: {
              destination: item,
            },
          });
          return;
        case 'editar':
          navigate(`/destinations/${item.idResiduoDestinoFinal}/edit`, {
            state: {
              destination: item,
            },
          });
          return;
        default:
          break;
      }

      showNotification({
        title: 'Destinos finais',
        message: `Destino final excluído com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Destinos finais',
        message: error?.isBusinessException
          ? error.description
          : `Não foi possível ${action} o destino final.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Destination}
        title="Destino Finais"
        description="Gerencie destinos finais."
        buttons={[
          <Button
            key="Adicionar"
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={() => navigate('/destinations/add')}
            data-permission={Permission.DestinationAdd}
          >
            Adicionar
          </Button>,
        ]}
      />
      <PageContent>
        <Filter filter={filter} clear={clear} loading={loading} />
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default DestinationSearch;
