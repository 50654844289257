import {
  Atom,
  Backhoe,
  Briefcase,
  BuildingSkyscraper,
  CalendarEvent,
  Checklist,
  Coin,
  Dashboard,
  HeartHandshake,
  Hierarchy,
  License,
  Package,
  Recycle,
  ReportSearch,
  Target,
  Tool,
  Truck,
  User,
  UserPlus,
  UserX,
  World,
} from 'tabler-icons-react';
import { Permission } from '../models/core/departments.type';

const LocalStorageKey = {
  ApplicationAccessToken: '@performa:ApplicationAccessToken',
  ApplicationVersion: '@performa:ApplicationVersion',
  User: '@performa:User',
  UserCredentials: '@performa:UserCredentials',
  UserAccessToken: '@performa:UserAccessToken',
  CoreDataReference: '@performa:CoreDataReference',
  BrasilAPIBanks: '@brasilAPI:banks',
  BrasilAPIStates: '@brasilAPI:states',
};

const SessionStorageKey = {
  DashboardGeneral: '@performa:DashboardGeneral',
  DashboardManagerial: '@performa:DashboardManagerial',

  DepartmentSearch: '@performa:DepartmentSearch',
  DismissalSearch: '@performa:DismissalSearch',
  InvitationSearch: '@performa:InvitationSearch',
  UserSearch: '@performa:UserSearch',

  ResidueSearch: '@performa:ResidueSearch',
  ServiceSearch: '@performa:ServiceSearch',
  PackagingSearch: '@performa:PackagingSearch',
  DestinationSearch: '@performa:DestinationSearch',
  EquipmentSearch: '@performa:EquipmentSearch',
  TreatmentSearch: '@performa:TreatmentSearch',
  VehicleSearch: '@performa:VehicleSearch',
  EntitySearch: '@performa:EntitySearch',

  ProposalSearch: '@performa:ProposalSearch',
  ContractSearch: '@performa:ContractSearch',
  EventSearch: '@performa:EventSearch',

  ContractStats: '@performa:ContractStatus',

  TempEntity: '@performa:TempEntity',
  TempEvent: '@performa:TempEvent',
  TempProposal: '@performa:TempProposal',

  BrasilAPICities: '@brasilAPI:cities_{stateCode}',
};

const Feature = {
  Reference: {
    Entity: {
      icon: BuildingSkyscraper,
      color: 'indigo',
      label: 'Entidades',
      link: '/entities',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.EntityAdd,
        Delete: Permission.EntityDelete,
        Edit: Permission.EntityEdit,
        Search: Permission.EntitySearch,
        View: Permission.EntityView,
      },
    },
    Residue: {
      icon: Atom,
      color: 'green',
      label: 'Resíduos',
      link: '/residues',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.ResidueAdd,
        Delete: Permission.ResidueDelete,
        Edit: Permission.ResidueEdit,
        Search: Permission.ResidueSearch,
        View: Permission.ResidueView,
      },
    },
    Service: {
      icon: Tool,
      color: 'violet',
      label: 'Serviços',
      link: '/services',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.ServiceAdd,
        Delete: Permission.ServiceDelete,
        Edit: Permission.ServiceEdit,
        Search: Permission.ServiceSearch,
        View: Permission.ServiceView,
      },
    },
    Packaging: {
      icon: Package,
      color: 'cyan',
      label: 'Acondicionamentos',
      link: '/packaging',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.PackagingAdd,
        Delete: Permission.PackagingDelete,
        Edit: Permission.PackagingEdit,
        Search: Permission.PackagingSearch,
        View: Permission.PackagingView,
      },
    },
    Destination: {
      icon: Target,
      color: 'pink',
      label: 'Destinos Finais',
      link: '/destinations',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.DestinationAdd,
        Delete: Permission.DestinationDelete,
        Edit: Permission.DestinationEdit,
        Search: Permission.DestinationSearch,
        View: Permission.DestinationView,
      },
    },
    Equipment: {
      icon: Backhoe,
      color: 'grape',
      label: 'Equipamentos',
      link: '/equipment',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.EquipmentAdd,
        Delete: Permission.EquipmentDelete,
        Edit: Permission.EquipmentEdit,
        Search: Permission.EquipmentSearch,
        View: Permission.EquipmentView,
      },
    },
    Treatment: {
      icon: Recycle,
      color: 'yellow',
      label: 'Tratamentos',
      link: '/treatments',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.TreatmentAdd,
        Delete: Permission.TreatmentDelete,
        Edit: Permission.TreatmentEdit,
        Search: Permission.TreatmentSearch,
        View: Permission.TreatmentView,
      },
    },
    Vehicle: {
      icon: Truck,
      color: 'lime',
      label: 'Veículos',
      link: '/vehicles',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.VehicleAdd,
        Delete: Permission.VehicleDelete,
        Edit: Permission.VehicleEdit,
        Search: Permission.VehicleSearch,
        View: Permission.VehicleView,
      },
    },
  },
  Managerial: {
    Department: {
      icon: Hierarchy,
      color: 'orange',
      label: 'Departamentos',
      link: '/departments',
      hasAddLink: true,
      subItems: [],
      permissions: undefined,
    },
    User: {
      icon: User,
      color: 'indigo',
      label: 'Usuários',
      link: '/users',
      hasAddLink: false,
      subItems: [],
      permissions: undefined,
    },
    Invitation: {
      icon: UserPlus,
      color: 'green',
      label: 'Convites',
      link: '/invitations',
      hasAddLink: true,
      subItems: [],
      permissions: undefined,
    },
    Dismissals: {
      icon: UserX,
      color: 'red',
      label: 'Desligamentos',
      link: '/dismissals',
      hasAddLink: false,
      subItems: [],
      permissions: undefined,
    },
  },
  Home: {
    Dashboard: {
      icon: Dashboard,
      color: 'blue',
      label: 'Dashboards',
      link: '/',
      hasAddLink: false,
      subItems: [
        { icon: World, label: 'Geral', link: '/', hasAddLink: false, restricted: false },
        {
          icon: Briefcase,
          label: 'Gerencial',
          link: '/dashboards/managerial',
          hasAddLink: false,
          restricted: true,
        },
      ],
      permissions: undefined,
    },
    Proposal: {
      icon: ReportSearch,
      color: 'indigo',
      label: 'Propostas',
      link: '/proposals',
      hasAddLink: true,
      subItems: [],
      permissions: {
        Add: Permission.ProposalAdd,
        Delete: Permission.ProposalDelete,
        Edit: Permission.ProposalEdit,
        Search: Permission.ProposalSearch,
        View: Permission.ProposalView,
        Print: Permission.ProposalPrint,
        Own: Permission.ProposalOwn,
      },
    },
    Contract: {
      icon: License,
      color: 'green',
      label: 'Contratos',
      link: '/contracts',
      hasAddLink: false,
      subItems: [],
      permissions: {
        Add: Permission.ContractAdd,
        Delete: Permission.ContractDelete,
        Edit: Permission.ContractEdit,
        Search: Permission.ContractSearch,
        View: Permission.ContractView,
        Cancel: Permission.ContractCancel,
      },
    },
    Event: {
      icon: CalendarEvent,
      color: 'violet',
      label: 'Eventos',
      link: '/events',
      hasAddLink: false,
      subItems: [
        { label: 'Todos', link: '/events', hasAddLink: false, restricted: false },
        {
          icon: HeartHandshake,
          label: 'Comercial',
          link: '/events?filter=commercial',
          hasAddLink: false,
          restricted: false,
        },
        {
          icon: Coin,
          label: 'Financeiro',
          link: '/events?filter=financial',
          hasAddLink: false,
          restricted: false,
        },
        {
          icon: Checklist,
          label: 'Operacional',
          link: '/events?filter=operational',
          hasAddLink: false,
          restricted: false,
        },
      ],
      permissions: {
        Add: Permission.EventAdd,
        Delete: Permission.EventDelete,
        Edit: Permission.EventEdit,
        Search: Permission.EventSearch,
        View: Permission.EventView,
        Print: Permission.EventPrint,
      },
    },
  },
};

const Chart = {
  Color: {
    'Receita (Potencial)': '#B2F2BB',
    Receita: '#51CF66',
    'Despesa (Potencial)': '#FFC9C9',
    Despesa: '#FF6B6B',
    'Imposto (Potencial)': '#FFD8A8',
    Imposto: '#FF922B',
    'Balanço (Potencial)': '#BAC8FF',
    Balanço: '#5C7CFA',

    'Tonelada(s) (Potencial)': '#D0BFFF',
    'Tonelada(s)': '#845EF7',
    'Metro(s) Cúbico(s) (Potencial)': '#96F2D7',
    'Metro(s) Cúbico(s)': '#20C997',

    'Acondicionamento (Potencial)': '#99E9F2',
    Acondicionamento: '#22B8CF',
    'Equipamento (Potencial)': '#EEBEFA',
    Equipamento: '#CC5DE8',
    'Veículo (Potencial)': '#D8F5A2',
    Veículo: '#94D82D',
  },
};

const Core = {
  SystemId: '00000000-0000-0000-0000-000000000000',
};

export enum EntityItemEnum {
  Packaging = 'Acondicionamento',
  Destination = 'Destino Final',
  Equipment = 'Equipamento',
  Service = 'Serviço',
  Treatment = 'Tratamento',
  Vehicle = 'Veículo',
}

const RecurrenceData = {
  Days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  Months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  Frequency: [
    { label: 'dia(s)', value: 'd' },
    { label: 'semana(s)', value: 'w' },
    { label: 'mês(es)', value: 'm' },
    { label: 'ano(s)', value: 'y' },
  ],
};

const Financial = {
  Margin: 40,
  Tax: 14.25,
};

export { Chart, Core, Feature, Financial, LocalStorageKey, RecurrenceData, SessionStorageKey };
