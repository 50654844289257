/* eslint-disable react/destructuring-assignment */
import { Group, Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import { ProposalResidueType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';

type DataViewProps = {
  data: ProposalResidueType;
};

export default function ResidueDocumentView(props: DataViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <Group>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Documentos"
            text="Documentos que estarão disponíveis e acordados entre as partes."
          />
        </Group>
        <Space h="xs" />
        <SimpleGrid cols={7}>
          <PageViewProperty
            label="Nota Fiscal"
            text={props.data.documento.notaFiscal ? 'Sim' : 'Não'}
            size="md"
          />
          <PageViewProperty label="XML" text={props.data.documento.xml ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty label="MTR" text={props.data.documento.mtr ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty label="SGA" text={props.data.documento.sga ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty
            label="Romaneio de coleta"
            text={props.data.documento.romaneioColeta ? 'Sim' : 'Não'}
            size="md"
          />
          <PageViewProperty
            label="Peso Origem"
            text={props.data.documento.pesoOrigem ? 'Sim' : 'Não'}
            size="md"
          />
          <PageViewProperty
            label="Peso Destino"
            text={props.data.documento.pesoDestino ? 'Sim' : 'Não'}
            size="md"
          />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.documento.idCriadoPor}
        criadoPor={props.data.documento.criadoPor}
        dataCriacao={props.data.documento.dataCriacao}
        idModificadoPor={props.data?.documento?.idModificadoPor || null}
        modificadoPor={props.data?.documento?.modificadoPor || null}
        dataModificacao={props.data?.documento?.dataModificacao || null}
        size="md"
      />
    </div>
  );
}
