/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import {
  Anchor,
  Avatar,
  Box,
  Breadcrumbs,
  Divider,
  Group,
  Paper,
  Space,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { FeatureType } from '../../../models/utils/constants.type';
import { validate } from '../../../utils/permission.utils';
import useCurrentUser from '../../../hooks/useCurrentUser';

type BreadcrumbType = {
  title: string;
  href: string;
};

type PageHeaderProps = {
  feature: FeatureType;
  title: string;
  description?: string | null;
  buttons?: any[] | null;
  breadcrumbs?: BreadcrumbType[] | null;
  padding?: number;
};

export default function PageHeader(props: PageHeaderProps) {
  const [currentUser] = useCurrentUser();

  const breadcrumbs = (props.breadcrumbs || []).map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Anchor href={item.href} key={index} size="xs" color={props.feature.color}>
      {item.title}
    </Anchor>
  ));

  return (
    <Paper style={{ padding: props.padding !== undefined && props.padding >= 0 ? props.padding : 16 }}>
      <Group>
        <Avatar radius="lg" size="lg" classNames={{ placeholder: 'custom-mantine-avatar-placeholder' }}>
          <ThemeIcon color={props.feature.color} variant="outline" size={48}>
            <props.feature.icon size={36} />
          </ThemeIcon>
        </Avatar>
        <Box sx={{ flex: 1 }}>
          <Text size="xl" color={props.feature.color} weight={500}>
            {props.title}
          </Text>
          <Text color="dimmed" size="md">
            {props.description}
          </Text>
        </Box>
        {props.buttons
          ?.filter((x) => !x.props?.hidden && validate(x.props?.['data-permission'], currentUser.permissoes))
          .map((button, index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>{button}</div>
            );
          })}
      </Group>
      {breadcrumbs.length > 0 && (
        <div>
          <Space h="sm" />
          <Breadcrumbs separator="/">{breadcrumbs}</Breadcrumbs>
        </div>
      )}
      <Divider my="sm" color={props.feature.color} />
    </Paper>
  );
}
