import { memo } from 'react';
import { Group, Text } from '@mantine/core';
import { Check, X } from 'tabler-icons-react';

type PasswordRequirementProps = {
  meets: boolean;
  label: string;
};

function PasswordRequirement({ meets, label }: PasswordRequirementProps) {
  return (
    <Group>
      <Text color={meets ? 'teal' : 'red'} size="sm">
        {meets ? <Check /> : <X />}
      </Text>
      <Text color={meets ? 'teal' : 'red'} size="sm" style={{ marginTop: -7 }}>
        {label}
      </Text>
    </Group>
  );
}

export default memo(PasswordRequirement);
