/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Grid, Group, Modal, Paper, Space, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Check, Eye, Scale, X, ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  ProposalServiceQuotationType,
  ProposalServiceType,
} from '../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../theme';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../utils/helper.utils';
import ProposalItemToleranceView from '../components/ProposalItemToleranceView';
import ServiceQuotationGeneralView from './ServiceQuotationView/ServiceQuotationGeneralView';

type ViewProps = {
  data: ProposalServiceType;
};

export default function ServiceQuotationView(props: ViewProps) {
  const [quotationItem, setQuotationItem] = useState<ProposalServiceQuotationType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalServiceQuotationType> = [
    {
      title: '#',
      key: 'idPropostaServicoCotacao',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = a.idPropostaServicoCotacao;
        const bValue = b.idPropostaServicoCotacao;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceQuotationType) => <div>{row.idPropostaServicoCotacao || '-'}</div>,
    },
    {
      title: 'Serviço',
      key: 'servico',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) =>
        a.servico.localeCompare(b.servico),
      render: (row: ProposalServiceQuotationType) => {
        return (
          <ProfileCardLink
            id={row.idServico.toString()}
            name={row.servico || '-'}
            nameSize="sm"
            description={row.servicoDescricao}
            descriptionSize="xs"
            linkPrefix="services"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Item',
      key: 'item',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aItem = a.residuoAcondicionamento || a.residuoEquipamento || '-';
        const bItem = b.residuoAcondicionamento || b.residuoEquipamento || '-';
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalServiceQuotationType) => {
        if (row.idResiduoAcondicionamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoAcondicionamento || '').toString()}
              name={row.residuoAcondicionamento || '-'}
              nameSize="sm"
              description={row.residuoAcondicionamentoDescricao}
              descriptionSize="xs"
              linkPrefix="packaging"
              showLink={false}
            />
          );
        }
        if (row.idResiduoEquipamento) {
          return (
            <ProfileCardLink
              id={(row.idResiduoEquipamento || '').toString()}
              name={row.residuoEquipamento || '-'}
              nameSize="sm"
              description={row.residuoEquipamentoDescricao}
              descriptionSize="xs"
              linkPrefix="equipment"
              showLink={false}
            />
          );
        }
        return (
          <ProfileCardLink
            id={newGuid()}
            name="Fornecedor"
            nameSize="sm"
            description={null}
            descriptionSize="xs"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Fornecedor',
      key: 'idFornecedor',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) =>
        (a.fornecedorNomeFantasia || a.fornecedorRazaoSocial || a.fornecedorNome || '').localeCompare(
          b.fornecedorNomeFantasia || b.fornecedorRazaoSocial || b.fornecedorNome || ''
        ),
      render: (row: ProposalServiceQuotationType) => {
        if (row.fornecedorCNPJ) {
          return (
            <ProfileCardLink
              id={row.idFornecedor.toString()}
              name={row.fornecedorNomeFantasia || row.fornecedorRazaoSocial || '-'}
              nameSize="sm"
              description={row.fornecedorNomeFantasia ? row.fornecedorRazaoSocial : row.fornecedorCNPJ}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink={false}
            />
          );
        }
        return (
          <ProfileCardLink
            id={row.idFornecedor.toString()}
            name={row.fornecedorNome || '-'}
            nameSize="sm"
            description={row.fornecedorCPF}
            descriptionSize="xs"
            linkPrefix="entities"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Preço',
      key: 'preco',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = a.preco || 0;
        const bValue = b.preco || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceQuotationType) => <div>{formatCurrency(row.preco)}</div>,
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      dataIndex: 'quantidade',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
    },
    {
      title: 'Frequência',
      key: 'frequencia',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) => {
        const aValue = `${
          a.idResiduoAcondicionamento === null || a.idResiduoEquipamento === null ? `${a.frequencia} ` : ''
        }${a.frequenciaUnidadeMedida1} / ${a.frequenciaUnidadeMedida2}`;
        const bValue = `${
          b.idResiduoAcondicionamento === null || b.idResiduoEquipamento === null ? `${b.frequencia} ` : ''
        }${b.frequenciaUnidadeMedida1} / ${b.frequenciaUnidadeMedida2}`;

        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalServiceQuotationType) => {
        return `${
          row.idResiduoAcondicionamento === null && row.idResiduoEquipamento === null
            ? `${row.frequencia} `
            : ''
        }${row.frequenciaUnidadeMedida1} / ${row.frequenciaUnidadeMedida2}`;
      },
    },
    {
      title: 'Primário?',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ProposalServiceQuotationType, b: ProposalServiceQuotationType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalServiceQuotationType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setQuotationItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  const buildTabs = () => {
    const tabs = [
      {
        key: 'Cotacao',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <ZoomMoney size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Cotação
            </Text>
          </Group>
        ),
        children: <ServiceQuotationGeneralView data={quotationItem!} />,
        forceRender: true,
      },
      {
        key: 'Tolerancias',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Scale size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Tolerâncias
            </Text>
          </Group>
        ),
        children: (
          <Paper shadow="xs" p="md" withBorder>
            <ProposalItemToleranceView data={quotationItem?.tolerancias || []} />
          </Paper>
        ),
        forceRender: true,
      },
    ];
    return tabs;
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Serviço/Cotação - Visualizar"
        size="50%"
      >
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <PageSection
              size="lg"
              color={Feature.Home.Proposal.color}
              label="Cotação para:"
              text="Este é o serviço a ser cotado e poderá estar acompanhado de um tipo de acondicionamento ou equipamento."
            />
            <Space h="xs" />

            <Grid columns={4}>
              <Grid.Col span={1}>
                <PageViewProperty
                  label="Serviço"
                  text={
                    <ProfileCardLink
                      id={props.data.idServico.toString()}
                      name={props.data.servico || '-'}
                      nameSize="sm"
                      description={props.data.servicoDescricao}
                      descriptionSize="xs"
                      linkPrefix="services"
                    />
                  }
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <PageViewProperty label="Quantidade" text={props.data.quantidade || '-'} size="sm" />
              </Grid.Col>

              {props.data.idResiduoAcondicionamento ? (
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Acondicionamento"
                    text={
                      <ProfileCardLink
                        id={(props.data.idResiduoAcondicionamento || '').toString()}
                        name={props.data.residuoAcondicionamento || '-'}
                        nameSize="sm"
                        description={props.data.residuoAcondicionamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="packaging"
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
              ) : props.data.idResiduoEquipamento ? (
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Equipamento"
                    text={
                      <ProfileCardLink
                        id={(props.data.idResiduoEquipamento || '').toString()}
                        name={props.data.residuoEquipamento || '-'}
                        nameSize="sm"
                        description={props.data.residuoEquipamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="equipment"
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
              ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
              )}

              <Grid.Col span={1}>
                <PageViewProperty
                  label="Frequência"
                  text={`${
                    props.data.idResiduoAcondicionamento === null || props.data.idResiduoEquipamento === null
                      ? `${props.data.frequencia} `
                      : ''
                  }${props.data.frequenciaUnidadeMedida1} / ${props.data.frequenciaUnidadeMedida2}`}
                  size="sm"
                />
              </Grid.Col>
            </Grid>
          </Paper>
          <Space h="lg" />

          <Paper shadow="xs" p="md" withBorder>
            <Tabs items={buildTabs()} defaultActiveKey="Cotacao" />
          </Paper>
        </div>
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Cotações"
        text="Cotações para este serviço."
      />
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data.cotacoes}
        columns={columns}
        rowKey={(item: ProposalServiceQuotationType) => item.idPropostaServicoCotacao || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
