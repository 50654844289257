import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, Key, Mail } from 'tabler-icons-react';
import { Alert, Button, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import authService from '../../../../../services/iam/auth.service';

type FormProps = {
  email: string;
  confirmationCode: string;
};

export default function EmailChange({ email }: { email: string }) {
  const STEP_CHANGE_EMAIL = 'change-email';
  const STEP_CHANGE_EMAIL_CONFIRM = 'change-email-confirm';

  const navigate = useNavigate();

  const [step, setStep] = useState(STEP_CHANGE_EMAIL);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<FormProps>({
    initialValues: {
      email: '',
      confirmationCode: '',
    },
    validate: {
      email: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^\S+@\S+$/.test(value.trim())) {
          return 'Formato inválido (Ex: nome@dominio.com)';
        }
        return null;
      },
      confirmationCode: (value) => {
        if (step === STEP_CHANGE_EMAIL_CONFIRM && value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (values: FormProps) => {
    try {
      setLoading(true);

      if (step === STEP_CHANGE_EMAIL) {
        await authService.changeEmail(values);
        setStep(STEP_CHANGE_EMAIL_CONFIRM);
        showNotification({
          title: 'Editar Perfil - Alterar Email',
          message: 'E-mail alterado com sucesso. Verifique seu e-mail e valide seu token nesse momento.',
          color: 'green',
        });
      } else {
        await authService.changeEmailConfirm(values);
        setSuccess(true);
        showNotification({
          title: 'Editar Perfil - Alterar Email',
          message: 'E-mail confirmado com sucesso.',
          color: 'green',
        });
        navigate('/logout', {
          replace: true,
        });
      }
    } catch (error: any) {
      showNotification({
        title: 'Editar Perfil - Alterar Email',
        message: error?.isBusinessException
          ? error.description
          : `Não foi possível ${step === STEP_CHANGE_EMAIL ? 'alterar' : 'confirmar'} seu e-mail.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
        Você só deve realizar esta operação caso você esteja pronto para verificar seu e-mail neste exato
        momento.
      </Alert>
      <Space h="xl" />
      <TextInput
        icon={<Mail size={15} />}
        label="E-mail atual"
        placeholder="Digite seu e-mail"
        type="email"
        maxLength={100}
        disabled
        value={email}
      />
      <Space h="xs" />
      <TextInput
        icon={<Mail size={15} />}
        label="Novo E-mail"
        placeholder="Digite seu novo e-mail"
        type="email"
        maxLength={100}
        required
        disabled={step === STEP_CHANGE_EMAIL_CONFIRM}
        {...form.getInputProps('email')}
      />
      {step === STEP_CHANGE_EMAIL_CONFIRM && <Space h="xs" />}
      {step === STEP_CHANGE_EMAIL_CONFIRM && (
        <TextInput
          icon={<Key size={15} />}
          label="Código de confirmação"
          description="Você receberá este código via e-mail"
          placeholder="Digite seu código de confirmação"
          maxLength={6}
          type="number"
          inputMode="numeric"
          required
          {...form.getInputProps('confirmationCode')}
        />
      )}
      <Space h="xl" />
      <Button fullWidth type="submit" loading={loading} disabled={success}>
        {step === STEP_CHANGE_EMAIL ? 'Alterar E-mail' : 'Confirmar E-mail'}
      </Button>
    </form>
  );
}
