import { Button, Group, PasswordInput, Popover, Progress, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Lock, Mail } from 'tabler-icons-react';
import PasswordRequirement from '../../../components/iam/PasswordRequirement/PasswordRequirement';
import SplitViewForm from '../../../components/layout/SplitViewForm/SplitViewForm';
import authService from '../../../services/iam/auth.service';

type FormProps = {
  email: string;
  password: string;
  confirmationCode: string;
};

export default function ChangePassword() {
  const queryParams = new URLSearchParams(useLocation().search);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<FormProps>({
    initialValues: {
      email: queryParams.get('email') || '',
      password: '',
      confirmationCode: queryParams.get('code') || '',
    },
    validate: {
      password: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value.trim())) {
          return 'Formato inválido';
        }
        return null;
      },
    },
  });

  const handleSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      await authService.forgotPasswordConfirm(values);
      setSuccess(true);
      showNotification({
        title: 'Redefinir senha',
        message: 'Senha alterada com sucesso.',
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Redefinir senha',
        message: error?.isBusinessException ? error.description : 'Não foi possível alterar sua senha.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  /*
  BEGIN - Password PopOver
  */
  // TODO: App: 078 - move this to another place (componentize password strength thing)
  const requirements = [
    { re: /\d/, label: 'Ao menos um número' },
    { re: /[a-z]/, label: 'Ao menos uma letra minúscula' },
    { re: /[A-Z]/, label: 'Ao menos uma letra maiúscula' },
  ];

  function getStrength(password: string) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
  }

  const [popoverOpened, setPopoverOpened] = useState(false);
  const [popoverValue, setPopoverValue] = useState('');

  const checks = requirements.map((requirement) => (
    <PasswordRequirement
      key={requirement.label}
      label={requirement.label}
      meets={requirement.re.test(popoverValue)}
    />
  ));

  const strength = getStrength(popoverValue);
  const mediumStrength = strength > 50 ? 'yellow' : 'red';
  const color = strength === 100 ? 'teal' : mediumStrength;
  /*
  END - Password PopOver
  */

  return (
    <SplitViewForm title="Redefinir senha">
      <form style={{ width: 450 }} onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <Space h="xl" />
        <TextInput
          icon={<Mail size={15} />}
          label="E-mail"
          placeholder="Digite seu e-mail"
          type="email"
          maxLength={100}
          disabled
          required
          {...form.getInputProps('email')}
        />
        <Space h="xs" />

        <Popover
          opened={popoverOpened}
          position="bottom-start"
          withArrow
          transition="pop-top-left"
          shadow="md"
        >
          <Popover.Target>
            <div onFocusCapture={() => setPopoverOpened(true)} onBlurCapture={() => setPopoverOpened(false)}>
              <PasswordInput
                style={{ width: 450 }}
                icon={<Lock size={15} />}
                label="Nova senha"
                placeholder="Digite sua nova senha"
                maxLength={50}
                required
                {...form.getInputProps('password')}
                value={popoverValue}
                onChange={(event) => {
                  setPopoverValue(event.currentTarget.value);
                  form.setFieldValue('password', event.currentTarget.value);
                }}
              />
            </div>
          </Popover.Target>
          <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
            <Progress color={color} value={strength} size={5} style={{ marginBottom: 10 }} />
            <PasswordRequirement label="Mínimo de 8 caracteres" meets={popoverValue.length > 7} />
            {checks}
          </Popover.Dropdown>
        </Popover>
        {/* <Space h="xs" />
        <TextInput
          icon={<Key size={15}/>}
          label="Código de confirmação"
          placeholder="Digite seu código de confirmação"
          maxLength={6}
          // disabled
          required
          {...form.getInputProps("confirmationCode")}
        /> */}
        <Space h="xl" />

        <Button fullWidth type="submit" loading={loading} disabled={success}>
          Alterar senha
        </Button>
        <Space h="xl" />
        {success && (
          <Group position="center">
            <Link to="/">
              <Button variant="outline">Voltar para o Login</Button>
            </Link>
          </Group>
        )}
      </form>
    </SplitViewForm>
  );
}
