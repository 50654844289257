/* eslint-disable react/destructuring-assignment */
import { Building, ClearAll, Id, Search, User } from 'tabler-icons-react';
import { Button, Grid, Group, MultiSelect, Paper, Select, SimpleGrid, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { EntityTypeType } from '../../../models/core/cache.type';
import { isCNPJValid, isCPFValid } from '../../../utils/helper.utils';

type FilterData = {
  tipos: string[];
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  incricaoEstadual: string | null;
  incricaoMunicipal: string | null;
  cpf: string | null;
  nome: string | null;
};

type FilterProps = {
  entityTypeData: EntityTypeType[];
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function EntitySearchModalFilter(props: FilterProps) {
  const TIPO_TODOS = '*';
  const TIPO_PF = 'PF';
  const TIPO_PJ = 'PJ';
  const [tipo, setTipo] = useState(TIPO_PJ);

  const form = useForm<FilterData>({
    initialValues: {
      tipos: [],
      cnpj: '',
      razaoSocial: '',
      nomeFantasia: '',
      incricaoEstadual: '',
      incricaoMunicipal: '',
      cpf: '',
      nome: '',
    },
    validate: {
      cnpj: (value: string | null) => {
        if (tipo === TIPO_PJ) {
          if (value?.trim() !== '' && !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value || '')) {
            return 'Formato inválido (Ex: 00.000.000/0000-00)';
          }
          if (value?.trim() !== '' && !isCNPJValid(value || '')) {
            return 'CNPJ inválido';
          }
        }
        return null;
      },
      cpf: (value: string | null) => {
        if (tipo === TIPO_PF) {
          if (value?.trim() !== '' && !/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value || '')) {
            return 'Formato inválido (Ex: 000.000.000-00)';
          }
          if (value?.trim() !== '' && !isCPFValid(value || '')) {
            return 'CPF inválido';
          }
        }
        return null;
      },
    },
  });

  const handleSubmit = async (formData: FilterData) => {
    const tempFormData = JSON.parse(JSON.stringify(formData)) as FilterData;

    tempFormData.tipos = formData.tipos || null;

    if (tipo === TIPO_TODOS) {
      tempFormData.cnpj = null;
      tempFormData.razaoSocial = null;
      tempFormData.nomeFantasia = null;
      tempFormData.incricaoEstadual = null;
      tempFormData.incricaoMunicipal = null;
      tempFormData.cpf = null;
      tempFormData.nome = null;
    } else if (tipo === TIPO_PF) {
      tempFormData.cpf = tempFormData.cpf || null;
      tempFormData.nome = tempFormData.nome || null;
      tempFormData.cnpj = null;
      tempFormData.razaoSocial = null;
      tempFormData.nomeFantasia = null;
      tempFormData.incricaoEstadual = null;
      tempFormData.incricaoMunicipal = null;
    } else {
      tempFormData.cnpj = tempFormData.cnpj || null;
      tempFormData.razaoSocial = tempFormData.razaoSocial || null;
      tempFormData.nomeFantasia = tempFormData.nomeFantasia || null;
      tempFormData.incricaoEstadual = tempFormData.incricaoEstadual || null;
      tempFormData.incricaoMunicipal = tempFormData.incricaoMunicipal || null;
      tempFormData.cpf = null;
      tempFormData.nome = null;
    }

    props.filter(tempFormData);
  };

  return (
    <form id="entity-search" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={1}>
            <Select
              label="Tipo"
              description="Filtro: -"
              placeholder="Todos..."
              data={[
                { label: 'Pessoa Física', value: TIPO_PF },
                { label: 'Pessoa Jurídica', value: TIPO_PJ },
              ]}
              value={tipo}
              onChange={(event) => setTipo(event || TIPO_TODOS)}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <MultiSelect
              label="Escopos"
              description="Filtro: igual"
              placeholder="Todos..."
              data={props.entityTypeData.map((x) => {
                return {
                  value: x.codigoEntidadeTipo,
                  label: x.entidadeTipo,
                };
              })}
              clearable
              {...form.getInputProps('tipos')}
            />
          </Grid.Col>
        </Grid>
        {tipo === TIPO_PF && (
          <div>
            <Space h="md" />
            <SimpleGrid cols={5}>
              <NumberFormat
                icon={<Id size={15} />}
                label="CPF"
                description="Filtro: igual"
                placeholder="Todos..."
                mask="_"
                format="###.###.###-##"
                customInput={TextInput}
                {...form.getInputProps('cpf')}
              />
              <TextInput
                icon={<User size={15} />}
                label="Nome"
                description="Filtro: contém"
                placeholder="Todos..."
                maxLength={100}
                {...form.getInputProps('nome')}
              />
            </SimpleGrid>
          </div>
        )}
        {tipo === TIPO_PJ && (
          <div>
            <Space h="md" />
            <SimpleGrid cols={5}>
              <NumberFormat
                icon={<Id size={15} />}
                label="CNPJ"
                description="Filtro: igual"
                placeholder="Todos..."
                mask="_"
                format="##.###.###/####-##"
                customInput={TextInput}
                {...form.getInputProps('cnpj')}
              />
              <TextInput
                icon={<Building size={15} />}
                label="Razão Social"
                description="Filtro: contém"
                placeholder="Todos..."
                maxLength={500}
                {...form.getInputProps('razaoSocial')}
              />
              <TextInput
                icon={<Building size={15} />}
                label="Nome Fantasia"
                description="Filtro: contém"
                placeholder="Todos..."
                maxLength={500}
                {...form.getInputProps('nomeFantasia')}
              />
              <TextInput
                icon={<Id size={15} />}
                label="Inscrição Estadual"
                description="Filtro: igual"
                placeholder="Todos..."
                maxLength={50}
                {...form.getInputProps('incricaoEstadual')}
              />
              <TextInput
                icon={<Id size={15} />}
                label="Inscrição Municipal"
                description="Filtro: igual"
                placeholder="Todos..."
                maxLength={50}
                {...form.getInputProps('incricaoMunicipal')}
              />
            </SimpleGrid>
          </div>
        )}
        <Space h="md" />
        <SimpleGrid cols={1}>
          <Group>
            <Button
              color="secondary"
              leftIcon={<ClearAll size={18} />}
              disabled={props.loading}
              onClick={() => {
                form.reset();
                props.clear();
                setTipo(TIPO_TODOS);
              }}
            >
              Limpar
            </Button>
            <Button
              leftIcon={<Search size={18} />}
              type="button"
              form="entity-search"
              onClick={() => handleSubmit(form.values)}
              loading={props.loading}
            >
              Pesquisar
            </Button>
          </Group>
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { EntitySearchModalFilter };
export type { FilterData };
