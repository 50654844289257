/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  SegmentedControl,
  Select,
  SimpleGrid,
  Space,
  Switch,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DeviceFloppy, Notes, Ruler2 } from 'tabler-icons-react';
import PackagingSearch from '../../../../../../../../../components/core/PackagingSearch/PackagingSearch';
import { UnitOfMeasureType } from '../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../models/core/core.type';
import { EventVehiclePackagingType } from '../../../../../../../../../models/core/events.type';
import { PackagingType } from '../../../../../../../../../models/core/packaging.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string;
  residuoAcondicionamentoDescricao: string | null;

  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;

  compartilhado: boolean;
  enviar: string;
  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventVehiclePackagingType | null;
  item: EventVehiclePackagingType | null;
  idEventoVeiculo: number | undefined;
  callback(item: EventVehiclePackagingType | null, action: string, confirmed: boolean): void;
};

export default function ResiduePlanPackagingFormAddEdit(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoAcondicionamento: props.item?.idResiduoAcondicionamento || null,
      residuoAcondicionamento: props.item?.residuoAcondicionamento || '',
      residuoAcondicionamentoDescricao: props.item?.residuoAcondicionamentoDescricao || null,

      quantidade: props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida.toString() ||
        props.referenceData.unitOfMeasures
          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
          ?.idUnidadeMedida.toString() ||
        null,

      compartilhado: props.item ? props.item.compartilhado : false,
      enviar: props.item?.enviar.toString() || true.toString(),
      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoAcondicionamento: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoAcondicionamento || ''}
    |${Number(props.origItem?.quantidade) || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.compartilhado || ''}
    |${props.origItem?.enviar || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoAcondicionamento || ''}
    |${Number(form.values.quantidade) || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.compartilhado || ''}
    |${form.values.enviar || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          enviar: (values.enviar === true.toString()).toString(),
        };
      }
    }

    const formItem: EventVehiclePackagingType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idEventoVeiculo: props.idEventoVeiculo || -1,
      enviar: values.enviar === true.toString(),
    };

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.observacao = formItem.observacao?.trim() || null;

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form id="residue-plan-packaging" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={3}>
            <PackagingSearch
              label="Acondicionamento"
              formItem={form.getInputProps('residuoAcondicionamento')}
              idsToBeDisabled={[]}
              callback={(item: PackagingType | null) => {
                if (item) {
                  form.setFieldValue('idResiduoAcondicionamento', item.idResiduoAcondicionamento);
                  form.setFieldValue('residuoAcondicionamento', item.residuoAcondicionamento);
                  form.setFieldValue('residuoAcondicionamentoDescricao', item.descricao);
                }
              }}
              disabled={false}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Input.Wrapper label="Quantidade" required>
              <div />
            </Input.Wrapper>
            <Group spacing="xs">
              <NumberInput
                style={{ width: 125 }}
                placeholder="Quantidade"
                min={1}
                defaultValue={1}
                step={1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                {...form.getInputProps('quantidade')}
              />
              <Select
                icon={<Ruler2 size={15} />}
                placeholder="Selecione..."
                data={props.referenceData.unitOfMeasures
                  .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                  .map((x) => {
                    return {
                      value: x.idUnidadeMedida.toString(),
                      label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                      group: x.tipo,
                    };
                  })}
                searchable
                disabled
                required
                {...form.getInputProps('quantidadeIdUnidadeMedida')}
              />
            </Group>
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <SimpleGrid cols={5}>
          <div>
            <Input.Wrapper label="Compartilhado?" required>
              <div />
            </Input.Wrapper>
            <Switch {...form.getInputProps('compartilhado', { type: 'checkbox' })} />
          </div>
          <div>
            <Input.Wrapper label="Tipo" required>
              <div />
            </Input.Wrapper>
            <SegmentedControl
              color="primary"
              data={[
                { label: 'Enviar', value: true.toString() },
                { label: 'Retirar', value: false.toString() },
              ]}
              {...form.getInputProps('enviar')}
            />
          </div>
        </SimpleGrid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o acondicionamento"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />

      <Group position="right">
        <Button
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="residue-plan-packaging"
          onClick={() => handleSubmit(form.values)}
        >
          Salvar
        </Button>
      </Group>
    </form>
  );
}
