/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Mix, MixConfig } from '@ant-design/plots';
import { Alert, Button, Grid, Group, LoadingOverlay, Paper, Space, Stack, Switch, Text } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertTriangle, Table as ITable, PlugConnectedX, Reload, TableOff } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { ContractType } from '../../../../../../../models/core/contracts.type';
import {
  ContractSingleEstimateConsolidateType,
  ContractSingleEstimateEstimateType,
  ContractSingleEstimateType,
  ReportItem,
} from '../../../../../../../models/core/report.type';
import reportsService from '../../../../../../../services/core/report.service';
import theme from '../../../../../../../theme';
import cacheUtils from '../../../../../../../utils/cache.utils';
import { Feature, RecurrenceData, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../../utils/formatter.utils';
import { convertUnitOfMeasure } from '../../../../../../../utils/helper.utils';
import { getMonths } from '../../../../../../../utils/moment-recur.utils';

type FormViewProps = {
  data: ContractType;
  height: number | undefined;
};

type FilterData = {
  periodo: Date[];
  abaixoEstimativa: boolean;
};

type Response = {
  filterConfig: FilterData;
  data: ContractSingleEstimateType;
  badData: ContractSingleEstimateConsolidateType[];
  quantityMassChartConfig: MixConfig;
  quantityVolumeChartConfig: MixConfig;
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Estimativas de coleta de resíduos',
  description: 'Comparação entre as estimativas de coleta de resíduos e sua execução por mês.',
  cacheKey: `${SessionStorageKey.ContractStats}-{ID}-${ReportItem.ContratoIndividualEstimativas}`,
  filterConfig: {
    abaixoEstimativa: false,
  },
  dataConfig: {
    exportable: true,
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (a: ContractSingleEstimateConsolidateType, b: ContractSingleEstimateConsolidateType) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleEstimateConsolidateType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (a: ContractSingleEstimateConsolidateType, b: ContractSingleEstimateConsolidateType) =>
          new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: ContractSingleEstimateConsolidateType) => formatDateToString(row.data),
      },
      {
        title: 'Resíduo',
        key: 'residuo',
        sorter: (a: ContractSingleEstimateConsolidateType, b: ContractSingleEstimateConsolidateType) =>
          a.residuoCliente.localeCompare(b.residuoCliente),
        render: (row: ContractSingleEstimateConsolidateType) => {
          return (
            <ProfileCardLink
              id={row.idResiduo.toString()}
              name={row.residuoCliente}
              nameSize="sm"
              description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
                row.residuoClientePerigoso ? 'Sim' : 'Não'
              }`}
              descriptionSize="xs"
              linkPrefix="residues"
              showLink
            />
          );
        },
      },
      {
        title: 'Estimativa',
        key: 'estimativa',
        sorter: (a: ContractSingleEstimateConsolidateType, b: ContractSingleEstimateConsolidateType) => {
          const aItem = `${a.estimativa} ${a.estimativaUnidadeMedida1} / ${a.estimativaUnidadeMedida2}`;
          const bItem = `${b.estimativa} ${b.estimativaUnidadeMedida1} / ${b.estimativaUnidadeMedida2}`;
          return aItem.localeCompare(bItem);
        },
        render: (row: ContractSingleEstimateConsolidateType) => {
          return (
            <div>{`${row.estimativa} ${row.estimativaUnidadeMedida1} / ${row.estimativaUnidadeMedida2}`}</div>
          );
        },
      },
      {
        title: 'Quantidade',
        key: 'quantidade',
        sorter: (a: ContractSingleEstimateConsolidateType, b: ContractSingleEstimateConsolidateType) => {
          const aValue = a.quantidade;
          const bValue = b.quantidade;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractSingleEstimateConsolidateType) => (
          <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
        ),
      },
    ],
  },

  chartConfigQuantityMass: {
    plots: [
      {
        type: 'line',
        region: {
          start: {
            x: 0,
            y: 0,
          },
          end: {
            x: 1,
            y: 0.5,
          },
        },
        options: {
          data: [],
          xField: 'mesAno',
          yField: 'quantidade',
          yAxis: {
            title: {
              text: 'Tonelada(s)',
              style: {
                fontSize: 14,
              },
            },
          },
          tooltip: {
            showCrosshairs: true,
            shared: true,
            formatter: (el) => ({
              name: el.referencia,
              value: `${el.quantidade} ton`,
            }),
          },
          seriesField: 'referencia',
          interactions: [
            {
              type: 'association-active',
            },
            {
              type: 'association-highlight',
            },
          ],
        },
      },
      {
        type: 'line',
        region: {
          start: {
            x: 0,
            y: 0.6,
          },
          end: {
            x: 1,
            y: 1,
          },
        },
        options: {
          data: [],
          xField: 'mesAno',
          yField: 'estimativa',
          yAxis: {
            title: {
              text: 'Tonelada(s)',
              style: {
                fontSize: 14,
              },
            },
          },
          tooltip: {
            showCrosshairs: true,
            shared: true,
            formatter: (el) => ({
              name: el.referencia,
              value: `${el.estimativa} ton`,
            }),
          },
          seriesField: 'referencia',
          interactions: [
            {
              type: 'association-highlight',
            },
          ],
        },
      },
    ],
  } as MixConfig,

  chartConfigQuantityVolume: {
    plots: [
      {
        type: 'line',
        region: {
          start: {
            x: 0,
            y: 0,
          },
          end: {
            x: 1,
            y: 0.5,
          },
        },
        options: {
          data: [],
          xField: 'mesAno',
          yField: 'quantidade',
          yAxis: {
            title: {
              text: 'Metro(s) cúbico(s)',
              style: {
                fontSize: 14,
              },
            },
          },
          tooltip: {
            formatter: (el) => ({
              name: el.referencia,
              value: `${el.quantidade} m³`,
            }),
          },
          seriesField: 'referencia',
          interactions: [
            {
              type: 'association-active',
            },
            {
              type: 'association-highlight',
            },
          ],
        },
      },
      {
        type: 'line',
        region: {
          start: {
            x: 0,
            y: 0.6,
          },
          end: {
            x: 1,
            y: 1,
          },
        },
        options: {
          data: [],
          xField: 'mesAno',
          yField: 'estimativa',
          yAxis: {
            title: {
              text: 'Metro(s) cúbico(s)',
              style: {
                fontSize: 14,
              },
            },
          },
          tooltip: {
            formatter: (el) => ({
              name: el.referencia,
              value: `${el.estimativa} m³`,
            }),
          },
          seriesField: 'referencia',
          interactions: [
            {
              type: 'association-highlight',
            },
          ],
        },
      },
    ],
  } as MixConfig,
};

export default function ContractSingleEstimates(props: FormViewProps) {
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    unitOfMeasures: UnitOfMeasureType[];
  }>({
    unitOfMeasures: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          periodo: [
            moment(props.data.dataInicial, 'yyyy-MM-DD').toDate(),
            moment(
              props.data.dataFinal > props.data.dataInicial
                ? props.data.dataFinal
                : props.data.dataFinalOriginal,
              'yyyy-MM-DD'
            ).toDate(),
          ],
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idContrato: props.data.idContrato,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.contratoIndividualEstimativas(searchCriteria);
    data.consolidado =
      data.atual.map((x) => {
        const estimativa = data.estimativas.find((y) => y.idPropostaResiduo === x.idPropostaResiduo)!;
        return { ...x, ...estimativa };
      }) || [];

    return data;
  };

  const buildChartData = async (data: ContractSingleEstimateType) => {
    referenceData = {
      unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
    };

    const dataInicial = form.values.periodo[0];
    const dataFinal = form.values.periodo[1]; // props.data.dataFinal);
    const meses = getMonths(dataInicial, dataFinal);

    const toneladaUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Massa' && x.unidadeMedidaSigla === 'ton'
    )!;
    const metroCubicoUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Volume' && x.unidadeMedidaSigla === 'm³'
    )!;
    const mesUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'mês'
    )!;

    const commonQuantityData: (ContractSingleEstimateConsolidateType & {
      mesAno: {
        key: number;
        order: number;
        year: number;
        month: number;
      };
      estimativaMassa: number;
      estimativaMassaUnidadeMedida: UnitOfMeasureType;
      estimativaVolume: number;
      estimativaVolumeUnidadeMedida: UnitOfMeasureType;
      estimativaTempo: number;
      estimativaTempoUnidadeMedida: UnitOfMeasureType;
      quantidadeMassa: number;
      quantidadeMassaUnidadeMedida: UnitOfMeasureType;
      quantidadeVolume: number;
      quantidadeVolumeUnidadeMedida: UnitOfMeasureType;
      eVolume: boolean;
      invalido: boolean;
    })[] = data.consolidado.map((x) => {
      return {
        ...x,
        mesAno: getMonths(x.data, x.data)[0],
        estimativaMassa: x.estimativaIdUnidadeMedida1 === toneladaUoM.idUnidadeMedida ? x.estimativa : 0,
        estimativaMassaUnidadeMedida: toneladaUoM,
        estimativaVolume: x.estimativaIdUnidadeMedida1 === metroCubicoUoM.idUnidadeMedida ? x.estimativa : 0,
        estimativaVolumeUnidadeMedida: metroCubicoUoM,
        estimativaTempo: x.estimativaIdUnidadeMedida2 === mesUoM.idUnidadeMedida ? 1 : 0,
        estimativaTempoUnidadeMedida: mesUoM,
        quantidadeMassa: x.quantidadeIdUnidadeMedida === toneladaUoM.idUnidadeMedida ? x.quantidade : 0,
        quantidadeMassaUnidadeMedida: toneladaUoM,
        quantidadeVolume: x.quantidadeIdUnidadeMedida === metroCubicoUoM.idUnidadeMedida ? x.quantidade : 0,
        quantidadeVolumeUnidadeMedida: metroCubicoUoM,
        eVolume: x.estimativaIdUnidadeMedida1 === metroCubicoUoM.idUnidadeMedida,
        invalido: false,
      };
    });

    // converting all UoMs to common ones
    for (const item of commonQuantityData) {
      if (item.estimativaMassa === 0) {
        const quantidadeUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.estimativaIdUnidadeMedida1
        )!;
        const conversaoMassa = convertUnitOfMeasure(quantidadeUoM, toneladaUoM, item.estimativa);
        if (conversaoMassa.valid) {
          item.estimativaMassa = conversaoMassa.calc || 0;
          item.estimativaMassaUnidadeMedida = toneladaUoM;
        } else {
          item.estimativaMassa = -1;
          item.estimativaMassaUnidadeMedida = quantidadeUoM;
        }
      }

      if (item.estimativaTempo === 0) {
        const tempoUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.estimativaIdUnidadeMedida2
        )!;
        const conversaoTempo = convertUnitOfMeasure(tempoUoM, mesUoM, 1);
        if (conversaoTempo.valid) {
          item.estimativaTempo = conversaoTempo.calc || 0;
          item.estimativaTempoUnidadeMedida = tempoUoM;
        } else if (tempoUoM?.unidadeMedidaSigla === 'per') {
          item.estimativaTempo = 1 / meses.length;
          item.estimativaTempoUnidadeMedida = tempoUoM;
        }
      }

      if (item.quantidadeMassa === 0) {
        const massaUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.quantidadeIdUnidadeMedida
        )!;
        const conversaoMassa = convertUnitOfMeasure(massaUoM, toneladaUoM, item.quantidade);
        if (conversaoMassa.valid) {
          item.quantidadeMassa = conversaoMassa.calc || 0;
          item.quantidadeMassaUnidadeMedida = toneladaUoM;
        } else {
          item.quantidadeMassa = -1;
          item.quantidadeMassaUnidadeMedida = massaUoM;
        }
      }

      if (
        !convertUnitOfMeasure(item.estimativaMassaUnidadeMedida, item.quantidadeMassaUnidadeMedida, 1).valid
      ) {
        item.invalido = true;
      }
    }

    const commonEstimateData: (ContractSingleEstimateEstimateType & {
      estimativaMassa: number;
      estimativaMassaUnidadeMedida: UnitOfMeasureType;
      estimativaVolume: number;
      estimativaVolumeUnidadeMedida: UnitOfMeasureType;
      estimativaTempo: number;
      estimativaTempoUnidadeMedida: UnitOfMeasureType;
      eVolume: boolean;
      invalido: boolean;
    })[] = data.estimativas.map((x) => {
      return {
        ...x,
        estimativaMassa: x.estimativaIdUnidadeMedida1 === toneladaUoM.idUnidadeMedida ? x.estimativa : 0,
        estimativaMassaUnidadeMedida: toneladaUoM,
        estimativaVolume: x.estimativaIdUnidadeMedida1 === metroCubicoUoM.idUnidadeMedida ? x.estimativa : 0,
        estimativaVolumeUnidadeMedida: metroCubicoUoM,
        estimativaTempo: x.estimativaIdUnidadeMedida2 === mesUoM.idUnidadeMedida ? 1 : 0,
        estimativaTempoUnidadeMedida: mesUoM,
        eVolume: x.estimativaIdUnidadeMedida1 === metroCubicoUoM.idUnidadeMedida,
        invalido: false,
      };
    });

    // converting all UoMs to common ones
    for (const item of commonEstimateData) {
      if (item.estimativaMassa === 0) {
        const quantidadeUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.estimativaIdUnidadeMedida1
        )!;
        const conversaoMassa = convertUnitOfMeasure(quantidadeUoM, toneladaUoM, item.estimativa);
        if (conversaoMassa.valid) {
          item.estimativaMassa = conversaoMassa.calc || 0;
          item.estimativaMassaUnidadeMedida = toneladaUoM;
        } else {
          item.estimativaMassa = -1;
          item.estimativaMassaUnidadeMedida = quantidadeUoM;
        }
      }

      if (item.estimativaTempo === 0) {
        const tempoUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.estimativaIdUnidadeMedida2
        )!;
        const conversaoTempo = convertUnitOfMeasure(tempoUoM, mesUoM, 1);
        if (conversaoTempo.valid) {
          item.estimativaTempo = conversaoTempo.calc || 0;
          item.estimativaTempoUnidadeMedida = tempoUoM;
        } else if (tempoUoM?.unidadeMedidaSigla === 'per') {
          item.estimativaTempo = 1 / meses.length;
          item.estimativaTempoUnidadeMedida = tempoUoM;
        }
      }
    }

    // group by
    const finalData = [];
    const groupedData = lodash.groupBy(commonQuantityData, 'idPropostaResiduo');
    for (const key of Object.keys(groupedData)) {
      for (const mesAno of meses) {
        const items = groupedData[key];
        const referencia = items[0].residuoCliente;

        const estimativaMassa = items[0].estimativaMassa / items[0].estimativaTempo;
        const estimativaVolume = items[0].estimativaVolume / items[0].estimativaTempo;
        const { eVolume } = items[0];

        const quantidadeMassa = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.quantidadeMassa, 0);
        const quantidadeVolume = items
          .filter((x) => x.mesAno.key === mesAno.key)
          .reduce((a, b: any) => a + b.quantidadeVolume, 0);

        finalData.push({
          idPropostaResiduo: items[0].idPropostaResiduo,
          ordem: mesAno.order,
          mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
          referencia,
          estimativa: Number((eVolume ? estimativaVolume : estimativaMassa).toPrecision(3)),
          quantidade: Number((eVolume ? quantidadeVolume : quantidadeMassa).toPrecision(3)),
          eVolume,
        });
      }
    }

    const estimateFinalData = [];
    for (const item of commonEstimateData) {
      const referencia = item.residuoCliente;
      const estimativaMassa = item.estimativaMassa / item.estimativaTempo;
      const estimativaVolume = item.estimativaVolume / item.estimativaTempo;

      for (const mesAno of meses) {
        estimateFinalData.push({
          idPropostaResiduo: item.idPropostaResiduo,
          ordem: mesAno.order,
          mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
          referencia,
          estimativa: Number((item.eVolume ? estimativaVolume : estimativaMassa).toPrecision(3)),
          eVolume: item.eVolume,
        });
      }
    }

    const finalChartData = {
      finalData: finalData.sort((a, b) => a.ordem - b.ordem),
      badData: commonQuantityData.filter((x) => x.invalido),
      estimateData: estimateFinalData,
    };

    if (form.values.abaixoEstimativa) {
      finalChartData.finalData = finalChartData.finalData.filter((x) => x.quantidade < x.estimativa);
      finalChartData.estimateData = finalChartData.estimateData.filter((x) =>
        finalChartData.finalData.map((y) => y.idPropostaResiduo).includes(x.idPropostaResiduo)
      );
    }

    return finalChartData;
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = (tempResult?.response?.data as ContractSingleEstimateType) || {};

    if (force) {
      data = await searchMe();
    }
    const chartData = await buildChartData(data);

    const massData = chartData.finalData.filter((x) => !x.eVolume);
    const estimateMassData = chartData.estimateData.filter((x) => !x.eVolume);
    const quantityMassPlots = componentConfig.chartConfigQuantityMass.plots as any[];
    quantityMassPlots[0].options.data = massData;
    quantityMassPlots[1].options.data = estimateMassData;

    const volumeData = chartData.finalData.filter((x) => x.eVolume);
    const estimateVolumeData = chartData.estimateData.filter((x) => x.eVolume);
    const quantityVolumePlots = componentConfig.chartConfigQuantityVolume.plots as any[];
    quantityVolumePlots[0].options.data = volumeData;
    quantityVolumePlots[1].options.data = estimateVolumeData;

    return {
      filterConfig: form.values,
      data,
      badData: chartData.badData,
      quantityMassChartConfig: {
        ...componentConfig.chartConfigQuantityMass,
        plots: quantityMassPlots,
      },
      quantityVolumeChartConfig: {
        ...componentConfig.chartConfigQuantityVolume,
        plots: quantityVolumePlots,
      },
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', props.data.idContrato.toString());

    const loadInitialData = async () => {
      setLoading(true);
      setReferenceData({
        unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
      });
      setLoading(false);
    };

    try {
      loadInitialData();
      load(() => {}, true);
    } catch (error) {}

    return () => {
      sessionStorage.removeItem(componentConfig.cacheKey);
    };
  }, []);

  useEffect(() => {
    const filterConfig = result.response?.filterConfig;
    const abaixoEstimativaChanged = form.values.abaixoEstimativa !== filterConfig?.abaixoEstimativa;

    if (abaixoEstimativaChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  const noDataView = () => {
    return (
      <Stack spacing="md" align="center">
        <TableOff color={theme?.colors?.primary?.[6]} size={32} />
        <Text>Sem dados para mostrar.</Text>
      </Stack>
    );
  };

  const noQuantityMass =
    (result.response?.quantityMassChartConfig?.plots?.[0]?.options?.data?.length ||
      result.response?.quantityMassChartConfig?.plots?.[1]?.options?.data?.length ||
      0) === 0;
  const noQuantityVolume =
    (result.response?.quantityVolumeChartConfig?.plots?.[0]?.options?.data?.length ||
      result.response?.quantityVolumeChartConfig?.plots?.[1]?.options?.data?.length ||
      0) === 0;

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Contract.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Contract.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading ||
              (result.response?.data?.consolidado || []).length === 0 ||
              !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Contrato - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Contract.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data.consolidado}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} overlayBlur={3} />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Group>
                <div>
                  <Switch
                    label="Abaixo da estimativa apenas?"
                    {...form.getInputProps('abaixoEstimativa', { type: 'checkbox' })}
                  />
                </div>
              </Group>
            </Group>
          </Paper>
          <Space h="md" />

          {result.error ? (
            <Stack spacing="md" align="center">
              <PlugConnectedX color="red" size={32} />
              <Text>{result.error}</Text>
            </Stack>
          ) : result.loaded ? (
            (result.response?.data.consolidado || []).length > 0 ? (
              <div>
                {(result.response?.badData || []).length > 0 && (
                  <>
                    <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                      <Group position="apart">
                        <div>
                          Existe ao menos uma medição que não foi possível de ser convertida para a unidade de
                          medida da estimativa.
                        </div>
                        <Button
                          size="xs"
                          color="orange"
                          leftIcon={<ITable size={14} />}
                          onClick={() => {
                            modals.openModal({
                              title: `Contrato - Dados Irregulares`,
                              size: '80%',
                              closeOnClickOutside: false,
                              children: (
                                <Paper shadow="xs" p="md" withBorder>
                                  <PageSection
                                    size="lg"
                                    color={Feature.Home.Contract.color}
                                    label={componentConfig.title}
                                    text={componentConfig.description}
                                  />
                                  <Space h="xs" />

                                  <Paper shadow="xs" p="md" withBorder>
                                    <Table
                                      showSorterTooltip={false}
                                      size="small"
                                      dataSource={result.response?.badData}
                                      columns={componentConfig.dataConfig.columns}
                                      rowKey={componentConfig.dataConfig.rowKey}
                                      pagination={{
                                        pageSizeOptions: [10, 25, 50],
                                        showSizeChanger: true,
                                        showTotal: (total, range) =>
                                          `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                                      }}
                                    />
                                  </Paper>
                                </Paper>
                              ),
                            });
                          }}
                        >
                          Dados
                        </Button>
                      </Group>
                    </Alert>
                    <Space h="xl" />
                  </>
                )}

                <Grid columns={4}>
                  <Grid.Col span={noQuantityMass === noQuantityVolume ? 2 : noQuantityVolume ? 3 : 1}>
                    <Paper shadow="xs" p="md" withBorder>
                      {noQuantityMass ? (
                        noDataView()
                      ) : (
                        <Mix {...result.response?.quantityMassChartConfig!} height={props.height} />
                      )}
                    </Paper>
                  </Grid.Col>
                  <Grid.Col span={noQuantityMass === noQuantityVolume ? 2 : noQuantityMass ? 3 : 1}>
                    <Paper shadow="xs" p="md" withBorder>
                      {noQuantityVolume ? (
                        noDataView()
                      ) : (
                        <Mix {...result.response?.quantityVolumeChartConfig!} height={props.height} />
                      )}
                    </Paper>
                  </Grid.Col>
                </Grid>
              </div>
            ) : (
              <Paper shadow="xs" p="md" withBorder>
                {noDataView()}
              </Paper>
            )
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <Reload color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Esperando atualização</Text>
              </Stack>
            </Paper>
          )}
        </div>
      </Paper>
    </Paper>
  );
}
