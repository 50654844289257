/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Check, Plus, X } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { useProposalResiduePlanContext } from '../../../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import { UnitOfMeasureType } from '../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanDestinationType,
  ProposalResiduePlanType,
} from '../../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../../../../../../utils/helper.utils';
import ResiduePlanDestinationFormAddEdit from './ResiduePlanDestinationForm/ResiduePlanDestinationFormAddEdit';
import ResiduePlanDestinationFormViewActions from './ResiduePlanDestinationForm/ResiduePlanDestinationFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalResiduePlan: ProposalResiduePlanType | null;
};

const ResiduePlanDestinationFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalResiduePlanData, setProposalResiduePlanData } = useProposalResiduePlanContext();

  const [data, setData] = useState<ProposalResiduePlanDestinationType[]>(
    props.proposalResiduePlan?.destinoFinal
      ? [
          {
            ...props.proposalResiduePlan?.destinoFinal,
            action: props.proposalResiduePlan?.destinoFinal.action || Action.Nothing,
            id:
              props.proposalResiduePlan?.destinoFinal.idPropostaResiduoPlanoDestinoFinal?.toString() ||
              props.proposalResiduePlan?.destinoFinal.idTemporaria ||
              newGuid(),
          },
        ]
      : []
  );

  const [destinationItem, setDestinationItem] = useState<ProposalResiduePlanDestinationType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanDestinationType> = [
    {
      title: 'Destino Final',
      key: 'residuoDestinoFinal',
      sorter: (a: ProposalResiduePlanDestinationType, b: ProposalResiduePlanDestinationType) =>
        (a.residuoDestinoFinal || '').localeCompare(b.residuoDestinoFinal || ''),
      render: (row: ProposalResiduePlanDestinationType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoDestinoFinal?.toString() || ''}
            name={row.residuoDestinoFinal || ''}
            nameSize="sm"
            description={row.residuoDestinoFinalDescricao}
            descriptionSize="xs"
            linkPrefix="destinations"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Autorização Ambiental?',
      key: 'autorizacaoAmbiental',
      dataIndex: 'autorizacaoAmbiental',
      sorter: (a: ProposalResiduePlanDestinationType, b: ProposalResiduePlanDestinationType) =>
        a.autorizacaoAmbiental === b.autorizacaoAmbiental ? 0 : a.autorizacaoAmbiental ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: ProposalResiduePlanDestinationType, b: ProposalResiduePlanDestinationType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: ProposalResiduePlanDestinationType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={25} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanDestinationType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ResiduePlanDestinationFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    item: ProposalResiduePlanDestinationType | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduoPlanoDestinoFinal) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setDestinationItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }

    setProposalResiduePlanData({ ...proposalResiduePlanData, destinoFinal: data?.[0] || null });
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanDestinationType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Plano/Destino Final - ${destinationItem ? 'Editar' : 'Adicionar'}`}
        size="50%"
      >
        <ResiduePlanDestinationFormAddEdit
          referenceData={props.referenceData}
          origItem={props.proposalResiduePlan?.destinoFinal || null}
          item={destinationItem}
          idPropostaResiduoPlano={props.proposalResiduePlan?.idPropostaResiduoPlano}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Destino Final"
          text="Destino Final para este plano."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={data.filter((x) => x.action !== Action.Delete).length > 0}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ProposalResiduePlanDestinationType) =>
          item.id || item.idPropostaResiduoPlanoDestinoFinal || 0
        }
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ResiduePlanDestinationFormView;
