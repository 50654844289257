/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalSearchResponseType } from '../../../../../models/core/proposals.type';
import { formatDateToString } from '../../../../../utils/formatter.utils';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: ProposalSearchResponseType[];
  confirmActionResult(item: ProposalSearchResponseType, action: string, confirmed: boolean): void;
};
export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: '#',
      key: 'idProposta',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) => {
        const aValue = a.idProposta;
        const bValue = b.idProposta;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalSearchResponseType) => (
        <Link to={`./${row.idProposta}`} target="_blank">
          {row.idProposta}
        </Link>
      ),
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'propostaTipo',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        a.propostaTipo.localeCompare(b.propostaTipo),
    },
    {
      title: 'Empresa',
      key: 'empresa',
      dataIndex: 'empresa',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        a.empresa.localeCompare(b.empresa),
    },
    {
      title: 'Cliente',
      key: 'idCliente',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
          b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
        ),
      render: (row: ProposalSearchResponseType) => {
        if (row.clienteCNPJ) {
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
              nameSize="sm"
              description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        return (
          <ProfileCardLink
            id={row.idCliente.toString()}
            name={row.clienteNome || '-'}
            nameSize="sm"
            description={row.clienteCPF}
            descriptionSize="xs"
            linkPrefix="entities"
            showLink
          />
        );
      },
    },
    {
      title: 'Gerenciador',
      key: 'idGerenciador',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        (a.gerenciadorNomeFantasia || a.gerenciadorRazaoSocial || a.gerenciadorNome || '').localeCompare(
          b.gerenciadorNomeFantasia || b.gerenciadorRazaoSocial || b.gerenciadorNome || ''
        ),
      render: (row: ProposalSearchResponseType) => {
        if (!row.gerenciadorCNPJ) {
          return '-';
        }
        if (row.gerenciadorCNPJ) {
          return (
            <ProfileCardLink
              id={row.idGerenciador!.toString()}
              name={row.gerenciadorNomeFantasia || row.gerenciadorRazaoSocial || '-'}
              nameSize="sm"
              description={row.gerenciadorNomeFantasia ? row.gerenciadorRazaoSocial : row.gerenciadorCNPJ}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        }
        return (
          <ProfileCardLink
            id={row.idGerenciador!.toString()}
            name={row.gerenciadorNome || '-'}
            nameSize="sm"
            description={row.gerenciadorCPF}
            descriptionSize="xs"
            linkPrefix="entities"
            showLink
          />
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        a.propostaStatus.localeCompare(b.propostaStatus),
      render: (row: ProposalSearchResponseType) => <Badge variant="outline">{row.propostaStatus}</Badge>,
    },
    {
      title: 'Validade',
      key: 'validade',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        new Date(a.validade).valueOf() - new Date(b.validade).valueOf(),
      render: (row: ProposalSearchResponseType) => formatDateToString(row.validade),
    },
    {
      title: 'Data Inicial',
      key: 'dataInicial',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        new Date(a.dataInicial).valueOf() - new Date(b.dataInicial).valueOf(),
      render: (row: ProposalSearchResponseType) => formatDateToString(row.dataInicial),
    },
    {
      title: 'Data Final',
      key: 'dataFinal',
      sorter: (a: ProposalSearchResponseType, b: ProposalSearchResponseType) =>
        new Date(a.dataFinal).valueOf() - new Date(b.dataFinal).valueOf(),
      render: (row: ProposalSearchResponseType) => formatDateToString(row.dataFinal),
    },
    {
      title: 'Ações',
      width: '210px',
      render: (row: ProposalSearchResponseType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: ProposalSearchResponseType) => item.idProposta}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
