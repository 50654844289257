/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { DualAxes, DualAxesConfig } from '@ant-design/plots';
import {
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  BoxMultiple,
  Building,
  Check,
  Table as ITable,
  PlugConnectedX,
  Reload,
  SortDescendingNumbers,
  TableOff,
  X,
} from 'tabler-icons-react';
import Gauge from '../../../../../../components/charts/Gauge/Gauge';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { CompanyType, UnitOfMeasureType } from '../../../../../../models/core/cache.type';
import {
  CompanyClientQuantityPotentialResponseType,
  ReportItem,
} from '../../../../../../models/core/report.type';
import reportsService from '../../../../../../services/core/report.service';
import theme from '../../../../../../theme';
import cacheUtils from '../../../../../../utils/cache.utils';
import { Chart, RecurrenceData, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import { convertUnitOfMeasure, newGuid } from '../../../../../../utils/helper.utils';
import { MonthYear, getMonths } from '../../../../../../utils/moment-recur.utils';

type FormViewProps = {
  idEntidade: number | undefined;
  color: string;
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  agrupador: string;
  indicador: string;
  clientes: string[];
};

type Response = {
  filterConfig: FilterData;
  data: CompanyClientQuantityPotentialResponseType[];
  filteredData: CompanyClientQuantityPotentialResponseType[];
  chartConfig: DualAxesConfig;
  grandTotal: {
    massaPotencial: number;
    massa: number;
    volumePotencial: number;
    volume: number;
  };
  chartDataSummary: {
    agrupador: string;
    ref1: string;
    ref2: string;
    quantidadeMassa: number;
    quantidadeVolume: number;
  }[];
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
  guid: string;
};

type CalcData = CompanyClientQuantityPotentialResponseType & {
  mesAno: MonthYear;
  quantidadeMassa: number;
  quantidadeMassaUnidadeMedida: UnitOfMeasureType;
  quantidadeVolume: number;
  quantidadeVolumeUnidadeMedida: UnitOfMeasureType;
  quantidadeTempo: number;
  quantidadeTempoUnidadeMedida: UnitOfMeasureType;
  eVolume: boolean;
};

const componentConfig = {
  title: 'Potencial Quantitativo',
  description:
    'Comparação quantitativa entre valores potenciais e valores já faturados baseado em eventos com medição definida.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.EmpresaClientePotencialQuantitativo}-{ID}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    agrupadorData: [
      // Geral
      {
        value: 'nenhum',
        label: `Nenhum`,
        group: 'Geral',
        reference: 'calcNenhum',
      },
      {
        value: 'idEmpresa',
        label: `Empresa`,
        group: 'Geral',
        reference: 'empresa',
      },
      {
        value: 'idContrato',
        label: `Contrato`,
        group: 'Geral',
        reference: 'calcContrato',
      },
      // Entidade
      {
        value: 'idCliente',
        label: `Cliente`,
        group: 'Entidade',
        reference: 'calcCliente',
      },
      // Resíduo
      {
        value: 'idResiduo',
        label: `Resíduo`,
        group: 'Resíduo',
        reference: 'residuo',
      },
      {
        value: 'idPropostaResiduo',
        label: `Resíduo (Cliente)`,
        group: 'Resíduo',
        reference: 'residuoCliente',
      },
    ],
    agrupador: 'nenhum',
    indicadorData: [
      {
        value: 'quantidade',
        label: `Quantidade`,
      },
    ],
    indicador: 'quantidade',
    clientes: [],
  },
  dataConfig: {
    exportable: true,
    separator: '╗',
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Empresa',
        key: 'empresa',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => a.empresa.localeCompare(b.empresa),
        render: (row: CompanyClientQuantityPotentialResponseType) => (
          <Badge variant="outline">{row.empresa}</Badge>
        ),
      },
      {
        title: 'Contrato #',
        key: 'idContrato',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => {
          const aValue = a.idContrato;
          const bValue = b.idContrato;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientQuantityPotentialResponseType) => (
          <ProfileCardLink
            id={row.idContrato.toString()}
            name="Contrato"
            nameSize="sm"
            description={`# ${row.idContrato}`}
            descriptionSize="xs"
            linkPrefix="contracts"
            showLink
          />
        ),
      },
      {
        title: 'Proposta #',
        key: 'idProposta',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => {
          const aValue = a.idProposta;
          const bValue = b.idProposta;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientQuantityPotentialResponseType) => (
          <ProfileCardLink
            id={row.idProposta.toString()}
            name="Proposta"
            nameSize="sm"
            description={`# ${row.idProposta}`}
            descriptionSize="xs"
            linkPrefix="proposals"
            showLink
          />
        ),
      },
      {
        title: 'Cliente',
        key: 'idCliente',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) =>
          (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
            b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
          ),
        render: (row: CompanyClientQuantityPotentialResponseType) => {
          if (row.clienteCNPJ) {
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                nameSize="sm"
                description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNome || '-'}
              nameSize="sm"
              description={row.clienteCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        },
      },
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientQuantityPotentialResponseType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo} | ${row.eventoStatus}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: CompanyClientQuantityPotentialResponseType) => formatDateToString(row.data),
      },
      {
        title: 'Quantidade',
        key: 'quantidade',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => {
          const aValue = a.quantidade || 0;
          const bValue = b.quantidade || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientQuantityPotentialResponseType) => {
          if (!row.quantidadeIdUnidadeMedida) {
            return '-';
          }
          return <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>;
        },
      },
      {
        title: 'Faturado?',
        key: 'faturado',
        dataIndex: 'faturado',
        sorter: (
          a: CompanyClientQuantityPotentialResponseType,
          b: CompanyClientQuantityPotentialResponseType
        ) => (a.faturado === b.faturado ? 0 : a.faturado ? -1 : 1),
        render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
      },
    ],
  },
  chartConfig: {
    data: [],
    xField: 'mesAno',
    yField: ['total', 'total'],
    yAxis: [
      {
        title: {
          text: 'Tonelada(s)',
          style: {
            fontSize: 14,
          },
        },
      },
      {
        title: {
          text: 'Metro(s) Cúbico(s)',
          style: {
            fontSize: 14,
          },
        },
      },
    ],
    legend: {
      position: 'right',
    },
    tooltip: {
      formatter: (el) => {
        return {
          name: el.referencia,
          value: `${Number(el.total).toFixed(3)} ${el.referencia.startsWith('T') ? 'ton' : 'm³'}`,
        };
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    geometryOptions: [
      {
        geometry: 'line',
        seriesField: 'referencia',
        color: (data: any) => {
          return (Chart.Color as any)[data.referencia];
        },
      },
      {
        geometry: 'line',
        seriesField: 'referencia',
        color: (data: any) => {
          return (Chart.Color as any)[data.referencia];
        },
        point: {},
        smooth: true,
      },
    ],
  } as DualAxesConfig,
};

const QuantityPotencial = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
    guid: newGuid(),
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
    unitOfMeasures: UnitOfMeasureType[];
  }>({
    companyData: [],
    unitOfMeasures: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idEntidade: props.idEntidade,
      idEmpresa:
        form.values.idEmpresa !== null && form.values.idEmpresa !== undefined
          ? Number(form.values.idEmpresa)
          : null,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.empresaClientePotencialQuantitativo(searchCriteria);
    return data;
  };

  const filterMe = (data: CompanyClientQuantityPotentialResponseType[]) => {
    let filteredData = data.filter(
      (x) => Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa))
    );

    if (form.values.clientes.length > 0) {
      filteredData = data.filter((x) =>
        form.values.clientes.map((y) => Number(y)).includes(Number(x.idCliente))
      );
    }
    return filteredData;
  };

  const unifyItemsByUnitOfMeasure = (data: CompanyClientQuantityPotentialResponseType[]) => {
    const toneladaUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Massa' && x.unidadeMedidaSigla === 'ton'
    )!;
    const metroCubicoUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Volume' && x.unidadeMedidaSigla === 'm³'
    )!;
    const horaUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'h'
    )!;

    const commonQuantityData: (CompanyClientQuantityPotentialResponseType & {
      quantidadeMassa: number;
      quantidadeMassaUnidadeMedida: UnitOfMeasureType;
      quantidadeVolume: number;
      quantidadeVolumeUnidadeMedida: UnitOfMeasureType;
      quantidadeTempo: number;
      quantidadeTempoUnidadeMedida: UnitOfMeasureType;
      eVolume: boolean;
    })[] = data.map((x) => {
      return {
        ...x,
        quantidadeMassa: x.quantidadeIdUnidadeMedida === toneladaUoM.idUnidadeMedida ? x.quantidade : 0,
        quantidadeMassaUnidadeMedida: toneladaUoM,
        quantidadeVolume: x.quantidadeIdUnidadeMedida === metroCubicoUoM.idUnidadeMedida ? x.quantidade : 0,
        quantidadeVolumeUnidadeMedida: metroCubicoUoM,
        quantidadeTempo: x.tempoIdUnidadeMedida === horaUoM.idUnidadeMedida ? x.tempo || 0 : 0,
        quantidadeTempoUnidadeMedida: horaUoM,
        eVolume: x.quantidadeIdUnidadeMedida === metroCubicoUoM.idUnidadeMedida,
      };
    });

    // converting all UoMs to common ones
    for (const item of commonQuantityData) {
      if (item.quantidadeMassa === 0 && !item.eVolume) {
        const massaUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.quantidadeIdUnidadeMedida
        )!;
        const conversaoMassa = convertUnitOfMeasure(massaUoM, toneladaUoM, item.quantidade);
        if (conversaoMassa.valid) {
          item.quantidadeMassa = conversaoMassa.calc || 0;
          item.quantidadeMassaUnidadeMedida = toneladaUoM;
        } else {
          item.quantidadeMassa = -1;
          item.quantidadeMassaUnidadeMedida = toneladaUoM;
        }
      }

      if ((item.tempo || 0) > 0) {
        const tempoUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.tempoIdUnidadeMedida
        )!;
        const conversaoMassa = convertUnitOfMeasure(tempoUoM, horaUoM, item.tempo);
        if (conversaoMassa.valid) {
          item.quantidadeTempo = conversaoMassa.calc || 0;
          item.quantidadeTempoUnidadeMedida = horaUoM;
        } else {
          item.quantidadeTempo = -1;
          item.quantidadeTempoUnidadeMedida = horaUoM;
        }
      }
    }

    return commonQuantityData;
  };

  const buildChartData = async (data: CompanyClientQuantityPotentialResponseType[]) => {
    referenceData = {
      companyData: await cacheUtils.listCompanies(),
      unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
    };

    const dataInicial = form.values.periodo[0];
    const dataFinal = form.values.periodo[1];
    const meses = getMonths(dataInicial!, dataFinal!);

    const calcData: CalcData[] = unifyItemsByUnitOfMeasure(data).map((x) => {
      const mesAno = getMonths(x.data, x.data)[0];
      return {
        ...x,
        mesAno,

        calcNenhum:
          componentConfig.filterConfig.indicadorData.find((y) => y.value === form.values.indicador)?.label ||
          '-',
        calcContrato: `Contrato #${x.idContrato}`,
        calcCliente: `${x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome}`,
      };
    });

    const massData = [];
    const volumeData = [];
    for (const mesAno of meses) {
      const massaPotencial = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !x.eVolume)
        .reduce((a, b) => a + b.quantidadeMassa, 0);
      const volumePotencial = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !!x.eVolume)
        .reduce((a, b) => a + b.quantidadeVolume, 0);
      // const tempoPotencial = calcData
      //   .filter((x) => x.mesAno.key === mesAno.key)
      //   .reduce((a, b) => a + b.quantidadeTempo, 0);

      const massa = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !x.eVolume && !!x.faturado)
        .reduce((a, b) => a + b.quantidadeMassa, 0);
      const volume = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !!x.eVolume && !!x.faturado)
        .reduce((a, b) => a + b.quantidadeVolume, 0);
      // const tempo = calcData
      //   .filter((x) => x.mesAno.key === mesAno.key && !!x.faturado)
      //   .reduce((a, b) => a + b.quantidadeTempo, 0);

      massData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Tonelada(s) (Potencial)',
        total: massaPotencial,
        tipo: 'MP',
      });
      massData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Tonelada(s)',
        total: massa,
        tipo: 'M',
      });

      volumeData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Metro(s) Cúbico(s) (Potencial)',
        total: volumePotencial,
        tipo: 'VP',
      });
      volumeData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Metro(s) Cúbico(s)',
        total: volume,
        tipo: 'V',
      });

      // quantityData.push({
      //   ordem: mesAno.order,
      //   mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
      //   referencia: 'Hora(s) (Potencial)',
      //   total: tempoPotencial,
      //   tipo: 'TP',
      // });
      // quantityData.push({
      //   ordem: mesAno.order,
      //   mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
      //   referencia: 'Hora(s)',
      //   total: tempo,
      //   tipo: 'T',
      // });
    }

    // grand total
    const grandTotal = {
      massaPotencial: massData.filter((x) => x.tipo === 'MP').reduce((a, b: any) => a + b.total, 0),
      volumePotencial: volumeData.filter((x) => x.tipo === 'VP').reduce((a, b: any) => a + b.total, 0),
      // tempoPotencial: quantityData.filter((x) => x.tipo === 'TP').reduce((a, b: any) => a + b.total, 0),
      massa: massData.filter((x) => x.tipo === 'M').reduce((a, b: any) => a + b.total, 0),
      volume: volumeData.filter((x) => x.tipo === 'V').reduce((a, b: any) => a + b.total, 0),
      // tempo: quantityData.filter((x) => x.tipo === 'T').reduce((a, b: any) => a + b.total, 0),
    };

    const agrupador = componentConfig.filterConfig.agrupadorData.find(
      (x) => x.value === form.values.agrupador
    )!;
    const calcAgrupador = agrupador.value === 'nenhum' ? undefined : agrupador.value;

    const chartDataSummary = [];
    const chartDataSummaryGroup = lodash.groupBy(calcData, calcAgrupador);
    for (const key of Object.keys(chartDataSummaryGroup).filter((x) => x !== 'null')) {
      const items = chartDataSummaryGroup[key];
      const item = items[0];
      const agrupadorValue = (item as any)[agrupador.reference!].split(componentConfig.dataConfig.separator);

      chartDataSummary.push({
        agrupador: agrupadorValue[0],
        ref1: agrupadorValue?.[1] || '-',
        ref2: agrupadorValue?.[2] || '-',
        quantidadeMassaPotencial: items.reduce((a, b) => a + b.quantidadeMassa, 0),
        quantidadeVolumePotencial: items.reduce((a, b) => a + b.quantidadeVolume, 0),
        quantidadeMassa: items.filter((x) => !!x.faturado).reduce((a, b) => a + b.quantidadeMassa, 0),
        quantidadeVolume: items.filter((x) => !!x.faturado).reduce((a, b) => a + b.quantidadeVolume, 0),
      });
    }

    return {
      grandTotal,
      massData: massData.sort((a, b) => a.ordem - b.ordem),
      volumeData: volumeData.sort((a, b) => a.ordem - b.ordem),
      chartDataSummary,
    };
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);
    const chartData = await buildChartData(filteredData);

    return {
      filterConfig: form.values,
      data,
      filteredData,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: [chartData.massData, chartData.volumeData],
      },
      grandTotal: chartData.grandTotal,
      chartDataSummary: chartData.chartDataSummary,
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null, guid: newGuid() };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message, guid: newGuid() };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', (props.idEntidade || 0).toString());

    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
        unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult, guid: newGuid() });
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const clientsChanged = (filterConfig?.clientes || []).join('-') !== form.values.clientes.join('-');
    const agrupadorChanged = form.values.agrupador !== filterConfig?.agrupador;
    const indicadorChanged = form.values.indicador !== filterConfig?.indicador;

    if (idEmpresaChanged || clientsChanged || agrupadorChanged || indicadorChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={props.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={props.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={props.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={3} />

        <Paper shadow="xs" p="md" withBorder>
          <Group position="apart">
            <Group spacing="md">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Select
                icon={<Building size={15} />}
                label="Empresa"
                description="Filtro: igual"
                placeholder="Todos..."
                data={referenceData.companyData.map((x) => {
                  return {
                    value: x.idEmpresa.toString(),
                    label: x.empresa,
                  };
                })}
                searchable
                clearable={currentUser.executivo}
                disabled={!currentUser.executivo}
                {...form.getInputProps('idEmpresa')}
              />
            </Group>
            <Group spacing="md">
              <Select
                style={{ width: 300 }}
                icon={<BoxMultiple size={15} />}
                label="Agrupador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                searchable
                data={componentConfig.filterConfig.agrupadorData}
                {...form.getInputProps('agrupador')}
              />
              <Select
                icon={<SortDescendingNumbers size={15} />}
                style={{ width: 150 }}
                label="Indicador"
                description="Filtro: Igual"
                placeholder="Selecione..."
                data={componentConfig.filterConfig.indicadorData}
                {...form.getInputProps('indicador')}
              />
            </Group>
          </Group>
          {result.loaded && (result.response?.data || []).length > 0 && !props.idEntidade && (
            <div>
              <Space h="md" />
              <MultiSelect
                label="Clientes"
                description="Filtro: igual"
                placeholder="Todos..."
                data={Array.from(
                  new Set(
                    result.response?.data.map(
                      (x) =>
                        `${x.idCliente}${componentConfig.dataConfig.separator}${
                          x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome
                        }`
                    )
                  )
                ).map((x) => {
                  return {
                    value: x.split(componentConfig.dataConfig.separator)[0],
                    label: x.split(componentConfig.dataConfig.separator)[1],
                  };
                })}
                clearable
                {...form.getInputProps('clientes')}
              />
            </div>
          )}
        </Paper>
        <Space h="md" />

        {result.error ? (
          <Stack spacing="md" align="center">
            <PlugConnectedX color="red" size={32} />
            <Text>{result.error}</Text>
          </Stack>
        ) : result.loaded ? (
          (result.response?.filteredData || []).length > 0 ? (
            <div>
              <Paper shadow="xs" p="md" withBorder>
                <Grid columns={2}>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.massaPotencial || 0}
                      maxString={`${result.response?.grandTotal.massaPotencial || 0} ton`}
                      actual={result.response?.grandTotal.massa || 0}
                      actualString={`${result.response?.grandTotal.massa || 0} ton`}
                      title="Tonelada(s)"
                      color={{
                        text: Chart.Color['Tonelada(s)'],
                        min: Chart.Color['Tonelada(s) (Potencial)'],
                        max: Chart.Color['Tonelada(s)'],
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.volumePotencial || 0}
                      maxString={`${result.response?.grandTotal.volumePotencial || 0} m³`}
                      actual={result.response?.grandTotal.volume || 0}
                      actualString={`${result.response?.grandTotal.volume || 0} m³`}
                      title="Metro(s) Cúbico(s)"
                      color={{
                        text: Chart.Color['Metro(s) Cúbico(s)'],
                        min: Chart.Color['Metro(s) Cúbico(s) (Potencial)'],
                        max: Chart.Color['Metro(s) Cúbico(s)'],
                      }}
                      height={120}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <DualAxes {...result.response?.chartConfig!} height={props.height} />
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Table
                  key={result.guid}
                  showSorterTooltip={false}
                  size="small"
                  dataSource={result.response?.chartDataSummary || []}
                  columns={[
                    {
                      title: 'Agrupador',
                      key: 'agrupador',
                      sorter: (a: any, b: any) => a.agrupador.localeCompare(b.agrupador),
                      render: (row: any) => row.agrupador,
                    },
                    {
                      title: 'Referência 1',
                      key: 'ref1',
                      sorter: (a: any, b: any) => a.ref1.localeCompare(b.ref1),
                      render: (row: any) => row.ref1,
                    },
                    {
                      title: 'Referência 2',
                      key: 'ref2',
                      sorter: (a: any, b: any) => a.ref2.localeCompare(b.ref2),
                      render: (row: any) => row.ref2,
                    },
                    {
                      title: 'Tonelada(s)',
                      key: 'massa',
                      sorter: (a, b) => {
                        const aValue = a.quantidadeMassa || 0;
                        const bValue = b.quantidadeMassa || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row) => {
                        if (!row.quantidadeMassaPotencial) {
                          return '-';
                        }
                        return (
                          <div>{`${row.quantidadeMassa.toFixed(3)} / ${row.quantidadeMassaPotencial.toFixed(
                            3
                          )} Tonelada(s)`}</div>
                        );
                      },
                    },
                    {
                      title: 'Metro(s) Cúbico(s)',
                      key: 'volume',
                      sorter: (a, b) => {
                        const aValue = a.quantidadeVolume || 0;
                        const bValue = b.quantidadeVolume || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row) => {
                        if (!row.quantidadeVolumePotencial) {
                          return '-';
                        }
                        return (
                          <div>{`${row.quantidadeVolume.toFixed(3)} / ${row.quantidadeVolumePotencial.toFixed(
                            3
                          )} Metro(s) Cúbico(s)`}</div>
                        );
                      },
                    },
                  ]}
                  rowKey={(item) =>
                    `${item.agrupador}-${item.ref1 || '0'}-${item.ref2 || '0'}-${
                      item.quantidadeMassa || '0'
                    }-${item.quantidadeVolume || '0'}`
                  }
                  pagination={{
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                  }}
                />
              </Paper>
            </div>
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            </Paper>
          )
        ) : (
          <Paper shadow="xs" p="md" withBorder>
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Paper>
  );
});

export default QuantityPotencial;
