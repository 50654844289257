import { createContext, ReactNode, useContext, useState } from 'react';
import { ProposalResiduePlanType } from '../../../models/core/proposals.type';
import { ResidueType } from '../../../models/core/residues.type';

type ProposalResidueData = {
  residuo: ResidueType | null;
  codigoResiduoEstadoFisico: string | null;
  residuoCliente: string;
  residuoClientePerigoso: boolean;
  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida1: string | null;
  minimoAceitavelIdUnidadeMedida2: string | null;
  estimativa: number | null;
  estimativaIdUnidadeMedida1: string | null;
  estimativaIdUnidadeMedida2: string | null;
  dataInicial: Date;
  dataFinal: Date;
  idEntidadeEndereco: number | null;
  planos: ProposalResiduePlanType[];
};

interface ProposalResidueContextProps {
  proposalResidueData: ProposalResidueData;
  setProposalResidueData(proposalResidueContextData: ProposalResidueData): void;
}
const ProposalResidueContext = createContext<ProposalResidueContextProps>({} as ProposalResidueContextProps);

// eslint-disable-next-line react/function-component-definition
export const ProposalResidueProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [proposalResidueData, setProposalResidueData] = useState<ProposalResidueData>(
    {} as ProposalResidueData
  );
  return (
    <ProposalResidueContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        proposalResidueData,
        setProposalResidueData,
      }}
    >
      {children}
    </ProposalResidueContext.Provider>
  );
};

export type { ProposalResidueData };

export function useProposalResidueContext() {
  return useContext(ProposalResidueContext);
}
