import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Mail } from 'tabler-icons-react';
import { Button, Group, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import SplitViewForm from '../../../components/layout/SplitViewForm/SplitViewForm';
import authService from '../../../services/iam/auth.service';

type FormProps = {
  email: string;
};

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const form = useForm<FormProps>({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^\S+@\S+$/.test(value.trim())) {
          return 'Formato inválido (Ex: nome@dominio.com)';
        }
        return null;
      },
    },
  });

  const handleSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      await authService.forgotPassword(values);
      setSuccess(true);
      showNotification({
        title: 'Esqueci minha senha',
        message: 'E-mail enviado com sucesso.',
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Esqueci minha senha',
        message: error?.isBusinessException ? error.description : 'Usuário não encontrado.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SplitViewForm title="Esqueci minha senha">
      <form style={{ width: 450 }} onSubmit={form.onSubmit(handleSubmit)} noValidate>
        <Space h="xl" />
        <TextInput
          icon={<Mail size={15} />}
          label="E-mail"
          placeholder="Digite seu e-mail"
          type="email"
          maxLength={100}
          required
          {...form.getInputProps('email')}
        />
        <Space h="xl" />
        <Button fullWidth type="submit" loading={loading} disabled={success}>
          Enviar E-mail
        </Button>
        <Space h="xl" />
        {success && (
          <Group position="center">
            <Link to="/">
              <Button variant="outline">Voltar para o Login</Button>
            </Link>
          </Group>
        )}
      </form>
    </SplitViewForm>
  );
}
