import axios from 'axios';
import { SignInRequestType } from '../models/iam/auth.type';
import { AxiosCustomRequestType } from '../models/utils/axios.type';
import { LocalStorageKey } from '../utils/constants.utils';
import { decrypt } from '../utils/helper.utils';
import authService from './iam/auth.service';

const instance = axios.create({
  method: 'post',
  baseURL: `https://${process.env.REACT_APP_API_GATEWAY_IAM_IDENTITY}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getUserAccessToken = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem(LocalStorageKey.UserAccessToken)?.replaceAll('"', '')}`,
  };
};

let isRefreshingToken = false;
const refreshUserAccessToken = async () => {
  isRefreshingToken = true;
  const signInProps = JSON.parse(
    decrypt(localStorage.getItem(LocalStorageKey.UserCredentials) || '{}')
  ) as SignInRequestType;
  await authService.signIn(signInProps);
};

const call = async (request: AxiosCustomRequestType): Promise<any> => {
  const authTokenHeader = getUserAccessToken();
  try {
    const { status, data } = await instance.request({
      method: request.method,
      url: request.url,
      timeout: Number(request.timeout || 15000),
      data: request?.data,
      params: request?.params,
      headers: {
        ...(request.headers || {}),
        ...authTokenHeader,
        'Content-Type': 'application/json',
      },
    });
    return { status, data };
  } catch (error: any) {
    const statusCode: number = error?.response?.status || error?.code;
    if (statusCode === 401) {
      if (!isRefreshingToken) {
        await refreshUserAccessToken();
        return call(request);
      }
    }
    throw error?.response?.data || error;
  } finally {
    isRefreshingToken = false;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { call };
