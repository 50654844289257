/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { Line, LineConfig } from '@ant-design/plots';
import {
  ActionIcon,
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Building,
  Check,
  ExternalLink,
  Table as ITable,
  PlugConnectedX,
  Reload,
  TableOff,
  X,
} from 'tabler-icons-react';
import Gauge from '../../../../../../components/charts/Gauge/Gauge';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../../../models/core/cache.type';
import {
  CompanyClientFinancialPotentialResponseType,
  ReportItem,
} from '../../../../../../models/core/report.type';
import reportsService from '../../../../../../services/core/report.service';
import theme from '../../../../../../theme';
import cacheUtils from '../../../../../../utils/cache.utils';
import { Chart, RecurrenceData, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../utils/formatter.utils';
import { getMonths } from '../../../../../../utils/moment-recur.utils';

type FormViewProps = {
  idEntidade: number | undefined;
  color: string;
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  clientes: string[];
};

type Response = {
  filterConfig: FilterData;
  data: CompanyClientFinancialPotentialResponseType[];
  filteredData: CompanyClientFinancialPotentialResponseType[];
  chartConfig: LineConfig;
  grandTotal: {
    receitaPotencial: number;
    receita: number;
    despesaPotencial: number;
    despesa: number;
    impostoPotencial: number;
    imposto: number;
    balancoPotencial: number;
    balanco: number;
  };
  clientsSummary: {
    idCliente: number;
    cliente: string;
    eventos: number;
    eventosFaturados: number;
    receita: number;
    despesa: number;
    imposto: number;
    balanco: number;
  }[];
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Potencial Financeiro',
  description:
    'Comparação financeira entre valores potenciais e valores já faturados baseado em eventos com medição definida.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.EmpresaClientePotencialFinanceiro}-{ID}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    clientes: [],
  },
  dataConfig: {
    exportable: true,
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Empresa',
        key: 'empresa',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => a.empresa.localeCompare(b.empresa),
        render: (row: CompanyClientFinancialPotentialResponseType) => (
          <Badge variant="outline">{row.empresa}</Badge>
        ),
      },
      {
        title: 'Contrato #',
        key: 'idContrato',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.idContrato;
          const bValue = b.idContrato;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => (
          <ProfileCardLink
            id={row.idContrato.toString()}
            name="Contrato"
            nameSize="sm"
            description={`# ${row.idContrato}`}
            descriptionSize="xs"
            linkPrefix="contracts"
            showLink
          />
        ),
      },
      {
        title: 'Proposta #',
        key: 'idProposta',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.idProposta;
          const bValue = b.idProposta;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => (
          <ProfileCardLink
            id={row.idProposta.toString()}
            name="Proposta"
            nameSize="sm"
            description={`# ${row.idProposta}`}
            descriptionSize="xs"
            linkPrefix="proposals"
            showLink
          />
        ),
      },
      {
        title: 'Cliente',
        key: 'idCliente',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) =>
          (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
            b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
          ),
        render: (row: CompanyClientFinancialPotentialResponseType) => {
          if (row.clienteCNPJ) {
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                nameSize="sm"
                description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNome || '-'}
              nameSize="sm"
              description={row.clienteCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        },
      },
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo} | ${row.eventoStatus}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink
            />
          );
        },
      },
      {
        title: 'Data',
        key: 'data',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => new Date(a.data).valueOf() - new Date(b.data).valueOf(),
        render: (row: CompanyClientFinancialPotentialResponseType) => formatDateToString(row.data),
      },
      {
        title: 'Receita',
        key: 'receita',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.receita || 0;
          const bValue = b.receita || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => (
          <div>{formatCurrency(row.receita)}</div>
        ),
      },
      {
        title: 'Despesa',
        key: 'despesa',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.despesa || 0;
          const bValue = b.despesa || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => (
          <div>{formatCurrency(row.despesa)}</div>
        ),
      },
      {
        title: 'Imposto',
        key: 'imposto',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.imposto || 0;
          const bValue = b.imposto || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => (
          <div>{formatCurrency(row.imposto)}</div>
        ),
      },
      {
        title: 'Balanço',
        key: 'balanco',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => {
          const aValue = a.receita - a.despesa - a.imposto;
          const bValue = b.receita - b.despesa - b.imposto;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: CompanyClientFinancialPotentialResponseType) => {
          const total = row.receita - row.despesa - row.imposto;
          const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

          return <div style={{ color }}>{formatCurrency(total)}</div>;
        },
      },
      {
        title: 'Faturado?',
        key: 'faturado',
        dataIndex: 'faturado',
        sorter: (
          a: CompanyClientFinancialPotentialResponseType,
          b: CompanyClientFinancialPotentialResponseType
        ) => (a.faturado === b.faturado ? 0 : a.faturado ? -1 : 1),
        render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
      },
    ],
  },
  chartConfig: {
    data: [],
    xField: 'mesAno',
    yField: 'total',
    seriesField: 'referencia',
    yAxis: {
      label: {
        formatter: (value) => {
          return formatCurrency(Number(value));
        },
      },
      nice: true,
    },
    legend: {
      position: 'right',
    },
    color: (data) => {
      return (Chart.Color as any)[data.referencia];
    },
    tooltip: {
      formatter: (el) => {
        return {
          name: `${el.referencia}`,
          value: formatCurrency(el.total),
        };
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  } as LineConfig,
};

const FinancialPotencial = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
  }>({
    companyData: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idEntidade: props.idEntidade,
      idEmpresa:
        form.values.idEmpresa !== null && form.values.idEmpresa !== undefined
          ? Number(form.values.idEmpresa)
          : null,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.empresaClientePotencialFinanceiro(searchCriteria);
    return data;
  };

  const filterMe = (data: CompanyClientFinancialPotentialResponseType[]) => {
    let filteredData = data.filter(
      (x) => Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa))
    );

    if (form.values.clientes.length > 0) {
      filteredData = data.filter((x) =>
        form.values.clientes.map((y) => Number(y)).includes(Number(x.idCliente))
      );
    }
    return filteredData;
  };

  const buildChartData = async (data: CompanyClientFinancialPotentialResponseType[]) => {
    referenceData = {
      companyData: await cacheUtils.listCompanies(),
    };

    const dataInicial = form.values.periodo[0];
    const dataFinal = form.values.periodo[1];
    const meses = getMonths(dataInicial, dataFinal);

    const calcData = data.map((x) => {
      const mesAno = getMonths(x.data, x.data)[0];
      return {
        ...x,
        mesAno,
      };
    });

    const chartData = [];
    for (const mesAno of meses) {
      const receitaPotencial = calcData
        .filter((x) => x.mesAno.key === mesAno.key)
        .reduce((a, b) => a + b.receita, 0);
      const despesaPotencial = calcData
        .filter((x) => x.mesAno.key === mesAno.key)
        .reduce((a, b) => a + b.despesa, 0);
      const impostoPotencial = calcData
        .filter((x) => x.mesAno.key === mesAno.key)
        .reduce((a, b) => a + b.imposto, 0);
      const balancoPotencial = calcData
        .filter((x) => x.mesAno.key === mesAno.key)
        .reduce((a, b) => a + b.balanco, 0);

      const receita = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !!x.faturado)
        .reduce((a, b: any) => a + b.receita, 0);
      const despesa = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !!x.faturado)
        .reduce((a, b: any) => a + b.despesa, 0);
      const imposto = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !!x.faturado)
        .reduce((a, b: any) => a + b.imposto, 0);
      const balanco = calcData
        .filter((x) => x.mesAno.key === mesAno.key && !!x.faturado)
        .reduce((a, b: any) => a + b.balanco, 0);

      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Receita (Potencial)',
        total: receitaPotencial,
        tipo: 'RP',
      });
      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Receita',
        total: receita,
        tipo: 'R',
      });

      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Despesa (Potencial)',
        total: despesaPotencial,
        tipo: 'DP',
      });
      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Despesa',
        total: despesa,
        tipo: 'D',
      });

      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Imposto (Potencial)',
        total: impostoPotencial,
        tipo: 'IP',
      });
      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Imposto',
        total: imposto,
        tipo: 'I',
      });

      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Balanço (Potencial)',
        total: balancoPotencial,
        tipo: 'BP',
      });
      chartData.push({
        ordem: mesAno.order,
        mesAno: `${RecurrenceData.Months[mesAno.month]}-${mesAno.year}`,
        referencia: 'Balanço',
        total: balanco,
        tipo: 'B',
      });
    }

    const grandTotal = {
      receitaPotencial: chartData.filter((x) => x.tipo === 'RP').reduce((a, b: any) => a + b.total, 0),
      despesaPotencial: chartData.filter((x) => x.tipo === 'DP').reduce((a, b: any) => a + b.total, 0),
      impostoPotencial: chartData.filter((x) => x.tipo === 'IP').reduce((a, b: any) => a + b.total, 0),
      balancoPotencial: chartData.filter((x) => x.tipo === 'BP').reduce((a, b: any) => a + b.total, 0),
      receita: chartData.filter((x) => x.tipo === 'R').reduce((a, b: any) => a + b.total, 0),
      despesa: chartData.filter((x) => x.tipo === 'D').reduce((a, b: any) => a + b.total, 0),
      imposto: chartData.filter((x) => x.tipo === 'I').reduce((a, b: any) => a + b.total, 0),
      balanco: chartData.filter((x) => x.tipo === 'B').reduce((a, b: any) => a + b.total, 0),
    };

    const clientsSummary = [];
    const groupedData = lodash.groupBy(data, 'idCliente');
    for (const key of Object.keys(groupedData)) {
      const items = groupedData[key];
      const item = items[0];
      clientsSummary.push({
        idCliente: item.idCliente,
        cliente: `${item.clienteNomeFantasia || item.clienteRazaoSocial || item.clienteNome || '?'}`,
        eventos: items.length,
        eventosFaturados: items.filter((x) => x.faturado).length || 0,
        receita: items.filter((x) => x.faturado).reduce((a, b: any) => a + b.receita, 0),
        receitaPotencial: items.reduce((a, b: any) => a + b.receita, 0),
        despesa: items.filter((x) => x.faturado).reduce((a, b: any) => a + b.despesa, 0),
        despesaPotencial: items.reduce((a, b: any) => a + b.despesa, 0),
        imposto: items.filter((x) => x.faturado).reduce((a, b: any) => a + b.imposto, 0),
        impostoPotencial: items.reduce((a, b: any) => a + b.imposto, 0),
        balanco: items.filter((x) => x.faturado).reduce((a, b: any) => a + b.balanco, 0),
        balancoPotencial: items.reduce((a, b: any) => a + b.balanco, 0),
      });
    }

    return { grandTotal, chartData: chartData.sort((a, b) => a.ordem - b.ordem), clientsSummary };
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);
    const chartData = await buildChartData(filteredData);

    return {
      filterConfig: form.values,
      data,
      filteredData,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: chartData.chartData,
      },
      grandTotal: chartData.grandTotal,
      clientsSummary: chartData.clientsSummary,
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    componentConfig.cacheKey = componentConfig.cacheKey.replace('{ID}', (props.idEntidade || 0).toString());

    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult });
        setForceSearch(false);
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const clientsChanged = (filterConfig?.clientes || []).join('-') !== form.values.clientes.join('-');

    if (idEmpresaChanged || clientsChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={props.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={props.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={props.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={3} />

        <Paper shadow="xs" p="md" withBorder>
          <Group spacing="md">
            <DateRangePicker
              label="Período"
              description="Filtro: entre"
              placeholder="Período"
              clearable={false}
              amountOfMonths={2}
              allowSingleDateInRange
              required
              {...form.getInputProps('periodo')}
            />
            <Select
              icon={<Building size={15} />}
              label="Empresa"
              description="Filtro: igual"
              placeholder="Todos..."
              data={referenceData.companyData.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
            {result.loaded && (result.response?.data || []).length > 0 && !props.idEntidade && (
              <MultiSelect
                label="Clientes"
                description="Filtro: igual"
                placeholder="Todos..."
                data={Array.from(
                  new Set(
                    result.response?.data.map(
                      (x) =>
                        `${x.idCliente}╗${x.clienteNomeFantasia || x.clienteRazaoSocial || x.clienteNome}`
                    )
                  )
                ).map((x) => {
                  return {
                    value: x.split('╗')[0],
                    label: x.split('╗')[1],
                  };
                })}
                clearable
                {...form.getInputProps('clientes')}
              />
            )}
          </Group>
        </Paper>
        <Space h="md" />

        {result.error ? (
          <Stack spacing="md" align="center">
            <PlugConnectedX color="red" size={32} />
            <Text>{result.error}</Text>
          </Stack>
        ) : result.loaded ? (
          (result.response?.filteredData || []).length > 0 ? (
            <div>
              <Paper shadow="xs" p="md" withBorder>
                <Grid columns={4}>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.receitaPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.receitaPotencial || 0)}
                      actual={result.response?.grandTotal.receita || 0}
                      actualString={formatCurrency(result.response?.grandTotal.receita || 0 || 0)}
                      title="Receita"
                      color={{
                        text: Chart.Color.Receita,
                        min: Chart.Color['Receita (Potencial)'],
                        max: Chart.Color.Receita,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.despesaPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.despesaPotencial || 0)}
                      actual={result.response?.grandTotal.despesa || 0}
                      actualString={formatCurrency(result.response?.grandTotal.despesa || 0 || 0)}
                      title="Despesa"
                      color={{
                        text: Chart.Color.Despesa,
                        min: Chart.Color['Despesa (Potencial)'],
                        max: Chart.Color.Despesa,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.impostoPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.impostoPotencial || 0)}
                      actual={result.response?.grandTotal.imposto || 0}
                      actualString={formatCurrency(result.response?.grandTotal.imposto || 0 || 0)}
                      title="Imposto"
                      color={{
                        text: Chart.Color.Imposto,
                        min: Chart.Color['Imposto (Potencial)'],
                        max: Chart.Color.Imposto,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Gauge
                      max={result.response?.grandTotal.balancoPotencial || 0}
                      maxString={formatCurrency(result.response?.grandTotal.balancoPotencial || 0)}
                      actual={result.response?.grandTotal.balanco || 0}
                      actualString={formatCurrency(result.response?.grandTotal.balanco || 0 || 0)}
                      title="Balanço"
                      color={{
                        text: Chart.Color.Balanço,
                        min: Chart.Color['Balanço (Potencial)'],
                        max: Chart.Color.Balanço,
                      }}
                      height={120}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Line {...result.response?.chartConfig!} height={props.height} />
              </Paper>
              <Space h="lg" />

              <Paper shadow="xs" p="md" withBorder>
                <Table
                  showSorterTooltip={false}
                  size="small"
                  dataSource={result.response?.clientsSummary || []}
                  columns={[
                    {
                      title: 'Cliente',
                      key: 'idCliente',
                      sorter: (a: any, b: any) => a.cliente.localeCompare(b.cliente),
                      render: (row: any) => row.cliente,
                    },
                    // ...(componentConfig.dataConfig.columns.filter((x) =>
                    //   ['receita', 'despesa', 'imposto', 'balanco'].includes(x.key)
                    // ) as any),
                    {
                      title: 'Receita',
                      key: 'receita',
                      sorter: (a: any, b: any) => {
                        const aValue = a.receita || 0;
                        const bValue = b.receita || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: any) => (
                        <div>
                          {formatCurrency(row.receita)} / {formatCurrency(row.receitaPotencial)}
                        </div>
                      ),
                    },
                    {
                      title: 'Despesa',
                      key: 'despesa',
                      sorter: (a: any, b: any) => {
                        const aValue = a.despesa || 0;
                        const bValue = b.despesa || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: any) => (
                        <div>
                          {formatCurrency(row.despesa)} / {formatCurrency(row.despesaPotencial)}
                        </div>
                      ),
                    },
                    {
                      title: 'Imposto',
                      key: 'imposto',
                      sorter: (a: any, b: any) => {
                        const aValue = a.imposto || 0;
                        const bValue = b.imposto || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: any) => (
                        <div>
                          {formatCurrency(row.imposto)} / {formatCurrency(row.impostoPotencial)}
                        </div>
                      ),
                    },
                    {
                      title: 'Balanço',
                      key: 'balanco',
                      sorter: (a: any, b: any) => {
                        const aValue = a.balanco || 0;
                        const bValue = b.balanco || 0;
                        if (aValue === bValue) {
                          return 0;
                        }
                        return aValue > bValue ? 1 : -1;
                      },
                      render: (row: any) => {
                        const color = row.balanco > 0 ? 'green' : row.balanco === 0 ? 'orange' : 'red';

                        return (
                          <div style={{ color }}>
                            {formatCurrency(row.balanco)} / {formatCurrency(row.balancoPotencial)}
                          </div>
                        );
                      },
                    },

                    {
                      title: 'Ações',
                      width: '100px',
                      render: (row) => (
                        <Tooltip withArrow transition="fade" transitionDuration={200} label="Analisar">
                          <ActionIcon
                            size="sm"
                            color="primary"
                            style={{ borderColor: theme?.colors?.primary?.[6] }}
                            variant="outline"
                            onClick={() => {
                              const link = document.createElement('a');
                              link.setAttribute(
                                'href',
                                `/entities/${row.idCliente}?target=statistics&company=${
                                  form.values.idEmpresa
                                }&initialDate=${formatDateToString(
                                  form.values.periodo[0]
                                )}&finalDate=${formatDateToString(form.values.periodo[1])}`
                              );
                              link.setAttribute('target', '_blank');
                              link.click();
                            }}
                          >
                            <ExternalLink size={15} color={theme?.colors?.primary?.[6]} />
                          </ActionIcon>
                        </Tooltip>
                      ),
                    },
                  ]}
                  rowKey="idCliente"
                  pagination={{
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                  }}
                />
              </Paper>
            </div>
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            </Paper>
          )
        ) : (
          <Paper shadow="xs" p="md" withBorder>
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          </Paper>
        )}
      </div>
    </Paper>
  );
});

export default FinancialPotencial;
