/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import { UnitOfMeasureType } from '../../../models/core/cache.type';
import { Action, ItemToleranceType } from '../../../models/core/core.type';
import { formatCurrency } from '../../../utils/formatter.utils';
import { newGuid } from '../../../utils/helper.utils';
import PageSection from '../PageSection/PageSection';
import ToleranceFormAddEdit from './ToleranceForm/ToleranceFormAddEdit';
import ToleranceFormViewActions from './ToleranceForm/ToleranceFormViewActions';

type PageViewPropertyProps = {
  refId: number | undefined;
  title: string;
  description: string | null;
  color: string;
  disabled: boolean;
  origData: ItemToleranceType[];
  data: ItemToleranceType[];
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
};

const ToleranceForm = forwardRef((props: PageViewPropertyProps, ref) => {
  const [data, setData] = useState<ItemToleranceType[]>(
    props.data.map((x: ItemToleranceType) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id:
          x.idPropostaTolerancia?.toString() ||
          x.idEventoTolerancia?.toString() ||
          x.idTemporaria ||
          newGuid(),
      };
    })
  );

  const [toleranceItem, setToleranceItem] = useState<ItemToleranceType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ItemToleranceType> = [
    {
      title: 'Unidade de Medida',
      key: 'unidadeMedida',
      dataIndex: 'unidadeMedida',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => a.unidadeMedida.localeCompare(b.unidadeMedida),
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      dataIndex: 'quantidade',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => {
        const aValue = a.quantidade;
        const bValue = b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
    },
    {
      title: 'Preço Unitário',
      key: 'precoUnitario',
      sorter: (a: ItemToleranceType, b: ItemToleranceType) => {
        const aValue = a.precoUnitario || 0;
        const bValue = b.precoUnitario || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ItemToleranceType) => <div>{formatCurrency(row.precoUnitario)}</div>,
    },
  ];

  if (!props.disabled) {
    columns.push({
      title: 'Ações',
      width: '100px',
      render: (row: ItemToleranceType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ToleranceFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    });
  }

  const confirmActionResult = (item: ItemToleranceType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaTolerancia || dataItem.idEventoTolerancia) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setToleranceItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): ItemToleranceType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Tolerância - ${toleranceItem ? 'Editar' : 'Adicionar'}`}
        size="50%"
      >
        <ToleranceFormAddEdit
          referenceData={props.referenceData}
          origItem={
            toleranceItem
              ? props.origData?.find(
                  (x) =>
                    (x.idPropostaTolerancia === toleranceItem.idPropostaTolerancia ||
                      x.idEventoTolerancia === toleranceItem.idEventoTolerancia) &&
                    toleranceItem.action !== 'add'
                ) || null
              : null
          }
          item={toleranceItem}
          idProposta={props.refId}
          idEvento={props.refId}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection size="lg" color={props.color} label={props.title} text={props.description || ''} />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={props.disabled}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ItemToleranceType) =>
          item.idPropostaTolerancia || item.idEventoTolerancia || item.id || newGuid()
        }
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ToleranceForm;
