/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  Alert,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Input,
  Modal,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useRef, useState } from 'react';
import {
  AlertTriangle,
  Calculator,
  Calendar,
  ClearAll,
  DeviceFloppy,
  Notes,
  Ruler2,
} from 'tabler-icons-react';
import {
  Status,
  TipoCodigo,
  gerenciarVeiculoCompartilhadoMovimentacao,
} from '../../../../../business/events/general';
import {
  MeasurementConversionErrorType,
  calculateCommissionMovements,
  calculateDestinationMovements,
  calculateEquipmentMovements,
  calculateHash,
  calculatePackagingMovements,
  calculateServiceMovements,
  calculateTreatmentMovements,
  calculateVehicleMovements,
} from '../../../../../business/events/measurement';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { MovementType, UnitOfMeasureType } from '../../../../../models/core/cache.type';
import { Action } from '../../../../../models/core/core.type';
import {
  EventMeasurementMovementType,
  EventMeasurementType,
  EventType,
} from '../../../../../models/core/events.type';
import eventsService from '../../../../../services/core/events.service';
import { Feature } from '../../../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid, tryGetDateISOFormat } from '../../../../../utils/helper.utils';
import MovementFormView from './MovementFormView/MovementFormView';
import { validate } from '../../../../../utils/permission.utils';
import { Permission } from '../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../hooks/useCurrentUser';

type EventFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    movementTypeData: MovementType[];
  };
  data: EventType;

  callbackMeasurement(item: EventMeasurementType): void;
};

type FormViewData = {
  action: Action;
  dataRecebimento: Date;
  quantidadeOrigem: number | null;
  quantidadeOrigemIdUnidadeMedida: string | null;
  quantidadeDestino: number | null;
  quantidadeDestinoIdUnidadeMedida: string | null;
  tempo: number | null;
  tempoIdUnidadeMedida: string | null;
  observacao: string | null;
};

export default function MeasurementFormView(props: EventFormViewProps) {
  const [currentUser] = useCurrentUser();
  const refMovementForm = useRef<any>();
  const [updating, setUpdating] = useState(false);
  const [calculateModal, setCalculateModal] = useState<boolean>(false);
  const [calculating, setCalculating] = useState<boolean>(false);
  const [newMovementsMessage, setNewMovementsMessage] = useState<boolean>(false);

  const [data, setMedicao] = useState<{
    medicao: EventMeasurementType | null;
    erros: MeasurementConversionErrorType[];
    hashes: string[];
    key: string;
  }>({
    medicao: props.data.medicao,
    erros: [],
    hashes: [],
    key: newGuid(),
  });

  const disabled = props.data.codigoEventoStatus !== Status.PendenteMedicao;
  const evento = props.data;
  const residuo = evento.referencia?.contrato?.residuo;
  const servico = evento.referencia?.contrato?.servico;
  const item =
    evento.resumoJSON?.acondicionamento || evento.resumoJSON?.equipamento || evento.resumoJSON?.servico;

  const form = useForm<FormViewData>({
    initialValues: {
      action: data.medicao ? Action.Nothing : Action.Add,

      dataRecebimento: formatDateStringToDate(
        tryGetDateISOFormat(data?.medicao?.dataRecebimento || props.data.data)
      ),
      quantidadeOrigem: data?.medicao?.quantidadeOrigem || item?.quantidade || null,
      quantidadeOrigemIdUnidadeMedida: item
        ? props.referenceData.unitOfMeasures
            .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')!
            .idUnidadeMedida.toString() || null
        : data?.medicao?.quantidadeOrigemIdUnidadeMedida?.toString() || null,

      quantidadeDestino: data?.medicao?.quantidadeDestino || null,
      quantidadeDestinoIdUnidadeMedida: data?.medicao?.quantidadeDestinoIdUnidadeMedida?.toString() || null,

      tempo: data?.medicao?.tempo || null,
      tempoIdUnidadeMedida: data?.medicao?.tempoIdUnidadeMedida?.toString() || null,

      observacao: data?.medicao?.observacao || '',
    },
    validate: {
      dataRecebimento: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        return null;
      },
      quantidadeOrigem: (value: number | null, values: FormViewData) => {
        if ((value === null || value === undefined) && values.quantidadeOrigemIdUnidadeMedida) {
          return 'Campo obrigatório';
        }
        const { quantidadeDestino, quantidadeDestinoIdUnidadeMedida } = values;
        if (
          evento.codigoEventoTipo !== TipoCodigo.Comissao &&
          (value === null || value === undefined) &&
          !values.quantidadeOrigemIdUnidadeMedida &&
          !quantidadeDestino &&
          !quantidadeDestinoIdUnidadeMedida
        ) {
          return 'Quantidade de Origem ou de Destino é obrigatório';
        }
        return null;
      },
      quantidadeOrigemIdUnidadeMedida: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.quantidadeOrigem || values.quantidadeOrigem === 0) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeDestino: (value: number | null, values: FormViewData) => {
        if ((value === null || value === undefined) && values.quantidadeDestinoIdUnidadeMedida) {
          return 'Campo obrigatório';
        }
        const { quantidadeOrigem, quantidadeOrigemIdUnidadeMedida } = values;
        if (
          evento.codigoEventoTipo !== TipoCodigo.Comissao &&
          (value === null || value === undefined) &&
          !values.quantidadeOrigemIdUnidadeMedida &&
          !quantidadeOrigem &&
          !quantidadeOrigemIdUnidadeMedida
        ) {
          return 'Quantidade de Origem ou de Destino é obrigatório';
        }
        return null;
      },
      quantidadeDestinoIdUnidadeMedida: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.quantidadeDestino || values.quantidadeDestino === 0) {
          return 'Campo obrigatório';
        }
        return null;
      },
      tempo: (value: number | null, values: FormViewData) => {
        if (value === null || value === undefined) {
          if (values.tempoIdUnidadeMedida) {
            return 'Campo obrigatório';
          }
          const tolerancias = evento.veiculo?.cotacao?.tolerancias?.map((x) => x.idUnidadeMedida) || [];
          const toleranciasUM = props.referenceData.unitOfMeasures.filter((x) => {
            return tolerancias.indexOf(x.idUnidadeMedida) > -1;
          });

          if (toleranciasUM.findIndex((x) => x.tipo === 'Tempo') > -1) {
            return 'Campo obrigatório';
          }
        }

        return null;
      },
      tempoIdUnidadeMedida: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.tempo || values.tempo === 0) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isUnit =
    (props.referenceData.unitOfMeasures.find(
      (x) =>
        String(x.idUnidadeMedida) === form.values.quantidadeOrigemIdUnidadeMedida ||
        form.values.quantidadeDestinoIdUnidadeMedida
    )?.unidadeMedidaSigla || '') === 'un';

  const isModified = (grandTotal: {
    receita: number;
    despesa: number;
    imposto: number;
    balanco: number;
  }): boolean => {
    const origData = `${formatDateToString(data?.medicao?.dataRecebimento || props.data.data) || ''}
    |${Number(data?.medicao?.quantidadeOrigem) || ''}
    |${data?.medicao?.quantidadeOrigemIdUnidadeMedida || ''}
    |${Number(data?.medicao?.quantidadeDestino) || ''}
    |${data?.medicao?.quantidadeDestinoIdUnidadeMedida || ''}
    |${Number(data?.medicao?.tempo) || ''}
    |${data?.medicao?.tempoIdUnidadeMedida || ''}
    |${data?.medicao?.receita || ''}
    |${data?.medicao?.despesa || ''}
    |${data?.medicao?.imposto || ''}
    |${data?.medicao?.balanco || ''}
    |${data?.medicao?.observacao || ''}`;

    const formData = `${formatDateToString(form.values.dataRecebimento)}
    |${form.values.quantidadeOrigem || ''}
    |${form.values.quantidadeOrigemIdUnidadeMedida || ''}
    |${form.values.quantidadeDestino || ''}
    |${form.values.quantidadeDestinoIdUnidadeMedida || ''}
    |${Number(form.values.tempo) || ''}
    |${form.values.tempoIdUnidadeMedida || ''}
    |${grandTotal.receita || ''}
    |${grandTotal.despesa || ''}
    |${grandTotal.imposto || ''}
    |${grandTotal.balanco || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const buildFormItem = () => {
    const formValues = form.values;
    const refMovementData = refMovementForm.current.validate();

    const formItem: EventMeasurementType = {
      ...formValues,
      ...buildFakeAuditObject(),
      idEvento: props.data.idEvento,
      idEventoMedicao: data?.medicao?.idEventoMedicao,

      quantidadeOrigem: formValues.quantidadeOrigem || null,
      quantidadeOrigemIdUnidadeMedida: Number(formValues.quantidadeOrigemIdUnidadeMedida) || null,
      quantidadeDestino: formValues.quantidadeDestino || null,
      quantidadeDestinoIdUnidadeMedida: Number(formValues.quantidadeDestinoIdUnidadeMedida) || null,
      tempo: formValues.tempo || null,
      tempoIdUnidadeMedida: Number(formValues.tempoIdUnidadeMedida) || null,
      receita: refMovementData?.grandTotal?.receita,
      despesa: refMovementData?.grandTotal?.despesa,
      imposto: refMovementData?.grandTotal?.imposto,
      balanco: refMovementData?.grandTotal?.balanco,
    };

    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.movimentacoes = refMovementData.data;

    return formItem;
  };

  const handleSubmit = async () => {
    const formResult = form.validate();
    if (formResult.hasErrors) {
      return;
    }

    try {
      setUpdating(true);

      const formValues = form.values;
      if (props.data.medicao || data.medicao?.idEventoMedicao) {
        if (isModified(refMovementForm.current.validate().grandTotal)) {
          formValues.action = Action.Modify;
        } else {
          formValues.action = Action.Nothing;
        }
      }

      const formItem = buildFormItem();
      let response;

      if (formItem.action === Action.Add && !data.medicao?.idEventoMedicao) {
        response = await eventsService.insertMeasurement(formItem);
      } else {
        response = await eventsService.updateMeasurement(formItem);
      }

      for (const x of response.movimentacoes) {
        x.hash = calculateHash(x);
      }
      setMedicao({ medicao: response, erros: [], hashes: [], key: newGuid() });

      props.callbackMeasurement(response);
      showNotification({
        title: 'Evento - Visualizar',
        message: `Medição definida com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Evento - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível difinir a medição.`,
        color: 'red',
      });
    } finally {
      setUpdating(false);
    }
  };

  const clear = () => {
    const movimentacoes = refMovementForm.current.validate().data as EventMeasurementMovementType[];
    const movimentacoesFiltradas = movimentacoes.filter((x) => {
      return !data.hashes.includes(x.hash!);
    });

    setMedicao({
      ...data,
      medicao: { ...data.medicao!, movimentacoes: movimentacoesFiltradas },
      erros: [],
      hashes: [],
      key: newGuid(),
    });
    setNewMovementsMessage(false);
  };

  const calculate = (calcularFreteCompartilhado: boolean) => {
    // general data
    let idFornecedor;

    // residue data
    let minimoAceitavel = null;
    let minimoAceitavelUM = null;

    if (residuo?.minimoAceitavelUnidadeMedidaSigla2 === 'col') {
      minimoAceitavel = residuo.minimoAceitavel!;
      minimoAceitavelUM =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === residuo.minimoAceitavelIdUnidadeMedida1
        ) || null;
    }

    // form data
    let quantidade;
    let quantidadeUM;
    const { quantidadeOrigem, quantidadeDestino } = form.values;

    const quantidadeOrigemUM = props.referenceData.unitOfMeasures.find(
      (x) => x.idUnidadeMedida === Number(form.values.quantidadeOrigemIdUnidadeMedida)
    );
    const quantidadeDestinoUM = props.referenceData.unitOfMeasures.find(
      (x) => x.idUnidadeMedida === Number(form.values.quantidadeDestinoIdUnidadeMedida)
    );

    const tempo = form.values.tempo!;
    const tempoIdUnidadeMedida = Number(form.values.tempoIdUnidadeMedida!);
    const tempoColetaUM = props.referenceData.unitOfMeasures.find(
      (x) => x.idUnidadeMedida === tempoIdUnidadeMedida
    );

    // movements
    let movimentacoes: EventMeasurementMovementType[] = [];
    let erros: MeasurementConversionErrorType[] = [];

    if (evento.acondicionamento) {
      quantidade = evento.acondicionamento.quantidade;
      quantidadeUM = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(evento.acondicionamento?.quantidadeIdUnidadeMedida)
      );

      idFornecedor = evento.acondicionamento.cotacao.idFornecedor;

      const acondicionamentoMovimentacoes = calculatePackagingMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        idFornecedor,
        evento.acondicionamento,
        quantidade!,
        quantidadeUM!,
        residuo?.compra || false,
        props.referenceData.movementTypeData
      );

      movimentacoes = movimentacoes.concat(acondicionamentoMovimentacoes.movimentacoes);
      erros = erros.concat(acondicionamentoMovimentacoes.erros);
    }

    if (evento.equipamento) {
      quantidade = evento.equipamento.quantidade;
      quantidadeUM = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(evento.equipamento?.quantidadeIdUnidadeMedida)
      );

      idFornecedor = evento.equipamento.cotacao.idFornecedor;

      const equipamentoMovimentacoes = calculateEquipmentMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        idFornecedor,
        evento.equipamento,
        quantidade!,
        quantidadeUM!,
        residuo?.compra || false,
        props.referenceData.movementTypeData
      );

      movimentacoes = movimentacoes.concat(equipamentoMovimentacoes.movimentacoes);
      erros = erros.concat(equipamentoMovimentacoes.erros);
    }

    if (gerenciarVeiculoCompartilhadoMovimentacao(evento) && calcularFreteCompartilhado) {
      quantidade = quantidadeOrigem || quantidadeDestino;
      quantidadeUM = quantidadeOrigemUM || quantidadeDestinoUM;

      idFornecedor = evento.veiculo!.cotacao.idFornecedor;

      const veiculoMovimentacoes = calculateVehicleMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        idFornecedor,
        evento.veiculo!,
        quantidade!,
        quantidadeUM!,
        tempo,
        tempoColetaUM || null,
        minimoAceitavel,
        minimoAceitavelUM,
        residuo?.compra || false,
        residuo?.cobrarTolerancia || false,
        props.referenceData.movementTypeData,
        props.referenceData.unitOfMeasures
      );

      movimentacoes = movimentacoes.concat(veiculoMovimentacoes.movimentacoes);
      erros = erros.concat(veiculoMovimentacoes.erros);
    }

    if (evento.tratamento) {
      quantidade = quantidadeDestino || quantidadeOrigem;
      quantidadeUM = quantidadeDestinoUM || quantidadeOrigemUM;

      idFornecedor = evento.tratamento.cotacao.idFornecedor;

      const tratamentoMovimentacoes = calculateTreatmentMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        idFornecedor,
        evento.tratamento,
        quantidade!,
        quantidadeUM!,
        minimoAceitavel,
        minimoAceitavelUM,
        residuo?.compra || false,
        residuo?.cobrarTolerancia || false,
        props.referenceData.movementTypeData,
        props.referenceData.unitOfMeasures
      );

      movimentacoes = movimentacoes.concat(tratamentoMovimentacoes.movimentacoes);
      erros = erros.concat(tratamentoMovimentacoes.erros);
    }

    if (evento.destinoFinal) {
      quantidade = quantidadeDestino || quantidadeOrigem;
      quantidadeUM = quantidadeDestinoUM || quantidadeOrigemUM;

      idFornecedor = evento.destinoFinal.cotacao.idFornecedor;

      const destinoFinalMovimentacoes = calculateDestinationMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        evento.resumoJSON!.cliente!.idCliente!,
        idFornecedor,
        evento.destinoFinal,
        quantidade!,
        quantidadeUM!,
        minimoAceitavel,
        minimoAceitavelUM,
        residuo?.compra || false,
        residuo?.cobrarTolerancia || false,
        props.referenceData.movementTypeData,
        props.referenceData.unitOfMeasures
      );

      movimentacoes = movimentacoes.concat(destinoFinalMovimentacoes.movimentacoes);
      erros = erros.concat(destinoFinalMovimentacoes.erros);
    }

    if (evento.servico) {
      quantidade = evento.servico.quantidade;
      quantidadeUM = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(evento.servico?.frequenciaIdUnidadeMedida1)
      );

      idFornecedor = evento.servico.cotacao.idFornecedor;

      const servicoMovimentacoes = calculateServiceMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        idFornecedor,
        evento.servico,
        quantidade,
        quantidadeUM!,
        residuo?.compra || false,
        props.referenceData.movementTypeData
      );

      movimentacoes = movimentacoes.concat(servicoMovimentacoes.movimentacoes);
      erros = erros.concat(servicoMovimentacoes.erros);
    }

    if (evento.referencia?.comissao) {
      idFornecedor = evento.referencia?.comissao.idEntidade;

      const comissaoMovimentacoes = calculateCommissionMovements(
        evento.idEvento,
        evento.codigoEventoReferencia!,
        idFornecedor,
        evento.referencia.comissao,
        props.referenceData.movementTypeData,
        props.referenceData.unitOfMeasures
      );

      movimentacoes = movimentacoes.concat(comissaoMovimentacoes.movimentacoes);
      erros = erros.concat(comissaoMovimentacoes.erros);
    }

    try {
      const formItem = buildFormItem();

      // movements - hash
      for (const x of movimentacoes) {
        x.action = Action.Add;
        x.id = newGuid();
        x.hash = calculateHash(x);

        if (formItem.movimentacoes.findIndex((y) => y.hash === x.hash) === -1) {
          formItem.movimentacoes.push(x);
        }
      }

      setMedicao({ medicao: formItem, erros, hashes: movimentacoes.map((x) => x.hash!), key: newGuid() });
      if (
        movimentacoes.length !== (refMovementForm.current.validate().data.length ?? 0) ||
        (movimentacoes.length > 0 && !data.medicao)
      ) {
        setNewMovementsMessage(true);
      }

      // showNotification({
      //   title: 'Evento - Visualizar',
      //   message: `Medição calculada com sucesso.`,
      //   color: 'green',
      // });
    } catch (error: any) {
      showNotification({
        title: 'Evento - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível calcular a medição.`,
        color: 'red',
      });
    }
  };

  const preCalculate = () => {
    const formResult = form.validate();
    if (formResult.hasErrors) {
      return;
    }

    if (props.data.idEventoVeiculoCompartilhado && props.data.resumoJSON?.veiculoCompartilhado) {
      setCalculateModal(true);
    } else {
      calculate(true);
    }
  };

  const buildResidueHeader = () => {
    if (!residuo) {
      return <></>;
    }

    return (
      <Group>
        <ProfileCardLink
          id={residuo.idResiduo.toString()}
          name={residuo.residuoCliente}
          nameSize="md"
          description={`Código IBAMA: ${residuo.codigoIBAMA || '-'} | Perigoso: ${
            residuo.residuoClientePerigoso ? 'Sim' : 'Não'
          }`}
          descriptionSize="sm"
          linkPrefix="residues"
          showLink={false}
        />
        <Group>
          <Stack spacing="xs">
            <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
              {`Estimativa: ${residuo.estimativa} ${residuo.estimativaUnidadeMedida1} / ${residuo.estimativaUnidadeMedida2}`}
            </Badge>
            {residuo.minimoAceitavel ? (
              <Badge variant="outline" size="md" color="orange">
                {`Mínimo Aceitável: ${residuo.minimoAceitavel} ${residuo.minimoAceitavelUnidadeMedida1} / ${residuo.minimoAceitavelUnidadeMedida2}`}
              </Badge>
            ) : (
              <></>
            )}
          </Stack>
          <Stack spacing="xs">
            {residuo.compra && (
              <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
                Compra
              </Badge>
            )}
            {residuo.cobrarTolerancia && (
              <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
                Cobrar Tolerâncias
              </Badge>
            )}
          </Stack>
        </Group>
      </Group>
    );
  };

  const buildServiceHeader = () => {
    if (!servico) {
      return <></>;
    }

    return (
      <Group>
        <ProfileCardLink
          id={servico.idServico.toString()}
          name={servico.servico}
          nameSize="md"
          description={servico.servicoDescricao}
          descriptionSize="sm"
          linkPrefix="services"
          showLink={false}
        />
        <Badge variant="outline" size="md" color={Feature.Home.Event.color}>
          {`${servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}`}
        </Badge>
      </Group>
    );
  };

  return (
    <div>
      <Modal
        opened={calculateModal}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setCalculateModal(false)}
        size="auto"
        title="Gostaria de calcular o valor de frete?"
      >
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <Box w={450}>
              <Text>
                Esse evento possui uma cotação de veículo e também está compartilhando o frete. Ao confirmar,
                os custos de frete original serão adicionados às movimentações.
              </Text>
            </Box>
          </Paper>
          <Space h="xl" />

          <Group position="right">
            <Button
              color="secondary"
              disabled={updating}
              onClick={() => {
                setCalculateModal(false);
                calculate(false);
              }}
            >
              Não
            </Button>
            <Button
              color="primary"
              loading={updating}
              onClick={async () => {
                setCalculateModal(false);
                calculate(true);
              }}
            >
              Sim
            </Button>
          </Group>
        </div>
      </Modal>

      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Medição"
          text="Informações de medição do evento."
        />
        <Button
          color="accent"
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="event-measurement"
          onClick={handleSubmit}
          loading={updating}
          hidden={disabled}
          disabled={!validate(Permission.EventEdit, currentUser.permissoes)}
        >
          Definir Medição
        </Button>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          {residuo ? buildResidueHeader() : servico ? buildServiceHeader() : <></>}
        </Group>
        <Space h="sm" />

        <Paper shadow="xs" p="md" withBorder>
          <Group position="apart">
            <PageSection
              size="md"
              color={Feature.Home.Event.color}
              label="Recebimento de carga e verificação de serviço"
              text="Informações de recebimento de carga e verificação de serviço."
            />
            <Group>
              <Button color="secondary" leftIcon={<ClearAll size={18} />} hidden={disabled} onClick={clear}>
                Limpar
              </Button>
              <Button
                color="primary"
                leftIcon={<Calculator size={18} />}
                type="button"
                onClick={() => {
                  setNewMovementsMessage(false);
                  setCalculating(true);
                  preCalculate();
                  setTimeout(() => {
                    setCalculating(false);
                  }, 2000);
                }}
                hidden={disabled}
                loading={calculating}
              >
                Calcular
              </Button>
            </Group>
          </Group>
          <Space h="xs" />

          {newMovementsMessage && (
            <>
              <Alert
                icon={<AlertTriangle size={32} />}
                title="Novas movimentações"
                color="yellow"
                withCloseButton
                onClose={() => setNewMovementsMessage(false)}
              >
                Novas movimentações foram adicionadas. Verifique-as.
              </Alert>
              <Space h="xs" />
            </>
          )}

          <form id="event-measurement" noValidate>
            <Grid columns={4}>
              <Grid.Col span={1}>
                <DatePicker
                  icon={<Calendar size={15} />}
                  label="Data do Recebimento"
                  locale="pt-br"
                  inputFormat="DD/MM/YYYY"
                  maxDate={new Date()}
                  clearable={false}
                  allowFreeInput
                  required
                  disabled={disabled}
                  {...form.getInputProps('dataRecebimento')}
                />
              </Grid.Col>
              <Grid.Col span={1} />
              <Grid.Col span={2}>
                <Input.Wrapper label="Tempo de Coleta/Serviço">
                  <div />
                </Input.Wrapper>
                <Group spacing="xs">
                  <NumberInput
                    style={{ width: 125 }}
                    placeholder="Tempo"
                    min={0}
                    precision={2}
                    step={0.01}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    disabled={disabled || props.data.codigoEventoTipo === TipoCodigo.Comissao}
                    {...form.getInputProps('tempo')}
                  />
                  <Select
                    icon={<Ruler2 size={15} />}
                    placeholder="Selecione..."
                    data={props.referenceData.unitOfMeasures
                      .filter(
                        (x) =>
                          x.tipo === 'Tempo' && (x.unidadeMedidaSigla === 'h' || x.unidadeMedidaSigla === 'd')
                      )
                      .map((x) => {
                        return {
                          value: x.idUnidadeMedida.toString(),
                          label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                          group: x.tipo,
                        };
                      })}
                    searchable
                    clearable
                    disabled={disabled || props.data.codigoEventoTipo === TipoCodigo.Comissao}
                    {...form.getInputProps('tempoIdUnidadeMedida')}
                  />
                </Group>
              </Grid.Col>
            </Grid>
            <Space h="xs" />

            <Grid columns={4}>
              <Grid.Col span={2}>
                <Input.Wrapper label="Quantidade de Origem">
                  <div />
                </Input.Wrapper>
                <Group spacing="xs">
                  <NumberInput
                    style={{ width: 125 }}
                    placeholder="Quantidade"
                    min={!isUnit ? 0 : 1}
                    precision={!isUnit ? 3 : 0}
                    step={!isUnit ? 0.001 : 1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    disabled={disabled || props.data.codigoEventoTipo === TipoCodigo.Comissao}
                    {...form.getInputProps('quantidadeOrigem')}
                  />
                  <Select
                    icon={<Ruler2 size={15} />}
                    placeholder="Selecione..."
                    data={props.referenceData.unitOfMeasures
                      .filter(
                        (x) =>
                          x.tipo === 'Massa' ||
                          x.tipo === 'Volume' ||
                          (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                      )
                      .map((x) => {
                        return {
                          value: x.idUnidadeMedida.toString(),
                          label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                          group: x.tipo,
                        };
                      })}
                    searchable
                    clearable
                    disabled={disabled || props.data.codigoEventoTipo === TipoCodigo.Comissao}
                    {...form.getInputProps('quantidadeOrigemIdUnidadeMedida')}
                  />
                </Group>
              </Grid.Col>
              <Grid.Col span={2}>
                <Input.Wrapper label="Quantidade de Destino">
                  <div />
                </Input.Wrapper>
                <Group spacing="xs">
                  <NumberInput
                    style={{ width: 125 }}
                    placeholder="Quantidade"
                    min={!isUnit ? 0 : 1}
                    precision={!isUnit ? 3 : 0}
                    step={!isUnit ? 0.001 : 1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    disabled={disabled || props.data.codigoEventoTipo === TipoCodigo.Comissao}
                    {...form.getInputProps('quantidadeDestino')}
                  />
                  <Select
                    icon={<Ruler2 size={15} />}
                    placeholder="Selecione..."
                    data={props.referenceData.unitOfMeasures
                      .filter(
                        (x) =>
                          x.tipo === 'Massa' ||
                          x.tipo === 'Volume' ||
                          (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                      )
                      .map((x) => {
                        return {
                          value: x.idUnidadeMedida.toString(),
                          label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                          group: x.tipo,
                        };
                      })}
                    searchable
                    clearable
                    disabled={disabled || props.data.codigoEventoTipo === TipoCodigo.Comissao}
                    {...form.getInputProps('quantidadeDestinoIdUnidadeMedida')}
                  />
                </Group>
              </Grid.Col>
            </Grid>
            <Space h="xs" />

            <SimpleGrid cols={1}>
              <Textarea
                icon={<Notes size={15} />}
                label="Observação"
                placeholder="Observação sobre a evento"
                autosize
                maxLength={500}
                disabled={disabled}
                {...form.getInputProps('observacao')}
              />
            </SimpleGrid>
          </form>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <MovementFormView
            key={data.key}
            ref={refMovementForm}
            editable={!disabled}
            event={props.data}
            data={{ ...data, comissao: props.data.referencia?.comissao || null }}
            referenceData={props.referenceData}
          />
        </Paper>
      </Paper>
    </div>
  );
}
