/* eslint-disable react/destructuring-assignment */
import { Grid, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { Certificate, ChartInfographic, Database, Id, MapPins, Users, Wallet } from 'tabler-icons-react';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import { EntityType } from '../../../../../models/core/entities.type';
import { Feature } from '../../../../../utils/constants.utils';
import AddressView from './components/AddressView';
import ContactView from './components/ContactView';
import DestinationView from './components/DestinationView';
import DocumentView from './components/DocumentView';
import EquipmentView from './components/EquipmentView';
import GeneralView from './components/GeneralView';
import PackagingView from './components/PackagingView';
import PaymentMethodView from './components/PaymentMethodView';
import ServiceView from './components/ServiceView';
import TreatmentView from './components/TreatmentView';
import VehicleView from './components/VehicleView';
import FinancialPotencial from '../../../../home/Dashboard/Charts/Managerial/Shared/FinancialPotencial';
import QuantityPotencial from '../../../../home/Dashboard/Charts/Managerial/Shared/QuantityPotencial';
import FinancialMovement from '../../../../home/Dashboard/Charts/Managerial/Shared/FinancialMovement';
import Statement from '../../../../home/Dashboard/Charts/Managerial/Shared/Statement';

type DataViewProps = {
  data: EntityType;
};

export default function DataView(props: DataViewProps) {
  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <GeneralView data={props.data} />
          </Paper>
          <Space h="lg" />

          <PageViewAudit
            idCriadoPor={props.data.idCriadoPor}
            criadoPor={props.data.criadoPor}
            dataCriacao={props.data.dataCriacao}
            idModificadoPor={props.data?.idModificadoPor || null}
            modificadoPor={props.data?.modificadoPor || null}
            dataModificacao={props.data?.dataModificacao || null}
            size="md"
          />
        </div>
      ),
      forceRender: true,
    },
    {
      key: 'Contatos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Users size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Contatos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ContactView data={props.data.contatos} size="lg" expandable />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Documentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Certificate size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Documentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <DocumentView data={props.data.documentos} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Endereços',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <MapPins size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Endereços
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <AddressView data={props.data.enderecos} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Formas de Pagamento',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Wallet size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Formas de Pagamento
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <PaymentMethodView data={props.data.formasPagamento} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Referências',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Database size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Referências
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <PackagingView data={props.data.residuoAcondicionamentos} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <EquipmentView data={props.data.residuoEquipamentos} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <VehicleView data={props.data.residuoVeiculos} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <TreatmentView data={props.data.residuoTratamentos} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <DestinationView data={props.data.residuoDestinosFinais} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <ServiceView data={props.data.servicos} />
          </Paper>
        </div>
      ),
      forceRender: true,
    },
    {
      key: 'Estatísticas',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <ChartInfographic size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Estatísticas
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <FinancialPotencial
                idEntidade={props.data.idEntidade}
                color={Feature.Reference.Entity.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <QuantityPotencial
                idEntidade={props.data.idEntidade}
                color={Feature.Reference.Entity.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <FinancialMovement
                idEntidade={props.data.idEntidade}
                color={Feature.Reference.Entity.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <Statement
                idContrato={undefined}
                idEntidade={props.data.idEntidade}
                color={Feature.Reference.Entity.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
        </div>
      ),
      forceRender: false,
    },
  ];

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Tabs
        items={tabs}
        defaultActiveKey="Geral"
        className={`custom-ant-tabs-nav-list custom-ant-tabs-nav-list-${tabs.length}`}
      />
    </Paper>
  );
}
