import { createContext, ReactNode, useContext, useState } from 'react';

type EventEquipmentData = {
  idResiduoEquipamento: number | null;
  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;
};

interface EventEquipmentContextProps {
  eventEquipmentData: EventEquipmentData;
  setEventEquipmentData(eventEquipmentContextData: EventEquipmentData): void;
}
const EventEquipmentContext = createContext<EventEquipmentContextProps>({} as EventEquipmentContextProps);

// eslint-disable-next-line react/function-component-definition
export const EventEquipmentProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventEquipmentData, setEventEquipmentData] = useState<EventEquipmentData>({} as EventEquipmentData);
  return (
    <EventEquipmentContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventEquipmentData,
        setEventEquipmentData,
      }}
    >
      {children}
    </EventEquipmentContext.Provider>
  );
};

export type { EventEquipmentData };

export function useEventEquipmentContext() {
  return useContext(EventEquipmentContext);
}
