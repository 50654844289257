/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Alert, Group, Paper, Space, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import filesize from 'filesize';
import { useState } from 'react';
import { CircleX, FileUpload, Icon, Upload, X } from 'tabler-icons-react';
import moment from 'moment';
import { Action } from '../../../../../../models/core/core.type';
import { EventDocumentFileType } from '../../../../../../models/core/events.type';
import theme from '../../../../../../theme';
import { buildFakeAuditObject, newGuid } from '../../../../../../utils/helper.utils';

type FormViewProps = {
  items: EventDocumentFileType[];
  idEventoDocumento: number | undefined;
  callback(items: EventDocumentFileType[] | null, action: string, confirmed: boolean): void;
};

type DocumentFile = {
  nome: string;
  tamanho: number;
  tipo: string;
  binario: string;
};

function getIconColor(status: string) {
  if (status === 'accepted') {
    return theme.colors!.primary![theme.colorScheme === 'dark' ? 4 : 6];
  }
  if (status === 'rejected') {
    return theme.colors!.red![theme.colorScheme === 'dark' ? 4 : 6];
  }
  if (theme.colorScheme === 'dark') {
    return theme.colors!.dark![0];
  }
  return theme.colors!.gray![7];
}

function ImageUploadIcon({ status, ...props }: React.ComponentProps<Icon> & { status: string }) {
  if (status === 'accepted') {
    return <Upload {...props} />;
  }

  if (status === 'rejected') {
    return <X {...props} />;
  }

  return <FileUpload {...props} />;
}

export default function DocumentFileFormAddEdit(props: FormViewProps) {
  const [error, setError] = useState<any | null>(null);

  const uploadLimit = {
    size: 3 * 1024 ** 2,
    label: '3MB',
  };

  const handleSubmit = async (attachedFiles: DocumentFile[]) => {
    const formItems: EventDocumentFileType[] = attachedFiles.map((x) => {
      const id = newGuid();
      return {
        ...x,
        ...buildFakeAuditObject(),
        idTemporaria: id,
        idEventoDocumento: props.idEventoDocumento || -1,
        action: Action.Add,
        id,
        dataCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
    });
    props.callback(formItems, 'callback', true);
  };

  function readFileAsync(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => {
        const customError = <Text>Operação abortada no arquivo {file.name}.</Text>;
        setError(customError);
        reject();
      };
      reader.onerror = () => {
        const customError = <Text>Não foi possível selecionar o arquivo {file.name}.</Text>;
        setError(customError);
        reject();
      };
      reader.onload = () => {
        resolve({ nome: file.name, tamanho: file.size, tipo: file.type, binario: reader.result });
      };
      reader.readAsDataURL(file);
    });
  }

  const onDropFiles = async (attachedFiles: File[]) => {
    const alreadyAttachedFiles =
      attachedFiles
        .filter((y) => props.items.map((z) => z.nome).includes(y.name))
        .map((attachedFile) => {
          return (
            <Text size="sm" color="dimmed">
              {`- ${attachedFile.name} (${filesize(attachedFile.size, { locale: 'pt-BR' })})`}
            </Text>
          );
        }) || [];
    if (alreadyAttachedFiles.length > 0) {
      setError({
        title: `Arquivos já adicionados:`,
        data: <div>{alreadyAttachedFiles}</div>,
      });
      return;
    }

    const maxSizeFiles =
      attachedFiles
        .filter((x) => x.size > uploadLimit.size)
        .map((attachedFile) => {
          return (
            <Text size="sm" color="dimmed">
              {`- ${attachedFile.name} (${filesize(attachedFile.size, { locale: 'pt-BR' })})`}
            </Text>
          );
        }) || [];
    if (maxSizeFiles.length > 0) {
      setError({
        title: `Arquivos rejeitados por excederem o tamanho máximo de ${uploadLimit.label}:`,
        data: <div>{maxSizeFiles}</div>,
      });
      return;
    }
    const sumFilesSize = props.items
      .filter((x) => x.action === 'add')
      .map((y) => y.tamanho)
      .concat(attachedFiles.map((z) => z.size))
      .reduce((a, b) => a + b, 0);
    if (sumFilesSize > uploadLimit.size) {
      setError({
        title: `O tamanho de todos os arquivos a serem adicionados é maior que ${uploadLimit.label}:`,
        data: <div>Total: {filesize(sumFilesSize, { locale: 'pt-BR' })}</div>,
      });
      return;
    }

    const newFiles: DocumentFile[] = [];
    for (const file of attachedFiles) {
      const documentFile = (await readFileAsync(file)) as DocumentFile;
      newFiles.push(documentFile);
    }
    handleSubmit(newFiles);
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      {error && (
        <>
          <Alert
            icon={<CircleX size={16} />}
            title={error.title}
            color="red"
            withCloseButton
            onClose={() => setError(null)}
          >
            {error.data}
          </Alert>
          <Space h="xl" />
        </>
      )}
      {/* <Dropzone maxSize={uploadLimit.size} onDrop={onDropFiles} onReject={onRejectedFiles}> */}
      <Dropzone onDrop={onDropFiles}>
        <Dropzone.Idle>
          <Group position="center" spacing="xl">
            <ImageUploadIcon status="idle" style={{ color: getIconColor('idle') }} size={80} />
            <div>
              <Text size="xl" inline>
                Arraste arquivos aqui ou clique para seleciona-los
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Adicione quantos arquivos quiser totalizando até {uploadLimit.label}
              </Text>
            </div>
          </Group>
        </Dropzone.Idle>
      </Dropzone>
    </Paper>
  );
}
