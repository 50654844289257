/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { BidirectionalBar, BidirectionalBarConfig, Column, ColumnConfig } from '@ant-design/plots';
import {
  Badge,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Building,
  Filter,
  Table as ITable,
  PlugConnectedX,
  Reload,
  SortDescendingNumbers,
  TableOff,
} from 'tabler-icons-react';
import { TipoCodigo } from '../../../../business/events/general';
import PageSection from '../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { CompanyType, UnitOfMeasureType } from '../../../../models/core/cache.type';
import { ContractGeneralTopResidueResponseType, ReportItem } from '../../../../models/core/report.type';
import reportsService from '../../../../services/core/report.service';
import theme from '../../../../theme';
import cacheUtils from '../../../../utils/cache.utils';
import { Chart, Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { formatCurrency } from '../../../../utils/formatter.utils';
import { convertUnitOfMeasure } from '../../../../utils/helper.utils';

type FormViewProps = {
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  top: number;
  ordem: string;
};

type Response = {
  filterConfig: FilterData;
  data: ContractGeneralTopResidueResponseType[];
  financeChartConfig: ColumnConfig;
  quantityMassChartConfig: BidirectionalBarConfig;
  quantityVolumeChartConfig: BidirectionalBarConfig;
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Resíduos',
  description:
    'Fechamentos finalizados em um período de tempo com maiores indicadores financeiros e quantitativos por resíduo.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.ContratoGeralTopResiduos}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    top: 10,
    ordemData: [
      {
        value: 'receita',
        label: `Receita`,
      },
      {
        value: 'despesa',
        label: `Despesa`,
      },
      {
        value: 'imposto',
        label: `Imposto`,
      },
      {
        value: 'balanco',
        label: `Balanço`,
      },
    ],
    ordem: 'balanco',
  },
  dataConfig: {
    exportable: true,
    rowKey: 'idEvento',
    columns: [
      {
        title: 'Empresa',
        key: 'empresa',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) =>
          a.empresa.localeCompare(b.empresa),
        render: (row: ContractGeneralTopResidueResponseType) => (
          <Badge variant="outline">{row.empresa}</Badge>
        ),
      },
      {
        title: 'Contrato #',
        key: 'idContrato',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.idContrato;
          const bValue = b.idContrato;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => (
          <ProfileCardLink
            id={row.idContrato.toString()}
            name="Contrato"
            nameSize="sm"
            description={`# ${row.idContrato}`}
            descriptionSize="xs"
            linkPrefix="contracts"
            showLink
          />
        ),
      },
      {
        title: 'Proposta #',
        key: 'idProposta',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.idProposta;
          const bValue = b.idProposta;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => (
          <ProfileCardLink
            id={row.idProposta.toString()}
            name="Proposta"
            nameSize="sm"
            description={`# ${row.idProposta}`}
            descriptionSize="xs"
            linkPrefix="proposals"
            showLink
          />
        ),
      },
      {
        title: 'Cliente',
        key: 'idCliente',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) =>
          (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
            b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
          ),
        render: (row: ContractGeneralTopResidueResponseType) => {
          if (row.clienteCNPJ) {
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                nameSize="sm"
                description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idCliente.toString()}
              name={row.clienteNome || '-'}
              nameSize="sm"
              description={row.clienteCPF}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        },
      },
      {
        title: 'Evento',
        key: 'idEvento',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.idEvento;
          const bValue = b.idEvento;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => {
          return (
            <ProfileCardLink
              id={row.idEvento.toString()}
              name={`# ${row.idEvento}`}
              nameSize="sm"
              avatar="E"
              description={`${row.eventoTipo}`}
              descriptionSize="xs"
              linkPrefix="events"
              showLink={false}
            />
          );
        },
      },
      {
        title: 'Resíduo',
        key: 'residuo',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) =>
          a.residuoCliente.localeCompare(b.residuoCliente),
        render: (row: ContractGeneralTopResidueResponseType) => {
          return (
            <ProfileCardLink
              id={row.idResiduo.toString()}
              name={row.residuoCliente}
              nameSize="sm"
              description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
                row.residuoClientePerigoso ? 'Sim' : 'Não'
              }`}
              descriptionSize="xs"
              linkPrefix="residues"
              showLink
            />
          );
        },
      },
      {
        title: 'Quantidade',
        key: 'quantidade',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.quantidade;
          const bValue = b.quantidade;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => (
          <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>
        ),
      },
      {
        title: 'Tempo de Coleta',
        key: 'tempo',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.tempo || 0;
          const bValue = b.tempo || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => {
          if (!row.tempo) {
            return '-';
          }
          return <div>{`${row.tempo} ${row.tempoUnidadeMedida}`}</div>;
        },
      },
      {
        title: 'Receita',
        key: 'receita',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.receita || 0;
          const bValue = b.receita || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => <div>{formatCurrency(row.receita)}</div>,
      },
      {
        title: 'Despesa',
        key: 'despesa',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.despesa || 0;
          const bValue = b.despesa || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => <div>{formatCurrency(row.despesa)}</div>,
      },
      {
        title: 'Imposto',
        key: 'imposto',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.imposto || 0;
          const bValue = b.imposto || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => <div>{formatCurrency(row.imposto)}</div>,
      },
      {
        title: 'Balanço',
        key: 'balanco',
        sorter: (a: ContractGeneralTopResidueResponseType, b: ContractGeneralTopResidueResponseType) => {
          const aValue = a.receita - a.despesa - a.imposto;
          const bValue = b.receita - b.despesa - b.imposto;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: ContractGeneralTopResidueResponseType) => {
          const total = row.receita - row.despesa - row.imposto;
          const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

          return <div style={{ color }}>{formatCurrency(total)}</div>;
        },
      },
    ],
  },
  chartConfigFinance: {
    data: [],
    xField: 'referencia',
    yField: 'valor',
    yAxis: {
      label: {
        formatter: (value) => {
          return formatCurrency(Number(value));
        },
      },
    },
    seriesField: 'tipo',
    isGroup: true,
    appendPadding: 15,
    // label: {
    //   position: 'top',
    //   formatter: (el) => {
    //     return formatCurrency(Number(el.valor));
    //   },
    // },
    color: (data) => {
      return (Chart.Color as any)[data.tipo];
    },
    legend: {
      position: 'bottom',
    },
    tooltip: {
      formatter: (el: any) => ({
        name: el.tipo,
        value: formatCurrency(el.valor),
      }),
    },
  } as ColumnConfig,
  chartConfigQuantityMass: {
    data: [],
    xField: 'referencia',
    xAxis: {
      position: 'bottom',
    },
    interactions: [
      {
        type: 'active-region',
      },
    ],
    yField: ['quantidadeTempo', 'quantidadeMassa'],
    label: {
      position: 'middle',
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
    meta: {
      quantidadeTempo: { alias: 'Tempo - Hora(s)' },
      quantidadeMassa: { alias: 'Massa - Tonelada(s)' },
    },
  } as BidirectionalBarConfig,
  chartConfigQuantityVolume: {
    data: [],
    xField: 'referencia',
    xAxis: {
      position: 'bottom',
    },
    interactions: [
      {
        type: 'active-region',
      },
    ],
    yField: ['quantidadeTempo', 'quantidadeVolume'],
    label: {
      position: 'middle',
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
    meta: {
      quantidadeTempo: { alias: 'Tempo - Hora(s)' },
      quantidadeVolume: { alias: 'Volume - Metro(s) cúbico(s)' },
    },
  } as BidirectionalBarConfig,
};

const ContractGeneralTopResidue = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  // eslint-disable-next-line prefer-const
  let [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
    unitOfMeasures: UnitOfMeasureType[];
  }>({
    companyData: [],
    unitOfMeasures: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idEmpresa:
        form.values.idEmpresa !== null && form.values.idEmpresa !== undefined
          ? Number(form.values.idEmpresa)
          : null,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.contratoGeralTopResiduos(searchCriteria);
    return data;
  };

  const sortMe = (data: any[]) => {
    return data.sort((a: any, b: any) => b[form.values.ordem] - a[form.values.ordem]);
  };

  const filterMe = (data: ContractGeneralTopResidueResponseType[]) => {
    let filteredData = data.filter(
      (x) => Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa))
    );
    filteredData = sortMe(filteredData);
    filteredData = filteredData.slice(0, form.values.top || 1);
    return filteredData;
  };

  const buildChartData = async (data: ContractGeneralTopResidueResponseType[]) => {
    referenceData = {
      companyData: await cacheUtils.listCompanies(),
      unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
    };

    const toneladaUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Massa' && x.unidadeMedidaSigla === 'ton'
    )!;
    const metroCubicoUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Volume' && x.unidadeMedidaSigla === 'm³'
    )!;
    const horaUoM = referenceData.unitOfMeasures.find(
      (x) => x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'h'
    )!;

    const commonQuantityData: (ContractGeneralTopResidueResponseType & {
      quantidadeMassa: number;
      quantidadeMassaUnidadeMedida: UnitOfMeasureType;
      quantidadeVolume: number;
      quantidadeVolumeUnidadeMedida: UnitOfMeasureType;
      quantidadeTempo: number;
      quantidadeTempoUnidadeMedida: UnitOfMeasureType;
    })[] = data.map((x) => {
      return {
        ...x,
        quantidadeMassa: x.quantidadeIdUnidadeMedida === toneladaUoM.idUnidadeMedida ? x.quantidade : 0,
        quantidadeMassaUnidadeMedida: toneladaUoM,
        quantidadeVolume: x.quantidadeIdUnidadeMedida === metroCubicoUoM.idUnidadeMedida ? x.quantidade : 0,
        quantidadeVolumeUnidadeMedida: metroCubicoUoM,
        quantidadeTempo: x.tempoIdUnidadeMedida === horaUoM.idUnidadeMedida ? x.tempo || 0 : 0,
        quantidadeTempoUnidadeMedida: horaUoM,
      };
    });

    // converting all UoMs to common ones
    for (const item of commonQuantityData) {
      if (item.quantidadeMassa === 0 && item.codigoEventoTipo === TipoCodigo.Coleta) {
        const massaUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.quantidadeIdUnidadeMedida
        )!;
        const conversaoMassa = convertUnitOfMeasure(massaUoM, toneladaUoM, item.quantidade);
        if (conversaoMassa.valid) {
          item.quantidadeMassa = conversaoMassa.calc || 0;
          item.quantidadeMassaUnidadeMedida = toneladaUoM;
        } else {
          item.quantidadeMassa = -1;
          item.quantidadeMassaUnidadeMedida = toneladaUoM;
        }
      }

      if ((item.tempo || 0) > 0) {
        const tempoUoM = referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === item.tempoIdUnidadeMedida
        )!;
        const conversaoMassa = convertUnitOfMeasure(tempoUoM, horaUoM, item.tempo);
        if (conversaoMassa.valid) {
          item.quantidadeTempo = conversaoMassa.calc || 0;
          item.quantidadeTempoUnidadeMedida = horaUoM;
        } else {
          item.quantidadeTempo = -1;
          item.quantidadeTempoUnidadeMedida = horaUoM;
        }
      }
    }

    // group by
    let finalData = [];
    const groupedData = lodash.groupBy(commonQuantityData, 'idPropostaResiduo');
    for (const key of Object.keys(groupedData)) {
      const items = groupedData[key];
      const referencia = items[0].residuoCliente;
      const receita = items.reduce((a, b: any) => a + b.receita, 0);
      const despesa = items.reduce((a, b: any) => a + b.despesa, 0);
      const imposto = items.reduce((a, b: any) => a + b.imposto, 0);
      const balanco = items.reduce((a, b: any) => a + b.balanco, 0);
      const quantidadeMassa = items.reduce((a, b: any) => a + b.quantidadeMassa, 0);
      const quantidadeVolume = items.reduce((a, b: any) => a + b.quantidadeVolume, 0);
      const quantidadeTempo = items.reduce((a, b: any) => a + b.quantidadeTempo, 0);

      finalData.push({
        referencia,
        receita,
        despesa,
        imposto,
        balanco,
        quantidadeMassa,
        quantidadeVolume,
        quantidadeTempo,
      });
    }

    finalData = sortMe(finalData);
    const financeChartData = [];
    for (const item of finalData) {
      const { referencia } = item;

      financeChartData.push({ referencia, tipo: 'Receita', valor: item.receita });
      financeChartData.push({ referencia, tipo: 'Despesa', valor: item.despesa });
      financeChartData.push({ referencia, tipo: 'Imposto', valor: item.imposto });
      financeChartData.push({ referencia, tipo: 'Balanço', valor: item.balanco });
    }

    return { quantityChartData: finalData, financeChartData };
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);
    const chartData = await buildChartData(filteredData);

    return {
      filterConfig: form.values,
      data,
      financeChartConfig: {
        ...componentConfig.chartConfigFinance,
        data: chartData.financeChartData,
      },
      quantityMassChartConfig: {
        ...componentConfig.chartConfigQuantityMass,
        data: chartData.quantityChartData.filter((x) => x.quantidadeVolume === 0),
      },
      quantityVolumeChartConfig: {
        ...componentConfig.chartConfigQuantityVolume,
        data: chartData.quantityChartData.filter((x) => x.quantidadeVolume > 0),
      },
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
        unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult });
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const topChanged = form.values.top !== filterConfig?.top;
    const ordemChanged = form.values.ordem !== filterConfig?.ordem;

    if (idEmpresaChanged || topChanged || ordemChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  const noDataView = () => {
    return (
      <Stack spacing="md" align="center">
        <TableOff color={theme?.colors?.primary?.[6]} size={32} />
        <Text>Sem dados para mostrar.</Text>
      </Stack>
    );
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Dashboard.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Dashboard.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Dashboard.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} overlayBlur={3} />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <DateRangePicker
                label="Período"
                description="Filtro: entre"
                placeholder="Período"
                clearable={false}
                amountOfMonths={2}
                allowSingleDateInRange
                required
                {...form.getInputProps('periodo')}
              />
              <Group>
                <Select
                  icon={<Building size={15} />}
                  label="Empresa"
                  description="Filtro: igual"
                  placeholder="Todos..."
                  data={referenceData.companyData.map((x) => {
                    return {
                      value: x.idEmpresa.toString(),
                      label: x.empresa,
                    };
                  })}
                  searchable
                  clearable={currentUser.executivo}
                  disabled={!currentUser.executivo}
                  {...form.getInputProps('idEmpresa')}
                />
                {/* <NumberInput
                    icon={<Calendar size={15} />}
                    label="Dias"
                    description="Filtro: Últimos"
                    placeholder="Desde Sempre"
                    min={0}
                    step={1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    {...form.getInputProps('dias')}
                  /> */}
                <NumberInput
                  icon={<Filter size={15} />}
                  label="Top"
                  description="Filtro: Igual"
                  placeholder="Top"
                  type="number"
                  min={1}
                  max={25}
                  step={1}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  {...form.getInputProps('top')}
                />
                <Select
                  icon={<SortDescendingNumbers size={15} />}
                  style={{ width: 150 }}
                  label="Ordem"
                  description="Filtro: Igual"
                  placeholder="Selecione..."
                  data={componentConfig.filterConfig.ordemData}
                  {...form.getInputProps('ordem')}
                />
              </Group>
            </Group>
          </Paper>
          <Space h="md" />

          {result.error ? (
            <Stack spacing="md" align="center">
              <PlugConnectedX color="red" size={32} />
              <Text>{result.error}</Text>
            </Stack>
          ) : result.loaded ? (
            (result.response?.data || []).length > 0 ? (
              <div>
                <Grid columns={4}>
                  <Grid.Col span={4}>
                    <Paper shadow="xs" p="md" withBorder>
                      <Column {...result.response?.financeChartConfig!} height={props.height} />
                    </Paper>
                  </Grid.Col>
                </Grid>
                <Space h="lg" />

                <Grid columns={4}>
                  <Grid.Col span={2}>
                    <Paper shadow="xs" p="md" withBorder>
                      {(result.response?.quantityMassChartConfig.data.length || 0) === 0 ? (
                        noDataView()
                      ) : (
                        <BidirectionalBar
                          {...result.response?.quantityMassChartConfig!}
                          height={props.height}
                        />
                      )}
                    </Paper>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Paper shadow="xs" p="md" withBorder>
                      {(result.response?.quantityVolumeChartConfig.data.length || 0) === 0 ? (
                        noDataView()
                      ) : (
                        <BidirectionalBar
                          {...result.response?.quantityVolumeChartConfig!}
                          height={props.height}
                        />
                      )}
                    </Paper>
                  </Grid.Col>
                </Grid>
              </div>
            ) : (
              <Paper shadow="xs" p="md" withBorder>
                {noDataView()}
              </Paper>
            )
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <Reload color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Esperando atualização</Text>
              </Stack>
            </Paper>
          )}
        </div>
      </Paper>
    </Paper>
  );
});

export default ContractGeneralTopResidue;
