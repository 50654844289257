/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Alert, Badge, Button, Group, Modal, Paper, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { AlertTriangle, Plus } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { CompanyType, DocumentType } from '../../../../../../models/core/cache.type';
import { Action } from '../../../../../../models/core/core.type';
import {
  EventDocumentFileType,
  EventDocumentType,
  EventType,
} from '../../../../../../models/core/events.type';
import { Feature, SessionStorageKey } from '../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../utils/helper.utils';
import DocumentFormAddEdit from './DocumentFormAddEdit';
import DocumentFormViewActions from './DocumentFormViewActions';
import FileView from './FileView';
import { validate } from '../../../../../../utils/permission.utils';
import { Permission } from '../../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';

type EventDocumentEditType = EventDocumentType & {
  origFiles?: EventDocumentFileType[];
};

type FormViewProps = {
  referenceData: {
    documentTypeData: DocumentType[];
    companyData: CompanyType[];
  };
  data: EventType;
};

const DocumentFormView = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const [modalData, setModalData] = useState<{ opened: boolean; item: EventDocumentEditType | null }>({
    opened: false,
    item: null,
  });
  const [data, setData] = useState<EventDocumentEditType[]>(
    props.data.documentos.map((x) => {
      return {
        ...x,
        // origFiles: x.arquivos,
        action: Action.Nothing,
        id: x.idEventoDocumento?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo',
      key: 'documentoTipo',
      sorter: (a: EventDocumentType, b: EventDocumentType) => {
        return (a.outroTipo || a.documentoTipo).localeCompare(b.outroTipo || b.documentoTipo);
      },
      render: (row: EventDocumentType) => (
        <div>{`${row.outroTipo ? `(O) ${row.outroTipo}` : row.documentoTipo}`}</div>
      ),
    },
    {
      title: 'Número',
      key: 'numeroDocumento',
      sorter: (a: EventDocumentType, b: EventDocumentType) => {
        return (a.numeroDocumento || '').localeCompare(b.numeroDocumento || '');
      },
      render: (row: EventDocumentType) => <div>{row?.numeroDocumento || '-'}</div>,
    },
    {
      title: 'Data',
      key: 'data',
      sorter: (a: EventDocumentType, b: EventDocumentType) => {
        const date = new Date();
        return new Date(a.data || date).valueOf() - new Date(b.data || date).valueOf();
      },
      render: (row: EventDocumentType) => {
        if (row.data) {
          return moment(typeof row.data === 'string' ? moment(row.data, 'yyyy-MM-DD') : row.data).format(
            'DD/MM/yyyy'
          );
        }
        return '-';
      },
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EventDocumentType, b: EventDocumentType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EventDocumentType) => {
        return <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />;
      },
    },
    {
      title: 'Arquivos',
      key: 'arquivos',
      sorter: (a: EventDocumentType, b: EventDocumentType) => {
        const aValue = a.arquivos.filter((x) => x.action !== Action.Delete).length;
        const bValue = b.arquivos.filter((x) => x.action !== Action.Delete).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventDocumentType) => {
        return <div>{row.arquivos.filter((x) => x.action !== Action.Delete).length}</div>;
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EventDocumentType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DocumentFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EventDocumentEditType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      setModalData({ opened: false, item: null });
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEventoDocumento) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setModalData({ opened: true, item: item! });
        break;
      case 'callback':
        setModalData({ opened: false, item: null });
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(JSON.parse(JSON.stringify(data)));
        break;
      default:
        break;
    }
  };

  const calculatePendingDocuments = () => {
    const residue = props.data.referencia?.contrato?.residuo;
    if (!residue) {
      return [];
    }
    const doc = residue.documento;

    let allItems = [
      doc.notaFiscal ? 'Nota Fiscal' : '',
      doc.xml ? 'XML' : '',
      doc.mtr ? 'MTR' : '',
      doc.sga ? 'SGA' : '',
      doc.romaneioColeta ? 'Romaneio de Coleta' : '',
      doc.pesoOrigem ? 'Peso de Origem' : '',
      doc.pesoDestino ? 'Peso de Destino' : '',
    ];
    allItems = allItems.filter((x) => x);

    const processedItems = data.filter((x) => x.action !== Action.Delete).map((y) => y.documentoTipo) || [];

    for (const item of processedItems) {
      const index = allItems.findIndex((x) => x === item);
      if (index > -1) {
        allItems.splice(index, 1);
      }
    }

    return allItems;
  };
  const buildDocumentBadgeList = (list: string[]) => {
    return (
      <Group>
        {list.map((x) => (
          <Badge key={newGuid()} variant="outline" size="sm">
            {x}
          </Badge>
        ))}
      </Group>
    );
  };
  const pendingDocuments = calculatePendingDocuments();

  useImperativeHandle(ref, () => ({
    validate(): EventDocumentEditType[] {
      return data.map((x) => {
        return {
          ...x,
          arquivos: x.arquivos.map((y) => {
            return { ...y, action: y.action || Action.Nothing };
          }),
        };
      });
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEvent = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEvent) || 'null');
    if (tempEvent) {
      setData(tempEvent.documentos);
    }
  }, []);

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setModalData({ opened: false, item: null })}
        title={`Documento - ${modalData.item ? 'Editar' : 'Adicionar'}`}
        size="xl"
      >
        <DocumentFormAddEdit
          referenceData={props.referenceData}
          origItem={modalData.item}
          item={modalData.item}
          idEvento={props.data.idEvento}
          idEmpresa={props.data.idEmpresa}
          callback={confirmActionResult}
        />
      </Modal>

      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Documentos"
          text="Lista de documentos associados à esse evento."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={!validate(Permission.EventEdit, currentUser.permissoes)}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />

      {pendingDocuments.length > 0 && (
        <div>
          <Alert icon={<AlertTriangle size={32} />} title="Documentos Pendentes" color="yellow">
            {buildDocumentBadgeList(pendingDocuments)}
          </Alert>
          <Space h="lg" />
        </div>
      )}

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EventDocumentEditType) => item.id || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <div>
                <PageViewAudit
                  idCriadoPor={item.idCriadoPor}
                  criadoPor={item.criadoPor}
                  dataCriacao={item.dataCriacao}
                  idModificadoPor={item.idModificadoPor || null}
                  modificadoPor={item.modificadoPor || null}
                  dataModificacao={item.dataModificacao || null}
                  size="sm"
                />
                <Space h="md" />
                <Paper shadow="xs" p="md" withBorder>
                  <FileView
                    typeId={item.idEvento}
                    data={item.arquivos.filter((x) => x.action !== Action.Delete)}
                    size="sm"
                    expandable
                  />
                </Paper>
              </div>
            );
          },
        }}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default DocumentFormView;
