/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { useMemo, useRef } from 'react';
import { CurrencyReal, DeviceFloppy, Notes, Ruler2, Scale, ZoomMoney } from 'tabler-icons-react';
import EntityItemSearch from '../../../../../../../../../../../../../components/core/EntityItemSearch/EntityItemSearch';
import PageSection from '../../../../../../../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ToleranceForm from '../../../../../../../../../../../../../components/core/Tolerance/ToleranceForm';
import {
  EntityTypeType,
  UnitOfMeasureType,
} from '../../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../../../../../../../../../models/core/entities.type';
import {
  ProposalResiduePlanQuotationVehicleType,
  ProposalResiduePlanVehicleType,
} from '../../../../../../../../../../../../../models/core/proposals.type';
import { EntityItemEnum, Feature } from '../../../../../../../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  fornecedorData: EntitySearchResponseType | null;
  idFornecedor: number | null;
  fornecedor: string;
  idEntidadeResiduoVeiculo: number | null;

  quantidade: number;
  quantidadeIdUnidadeMedida: string | null;
  frequenciaIdUnidadeMedida: string | null;
  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida: string | null;

  preco: number | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanQuotationVehicleType | null;
  item: ProposalResiduePlanQuotationVehicleType | null;
  refItem: ProposalResiduePlanVehicleType;
  idPropostaResiduoPlanoCotacao: number | undefined;
  callback(
    item: ProposalResiduePlanQuotationVehicleType | null,
    refItem: ProposalResiduePlanVehicleType | null,
    action: string,
    confirmed: boolean
  ): void;
};

export default function RPQuotationVehicleFormAddEdit(props: FormViewProps) {
  const refToleranceForm = useRef<any>();

  const buildFornecedorDataFromItem = (
    item: ProposalResiduePlanQuotationVehicleType | null
  ): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idFornecedor),
      tipos: [],
      cnpj: item.fornecedorCNPJ || null,
      razaoSocial: item.fornecedorRazaoSocial || null,
      nomeFantasia: item.fornecedorNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.fornecedorCPF || null,
      nome: item.fornecedorNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const builQuantityFromContext = useMemo(() => {
    const um1 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(props.refItem?.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    return `${props.refItem?.quantidade} ${um1}`;
  }, [props.refItem, props.referenceData.unitOfMeasures]);

  const builWeightFromContext = useMemo(() => {
    const um1 =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(props.refItem?.cargaMediaIdUnidadeMedida)
      )?.unidadeMedida || '';

    return `${props.refItem?.cargaMedia} ${um1}`;
  }, [props.refItem, props.referenceData.unitOfMeasures]);

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || props.refItem?.id || newGuid(),

      fornecedorData: buildFornecedorDataFromItem(props.item),
      idFornecedor: props.item?.idFornecedor || null,
      fornecedor:
        props.item?.fornecedorNomeFantasia ||
        props.item?.fornecedorRazaoSocial ||
        props.item?.fornecedorNome ||
        '',
      idEntidadeResiduoVeiculo: props.item?.idEntidadeResiduoVeiculo || null,

      quantidade: props.item?.quantidade || props.refItem?.quantidade || 1,
      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida?.toString() ||
        props.refItem?.quantidadeIdUnidadeMedida.toString() ||
        null,
      frequenciaIdUnidadeMedida: props.item?.frequenciaIdUnidadeMedida?.toString() || null,

      minimoAceitavel: props.item?.minimoAceitavel || null,
      minimoAceitavelIdUnidadeMedida: props.item?.minimoAceitavelIdUnidadeMedida?.toString() || null,

      preco: props.item?.preco === 0 ? 0 : props.item?.preco || null,

      observacao: props.item?.observacao || '',
    },
    validate: {
      fornecedor: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      minimoAceitavel: (value: number | null, values: FormViewData) => {
        if ((value === null || value === undefined) && values.minimoAceitavelIdUnidadeMedida) {
          return 'Campo obrigatório';
        }
        if (value === 0 && values.minimoAceitavelIdUnidadeMedida) {
          return 'Mínimo Aceitável deve ser maior do que zero';
        }
        return null;
      },
      minimoAceitavelIdUnidadeMedida: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.minimoAceitavel) {
          return 'Campo obrigatório';
        }
        return null;
      },
      preco: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idFornecedor || ''}
    |${props.origItem?.idEntidadeResiduoVeiculo || ''}
    |${props.origItem?.quantidade || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida || ''}
    |${Number(props.origItem?.minimoAceitavel) || ''}
    |${props.origItem?.minimoAceitavelIdUnidadeMedida || ''}
    |${Number(props.origItem?.preco) || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idFornecedor || ''}
    |${form.values.idEntidadeResiduoVeiculo || ''}
    |${form.values.quantidade || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.frequenciaIdUnidadeMedida || ''}
    |${Number(form.values.minimoAceitavel) || ''}
    |${form.values.minimoAceitavelIdUnidadeMedida || ''}
    |${Number(form.values.preco) || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (props.origItem) {
      if (isModified() && props.origItem.idPropostaResiduoPlanoCotacaoVeiculo) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          frequenciaIdUnidadeMedida: form.values.frequenciaIdUnidadeMedida?.toString() || '',
          minimoAceitavelIdUnidadeMedida: form.values.minimoAceitavelIdUnidadeMedida?.toString() || '',
        };
      }
    }

    const formItem: ProposalResiduePlanQuotationVehicleType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaResiduoPlanoCotacao: props.idPropostaResiduoPlanoCotacao || -1,
    };

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.frequenciaIdUnidadeMedida = Number(formItem.frequenciaIdUnidadeMedida);
    formItem.frequenciaUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.frequenciaIdUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.minimoAceitavel = form.values.minimoAceitavel === 0 ? null : form.values.minimoAceitavel;
    formItem.minimoAceitavelIdUnidadeMedida = Number(form.values.minimoAceitavelIdUnidadeMedida) || null;

    formItem.preco = Number(formItem.preco);

    formItem.idFornecedor = Number(form.values.fornecedorData?.idEntidade);
    formItem.fornecedorCNPJ = form.values.fornecedorData?.cnpj || null;
    formItem.fornecedorRazaoSocial = form.values.fornecedorData?.razaoSocial || null;
    formItem.fornecedorNomeFantasia = form.values.fornecedorData?.nomeFantasia || null;
    formItem.fornecedorCPF = form.values.fornecedorData?.cpf || null;
    formItem.fornecedorNome = form.values.fornecedorData?.nome || null;

    formItem.idResiduoVeiculo = props.refItem?.idResiduoVeiculo || -1;
    formItem.residuoVeiculo = props.refItem?.residuoVeiculo || '';
    formItem.residuoVeiculoDescricao = props.refItem?.residuoVeiculoDescricao || null;

    formItem.observacao = formItem.observacao?.trim() || null;
    formItem.tolerancias = refToleranceForm?.current?.validate() || [];

    props.callback({ ...formItem, action: values.action, id: values.id }, props.refItem, 'callback', true);
  };

  const tabs = [
    {
      key: 'Cotacao',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotação
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntityItemSearch
                label="Fornecedor"
                referenceData={props.referenceData}
                item={EntityItemEnum.Vehicle}
                itemId={props.refItem?.idResiduoVeiculo || -1}
                formItem={form.getInputProps('fornecedor') || undefined}
                idsToBeDisabled={[]}
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('fornecedorData', item);
                    form.setFieldValue('idFornecedor', item.idEntidade);
                    form.setFieldValue(
                      'fornecedor',
                      item.nomeFantasia || item.razaoSocial || item.nome || ''
                    );
                    form.setFieldValue('idEntidadeResiduoVeiculo', (item as any).idEntidadeResiduoVeiculo);
                  }
                }}
                disabled={false}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={3}>
              <Stack>
                <div>
                  <Input.Wrapper label="Quantidade/Frequência" required>
                    <div />
                  </Input.Wrapper>
                  <Group spacing="xs">
                    <NumberInput
                      style={{ width: 125 }}
                      placeholder="Quantidade"
                      min={1}
                      defaultValue={1}
                      step={1}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      required
                      {...form.getInputProps('quantidade')}
                    />
                    <Select
                      icon={<Ruler2 size={15} />}
                      style={{ width: 200 }}
                      placeholder="Selecione..."
                      data={props.referenceData.unitOfMeasures
                        .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                        .map((x) => {
                          return {
                            value: x.idUnidadeMedida.toString(),
                            label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                            group: x.tipo,
                          };
                        })}
                      searchable
                      disabled
                      required
                      {...form.getInputProps('quantidadeIdUnidadeMedida')}
                    />
                    <Text>/</Text>
                    <Select
                      icon={<Ruler2 size={15} />}
                      placeholder="Selecione..."
                      data={props.referenceData.unitOfMeasures
                        .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'col')
                        .map((x) => {
                          return {
                            value: x.idUnidadeMedida.toString(),
                            label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                            group: x.tipo,
                          };
                        })}
                      searchable
                      required
                      {...form.getInputProps('frequenciaIdUnidadeMedida')}
                    />
                  </Group>
                </div>
                <div>
                  <Input.Wrapper label="Mínimo Aceitável">
                    <div />
                  </Input.Wrapper>
                  <Group spacing="xs">
                    <NumberInput
                      style={{ width: 125 }}
                      placeholder="Mínimo Aceitável"
                      min={0}
                      precision={2}
                      step={0.5}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      {...form.getInputProps('minimoAceitavel')}
                    />
                    <Select
                      icon={<Ruler2 size={15} />}
                      style={{ width: 200 }}
                      placeholder="Selecione..."
                      data={props.referenceData.unitOfMeasures
                        .filter(
                          (x) =>
                            x.tipo === 'Massa' ||
                            x.tipo === 'Volume' ||
                            (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                        )
                        .map((x) => {
                          return {
                            value: x.idUnidadeMedida.toString(),
                            label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                            group: x.tipo,
                          };
                        })}
                      clearable
                      searchable
                      required
                      {...form.getInputProps('minimoAceitavelIdUnidadeMedida')}
                    />
                    <Text>/</Text>
                    <Select
                      icon={<Ruler2 size={15} />}
                      placeholder="Selecione..."
                      data={props.referenceData.unitOfMeasures
                        .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'col')
                        .map((x) => {
                          return {
                            value: x.idUnidadeMedida.toString(),
                            label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                            group: x.tipo,
                          };
                        })}
                      value={props.referenceData.unitOfMeasures
                        .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'col')
                        ?.idUnidadeMedida.toString()}
                      disabled
                    />
                  </Group>
                </div>
              </Stack>
            </Grid.Col>
            <Grid.Col span={1} style={{ display: 'flex' }}>
              <Stack justify="center" align="center">
                <NumberInput
                  icon={<CurrencyReal size={15} />}
                  label="Preço"
                  placeholder="Preço"
                  min={0}
                  precision={2}
                  hideControls
                  required
                  {...form.getInputProps('preco')}
                />
              </Stack>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o a cotação do veiculo"
              autosize
              maxLength={500}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tolerancias',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Scale size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Tolerâncias
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ToleranceForm
            ref={refToleranceForm}
            refId={props.item?.idPropostaResiduoPlanoVeiculo}
            title="Tolerâncias"
            description="Tolerâncias para esta cotação."
            color={Feature.Home.Proposal.color}
            disabled={false}
            origData={props.origItem?.tolerancias || []}
            data={props.item?.tolerancias || []}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <form id="residue-plan-quotation-vehicle" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Cotação para:"
            text="Este é o veículo a ser cotado."
          />
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={2}>
              <PageViewProperty label="Veículo" text={props.refItem?.residuoVeiculo || '-'} size="sm" />
            </Grid.Col>

            <Grid.Col span={1}>
              <PageViewProperty label="Quantidade" text={builQuantityFromContext} size="sm" />
            </Grid.Col>

            <Grid.Col span={1}>
              <PageViewProperty label="Carga Média (Coleta)" text={builWeightFromContext} size="sm" />
            </Grid.Col>
          </Grid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <Tabs items={tabs} defaultActiveKey="Cotacao" />
        </Paper>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} type="submit" form="residue-plan-quotation-vehicle">
          Salvar
        </Button>
      </Group>
    </form>
  );
}
