import { call } from '../core-reference.service';
import {
  ResidueUpdateType,
  ResidueDeleteType,
  ResidueInsertType,
  ResidueSearchType,
  ResidueSelectType,
  ResidueType,
} from '../../models/core/residues.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const residuesEndpoint = '/v1/residuos';

export default class ResiduesService {
  static search = async (searchProps: ResidueSearchType): Promise<ResidueType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: residuesEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<ResidueType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: ResidueSelectType): Promise<ResidueType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${residuesEndpoint}/${selectProps.idResiduo}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: ResidueUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${residuesEndpoint}/${updateProps.idResiduo}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: ResidueDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${residuesEndpoint}/${deleteProps.idResiduo}`,
    };
    return call(request);
  };

  static insert = async (insertProps: ResidueInsertType): Promise<ResidueType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: residuesEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
