/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { Alert, Badge, Button, Group, Modal, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import lodash from 'lodash';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { AlertTriangle, InfoCircle, Plus } from 'tabler-icons-react';
import { TipoCodigo } from '../../../../../../../business/events/general';
import EventMeasurementSearch from '../../../../../../../components/core/EventMeasurementSearch/EventMeasurementSearch';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import {
  EventStatusType,
  MovementType,
  MovementTypeStandard,
  UnitOfMeasureType,
} from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import {
  EventBillingMeasurementType,
  EventMeasurementSearchResponseType,
  EventType,
} from '../../../../../../../models/core/events.type';
import { UserType } from '../../../../../../../models/core/users.type';
import theme from '../../../../../../../theme';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../../utils/formatter.utils';
import MovementFormView from '../../MovementFormView/MovementFormView';
import BillingMeasurementFormViewActions from './BillingMeasurementFormViewActions';
import { newGuid } from '../../../../../../../utils/helper.utils';

type BillingMeasurementFormViewProps = {
  referenceData: {
    eventStatusData: EventStatusType[];
    userData: UserType[];
    unitOfMeasures: UnitOfMeasureType[];
    movementTypeData: MovementType[];
  };
  event: EventType;
  data: EventBillingMeasurementType[];
  editable: boolean;
  dataInicial: Date;
  dataFinal: Date;
  callbackBillingMeasurement(items: EventBillingMeasurementType[]): void;
};

type ModalData = {
  opened: boolean;
  items: EventMeasurementSearchResponseType[];
  action: string;
};

const BillingMeasurementFormView = forwardRef((props: BillingMeasurementFormViewProps, ref) => {
  const [data, setData] = useState<EventBillingMeasurementType[]>(
    props.data.map((x: EventBillingMeasurementType) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
      };
    })
  );

  const [modalData, setModalData] = useState<ModalData>({
    opened: false,
    items: [],
    action: '',
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Evento',
      key: 'idEvento',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = a.idEvento;
        const bValue = b.idEvento;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventBillingMeasurementType) => {
        return (
          <ProfileCardLink
            id={row.idEvento.toString()}
            name={`# ${row.idEvento}`}
            nameSize="sm"
            avatar="E"
            description={`${row.eventoStatus} | ${row.eventoTipo}`}
            descriptionSize="xs"
            linkPrefix="events"
            showLink
          />
        );
      },
    },
    {
      title: 'Descrição',
      key: 'descricao',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = a.automatico
          ? a.descricao
          : a.resumoJSON?.residuo?.residuoCliente || a.resumoJSON?.servico?.servico || a.descricao;
        const bValue = b.automatico
          ? b.descricao
          : b.resumoJSON?.residuo?.residuoCliente || b.resumoJSON?.servico?.servico || b.descricao;
        return (aValue || '').localeCompare(bValue || '');
      },
      render: (row: EventBillingMeasurementType) => {
        let value = row.automatico
          ? row.descricao
          : `${row.resumoJSON?.residuo?.residuoCliente || row.resumoJSON?.servico?.servico || '-'} | ${
              row.descricao
            }`;
        if (value.startsWith('- |')) {
          value = value.replace('- |', '');
        }
        return <TableCellEllipsis label={value} numberOfChars={50} toolTipWidth={300} />;
      },
    },
    {
      title: 'Data',
      key: 'data',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) =>
        new Date(a.data).valueOf() - new Date(b.data).valueOf(),
      render: (row: EventBillingMeasurementType) => formatDateToString(row.data),
    },
    {
      title: 'Documentos',
      key: 'documentos',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = (a.resumoJSON?.documentos || []).length;
        const bValue = (b.resumoJSON?.documentos || []).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventBillingMeasurementType) => {
        const documentos =
          row.resumoJSON?.documentos?.filter(
            (x) =>
              x.documentoTipo === 'Nota Fiscal' ||
              x.documentoTipo === 'MTR' ||
              x.documentoTipo === 'Romaneio de Coleta'
          ) || [];
        if (documentos.length > 0) {
          return (
            <Group spacing="xs">
              {documentos.map((y) => {
                return (
                  <Badge
                    key={y.idEventoDocumento?.toString() || '-'}
                    variant="outline"
                  >{`${y.documentoTipo} - ${y.numeroDocumento}`}</Badge>
                );
              })}
            </Group>
          );
        }
        return '-';
      },
    },
    {
      title: 'Receita',
      key: 'receita',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = a.receita || 0;
        const bValue = b.receita || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventBillingMeasurementType) => <div>{formatCurrency(row.receita)}</div>,
    },
    {
      title: 'Despesa',
      key: 'despesa',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = a.despesa || 0;
        const bValue = b.despesa || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventBillingMeasurementType) => {
        if ((row.resumoJSON?.comissao?.porcentagem || 0) > 0) {
          return (
            <Group>
              <Tooltip
                withArrow
                transition="fade"
                transitionDuration={200}
                label={<div>Despesa variável.</div>}
              >
                <div style={{ marginTop: 5, marginRight: -15 }}>
                  <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                </div>
              </Tooltip>
              <div>{`${formatCurrency(row.despesa)}`}</div>
            </Group>
          );
        }

        return <div>{formatCurrency(row.despesa)}</div>;
      },
    },
    {
      title: 'Imposto',
      key: 'imposto',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = a.imposto || 0;
        const bValue = b.imposto || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventBillingMeasurementType) => <div>{formatCurrency(row.imposto)}</div>,
    },
    {
      title: 'Balanço',
      key: 'balanco',
      sorter: (a: EventBillingMeasurementType, b: EventBillingMeasurementType) => {
        const aValue = a.receita - a.despesa - a.imposto;
        const bValue = b.receita - b.despesa - b.imposto;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventBillingMeasurementType) => {
        const total = row.receita - row.despesa - row.imposto;
        const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

        return <div style={{ color }}>{formatCurrency(total)}</div>;
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EventBillingMeasurementType) => (
        <BillingMeasurementFormViewActions
          editable={props.editable}
          item={row}
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          confirmActionResult={confirmActionResult}
        />
      ),
    },
  ];

  const confirmActionResult = (
    items: EventMeasurementSearchResponseType[],
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex: number;
    let dataItem;
    let callbackData;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.idEventoMedicao === items[0]?.idEventoMedicao);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (props.data.find((x) => x.idEventoMedicao === items[0]?.idEventoMedicao)) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
        }
        callbackData = data;
        break;
      case 'adicionar':
        setModalData({ opened: true, items, action });
        break;
      case 'callback':
        setModalData({ opened: false, items: [], action: '' });
        if (items.length > 0) {
          for (const item of items) {
            (item as any).action = Action.Add;
          }
        }
        callbackData = data.concat(items as EventBillingMeasurementType[]);

        break;
      default:
        break;
    }

    if (callbackData) {
      const grandTotal = {
        receita: lodash.sumBy(
          callbackData.filter((x) => x.action !== Action.Delete),
          'receita'
        ),
      };

      // comissão percentual
      for (const eventoComissao of callbackData.filter(
        (x) => x.codigoEventoTipo === TipoCodigo.Comissao && (x.resumoJSON?.comissao?.porcentagem || 0) > 0
      )) {
        const porcentagem = eventoComissao.resumoJSON?.comissao?.porcentagem!;

        for (const comissaoPercentual of (eventoComissao?.resumoJSON?.medicao?.movimentacoes || []).filter(
          (y) => y.codigoMovimentacaoPadrao === MovementTypeStandard.ComissaoPercentual
        )) {
          if (eventoComissao.action === Action.Delete) {
            comissaoPercentual.despesa = 0;
            comissaoPercentual.balanco = 0;
          } else {
            comissaoPercentual.despesa = grandTotal.receita * (porcentagem / 100);
            comissaoPercentual.balanco = comissaoPercentual.despesa * -1;
          }
        }

        eventoComissao.id = newGuid();
        eventoComissao.despesa = lodash.sumBy(eventoComissao.resumoJSON?.medicao?.movimentacoes, 'despesa');
        eventoComissao.balanco = lodash.sumBy(eventoComissao.resumoJSON?.medicao?.movimentacoes, 'balanco');
      }

      callbackData = JSON.parse(JSON.stringify(callbackData));
      setData(callbackData);
      props.callbackBillingMeasurement(callbackData);
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EventBillingMeasurementType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  const hasOutOfPeriodData = () => {
    return (
      data.findIndex(
        (x) =>
          x.action !== Action.Delete &&
          (moment(x.data, 'yyyy-MM-DD').toDate() < props.dataInicial ||
            moment(x.data, 'yyyy-MM-DD').toDate() > props.dataFinal)
      ) > -1
    );
  };

  return (
    <div>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setModalData({ opened: false, items: [], action: '' })}
        title="Evento/Medições - Adicionar"
        size="80%"
      >
        <EventMeasurementSearch
          referenceData={props.referenceData}
          idsToBeDisabled={data.map((x) => x.idEvento)}
          idContrato={props.event.idContrato!}
          callback={(items: EventMeasurementSearchResponseType[]) => {
            confirmActionResult(items, 'callback', true);
            setModalData({ opened: false, items: [], action: '' });
          }}
          dataInicial={props.dataInicial}
          dataFinal={props.dataFinal}
        />
      </Modal>

      <Group position="apart">
        <PageSection
          size="md"
          color={Feature.Home.Event.color}
          label="Medições"
          text="Lista de medições de eventos elegíveis a serem faturados."
        />
        {props.editable && (
          <Group>
            <Button
              color="primary"
              leftIcon={<Plus size={18} />}
              onClick={() => {
                confirmActionResult([], 'adicionar', true);
              }}
            >
              Adicionar
            </Button>
          </Group>
        )}
      </Group>
      <Space h="xs" />

      {hasOutOfPeriodData() && (
        <>
          <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
            Existe ao menos uma medição fora da período selecionado.
          </Alert>
          <Space h="md" />
        </>
      )}

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EventBillingMeasurementType) => item.id || item.idEventoMedicao || 0}
        expandable={{
          rowExpandable: (row) => !!row.resumoJSON,
          expandedRowRender: (row) => (
            <MovementFormView
              data={{
                comissao: row.resumoJSON?.comissao || null,
                medicao: row.resumoJSON?.medicao || null,
                erros: [],
              }}
              editable={false}
              referenceData={props.referenceData}
              event={props.event}
            />
          ),
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default BillingMeasurementFormView;
