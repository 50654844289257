/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Paper, Space } from '@mantine/core';
import lodash from 'lodash';
import { ReactNode } from 'react';
import { Status, Tipo, TipoCodigo } from '../../../../../business/events/general';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import { EventSummaryType } from '../../../../../models/core/events.type';
import { Feature } from '../../../../../utils/constants.utils';
import Summary from '../../components/Summary';

type DataViewProps = {
  data: {
    idEvento: number;
    idContrato: number | null;
    idEntidade: number | null;
    idProposta: number | null;
    idEventoVeiculoCompartilhado: number | null;
    codigoEventoStatus: Status;
    codigoEventoTipo: TipoCodigo;
    data: Date;
    resumoJSON: EventSummaryType | null;
  }[];
};

export default function SummaryView(props: DataViewProps) {
  const getTipo = (codigoEventoTipo: TipoCodigo) => {
    switch (codigoEventoTipo) {
      case TipoCodigo.Cobranca:
        return Tipo.Cobranca;
      case TipoCodigo.Coleta:
        return Tipo.Coleta;
      case TipoCodigo.Comissao:
        return Tipo.Comissao;
      case TipoCodigo.Documetacao:
        return Tipo.Documetacao;
      case TipoCodigo.Expiracao:
        return Tipo.Expiracao;
      case TipoCodigo.Fechamento:
        return Tipo.Fechamento;
      case TipoCodigo.Lembrete:
        return Tipo.Lembrete;
      case TipoCodigo.MobilizacaoEnviar:
        return Tipo.MobilizacaoEnviar;
      case TipoCodigo.MobilizacaoRetirar:
        return Tipo.MobilizacaoRetirar;
      default:
        return '?';
    }
  };

  const buildGroupedData = () => {
    const nodes: ReactNode[] = [];
    const groupedData = lodash.groupBy(props.data, 'codigoEventoTipo');

    for (const key of Object.keys(groupedData)) {
      const node = (
        <>
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label={getTipo(key as TipoCodigo)}
            text=""
          />
          <Space h="xs" />
          <Summary data={groupedData[key]} />
        </>
      );
      nodes.push(node);
      nodes.push(<Space h="lg" />);
    }
    nodes.pop();

    return nodes;
  };

  return (
    <Paper shadow="xs" p="md" withBorder>
      {buildGroupedData()}
    </Paper>
  );
}
