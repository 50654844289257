import { Button, Card, Center, Loader } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, Edit, Trash } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { Permission } from '../../../../models/core/departments.type';
import { VehicleType } from '../../../../models/core/vehicles.type';
import vehiclesVehicle from '../../../../services/core/vehicles.service';
import { Feature } from '../../../../utils/constants.utils';
import DataView from './components/DataView';

type Result = {
  loading: boolean;
  data: VehicleType | null;
};

function VehicleView() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const modals = useModals();
  const { idResiduoVeiculo } = useParams();

  const [result, setResult] = useState<Result>({
    loading: location?.state?.vehicle === undefined,
    data: location?.state?.vehicle || null,
  });
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data: VehicleType | null = null;
      try {
        data = await vehiclesVehicle.select({
          idResiduoVeiculo: Number(idResiduoVeiculo),
        });
      } catch (error: any) {
        showNotification({
          title: 'Veículo - Visualizar',
          message: error?.isBusinessException ? error.description : `Não foi possível carregar o veículo.`,
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setResult({ loading: false, data });
      }
    };
    if (result.data === null) {
      fetchData();
    }
  }, [idResiduoVeiculo, navigate, result]);

  const handleAction = async () => {
    try {
      setResult({ loading: false, data: result.data });

      setDeleting(true);
      await vehiclesVehicle.delete({ idResiduoVeiculo: Number(idResiduoVeiculo) });
      navigate('/vehicles');

      showNotification({
        title: 'Veículo - Visualizar',
        message: `Veículo excluído com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Veículo - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível excluir o veículo.`,
        color: 'red',
      });
    } finally {
      setDeleting(false);
    }
  };

  const showModal = () =>
    modals.openConfirmModal({
      title: `Gostaria de excluir esse veículo?`,
      size: 'sm',
      closeOnClickOutside: false,
      closeOnEscape: false,
      onConfirm: () => {
        handleAction();
      },
    });

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Vehicle}
        title="Veículo - Visualizar"
        description="Visualize os detalhes de um veículo."
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={deleting || result.loading}
          >
            Voltar
          </Button>,
          <Button
            key="Excluir"
            color="red"
            leftIcon={<Trash size={18} />}
            onClick={() => {
              showModal();
            }}
            disabled={deleting || result.loading}
            loading={deleting}
            data-permission={Permission.VehicleDelete}
          >
            Excluir
          </Button>,
          <Button
            key="Editar"
            color="accent"
            leftIcon={<Edit size={18} />}
            onClick={() => {
              navigate(`/vehicles/${result?.data?.idResiduoVeiculo}/edit`, {
                state: {
                  vehicle: result.data,
                },
              });
            }}
            disabled={deleting || result.loading}
            data-permission={Permission.VehicleEdit}
          >
            Editar
          </Button>,
        ]}
      />
      {result.data === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <DataView data={result.data} />
        </PageContent>
      )}
    </Card>
  );
}

export default VehicleView;
