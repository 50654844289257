/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { Check, DeviceFloppy, Refresh, Share, X } from 'tabler-icons-react';
import { ProposalResidueType } from '../../../models/core/proposals.type';
import proposalsService from '../../../services/core/proposals.service';
import { montarRecorrenciaString } from '../../../utils/business.utils';
import { formatDateToString } from '../../../utils/formatter.utils';
import ResiduePlanQuotationSummary from '../../../views/core/proposals/components/ResiduePlanQuotationSummary';
import ProfileCardLink from '../ProfileCardLink/ProfileCardLink';

type FormViewProps = {
  referenceData: {
    idProposta: number;
  };
  idsToBeDisabled: number[];
  callback(item: ProposalResidueType | null, confirmed: boolean): void;
};

export default function ProposalResidueListModal(props: FormViewProps) {
  const [data, setData] = useState<ProposalResidueType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<ProposalResidueType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const filter = async (idProposta: number, cache: boolean) => {
    try {
      setLoading(true);
      const result = await proposalsService.listResidues({
        idProposta,
        cache,
      });
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Proposta - Resíduos`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível listar os resíduos dessa proposta.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProposalResidueType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ProposalResidueType) => ({
      disabled: props.idsToBeDisabled.includes(record.idProposta),
      name: record.idProposta.toString(),
    }),
  };

  useEffect(() => {
    filter(props.referenceData.idProposta, true);
  }, []);

  const isResiduePlanQuotationComplete = (row: ProposalResidueType) => {
    let isComplete = false;
    const primaryPlan = row.planos.find((x) => x.primario);
    if (primaryPlan) {
      isComplete = primaryPlan.cotacoes.some((x) => x.primario);
    }
    return { primaryPlan, isComplete };
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} />
      <Paper shadow="xs" p="md" withBorder>
        <Table
          showSorterTooltip={false}
          size="small"
          dataSource={data}
          columns={[
            {
              title: 'Resíduo',
              key: 'residuo',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
                a.residuoCliente.localeCompare(b.residuoCliente),
              render: (row: ProposalResidueType) => {
                return (
                  <ProfileCardLink
                    id={row.idResiduo.toString()}
                    name={row.residuoCliente}
                    nameSize="sm"
                    description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
                      row.residuoClientePerigoso ? 'Sim' : 'Não'
                    }`}
                    descriptionSize="xs"
                    linkPrefix="residues"
                    showLink
                  />
                );
              },
            },
            {
              title: 'Mínimo Aceitável',
              key: 'minimoAceitavel',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
                const aItem = a.minimoAceitavel
                  ? `${a.minimoAceitavel} ${a.minimoAceitavelUnidadeMedida1} / ${a.minimoAceitavelUnidadeMedida2}`
                  : '';
                const bItem = a.minimoAceitavel
                  ? `${b.minimoAceitavel} ${b.minimoAceitavelUnidadeMedida1} / ${b.minimoAceitavelUnidadeMedida2}`
                  : '';
                return aItem.localeCompare(bItem);
              },
              render: (row: ProposalResidueType) => {
                if (!row.minimoAceitavel) {
                  return '-';
                }
                return (
                  <div>{`${row.minimoAceitavel} ${row.minimoAceitavelUnidadeMedida1} / ${row.minimoAceitavelUnidadeMedida2}`}</div>
                );
              },
            },
            {
              title: 'Estimativa',
              key: 'estimativa',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
                const aItem = `${a.estimativa} ${a.estimativaUnidadeMedida1} / ${a.estimativaUnidadeMedida2}`;
                const bItem = `${b.estimativa} ${b.estimativaUnidadeMedida1} / ${b.estimativaUnidadeMedida2}`;
                return aItem.localeCompare(bItem);
              },
              render: (row: ProposalResidueType) => {
                return (
                  <div>{`${row.estimativa} ${row.estimativaUnidadeMedida1} / ${row.estimativaUnidadeMedida2}`}</div>
                );
              },
            },
            {
              title: 'Recorrência',
              key: 'recorrencia',
              render: (row: ProposalResidueType) => {
                const recorrencia = montarRecorrenciaString(row.recorrencia);
                if (!recorrencia) {
                  return '-';
                }
                return (
                  <div>
                    <div>{recorrencia.str1}</div>
                    {recorrencia.str2 ? <div>{recorrencia.str2}</div> : <></>}
                  </div>
                );
              },
            },
            {
              title: 'Data Inicial',
              key: 'dataInicial',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
                new Date(a.recorrencia.dataInicial || a.dataInicial).valueOf() -
                new Date(b.recorrencia.dataInicial || b.dataInicial).valueOf(),
              render: (row: ProposalResidueType) =>
                formatDateToString(row.recorrencia.dataInicial || row.dataInicial),
            },
            {
              title: 'Data Final',
              key: 'dataFinal',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
                new Date(a.recorrencia.dataFinal || a.dataFinal).valueOf() -
                new Date(b.recorrencia.dataFinal || b.dataFinal).valueOf(),
              render: (row: ProposalResidueType) =>
                formatDateToString(row.recorrencia.dataFinal || row.dataFinal),
            },
            {
              title: 'Planos',
              key: 'planos',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
                const aValue = a.planos.length;
                const bValue = b.planos.length;
                if (aValue === bValue) {
                  return 0;
                }
                return aValue > bValue ? 1 : -1;
              },
              render: (row: ProposalResidueType) => {
                const completePlan = isResiduePlanQuotationComplete(row);
                return (
                  <Group spacing="xs">
                    <div>{row.planos.length || '-'}</div>
                    <div>{completePlan.isComplete ? <Check color="green" /> : <X color="red" />}</div>
                    {completePlan?.primaryPlan?.acondicionamentos?.find((x) => x.compartilhado) && (
                      <div>
                        <Share size={16} />
                      </div>
                    )}
                  </Group>
                );
              },
            },
          ]}
          rowSelection={{ type: 'radio', ...rowSelection }}
          rowKey={(item: ProposalResidueType) => item.idPropostaResiduo || 0}
          expandable={{
            rowExpandable: (row) => isResiduePlanQuotationComplete(row).isComplete,
            // eslint-disable-next-line react/no-unstable-nested-components
            expandedRowRender: (row) => {
              return (
                <ResiduePlanQuotationSummary data={row.planos.find((x) => x.primario)!} quotation={null} />
              );
            },
          }}
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button
          color="accent"
          leftIcon={<Refresh size={18} />}
          onClick={() => {
            filter(props.referenceData.idProposta, false);
          }}
        >
          Atualizar
        </Button>
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
