/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Edit, Eye, FileImport, Trash } from 'tabler-icons-react';
import { Status, TipoCodigo } from '../../../../../business/events/general';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { EventSearchResponseType } from '../../../../../models/core/events.type';
import theme from '../../../../../theme';
import { validate } from '../../../../../utils/permission.utils';
import { Permission } from '../../../../../models/core/departments.type';

type DataViewActionProps = {
  item: EventSearchResponseType;
  confirmActionResult(
    items: EventSearchResponseType[],
    action: string,
    value: string | null,
    confirmed: boolean
  ): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const showModal = (item: EventSearchResponseType, action: string) => {
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse evento?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="#" text={item.idEvento.toString()} size="sm" />
            <PageViewProperty label="Audiência" text={item.eventoAudiencia} size="sm" />
            <PageViewProperty label="Tipo" text={item.eventoTipo} size="sm" />
            <PageViewProperty label="Status" text={item.eventoStatus} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult([item], action, null, false);
      },
      onConfirm: () => {
        props.confirmActionResult([item], action, null, true);
      },
    });
  };

  return (
    <Group>
      {validate(Permission.EventView, currentUser.permissoes) && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult([props.item], 'visualizar', null, true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {props.item.codigoEventoStatus !== Status.Cancelado &&
        props.item.codigoEventoStatus !== Status.Finalizado &&
        validate(Permission.EventEdit, currentUser.permissoes) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.accent?.[6] }}
              variant="outline"
              onClick={() => {
                props.confirmActionResult([props.item], 'editar', null, true);
              }}
            >
              <Edit size={15} color={theme?.colors?.accent?.[6]} />
            </ActionIcon>
          </Tooltip>
        )}
      {props.item.codigoEventoTipo !== TipoCodigo.Expiracao &&
        props.item.codigoEventoTipo !== TipoCodigo.Cobranca &&
        props.item.codigoEventoTipo !== TipoCodigo.Comissao &&
        !props.item.obsoleto &&
        validate(Permission.EventView, currentUser.permissoes) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Importar">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.gray?.[6] }}
              variant="outline"
              onClick={() => {
                props.confirmActionResult([props.item], 'importar', null, true);
              }}
            >
              <FileImport size={15} color={theme?.colors?.gray?.[6]} />
            </ActionIcon>
          </Tooltip>
        )}
      {!(
        props.item.codigoEventoStatus === Status.Finalizado &&
        (props.item.codigoEventoTipo === TipoCodigo.Cobranca ||
          props.item.codigoEventoTipo === TipoCodigo.Coleta ||
          props.item.codigoEventoTipo === TipoCodigo.Comissao ||
          props.item.codigoEventoTipo === TipoCodigo.Fechamento ||
          props.item.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
          props.item.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar)
      ) &&
        validate(Permission.EventDelete, currentUser.permissoes) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                showModal(props.item, 'excluir');
              }}
            >
              <Trash size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        )}
    </Group>
  );
}
