/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Grid,
  Group,
  Input,
  NumberInput,
  Select,
  SimpleGrid,
  Space,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Notes, Ruler2 } from 'tabler-icons-react';
import VehicleSearch from '../../../../../../../components/core/VehicleSearch/VehicleSearch';
import { useEventVehicleContext } from '../../../../../../../contexts/core/events/EventVehicle.context';
import { UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EventVehicleType } from '../../../../../../../models/core/events.type';
import { VehicleType } from '../../../../../../../models/core/vehicles.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoVeiculo: number | null;
  residuoVeiculo: string;
  residuoVeiculoDescricao: string | null;

  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;

  cargaMedia: number | null;
  cargaMediaIdUnidadeMedida: string | null;

  frota: string | null;
  placa: string | null;
  motorista: string | null;
  rota: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventVehicleType | null;
  item: EventVehicleType | null;
  disabled: boolean;
  statusSoftDisabled: boolean;
};

const EventVehicleFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const { setEventVehicleData } = useEventVehicleContext();
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoVeiculo: props.item?.idResiduoVeiculo || null,
      residuoVeiculo: props.item?.residuoVeiculo || '',
      residuoVeiculoDescricao: props.item?.residuoVeiculoDescricao || null,

      quantidade: props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida.toString() ||
        props.referenceData.unitOfMeasures
          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
          ?.idUnidadeMedida.toString() ||
        null,

      cargaMedia: props.item?.cargaMedia || 0,
      cargaMediaIdUnidadeMedida: props.item?.cargaMediaIdUnidadeMedida?.toString() || null,

      frota: props.item?.frota || '',
      placa: props.item?.placa || '',
      motorista: props.item?.motorista || '',
      rota: props.item?.rota || '',

      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoVeiculo: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      cargaMedia: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (value <= 0) {
          return 'Valor deve ser maior que zero';
        }
        return null;
      },
      cargaMediaIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoVeiculo || ''}
    |${Number(props.origItem?.quantidade) || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${Number(props.origItem?.cargaMedia) || ''}
    |${props.origItem?.cargaMediaIdUnidadeMedida || ''}
    |${props.origItem?.frota || ''}
    |${props.origItem?.placa || ''}
    |${props.origItem?.motorista || ''}
    |${props.origItem?.rota || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoVeiculo || ''}
    |${Number(form.values.quantidade) || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${Number(form.values.cargaMedia) || ''}
    |${form.values.cargaMediaIdUnidadeMedida || ''}
    |${form.values.frota || ''}
    |${form.values.placa || ''}
    |${form.values.motorista || ''}
    |${form.values.rota || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): EventVehicleType | null {
      if (form.validate().hasErrors) {
        return null;
      }

      let { values } = form;
      if (props.origItem) {
        if (isModified()) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
            quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || null,
            cargaMediaIdUnidadeMedida: form.values.cargaMediaIdUnidadeMedida?.toString() || null,
          };
        }
      }

      const formItem: EventVehicleType = {
        ...values,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idEventoVeiculo: props.item?.idEventoVeiculo || -1,
      };

      formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
      formItem.quantidadeUnidadeMedida =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
        )?.unidadeMedida || '';

      formItem.cargaMediaIdUnidadeMedida = Number(formItem.cargaMediaIdUnidadeMedida);
      formItem.cargaMediaUnidadeMedida =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === Number(form.values.cargaMediaIdUnidadeMedida)
        )?.unidadeMedida || '';

      formItem.frota = formItem.frota?.trim() || null;
      formItem.placa = formItem.placa?.trim() || null;
      formItem.motorista = formItem.motorista?.trim() || null;
      formItem.rota = formItem.rota?.trim() || null;

      formItem.observacao = formItem.observacao?.trim() || null;

      return formItem;
    },
    clear() {
      form.reset();
    },
  }));

  useEffect(() => {
    setEventVehicleData({
      idResiduoVeiculo: form.values.idResiduoVeiculo,
      quantidade: form.values.quantidade,
      quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida,
      cargaMedia: form.values.cargaMedia,
      cargaMediaIdUnidadeMedida: form.values.cargaMediaIdUnidadeMedida,
    });
  }, [form.values, setEventVehicleData]);

  return (
    <form id="event-vehicle" noValidate>
      <Grid columns={6}>
        <Grid.Col span={6}>
          <VehicleSearch
            label="Veículo"
            formItem={form.getInputProps('residuoVeiculo')}
            idsToBeDisabled={[]}
            callback={(item: VehicleType | null) => {
              if (item) {
                form.setFieldValue('idResiduoVeiculo', item.idResiduoVeiculo);
                form.setFieldValue('residuoVeiculo', item.residuoVeiculo);
                form.setFieldValue('residuoEquipamentoDescricao', item.descricao);
              }
            }}
            disabled={props.disabled}
          />
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <Grid columns={6}>
        <Grid.Col span={3}>
          <Input.Wrapper label="Quantidade" required>
            <div />
          </Input.Wrapper>
          <Group spacing="xs">
            <NumberInput
              style={{ width: 125 }}
              placeholder="Quantidade"
              min={1}
              defaultValue={1}
              step={1}
              stepHoldDelay={500}
              stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
              required
              {...form.getInputProps('quantidade')}
              disabled={props.disabled}
            />
            <Select
              icon={<Ruler2 size={15} />}
              placeholder="Selecione..."
              data={props.referenceData.unitOfMeasures
                .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                .map((x) => {
                  return {
                    value: x.idUnidadeMedida.toString(),
                    label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                    group: x.tipo,
                  };
                })}
              searchable
              disabled
              required
              {...form.getInputProps('quantidadeIdUnidadeMedida')}
            />
          </Group>
        </Grid.Col>
        <Grid.Col span={3}>
          <Input.Wrapper label="Carga Média (Coleta)" required>
            <div />
          </Input.Wrapper>
          <Group spacing="xs">
            <NumberInput
              style={{ width: 125 }}
              placeholder="Carga Média"
              min={0.01}
              defaultValue={10.01}
              precision={2}
              step={0.5}
              stepHoldDelay={500}
              stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
              required
              {...form.getInputProps('cargaMedia')}
              disabled={props.disabled}
            />
            <Select
              icon={<Ruler2 size={15} />}
              placeholder="Selecione..."
              data={props.referenceData.unitOfMeasures
                .filter(
                  (x) =>
                    x.tipo === 'Massa' ||
                    x.tipo === 'Volume' ||
                    (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                )
                .map((x) => {
                  return {
                    value: x.idUnidadeMedida.toString(),
                    label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                    group: x.tipo,
                  };
                })}
              searchable
              required
              {...form.getInputProps('cargaMediaIdUnidadeMedida')}
              disabled={props.disabled}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      <SimpleGrid cols={4}>
        <TextInput
          // icon={<Package size={15} />}
          label="Frota"
          placeholder="Frota"
          maxLength={50}
          disabled={props.statusSoftDisabled}
          {...form.getInputProps('frota')}
        />
        <TextInput
          // icon={<Package size={15} />}
          label="Placa"
          placeholder="Placa"
          maxLength={50}
          disabled={props.statusSoftDisabled}
          {...form.getInputProps('placa')}
        />
        <TextInput
          // icon={<Package size={15} />}
          label="Motorista"
          placeholder="Motorista"
          maxLength={50}
          disabled={props.statusSoftDisabled}
          {...form.getInputProps('motorista')}
        />
        <TextInput
          // icon={<Package size={15} />}
          label="Rota"
          placeholder="Rota"
          maxLength={50}
          disabled={props.statusSoftDisabled}
          {...form.getInputProps('rota')}
        />
      </SimpleGrid>
      <Space h="xs" />

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Observação"
          placeholder="Observação sobre o veículo"
          autosize
          maxLength={500}
          {...form.getInputProps('observacao')}
          disabled={props.disabled}
        />
      </SimpleGrid>
    </form>
  );
});

export default EventVehicleFormAddEdit;
