/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { Alert, Button, Grid, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CircleX, DeviceFloppy, Id, ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalResidueContext } from '../../../../../../../../../contexts/core/proposals/ProposalResidue.context';
import { useProposalResiduePlanContext } from '../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import {
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../../../../../../models/core/cache.type';
import {
  ProposalResiduePlanType,
  ProposalServiceType,
} from '../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../utils/constants.utils';
import ResiduePlanGeneralFormView from './components/ResiduePlanGeneralFormView';
import ResiduePlanQuotationFormView from './components/ResiduePlanQuotationFormView';

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
  };
  origItem: ProposalResiduePlanType | null;
  item: ProposalResiduePlanType | null;
  idPropostaResiduo: number | undefined;
  services: ProposalServiceType[];
  callback(item: ProposalResiduePlanType | null, action: string, confirmed: boolean): void;
};

export default function ResiduePlanFormAddEdit(props: FormViewProps) {
  const { proposalResidueData } = useProposalResidueContext();
  const { setProposalResiduePlanData } = useProposalResiduePlanContext();

  const refGeneralForm = useRef<any>();
  const refQuotationForm = useRef<any>();

  const [error, setError] = useState<any | null>(null);

  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <ResiduePlanGeneralFormView
          ref={refGeneralForm}
          idPropostaResiduo={props.idPropostaResiduo}
          item={props.item}
          origItem={props.origItem}
          referenceData={props.referenceData}
          idEntidadeEndereco={proposalResidueData.idEntidadeEndereco!}
          services={props.services}
          callback={props.callback}
        />
      ),
      forceRender: true,
    },
    {
      key: 'Cotacoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotações
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanQuotationFormView
            ref={refQuotationForm}
            referenceData={props.referenceData}
            proposalResiduePlan={props.item}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  const handleSubmit = () => {
    let step = 'Geral';
    try {
      const generalData = refGeneralForm.current.validate();

      step = 'Cotações';
      const cotacoesData = refQuotationForm?.current?.validate() || {};

      props.callback(
        {
          ...generalData,
          cotacoes: cotacoesData,
        },
        'callback',
        true
      );
    } catch (ex: any) {
      setError({
        title: step,
        message: ex.message,
      });
    }
  };

  useEffect(() => {
    if (props.item) {
      setProposalResiduePlanData({ ...props.item, servicos: props.services });
    }
  }, []);

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Plano para:"
          text="Este é o resíduo a ser planejado."
        />
        <Space h="xs" />

        <Grid columns={5}>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Resíduo"
              text={
                <ProfileCardLink
                  id={proposalResidueData.residuo?.idResiduo.toString() || ''}
                  name={proposalResidueData.residuoCliente}
                  nameSize="sm"
                  description={`Código IBAMA: ${
                    (proposalResidueData.residuo as any)?.residuoData?.codigoIBAMA || '-'
                  } | Perigoso: ${proposalResidueData.residuoClientePerigoso ? 'Sim' : 'Não'}`}
                  descriptionSize="xs"
                  showLink={false}
                />
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Estado Físico"
              text={
                props.referenceData.residueStateOfMatterType.find(
                  (x) => x.codigoResiduoEstadoFisico === proposalResidueData.codigoResiduoEstadoFisico
                )?.residuoEstadoFisico || '-'
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Mínimo Aceitável"
              text={
                !proposalResidueData.minimoAceitavel
                  ? '-'
                  : `${proposalResidueData.minimoAceitavel} ${
                      props.referenceData.unitOfMeasures.find(
                        (x) =>
                          x.idUnidadeMedida === Number(proposalResidueData.minimoAceitavelIdUnidadeMedida1)
                      )?.unidadeMedida || '-'
                    } / ${
                      props.referenceData.unitOfMeasures.find(
                        (x) =>
                          x.idUnidadeMedida === Number(proposalResidueData.minimoAceitavelIdUnidadeMedida2)
                      )?.unidadeMedida || '-'
                    }`
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Estimativa"
              text={`${proposalResidueData.estimativa} ${
                props.referenceData.unitOfMeasures.find(
                  (x) => x.idUnidadeMedida === Number(proposalResidueData.estimativaIdUnidadeMedida1)
                )?.unidadeMedida || '-'
              } / ${
                props.referenceData.unitOfMeasures.find(
                  (x) => x.idUnidadeMedida === Number(proposalResidueData.estimativaIdUnidadeMedida2)
                )?.unidadeMedida || '-'
              }`}
              size="sm"
            />
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        {error && (
          <>
            <Alert
              icon={<CircleX size={18} />}
              title={error.title}
              color="red"
              withCloseButton
              onClose={() => setError(null)}
            >
              {error.message}
            </Alert>
            <Space h="xl" />
          </>
        )}

        <Tabs items={tabs} defaultActiveKey="Geral" />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </Paper>
  );
}
