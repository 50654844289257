import { createContext, ReactNode, useContext, useState } from 'react';

type EventDestinationData = {
  idResiduoDestinoFinal: number | null;
  autorizacaoAmbiental: boolean;
};

interface EventDestinationContextProps {
  eventDestinationData: EventDestinationData;
  setEventDestinationData(eventDestinationContextData: EventDestinationData): void;
}
const EventDestinationContext = createContext<EventDestinationContextProps>(
  {} as EventDestinationContextProps
);

// eslint-disable-next-line react/function-component-definition
export const EventDestinationProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventDestinationData, setEventDestinationData] = useState<EventDestinationData>(
    {} as EventDestinationData
  );
  return (
    <EventDestinationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventDestinationData,
        setEventDestinationData,
      }}
    >
      {children}
    </EventDestinationContext.Provider>
  );
};

export type { EventDestinationData };

export function useEventDestinationContext() {
  return useContext(EventDestinationContext);
}
