import { createContext, ReactNode, useContext, useState } from 'react';

type EventVehicleData = {
  idResiduoVeiculo: number | null;
  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;
  cargaMedia: number | null;
  cargaMediaIdUnidadeMedida: string | null;
};

interface EventVehicleContextProps {
  eventVehicleData: EventVehicleData;
  setEventVehicleData(eventVehicleContextData: EventVehicleData): void;
}
const EventVehicleContext = createContext<EventVehicleContextProps>({} as EventVehicleContextProps);

// eslint-disable-next-line react/function-component-definition
export const EventVehicleProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventVehicleData, setEventVehicleData] = useState<EventVehicleData>({} as EventVehicleData);
  return (
    <EventVehicleContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventVehicleData,
        setEventVehicleData,
      }}
    >
      {children}
    </EventVehicleContext.Provider>
  );
};

export type { EventVehicleData };

export function useEventVehicleContext() {
  return useContext(EventVehicleContext);
}
