/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Paper, Space } from '@mantine/core';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { EventServiceType, EventType } from '../../../../../../../models/core/events.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import EventServiceItem from './EventServiceItem';
import EventServiceQuotation from './EventServiceQuotation';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type DataViewProps = {
  data: EventServiceType;
  event: EventType;
};

export default function EventServiceView(props: DataViewProps) {
  const cotacaoPrimaria = props.event.referencia?.contrato.servico?.cotacoes?.find((y) => y.primario);

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Home.Event.color} label="Serviço" text="Detalhes do serviço." />
        <Space h="xs" />
        <EventServiceItem data={props.data} />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Fornecedor"
            text="Detalhes do fornecedor."
          />
          <Group>
            {cotacaoPrimaria && (
              <Badge variant="outline" size="md" color="orange">
                {`Preço (Cliente): ${formatCurrency(
                  calcularPreco(
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.margem,
                    cotacaoPrimaria.precoFinal,
                    cotacaoPrimaria.imposto || 0,
                    props.event.referencia?.contrato?.residuo?.compra || false,
                    false
                  ).novoPrecoComMargem
                )} | ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.frequenciaUnidadeMedida1} / ${
                  cotacaoPrimaria.frequenciaUnidadeMedida2
                }`}
              </Badge>
            )}
          </Group>
        </Group>
        <Space h="xs" />
        <EventServiceQuotation data={props.data.cotacao} />
      </Paper>
    </div>
  );
}
