/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, Input, Modal, TextInput, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { Click, InfoCircle, Tool } from 'tabler-icons-react';
import { ProposalServiceType } from '../../../models/core/proposals.type';
import theme from '../../../theme';
import ProposalServiceListModal from './ProposalServiceListModal';

type FormViewProps = {
  label: string;
  tooltip: string | null;
  referenceData: {
    idProposta: number | null;
    servicos: ProposalServiceType[];
  };
  formItem: any;
  idPropostaServico: number | null;
  propostaServico: string | null;
  idsToBeDisabled: number[];
  disabled: boolean;
  callback(item: ProposalServiceType | null): void;
};

export default function ProposalServiceList(props: FormViewProps) {
  const [opened, setOpened] = useState(false);

  const confirmActionResult = (item: ProposalServiceType | null, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    setOpened(false);
    props.callback(item);
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Proposta - Serviços"
        size="70%"
      >
        <ProposalServiceListModal
          referenceData={props.referenceData}
          idsToBeDisabled={props.idsToBeDisabled}
          callback={confirmActionResult}
        />
      </Modal>

      <Input.Wrapper label={props.label} required>
        {props.tooltip && (
          <Group style={{ display: 'inline-block', marginLeft: 5, marginTop: -2, verticalAlign: 'bottom' }}>
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              multiline
              width={300}
              label={props.tooltip}
            >
              <div>
                <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
              </div>
            </Tooltip>
          </Group>
        )}
      </Input.Wrapper>

      <TextInput
        icon={<Tool size={15} />}
        rightSection={
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            disabled={props.disabled}
            onClick={() => {
              setOpened(true);
            }}
          >
            <Click size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        }
        onClick={() => {
          setOpened(true);
        }}
        // label={props.label}
        placeholder="Selecione..."
        {...props.formItem}
        readOnly
        required
        disabled={props.disabled}
      />
    </div>
  );
}
