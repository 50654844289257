/* eslint-disable react/require-default-props */
import React from 'react';
import { Navigate } from 'react-router-dom';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Permission } from '../../models/core/departments.type';
import { validate } from '../../utils/permission.utils';

interface ProtectedRouteProps {
  element: React.ReactElement;
  permission?: Permission;
  managerial?: boolean;
}

function ProtectedRoute({ element, permission, managerial }: ProtectedRouteProps) {
  const [currentUser] = useCurrentUser();

  if (permission && !validate(permission, currentUser.permissoes)) {
    return <Navigate to="/403" />;
  }

  if (!!managerial && !currentUser.gerencial) {
    return <Navigate to="/403" />;
  }

  return element;
}

export default ProtectedRoute;
