/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Grid, Group, Modal, Paper, Space, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Check, Eye, Id, X, ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import {
  ProposalResiduePlanType,
  ProposalResidueType,
  ProposalServiceType,
} from '../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../theme';
import { Feature } from '../../../../../../../utils/constants.utils';
import ResiduePlanGeneralView from './ResiduePlanView/ResiduePlanGeneralView';
import ResiduePlanQuotationView from './ResiduePlanView/ResiduePlanQuotationView';

type ViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  data: ProposalResidueType;
  services: ProposalServiceType[];
};

export default function ResiduePlanView(props: ViewProps) {
  const [planItem, setPlanItem] = useState<ProposalResiduePlanType>();
  const [opened, setOpened] = useState(false);

  const buildBadges = (list: string[]) => {
    if ((list || []).length === 0) {
      return '-';
    }
    return (
      <Group>
        {list.map((x) => (
          <Badge key={x} variant="outline" size="sm">
            {x}
          </Badge>
        ))}
      </Group>
    );
  };

  const columns: ColumnsType<ProposalResiduePlanType> = [
    {
      title: '#',
      key: 'idPropostaResiduoPlano',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) => {
        const aValue = a.idPropostaResiduoPlano;
        const bValue = b.idPropostaResiduoPlano;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanType) => <div>{row.idPropostaResiduoPlano || '-'}</div>,
    },
    {
      title: 'Acondicionamentos',
      key: 'acondicionamentos',
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.acondicionamentos?.map((x) => x.residuoAcondicionamento)),
    },
    {
      title: 'Equipamentos',
      key: 'equipamentos',
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.equipamentos?.map((x) => x.residuoEquipamento)),
    },
    {
      title: 'Veiculo',
      key: 'residuoVeiculo',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        (a.veiculo?.residuoVeiculo || '').localeCompare(b.veiculo?.residuoVeiculo || ''),
      render: (row: ProposalResiduePlanType) => buildBadges(row.veiculo ? [row.veiculo?.residuoVeiculo] : []),
    },
    {
      title: 'Tratamento',
      key: 'tratamento',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        (a?.tratamento?.residuoTratamento || '').localeCompare(b?.tratamento?.residuoTratamento || ''),
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.tratamento ? [row.tratamento?.residuoTratamento] : []),
    },
    {
      title: 'Destino Final',
      key: 'destinoFinal',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        (a?.destinoFinal?.residuoDestinoFinal || '').localeCompare(
          b?.destinoFinal?.residuoDestinoFinal || ''
        ),
      render: (row: ProposalResiduePlanType) =>
        buildBadges(row.destinoFinal ? [row.destinoFinal?.residuoDestinoFinal] : []),
    },
    {
      title: 'Primário?',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Cotações',
      key: 'cotacoes',
      sorter: (a: ProposalResiduePlanType, b: ProposalResiduePlanType) => {
        const aValue = a.cotacoes.length;
        const bValue = b.cotacoes.length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanType) => <div>{row.cotacoes.length}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setPlanItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: <ResiduePlanGeneralView data={planItem!} services={props.services} />,
      forceRender: true,
    },
    {
      key: 'Cotacoes',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <ZoomMoney size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Cotações
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanQuotationView
            data={planItem!}
            residueData={props.data}
            referenceData={props.referenceData}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Resíduo/Plano - Visualizar"
        size="70%"
      >
        <Paper shadow="xs" p="md" withBorder>
          <Paper shadow="xs" p="md" withBorder>
            <PageSection
              size="lg"
              color={Feature.Home.Proposal.color}
              label="Plano para:"
              text="Este é o resíduo a ser planejado."
            />
            <Space h="xs" />

            <Grid columns={5}>
              <Grid.Col span={2}>
                <PageViewProperty
                  label="Resíduo"
                  text={
                    <ProfileCardLink
                      id={props.data.idResiduo.toString() || ''}
                      name={props.data.residuoCliente}
                      nameSize="sm"
                      description={`Código IBAMA: ${props.data.codigoIBAMA || '-'} | Perigoso: ${
                        props.data.residuoClientePerigoso ? 'Sim' : 'Não'
                      }`}
                      descriptionSize="xs"
                      linkPrefix="residues"
                      showLink
                    />
                  }
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <PageViewProperty label="Estado Físico" text={props.data.residuoEstadoFisico} size="sm" />
              </Grid.Col>
              <Grid.Col span={1}>
                <PageViewProperty
                  label="Mínimo Aceitável"
                  text={
                    !props.data.minimoAceitavel
                      ? '-'
                      : `${props.data.minimoAceitavelUnidadeMedida1} / ${props.data.minimoAceitavelUnidadeMedida2}`
                  }
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <PageViewProperty
                  label="Estimativa"
                  text={`${props.data.estimativa} ${props.data.estimativaUnidadeMedida1} / ${props.data.estimativaUnidadeMedida2}`}
                  size="sm"
                />
              </Grid.Col>
            </Grid>
          </Paper>
          <Space h="lg" />

          <Paper shadow="xs" p="md" withBorder>
            <Tabs items={tabs} defaultActiveKey="Geral" />
          </Paper>
        </Paper>
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Planos"
        text="Planos para este resíduo."
      />
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data.planos}
        columns={columns}
        rowKey={(item: ProposalResiduePlanType) => item.idPropostaResiduoPlano || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
