/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { DatePicker } from '@mantine/dates';
import { BrightnessHalf, Calendar, Clock, InfoCircle, Notes, Ruler2 } from 'tabler-icons-react';
import EntityAddressList from '../../../../../../../../components/core/EntityAddressList/EntityAddressList';
import EntitySearch from '../../../../../../../../components/core/EntitySearch/EntitySearch';
import EquipmentSearch from '../../../../../../../../components/core/EquipmentSearch/EquipmentSearch';
import PackagingSearch from '../../../../../../../../components/core/PackagingSearch/PackagingSearch';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import ServiceSearch from '../../../../../../../../components/core/ServiceSearch/ServiceSearch';
import { ProposalGeneralData } from '../../../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { useProposalServiceContext } from '../../../../../../../../contexts/core/proposals/ProposalService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../models/core/core.type';
import {
  EntityAddressType,
  EntitySearchResponseType,
} from '../../../../../../../../models/core/entities.type';
import { EquipmentType } from '../../../../../../../../models/core/equipment.type';
import { PackagingType } from '../../../../../../../../models/core/packaging.type';
import { ProposalServiceType } from '../../../../../../../../models/core/proposals.type';
import { ServiceType } from '../../../../../../../../models/core/services.type';
import theme from '../../../../../../../../theme';
import { EntityItemEnum, Feature } from '../../../../../../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../../../../../../utils/formatter.utils';
import {
  buildFakeAuditObject,
  dateDiffInDaysDisregardTime,
  newGuid,
  tryGetDateISOFormat,
} from '../../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;
  idServico: number | null;
  servico: string;
  tipo: string;

  quantidade: number | null;
  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string;
  idResiduoEquipamento: number | null;
  residuoEquipamento: string;

  dataInicial: Date;
  dataFinal: Date;
  frequencia: number | null;
  frequenciaIdUnidadeMedida1: string | null;
  frequenciaIdUnidadeMedida2: string | null;
  cobrarTolerancia: boolean;
  compartilhado: boolean;
  gerenciar: boolean;

  turno: string | null;
  jornada: string | null;

  clienteData: EntitySearchResponseType | null;
  idCliente: number | null;
  cliente: string;
  enderecoData: EntityAddressType | null;
  idEntidadeEndereco: number | null;
  endereco: string;

  observacao: string | null;
};

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
  };
  proposalGeneralData: ProposalGeneralData;
  origItem: ProposalServiceType | null;
  // item: (ProposalServiceType & { origContacts?: ProposalServiceContactType[] }) | null;
  item: ProposalServiceType | null;
  idProposta: number | undefined;

  callback(item: ProposalServiceType | null, action: string, confirmed: boolean): void;
  disabled: boolean;
};

const ServiceGeneralFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalServiceData, setProposalServiceData } = useProposalServiceContext();

  const buildClienteDataFromItem = (item: ProposalServiceType | null): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idCliente),
      tipos: [],
      cnpj: item.clienteCNPJ || null,
      razaoSocial: item.clienteRazaoSocial || null,
      nomeFantasia: item.clienteNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.clienteCPF || null,
      nome: item.clienteNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const buildEnderecoDataFromItem = (item: ProposalServiceType | null): EntityAddressType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidadeEndereco: item.idEntidadeEndereco,
      idEntidade: item.idCliente,
      codigoEnderecoTipo: '',
      enderecoTipo: '',
      codigoEstado: item.codigoEstado,
      estado: '',
      cidade: item.cidade,
      cidadeCodigoIBGE: item.cidadeCodigoIBGE || null,
      bairro: item.bairro,
      logradouro: item.logradouro,
      numero: item.numero,
      complemento: item.complemento || null,
      referencia: item.referencia || null,
      cep: item.cep,
      latitude: item.latitude || null,
      longitude: item.longitude || null,
      contatos: [],
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idServico: props.item?.idServico || null,
      servico: props.item?.servico || '',
      tipo: props.item?.idResiduoAcondicionamento
        ? EntityItemEnum.Packaging
        : props.item?.idResiduoEquipamento
        ? EntityItemEnum.Equipment
        : EntityItemEnum.Service,

      quantidade: props.item?.quantidade || 1,
      idResiduoAcondicionamento: props.item?.idResiduoAcondicionamento || null,
      residuoAcondicionamento: props.item?.residuoAcondicionamento || '',
      idResiduoEquipamento: props.item?.idResiduoEquipamento || null,
      residuoEquipamento: props.item?.residuoEquipamento || '',

      dataInicial: props.item
        ? formatDateStringToDate(tryGetDateISOFormat(props.item.dataInicial))
        : props.proposalGeneralData.dataInicial,

      dataFinal: props.item
        ? formatDateStringToDate(tryGetDateISOFormat(props.item.dataFinal))
        : props.proposalGeneralData.dataFinal,

      frequencia: props.item?.frequencia || 1,
      frequenciaIdUnidadeMedida1: props.item?.frequenciaIdUnidadeMedida1?.toString() || null,
      frequenciaIdUnidadeMedida2: props.item?.frequenciaIdUnidadeMedida2?.toString() || null,
      cobrarTolerancia: props.item ? props.item.cobrarTolerancia : false,
      compartilhado: props.item ? props.item.compartilhado : false,
      gerenciar: props.item ? props.item.gerenciar : true,

      turno: props.item?.turno?.toString() || '',
      jornada: props.item?.jornada || '',

      clienteData: buildClienteDataFromItem(props.item) || props.proposalGeneralData.cliente,
      idCliente: props.item?.idCliente || props.proposalGeneralData.cliente?.idEntidade || null,
      cliente:
        props.item?.clienteNomeFantasia ||
        props.item?.clienteRazaoSocial ||
        props.item?.clienteNome ||
        props.proposalGeneralData.cliente?.nomeFantasia ||
        props.proposalGeneralData.cliente?.razaoSocial ||
        props.proposalGeneralData.cliente?.nome ||
        '',

      enderecoData: buildEnderecoDataFromItem(props.item),
      idEntidadeEndereco: props.item?.idEntidadeEndereco || null,
      endereco: props.item?.idEntidadeEndereco
        ? `${props.item.logradouro}, ${props.item.numero} | ${props.item.bairro} - ${props.item.cidade}/${props.item.codigoEstado}`
        : '',

      observacao: props.item?.observacao || '',
      // contatos: [],
    },
    validate: {
      servico: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      quantidade: (value: number | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      residuoAcondicionamento: (value, values: FormViewData) => {
        if (value.trim() === '' && values.tipo === EntityItemEnum.Packaging) {
          return 'Campo obrigatório';
        }
        return null;
      },
      residuoEquipamento: (value, values: FormViewData) => {
        if (value.trim() === '' && values.tipo === EntityItemEnum.Equipment) {
          return 'Campo obrigatório';
        }
        return null;
      },
      cliente: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      endereco: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      dataInicial: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataInicial, true) < 0 ||
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataFinal, true) > 0
        ) {
          return `Data fora do limite (${formatDateToString(
            props.proposalGeneralData.dataInicial
          )} - ${formatDateToString(props.proposalGeneralData.dataFinal)})`;
        }
        return null;
      },
      dataFinal: (value, values: FormViewData) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (value < values.dataInicial) {
          return 'Data final não pode ser anterior à data inicial';
        }
        if (
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataInicial, true) < 0 ||
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataFinal, true) > 0
        ) {
          return `Data fora do limite (${formatDateToString(
            props.proposalGeneralData.dataInicial
          )} - ${formatDateToString(props.proposalGeneralData.dataFinal)})`;
        }
        return null;
      },
      frequencia: (value: number | null, values: FormViewData) => {
        if (!value && values.tipo === EntityItemEnum.Service) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida1: (value: string | null, values: FormViewData) => {
        if (!value && values.tipo === EntityItemEnum.Service) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida2: (value: string | null, values: FormViewData) => {
        if (!value && values.tipo === EntityItemEnum.Service) {
          return 'Campo obrigatório';
        }
        return null;
      },
      // jornada: (value: string | null, values: FormViewData) => {
      //   if (
      //     !value ||
      //     /^(((Seg|Ter|Qua|Qui|Sex|Sab|Dom)-(Seg|Ter|Qua|Qui|Sex|Sab|Dom) \d{2}:\d{2}-\d{2}:\d{2}),?){1,}$/.test(
      //       value || ''
      //     ) ||
      //     values.tipo !== EntityItemEnum.Service
      //   ) {
      //     return null;
      //   }
      //   return 'Formato inválido (Ex: Seg-Sex 08:00-17:00,Sab-Sab 08:00-12:00)';
      // },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idServico || ''}
    |${props.origItem?.quantidade || ''}
    |${props.origItem?.idResiduoAcondicionamento || ''}
    |${props.origItem?.idResiduoEquipamento || ''}
    |${formatDateToString(props.origItem?.dataInicial || new Date()) || ''}
    |${formatDateToString(props.origItem?.dataFinal || new Date()) || ''}
    |${Number(props.origItem?.frequencia) || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida1 || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida2 || ''}
    |${props.origItem?.cobrarTolerancia || ''}
    |${props.origItem?.compartilhado || ''}
    |${props.origItem?.gerenciar || ''}
    |${Number(props.origItem?.turno) || ''}
    |${props.origItem?.jornada || ''}
    |${props.origItem?.idCliente || ''}
    |${props.origItem?.idEntidadeEndereco || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idServico || ''}
    |${form.values.quantidade || ''}
    |${form.values.idResiduoAcondicionamento || ''}
    |${form.values.idResiduoEquipamento || ''}
    |${formatDateToString(form.values.dataInicial) || ''}
    |${formatDateToString(form.values.dataFinal) || ''}
    |${Number(form.values.frequencia) || ''}
    |${form.values.frequenciaIdUnidadeMedida1 || ''}
    |${form.values.frequenciaIdUnidadeMedida2 || ''}
    |${form.values.cobrarTolerancia || ''}
    |${form.values.compartilhado || ''}
    |${form.values.gerenciar || ''}
    |${Number(form.values.turno) || ''}
    |${form.values.jornada || ''}
    |${form.values.idCliente || ''}
    |${form.values.idEntidadeEndereco || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalServiceType | null {
      if (form.validate().hasErrors) {
        throw Error('Existem pendências a serem corrigidas.');
      }
      let formValues = form.values;

      if (props.origItem) {
        if (isModified()) {
          formValues.action = Action.Modify;
        } else {
          formValues = {
            ...formValues,
            ...props.origItem,
            action: Action.Nothing,
            residuoAcondicionamento: form.values.residuoAcondicionamento || '',
            residuoEquipamento: form.values.residuoEquipamento || '',
            frequenciaIdUnidadeMedida1: form.values.frequenciaIdUnidadeMedida1?.toString() || '',
            frequenciaIdUnidadeMedida2: form.values.frequenciaIdUnidadeMedida2?.toString() || '',
          };
        }
      }

      const formItem: ProposalServiceType = {
        ...formValues,
        ...buildFakeAuditObject(),

        idServico: Number(formValues.idServico),
        servico: formValues.servico,
        quantidade: Number(formValues.quantidade),

        idCliente: Number(formValues.idCliente),
        clienteCNPJ: formValues.clienteData?.cnpj,
        clienteRazaoSocial: formValues.clienteData?.razaoSocial,
        clienteNomeFantasia: formValues.clienteData?.nomeFantasia,
        clienteCPF: formValues.clienteData?.cpf,
        clienteNome: formValues.clienteData?.nome,

        idEntidadeEndereco: Number(formValues.idEntidadeEndereco),
        codigoEstado: formValues.enderecoData?.codigoEstado,
        cidade: formValues.enderecoData?.cidade,
        cidadeCodigoIBGE: formValues.enderecoData?.cidadeCodigoIBGE,
        bairro: formValues.enderecoData?.bairro,
        logradouro: formValues.enderecoData?.logradouro,
        numero: formValues.enderecoData?.numero,
        complemento: formValues.enderecoData?.complemento,
        referencia: formValues.enderecoData?.referencia,
        cep: formValues.enderecoData?.cep,
        latitude: formValues.enderecoData?.latitude,
        longitude: formValues.enderecoData?.longitude,

        frequenciaIdUnidadeMedida1: Number(formValues.frequenciaIdUnidadeMedida1),
        frequenciaIdUnidadeMedida2: Number(formValues.frequenciaIdUnidadeMedida2),

        idProposta: props.idProposta || -1,
      };

      if (formValues.tipo === EntityItemEnum.Packaging) {
        formItem.idResiduoEquipamento = null;
        formItem.residuoEquipamento = null;
        formItem.residuoEquipamentoDescricao = null;
      } else if (formValues.tipo === EntityItemEnum.Equipment) {
        formItem.idResiduoAcondicionamento = null;
        formItem.residuoAcondicionamento = null;
        formItem.residuoAcondicionamentoDescricao = null;
        formItem.compartilhado = false;
        formItem.gerenciar = true;
      } else {
        formItem.idResiduoEquipamento = null;
        formItem.residuoEquipamento = null;
        formItem.residuoEquipamentoDescricao = null;
        formItem.idResiduoAcondicionamento = null;
        formItem.residuoAcondicionamento = null;
        formItem.residuoAcondicionamentoDescricao = null;
        formItem.compartilhado = false;
        formItem.gerenciar = true;
      }

      if (formValues.tipo !== EntityItemEnum.Service) {
        formItem.frequencia = 1;
        formItem.frequenciaIdUnidadeMedida1 = Number(
          props.referenceData.unitOfMeasures.find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
            ?.idUnidadeMedida || null
        );
        formItem.frequenciaIdUnidadeMedida2 = Number(
          props.referenceData.unitOfMeasures.find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
            ?.idUnidadeMedida || null
        );
        formItem.turno = null;
        formItem.jornada = null;
      }

      formItem.frequenciaUnidadeMedida1 = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === formItem.frequenciaIdUnidadeMedida1
      )?.unidadeMedida;

      formItem.frequenciaUnidadeMedida2 = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === formItem.frequenciaIdUnidadeMedida2
      )?.unidadeMedida;

      formItem.dataInicial = formValues.dataInicial;
      formItem.dataFinal = formValues.dataFinal;

      formItem.jornada = formItem.jornada?.trim() || null;
      formItem.observacao = formItem.observacao?.trim() || null;

      return formItem;
    },
    getFormItemValue(item: keyof FormViewData) {
      return form.getInputProps(item).value;
    },
    clear() {
      form.reset();
      form.setFieldValue('idCliente', -1);
      form.setFieldValue('cliente', '');
      form.setFieldValue('idEntidadeEndereco', -1);
      form.setFieldValue('endereco', '');
    },
  }));

  useEffect(() => {
    setProposalServiceData({
      tipo: form.values.tipo as EntityItemEnum,
      servico: form.values.idServico ? { ...form.values, ...buildFakeAuditObject(), descricao: null } : null,
      quantidade: form.values.quantidade,
      acondicionamento: form.values.idResiduoAcondicionamento
        ? { ...form.values, ...buildFakeAuditObject(), descricao: null }
        : null,
      equipamento: form.values.idResiduoEquipamento
        ? { ...form.values, ...buildFakeAuditObject(), descricao: null }
        : null,

      dataInicial: form.values.dataInicial,
      dataFinal: form.values.dataFinal,
      frequencia: form.values.frequencia,
      frequenciaIdUnidadeMedida1: Number(form.values.frequenciaIdUnidadeMedida1),
      frequenciaIdUnidadeMedida2: Number(form.values.frequenciaIdUnidadeMedida2),
    });
  }, [form.values, setProposalServiceData]);

  return (
    <div>
      <form id="proposal-service" noValidate>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Geral"
            text="Informações gerais do serviço como referências, datas, local, etc."
          />
          <Space h="xs" />

          <Grid columns={6}>
            <Grid.Col span={2}>
              <ServiceSearch
                label="Serviço"
                formItem={form.getInputProps('servico')}
                idsToBeDisabled={[]}
                callback={(item: ServiceType | null) => {
                  if (item) {
                    form.setFieldValue('idServico', item.idServico);
                    form.setFieldValue('servico', item.servico);
                    setProposalServiceData({ ...proposalServiceData, servico: item });
                  }
                }}
                disabled={props.disabled}
              />
            </Grid.Col>

            <Grid.Col span={1}>
              <NumberInput
                label="Quantidade"
                placeholder="Quantidade"
                min={1}
                defaultValue={1}
                step={1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                // disabled={props.disabled}
                {...form.getInputProps('quantidade')}
              />
            </Grid.Col>

            <Grid.Col span={1}>
              <div>
                <Input.Wrapper label="Referência" required>
                  <Group
                    style={{ display: 'inline-block', marginLeft: 5, marginTop: -2, verticalAlign: 'bottom' }}
                  >
                    <Tooltip
                      withArrow
                      transition="fade"
                      transitionDuration={200}
                      label="Determina o tipo do item a ser associado com este serviço."
                    >
                      <div>
                        <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                      </div>
                    </Tooltip>
                  </Group>
                </Input.Wrapper>
                <Select
                  placeholder="Selecione..."
                  data={[
                    { label: 'Fornecedor', value: EntityItemEnum.Service },
                    { label: 'Acondicionamento', value: EntityItemEnum.Packaging },
                    { label: 'Equipamento', value: EntityItemEnum.Equipment },
                  ]}
                  // {...form.getInputProps('tipo')}
                  value={form.values.tipo}
                  onChange={(tipo: string) => {
                    form.setFieldValue('tipo', tipo);

                    if (tipo === EntityItemEnum.Packaging || tipo === EntityItemEnum.Equipment) {
                      form.setFieldValue('frequencia', 1);
                      form.setFieldValue(
                        'frequenciaIdUnidadeMedida1',
                        props.referenceData.unitOfMeasures
                          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                          ?.idUnidadeMedida?.toString() || null
                      );
                      form.setFieldValue(
                        'frequenciaIdUnidadeMedida2',
                        props.referenceData.unitOfMeasures
                          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                          ?.idUnidadeMedida?.toString() || null
                      );
                      form.setFieldValue('turno', null);
                      form.setFieldValue('jornada', null);
                    } else {
                      form.setFieldValue('frequencia', 1);
                      form.setFieldValue('frequenciaIdUnidadeMedida1', null);
                      form.setFieldValue('frequenciaIdUnidadeMedida2', null);
                    }

                    if (tipo === EntityItemEnum.Packaging) {
                      form.setFieldValue('idResiduoEquipamento', null);
                      form.setFieldValue('residuoEquipamento', '');
                    } else if (tipo === EntityItemEnum.Equipment) {
                      form.setFieldValue('idResiduoAcondicionamento', null);
                      form.setFieldValue('residuoAcondicionamento', '');
                    } else {
                      form.setFieldValue('idResiduoEquipamento', null);
                      form.setFieldValue('residuoEquipamento', '');
                      form.setFieldValue('idResiduoAcondicionamento', null);
                      form.setFieldValue('residuoAcondicionamento', '');
                    }
                  }}
                  disabled={props.disabled}
                  required
                />
              </div>
            </Grid.Col>

            {form.values.tipo === EntityItemEnum.Packaging ? (
              <>
                <Grid.Col span={1}>
                  <PackagingSearch
                    label="Acondicionamento"
                    formItem={form.getInputProps('residuoAcondicionamento')}
                    idsToBeDisabled={[]}
                    callback={(item: PackagingType | null) => {
                      if (item) {
                        form.setFieldValue('idResiduoAcondicionamento', item.idResiduoAcondicionamento);
                        form.setFieldValue('residuoAcondicionamento', item.residuoAcondicionamento);
                        setProposalServiceData({ ...proposalServiceData, acondicionamento: item });
                      }
                    }}
                    disabled={props.disabled}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <Stack spacing="xs">
                    <Switch
                      label="Gerenciar?"
                      disabled={props.disabled}
                      {...form.getInputProps('gerenciar', { type: 'checkbox' })}
                    />
                    <Switch
                      label="Compartilhado?"
                      disabled={props.disabled}
                      {...form.getInputProps('compartilhado', { type: 'checkbox' })}
                    />
                  </Stack>
                </Grid.Col>
              </>
            ) : form.values.tipo === EntityItemEnum.Equipment ? (
              <Grid.Col span={2}>
                <EquipmentSearch
                  label="Equipamento"
                  formItem={form.getInputProps('residuoEquipamento')}
                  idsToBeDisabled={[]}
                  callback={(item: EquipmentType | null) => {
                    if (item) {
                      form.setFieldValue('idResiduoEquipamento', item.idResiduoEquipamento);
                      form.setFieldValue('residuoEquipamento', item.residuoEquipamento);
                      setProposalServiceData({ ...proposalServiceData, equipamento: item });
                    }
                  }}
                  disabled={props.disabled}
                />
              </Grid.Col>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </Grid>
          <Space h="xs" />

          <Grid columns={6}>
            <Grid.Col span={1}>
              <Input.Wrapper label="Data Inicial" required>
                <Group
                  style={{
                    display: 'inline-block',
                    marginLeft: 5,
                    marginTop: -2,
                    verticalAlign: 'bottom',
                  }}
                >
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Data prevista de início do serviço"
                  >
                    <div>
                      <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                    </div>
                  </Tooltip>
                </Group>
              </Input.Wrapper>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={proposalServiceData.dataInicial}
                maxDate={proposalServiceData.dataFinal}
                required
                // disabled={props.disabled}
                {...form.getInputProps('dataInicial')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Input.Wrapper label="Data Final" required>
                <Group
                  style={{
                    display: 'inline-block',
                    marginLeft: 5,
                    marginTop: -2,
                    verticalAlign: 'bottom',
                  }}
                >
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Data prevista de fim do serviço"
                  >
                    <div>
                      <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                    </div>
                  </Tooltip>
                </Group>
              </Input.Wrapper>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={form.values.dataInicial}
                maxDate={proposalServiceData.dataFinal}
                required
                // disabled={props.disabled}
                {...form.getInputProps('dataFinal')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Input.Wrapper label="Frequência" required>
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                {form.values.tipo === EntityItemEnum.Service && (
                  <NumberInput
                    style={{ width: 100 }}
                    placeholder="Frequência"
                    min={0.5}
                    defaultValue={1}
                    precision={2}
                    step={0.5}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    required
                    disabled={form.values.tipo !== EntityItemEnum.Service}
                    {...form.getInputProps('frequencia')}
                  />
                )}
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'h') ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla !== 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  disabled={form.values.tipo !== EntityItemEnum.Service}
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida1')}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  disabled={form.values.tipo !== EntityItemEnum.Service}
                  required
                  {...form.getInputProps('frequenciaIdUnidadeMedida2')}
                />
              </Group>
            </Grid.Col>
            <Grid.Col span={1}>
              <div style={{ marginTop: 30 }}>
                <Switch
                  label="Cobrar Tolerâncias?"
                  // disabled={props.disabled}
                  {...form.getInputProps('cobrarTolerancia', { type: 'checkbox' })}
                />
              </div>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          {form.values.tipo === EntityItemEnum.Service && (
            <div>
              <Grid columns={3}>
                <Grid.Col span={1}>
                  <Select
                    icon={<BrightnessHalf size={15} />}
                    label="Turno"
                    placeholder="Selecione..."
                    data={['1º Turno', '2º Turno', '3º Turno'].map((x, i) => {
                      return {
                        value: i.toString(),
                        label: x,
                      };
                    })}
                    searchable
                    clearable
                    // disabled={props.disabled}
                    {...form.getInputProps('turno')}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <TextInput
                    icon={<Clock size={15} />}
                    rightSection={
                      <ActionIcon
                        size="sm"
                        radius="sm"
                        color="primary"
                        style={{ borderColor: theme?.colors?.primary?.[6] }}
                        variant="outline"
                        // disabled={props.disabled}
                        onClick={() => {
                          form.setFieldValue('jornada', 'Seg-Sex 08:00-17:00,Sab-Sab 08:00-12:00');
                        }}
                      >
                        <Clock size={15} color={theme?.colors?.primary?.[6]} />
                      </ActionIcon>
                    }
                    label="Jornada"
                    placeholder="Seg-Sex 08:00-17:00,Sab-Sab 08:00-12:00,..."
                    maxLength={100}
                    // disabled={props.disabled}
                    {...form.getInputProps('jornada')}
                  />
                </Grid.Col>
              </Grid>
              <Space h="xs" />
            </div>
          )}

          <SimpleGrid cols={1}>
            <Textarea
              icon={<Notes size={15} />}
              label="Observação"
              placeholder="Observação sobre o serviço"
              autosize
              maxLength={500}
              // disabled={props.disabled}
              {...form.getInputProps('observacao')}
            />
          </SimpleGrid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Cliente e Local"
            text="Cliente e local onde a prestação de serviço deverá ocorrer."
          />
          <Space h="xs" />
          <Grid columns={4}>
            <Grid.Col span={2}>
              <EntitySearch
                label="Cliente"
                referenceData={props.referenceData}
                formItem={form.getInputProps('cliente') || undefined}
                idsToBeDisabled={[]}
                disabled={props.disabled}
                required
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('clienteData', item);
                    form.setFieldValue('idCliente', item.idEntidade);
                    form.setFieldValue('cliente', item.nomeFantasia || item.razaoSocial || item.nome || '');
                    form.setFieldValue('idEntidadeEndereco', -1);
                    form.setFieldValue('endereco', '');
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <EntityAddressList
                label="Endereço"
                referenceData={{ idEntidade: form.values.idCliente! }}
                formItem={form.getInputProps('endereco')}
                idEntidadeEndereco={form.values.idEntidadeEndereco}
                endereco={form.values.endereco}
                idsToBeDisabled={[]}
                callback={async (item: EntityAddressType | null) => {
                  if (item) {
                    form.setFieldValue('enderecoData', item);
                    form.setFieldValue('idEntidadeEndereco', item.idEntidadeEndereco!);
                    form.setFieldValue(
                      'endereco',
                      `${item.logradouro}, ${item.numero} | ${item.bairro} - ${item.cidade}/${item.codigoEstado}`
                    );
                  }
                }}
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
    </div>
  );
});

export default ServiceGeneralFormView;
