/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { DeviceFloppy } from 'tabler-icons-react';
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { FilterData, EquipmentSearchModalFilter } from './EquipmentSearchModalFilter';
import { EquipmentType } from '../../../models/core/equipment.type';
import { SessionStorageKey } from '../../../utils/constants.utils';
import equipmentsService from '../../../services/core/equipment.service';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';

type EquipmentFormViewProps = {
  idsToBeDisabled: number[];
  callback(item: EquipmentType | null, confirmed: boolean): void;
};

export default function EquipmentSearchModal(props: EquipmentFormViewProps) {
  const [data, setData] = useState<EquipmentType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<EquipmentType[]>([]);

  const handleSubmit = () => {
    props.callback(selectedData?.[0] || null, true);
  };

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.EquipmentSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await equipmentsService.search({
        residuoEquipamento: inputFilterData?.residuoEquipamento || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.EquipmentSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Equipamento - Pesquisar`,
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar equipamentos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EquipmentType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: EquipmentType) => ({
      disabled: props.idsToBeDisabled.includes(record.idResiduoEquipamento),
      name: record.idResiduoEquipamento.toString(),
    }),
  };

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.EquipmentSearch) || '[]'
    ) as EquipmentType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({
        residuoEquipamento: null,
      });
    }
  }, []);

  return (
    <div>
      <EquipmentSearchModalFilter filter={filter} clear={clear} loading={loading} />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: 'Equipamento',
                key: 'residuoEquipamento',
                dataIndex: 'residuoEquipamento',
                sorter: (a: EquipmentType, b: EquipmentType) =>
                  a.residuoEquipamento.localeCompare(b.residuoEquipamento),
              },
              {
                title: 'Descrição',
                key: 'descricao',
                sorter: (a: EquipmentType, b: EquipmentType) => {
                  return (a.descricao || '').localeCompare(b.descricao || '');
                },
                render: (row: EquipmentType) => (
                  <TableCellEllipsis label={row?.descricao} numberOfChars={25} toolTipWidth={300} />
                ),
              },
            ]}
            rowSelection={{ type: 'radio', ...rowSelection }}
            rowKey={(item: EquipmentType) => item.idResiduoEquipamento}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
