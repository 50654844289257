/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { Grid, Group, Paper, Space, Text, Textarea, ThemeIcon } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Tabs } from 'antd';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Backhoe, Notes, Package, Recycle, Target, Truck } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanType,
  ProposalServiceType,
} from '../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../utils/helper.utils';
import ResiduePlanDestinationFormView from './ResiduePlanGeneralFormView/ResiduePlanDestinationFormView';
import ResiduePlanEquipmentFormView from './ResiduePlanGeneralFormView/ResiduePlanEquipmentFormView';
import ResiduePlanPackagingFormView from './ResiduePlanGeneralFormView/ResiduePlanPackagingFormView';
import ResiduePlanTreatmentFormView from './ResiduePlanGeneralFormView/ResiduePlanTreatmentFormView';
import ResiduePlanVehicleFormView from './ResiduePlanGeneralFormView/ResiduePlanVehicleFormView';

type FormViewData = {
  action: Action;
  id: string;

  primario: boolean;
  observacao: string | null;
};

type ProposalFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanType | null;
  item: ProposalResiduePlanType | null;
  idPropostaResiduo: number | undefined;
  idEntidadeEndereco: number;
  services: ProposalServiceType[];

  callback(item: ProposalResiduePlanType | null, action: string, confirmed: boolean): void;
};

const ResiduePlanGeneralFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const refPackagingForm = useRef<any>();
  const refEquipmentForm = useRef<any>();
  const refVehicleForm = useRef<any>();
  const refTreatmentForm = useRef<any>();
  const refDestinationForm = useRef<any>();

  const tabs = [
    {
      key: 'Acondicionamentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Package size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Acondicionamentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanPackagingFormView
            ref={refPackagingForm}
            referenceData={props.referenceData}
            proposalResiduePlan={props.item}
            idEntidadeEndereco={props.idEntidadeEndereco}
            services={props.services}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Equipamentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Backhoe size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Equipamentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanEquipmentFormView
            ref={refEquipmentForm}
            referenceData={props.referenceData}
            proposalResiduePlan={props.item}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Veiculos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Truck size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Veículo
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanVehicleFormView
            ref={refVehicleForm}
            referenceData={props.referenceData}
            proposalResiduePlan={props.item}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Tratamento',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Recycle size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Tratamento
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanTreatmentFormView
            ref={refTreatmentForm}
            referenceData={props.referenceData}
            proposalResiduePlan={props.item}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'DestinoFinal',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
            <Target size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
            Destino Final
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ResiduePlanDestinationFormView
            ref={refDestinationForm}
            referenceData={props.referenceData}
            proposalResiduePlan={props.item}
          />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      primario: props.item?.primario || false,
      observacao: props.item?.observacao || '',
    },
    validate: {},
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.primario || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.primario || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanType | null {
      if (form.validate().hasErrors) {
        throw Error('Existem pendências a serem corrigidas.');
      }

      const acondicionamentos = refPackagingForm?.current?.validate() || [];
      const equipamentos = refEquipmentForm?.current?.validate() || [];
      const veiculo = refVehicleForm?.current?.validate()?.[0] || null;
      const tratamento = refTreatmentForm?.current?.validate()?.[0] || null;
      const destinoFinal = refDestinationForm?.current?.validate()?.[0] || null;

      if (
        (!tratamento || tratamento.action === Action.Delete) &&
        (!destinoFinal || destinoFinal.action === Action.Delete)
      ) {
        throw Error('Tratamento ou Destino Final deve ser preenchido.');
      }

      let { values } = form;

      if (props.origItem) {
        if (isModified() && props.origItem.idPropostaResiduoPlano) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: props.item?.action || Action.Nothing,
          };
        }
      }

      const formItem: ProposalResiduePlanType = {
        ...values,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idPropostaResiduo: props.idPropostaResiduo || -1,
      };

      formItem.observacao = formItem.observacao?.trim() || null;
      formItem.acondicionamentos = acondicionamentos;
      formItem.equipamentos = equipamentos;
      formItem.veiculo = veiculo;
      formItem.tratamento = tratamento;
      formItem.destinoFinal = destinoFinal;
      formItem.cotacoes = [];

      return formItem;
    },
  }));

  return (
    <div>
      <form id="proposal-residue-plan" noValidate>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Geral"
            text="Informações gerais do plano de coleta do resíduo."
          />
          <Space h="xs" />

          <Paper shadow="xs" p="md" withBorder>
            <Grid columns={1}>
              {/* <Grid.Col span={1}>
                <PageViewProperty label="Completo?" text={form.values.completo ? 'Sim' : 'Não'} size="sm" />
              </Grid.Col> */}
              <Grid.Col span={1}>
                <PageViewProperty label="Primário?" text={form.values.primario ? 'Sim' : 'Não'} size="sm" />
              </Grid.Col>
            </Grid>
            <Space h="xs" />

            <Grid columns={1}>
              <Grid.Col span={1}>
                <Textarea
                  icon={<Notes size={15} />}
                  label="Observação"
                  placeholder="Observação sobre o plano de coleta do resíduo"
                  autosize
                  maxLength={500}
                  {...form.getInputProps('observacao')}
                />
              </Grid.Col>
            </Grid>
          </Paper>
          <Space h="lg" />

          <Paper shadow="xs" p="md" withBorder>
            <Tabs items={tabs} defaultActiveKey="Acondicionamentos" />
          </Paper>
        </Paper>
      </form>
    </div>
  );
});

export default ResiduePlanGeneralFormView;
