import { AuditType } from './core.type';

export enum Permission {
  ContractAdd = 'Contract.Add',
  ContractCancel = 'Contract.Cancel',
  ContractDelete = 'Contract.Delete',
  ContractEdit = 'Contract.Edit',
  ContractSearch = 'Contract.Search',
  ContractView = 'Contract.View',
  DestinationAdd = 'Destination.Add',
  DestinationDelete = 'Destination.Delete',
  DestinationEdit = 'Destination.Edit',
  DestinationSearch = 'Destination.Search',
  DestinationView = 'Destination.View',
  EntityAdd = 'Entity.Add',
  EntityDelete = 'Entity.Delete',
  EntityEdit = 'Entity.Edit',
  EntitySearch = 'Entity.Search',
  EntityView = 'Entity.View',
  EquipmentAdd = 'Equipment.Add',
  EquipmentDelete = 'Equipment.Delete',
  EquipmentEdit = 'Equipment.Edit',
  EquipmentSearch = 'Equipment.Search',
  EquipmentView = 'Equipment.View',
  EventAdd = 'Event.Add',
  EventDelete = 'Event.Delete',
  EventEdit = 'Event.Edit',
  EventPrint = 'Event.Print',
  EventSearch = 'Event.Search',
  EventView = 'Event.View',
  PackagingAdd = 'Packaging.Add',
  PackagingDelete = 'Packaging.Delete',
  PackagingEdit = 'Packaging.Edit',
  PackagingSearch = 'Packaging.Search',
  PackagingView = 'Packaging.View',
  ProposalAdd = 'Proposal.Add',
  ProposalDelete = 'Proposal.Delete',
  ProposalEdit = 'Proposal.Edit',
  ProposalPrint = 'Proposal.Print',
  ProposalSearch = 'Proposal.Search',
  ProposalView = 'Proposal.View',
  ProposalOwn = 'Proposal.Own',
  ResidueAdd = 'Residue.Add',
  ResidueDelete = 'Residue.Delete',
  ResidueEdit = 'Residue.Edit',
  ResidueSearch = 'Residue.Search',
  ResidueView = 'Residue.View',
  ServiceAdd = 'Service.Add',
  ServiceDelete = 'Service.Delete',
  ServiceEdit = 'Service.Edit',
  ServiceSearch = 'Service.Search',
  ServiceView = 'Service.View',
  TreatmentAdd = 'Treatment.Add',
  TreatmentDelete = 'Treatment.Delete',
  TreatmentEdit = 'Treatment.Edit',
  TreatmentSearch = 'Treatment.Search',
  TreatmentView = 'Treatment.View',
  VehicleAdd = 'Vehicle.Add',
  VehicleDelete = 'Vehicle.Delete',
  VehicleEdit = 'Vehicle.Edit',
  VehicleSearch = 'Vehicle.Search',
  VehicleView = 'Vehicle.View',
}

export enum PermissionDisabled {
  ContractAdd = 'Contract.Add',
  ContractEdit = 'Contract.Edit',
}

type DepartmentSearchType = {
  idEmpresa?: string | null;
  departamento?: string | null;
  listarPermissoes?: boolean;
  limit: number;
  offset: number;
};

type DepartmentSelectType = {
  idDepartamento: number;
};

type DepartmentUpdateType = {
  idDepartamento: number;
  idEmpresa: number;
  departamento: string;
  gerencial: boolean;
  suporte: boolean;
  permissoes: DepartmentPermissionType[];
};

type DepartmentDeleteType = {
  idDepartamento: number;
};

type DepartmentInsertType = {
  idEmpresa: number;
  departamento: string;
  gerencial: boolean;
  suporte: boolean;
  permissoes: DepartmentPermissionType[];
};

type DepartmentType = {
  idDepartamento: number;
  departamento: string;
  idEmpresa: number;
  empresa: string;
  executivo?: boolean;
  gerencial: boolean;
  suporte: boolean;
  permissoes: DepartmentPermissionType[];
} & AuditType;

type DepartmentPermissionType = {
  idDepartamentoPermissao?: number | null;
  idDepartamento?: number | null;
  codigoPermissao: Permission;
}; // & AuditType;

export type {
  DepartmentSearchType,
  DepartmentSelectType,
  DepartmentUpdateType,
  DepartmentDeleteType,
  DepartmentInsertType,
  DepartmentType,
  DepartmentPermissionType,
};
