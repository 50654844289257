import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import { Button, Card, LoadingOverlay, Paper } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import DataView from './components/DataView';
import { PackagingType } from '../../../../models/core/packaging.type';
import { Filter, FilterData } from './components/Filter';
import PageContent from '../../../../components/core/PageContent/PageContent';
import packagingsPackaging from '../../../../services/core/packaging.service';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { Permission } from '../../../../models/core/departments.type';

function PackagingSearch() {
  const navigate = useNavigate();

  const [data, setData] = useState<PackagingType[]>([]);
  const [loading, setLoading] = useState(false);

  /* eslint-disable @typescript-eslint/no-use-before-define */
  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.PackagingSearch) || '[]'
    ) as PackagingType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({ residuoAcondicionamento: '' });
    }
  }, []);

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.PackagingSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await packagingsPackaging.search({
        residuoAcondicionamento: inputFilterData?.residuoAcondicionamento || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.PackagingSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Acondicionamentos',
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível pesquisar acondicionamentos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmActionResult = async (item: PackagingType, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);

      switch (action) {
        case 'excluir':
          await packagingsPackaging.delete({ idResiduoAcondicionamento: item.idResiduoAcondicionamento });
          setData(data.filter((x) => x.idResiduoAcondicionamento !== item.idResiduoAcondicionamento));
          break;
        case 'visualizar':
          navigate(`/packaging/${item.idResiduoAcondicionamento}`, {
            state: {
              packaging: item,
            },
          });
          return;
        case 'editar':
          navigate(`/packaging/${item.idResiduoAcondicionamento}/edit`, {
            state: {
              packaging: item,
            },
          });
          return;
        default:
          break;
      }

      showNotification({
        title: 'Acondicionamentos',
        message: `Acondicionamento excluído com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Acondicionamentos',
        message: error?.isBusinessException
          ? error.description
          : `Não foi possível ${action} o acondicionamento.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Packaging}
        title="Acondicionamentos"
        description="Gerencie acondicionamentos."
        buttons={[
          <Button
            key="Adicionar"
            color="primary"
            leftIcon={<Plus size={18} />}
            disabled={loading}
            onClick={() => navigate('/packaging/add')}
            data-permission={Permission.PackagingAdd}
          >
            Adicionar
          </Button>,
        ]}
      />
      <PageContent>
        <Filter filter={filter} clear={clear} loading={loading} />
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <DataView data={data} confirmActionResult={confirmActionResult} />
          </Paper>
        </div>
      </PageContent>
    </Card>
  );
}

export default PackagingSearch;
