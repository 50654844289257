/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { DeviceFloppy } from 'tabler-icons-react';
import { Button, Group, LoadingOverlay, Paper, Space } from '@mantine/core';
import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import { EntityDestinationType } from '../../../../../../../models/core/entities.type';
import { FilterData, DestinationFormSearchFilter } from './DestinationFormSearchFilter';
import { DestinationType } from '../../../../../../../models/core/destinations.type';
import { SessionStorageKey } from '../../../../../../../utils/constants.utils';
import destinationsService from '../../../../../../../services/core/destinations.service';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';
import { Action } from '../../../../../../../models/core/core.type';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';

type FormViewProps = {
  items: EntityDestinationType[];
  idEntidade: number | undefined;
  callback(items: EntityDestinationType[], action: string, confirmed: boolean): void;
};

export default function DestinationFormSearch(props: FormViewProps) {
  const [data, setData] = useState<DestinationType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<DestinationType[]>([]);

  const handleSubmit = () => {
    props.callback(
      selectedData.map((x) => {
        return {
          ...x,
          ...buildFakeAuditObject(),
          residuoDestinoFinalDescricao: x.descricao,
          idEntidade: props.idEntidade || -1,
          id: newGuid(),
          action: Action.Add,
        };
      }),
      'callback',
      true
    );
  };

  const clear = () => {
    sessionStorage.removeItem(SessionStorageKey.DestinationSearch);
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    try {
      setLoading(true);
      const result = await destinationsService.search({
        residuoDestinoFinal: inputFilterData?.destinoFinal || null,
        limit: 1000,
        offset: 0,
      });
      sessionStorage.setItem(SessionStorageKey.DestinationSearch, JSON.stringify(result));
      setData(result);
    } catch (error: any) {
      showNotification({
        title: `Entidade - ${!props.idEntidade ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível pesquisar destinos finais.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DestinationType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: DestinationType) => ({
      disabled: props.items.findIndex((x) => x.idResiduoDestinoFinal === record.idResiduoDestinoFinal) !== -1,
      name: record.idResiduoDestinoFinal.toString(),
    }),
  };

  useEffect(() => {
    const cacheResult = JSON.parse(
      sessionStorage.getItem(SessionStorageKey.DestinationSearch) || '[]'
    ) as DestinationType[];

    if (cacheResult.length > 0) {
      setData(cacheResult);
    } else {
      filter({ destinoFinal: '' });
    }
  }, []);

  return (
    <div>
      <DestinationFormSearchFilter filter={filter} clear={clear} loading={loading} />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: 'Destino Final',
                key: 'destinoFinal',
                dataIndex: 'residuoDestinoFinal',
                sorter: (a: DestinationType, b: DestinationType) =>
                  a.residuoDestinoFinal.localeCompare(b.residuoDestinoFinal),
              },
              {
                title: 'Descrição',
                key: 'descricao',
                sorter: (a: DestinationType, b: DestinationType) => {
                  return (a.descricao || '').localeCompare(b.descricao || '');
                },
                render: (row: DestinationType) => (
                  <TableCellEllipsis label={row?.descricao} numberOfChars={25} toolTipWidth={300} />
                ),
              },
            ]}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            rowKey={(item: DestinationType) => item.idResiduoDestinoFinal}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
