/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { MantineSize, Space, SimpleGrid, Paper } from '@mantine/core';
import { calculateMantineSize } from '../../../utils/mantine.utils';
import { formatTimestampToString } from '../../../utils/formatter.utils';
import ProfileCardLink from '../ProfileCardLink/ProfileCardLink';
import PageSection from '../PageSection/PageSection';

type PageViewAuditProps = {
  idCriadoPor: string | null;
  criadoPor: string | null;
  dataCriacao: Date | null;
  idModificadoPor?: string | null;
  modificadoPor?: string | null;
  dataModificacao?: Date | null;
  size: MantineSize;
  gridSize?: number;
};

export default function PageViewAudit(props: PageViewAuditProps) {
  const finalSize = calculateMantineSize(props.size);
  return (
    <Paper shadow="xs" p="md" withBorder>
      <PageSection size={finalSize.bigger} color="primary" label="Auditoria" text="" />
      <Space h="xs" />
      <SimpleGrid cols={props.gridSize || 4}>
        <ProfileCardLink
          id={props.idCriadoPor || 'N/A'}
          name={props.criadoPor || 'N/A'}
          nameSize={finalSize.current}
          description={`Criado em ${props.dataCriacao ? formatTimestampToString(props.dataCriacao) : 'N/A'}`}
          descriptionSize={finalSize.smaller}
        />
        {props?.dataModificacao && (
          <ProfileCardLink
            id={props?.idModificadoPor || 'N/A'}
            name={props?.modificadoPor || 'N/A'}
            nameSize={finalSize.current}
            description={`Modificado em ${
              props.dataModificacao ? formatTimestampToString(props.dataModificacao) : 'N/A'
            }`}
            descriptionSize={finalSize.smaller}
          />
        )}
      </SimpleGrid>
    </Paper>
  );
}
