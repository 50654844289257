/* eslint-disable react/destructuring-assignment */
import { Group, Paper, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../components/core/PageViewAudit/PageViewAudit';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { EntityDocumentType } from '../../../../../../models/core/entities.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import FileView from './FileView';

type ViewProps = {
  data: EntityDocumentType[];
};

export default function DocumentView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Tipo',
      key: 'documentoTipo',
      dataIndex: 'documentoTipo',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) =>
        a.documentoTipo.localeCompare(b.documentoTipo),
    },
    {
      title: 'Outro Tipo',
      key: 'outroTipo',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        return (a.outroTipo || '').localeCompare(b.outroTipo || '');
      },
      render: (row: EntityDocumentType) => <div>{row?.outroTipo || '-'}</div>,
    },
    {
      title: 'Número do Documento',
      key: 'numeroDocumento',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        return (a.numeroDocumento || '').localeCompare(b.numeroDocumento || '');
      },
      render: (row: EntityDocumentType) => <div>{row?.numeroDocumento || '-'}</div>,
    },
    {
      title: 'Lembrete (dias)',
      key: 'validadeLembreteDias',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        const aValue = a.validadeLembreteDias || 0;
        const bValue = b.validadeLembreteDias || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityDocumentType) => <div>{row?.validadeLembreteDias || '-'}</div>,
    },
    {
      title: 'Validade',
      key: 'validade',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) =>
        new Date(a.validade.toString().split('T')[0]).valueOf() -
        new Date(b.validade.toString().split('T')[0]).valueOf(),
      render: (row: EntityDocumentType) => formatDateToString(row.validade),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EntityDocumentType) => {
        return <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />;
      },
    },
    {
      title: 'Arquivos',
      key: 'arquivos',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        const aValue = a.arquivos.length;
        const bValue = b.arquivos.length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityDocumentType) => <div>{row.arquivos.length}</div>,
    },
  ];

  return (
    <div>
      <Group>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Documentos" text="" />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EntityDocumentType) => item.idEntidadeDocumento || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <>
                <PageViewAudit
                  idCriadoPor={item.idCriadoPor}
                  criadoPor={item.criadoPor}
                  dataCriacao={item.dataCriacao}
                  idModificadoPor={item.idModificadoPor || null}
                  modificadoPor={item.modificadoPor || null}
                  dataModificacao={item.dataModificacao || null}
                  size="sm"
                />
                <Space h="md" />
                <Paper shadow="xs" p="md" withBorder>
                  <FileView typeId={item.idEntidade} data={item.arquivos} size="sm" expandable />
                </Paper>
              </>
            );
          },
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
