import { call } from '../iam-identity.service';
import { UpdateIdentityType } from '../../models/iam/identity.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

export default class AuthService {
  static updateIdentity = async (updateIdentityProps: UpdateIdentityType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `/v1/identities/${updateIdentityProps.identityId}`,
      data: updateIdentityProps,
    };
    return (await call(request))?.data?.data;
  };
}
