/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Group, Paper, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useModals } from '@mantine/modals';
import {
  EntityAddressContactType,
  EntityAddressType,
  EntityType,
} from '../../../../../../../models/core/entities.type';
import AddressFormViewActions from './AddressFormViewActions';
import AddressFormAddEdit from './AddressFormAddEdit';
import { AddressTypeType } from '../../../../../../../models/core/cache.type';
import { EstadoType } from '../../../../../../../models/utils/brasilapi.type';
import ContactView from '../../../../EntityView/components/components/ContactView';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../models/core/core.type';

type EntityAddressEditType = EntityAddressType & {
  origContacts?: EntityAddressContactType[];
};

type FormViewProps = {
  referenceData: {
    addressTypeData: AddressTypeType[];
    estadoTypeData: EstadoType[];
  };
  entity: EntityType | null;
};

const AddressFormView = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [data, setData] = useState<EntityAddressEditType[]>(
    props.entity?.enderecos.map((x) => {
      return {
        ...x,
        origContacts: x.contatos,
        action: Action.Nothing,
        id: x.idEntidadeEndereco?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo',
      key: 'enderecoTipo',
      dataIndex: 'enderecoTipo',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.enderecoTipo.localeCompare(b.enderecoTipo),
    },
    {
      title: 'Estado',
      key: 'estado',
      dataIndex: 'estado',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.estado.localeCompare(b.estado),
    },
    {
      title: 'Cidade',
      key: 'cidade',
      dataIndex: 'cidade',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.cidade.localeCompare(b.cidade),
    },
    {
      title: 'Bairro',
      key: 'bairro',
      dataIndex: 'bairro',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.bairro.localeCompare(b.bairro),
    },
    {
      title: 'Logradouro',
      key: 'logradouro',
      dataIndex: 'logradouro',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.logradouro.localeCompare(b.logradouro),
    },
    {
      title: 'Número',
      key: 'numero',
      dataIndex: 'numero',
      sorter: (a: EntityAddressType, b: EntityAddressType) => a.numero.localeCompare(b.numero),
    },
    {
      title: 'Complemento',
      key: 'complemento',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        return (a.complemento || '').localeCompare(b.complemento || '');
      },
      render: (row: EntityAddressType) => <div>{row?.complemento || '-'}</div>,
    },
    {
      title: 'Referência',
      key: 'referencia',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        return (a.referencia || '').localeCompare(b.referencia || '');
      },
      render: (row: EntityAddressType) => <div>{row?.referencia || '-'}</div>,
    },
    {
      title: 'CEP',
      key: 'cep',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        return (a.cep || '').localeCompare(b.cep || '');
      },
      render: (row: EntityAddressType) => <div>{row?.cep || '-'}</div>,
    },
    {
      title: 'Contatos',
      key: 'contatos',
      sorter: (a: EntityAddressType, b: EntityAddressType) => {
        const aValue = a.contatos.filter((x) => x.action !== Action.Delete).length;
        const bValue = b.contatos.filter((x) => x.action !== Action.Delete).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityAddressType) => <div>{row.contatos.length}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityAddressEditType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <AddressFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EntityAddressEditType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeEndereco) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Endereço - ${item ? 'Editar' : 'Adicionar'}`,
          size: 'xl',
          closeOnClickOutside: false,
          children: (
            <AddressFormAddEdit
              referenceData={props.referenceData}
              origItem={
                item
                  ? props.entity?.enderecos.find((x) => x.idEntidadeEndereco === item.idEntidadeEndereco) ||
                    null
                  : null
              }
              item={item}
              idEntidade={props.entity?.idEntidade}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityAddressEditType[] {
      return data.map((x) => {
        return {
          ...x,
          contatos: x.contatos.map((y) => {
            return { ...y, action: y.action || Action.Nothing };
          }),
        };
      });
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setData(tempEntity.enderecos);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Endereços" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityAddressEditType) => item.id || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <Paper shadow="xs" p="md" withBorder>
                <ContactView
                  data={item.contatos.filter((x) => x.action !== Action.Delete)}
                  size="sm"
                  expandable={false}
                />
              </Paper>
            );
          },
        }}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default AddressFormView;
