/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import filesize from 'filesize';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../models/core/core.type';
import { EventDocumentFileType, EventDocumentType } from '../../../../../../models/core/events.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../utils/helper.utils';
import DocumentFileFormAddEdit from './DocumentFileFormAddEdit';
import DocumentFileFormViewActions from './DocumentFileFormViewActions';
import { validate } from '../../../../../../utils/permission.utils';
import { Permission } from '../../../../../../models/core/departments.type';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';

type FormViewProps = {
  eventDocument: (EventDocumentType & { origFiles?: EventDocumentFileType[] }) | null;
};

const DocumentFileFormView = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const [data, setData] = useState<EventDocumentFileType[]>(
    props.eventDocument?.arquivos.map((x) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idEventoDocumentoArquivo?.toString() || x.idTemporaria || newGuid(),
      };
    }) || []
  );
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nome',
      dataIndex: 'nome',
      sorter: (a: EventDocumentFileType, b: EventDocumentFileType) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Tamanho',
      key: 'tamanho',
      sorter: (a: EventDocumentFileType, b: EventDocumentFileType) => {
        const aValue = a.tamanho;
        const bValue = b.tamanho;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EventDocumentFileType) => <div>{filesize(row.tamanho, { locale: 'pt-BR' })}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EventDocumentFileType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DocumentFileFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (items: EventDocumentFileType[] | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === items![0].id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEventoDocumentoArquivo) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'adicionar':
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        setData(data.concat(items || []));
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EventDocumentFileType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Documento/Arquivo - Adicionar"
        size="xl"
      >
        <DocumentFileFormAddEdit
          items={data}
          idEventoDocumento={props.eventDocument?.idEventoDocumento}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Home.Event.color} label="Arquivos" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
          disabled={!validate(Permission.EventEdit, currentUser.permissoes)}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EventDocumentFileType) => item.id || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default DocumentFileFormView;
