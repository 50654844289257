/* eslint-disable react/destructuring-assignment */
import { Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { DepartmentType, Permission, PermissionDisabled } from '../../../../../models/core/departments.type';
import { Feature } from '../../../../../utils/constants.utils';
import DepartmentPermission from '../../components/DepartmentPermission';

type DataViewProps = {
  data: DepartmentType;
};

export default function DataView(props: DataViewProps) {
  return (
    <>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.Department.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Empresa" text={props.data.empresa} size="md" />
          <PageViewProperty label="Departamento" text={props.data.departamento} size="md" />
          <PageViewProperty label="Acesso Executivo?" text={props.data.executivo ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty label="Acesso Gerencial?" text={props.data.gerencial ? 'Sim' : 'Não'} size="md" />
          <PageViewProperty label="Acesso de Suporte?" text={props.data.suporte ? 'Sim' : 'Não'} size="md" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Managerial.Department.color}
          label="Permissões"
          text="Lista de permissões em que os usuários pertencentes a este departamento terão acesso."
        />
        <Space h="xs" />
        <DepartmentPermission
          ref={null}
          idDepartamento={props.data.idDepartamento}
          permissoes={Object.values(Permission)}
          permissoesDesabilidatas={Object.values(PermissionDisabled) as any as Permission[]}
          permissoesAtuais={props.data.permissoes}
          modoEdicao={false}
        />
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
      />
    </>
  );
}
