/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-access-key */
/* eslint-disable no-continue */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import { Group, Table as MantineTable, Switch, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { ReactNode, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Check, X } from 'tabler-icons-react';
import useDynamicRefs from '../../../../hooks/useDynamicRefs';
import { DepartmentPermissionType, Permission } from '../../../../models/core/departments.type';
import { FeatureType } from '../../../../models/utils/constants.type';
import { Feature } from '../../../../utils/constants.utils';
import { buildFakeAuditObject } from '../../../../utils/helper.utils';

type ComponentPropertyProps = {
  idDepartamento: number | null;
  permissoes: Permission[];
  permissoesDesabilidatas: Permission[];
  permissoesAtuais: DepartmentPermissionType[];
  modoEdicao: boolean;
};

type PermissionMap = {
  grupo: string;
  item: FeatureType;
  ordem: number;
  permissoes: {
    ordem: number;
    permissao: string;
    desabilitado: boolean;
    ativo: boolean;
  }[];
};

const permissionPrefixTranslator = {
  Proposal: { group: 'Home', order: 0, item: Feature.Home.Proposal },
  Contract: { group: 'Home', order: 1, item: Feature.Home.Contract },
  Event: { group: 'Home', order: 2, item: Feature.Home.Event },

  Entity: { group: 'Referências', order: 3, item: Feature.Reference.Entity },
  Residue: { group: 'Referências', order: 4, item: Feature.Reference.Residue },
  Service: { group: 'Referências', order: 5, item: Feature.Reference.Service },
  Packaging: { group: 'Referências', order: 6, item: Feature.Reference.Packaging },
  Destination: { group: 'Referências', order: 7, item: Feature.Reference.Destination },
  Equipment: { group: 'Referências', order: 8, item: Feature.Reference.Equipment },
  Treatment: { group: 'Referências', order: 9, item: Feature.Reference.Treatment },
  Vehicle: { group: 'Referências', order: 10, item: Feature.Reference.Vehicle },
};

const permissionSuffixTranslator = {
  Add: { name: 'Adicionar', order: 0 },
  Edit: { name: 'Editar', order: 1 },
  Search: { name: 'Pesquisar', order: 2 },
  View: { name: 'Visualizar', order: 3 },
  Delete: { name: 'Excluir', order: 4 },
  Print: { name: 'Imprimir', order: 5 },
  Cancel: { name: 'Cancelar', order: 6 },
  Own: { name: 'Próprios', order: 7 },
};

const DepartmentPermission = forwardRef((props: ComponentPropertyProps, ref) => {
  const [getRef, setRef] = useDynamicRefs();

  const buildPermissionMap = () => {
    const map = new Map<string, PermissionMap>();

    for (const permissao of props.permissoes) {
      const split = permissao.split('.');
      const prefix = (permissionPrefixTranslator as any)[split[0]];
      const key = `${prefix.group}.${prefix.item.label}`;

      const values = map.get(key)?.permissoes ?? [];
      values.push({
        ordem: (permissionSuffixTranslator as any)[split[1]].order,
        permissao,
        desabilitado: !!props.permissoesDesabilidatas.find((x) => x === permissao),
        ativo: !!props.permissoesAtuais.find((x) => x.codigoPermissao === permissao),
      });

      map.set(key, { grupo: prefix.group, item: prefix.item, ordem: prefix.order, permissoes: values });
    }

    return map;
  };

  const buildColumns = () => {
    const nodes: ReactNode[] = [
      <th key="permissao" style={{ textAlign: 'unset' }}>
        Permissão
      </th>,
    ];
    for (const item of Object.values(permissionSuffixTranslator).sort((a, b) => a.order - b.order)) {
      nodes.push(<th key={item.name}>{item.name}</th>);
    }
    return nodes;
  };

  const buildCells = (permission: PermissionMap) => {
    const cells = Array(Object.keys(permissionSuffixTranslator).length).fill(
      <td style={{ textAlign: 'center' }}>-</td>
    );

    for (const x of permission.permissoes) {
      cells[x.ordem] = (
        <td key={x.permissao} style={{ textAlign: 'center' }}>
          {props.modoEdicao ? (
            <Group position="center">
              {x.desabilitado ? (
                '-'
              ) : (
                <Switch
                  disabled={x.desabilitado}
                  key={x.permissao}
                  ref={setRef(x.permissao)}
                  {...{
                    initially_enabled: x.ativo.toString(),
                  }}
                />
              )}
            </Group>
          ) : (
            <div>{x.desabilitado ? '-' : x.ativo ? <Check color="green" /> : <X color="red" />}</div>
          )}
        </td>
      );
    }

    return (
      <tr key={`${permission.grupo}.${permission.item.label}`}>
        <td>
          <UnstyledButton>
            <Group>
              <ThemeIcon color={permission.item.color} variant="outline">
                <permission.item.icon size={16} />
              </ThemeIcon>
              <Text size="sm">{permission.item.label}</Text>
              {props.modoEdicao && (
                <Switch
                  key={permission.item.label}
                  accessKey={permission.permissoes?.[0].permissao.split('.')?.[0] ?? ''}
                  size="xs"
                  onChange={(event) => {
                    for (const [refKey, refValue] of getRef(null)) {
                      if (refKey.startsWith(event.target.accessKey)) {
                        refValue.current.checked = !event.target.checked;
                        refValue.current.click();
                      }
                    }
                  }}
                />
              )}
            </Group>
          </UnstyledButton>
        </td>
        {cells}
      </tr>
    );
  };

  const buildRows = () => {
    const permissionMap = buildPermissionMap();
    const nodes: ReactNode[] = [];

    for (const permission of Array.from(permissionMap.values())
      .sort((a, b) => a.ordem - b.ordem)
      .map((x) => x)) {
      nodes.push(buildCells(permission));
    }

    return nodes;
  };

  useImperativeHandle(ref, () => ({
    validate() {
      const permissions: string[] = [];
      for (const [refKey, refValue] of getRef(null)) {
        if (!refValue.current) continue;

        for (const [key, value] of Object.entries(refValue.current ?? {})) {
          if (key.startsWith('__reactProps') && !!(value as any).checked) {
            permissions.push(refKey);
          }
        }
      }

      const intersectPermissions: DepartmentPermissionType[] = props.permissoesAtuais.filter((x) =>
        permissions.includes(x.codigoPermissao)
      );
      const newPermissions = permissions
        .filter((x) => !props.permissoesAtuais.map((y) => y.codigoPermissao).includes(x as Permission))
        .map((x) => {
          return { ...buildFakeAuditObject(), idDepartamento: props.idDepartamento, codigoPermissao: x };
        });

      return intersectPermissions.concat(newPermissions);
    },
  }));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [refKey, refValue] of getRef(null)) {
      if (!refValue.current) continue;

      for (const [key, value] of Object.entries(refValue.current)) {
        if (key.startsWith('__reactProps') && (value as any)?.initially_enabled === 'true') {
          refValue.current.click();
          break;
        }
      }
    }
  }, []);

  return (
    <div>
      <MantineTable className="custom-mantine-table" highlightOnHover>
        <thead>
          <tr>{buildColumns()}</tr>
        </thead>
        <tbody>{buildRows()}</tbody>
      </MantineTable>
    </div>
  );
});

export default DepartmentPermission;
