/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Column, ColumnConfig, Pie, PieConfig, Rose, RoseConfig } from '@ant-design/plots';
import { Badge, Button, Group, LoadingOverlay, Paper, Space, Stack, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import lodash from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Table as ITable, PlugConnectedX, Reload, TableOff } from 'tabler-icons-react';
import {
  DashboardItem,
  ExpiringContractsResponseType,
  ExpiringEntityDocumentsResponseType,
  ExpiringProposalsResponseType,
  MyEventsResponseType,
} from '../../../models/core/dashboard.type';
import dashboardService from '../../../services/core/dashboard.service';
import theme from '../../../theme';
import { Feature, SessionStorageKey } from '../../../utils/constants.utils';
import { formatDateToString } from '../../../utils/formatter.utils';
import PageSection from '../PageSection/PageSection';
import ProfileCardLink from '../ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';

type FormViewProps = {
  item: DashboardItem;
  filter: any;
  title: string;
  description: string;
  exportable: boolean;
  height: number | undefined;
};

type ChartType = {
  plot: string;
  item: DashboardItem;
  data: any;
  chartData: any;
  config: any;
} | null;

const chartMap = new Map([
  [
    DashboardItem.MeusEventos,
    {
      rowKey: 'idEvento',
      columns: [
        {
          title: '#',
          key: 'idEvento',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) => {
            const aValue = a.idEvento;
            const bValue = b.idEvento;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
          render: (row: MyEventsResponseType) => (
            <a href={`./events?id=${row.idEvento}`} target="_blank" rel="noreferrer">
              {row.idEvento}
            </a>
          ),
        },
        {
          title: 'Empresa',
          key: 'empresa',
          dataIndex: 'empresa',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) => a.empresa.localeCompare(b.empresa),
        },

        {
          title: 'Audiência',
          key: 'eventoAudiencia',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            a.eventoAudiencia.localeCompare(b.eventoAudiencia),
          render: (row: MyEventsResponseType) => <Badge variant="outline">{row.eventoAudiencia}</Badge>,
        },
        {
          title: 'Tipo',
          key: 'eventoTipo',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            a.eventoTipo.localeCompare(b.eventoTipo),
          render: (row: MyEventsResponseType) => <Badge variant="outline">{row.eventoTipo}</Badge>,
        },
        {
          title: 'Status',
          key: 'status',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            a.eventoStatus.localeCompare(b.eventoStatus),
          render: (row: MyEventsResponseType) => <Badge variant="outline">{row.eventoStatus}</Badge>,
        },
        {
          title: 'Data',
          key: 'data',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            new Date(a.data).valueOf() - new Date(b.data).valueOf(),
          render: (row: MyEventsResponseType) => formatDateToString(row.data),
        },
      ],
    },
  ],
  [
    DashboardItem.EventosAlerta,
    {
      rowKey: 'idEvento',
      columns: [
        {
          title: '#',
          key: 'idEvento',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) => {
            const aValue = a.idEvento;
            const bValue = b.idEvento;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
          render: (row: MyEventsResponseType) => (
            <a href={`./events?id=${row.idEvento}`} target="_blank" rel="noreferrer">
              {row.idEvento}
            </a>
          ),
        },
        {
          title: 'Empresa',
          key: 'empresa',
          dataIndex: 'empresa',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) => a.empresa.localeCompare(b.empresa),
        },

        {
          title: 'Audiência',
          key: 'eventoAudiencia',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            a.eventoAudiencia.localeCompare(b.eventoAudiencia),
          render: (row: MyEventsResponseType) => <Badge variant="outline">{row.eventoAudiencia}</Badge>,
        },
        {
          title: 'Tipo',
          key: 'eventoTipo',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            a.eventoTipo.localeCompare(b.eventoTipo),
          render: (row: MyEventsResponseType) => <Badge variant="outline">{row.eventoTipo}</Badge>,
        },
        {
          title: 'Status',
          key: 'status',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            a.eventoStatus.localeCompare(b.eventoStatus),
          render: (row: MyEventsResponseType) => <Badge variant="outline">{row.eventoStatus}</Badge>,
        },
        {
          title: 'Data',
          key: 'data',
          sorter: (a: MyEventsResponseType, b: MyEventsResponseType) =>
            new Date(a.data).valueOf() - new Date(b.data).valueOf(),
          render: (row: MyEventsResponseType) => formatDateToString(row.data),
        },
      ],
    },
  ],
  [
    DashboardItem.ContratosExpiracao,
    {
      rowKey: 'idContrato',
      columns: [
        {
          title: '#',
          key: 'idContrato',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) => {
            const aValue = a.idContrato;
            const bValue = b.idContrato;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
          render: (row: ExpiringContractsResponseType) => (
            <a href={`./contracts/${row.idContrato}`} target="_blank" rel="noreferrer">
              {row.idContrato}
            </a>
          ),
        },
        {
          title: 'Empresa',
          key: 'empresa',
          dataIndex: 'empresa',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) =>
            a.empresa.localeCompare(b.empresa),
        },
        {
          title: 'Proposta #',
          key: 'idProposta',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) => {
            const aValue = a.idProposta;
            const bValue = b.idProposta;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
          render: (row: ExpiringContractsResponseType) => (
            <ProfileCardLink
              id={row.idProposta.toString()}
              name="Proposta"
              nameSize="sm"
              description={`# ${row.idProposta}`}
              descriptionSize="xs"
              linkPrefix="proposals"
              showLink
            />
          ),
        },
        {
          title: 'Cliente',
          key: 'idCliente',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) =>
            (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
              b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
            ),
          render: (row: ExpiringContractsResponseType) => {
            if (row.clienteCNPJ) {
              return (
                <ProfileCardLink
                  id={row.idCliente.toString()}
                  name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                  nameSize="sm"
                  description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                  descriptionSize="xs"
                  linkPrefix="entities"
                  showLink
                />
              );
            }
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNome || '-'}
                nameSize="sm"
                description={row.clienteCPF}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          },
        },
        {
          title: 'Status',
          key: 'status',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) =>
            a.contratoStatus.localeCompare(b.contratoStatus),
          render: (row: ExpiringContractsResponseType) => (
            <Badge variant="outline">{row.contratoStatus}</Badge>
          ),
        },
        {
          title: 'Data Inicial',
          key: 'dataInicial',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) =>
            new Date(a.dataInicial).valueOf() - new Date(b.dataInicial).valueOf(),
          render: (row: ExpiringContractsResponseType) => formatDateToString(row.dataInicial),
        },
        {
          title: 'Data Final',
          key: 'dataFinal',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) =>
            new Date(a.dataFinal).valueOf() - new Date(b.dataFinal).valueOf(),
          render: (row: ExpiringContractsResponseType) => formatDateToString(row.dataFinal),
        },
        {
          title: 'Expira Em (dias)',
          key: 'expiraEmDias',
          dataIndex: 'expiraEmDias',
          sorter: (a: ExpiringContractsResponseType, b: ExpiringContractsResponseType) => {
            const aValue = a.expiraEmDias;
            const bValue = b.expiraEmDias;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
        },
      ],
    },
  ],
  [
    DashboardItem.PropostasExpiracao,
    {
      rowKey: 'idProposta',
      columns: [
        {
          title: '#',
          key: 'idProposta',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) => {
            const aValue = a.idProposta;
            const bValue = b.idProposta;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
          render: (row: ExpiringProposalsResponseType) => (
            <a href={`./proposals/${row.idProposta}`} target="_blank" rel="noreferrer">
              {row.idProposta}
            </a>
          ),
        },
        {
          title: 'Empresa',
          key: 'empresa',
          dataIndex: 'empresa',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) =>
            a.empresa.localeCompare(b.empresa),
        },
        {
          title: 'Cliente',
          key: 'idCliente',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) =>
            (a.clienteNomeFantasia || a.clienteRazaoSocial || a.clienteNome || '').localeCompare(
              b.clienteNomeFantasia || b.clienteRazaoSocial || b.clienteNome || ''
            ),
          render: (row: ExpiringProposalsResponseType) => {
            if (row.clienteCNPJ) {
              return (
                <ProfileCardLink
                  id={row.idCliente.toString()}
                  name={row.clienteNomeFantasia || row.clienteRazaoSocial || '-'}
                  nameSize="sm"
                  description={row.clienteNomeFantasia ? row.clienteRazaoSocial : row.clienteCNPJ}
                  descriptionSize="xs"
                  linkPrefix="entities"
                  showLink
                />
              );
            }
            return (
              <ProfileCardLink
                id={row.idCliente.toString()}
                name={row.clienteNome || '-'}
                nameSize="sm"
                description={row.clienteCPF}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          },
        },
        {
          title: 'Gerenciador',
          key: 'idGerenciador',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) =>
            (a.gerenciadorNomeFantasia || a.gerenciadorRazaoSocial || a.gerenciadorNome || '').localeCompare(
              b.gerenciadorNomeFantasia || b.gerenciadorRazaoSocial || b.gerenciadorNome || ''
            ),
          render: (row: ExpiringProposalsResponseType) => {
            if (!row.gerenciadorCNPJ) {
              return '-';
            }
            if (row.gerenciadorCNPJ) {
              return (
                <ProfileCardLink
                  id={row.idGerenciador!.toString()}
                  name={row.gerenciadorNomeFantasia || row.gerenciadorRazaoSocial || '-'}
                  nameSize="sm"
                  description={row.gerenciadorNomeFantasia ? row.gerenciadorRazaoSocial : row.gerenciadorCNPJ}
                  descriptionSize="xs"
                  linkPrefix="entities"
                  showLink
                />
              );
            }
            return (
              <ProfileCardLink
                id={row.idGerenciador!.toString()}
                name={row.gerenciadorNome || '-'}
                nameSize="sm"
                description={row.gerenciadorCPF}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          },
        },
        {
          title: 'Status',
          key: 'status',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) =>
            a.propostaStatus.localeCompare(b.propostaStatus),
          render: (row: ExpiringProposalsResponseType) => (
            <Badge variant="outline">{row.propostaStatus}</Badge>
          ),
        },
        {
          title: 'Validade',
          key: 'validade',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) =>
            new Date(a.validade).valueOf() - new Date(b.validade).valueOf(),
          render: (row: ExpiringProposalsResponseType) => formatDateToString(row.validade),
        },
        {
          title: 'Expira Em (dias)',
          key: 'expiraEmDias',
          dataIndex: 'expiraEmDias',
          sorter: (a: ExpiringProposalsResponseType, b: ExpiringProposalsResponseType) => {
            const aValue = a.expiraEmDias;
            const bValue = b.expiraEmDias;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
        },
      ],
    },
  ],
  [
    DashboardItem.EntidadeDocumentosExpiracao,
    {
      rowKey: 'idEntidadeDocumento',
      columns: [
        {
          title: 'Entidade',
          key: 'idEntidade',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) =>
            (a.nomeFantasia || a.razaoSocial || a.nome || '').localeCompare(
              b.nomeFantasia || b.razaoSocial || b.nome || ''
            ),
          render: (row: ExpiringEntityDocumentsResponseType) => {
            if (row.cnpj) {
              return (
                <ProfileCardLink
                  id={row.idEntidade.toString()}
                  name={row.nomeFantasia || row.razaoSocial || '-'}
                  nameSize="sm"
                  description={row.nomeFantasia ? row.razaoSocial : row.cnpj}
                  descriptionSize="xs"
                  linkPrefix="entities"
                  showLink
                />
              );
            }
            return (
              <ProfileCardLink
                id={row.idEntidade.toString()}
                name={row.nome || '-'}
                nameSize="sm"
                description={row.cpf}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          },
        },
        {
          title: 'Tipo',
          key: 'documentoTipo',
          dataIndex: 'documentoTipo',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) =>
            a.documentoTipo.localeCompare(b.documentoTipo),
        },
        {
          title: 'Outro Tipo',
          key: 'outroTipo',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) => {
            return (a.outroTipo || '').localeCompare(b.outroTipo || '');
          },
          render: (row: ExpiringEntityDocumentsResponseType) => <div>{row?.outroTipo || '-'}</div>,
        },
        {
          title: 'Número do Documento',
          key: 'numeroDocumento',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) => {
            return (a.numeroDocumento || '').localeCompare(b.numeroDocumento || '');
          },
          render: (row: ExpiringEntityDocumentsResponseType) => <div>{row?.numeroDocumento || '-'}</div>,
        },
        {
          title: 'Lembrete (dias)',
          key: 'validadeLembreteDias',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) => {
            const aValue = a.validadeLembreteDias || 0;
            const bValue = b.validadeLembreteDias || 0;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
          render: (row: ExpiringEntityDocumentsResponseType) => <div>{row?.validadeLembreteDias || '-'}</div>,
        },
        {
          title: 'Validade',
          key: 'validade',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) =>
            new Date(a.validade.toString().split('T')[0]).valueOf() -
            new Date(b.validade.toString().split('T')[0]).valueOf(),
          render: (row: ExpiringEntityDocumentsResponseType) => formatDateToString(row.validade),
        },
        {
          title: 'Observação',
          key: 'observacao',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) => {
            return (a.observacao || '').localeCompare(b.observacao || '');
          },
          render: (row: ExpiringEntityDocumentsResponseType) => {
            return <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />;
          },
        },
        {
          title: 'Expira Em (dias)',
          key: 'expiraEmDias',
          dataIndex: 'expiraEmDias',
          sorter: (a: ExpiringEntityDocumentsResponseType, b: ExpiringEntityDocumentsResponseType) => {
            const aValue = a.expiraEmDias;
            const bValue = b.expiraEmDias;
            if (aValue === bValue) {
              return 0;
            }
            return aValue > bValue ? 1 : -1;
          },
        },
      ],
    },
  ],
]);

const ChartCard = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [result, setResult] = useState<{
    data: ChartType | null;
    loaded: boolean;
    error: string | null;
  }>({
    data: null,
    loaded: false,
    error: null,
  });

  const [loading, setLoading] = useState(false);

  const buildChart = async () => {
    let chart: ChartType = null;

    let data;
    const chartData: any[] = [];

    const { item } = props;
    switch (item) {
      case DashboardItem.MeusEventos:
        data = await dashboardService.myEvents({
          ...props.filter,
          item,
        });

        chart = {
          plot: 'Pie',
          item,
          data,
          chartData: data.reduce((res: any, value) => {
            if (!res[value.eventoStatus]) {
              res[value.eventoStatus] = {
                eventoStatus: value.eventoStatus,
                total: 0,
              };
              chartData.push(res[value.eventoStatus]);
            }
            res[value.eventoStatus].total += 1;
            return res;
          }, {}),
          config: {
            appendPadding: 10,
            data: chartData,
            angleField: 'total',
            colorField: 'eventoStatus',
            radius: 1,
            innerRadius: 0.64,
            label: {
              type: 'inner',
              offset: '-50%',
              style: {
                textAlign: 'center',
              },
              autoRotate: false,
              content: '{value}\n{percentage}',
            },
            statistic: {
              title: {
                offsetY: -4,
                content: 'Total',
                style: {
                  fontSize: props.height ? props.height / 20 : undefined,
                },
              },
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
              {
                type: 'pie-statistic-active',
              },
            ],
            autoFit: true,
          } as PieConfig,
        };

        break;
      case DashboardItem.EventosAlerta:
        data = await dashboardService.pendingEvents({
          ...props.filter,
          item,
        });

        chart = {
          plot: 'Column',
          item,
          data,
          chartData: data.reduce((res: any, value) => {
            const key = `${value.eventoAudiencia}|${value.eventoTipo}`;
            if (!res[key]) {
              res[key] = {
                eventoAudiencia: value.eventoAudiencia,
                eventoTipo: value.eventoTipo,
                total: 0,
              };
              chartData.push(res[key]);
            }
            res[key].total += 1;
            return res;
          }, {}),
          config: {
            data: chartData,
            xField: 'eventoAudiencia',
            yField: 'total',
            isStack: true,
            seriesField: 'eventoTipo',
            label: {
              position: `middle`,
            },
            legend: {
              position: 'right',
            },
          } as ColumnConfig,
        };

        break;
      case DashboardItem.ContratosExpiracao:
      case DashboardItem.PropostasExpiracao:
      case DashboardItem.EntidadeDocumentosExpiracao:
        if (item === DashboardItem.ContratosExpiracao) {
          data = await dashboardService.expiringContracts({
            ...props.filter,
            item,
          });
        } else if (item === DashboardItem.PropostasExpiracao) {
          data = await dashboardService.expiringProposals({
            ...props.filter,
            item,
          });
        } else {
          data = await dashboardService.expiringEntityDocuments({
            ...props.filter,
            item,
          });
        }

        chart = {
          plot: 'Rose',
          item,
          data,
          chartData: (data as any).reduce((res: any, value: any) => {
            const dateDiff = value.expiraEmDias;
            let key = '';
            if (dateDiff < 0) {
              key = 'Expirado(s)';
            } else if (lodash.inRange(dateDiff, 0, 15)) {
              key = '0-15 dias';
            } else if (lodash.inRange(dateDiff, 15, 30)) {
              key = '15-30 dias';
            } else if (lodash.inRange(dateDiff, 30, 60)) {
              key = '30-60 dias';
            } else if (lodash.inRange(dateDiff, 60, 90)) {
              key = '60-90 dias';
            } else {
              key = '90+ dias';
            }

            if (!res[key]) {
              res[key] = {
                periodo: key,
                total: 0,
              };
              chartData.push(res[key]);
            }
            res[key].total += 1;
            return res;
          }, {}),
          config: {
            data: chartData.sort((a, b) => {
              return a.periodo.localeCompare(b.periodo);
            }),
            xField: 'periodo',
            yField: 'total',
            seriesField: 'periodo',
            radius: 0.9,
            label: {
              offset: -15,
              formatter: (value: any) => {
                return value.total;
              },
            },
            interactions: [
              {
                type: 'element-active',
              },
            ],
          } as RoseConfig,
        };

        break;
      default:
        break;
    }

    return chart;
  };

  const load = async (resolve: any) => {
    let tempResult;
    try {
      setLoading(true);
      tempResult = { data: await buildChart(), loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      tempResult = { data: null, loaded: true, error: message };
    } finally {
      try {
        sessionStorage.setItem(
          `${SessionStorageKey.DashboardGeneral}-${props.item}`,
          JSON.stringify(tempResult)
        );
      } catch (error) {}

      setResult(tempResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    try {
      const cacheResult = JSON.parse(
        sessionStorage.getItem(`${SessionStorageKey.DashboardGeneral}-${props.item}`) || 'null'
      );
      if (cacheResult) {
        setResult(cacheResult);
      }
    } catch (error) {}
  }, []);

  useImperativeHandle(ref, () => ({
    load() {
      return new Promise((resolve) => {
        load(resolve);
      });
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Dashboard.color}
          label={props.title}
          text={props.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Dashboard.color}
            leftIcon={<ITable size={14} />}
            hidden={loading || (result.data?.data || []).length === 0 || !props.exportable}
            onClick={() => {
              modals.openModal({
                title: `Dashboard - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Dashboard.color}
                      label={props.title}
                      text={props.description}
                    />
                    <Space h="xs" />

                    <Table
                      showSorterTooltip={false}
                      size="small"
                      dataSource={result.data?.data}
                      columns={chartMap.get(result.data!.item)!.columns as any}
                      rowKey={chartMap.get(result.data!.item)!.rowKey}
                      pagination={{
                        pageSizeOptions: [10, 25, 50],
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                      }}
                    />
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          {/* <Button color="accent" leftIcon={<Reload size={18} />} loading={loading} onClick={load}>
              Atualizar
            </Button> */}
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} overlayBlur={3} />

          {result.error ? (
            <Stack spacing="md" align="center">
              <PlugConnectedX color="red" size={32} />
              <Text>{result.error}</Text>
            </Stack>
          ) : result.loaded ? (
            (result.data?.data || []).length > 0 ? (
              <div>
                {result.data!.plot === 'Pie' ? (
                  <Pie {...result.data!.config} height={props.height} />
                ) : result.data!.plot === 'Column' ? (
                  <Column {...result.data!.config} height={props.height} />
                ) : (
                  <Rose {...result.data!.config} height={props.height} />
                )}
              </div>
            ) : (
              <Stack spacing="md" align="center">
                <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Sem dados para mostrar.</Text>
              </Stack>
            )
          ) : (
            <Stack spacing="md" align="center">
              <Reload color={theme?.colors?.primary?.[6]} size={32} />
              <Text>Esperando atualização</Text>
            </Stack>
          )}
        </div>
      </Paper>
    </Paper>
  );
});

export default ChartCard;
