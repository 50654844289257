/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { Badge, Button, Group, Modal, Paper, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Plus } from 'tabler-icons-react';
import {
  AudienciaCodigo,
  Relacao,
  RelacaoCodigo,
  Status,
  TipoCodigo,
} from '../../../../../business/events/general';
import EventSearch from '../../../../../components/core/EventSearch/EventSearch';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  CompanyType,
  DocumentType,
  EventRelationTypeData,
  EventStatusType,
} from '../../../../../models/core/cache.type';
import { Action } from '../../../../../models/core/core.type';
import {
  EventRelationType,
  EventSearchResponseType,
  EventType,
} from '../../../../../models/core/events.type';
import { UserType } from '../../../../../models/core/users.type';
import { Feature, SessionStorageKey } from '../../../../../utils/constants.utils';
import { formatDateStringToDate, formatTimestampToString } from '../../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid, tryGetDateISOFormat } from '../../../../../utils/helper.utils';
import RelationFormViewActions from './RelationFormView/RelationFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    eventRelationTypeData: EventRelationTypeData[];
    companyData: CompanyType[];
    eventStatusData: EventStatusType[];
    userData: UserType[];
    documentTypeData: DocumentType[];
  };
  event: EventType | null;
};

type ModalData = {
  opened: boolean;
  items: EventRelationType[];
  type: Relacao | null;
  action: string;
};

const RelationFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const tempEvento = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEvent) || 'null');
  const [data, setData] = useState<EventRelationType[]>(
    tempEvento
      ? [
          JSON.parse(
            JSON.stringify({
              action: Action.Add,
              idEvento: null,
              idEventoReferenciado: tempEvento?.idEvento,
              codigoEventoRelacao: Relacao.Importado,
              eventoRelacao: RelacaoCodigo.IMP,
              eventoReferenciadoCodigoEventoAudiencia: tempEvento.codigoEventoAudiencia,
              eventoReferenciadoAudiencia: tempEvento.eventoAudiencia,
              eventoReferenciadoCodigoEventoTipo: tempEvento.codigoEventoTipo,
              eventoReferenciadoEventoTipo: tempEvento.eventoTipo,
              eventoReferenciadoCodigoEventoStatus: tempEvento.codigoEventoStatus,
              eventoReferenciadoEventoStatus: tempEvento.eventoStatus,
              eventoReferenciadoCodigoEventoReferencia: tempEvento.codigoEventoReferencia,
              eventoReferenciadoEventoReferencia: tempEvento.eventoReferencia,
            })
          ),
        ]
      : props.event?.relacoes?.map((x: EventRelationType) => {
          return {
            ...x,
            action: x.action || Action.Nothing,
            id: x.idEventoRelacao?.toString() || x.idTemporaria || newGuid(),
          };
        }) || []
  );

  // const [selectedData, setSelectedData] = useState<EventRelationType[]>([]);
  const [modalData, setModalData] = useState<ModalData>({
    opened: false,
    items: [],
    type: null,
    action: '',
  });

  const columns: ColumnsType<any> = [
    // {
    //   title: '#',
    //   key: 'idEvento',
    //   sorter: (a: EventRelationType, b: EventRelationType) => {
    //     const aValue = a.idEvento;
    //     const bValue = b.idEvento;
    //     if (aValue === bValue) {
    //       return 0;
    //     }
    //     return aValue > bValue ? 1 : -1;
    //   },
    //   render: (row: EventRelationType) => row.idEvento,
    // },
    {
      title: 'Tipo',
      key: 'codigoEventoRelacao',
      sorter: (a: EventRelationType, b: EventRelationType) =>
        a.codigoEventoRelacao.localeCompare(b.codigoEventoRelacao),
      render: (row: EventRelationType) => <Badge variant="outline">{row.eventoRelacao}</Badge>,
    },
    {
      title: 'Evento',
      key: 'idEventoReferenciado',
      sorter: (a: EventRelationType, b: EventRelationType) =>
        a.eventoReferenciadoCodigoEventoTipo.localeCompare(b.eventoReferenciadoCodigoEventoTipo),
      render: (row: EventRelationType) => {
        const idEvento = props.event?.idEvento === row.idEvento ? row.idEventoReferenciado : row.idEvento;
        const eventoStatus =
          props.event?.idEvento === row.idEvento ? row.eventoReferenciadoEventoStatus : row.eventoStatus;
        const eventoTipo =
          props.event?.idEvento === row.idEvento ? row.eventoReferenciadoEventoTipo : row.eventoTipo;

        return idEvento ? (
          <ProfileCardLink
            id={idEvento.toString()}
            name={`# ${idEvento}`}
            nameSize="sm"
            avatar="E"
            description={`${eventoStatus} | ${eventoTipo}`}
            descriptionSize="xs"
            linkPrefix="events"
            showLink
          />
        ) : (
          // Importado
          <ProfileCardLink
            id={row.idEventoReferenciado.toString()}
            name={`# ${row.idEventoReferenciado}`}
            nameSize="sm"
            avatar="E"
            description={`${row.eventoReferenciadoEventoStatus} | ${row.eventoReferenciadoEventoTipo}`}
            descriptionSize="xs"
            linkPrefix="events"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: EventRelationType, b: EventRelationType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: EventRelationType) => {
        if (row.idCriadoPor) {
          return <ProfileCardLink id={row.idCriadoPor || ''} name={row.criadoPor || ''} nameSize="sm" />;
        }
        return '-';
      },
    },
    {
      title: 'Data Criação',
      key: 'dataCriacao',
      sorter: (a: EventRelationType, b: EventRelationType) => {
        const date = new Date();
        return new Date(a.dataCriacao || date).valueOf() - new Date(b.dataCriacao || date).valueOf();
      },
      render: (row: EventRelationType) => {
        if (row.dataCriacao) {
          return formatTimestampToString(row.dataCriacao);
        }
        return '-';
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EventRelationType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <RelationFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    items: EventRelationType[],
    type: Relacao,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex: number;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === items[0]?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEventoRelacao) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'adicionar':
        setModalData({ opened: true, items, type, action });
        break;
      case 'callback':
        setModalData({ opened: false, items: [], type: null, action: '' });
        if (items.length > 0) {
          setData(data.concat(items));
        }
        break;
      default:
        break;
    }
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: EventRelationType[]) => {
  //     setSelectedData(selectedRows);
  //   },
  //   getCheckboxProps: (record: EventRelationType) => ({
  //     name: record.id || record.idEventoRelacao?.toString(),
  //     disabled: record.codigoEventoRelacao !== Relacao.Relacionado,
  //   }),
  // };

  useImperativeHandle(ref, () => ({
    validate(): EventRelationType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Modal
        opened={modalData.opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setModalData({ opened: false, items: [], type: null, action: '' })}
        title="Evento/Relações - Adicionar"
        size="80%"
      >
        <EventSearch
          referenceData={props.referenceData}
          idsToBeDisabled={[
            ...(data
              .filter(
                (x) => x.codigoEventoRelacao === modalData.type && (x.action !== Action.Delete || !x.action)
              )
              .map((y) =>
                y.idEventoReferenciado === props.event?.idEvento ? y.idEvento : y.idEventoReferenciado
              ) ?? []),
            props.event?.idEvento ?? -1,
          ]}
          filterData={{
            idContrato: props.event?.idContrato ?? undefined,
            idEntidade: props.event?.idEntidade ?? undefined,
            idProposta: props.event?.idProposta ?? undefined,
            dataInicial:
              modalData.type === Relacao.VeiculoCompartilhado
                ? formatDateStringToDate(tryGetDateISOFormat(props.event?.data ?? new Date()))
                : undefined,
            dataFinal:
              modalData.type === Relacao.VeiculoCompartilhado
                ? formatDateStringToDate(tryGetDateISOFormat(props.event?.data ?? new Date()))
                : undefined,
            listaAudiencia:
              modalData.type === Relacao.VeiculoCompartilhado ? [AudienciaCodigo.Operacional] : [],
            listaTipo: modalData.type === Relacao.VeiculoCompartilhado ? [TipoCodigo.Coleta] : [],
            listaStatus:
              modalData.type === Relacao.VeiculoCompartilhado ? [Status.Pendente, Status.Agendado] : [],
            codigoEventoRelacao: modalData.type!,
          }}
          callback={(items: EventSearchResponseType[]) => {
            confirmActionResult(
              items.map((x) => {
                return {
                  ...buildFakeAuditObject(),
                  id: newGuid(),
                  action: Action.Add,
                  idEventoRelacao: null,
                  idTemporaria: newGuid(),
                  codigoEventoRelacao: modalData.type,
                  eventoRelacao: RelacaoCodigo[modalData.type!],
                  idEvento: props.event?.idEvento,
                  codigoEventoAudiencia: props.event?.codigoEventoAudiencia,
                  codigoEventoTipo: props.event?.codigoEventoTipo,
                  codigoEventoStatus: props.event?.codigoEventoStatus,
                  codigoEventoReferencia: props.event?.codigoEventoReferencia,
                  idEventoReferenciado: x.idEvento,
                  eventoReferenciadoCodigoEventoAudiencia: x.codigoEventoAudiencia,
                  eventoReferenciadoEventoAudiencia: x.eventoAudiencia,
                  eventoReferenciadoCodigoEventoTipo: x.codigoEventoTipo,
                  eventoReferenciadoEventoTipo: x.eventoTipo,
                  eventoReferenciadoCodigoEventoStatus: x.codigoEventoStatus,
                  eventoReferenciadoEventoStatus: x.eventoStatus,
                  eventoReferenciadoCodigoEventoReferencia: x.codigoEventoReferencia,
                  eventoReferenciadoEventoReferencia: x.eventoReferencia,
                  idCriadoPor: null,
                  dataCriacao: null,
                };
              }),
              modalData.type!,
              'callback',
              true
            );
            setModalData({ opened: false, items: [], type: null, action: '' });
          }}
          save={null}
        />
      </Modal>

      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Relações"
          text="Eventos relacionados."
        />
        <Group>
          <Button
            color="primary"
            leftIcon={<Plus size={18} />}
            onClick={() => {
              confirmActionResult([], Relacao.Relacionado, 'adicionar', true);
            }}
          >
            Adicionar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        // rowSelection={{ type: 'checkbox', ...rowSelection }}
        rowKey={(item: EventRelationType) => item.id || item.idEventoRelacao || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </Paper>
  );
});

export default RelationFormView;
