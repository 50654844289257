import {
  ContractDeleteType,
  ContractSearchRequestType,
  ContractSearchResponseType,
  ContractSelectType,
  ContractStatusType,
  ContractType,
} from '../../models/core/contracts.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { call } from '../core.service';

const entitysEndpoint = '/v1/contratos';

export default class ContractsService {
  static search = async (searchProps: ContractSearchRequestType): Promise<ContractSearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: entitysEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<ContractSearchResponseType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: ContractSelectType): Promise<ContractType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${entitysEndpoint}/${selectProps.idContrato}`,
    };
    return (await call(request))?.data?.data;
  };

  static delete = async (deleteProps: ContractDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${entitysEndpoint}/${deleteProps.idContrato}`,
    };
    return call(request);
  };

  static updateStatus = async (
    updateStatusProps: ContractStatusType
  ): Promise<{ idContrato: number | null }> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${entitysEndpoint}/${updateStatusProps.idContrato}/status`,
      data: updateStatusProps,
      timeout: 60000,
    };
    return (await call(request))?.data?.data;
  };
}
