import { useLocalStorage } from '@mantine/hooks';
import { UserType } from '../models/core/users.type';
import authService from '../services/iam/auth.service';
import { LocalStorageKey } from '../utils/constants.utils';
import { decrypt } from '../utils/helper.utils';

const useCurrentUser = (): [UserType] => {
  const [currentUser] = useLocalStorage<UserType>({
    key: LocalStorageKey.User,
    getInitialValueInEffect: false,
  });
  try {
    return [JSON.parse(decrypt(currentUser as any))];
  } catch (error) {
    // workaround to avoid users to clean up cache after encrypting this data
    Promise.resolve(authService.signOut()).finally(() => {
      window.location.reload();
    });
    throw error;
  }
};

export default useCurrentUser;
