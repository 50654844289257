import { call } from '../core.service';
import {
  DepartmentUpdateType,
  DepartmentDeleteType,
  DepartmentInsertType,
  DepartmentSearchType,
  DepartmentSelectType,
  DepartmentType,
} from '../../models/core/departments.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const departmentsEndpoint = '/v1/departamentos';

export default class DepartmentsService {
  static search = async (searchProps: DepartmentSearchType): Promise<DepartmentType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: departmentsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (listarPermissoes: boolean): Promise<DepartmentType[]> => {
    return this.search({ listarPermissoes, limit: 1000, offset: 0 });
  };

  static select = async (selectProps: DepartmentSelectType): Promise<DepartmentType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${departmentsEndpoint}/${selectProps.idDepartamento}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: DepartmentUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${departmentsEndpoint}/${updateProps.idDepartamento}`,
      data: updateProps,
    };
    return call(request);
  };

  static delete = async (deleteProps: DepartmentDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${departmentsEndpoint}/${deleteProps.idDepartamento}`,
    };
    return call(request);
  };

  static insert = async (insertProps: DepartmentInsertType): Promise<DepartmentType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: departmentsEndpoint,
      data: insertProps,
    };
    return (await call(request))?.data?.data;
  };
}
