/* eslint-disable react/destructuring-assignment */
import { Grid, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { Activity, ChartInfographic, Id, ReportSearch } from 'tabler-icons-react';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import { ContractType } from '../../../../../models/core/contracts.type';
import { Feature } from '../../../../../utils/constants.utils';
import ContractSingleMeasurement from './components/Charts/ContractSingleMeasurement';
import ContractSingleEquipament from './components/Charts/ContractSingleEquipment';
import ContractSingleEstimates from './components/Charts/ContractSingleEstimates';
import ContractSinglePackaging from './components/Charts/ContractSinglePackaging';
import ContractSingleService from './components/Charts/ContractSingleSupplier';
import GeneralView from './components/GeneralView';
import StatusView from './components/StatusView';
import Statement from '../../../../home/Dashboard/Charts/Managerial/Shared/Statement';
import ProposalView from './components/ProposalView';

type DataViewProps = {
  data: ContractType;
};

export default function DataView(props: DataViewProps) {
  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Contract.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Contract.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <GeneralView data={props.data} />
          </Paper>
          <Space h="lg" />

          <PageViewAudit
            idCriadoPor={props.data.idCriadoPor}
            criadoPor={props.data.criadoPor}
            dataCriacao={props.data.dataCriacao}
            idModificadoPor={props.data?.idModificadoPor || null}
            modificadoPor={props.data?.modificadoPor || null}
            dataModificacao={props.data?.dataModificacao || null}
            size="md"
          />
        </div>
      ),
      forceRender: true,
    },
    {
      key: 'Propostas',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Contract.color} variant="outline">
            <ReportSearch size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Contract.color} weight={500}>
            Propostas
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ProposalView data={props.data.propostas} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Estatísticas',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Contract.color} variant="outline">
            <ChartInfographic size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Contract.color} weight={500}>
            Estatísticas
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <ContractSingleMeasurement data={props.data} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <ContractSingleEstimates data={props.data} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={6}>
            <Grid.Col span={2}>
              <ContractSinglePackaging data={props.data} height={undefined} />
            </Grid.Col>
            <Grid.Col span={2}>
              <ContractSingleEquipament data={props.data} height={undefined} />
            </Grid.Col>
            <Grid.Col span={2}>
              <ContractSingleService data={props.data} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <Statement
                idContrato={props.data.idContrato}
                idEntidade={undefined}
                color={Feature.Home.Contract.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
        </div>
      ),
      forceRender: false,
    },
    {
      key: 'Status',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Contract.color} variant="outline">
            <Activity size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Contract.color} weight={500}>
            Status
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <StatusView data={props.data} />
        </Paper>
      ),
      forceRender: true,
    },
  ];

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Tabs
        items={tabs}
        defaultActiveKey="Geral"
        className="custom-ant-tabs-nav-list custom-ant-tabs-nav-list-3"
      />
    </Paper>
  );
}
