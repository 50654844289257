/* eslint-disable react/destructuring-assignment */
import { Notes, Target } from 'tabler-icons-react';
import { Paper, SimpleGrid, Space, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DestinationType } from '../../../../../models/core/destinations.type';
import { Feature } from '../../../../../utils/constants.utils';
import PageSection from '../../../../../components/core/PageSection/PageSection';

type FormViewData = {
  idResiduoDestinoFinal: number | null;
  residuoDestinoFinal: string;
  descricao: string | null;
};

type FormViewProps = {
  save(data: FormViewData): void;
  destination: DestinationType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      idResiduoDestinoFinal: props.destination?.idResiduoDestinoFinal || null,
      residuoDestinoFinal: props.destination?.residuoDestinoFinal || '',
      descricao: props.destination?.descricao || '',
    },
    validate: {
      residuoDestinoFinal: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = async (formData: FormViewData) => {
    props.save({
      ...formData,
      residuoDestinoFinal: formData.residuoDestinoFinal.trim(),
      descricao: formData.descricao?.trim() || null,
    });
  };

  return (
    <form id="destination-add-edit" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Destination.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <TextInput
            icon={<Target size={15} />}
            label="Destino Final"
            placeholder="Nome do destino final"
            maxLength={50}
            required
            {...form.getInputProps('residuoDestinoFinal')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <Textarea
          icon={<Notes size={15} />}
          label="Descrição"
          placeholder="Descrição do destino final"
          autosize
          maxLength={500}
          {...form.getInputProps('descricao')}
        />
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
