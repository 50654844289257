/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Group, Paper, Space, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CalendarEvent, Certificate, Check, Eye, Id, Schema, Share, X } from 'tabler-icons-react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import RecurrenceView from '../../../../../../components/core/Recurrence/RecurrenceView';
import { UnitOfMeasureType } from '../../../../../../models/core/cache.type';
import { ProposalResidueType, ProposalServiceType } from '../../../../../../models/core/proposals.type';
import theme from '../../../../../../theme';
import { montarRecorrenciaString } from '../../../../../../utils/business.utils';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../utils/formatter.utils';
import ResiduePlanQuotationSummary from '../../../components/ResiduePlanQuotationSummary';
import ResidueDocumentView from './ResidueView/ResidueDocumentView';
import ResidueGeneralView from './ResidueView/ResidueGeneralView';
import ResiduePlanView from './ResidueView/ResiduePlanView';

type ViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  data: ProposalResidueType[];
  services: ProposalServiceType[];
};

export default function ResidueView(props: ViewProps) {
  const modals = useModals();

  const isResiduePlanQuotationComplete = (row: ProposalResidueType) => {
    let isComplete = false;
    const primaryPlan = row.planos.find((x) => x.primario);
    if (primaryPlan) {
      isComplete = primaryPlan.cotacoes.some((x) => x.primario);
    }
    return { primaryPlan, isComplete };
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Resíduo',
      key: 'residuo',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
        a.residuoCliente.localeCompare(b.residuoCliente),
      render: (row: ProposalResidueType) => {
        return (
          <ProfileCardLink
            id={row.idResiduo.toString()}
            name={row.residuoCliente}
            nameSize="sm"
            description={`Código IBAMA: ${row.codigoIBAMA || '-'} | Perigoso: ${
              row.residuoClientePerigoso ? 'Sim' : 'Não'
            }`}
            descriptionSize="xs"
            linkPrefix="residues"
            showLink
          />
        );
      },
    },
    {
      title: 'Mínimo Aceitável',
      key: 'minimoAceitavel',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
        const aItem = a.minimoAceitavel
          ? `${a.minimoAceitavel} ${a.minimoAceitavelUnidadeMedida1} / ${a.minimoAceitavelUnidadeMedida2}`
          : '';
        const bItem = a.minimoAceitavel
          ? `${b.minimoAceitavel} ${b.minimoAceitavelUnidadeMedida1} / ${b.minimoAceitavelUnidadeMedida2}`
          : '';
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalResidueType) => {
        if (!row.minimoAceitavel) {
          return '-';
        }
        return (
          <div>{`${row.minimoAceitavel} ${row.minimoAceitavelUnidadeMedida1} / ${row.minimoAceitavelUnidadeMedida2}`}</div>
        );
      },
    },
    {
      title: 'Estimativa',
      key: 'estimativa',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
        const aItem = `${a.estimativa} ${a.estimativaUnidadeMedida1} / ${a.estimativaUnidadeMedida2}`;
        const bItem = `${b.estimativa} ${b.estimativaUnidadeMedida1} / ${b.estimativaUnidadeMedida2}`;
        return aItem.localeCompare(bItem);
      },
      render: (row: ProposalResidueType) => {
        return (
          <div>{`${row.estimativa} ${row.estimativaUnidadeMedida1} / ${row.estimativaUnidadeMedida2}`}</div>
        );
      },
    },
    {
      title: 'Recorrência',
      key: 'recorrencia',
      render: (row: ProposalResidueType) => {
        const recorrencia = montarRecorrenciaString(row.recorrencia);
        if (!recorrencia) {
          return '-';
        }
        return (
          <div>
            <div>{recorrencia.str1}</div>
            {recorrencia.str2 ? <div>{recorrencia.str2}</div> : <></>}
          </div>
        );
      },
    },
    {
      title: 'Data Inicial',
      key: 'dataInicial',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
        new Date(a.recorrencia.dataInicial || a.dataInicial).valueOf() -
        new Date(b.recorrencia.dataInicial || b.dataInicial).valueOf(),
      render: (row: ProposalResidueType) =>
        formatDateToString(row.recorrencia.dataInicial || row.dataInicial),
    },
    {
      title: 'Data Final',
      key: 'dataFinal',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
        new Date(a.recorrencia.dataFinal || a.dataFinal).valueOf() -
        new Date(b.recorrencia.dataFinal || b.dataFinal).valueOf(),
      render: (row: ProposalResidueType) => formatDateToString(row.recorrencia.dataFinal || row.dataFinal),
    },
    {
      title: 'Planos',
      key: 'planos',
      sorter: (a: ProposalResidueType, b: ProposalResidueType) => {
        const aValue = a.planos.length;
        const bValue = b.planos.length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResidueType) => {
        const completePlan = isResiduePlanQuotationComplete(row);
        return (
          <Group spacing="xs">
            <div>{row.planos.length || '-'}</div>
            <div>{completePlan.isComplete ? <Check color="green" /> : <X color="red" />}</div>
            {completePlan?.primaryPlan?.acondicionamentos?.find((x) => x.compartilhado) && (
              <div>
                <Share size={16} />
              </div>
            )}
          </Group>
        );
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResidueType) => {
        const tabs = [
          {
            key: 'Geral',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <Id size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Geral
                </Text>
              </Group>
            ),
            children: <ResidueGeneralView data={row} />,
            forceRender: true,
          },
          {
            key: 'Documentos',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <Certificate size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Documentos
                </Text>
              </Group>
            ),
            children: <ResidueDocumentView data={row} />,
            forceRender: true,
          },
          {
            key: 'Recorrencia',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <CalendarEvent size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Recorrência
                </Text>
              </Group>
            ),
            children: (
              <RecurrenceView
                title="Recorrência"
                description="Eventos personalizados a serem gerados para este resíduo."
                color={Feature.Home.Proposal.color}
                data={row.recorrencia}
              />
            ),
            forceRender: true,
          },
          {
            key: 'Planos',
            label: (
              <Group>
                <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
                  <Schema size={18} />
                </ThemeIcon>
                <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
                  Planos
                </Text>
              </Group>
            ),
            children: (
              <Paper shadow="xs" p="md" withBorder>
                <ResiduePlanView data={row} referenceData={props.referenceData} services={props.services} />
              </Paper>
            ),
            forceRender: true,
          },
        ];

        return (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
            <ActionIcon
              size="sm"
              color="primary"
              style={{ borderColor: theme?.colors?.primary?.[6] }}
              variant="outline"
              onClick={() => {
                modals.openModal({
                  title: `Resíduo - Visualizar (#${row.idPropostaResiduo})`,
                  size: '70%',
                  closeOnClickOutside: false,
                  children: (
                    <Paper shadow="xs" p="md" withBorder>
                      <Tabs items={tabs} defaultActiveKey="Geral" />
                    </Paper>
                  ),
                });
              }}
            >
              <Eye size={15} color={theme?.colors?.primary?.[6]} />
            </ActionIcon>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Resíduos"
          text="Todos os resíduos a serem gerenciados e requisitados pelo cliente juntamente com seus planos de coleta, tratamento e/ou destinação e cotações."
        />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ProposalResidueType) => item.idPropostaResiduo || 0}
        expandable={{
          rowExpandable: (row) => isResiduePlanQuotationComplete(row).isComplete,
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (row) => {
            return (
              <ResiduePlanQuotationSummary data={row.planos.find((x) => x.primario)!} quotation={null} />
            );
          },
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
