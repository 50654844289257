/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Group, Modal, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Check, Plus, X } from 'tabler-icons-react';
import { validarReferenciaServicoCompartilhado } from '../../../../../../../../../../../business/proposals/validation';
import PageSection from '../../../../../../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { useProposalResiduePlanContext } from '../../../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import { UnitOfMeasureType } from '../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanPackagingType,
  ProposalResiduePlanType,
  ProposalServiceType,
} from '../../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../../utils/constants.utils';
import { newGuid } from '../../../../../../../../../../../utils/helper.utils';
import ResiduePlanPackagingFormAddEdit from './ResiduePlanPackagingForm/ResiduePlanPackagingFormAddEdit';
import ResiduePlanPackagingFormViewActions from './ResiduePlanPackagingForm/ResiduePlanPackagingFormViewActions';

type ProposalFormViewProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalResiduePlan: ProposalResiduePlanType | null;
  idEntidadeEndereco: number;
  services: ProposalServiceType[];
};

const ResiduePlanPackagingFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalResiduePlanData, setProposalResiduePlanData } = useProposalResiduePlanContext();

  const [data, setData] = useState<ProposalResiduePlanPackagingType[]>(
    props.proposalResiduePlan?.acondicionamentos.map((x: ProposalResiduePlanPackagingType) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idPropostaResiduoPlanoAcondicionamento?.toString() || x.idTemporaria || newGuid(),
      };
    }) || []
  );

  const [packagingItem, setPackagingItem] = useState<ProposalResiduePlanPackagingType | null>(null);
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanPackagingType> = [
    {
      title: 'Acondicionamento',
      key: 'residuoAcondicionamento',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) =>
        a.residuoAcondicionamento.localeCompare(b.residuoAcondicionamento),
      render: (row: ProposalResiduePlanPackagingType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoAcondicionamento?.toString() || ''}
            name={row.residuoAcondicionamento || ''}
            nameSize="sm"
            description={row.residuoAcondicionamentoDescricao}
            descriptionSize="xs"
            linkPrefix="packaging"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) => {
        const aValue = a.compartilhado ? a.quantidade / 100 : a.quantidade;
        const bValue = b.compartilhado ? b.quantidade / 100 : b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanPackagingType) => {
        if (!row.compartilhado) {
          return <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>;
        }

        const servico = props.services.find(
          (x) =>
            (row.idPropostaServico && x.idPropostaServico === row.idPropostaServico) ||
            (row.idPropostaServicoTemp && x.id === row.idPropostaServicoTemp)
        );

        if (
          validarReferenciaServicoCompartilhado(
            row.idResiduoAcondicionamento,
            props.idEntidadeEndereco,
            servico
          )
        ) {
          return <div>{`% de ${servico?.quantidade} ${servico?.frequenciaUnidadeMedida1}`}</div>;
        }
        return <div style={{ color: 'red' }}>?</div>;
      },
    },
    {
      title: 'Gerenciar?',
      key: 'gerenciar',
      dataIndex: 'gerenciar',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) =>
        a.gerenciar === b.gerenciar ? 0 : a.gerenciar ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Serviço Compartilhado',
      key: 'servico',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) => {
        return (a.idPropostaServicoTemp || a.idPropostaServico || '')
          .toString()
          .localeCompare((b.idPropostaServicoTemp || b.idPropostaServico || '').toString());
      },
      render: (row: ProposalResiduePlanPackagingType) => {
        if (!row.compartilhado) {
          return '-';
        }

        const servico = props.services.find(
          (x) =>
            (row.idPropostaServico && x.idPropostaServico === row.idPropostaServico) ||
            (row.idPropostaServicoTemp && x.id === row.idPropostaServicoTemp)
        );

        if (
          validarReferenciaServicoCompartilhado(
            row.idResiduoAcondicionamento,
            props.idEntidadeEndereco,
            servico
          )
        ) {
          return (
            <ProfileCardLink
              id={(row.id || row.idPropostaResiduoPlanoAcondicionamento || '-').toString()}
              name={servico!.servico}
              nameSize="sm"
              description={`${servico!.quantidade} ${servico!.frequenciaUnidadeMedida1} / ${
                servico!.frequenciaUnidadeMedida2
              }`}
              descriptionSize="xs"
              linkPrefix="packaging"
              showLink={false}
            />
          );
        }

        return <div style={{ color: 'red' }}>Pendente de correção</div>;
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanPackagingType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ResiduePlanPackagingFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    item: ProposalResiduePlanPackagingType | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduoPlanoAcondicionamento) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setPackagingItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }

    setProposalResiduePlanData({ ...proposalResiduePlanData, acondicionamentos: data });
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanPackagingType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Plano/Acondicionamento - ${packagingItem ? 'Editar' : 'Adicionar'}`}
        size="50%"
      >
        <ResiduePlanPackagingFormAddEdit
          referenceData={props.referenceData}
          origItem={
            packagingItem
              ? props.proposalResiduePlan?.acondicionamentos?.find(
                  (x) =>
                    x.idPropostaResiduoPlanoAcondicionamento ===
                      packagingItem.idPropostaResiduoPlanoAcondicionamento && x.idPropostaResiduoPlano !== -1
                ) || null
              : null
          }
          item={packagingItem}
          idPropostaResiduoPlano={props.proposalResiduePlan?.idPropostaResiduoPlano}
          idEntidadeEndereco={props.idEntidadeEndereco}
          services={props.services}
          callback={confirmActionResult}
        />
      </Modal>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Acondicionamentos"
          text="Acondicionamentos para este plano."
        />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: ProposalResiduePlanPackagingType) =>
          item.id || item.idPropostaResiduoPlanoAcondicionamento || 0
        }
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ResiduePlanPackagingFormView;
