import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import PageContent from '../../../../components/core/PageContent/PageContent';
import vehiclesVehicle from '../../../../services/core/vehicles.service';
import { FormView, FormViewData } from './components/FormView';
import { VehicleType } from '../../../../models/core/vehicles.type';
import { Feature } from '../../../../utils/constants.utils';
import { Permission } from '../../../../models/core/departments.type';

type DataResult = {
  loading: boolean;
  vehicleData: VehicleType | null;
};

function VehicleAddEdit() {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { idResiduoVeiculo } = useParams();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: (!!idResiduoVeiculo && !location?.state?.vehicle) || false,
    vehicleData: location?.state?.vehicle || null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { vehicleData } = dataResult;
      try {
        vehicleData = await vehiclesVehicle.select({
          idResiduoVeiculo: Number(idResiduoVeiculo),
        });
      } catch (error: any) {
        showNotification({
          title: `Veículo - ${!idResiduoVeiculo ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar o veículo.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, vehicleData });
      }
    };

    if (idResiduoVeiculo && dataResult.vehicleData === null) {
      fetchData();
    }
  }, [idResiduoVeiculo, navigate, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      let newModel;
      if (!idResiduoVeiculo) {
        newModel = await vehiclesVehicle.insert({
          ...inputFormViewData,
        });
        navigate('/vehicles');
      } else {
        await vehiclesVehicle.update({
          ...inputFormViewData,
          idResiduoVeiculo: Number(idResiduoVeiculo),
        });
      }
      showNotification({
        title: `Veículo - ${!idResiduoVeiculo ? 'Adicionar' : 'Editar'}`,
        message: 'Veículo salvo com sucesso.',
        color: 'green',
      });
      navigate(`/vehicles/${newModel?.idResiduoVeiculo || idResiduoVeiculo}`, {
        state: {
          vehicle: newModel || null,
        },
      });
    } catch (error: any) {
      showNotification({
        title: `Veículo - ${!idResiduoVeiculo ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar o veículo.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Reference.Vehicle}
        title={`Veículo - ${!idResiduoVeiculo ? 'Adicionar' : 'Editar'}`}
        description={`${!idResiduoVeiculo ? 'Adicione' : 'Edite'} um veículo.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="vehicle-add-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            data-permission={!idResiduoVeiculo ? Permission.VehicleAdd : Permission.VehicleEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {idResiduoVeiculo && dataResult.vehicleData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView vehicle={dataResult.vehicleData} loading={saving} save={save} />
        </PageContent>
      )}
    </Card>
  );
}

export default VehicleAddEdit;
