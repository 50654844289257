/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, SimpleGrid, Space } from '@mantine/core';
import PageViewAudit from '../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalServiceQuotationType } from '../../../../../../../../models/core/proposals.type';
import { formatCurrency } from '../../../../../../../../utils/formatter.utils';

type ViewProps = {
  data: ProposalServiceQuotationType;
};

export default function ServiceQuotationGeneralView(props: ViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={2}>
            {props.data.fornecedorCNPJ ? (
              <PageViewProperty
                label="Fornecedor"
                text={
                  <ProfileCardLink
                    id={props.data.idFornecedor.toString()}
                    name={props.data.fornecedorNomeFantasia || props.data.fornecedorRazaoSocial || '-'}
                    nameSize="sm"
                    description={
                      props.data.fornecedorNomeFantasia
                        ? props.data.fornecedorRazaoSocial
                        : props.data.fornecedorCNPJ
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Fornecedor"
                text={
                  <ProfileCardLink
                    id={props.data.idFornecedor.toString()}
                    name={props.data.fornecedorNome || '-'}
                    nameSize="sm"
                    description={props.data.fornecedorCPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Quantidade" text={props.data.quantidade.toString()} size="sm" />
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Frequência"
              text={`${
                !(props.data.idResiduoAcondicionamento || props.data.idResiduoEquipamento)
                  ? `${props.data.frequencia} `
                  : ''
              }${props.data.frequenciaUnidadeMedida1} / ${props.data.frequenciaUnidadeMedida2}`}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={5}>
          <PageViewProperty
            label="Preço"
            text={props.data.preco ? formatCurrency(props.data.preco) : '-'}
            size="sm"
          />
          <PageViewProperty label="Primário?" text={props.data.primario ? 'Sim' : 'Não'} size="sm" />
        </SimpleGrid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid cols={1}>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
        gridSize={3}
      />
    </div>
  );
}
