/* eslint-disable react/destructuring-assignment */
import { Atom, ClearAll, Qrcode, Search } from 'tabler-icons-react';
import { Button, Grid, Group, Paper, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import NumberFormat from 'react-number-format';

type FilterData = {
  residuo: string;
  codigoIBAMA: string;
};

type FilterProps = {
  filter(data: FilterData): void;
  clear(): void;
  loading: boolean;
};

function Filter(props: FilterProps) {
  const form = useForm<FilterData>({
    initialValues: {
      residuo: '',
      codigoIBAMA: '',
    },
  });

  return (
    <form onSubmit={form.onSubmit(props.filter)}>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={2}>
            <TextInput
              icon={<Atom size={15} />}
              label="Resíduo"
              description="Filtro: contém"
              placeholder="Todos..."
              maxLength={500}
              {...form.getInputProps('residuo')}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <NumberFormat
              icon={<Qrcode size={15} />}
              label="Código IBAMA"
              description="Filtro: começa com"
              placeholder="Todos..."
              mask="_"
              format="## ## ##"
              customInput={TextInput}
              {...form.getInputProps('codigoIBAMA')}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Group>
              <Button
                style={{ marginTop: 44 }}
                color="secondary"
                leftIcon={<ClearAll size={18} />}
                disabled={props.loading}
                onClick={() => {
                  form.reset();
                  props.clear();
                }}
              >
                Limpar
              </Button>
              <Button
                style={{ marginTop: 44 }}
                leftIcon={<Search size={18} />}
                type="submit"
                loading={props.loading}
              >
                Pesquisar
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h="xl" />
    </form>
  );
}

export { Filter };
export type { FilterData };
