/* eslint-disable react/destructuring-assignment */
import { Alert, Button, Group, Paper, Space, Text, ThemeIcon } from '@mantine/core';
import { Tabs } from 'antd';
import { useRef, useState } from 'react';
import { Backhoe, CircleX, DeviceFloppy, Id, Package, Recycle, Target, Truck } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../../../components/core/PageSection/PageSection';
import { useProposalResiduePlanContext } from '../../../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../models/core/core.type';
import { ProposalResiduePlanQuotationType } from '../../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../../utils/constants.utils';
import ResiduePlanDestinationQuotationFormView from './components/ResiduePlanQuotationDestinationFormView';
import ResiduePlanEquipmentQuotationFormView from './components/ResiduePlanQuotationEquipmentFormView';
import ResiduePlanQuotationGeneralFormView from './components/ResiduePlanQuotationGeneralFormView';
import ResiduePlanPackagingQuotationFormView from './components/ResiduePlanQuotationPackagingFormView';
import ResiduePlanTreatmentQuotationFormView from './components/ResiduePlanQuotationTreatmentFormView';
import ResiduePlanVehicleQuotationFormView from './components/ResiduePlanQuotationVehicleFormView';

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanQuotationType | null;
  item: ProposalResiduePlanQuotationType | null;
  idPropostaResiduoPlano: number | undefined;
  callback(item: ProposalResiduePlanQuotationType | null, action: string, confirmed: boolean): void;
};

export default function ResiduePlanQuotationFormAddEdit(props: FormViewProps) {
  const { proposalResiduePlanData } = useProposalResiduePlanContext();

  const refGeneralForm = useRef<any>();
  const refPackagingForm = useRef<any>();
  const refEquipmentForm = useRef<any>();
  const refVehicleForm = useRef<any>();
  const refTreatmentForm = useRef<any>();
  const refDestinationForm = useRef<any>();

  const [error, setError] = useState<any | null>(null);

  const handleSubmit = () => {
    let step = 'Geral';
    try {
      const generalData = refGeneralForm.current?.validate();

      step = 'Acondicionamentos';
      const packagingData = refPackagingForm.current?.validate();

      step = 'Equipamentos';
      const equipmentData = refEquipmentForm.current?.validate();

      step = 'Veículo';
      const vehicleData = refVehicleForm.current?.validate();

      step = 'Tratamento';
      const treatmentData = refTreatmentForm.current?.validate();

      step = 'Destino Final';
      const destinationData = refDestinationForm.current?.validate();

      // check for diffs
      const isComplete =
        (packagingData ? packagingData.isComplete : true) &&
        (equipmentData ? equipmentData.isComplete : true) &&
        (vehicleData ? vehicleData.isComplete : true) &&
        (treatmentData ? treatmentData.isComplete : true) &&
        (destinationData ? destinationData.isComplete : true);

      if (props.item) {
        const oldIsComplete = props.item.completo;
        if (generalData.action === Action.Nothing && oldIsComplete !== isComplete) {
          generalData.action = Action.Modify;
        }
      }

      props.callback(
        {
          ...generalData,
          acondicionamentos: packagingData?.data || [],
          equipamentos: equipmentData?.data || [],
          veiculo: vehicleData?.data?.[0] || null,
          tratamento: treatmentData?.data?.[0] || null,
          destinoFinal: destinationData?.data?.[0] || null,
          completo: isComplete,
        },
        'callback',
        true
      );
    } catch (ex: any) {
      setError({
        title: step,
        message: ex.message,
      });
    }
  };

  const buildTabs = () => {
    const tabs = [
      {
        key: 'Geral',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Id size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Geral
            </Text>
          </Group>
        ),
        children: (
          <Paper shadow="xs" p="md" withBorder>
            <ResiduePlanQuotationGeneralFormView
              ref={refGeneralForm}
              idPropostaResiduoPlano={props.idPropostaResiduoPlano}
              item={props.item}
              origItem={props.origItem}
              referenceData={props.referenceData}
              callback={props.callback}
            />
          </Paper>
        ),
        forceRender: true,
      },
    ];

    if (
      proposalResiduePlanData.acondicionamentos?.length > 0 ||
      (props.item && props.item?.acondicionamentos?.length > 0)
    ) {
      tabs.push({
        key: 'Acondicionamentos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Package size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Acondicionamentos
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanPackagingQuotationFormView
            ref={refPackagingForm}
            referenceData={props.referenceData}
            proposalResiduePlanQuotation={props.item}
          />
        ),
        forceRender: true,
      });
    }

    if (
      proposalResiduePlanData.equipamentos?.length > 0 ||
      (props.item && props.item?.equipamentos?.length > 0)
    ) {
      tabs.push({
        key: 'Equipamentos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Backhoe size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Equipamentos
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanEquipmentQuotationFormView
            ref={refEquipmentForm}
            referenceData={props.referenceData}
            proposalResiduePlanQuotation={props.item}
          />
        ),
        forceRender: true,
      });
    }

    if (proposalResiduePlanData.veiculo || props.item?.veiculo) {
      tabs.push({
        key: 'Veiculos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Truck size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Veículo
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanVehicleQuotationFormView
            ref={refVehicleForm}
            referenceData={props.referenceData}
            proposalResiduePlanQuotation={props.item}
          />
        ),
        forceRender: true,
      });
    }

    if (proposalResiduePlanData.tratamento || props.item?.tratamento) {
      tabs.push({
        key: 'Tratamentos',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Recycle size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Tratamento
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanTreatmentQuotationFormView
            ref={refTreatmentForm}
            referenceData={props.referenceData}
            proposalResiduePlanQuotation={props.item}
          />
        ),
        forceRender: true,
      });
    }

    if (proposalResiduePlanData.destinoFinal || props.item?.destinoFinal) {
      tabs.push({
        key: 'DestinoFinais',
        label: (
          <Group>
            <ThemeIcon color={Feature.Home.Proposal.color} variant="outline">
              <Target size={18} />
            </ThemeIcon>
            <Text size="md" color={Feature.Home.Proposal.color} weight={500}>
              Destino Final
            </Text>
          </Group>
        ),
        children: (
          <ResiduePlanDestinationQuotationFormView
            ref={refDestinationForm}
            referenceData={props.referenceData}
            proposalResiduePlanQuotation={props.item}
          />
        ),
        forceRender: true,
      });
    }

    return tabs;
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Cotação"
          text="Cotação para cada item do plano de coleta de resíduo."
        />
        <Space h="xs" />

        <Paper shadow="xs" p="md" withBorder>
          {error && (
            <>
              <Alert
                icon={<CircleX size={18} />}
                title={error.title}
                color="red"
                withCloseButton
                onClose={() => setError(null)}
              >
                {error.message}
              </Alert>
              <Space h="xl" />
            </>
          )}

          <Tabs items={buildTabs()} defaultActiveKey="Geral" />
        </Paper>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
