export enum ContractStatusEnum {
  EmProcessamento = 'EP',
  AguardandoInicio = 'AI',
  EmSubstituicao = 'ES',
  Reconciliando = 'RE',
  EmAndamento = 'EA',
  Cancelado = 'CA',
  Substituido = 'SU',
  Finalizado = 'FI',
}

const map = [
  {
    currentStatus: ContractStatusEnum.EmProcessamento,
    validStatuses: [ContractStatusEnum.Cancelado, ContractStatusEnum.EmAndamento],
  },
  {
    currentStatus: ContractStatusEnum.AguardandoInicio,
    validStatuses: [ContractStatusEnum.Cancelado, ContractStatusEnum.EmAndamento],
  },
  {
    currentStatus: ContractStatusEnum.EmSubstituicao,
    validStatuses: [],
  },
  {
    currentStatus: ContractStatusEnum.Cancelado,
    validStatuses: [],
  },
  {
    currentStatus: ContractStatusEnum.EmAndamento,
    validStatuses: [ContractStatusEnum.Cancelado],
  },
  {
    currentStatus: ContractStatusEnum.Substituido,
    validStatuses: [],
  },
  {
    currentStatus: ContractStatusEnum.Finalizado,
    validStatuses: [],
  },
];

const getValidStatuses = (currentStatusCode: ContractStatusEnum): ContractStatusEnum[] => {
  return map.find((x) => x.currentStatus === currentStatusCode)?.validStatuses || [];
};

// const getStatusAction = (currentStatusCode: ContractStatusEnum): { action: string; icon: Icon } => {
//   switch (currentStatusCode) {
//     case ContractStatusEnum.AguardandoInicio:
//       return { action: 'Editar', icon: Edit };
//     case ContractStatusEnum.Cancelado:
//       return { action: 'Cancelar', icon: X };
//     case ContractStatusEnum.EmAndamento:
//       return { action: 'Enviar para Análise Financeira', icon: CurrencyDollar };
//     case ContractStatusEnum.Finalizado:
//       return { action: 'Finalizar', icon: Check };
//     default:
//       return { action: '?', icon: QuestionMark };
//   }
// };

const validarStatusWorkflow = (newStatusCode: ContractStatusEnum, currentStatusCode: ContractStatusEnum) => {
  if (
    map
      .find((x) => x.currentStatus === currentStatusCode)
      ?.validStatuses.findIndex((y) => y === newStatusCode) === -1
  ) {
    throw Error('Não é possível atualizar esse contrato com esse novo status.');
  }
};

export { getValidStatuses, validarStatusWorkflow };
