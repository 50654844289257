import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Group, Paper, Space, ThemeIcon, Text, Alert } from '@mantine/core';
import { Id, Users, MapPins, Wallet, Database, Certificate, CircleX } from 'tabler-icons-react';
import { Tabs } from 'antd';
import {
  EntityAddressType,
  EntityContactType,
  EntityEquipmentType,
  EntityPackagingType,
  EntityPaymentMethodType,
  EntityType,
  EntityVehicleType,
} from '../../../../../models/core/entities.type';
import {
  AddressTypeType,
  EntityTypeType,
  PaymentMethodType,
  DocumentType,
} from '../../../../../models/core/cache.type';
import GeneralFormView from './components/GeneralFormView';
import ContactFormView from './components/ContactFormView/ContactFormView';
import PaymentMethodFormView from './components/PaymentMethodFormView/PaymentMethodFormView';
import { BancoType, EstadoType } from '../../../../../models/utils/brasilapi.type';
import AddressFormView from './components/AddressFormView/AddressFormView';
import PackagingFormView from './components/PackagingFormView/PackagingFormView';
import DestinationFormView from './components/DestinationFormView/DestinationFormView';
import EquipmentFormView from './components/EquipmentFormView/EquipmentFormView';
import TreatmentFormView from './components/TreatmentFormView/TreatmentFormView';
import VehicleFormView from './components/VehicleFormView/VehicleFormView';
import ServiceFormView from './components/ServiceFormView/ServiceFormView';
import DocumentFormView from './components/DocumentFormView/DocumentFormView';
import { Feature, SessionStorageKey } from '../../../../../utils/constants.utils';

type FormViewData = {
  idEntidade: number | null;
  codigoEntidadeTipo: string;
  cnpj: string | null;
  razaoSocial: string | null;
  nomeFantasia: string | null;
  incricaoEstadual: string | null;
  incricaoMunicipal: string | null;
  cpf: string | null;
  nome: string | null;
  webSite: string | null;
  aceitaEncontroContas: boolean;
  contatos: EntityContactType[];
  enderecos: EntityAddressType[];
  formasPagamento: EntityPaymentMethodType[];
  residuoAcondicionamentos: EntityPackagingType[];
  residuoEquipamentos: EntityEquipmentType[];
  residuoVeiculos: EntityVehicleType[];
};

type FormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    paymentMethodTypeData: PaymentMethodType[];
    bancoTypeData: BancoType[];
    addressTypeData: AddressTypeType[];
    estadoTypeData: EstadoType[];
    documentTypeData: DocumentType[];
  };
  entity: EntityType | null;
};

const FormView = forwardRef((props: FormViewProps, ref) => {
  const refGeneralForm = useRef<any>();
  const refContactForm = useRef<any>();
  const refAddressForm = useRef<any>();
  const refPaymentMethodForm = useRef<any>();
  const refPackagingForm = useRef<any>();
  const refDestinationForm = useRef<any>();
  const refEquipmentForm = useRef<any>();
  const refTreatmentForm = useRef<any>();
  const refVehicleForm = useRef<any>();
  const refServiceForm = useRef<any>();
  const refDocumentForm = useRef<any>();

  const [error, setError] = useState<any | null>(null);

  const tabs = [
    {
      key: 'Geral',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Id size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Geral
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <GeneralFormView ref={refGeneralForm} entity={props.entity} referenceData={props.referenceData} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Contatos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Users size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Contatos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <ContactFormView ref={refContactForm} entity={props.entity} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Documentos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Certificate size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Documentos
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <DocumentFormView
            ref={refDocumentForm}
            entity={props.entity}
            referenceData={{
              documentTypeData: props.referenceData.documentTypeData,
            }}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Endereços',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <MapPins size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Endereços
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <AddressFormView ref={refAddressForm} entity={props.entity} referenceData={props.referenceData} />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Formas de Pagamento',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Wallet size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Formas de Pagamento
          </Text>
        </Group>
      ),
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <PaymentMethodFormView
            ref={refPaymentMethodForm}
            entity={props.entity}
            referenceData={{
              paymentMethodTypeData: props.referenceData.paymentMethodTypeData,
              bancoTypeData: props.referenceData.bancoTypeData,
            }}
          />
        </Paper>
      ),
      forceRender: true,
    },
    {
      key: 'Referências',
      label: (
        <Group>
          <ThemeIcon color={Feature.Reference.Entity.color} variant="outline">
            <Database size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Reference.Entity.color} weight={500}>
            Referências
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Paper shadow="xs" p="md" withBorder>
            <PackagingFormView ref={refPackagingForm} entity={props.entity} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <EquipmentFormView ref={refEquipmentForm} entity={props.entity} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <VehicleFormView ref={refVehicleForm} entity={props.entity} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <TreatmentFormView ref={refTreatmentForm} entity={props.entity} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <DestinationFormView ref={refDestinationForm} entity={props.entity} />
          </Paper>
          <Space h="lg" />
          <Paper shadow="xs" p="md" withBorder>
            <ServiceFormView ref={refServiceForm} entity={props.entity} />
          </Paper>
        </div>
      ),
      forceRender: true,
    },
  ];

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      const generalData = refGeneralForm.current.validate();
      if (generalData === null) {
        setError({
          title: 'Geral',
          message: 'Existem pendências a serem corrigidas.',
        });
        return null;
      }
      return {
        ...generalData,
        contatos: refContactForm.current.validate(),
        enderecos: refAddressForm.current.validate(),
        formasPagamento: refPaymentMethodForm.current.validate(),
        residuoAcondicionamentos: refPackagingForm.current.validate(),
        residuoDestinosFinais: refDestinationForm.current.validate(),
        residuoEquipamentos: refEquipmentForm.current.validate(),
        residuoTratamentos: refTreatmentForm.current.validate(),
        residuoVeiculos: refVehicleForm.current.validate(),
        servicos: refServiceForm.current.validate(),
        documentos: refDocumentForm.current.validate(),
      };
    },
    clear() {
      sessionStorage.removeItem(SessionStorageKey.TempEntity);
      refGeneralForm.current.clear();
      refContactForm.current.clear();
      refAddressForm.current.clear();
      refPaymentMethodForm.current.clear();
      refPackagingForm.current.clear();
      refDestinationForm.current.clear();
      refEquipmentForm.current.clear();
      refTreatmentForm.current.clear();
      refVehicleForm.current.clear();
      refServiceForm.current.clear();
      refDocumentForm.current.clear();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      {error && (
        <>
          <Alert
            icon={<CircleX size={18} />}
            title={error.title}
            color="red"
            withCloseButton
            onClose={() => setError(null)}
          >
            {error.message}
          </Alert>
          <Space h="xl" />
        </>
      )}
      <Tabs items={tabs} defaultActiveKey="Geral" />
    </Paper>
  );
});

export default FormView;
export type { FormViewData };
