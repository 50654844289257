/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Paper, Space } from '@mantine/core';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { EventPackagingType, EventType } from '../../../../../../../models/core/events.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import EventPackagingItem from './EventPackagingItem';
import EventPackagingQuotation from './EventPackagingQuotation';

type DataViewProps = {
  data: EventPackagingType;
  event: EventType;
};

export default function EventPackagingView(props: DataViewProps) {
  const cotacaoPrimaria = props.event.referencia?.contrato.residuo?.planos
    ?.find((x) => x.primario)
    ?.cotacoes?.find((y) => y.primario)
    ?.acondicionamentos?.find((z) => z.idResiduoAcondicionamento === props.data.idResiduoAcondicionamento);

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Acondicionamento"
          text="Detalhes do acondicionamento."
        />
        <Space h="xs" />
        <EventPackagingItem data={props.data} />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Fornecedor"
            text="Detalhes do fornecedor."
          />
          <Group>
            {cotacaoPrimaria && (
              <Badge variant="outline" size="md" color="orange">
                {`Preço (Cliente): ${formatCurrency(
                  calcularPreco(
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.margem,
                    cotacaoPrimaria.precoFinal,
                    cotacaoPrimaria.imposto || 0,
                    props.event.referencia!.contrato.residuo!.compra,
                    false
                  ).novoPrecoComMargem
                )} / ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.frequenciaUnidadeMedida}`}
              </Badge>
            )}
          </Group>
        </Group>
        <Space h="xs" />
        <EventPackagingQuotation
          data={props.data.cotacao}
          compra={props.event.referencia!.contrato.residuo!.compra}
        />
      </Paper>
    </div>
  );
}
