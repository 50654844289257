import { Center, Container, Loader, Stack, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../../services/iam/auth.service';

export default function LogOut() {
  const modals = useModals();
  const navigate = useNavigate();
  useEffect(() => {
    const logOut = async () => {
      try {
        await authService.signOut();
        // eslint-disable-next-line no-empty
      } catch (error: any) {
      } finally {
        modals.closeAll();
        navigate('/');
      }
    };
    logOut();
  }, [modals, navigate]);

  return (
    <Container fluid style={{ height: '100vh' }}>
      <Center style={{ height: '100%' }}>
        <Stack align="center">
          <Title order={5}>Saindo</Title>
          <Loader />
        </Stack>
      </Center>
    </Container>
  );
}
