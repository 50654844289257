/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { Column, ColumnConfig, Pie, PieConfig } from '@ant-design/plots';
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Chip,
  Divider,
  Grid,
  Group,
  Menu,
  NumberInput,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Switch,
  Table,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import lodash from 'lodash';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Adjustments,
  AlertTriangle,
  Briefcase,
  ChartInfographic,
  ChartPie,
  ChartPie4,
  Check,
  CurrencyReal,
  DeviceFloppy,
  Icon,
  Percentage,
  Share,
} from 'tabler-icons-react';
import { TipoEstimativa, consolidarEstimativas } from '../../../../../../business/proposals/estimate';
import { ProposalStatusEnum } from '../../../../../../business/proposals/status';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';
import useDynamicRefs from '../../../../../../hooks/useDynamicRefs';
import { UnitOfMeasureType } from '../../../../../../models/core/cache.type';
import { Permission } from '../../../../../../models/core/departments.type';
import { ProposalFinancialPatchType, ProposalType } from '../../../../../../models/core/proposals.type';
import proposalsService from '../../../../../../services/core/proposals.service';
import { montarRecorrenciaString } from '../../../../../../utils/business.utils';
import { Feature, Financial } from '../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../utils/helper.utils';
import { getMonths } from '../../../../../../utils/moment-recur.utils';
import { validate } from '../../../../../../utils/permission.utils';

type FormViewData = {
  margem: number;
  imposto: number;
  trigger: number;
};

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposal: ProposalType;
};

enum RefPrefixEnum {
  ResiduoAcondicionamento = 'r_a',
  ResiduoEquipamento = 'r_e',
  ResiduoVeiculo = 'r_v',
  ResiduoTratamento = 'r_t',
  ResiduoDestinoFinal = 'r_df',
  Servico = 's',
}

enum FinanceiroEnum {
  PrecificacaoDiv = 'precificacao',
  PrecificacaoToggle = 'precificacao_toggle',
  PrecificacaoTipoToggle = 'precificacao_tipo_toggle',
  PrecificacaoTipoToggleGroup = 'precificacao_tipo_toggle_group',
  PrecificacaoTipoToggleM = 'precificacao_tipo_toggle_m',
  PrecificacaoTipoToggleP = 'precificacao_tipo_toggle_p',
  PrecificacaoMargem = 'precificacao_margem',
  PrecificacaoMargemDiv = 'precificacao_margem_div',
  PrecificacaoPreco = 'precificacao_preco',
  PrecificacaoPrecoDiv = 'precificacao_preco_div',
  PrecificacaoImposto = 'precificacao_imposto',
  ResultadoEspecifico = 'resultado_especifico',
  ResultadoEspecificoM = 'm+',
  ResultadoEspecificoMI = 'm+i-',
  ResultadoEspecificoMIF = 'mf',
  ResultadoEspecificoCompra = 'resultado_especifico_compra',
  ResultadoEspecificoCompraM = 'm+',
  ResultadoEspecificoCompraMI = 'm+i-',
  ResultadoEspecificoCompraMIF = 'mf',
}

export default function FinancialView(props: ProposalFormViewProps) {
  const [currentUser] = useCurrentUser();
  const modals = useModals();
  const [getRef, setRef] = useDynamicRefs();
  const [updating, setUpdating] = useState(false);
  const hasPermission = validate(Permission.ProposalEdit, currentUser.permissoes);

  const form = useForm<FormViewData>({
    initialValues: {
      margem: Financial.Margin,
      imposto: Financial.Tax,
      trigger: 0,
    },
    validate: {
      margem: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      imposto: (value: number | null) => {
        if (value === null || value === undefined || value?.toString().trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const calculatePrice = (
    preco: number,
    margem: number | null,
    precoFinal: number | null,
    imposto: number
  ) => {
    const novoPrecoComMargem = precoFinal !== null ? Number(precoFinal) : preco + preco * (margem! / 100);
    const novoPrecoComMargemComImposto = novoPrecoComMargem - novoPrecoComMargem * (imposto / 100);
    const novaMargemFinal = ((preco - novoPrecoComMargemComImposto) / preco) * -100 || 0;
    return {
      novoPrecoComMargem,
      novoPrecoComMargemComImposto,
      novaMargemFinal,
    };
  };

  const defineSpecificPrice = (
    preco: number,
    margem: number | null,
    precoFinal: number | null,
    imposto: number,
    precoEspecificoRef: string,
    esconder: boolean
  ) => {
    margem = margem !== null ? Number(margem?.toString()?.replace(',', '.')) : null;
    precoFinal = precoFinal !== null ? Number(precoFinal?.toString()?.replace(',', '.')) : null;
    imposto = Number(imposto?.toString()?.replace(',', '.'));

    let { novoPrecoComMargem, novoPrecoComMargemComImposto, novaMargemFinal } = calculatePrice(
      preco,
      margem,
      precoFinal,
      imposto
    );

    getRef(`${precoEspecificoRef}_${FinanceiroEnum.ResultadoEspecificoM}`).current.innerText =
      formatCurrency(novoPrecoComMargem);
    getRef(`${precoEspecificoRef}_${FinanceiroEnum.ResultadoEspecificoMI}`).current.innerText =
      formatCurrency(novoPrecoComMargemComImposto);
    getRef(
      `${precoEspecificoRef}_${FinanceiroEnum.ResultadoEspecificoMIF}`
    ).current.innerText = `${formatCurrency(novoPrecoComMargemComImposto - preco)} (${novaMargemFinal.toFixed(
      2
    )}%)`;

    getRef(`${precoEspecificoRef}`).current.hidden = esconder;

    const margemFinal = Number(novaMargemFinal);
    getRef(`${precoEspecificoRef}`).current.style.color =
      margemFinal > 0 ? 'green' : margemFinal === 0 ? 'orange' : 'red';

    return {
      novoPrecoComMargem: Number(novoPrecoComMargem),
      novoPrecoComMargemComImposto: Number(novoPrecoComMargemComImposto),
      novaMargemFinal: Number(novaMargemFinal),
    };
  };

  const defineSpecificBuyPrice = (
    preco: number,
    margem: number | null,
    precoFinal: number | null,
    imposto: number,
    precoEspecificoRef: string,
    esconder: boolean
  ) => {
    margem = margem !== null ? Number(margem?.toString()?.replace(',', '.')) : null;
    precoFinal = precoFinal !== null ? Number(precoFinal?.toString()?.replace(',', '.')) : null;
    imposto = Number(imposto?.toString()?.replace(',', '.'));

    const { novoPrecoComMargem, novoPrecoComMargemComImposto, novaMargemFinal } = calculatePrice(
      preco,
      margem === null ? null : margem * -1,
      precoFinal,
      imposto * -1
    );

    getRef(`${precoEspecificoRef}_${FinanceiroEnum.ResultadoEspecificoCompraM}`).current.innerText =
      formatCurrency(novoPrecoComMargem);

    getRef(`${precoEspecificoRef}_${FinanceiroEnum.ResultadoEspecificoCompraMI}`).current.innerText =
      formatCurrency(novoPrecoComMargemComImposto);

    getRef(
      `${precoEspecificoRef}_${FinanceiroEnum.ResultadoEspecificoCompraMIF}`
    ).current.innerText = `${formatCurrency((Number(novoPrecoComMargemComImposto) - preco) * -1)} (${(
      Number(novaMargemFinal) * -1
    ).toFixed(2)}%)`;

    getRef(`${precoEspecificoRef}`).current.hidden = esconder;

    const margemFinal = Number(novaMargemFinal) * -1;
    getRef(`${precoEspecificoRef}`).current.style.color =
      margemFinal > 0 ? 'green' : margemFinal === 0 ? 'orange' : 'red';
  };

  const getItemMarginPriceTax = (type: RefPrefixEnum, id: number) => {
    let { margem, imposto } = form.values;

    let margemFinal: number | null = margem;
    let precoFinal = null;

    const switchRef = getRef(`${FinanceiroEnum.PrecificacaoToggle}_${type}_${id}`).current;
    if (switchRef.checked || switchRef.disabled) {
      const margemRef = getRef(`${FinanceiroEnum.PrecificacaoMargem}_${type}_${id}`).current;
      const precoFinalRef = getRef(`${FinanceiroEnum.PrecificacaoPreco}_${type}_${id}`).current;
      const impostoRef = getRef(`${FinanceiroEnum.PrecificacaoImposto}_${type}_${id}`).current;

      margem = margemRef.value === '' ? Financial.Margin : margemRef.value.replace(',', '.');
      precoFinal = precoFinalRef.value.replace(',', '.');
      imposto = impostoRef.value === '' ? Financial.Tax : impostoRef.value.replace(',', '.');

      const usarMargem = getRef(`${FinanceiroEnum.PrecificacaoTipoToggleM}_${type}_${id}`).current.checked;
      if (usarMargem) {
        margemFinal = Number(margem);
        precoFinal = null;
      } else {
        precoFinal = Number(precoFinal);
        margemFinal = null;
      }

      const compartilhado =
        (Object.entries(switchRef).find((x) => x[0].startsWith('__reactProps'))?.[1] as any)
          ?.compartilhado === 'true';

      if (margemRef.disabled && !compartilhado) {
        margemFinal = null;
      }
      if (precoFinalRef.disabled && !compartilhado) {
        precoFinal = null;
      }
    }

    return { margem: margemFinal, precoFinal, imposto: Number(imposto) };
  };

  const buildMarginPriceTaxInputs = (
    type: RefPrefixEnum,
    id: number,
    preco: number,
    compra: boolean,
    receita: boolean,
    compartilhado: boolean,
    margemInicial?: number | null,
    precoFinalInicial?: number | null,
    impostoInicial?: number | null
  ) => {
    margemInicial = margemInicial === null ? Financial.Margin : Number(margemInicial);
    impostoInicial = impostoInicial === null ? Financial.Tax : Number(impostoInicial);

    const calcularPrecificacaoEspecifica = (
      margemAtual: number | null,
      precoFinalAtual: number | null,
      impostoAtual: number | null,
      usarMargem: boolean | null,
      esconder: boolean
    ) => {
      let margem =
        margemAtual ||
        getRef(`${FinanceiroEnum.PrecificacaoMargem}_${type}_${id}`).current.value ||
        Financial.Margin;
      const margemOriginal = margem !== null ? Number(margem?.toString()?.replace(',', '.')) : null;

      usarMargem =
        usarMargem === null
          ? getRef(`${FinanceiroEnum.PrecificacaoTipoToggleM}_${type}_${id}`).current.checked
          : usarMargem;

      let precoFinal =
        precoFinalAtual || getRef(`${FinanceiroEnum.PrecificacaoPreco}_${type}_${id}`).current.value || 0;
      const precoFinalOriginal =
        precoFinal !== null ? Number(precoFinal?.toString()?.replace(',', '.')) : null;

      let imposto =
        impostoAtual ||
        getRef(`${FinanceiroEnum.PrecificacaoImposto}_${type}_${id}`).current.value ||
        Financial.Tax;
      imposto = Number(imposto?.toString()?.replace(',', '.'));

      if (usarMargem) {
        precoFinal = null;
      } else {
        margem = null;
      }

      if (compra && receita) {
        if (usarMargem) {
          margem = 0;
        } else {
          precoFinal = preco;
        }
      } else if (!compra && receita) {
        margem = 0;
        precoFinal = null;
      }

      const specPrice = defineSpecificPrice(
        preco,
        margem,
        precoFinal,
        imposto,
        `${FinanceiroEnum.ResultadoEspecifico}_${type}_${id}`,
        esconder
      );

      if (compra && receita) {
        if (usarMargem) {
          margem = margemOriginal;
        } else {
          precoFinal = precoFinalOriginal;
        }
        imposto = 0;

        defineSpecificBuyPrice(
          specPrice.novoPrecoComMargemComImposto,
          margem,
          precoFinal,
          imposto,
          `${FinanceiroEnum.ResultadoEspecificoCompra}_${type}_${id}`,
          esconder
        );
      }
    };

    return (
      <div>
        <Group position="apart">
          <Switch
            key={`${FinanceiroEnum.PrecificacaoToggle}_${type}_${id}`}
            ref={setRef(`${FinanceiroEnum.PrecificacaoToggle}_${type}_${id}`)}
            onChange={(event) => {
              const div = getRef(`${FinanceiroEnum.PrecificacaoDiv}_${type}_${id}`).current;
              div.hidden = !event.target.checked;

              const tipoToggle = getRef(`${FinanceiroEnum.PrecificacaoTipoToggle}_${type}_${id}`).current;
              tipoToggle.hidden = !event.target.checked;

              calcularPrecificacaoEspecifica(null, null, null, null, div.hidden);
            }}
            {...{
              initially_enabled: (
                margemInicial !== Financial.Margin ||
                impostoInicial !== Financial.Tax ||
                precoFinalInicial !== null ||
                receita ||
                compartilhado
              ).toString(),
              compra: compra.toString(),
              receita: receita.toString(),
              compartilhado: compartilhado.toString(),
              usar_margem: (precoFinalInicial === null).toString(),
            }}
          />
          <div ref={setRef(`${FinanceiroEnum.PrecificacaoTipoToggle}_${type}_${id}`)} hidden>
            <Chip.Group
              key={`${FinanceiroEnum.PrecificacaoTipoToggleGroup}_${type}_${id}`}
              position="center"
              defaultValue="m"
              onChange={(value) => {
                const divMargem = getRef(`${FinanceiroEnum.PrecificacaoMargemDiv}_${type}_${id}`).current;
                const divPreco = getRef(`${FinanceiroEnum.PrecificacaoPrecoDiv}_${type}_${id}`).current;
                if (value === 'm') {
                  divMargem.hidden = false;
                  divPreco.hidden = true;
                } else {
                  divMargem.hidden = true;
                  divPreco.hidden = false;
                }
                calcularPrecificacaoEspecifica(null, null, null, value === 'm', false);
              }}
            >
              <Chip
                key={`${FinanceiroEnum.PrecificacaoTipoToggleM}_${type}_${id}`}
                ref={setRef(`${FinanceiroEnum.PrecificacaoTipoToggleM}_${type}_${id}`)}
                value="m"
              >
                Margem
              </Chip>
              <Chip
                key={`${FinanceiroEnum.PrecificacaoTipoToggleP}_${type}_${id}`}
                ref={setRef(`${FinanceiroEnum.PrecificacaoTipoToggleP}_${type}_${id}`)}
                value="p"
              >
                Preço
              </Chip>
            </Chip.Group>
          </div>
        </Group>
        <div ref={setRef(`${FinanceiroEnum.PrecificacaoDiv}_${type}_${id}`)} hidden>
          <Divider my="sm" variant="dotted" />
          <Group position="apart">
            <div ref={setRef(`${FinanceiroEnum.PrecificacaoMargemDiv}_${type}_${id}`)}>
              <NumberInput
                key={`${FinanceiroEnum.PrecificacaoMargem}_${type}_${id}`}
                ref={setRef(`${FinanceiroEnum.PrecificacaoMargem}_${type}_${id}`)}
                icon={<Percentage size={15} />}
                style={{ width: 120 }}
                label="Margem"
                placeholder="Margem"
                precision={2}
                step={0.1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                defaultValue={(!compra && receita) || compartilhado ? 0 : margemInicial}
                onChange={(margem) => {
                  calcularPrecificacaoEspecifica(margem || null, null, null, true, false);
                }}
                disabled={
                  props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira ||
                  (!compra && receita) ||
                  compartilhado
                }
              />
            </div>
            <div ref={setRef(`${FinanceiroEnum.PrecificacaoPrecoDiv}_${type}_${id}`)} hidden>
              <NumberInput
                key={`${FinanceiroEnum.PrecificacaoPreco}_${type}_${id}`}
                ref={setRef(`${FinanceiroEnum.PrecificacaoPreco}_${type}_${id}`)}
                icon={<CurrencyReal size={15} />}
                style={{ width: 120 }}
                label="Preço"
                placeholder="Preço"
                precision={2}
                step={0.1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                defaultValue={precoFinalInicial === null || compartilhado ? 0 : Number(precoFinalInicial)}
                onChange={(precoFinal) => {
                  calcularPrecificacaoEspecifica(null, precoFinal || null, null, false, false);
                }}
                disabled={
                  props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira ||
                  (!compra && receita) ||
                  compartilhado
                }
              />
            </div>
            <NumberInput
              key={`${FinanceiroEnum.PrecificacaoImposto}_${type}_${id}`}
              ref={setRef(`${FinanceiroEnum.PrecificacaoImposto}_${type}_${id}`)}
              icon={<Percentage size={15} />}
              style={{ width: 120 }}
              label="Imposto"
              placeholder="Imposto"
              precision={2}
              step={0.1}
              stepHoldDelay={500}
              stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
              defaultValue={compartilhado ? 0 : impostoInicial}
              onChange={(imposto) => {
                calcularPrecificacaoEspecifica(null, null, imposto || null, null, false);
              }}
              disabled={
                props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira ||
                compartilhado
              }
            />
          </Group>
        </div>
      </div>
    );
  };

  const buildResult = (type: RefPrefixEnum, id: number, preco: number, compra: boolean, receita: boolean) => {
    const geral = calculatePrice(
      preco,
      receita ? 0 : form.values.margem,
      null,
      receita ? 0 : form.values.imposto
    );
    const margemFinal = Number(geral.novaMargemFinal);

    return (
      <Table
        key={`table_result_${type}_${id}`}
        className="custom-mantine-table"
        withBorder
        withColumnBorders
        style={{ lineHeight: 1 }}
      >
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Preço (+m)</th>
            <th>Preço (+m-i)</th>
            <th>Margem Final</th>
          </tr>
        </thead>
        <tbody>
          <tr
            key={newGuid()}
            style={{ color: margemFinal > 0 ? 'green' : margemFinal === 0 ? 'orange' : 'red' }}
          >
            <td>Geral</td>
            <td style={{ fontWeight: 700 }}>{formatCurrency(geral.novoPrecoComMargem)}</td>
            <td>{formatCurrency(geral.novoPrecoComMargemComImposto)}</td>
            <td>
              {formatCurrency(geral.novoPrecoComMargemComImposto - preco)} ({geral.novaMargemFinal.toFixed(2)}
              %)
            </td>
          </tr>
          <tr
            key={`${FinanceiroEnum.ResultadoEspecifico}_${type}_${id}`}
            ref={setRef(`${FinanceiroEnum.ResultadoEspecifico}_${type}_${id}`)}
            hidden
          >
            <td>Específico</td>
            <td
              style={{ fontWeight: !compra && receita ? 0 : 700 }}
              ref={setRef(
                `${FinanceiroEnum.ResultadoEspecifico}_${type}_${id}_${FinanceiroEnum.ResultadoEspecificoM}`
              )}
            >
              -
            </td>
            <td
              style={{ fontWeight: !compra && receita ? 700 : 0 }}
              ref={setRef(
                `${FinanceiroEnum.ResultadoEspecifico}_${type}_${id}_${FinanceiroEnum.ResultadoEspecificoMI}`
              )}
            >
              -
            </td>
            <td
              ref={setRef(
                `${FinanceiroEnum.ResultadoEspecifico}_${type}_${id}_${FinanceiroEnum.ResultadoEspecificoMIF}`
              )}
            >
              -
            </td>
          </tr>
          {compra && receita && (
            <tr
              key={`${FinanceiroEnum.ResultadoEspecificoCompra}_${type}_${id}`}
              ref={setRef(`${FinanceiroEnum.ResultadoEspecificoCompra}_${type}_${id}`)}
            >
              <td>Compra</td>
              <td
                ref={setRef(
                  `${FinanceiroEnum.ResultadoEspecificoCompra}_${type}_${id}_${FinanceiroEnum.ResultadoEspecificoCompraM}`
                )}
                style={{ fontWeight: 700 }}
              >
                -
              </td>
              <td
                ref={setRef(
                  `${FinanceiroEnum.ResultadoEspecificoCompra}_${type}_${id}_${FinanceiroEnum.ResultadoEspecificoCompraMI}`
                )}
              >
                -
              </td>
              <td
                ref={setRef(
                  `${FinanceiroEnum.ResultadoEspecificoCompra}_${type}_${id}_${FinanceiroEnum.ResultadoEspecificoCompraMIF}`
                )}
              >
                -
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const buildPayload = (
    idPropostaResiduo: number | null,
    idPropostaServico: number | null,
    primario: boolean,
    idCotacao: number | null
  ) => {
    const payload: ProposalFinancialPatchType = {
      idProposta: props.proposal.idProposta,
      acondicionamentos: [],
      equipamentos: [],
      veiculos: [],
      tratamentos: [],
      destinosFinais: [],
      servicos: [],
    };

    let { residuos, servicos } = props.proposal;
    if (idPropostaResiduo) {
      residuos = residuos.filter((x) => x.idPropostaResiduo === idPropostaResiduo);
      servicos = [];
    }

    if (idPropostaServico) {
      servicos = servicos.filter((x) => x.idPropostaServico === idPropostaServico);
      residuos = [];
    }

    for (const residuo of residuos) {
      for (const plano of residuo.planos.filter((x) => x.primario === (primario ? true : x.primario))) {
        for (const cotacao of plano.cotacoes.filter(
          (x) =>
            x.primario === (primario ? true : x.primario) &&
            x.idPropostaResiduoPlanoCotacao === (idCotacao ?? x.idPropostaResiduoPlanoCotacao)
        )) {
          for (const x of cotacao.acondicionamentos) {
            payload.acondicionamentos.push({
              ...getItemMarginPriceTax(
                RefPrefixEnum.ResiduoAcondicionamento,
                x.idPropostaResiduoPlanoCotacaoAcondicionamento!
              ),
              idPropostaResiduoPlanoCotacaoAcondicionamento: x.idPropostaResiduoPlanoCotacaoAcondicionamento!,
            });
          }

          for (const x of cotacao.equipamentos) {
            payload.equipamentos.push({
              ...getItemMarginPriceTax(
                RefPrefixEnum.ResiduoEquipamento,
                x.idPropostaResiduoPlanoCotacaoEquipamento!
              ),
              idPropostaResiduoPlanoCotacaoEquipamento: x.idPropostaResiduoPlanoCotacaoEquipamento!,
            });
          }

          if (cotacao.veiculo) {
            const x = cotacao.veiculo;
            payload.veiculos.push({
              ...getItemMarginPriceTax(RefPrefixEnum.ResiduoVeiculo, x.idPropostaResiduoPlanoCotacaoVeiculo!),
              idPropostaResiduoPlanoCotacaoVeiculo: x.idPropostaResiduoPlanoCotacaoVeiculo!,
            });
          }

          if (cotacao.tratamento) {
            const x = cotacao.tratamento;
            payload.tratamentos.push({
              ...getItemMarginPriceTax(
                RefPrefixEnum.ResiduoTratamento,
                x.idPropostaResiduoPlanoCotacaoTratamento!
              ),
              idPropostaResiduoPlanoCotacaoTratamento: x.idPropostaResiduoPlanoCotacaoTratamento!,
            });
          }

          if (cotacao.destinoFinal) {
            const x = cotacao.destinoFinal;
            payload.destinosFinais.push({
              ...getItemMarginPriceTax(
                RefPrefixEnum.ResiduoDestinoFinal,
                x.idPropostaResiduoPlanoCotacaoDestinoFinal!
              ),
              idPropostaResiduoPlanoCotacaoDestinoFinal: x.idPropostaResiduoPlanoCotacaoDestinoFinal!,
            });
          }
        }
      }
    }

    for (const servico of servicos) {
      for (const cotacao of servico.cotacoes.filter(
        (x) =>
          x.primario === (primario ? true : x.primario) &&
          x.idPropostaServicoCotacao === (idCotacao ?? x.idPropostaServicoCotacao)
      )) {
        payload.servicos.push({
          ...getItemMarginPriceTax(RefPrefixEnum.Servico, cotacao.idPropostaServicoCotacao),
          idPropostaServicoCotacao: cotacao.idPropostaServicoCotacao,
        });
      }
    }

    return payload;
  };

  const buildEstimatesButton = (
    proposta: ProposalType,
    itemTitle: string,
    dataInicial: Date,
    dataFinal: Date,
    idPropostaResiduo: number | null,
    idPropostaServico: number | null,
    primario: boolean,
    idCotacao: number | null
  ) => {
    return (
      <Menu key={newGuid()} shadow="md" withArrow>
        <Menu.Target>
          <Button
            key={newGuid()}
            color="yellow"
            size="xs"
            leftIcon={<ChartInfographic size={14} />}
            onClick={(e: any) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            loading={updating}
          >
            Estimativas
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            key={newGuid()}
            icon={<ChartPie size={14} />}
            onClick={(e: any) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              showEstimates(
                proposta,
                itemTitle,
                dataInicial,
                dataFinal,
                idPropostaResiduo,
                idPropostaServico,
                primario,
                idCotacao,
                TipoEstimativa.Grupo
              );
            }}
          >
            Grupo
          </Menu.Item>
          <Menu.Item
            key={newGuid()}
            icon={<ChartPie4 size={14} />}
            onClick={(e: any) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              showEstimates(
                proposta,
                itemTitle,
                dataInicial,
                dataFinal,
                idPropostaResiduo,
                idPropostaServico,
                primario,
                idCotacao,
                TipoEstimativa.Item
              );
            }}
          >
            Item
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  };
  // FIX: 009 - validateDOMNesting(...): <button> cannot appear as a descendant of
  const getItemEstimates = (
    proposta: ProposalType,
    itemTitle: string,
    dataInicial: Date,
    dataFinal: Date,
    idPropostaResiduo: number | null,
    idPropostaServico: number | null,
    idCotacao: number | null
  ) => {
    return buildEstimatesButton(
      proposta,
      itemTitle,
      dataInicial,
      dataFinal,
      idPropostaResiduo,
      idPropostaServico,
      false,
      idCotacao
    );
  };

  const buildResidues = useMemo(() => {
    const residuesNodes: ReactNode[] = [];

    let planNodes: ReactNode[] = [];
    let quotationGroupNodes: ReactNode[] = [];
    let quotationRecordNodes: {
      planQuotationId: number;
      primary: boolean;
      icon: Icon;
      supplier: string;
      node: ReactNode;
    }[] = [];

    for (let index = 0; index < props.proposal.residuos.length; index++) {
      const residuo = props.proposal.residuos[index];
      const residuoRecorrencia = montarRecorrenciaString(residuo.recorrencia);

      planNodes = [];

      for (const plano of residuo.planos) {
        quotationRecordNodes = [];
        quotationGroupNodes = [];

        for (const cotacao of plano.cotacoes) {
          for (const x of cotacao.acondicionamentos) {
            const planoX = plano.acondicionamentos.find(
              (y) => y.idPropostaResiduoPlanoAcondicionamento === x.idPropostaResiduoPlanoAcondicionamento
            )!;
            quotationRecordNodes.push({
              planQuotationId: x.idPropostaResiduoPlanoCotacao!,
              primary: cotacao.primario,
              icon: Feature.Reference.Packaging.icon,
              supplier: x.fornecedorNomeFantasia ?? x.fornecedorRazaoSocial ?? x.fornecedorNome ?? '-',
              node: (
                <tr key={`ra_${x.idPropostaResiduoPlanoCotacaoAcondicionamento}`}>
                  <td>
                    <Feature.Reference.Packaging.icon size={16} />
                    {planoX.gerenciar ? <Briefcase size={16} /> : <></>}
                    {planoX.compartilhado ? <Share size={16} /> : <></>}
                  </td>
                  <td>{x.residuoAcondicionamento}</td>
                  <td>
                    {planoX.compartilhado ? '%' : `${planoX.quantidade} ${planoX.quantidadeUnidadeMedida}`}
                  </td>
                  <td>
                    {x.quantidade} {x.quantidadeUnidadeMedida} / {x.frequenciaUnidadeMedida}
                  </td>
                  <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                  <td>
                    {buildMarginPriceTaxInputs(
                      RefPrefixEnum.ResiduoAcondicionamento,
                      x.idPropostaResiduoPlanoCotacaoAcondicionamento!,
                      x.preco,
                      residuo.compra,
                      false,
                      planoX.compartilhado,
                      x.margem,
                      x.precoFinal,
                      x.imposto
                    )}
                  </td>
                  <td>
                    {buildResult(
                      RefPrefixEnum.ResiduoAcondicionamento,
                      x.idPropostaResiduoPlanoCotacaoAcondicionamento!,
                      x.preco,
                      residuo.compra,
                      false
                    )}
                  </td>
                </tr>
              ),
            });
          }

          for (const x of cotacao.equipamentos) {
            const planoX = plano.equipamentos.find(
              (y) => y.idPropostaResiduoPlanoEquipamento === x.idPropostaResiduoPlanoEquipamento
            )!;
            quotationRecordNodes.push({
              planQuotationId: x.idPropostaResiduoPlanoCotacao!,
              primary: cotacao.primario,
              icon: Feature.Reference.Equipment.icon,
              supplier: x.fornecedorNomeFantasia ?? x.fornecedorRazaoSocial ?? x.fornecedorNome ?? '-',
              node: (
                <tr key={`re_${x.idPropostaResiduoPlanoCotacaoEquipamento}`}>
                  <td>
                    <Feature.Reference.Equipment.icon size={16} />
                  </td>
                  <td>{x.residuoEquipamento}</td>
                  <td>
                    {planoX.quantidade} {planoX.quantidadeUnidadeMedida}
                  </td>
                  <td>
                    {x.quantidade} {x.quantidadeUnidadeMedida} / {x.frequenciaUnidadeMedida}
                  </td>
                  <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                  <td>
                    {buildMarginPriceTaxInputs(
                      RefPrefixEnum.ResiduoEquipamento,
                      x.idPropostaResiduoPlanoCotacaoEquipamento!,
                      x.preco,
                      residuo.compra,
                      false,
                      false,
                      x.margem,
                      x.precoFinal,
                      x.imposto
                    )}
                  </td>
                  <td>
                    {buildResult(
                      RefPrefixEnum.ResiduoEquipamento,
                      x.idPropostaResiduoPlanoCotacaoEquipamento!,
                      x.preco,
                      residuo.compra,
                      false
                    )}
                  </td>
                </tr>
              ),
            });
          }

          if (cotacao.veiculo) {
            const x = cotacao.veiculo;
            const planoX = plano.veiculo!;
            quotationRecordNodes.push({
              planQuotationId: x.idPropostaResiduoPlanoCotacao!,
              primary: cotacao.primario,
              icon: Feature.Reference.Vehicle.icon,
              supplier: x.fornecedorNomeFantasia ?? x.fornecedorRazaoSocial ?? x.fornecedorNome ?? '-',
              node: (
                <tr key={`rv_${x.idPropostaResiduoPlanoCotacaoVeiculo}`}>
                  <td>
                    <Feature.Reference.Vehicle.icon size={16} />
                  </td>
                  <td>{x.residuoVeiculo}</td>
                  <td>
                    {planoX.quantidade} {planoX.quantidadeUnidadeMedida}
                  </td>
                  <td>
                    {x.quantidade} {x.quantidadeUnidadeMedida} / {x.frequenciaUnidadeMedida}
                  </td>
                  <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                  <td>
                    {buildMarginPriceTaxInputs(
                      RefPrefixEnum.ResiduoVeiculo,
                      x.idPropostaResiduoPlanoCotacaoVeiculo!,
                      x.preco,
                      residuo.compra,
                      false,
                      false,
                      x.margem,
                      x.precoFinal,
                      x.imposto
                    )}
                  </td>
                  <td>
                    {buildResult(
                      RefPrefixEnum.ResiduoVeiculo,
                      x.idPropostaResiduoPlanoCotacaoVeiculo!,
                      x.preco,
                      residuo.compra,
                      false
                    )}
                  </td>
                </tr>
              ),
            });
          }

          if (cotacao.tratamento) {
            const x = cotacao.tratamento;
            quotationRecordNodes.push({
              planQuotationId: x.idPropostaResiduoPlanoCotacao!,
              primary: cotacao.primario,
              icon: Feature.Reference.Treatment.icon,
              supplier: x.fornecedorNomeFantasia ?? x.fornecedorRazaoSocial ?? x.fornecedorNome ?? '-',
              node: (
                <tr key={`rt_${x.idPropostaResiduoPlanoCotacaoTratamento}`}>
                  <td>
                    <Feature.Reference.Treatment.icon size={16} />
                  </td>
                  <td>{x.residuoTratamento}</td>
                  <td>-</td>
                  <td>
                    {x.quantidade} {x.quantidadeUnidadeMedida}
                  </td>
                  <td style={{ color: 'red' }}>{formatCurrency(x.preco)}</td>
                  <td>
                    {buildMarginPriceTaxInputs(
                      RefPrefixEnum.ResiduoTratamento,
                      x.idPropostaResiduoPlanoCotacaoTratamento!,
                      x.preco,
                      residuo.compra,
                      false,
                      false,
                      x.margem,
                      x.precoFinal,
                      x.imposto
                    )}
                  </td>
                  <td>
                    {buildResult(
                      RefPrefixEnum.ResiduoTratamento,
                      x.idPropostaResiduoPlanoCotacaoTratamento!,
                      x.preco,
                      residuo.compra,
                      false
                    )}
                  </td>
                </tr>
              ),
            });
          }

          if (cotacao.destinoFinal) {
            const x = cotacao.destinoFinal;
            quotationRecordNodes.push({
              planQuotationId: x.idPropostaResiduoPlanoCotacao!,
              primary: cotacao.primario,
              icon: Feature.Reference.Destination.icon,
              supplier: x.fornecedorNomeFantasia ?? x.fornecedorRazaoSocial ?? x.fornecedorNome ?? '-',
              node: (
                <tr key={`rdf_${x.idPropostaResiduoPlanoCotacaoDestinoFinal}`}>
                  <td>
                    <Feature.Reference.Destination.icon size={16} />
                  </td>
                  <td>{x.residuoDestinoFinal}</td>
                  <td>-</td>
                  <td>
                    {x.quantidade} {x.quantidadeUnidadeMedida}
                  </td>
                  <td style={{ color: x.receita ? 'green' : 'red' }}>{formatCurrency(x.preco)}</td>
                  <td>
                    {buildMarginPriceTaxInputs(
                      RefPrefixEnum.ResiduoDestinoFinal,
                      x.idPropostaResiduoPlanoCotacaoDestinoFinal!,
                      x.preco,
                      residuo.compra,
                      x.receita,
                      false,
                      x.margem,
                      x.precoFinal,
                      x.imposto
                    )}
                  </td>
                  <td>
                    {buildResult(
                      RefPrefixEnum.ResiduoDestinoFinal,
                      x.idPropostaResiduoPlanoCotacaoDestinoFinal!,
                      x.preco,
                      residuo.compra,
                      x.receita
                    )}
                  </td>
                </tr>
              ),
            });
          }
        }

        const groupedQuotations = lodash.groupBy(quotationRecordNodes, 'planQuotationId');
        for (const key of Object.keys(groupedQuotations)) {
          const quotations = groupedQuotations[key];
          const suppliers = lodash.uniqBy(
            quotations.map((y) => ({ icon: y.icon, supplier: y.supplier })),
            'supplier'
          );

          quotationGroupNodes.push(
            <Accordion key={`accordion_rpq_${key}`} variant="contained" defaultValue={key}>
              <Accordion.Item key={`accordion_item_rpq_${key}`} value={key}>
                <Accordion.Control>
                  <Group position="apart">
                    <Stack spacing={0.75}>
                      <Group spacing="xs">
                        <Title order={5}>{`Cotação: #${key}`}</Title>
                        {quotations[0].primary && <Check color="green" />}
                      </Group>
                      <Group>
                        {suppliers.map((x) => (
                          <Group spacing={3} className="custom-mantine-badge-group-down">
                            <x.icon size={16} />
                            <Badge
                              key={newGuid()}
                              variant="outline"
                              size="md"
                              color={Feature.Home.Proposal.color}
                            >
                              {x.supplier}
                            </Badge>
                          </Group>
                        ))}
                      </Group>
                    </Stack>
                    {getItemEstimates(
                      { ...props.proposal, residuos: [residuo], servicos: [] },
                      residuo.residuoCliente,
                      residuo.recorrencia.dataInicial || residuo.dataInicial,
                      residuo.recorrencia.dataFinal || residuo.dataFinal,
                      residuo.idPropostaResiduo!,
                      null,
                      quotations[0].planQuotationId
                    )}
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  <div key={`rp_${key}`}>
                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        key={`table_s_${residuo.idPropostaResiduo?.toString()}`}
                        highlightOnHover
                        className="custom-mantine-table"
                        withBorder
                        withColumnBorders
                      >
                        <thead>
                          <tr>
                            <th colSpan={2}>Geral</th>
                            <th colSpan={1}>Plano</th>
                            <th colSpan={2}>Cotação</th>
                            <th colSpan={2}>Financeiro</th>
                          </tr>
                          <tr>
                            <th>Tipo</th>
                            <th>Item</th>
                            <th>Quantidade</th>
                            <th>Quantidade / Frequência</th>
                            <th>Preço</th>
                            <th>Precificação Específica</th>
                            <th>Resultado</th>
                          </tr>
                        </thead>
                        <tbody>{quotations.map((y) => y.node)}</tbody>
                      </Table>
                    </Paper>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          );

          if (quotationGroupNodes.length < Object.keys(groupedQuotations).length) {
            quotationGroupNodes.push(<Space key={newGuid()} h="lg" />);
          }
        }

        planNodes.push(
          <Accordion
            key={`accordion_rp_${plano.idPropostaResiduoPlano.toString()}`}
            variant="contained"
            defaultValue={plano.idPropostaResiduoPlano.toString()}
          >
            <Accordion.Item
              key={`accordion_item_rp_${plano.idPropostaResiduoPlano.toString()}`}
              value={plano.idPropostaResiduoPlano.toString()}
            >
              <Accordion.Control>
                <Group spacing="xs">
                  <Title order={5}>{`Plano: #${plano.idPropostaResiduoPlano}`}</Title>
                  {plano.primario && <Check color="green" />}
                  {plano.acondicionamentos.length > 0 && <Feature.Reference.Packaging.icon size={16} />}
                  {plano.equipamentos.length > 0 && <Feature.Reference.Equipment.icon size={16} />}
                  {plano.veiculo && <Feature.Reference.Vehicle.icon size={16} />}
                  {plano.tratamento && <Feature.Reference.Treatment.icon size={16} />}
                  {plano.destinoFinal && <Feature.Reference.Destination.icon size={16} />}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <div key={`rpq_${residuo.idPropostaResiduo}`}>
                  <Paper shadow="xs" p="md" withBorder>
                    {quotationGroupNodes}
                  </Paper>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );

        if (planNodes.length < residuo.planos.length) {
          planNodes.push(<Space key={newGuid()} h="lg" />);
        }
      }

      residuesNodes.push(
        <Accordion
          key={`accordion_r_${residuo.idPropostaResiduo!.toString()}`}
          variant="contained"
          defaultValue={residuo.idPropostaResiduo!.toString()}
        >
          <Accordion.Item
            key={`accordion_item_r_${residuo.idPropostaResiduo!.toString()}`}
            value={residuo.idPropostaResiduo!.toString()}
          >
            <Accordion.Control>
              <Group>
                <ProfileCardLink
                  id={residuo.idResiduo.toString()}
                  name={residuo.residuoCliente}
                  nameSize="md"
                  description={`Código IBAMA: ${residuo.codigoIBAMA || '-'} | Perigoso: ${
                    residuo.residuoClientePerigoso ? 'Sim' : 'Não'
                  }`}
                  descriptionLength={100}
                  descriptionSize="sm"
                  linkPrefix="residues"
                  showLink={false}
                />
                <Group>
                  <Stack spacing="xs">
                    <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                      {`Estimativa: ${residuo.estimativa} ${residuo.estimativaUnidadeMedida1} / ${residuo.estimativaUnidadeMedida2}`}
                    </Badge>
                    {residuo.minimoAceitavel ? (
                      <Badge key={newGuid()} variant="outline" size="md" color="orange">
                        {`Mínimo Aceitável: ${residuo.minimoAceitavel} ${residuo.minimoAceitavelUnidadeMedida1} / ${residuo.minimoAceitavelUnidadeMedida2}`}
                      </Badge>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack spacing="xs">
                    {residuo.compra && (
                      <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                        Compra
                      </Badge>
                    )}
                    {residuo.cobrarTolerancia && (
                      <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                        Cobrar Tolerâncias
                      </Badge>
                    )}
                  </Stack>
                  <Stack spacing="xs">
                    {residuoRecorrencia && (
                      <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                        <div>
                          <div>
                            Recorrência: {residuoRecorrencia.str1}
                            {residuoRecorrencia.str2 ? ` (${residuoRecorrencia.str2})` : ''}
                          </div>
                        </div>
                      </Badge>
                    )}
                  </Stack>
                </Group>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <div key={`r_${residuo.idPropostaResiduo}`}>
                <Paper shadow="xs" p="md" withBorder>
                  {planNodes.map((x) => x)}
                </Paper>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      );

      if (index < props.proposal.residuos.length - 1) {
        residuesNodes.push(<Space key={newGuid()} h="lg" />);
      }
    }

    return residuesNodes;
  }, [form.values.trigger]);

  const buildServices = useMemo(() => {
    const serviceNodes: ReactNode[] = [];
    let tableNodes: ReactNode[] = [];

    for (let index = 0; index < props.proposal.servicos.length; index++) {
      const servico = props.proposal.servicos[index];
      const servicoRecorrencia = montarRecorrenciaString(servico.recorrencia);

      tableNodes = [];

      for (const cotacao of servico.cotacoes) {
        const key = cotacao.idPropostaServicoCotacao.toString();
        tableNodes.push(
          <Accordion key={`accordion_sq_${key}`} variant="contained" defaultValue={key}>
            <Accordion.Item key={`accordion_item_sq_${key}`} value={key}>
              <Accordion.Control>
                <Group position="apart">
                  <Stack spacing={0.75}>
                    <Group spacing="xs">
                      <Title order={5}>{`Cotação: #${key}`}</Title>
                      {cotacao.primario && <Check color="green" />}
                    </Group>
                    <Group spacing={3} className="custom-mantine-badge-group-down">
                      {servico.idResiduoAcondicionamento && <Feature.Reference.Packaging.icon size={16} />}
                      {servico.idResiduoEquipamento && <Feature.Reference.Equipment.icon size={16} />}
                      {!servico.idResiduoAcondicionamento && !servico.idResiduoEquipamento && (
                        <Feature.Reference.Entity.icon size={16} />
                      )}
                      <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                        {cotacao.fornecedorNomeFantasia ||
                          cotacao.fornecedorRazaoSocial ||
                          cotacao.fornecedorNome}
                      </Badge>
                    </Group>
                  </Stack>

                  {getItemEstimates(
                    { ...props.proposal, residuos: [], servicos: [servico] },
                    `${servico.servico} - ${
                      servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'
                    }`,
                    servico.recorrencia.dataInicial || servico.dataInicial,
                    servico.recorrencia.dataFinal || servico.dataFinal,
                    null,
                    servico.idPropostaServico!,
                    cotacao.idPropostaServicoCotacao
                  )}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <div key={`sq_${key}`}>
                  <Paper shadow="xs" p="md" withBorder>
                    <Table
                      key={`table_s_${servico.idPropostaServico}`}
                      highlightOnHover
                      className="custom-mantine-table"
                      withBorder
                      withColumnBorders
                    >
                      <thead>
                        <tr>
                          <th colSpan={2}>Geral</th>
                          <th colSpan={2}>Plano</th>
                          <th colSpan={3}>Cotação</th>
                          <th colSpan={2}>Financeiro</th>
                        </tr>
                        <tr>
                          <th>Tipo</th>
                          <th>Item</th>
                          <th>Quantidade</th>
                          <th>Frequência</th>
                          <th>Quantidade</th>
                          <th>Frequência</th>
                          <th>Preço</th>
                          <th>Precificação Específica</th>
                          <th>Resultado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={`sc_${cotacao.idPropostaServicoCotacao}`}>
                          <td>
                            {servico.idResiduoAcondicionamento ? (
                              <>
                                <Feature.Reference.Packaging.icon size={16} />
                                {servico.gerenciar ? <Briefcase size={16} /> : <></>}
                                {servico.compartilhado ? <Share size={16} /> : <></>}
                              </>
                            ) : servico.idResiduoEquipamento ? (
                              <Feature.Reference.Equipment.icon size={16} />
                            ) : (
                              <Feature.Reference.Entity.icon size={16} />
                            )}
                          </td>
                          <td>
                            {servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}
                          </td>
                          <td>{servico.quantidade}</td>
                          <td>
                            {!(servico.idResiduoAcondicionamento || servico.idResiduoEquipamento)
                              ? `${servico.frequencia} `
                              : ''}
                            {servico.frequenciaUnidadeMedida1} / {servico.frequenciaUnidadeMedida2}
                          </td>
                          <td>{cotacao.quantidade}</td>
                          <td>
                            {!(cotacao.idResiduoAcondicionamento || cotacao.idResiduoEquipamento)
                              ? `${cotacao.frequencia} `
                              : ''}
                            {cotacao.frequenciaUnidadeMedida1} / {cotacao.frequenciaUnidadeMedida2}
                          </td>
                          <td style={{ color: 'red' }}>{formatCurrency(cotacao.preco)}</td>
                          <td>
                            {buildMarginPriceTaxInputs(
                              RefPrefixEnum.Servico,
                              cotacao.idPropostaServicoCotacao,
                              cotacao.preco,
                              false,
                              false,
                              false, // servico.compartilhado,
                              cotacao.margem,
                              cotacao.precoFinal,
                              cotacao.imposto
                            )}
                          </td>
                          <td>
                            {buildResult(
                              RefPrefixEnum.Servico,
                              cotacao.idPropostaServicoCotacao,
                              cotacao.preco,
                              false,
                              false
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Paper>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );

        if (tableNodes.length < servico.cotacoes.length) {
          tableNodes.push(<Space key={newGuid()} h="lg" />);
        }
      }

      serviceNodes.push(
        <Accordion
          key={`accordion_s_${servico.idPropostaServico!.toString()}`}
          variant="contained"
          defaultValue={servico.idPropostaServico!.toString()}
        >
          <Accordion.Item
            key={`accordion_s_${servico.idPropostaServico!.toString()}`}
            value={servico.idPropostaServico!.toString()}
          >
            <Accordion.Control>
              <Group>
                <ProfileCardLink
                  id={servico.idServico.toString()}
                  name={servico.servico}
                  nameSize="md"
                  description={servico.servicoDescricao}
                  descriptionSize="sm"
                  linkPrefix="services"
                  showLink={false}
                />
                <div>
                  <Group>
                    <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                      {`${servico.residuoAcondicionamento || servico.residuoEquipamento || 'Fornecedor'}`}
                    </Badge>
                    {servicoRecorrencia && (
                      <Badge key={newGuid()} variant="outline" size="md" color={Feature.Home.Proposal.color}>
                        <div>
                          <div>
                            Recorrência: {servicoRecorrencia.str1}
                            {servicoRecorrencia.str2 ? ` (${servicoRecorrencia.str2})` : ''}
                          </div>
                        </div>
                      </Badge>
                    )}
                  </Group>
                </div>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <Paper shadow="xs" p="md" withBorder>
                <div key={`s_${servico.idPropostaServico}`}>{tableNodes.map((x) => x)}</div>
              </Paper>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      );

      if (index < props.proposal.servicos.length - 1) {
        serviceNodes.push(<Space key={newGuid()} h="lg" />);
      }
    }

    return serviceNodes;
  }, [form.values.trigger]);

  // TODO: App 15 - Create another component only to handle the charts
  const showEstimates = (
    proposal: ProposalType,
    itemTitle: string,
    dataInicial: Date,
    dataFinal: Date,
    idPropostaResiduo: number | null,
    idPropostaServico: number | null,
    primario: boolean,
    idCotacao: number | null,
    tipoEstimativa: TipoEstimativa
  ) => {
    const propostaFinanceiro =
      props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira
        ? null
        : buildPayload(idPropostaResiduo, idPropostaServico, primario, idCotacao);

    const { goodData, badData, totalData, grandTotal } = consolidarEstimativas(
      proposal,
      propostaFinanceiro,
      props.referenceData.unitOfMeasures,
      primario,
      idCotacao,
      tipoEstimativa
    );

    const getTotalConfig = (angleField: string, title: string): PieConfig => {
      return {
        data: totalData.map((x) => {
          return {
            ...x,
            preco: Number(x.preco.toFixed(2)),
            imposto: Number(x.imposto.toFixed(2)),
            novoPrecoComMargem: Number(x.novoPrecoComMargem.toFixed(2)),
            novoPrecoComMargemComImposto: Number(x.novoPrecoComMargemComImposto.toFixed(2)),
            novaMargemFinal: Number(x.novaMargemFinal.toFixed(2)),
          };
        }),
        angleField,
        colorField: tipoEstimativa,
        radius: 0.9,
        innerRadius: 0.6,
        appendPadding: 15,
        label: {
          type: 'outer',
          offset: '50%',
          content: (item) => `${formatCurrency(Number(item[angleField]))}`,
          style: {
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'center',
          },
        },
        tooltip: {
          formatter: (el) => ({
            name: el.grupo,
            value: `${formatCurrency(Number(el[angleField]))}`,
          }),
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '14',
              textAlign: 'center',
            },
            content: title,
          },
        },
      };
    };

    const montlyData: {
      mesAno: string;
      valor: number;
    }[] = [];

    goodData.reduce((res: any, value: any) => {
      if (!res[value.mesAno]) {
        res[value.mesAno] = {
          mesAno: value.mesAno,
          valor: value.novoPrecoComMargem,
        };
        montlyData.push(res[value.mesAno]);
      }
      res[value.mesAno].valor += value.novoPrecoComMargem;
      return res;
    }, {});

    const annotations: any[] = montlyData.map((value) => {
      return {
        type: 'text',
        position: [value.mesAno, value.valor],
        content: formatCurrency(value.valor),
        style: {
          fontSize: 9,
          fontWeight: '500',
          textAlign: 'center',
        },
        offsetY: -10,
      };
    });

    const monthlyConfig: ColumnConfig = {
      data: goodData,
      xField: 'mesAno',
      yField: 'novoPrecoComMargem',
      yAxis: {
        label: {
          formatter: (value) => {
            return formatCurrency(Number(value));
          },
        },
        nice: true,
      },
      isStack: true,
      seriesField: 'item',
      appendPadding: 20,
      tooltip: {
        formatter: (el) => ({
          name: el.item,
          value: formatCurrency(el.novoPrecoComMargem),
        }),
      },
      interactions: [
        {
          type: 'active-region',
          enable: false,
        },
      ],
      connectedArea: {
        style: (oldStyle) => {
          return {
            fill: 'rgba(0,0,0,0.25)',
            stroke: oldStyle.fill,
            lineWidth: 0.5,
          };
        },
      },
      annotations,
    };

    modals.openModal({
      title: 'Proposta - Visualizar - Estimativas',
      size: '70%',
      closeOnClickOutside: false,
      children: (
        <div key={newGuid()}>
          <Paper shadow="xs" p="md" withBorder>
            <div style={{ textAlign: 'center' }}>
              <PageSection
                size="xl"
                color={Feature.Home.Proposal.color}
                label={itemTitle}
                text={primario ? 'Considerando apenas as cotações primárias' : `Cotação: #${idCotacao}`}
              />
            </div>
            <Space h="xs" />

            <div hidden={badData.length === 0}>
              <Alert
                icon={<AlertTriangle size={16} />}
                title="O(s) item(ns) abaixo não pode/puderam ser convertido(s)/estimado(s) mensalmente:"
                color="yellow"
              >
                {badData.map((y) => {
                  return (
                    <div key={newGuid()}>
                      -{' '}
                      {`${y?.grupo} - ${y?.item} - ${y?.tipo} - ${y?.subItem} - ${formatCurrency(
                        y?.cotacao.preco || 0
                      )}`}
                    </div>
                  );
                })}
              </Alert>
              <Space h="xs" />
            </div>
            <Paper shadow="xs" p="md" withBorder>
              <div style={{ textAlign: 'center' }}>
                <PageSection size="lg" color="primary" label="Estimativa Geral" text="" />
              </div>
              <Space h="xs" />

              <Paper shadow="xs" p="md" withBorder>
                <SimpleGrid cols={6}>
                  <PageViewProperty
                    label="Receita Bruta"
                    text={formatCurrency(grandTotal.receitaBruta)}
                    size="sm"
                  />
                  <PageViewProperty label="Custo" text={formatCurrency(grandTotal.custo)} size="sm" />
                  <PageViewProperty label="Imposto" text={formatCurrency(grandTotal.imposto)} size="sm" />
                  <PageViewProperty
                    label="Margem"
                    text={
                      <div
                        style={{
                          color: grandTotal.margem > 0 ? 'green' : grandTotal.margem < 0 ? 'red' : 'orange',
                        }}
                      >
                        {formatCurrency(grandTotal.margem)}
                      </div>
                    }
                    size="sm"
                  />
                  <PageViewProperty
                    label="Período"
                    text={`${formatDateToString(dataInicial)} - ${formatDateToString(dataFinal)}`}
                    size="sm"
                  />
                  <PageViewProperty
                    label="Tempo"
                    text={`${getMonths(dataInicial, dataFinal).length} mês(es)`}
                    size="sm"
                  />
                </SimpleGrid>
              </Paper>
              <Space h="lg" />

              <Grid columns={2} align="center">
                <Grid.Col span={1}>
                  <Paper shadow="xs" p="md" withBorder>
                    <Pie {...getTotalConfig('novoPrecoComMargem', 'Receita Bruta')} height={250} />
                  </Paper>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Paper shadow="xs" p="md" withBorder>
                    <Pie {...getTotalConfig('preco', 'Custo')} height={250} />
                  </Paper>
                </Grid.Col>
              </Grid>
              <Grid columns={2} align="center">
                <Grid.Col span={1}>
                  <Paper shadow="xs" p="md" withBorder>
                    <Pie {...getTotalConfig('imposto', 'Imposto')} height={250} />
                  </Paper>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Paper shadow="xs" p="md" withBorder>
                    <Pie {...getTotalConfig('novaMargemFinal', 'Margem')} height={250} />
                  </Paper>
                </Grid.Col>
              </Grid>
            </Paper>
            <Space h="lg" />

            <Paper shadow="xs" p="md" withBorder>
              <div style={{ textAlign: 'center' }}>
                <PageSection size="lg" color="primary" label="Estimativa Mensal (Receita Bruta)" text="" />
              </div>
              <Space h="xs" />
              <Column {...monthlyConfig} />
            </Paper>
          </Paper>
        </div>
      ),
    });
  };

  const handleSubmit = async () => {
    const formResult = form.validate();
    if (formResult.hasErrors) {
      return;
    }

    try {
      setUpdating(true);

      const payload = buildPayload(null, null, false, null);
      await proposalsService.updateFinancial(payload);

      setTimeout(() => {
        window.location.reload();
      }, 350);

      showNotification({
        title: 'Proposta - Visualizar',
        message: `Precificação alterada com sucesso.`,
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: 'Proposta - Visualizar',
        message: error?.isBusinessException ? error.description : `Não foi possível difinir a precificação.`,
        color: 'red',
      });
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    const allRefs = getRef(null);
    const clickTipoToggle = (key: string) => {
      for (const [refKey, refValue] of allRefs) {
        if (refKey === key) {
          refValue.current.click();
          break;
        }
      }
      return null;
    };
    const disableTipoToggle = (key: string) => {
      for (const [refKey, refValue] of allRefs) {
        if (refKey === key) {
          refValue.current.disabled = true;
          break;
        }
      }
      return null;
    };

    for (const [refKey, refValue] of allRefs) {
      // eslint-disable-next-line no-continue
      if (!refValue.current) continue;

      // let receita = false;
      let compartilhado = false;
      let usarMargem = true;
      if (refKey.startsWith(FinanceiroEnum.PrecificacaoToggle)) {
        for (const [key, value] of Object.entries(refValue.current)) {
          if (key.startsWith('__reactProps') && (value as any)?.initially_enabled === 'true') {
            // receita = (value as any)?.receita === 'true';
            compartilhado = (value as any)?.compartilhado === 'true';
            usarMargem = (value as any)?.usar_margem === 'true';
            refValue.current.click();

            if (!usarMargem) {
              clickTipoToggle(
                refKey.replace(FinanceiroEnum.PrecificacaoToggle, FinanceiroEnum.PrecificacaoTipoToggleP)
              );
            }
            break;
          }
        }
        // if (props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira || receita || compartilhado) {
        if (props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira || compartilhado) {
          refValue.current.disabled = true;
          disableTipoToggle(
            refKey.replace(FinanceiroEnum.PrecificacaoToggle, FinanceiroEnum.PrecificacaoTipoToggleP)
          );
          disableTipoToggle(
            refKey.replace(FinanceiroEnum.PrecificacaoToggle, FinanceiroEnum.PrecificacaoTipoToggleM)
          );
        }
      }
    }
  }, []);

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <div>
            <PageSection
              size="lg"
              color={Feature.Home.Proposal.color}
              label="Financeiro"
              text="Precificação para todos os resíduos e serviços baseado em seus planos e cotações primárias."
            />
            <Space h="xs" />
            <div hidden={props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira}>
              <form id="proposal-financial" noValidate>
                <Grid columns={4}>
                  <Grid.Col span={1}>
                    <NumberInput
                      icon={<Percentage size={15} />}
                      label="Margem"
                      placeholder="Margem"
                      min={0}
                      precision={2}
                      step={0.1}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      required
                      {...form.getInputProps('margem')}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <NumberInput
                      icon={<Percentage size={15} />}
                      label="Imposto"
                      placeholder="Imposto"
                      min={0}
                      precision={2}
                      step={0.1}
                      stepHoldDelay={500}
                      stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                      required
                      {...form.getInputProps('imposto')}
                    />
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Button
                      style={{ marginTop: 21 }}
                      leftIcon={<Adjustments size={18} />}
                      type="button"
                      onClick={() => {
                        form.setValues({ ...form.values, trigger: form.values.trigger + 1 });
                      }}
                      loading={updating}
                      disabled={!hasPermission}
                      hidden={props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira}
                    >
                      Aplicar
                    </Button>
                  </Grid.Col>
                </Grid>
              </form>
            </div>
            {props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira && (
              <SimpleGrid cols={4}>
                <PageViewProperty label="Margem" text={`${Financial.Margin.toFixed(2)}%`} size="sm" />
                <PageViewProperty label="Imposto" text={`${Financial.Tax.toFixed(2)}%`} size="sm" />
              </SimpleGrid>
            )}
          </div>
          <Group>
            {buildEstimatesButton(
              props.proposal,
              'Proposta',
              props.proposal.dataInicial,
              props.proposal.dataFinal,
              null,
              null,
              true,
              null
            )}
            <Button
              color="accent"
              leftIcon={<DeviceFloppy size={18} />}
              type="button"
              form="proposal-financial"
              onClick={handleSubmit}
              loading={updating}
              disabled={!hasPermission}
              hidden={props.proposal.codigoPropostaStatus !== ProposalStatusEnum.EmAnaliseFinanceira}
            >
              Definir Precificação
            </Button>
          </Group>
        </Group>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Resíduos"
          text="Precificação para todos os resíduos da proposta de acordo com seus planos e cotações primárias."
        />
        <Space h="xs" />
        {buildResidues}
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Serviços"
          text="Precificação para todos os serviços da proposta de acordo com suas cotações primárias."
        />
        <Space h="xs" />
        {buildServices}
      </Paper>
    </div>
  );
}
