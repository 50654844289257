/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Group, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useModals } from '@mantine/modals';
import { EntityPaymentMethodType, EntityType } from '../../../../../../../models/core/entities.type';
import PaymentMethodFormViewActions from './PaymentMethodFormViewActions';
import PaymentMethodFormAddEdit from './PaymentMethodFormAddEdit';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { PaymentMethodType } from '../../../../../../../models/core/cache.type';
import { BancoType } from '../../../../../../../models/utils/brasilapi.type';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewProps = {
  referenceData: {
    paymentMethodTypeData: PaymentMethodType[];
    bancoTypeData: BancoType[];
  };
  entity: EntityType | null;
};

const PaymentMethodFormView = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [data, setData] = useState<EntityPaymentMethodType[]>(
    props.entity?.formasPagamento.map((x) => {
      return { ...x, action: Action.Nothing, id: x.idEntidadeFormaPagamento?.toString() || '' };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Forma',
      key: 'formaPagamento',
      dataIndex: 'formaPagamento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) =>
        a.formaPagamento.localeCompare(b.formaPagamento),
    },
    {
      title: 'Prazo (dias)',
      key: 'prazoPagamentoDias',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        const aValue = a.prazoPagamentoDias || 0;
        const bValue = b.prazoPagamentoDias || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityPaymentMethodType) => <div>{row?.prazoPagamentoDias || '-'}</div>,
    },
    {
      title: 'Tipo',
      key: 'tipo',
      dataIndex: 'recebimento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) =>
        a.recebimento === b.recebimento ? 0 : a.recebimento ? -1 : 1,
      render: (row: boolean) => <div>{row ? 'Recebimento' : 'Pagamento'}</div>,
    },
    {
      title: 'CNPJ/CPF',
      key: 'cnpj-cpf',
      width: '180px',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.cnpj || a.cpf || '').localeCompare(b.cnpj || b.cpf || '');
      },
      render: (row: EntityPaymentMethodType) => <div>{row?.cnpj || row?.cpf}</div>,
    },
    {
      title: 'Beneficiário',
      key: 'nome',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.nome || '').localeCompare(b.nome || '');
      },
      render: (row: EntityPaymentMethodType) => <div>{row?.nome || '-'}</div>,
    },
    {
      title: 'Dados do Pagamento',
      key: 'dadosPagamento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.banco || a.chavePix || '').localeCompare(b.banco || b.chavePix || '');
      },
      render: (row: EntityPaymentMethodType) => {
        if (row.codigoFormaPagamento === 'PX' && row.recebimento) {
          return <div>Chave PIX: {row.chavePix}</div>;
        }
        if (row.codigoFormaPagamento === 'TB' && row.recebimento) {
          return (
            <div>
              <div>Banco: {row.banco}</div>
              <div>
                Agência: {row.agencia} | Conta corrente: {row.contaCorrente}
                {`${!row.digitoVerificador ? '' : `-${row.digitoVerificador}`}`}
              </div>
            </div>
          );
        }
        return <div>-</div>;
      },
    },
    {
      title: 'Condição de Pagamento',
      key: 'condicaoPagamento',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.condicaoPagamento || '').localeCompare(b.condicaoPagamento || '');
      },
      render: (row: EntityPaymentMethodType) => (
        <TableCellEllipsis label={row?.condicaoPagamento} numberOfChars={20} toolTipWidth={300} />
      ),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EntityPaymentMethodType, b: EntityPaymentMethodType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EntityPaymentMethodType) => (
        <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityPaymentMethodType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <PaymentMethodFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EntityPaymentMethodType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeFormaPagamento) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Forma de Pagamento - ${item ? 'Editar' : 'Adicionar'}`,
          size: 'xl',
          closeOnClickOutside: false,
          children: (
            <PaymentMethodFormAddEdit
              referenceData={props.referenceData}
              origItem={
                item
                  ? props.entity?.formasPagamento.find(
                      (x) => x.idEntidadeFormaPagamento === item.idEntidadeFormaPagamento
                    ) || null
                  : null
              }
              item={item}
              idEntidade={props.entity?.idEntidade}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityPaymentMethodType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setData(tempEntity.formasPagamento);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Formas de Pagamento" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityPaymentMethodType) => item.id || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default PaymentMethodFormView;
