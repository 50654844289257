/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Divider, Grid, Modal, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Check, Eye, X } from 'tabler-icons-react';
import PageSection from '../../../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import {
  ProposalResiduePlanPackagingType,
  ProposalServiceType,
} from '../../../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../../../theme';
import { Feature } from '../../../../../../../../../utils/constants.utils';

type ViewProps = {
  data: ProposalResiduePlanPackagingType[];
  services: ProposalServiceType[];
};

export default function ResiduePlanGeneralPackagingView(props: ViewProps) {
  const [planItem, setPlanItem] = useState<ProposalResiduePlanPackagingType>();
  const [opened, setOpened] = useState(false);

  const columns: ColumnsType<ProposalResiduePlanPackagingType> = [
    {
      title: 'Acondicionamento',
      key: 'residuoAcondicionamento',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) =>
        a.residuoAcondicionamento.localeCompare(b.residuoAcondicionamento),
      render: (row: ProposalResiduePlanPackagingType) => {
        if (!row) {
          return '-';
        }
        return (
          <ProfileCardLink
            id={row.idResiduoAcondicionamento?.toString() || ''}
            name={row.residuoAcondicionamento || ''}
            nameSize="sm"
            description={row.residuoAcondicionamentoDescricao}
            descriptionSize="xs"
            linkPrefix="packaging"
            showLink
          />
        );
      },
    },
    {
      title: 'Quantidade',
      key: 'quantidade',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) => {
        const aValue = a.compartilhado ? a.quantidade / 100 : a.quantidade;
        const bValue = b.compartilhado ? b.quantidade / 100 : b.quantidade;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanPackagingType) => {
        if (!row.compartilhado) {
          return <div>{`${row.quantidade} ${row.quantidadeUnidadeMedida}`}</div>;
        }

        const servico = props.services.find(
          (x) => row.idPropostaServico && x.idPropostaServico === row.idPropostaServico
        );

        return <div>{`% de ${servico?.quantidade} ${servico?.frequenciaUnidadeMedida1}`}</div>;
      },
    },
    {
      title: 'Gerenciar?',
      key: 'gerenciar',
      dataIndex: 'gerenciar',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) =>
        a.gerenciar === b.gerenciar ? 0 : a.gerenciar ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Serviço Compartilhado',
      key: 'servico',
      sorter: (a: ProposalResiduePlanPackagingType, b: ProposalResiduePlanPackagingType) => {
        return (a.idPropostaServico || '').toString().localeCompare((b.idPropostaServico || '').toString());
      },
      render: (row: ProposalResiduePlanPackagingType) => {
        if (!row.compartilhado) {
          return '-';
        }

        const servico = props.services.find(
          (x) => row.idPropostaServico && x.idPropostaServico === row.idPropostaServico
        );

        return (
          <ProfileCardLink
            id={(row.id || row.idPropostaResiduoPlanoAcondicionamento || '-').toString()}
            name={servico!.servico}
            nameSize="sm"
            description={`${servico!.quantidade} ${servico!.frequenciaUnidadeMedida1} / ${
              servico!.frequenciaUnidadeMedida2
            }`}
            descriptionSize="xs"
            linkPrefix="packaging"
            showLink={false}
          />
        );
      },
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanPackagingType) => (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
          <ActionIcon
            size="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              const servico = props.services.find(
                (x) => row.idPropostaServico && x.idPropostaServico === row.idPropostaServico
              );

              // eslint-disable-next-line no-param-reassign
              (row as any).tempServico = servico;

              setPlanItem(row);
              setOpened(true);
            }}
          >
            <Eye size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Plano/Acondicionamento - Visualizar"
        size="50%"
      >
        {planItem ? (
          <div>
            <Paper shadow="xs" p="md" withBorder>
              <Grid columns={6}>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Acondicionamento"
                    text={
                      <ProfileCardLink
                        id={(planItem.idResiduoAcondicionamento || '').toString()}
                        name={planItem.residuoAcondicionamento || '-'}
                        nameSize="sm"
                        description={planItem.residuoAcondicionamentoDescricao}
                        descriptionSize="xs"
                        linkPrefix="packaging"
                        showLink
                      />
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Quantidade"
                    text={
                      !planItem.compartilhado
                        ? `${planItem.quantidade} ${planItem.quantidadeUnidadeMedida}`
                        : `% de ${(planItem as any).tempServico.quantidade} ${
                            (planItem as any).tempServico.frequenciaUnidadeMedida1
                          }`
                    }
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <PageViewProperty
                    label="Gerenciar?"
                    text={`${planItem.gerenciar ? 'Sim' : 'Não'}`}
                    size="sm"
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <PageViewProperty
                    label="Serviço Compartilhado"
                    text={
                      planItem.compartilhado ? (
                        <ProfileCardLink
                          id={planItem.idPropostaResiduoPlanoAcondicionamento!.toString()}
                          name={(planItem as any).tempServico.servico}
                          nameSize="sm"
                          description={`${(planItem as any).tempServico.quantidade} ${
                            (planItem as any).tempServico.frequenciaUnidadeMedida1
                          } / ${(planItem as any).tempServico.frequenciaUnidadeMedida2}`}
                          descriptionSize="xs"
                          linkPrefix="packaging"
                          showLink={false}
                        />
                      ) : (
                        '-'
                      )
                    }
                    size="sm"
                  />
                </Grid.Col>
              </Grid>
              <Divider my="sm" variant="dotted" />

              <SimpleGrid cols={1}>
                <PageViewProperty label="Observação" text={planItem.observacao || '-'} size="sm" />
              </SimpleGrid>
            </Paper>
            <Space h="lg" />

            <PageViewAudit
              idCriadoPor={planItem.idCriadoPor}
              criadoPor={planItem.criadoPor}
              dataCriacao={planItem.dataCriacao}
              idModificadoPor={planItem?.idModificadoPor || null}
              modificadoPor={planItem?.modificadoPor || null}
              dataModificacao={planItem?.dataModificacao || null}
              size="sm"
              gridSize={3}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>

      <PageSection
        size="lg"
        color={Feature.Home.Proposal.color}
        label="Acondicionamentos"
        text="Acondicionamentos para este plano."
      />
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={props.data}
        columns={columns}
        rowKey={(item: ProposalResiduePlanPackagingType) => item.idPropostaResiduoPlanoAcondicionamento || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
