/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Group, Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalServiceType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../../utils/formatter.utils';

type ViewProps = {
  data: ProposalServiceType;
};

export default function ServiceGeneralView(props: ViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Geral"
          text="Informações gerais do serviço como referências, datas, local, etc."
        />
        <Space h="xs" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Serviço"
              text={
                <ProfileCardLink
                  id={props.data.idServico.toString()}
                  name={props.data.servico || '-'}
                  nameSize="sm"
                  description={props.data.servicoDescricao}
                  descriptionSize="xs"
                  linkPrefix="services"
                  showLink
                />
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Quantidade" text={props.data.quantidade.toString()} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Referência"
              text={
                props.data.idResiduoAcondicionamento
                  ? 'Acondicionamento'
                  : props.data.idResiduoEquipamento
                  ? 'Equipamento'
                  : 'Fornecedor'
              }
              size="sm"
            />
          </Grid.Col>

          <Grid.Col span={props.data.idResiduoAcondicionamento ? 2 : 1}>
            {props.data.idResiduoAcondicionamento && (
              <Group position="apart">
                <PageViewProperty
                  label="Acondicionamento"
                  text={
                    <ProfileCardLink
                      id={(props.data.idResiduoAcondicionamento || '').toString()}
                      name={props.data.residuoAcondicionamento || '-'}
                      nameSize="sm"
                      description={props.data.residuoAcondicionamentoDescricao}
                      descriptionSize="xs"
                      linkPrefix="packaging"
                      showLink
                    />
                  }
                  size="sm"
                />
                <PageViewProperty label="Gerenciar?" text={props.data.gerenciar ? 'Sim' : 'Não'} size="sm" />
                <PageViewProperty
                  label="Compartilhado?"
                  text={props.data.compartilhado ? 'Sim' : 'Não'}
                  size="sm"
                />
              </Group>
            )}
            {props.data.idResiduoEquipamento && (
              <PageViewProperty
                label="Equipamento"
                text={
                  <ProfileCardLink
                    id={(props.data.idResiduoEquipamento || '').toString()}
                    name={props.data.residuoEquipamento || '-'}
                    nameSize="sm"
                    description={props.data.residuoEquipamentoDescricao}
                    descriptionSize="xs"
                    linkPrefix="equipment"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Data Inicial"
              text={formatDateToString(props.data.dataInicial)}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Data Final" text={formatDateToString(props.data.dataFinal)} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Frequência"
              text={`${
                !(props.data.idResiduoAcondicionamento || props.data.idResiduoEquipamento)
                  ? `${props.data.frequencia} `
                  : ''
              }${props.data.frequenciaUnidadeMedida1} / ${props.data.frequenciaUnidadeMedida2}`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Cobrar Tolerância?"
              text={props.data.cobrarTolerancia ? 'Sim' : 'Não'}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Turno"
              text={props.data.turno ? `${props.data.turno + 1}º Turno` : '-'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <PageViewProperty label="Jornada" text={props.data.jornada || '-'} size="sm" />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <SimpleGrid>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Cliente e Local"
          text="Cliente e local onde a prestação de serviço deverá ocorrer."
        />
        <Space h="xs" />

        <Grid columns={6}>
          <Grid.Col span={2}>
            {props.data.clienteCNPJ ? (
              <PageViewProperty
                label="Cliente"
                text={
                  <ProfileCardLink
                    id={props.data.idCliente?.toString() || ''}
                    name={props.data.clienteNomeFantasia || props.data.clienteRazaoSocial || '-'}
                    nameSize="sm"
                    description={
                      props.data.clienteNomeFantasia ? props.data.clienteRazaoSocial : props.data.clienteCNPJ
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Cliente"
                text={
                  <ProfileCardLink
                    id={props.data.idCliente?.toString() || ''}
                    name={props.data.clienteNome || '-'}
                    nameSize="sm"
                    description={props.data.clienteCPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Logradouro"
              text={`${props.data.logradouro}, ${props.data.numero}${
                props.data.complemento ? ` | ${props.data.complemento}` : ''
              }`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Bairro - Cidade"
              text={`${props.data.bairro} - ${props.data.cidade} / ${props.data.codigoEstado}`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="CEP" text={props.data.cep} size="sm" />
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
      />
    </div>
  );
}
