/* eslint-disable react-hooks/exhaustive-deps */
import {
  Affix,
  Button,
  Card,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Space,
  Text,
  ThemeIcon,
  Tooltip,
  Transition,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useWindowScroll } from '@mantine/hooks';
import { Tabs } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowNarrowUp,
  Building,
  BuildingSkyscraper,
  CalendarEvent,
  License,
  Reload,
} from 'tabler-icons-react';
import PageContent from '../../../components/core/PageContent/PageContent';
import PageHeader from '../../../components/core/PageHeader/PageHeader';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { CompanyType, UnitOfMeasureType } from '../../../models/core/cache.type';
import cacheUtils from '../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../utils/constants.utils';
import { newGuid } from '../../../utils/helper.utils';
import ContractGeneralTopFinance from './Charts/ContractGeneralTopFinance';
import ContractGeneralTopResidues from './Charts/ContractGeneralTopResidues';
import ContractGeneralTopServices from './Charts/ContractGeneralTopServices';
import EntityGeneral from './Charts/EntityGeneral';
import EntityGeneralTopCommission from './Charts/EntityGeneralTopCommission';
import EntityGeneralTopFinance from './Charts/EntityGeneralTopFinance';
import EventGeneralCorrections from './Charts/EventGeneralCorrections';
import FinancialMovement from './Charts/Managerial/Shared/FinancialMovement';
import FinancialPotencial from './Charts/Managerial/Shared/FinancialPotencial';
import PackagingEquipmentVehicleQuantity from './Charts/Managerial/Companies/PackagingEquipmentVehicleQuantity';
import QuantityPotencial from './Charts/Managerial/Shared/QuantityPotencial';

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
};

function DashboardManagerial() {
  const FORMAT = 'DD/MM/YYYY HH:mm:ss';
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();
  const [scroll, scrollTo] = useWindowScroll();

  const refCompanyGeneralFinance = useRef<any>();
  const refContractGeneralTopFinance = useRef<any>();
  const refContractGeneralTopResidues = useRef<any>();
  const refContractGeneralTopServices = useRef<any>();
  const refEntityGeneralTopFinance = useRef<any>();
  const refEntityGeneralTopCommission = useRef<any>();
  const refEntityGeneral = useRef<any>();
  const refEventGeneralCorrections = useRef<any>();

  const refFinancialPotencial = useRef<any>();
  const refQuantityPotencial = useRef<any>();
  const refFinancialMovement = useRef<any>();
  const refPackagingEquipmentVehicleQuantity = useRef<any>();

  const [filterData, setFilterData] = useState<{
    companyData: CompanyType[];
    unitOfMeasures: UnitOfMeasureType[];
  }>({
    companyData: [],
    unitOfMeasures: [],
  });

  const [data, setData] = useState<{ cacheDate: string; filter: FilterData | null }>({
    cacheDate: '',
    filter: null,
  });
  const [loading, setLoading] = useState(false);

  const tabs = [
    {
      key: 'Empresas',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Dashboard.color} variant="outline">
            <BuildingSkyscraper size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Dashboard.color} weight={500}>
            Empresas
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <FinancialPotencial
                ref={refFinancialPotencial}
                idEntidade={undefined}
                color={Feature.Home.Dashboard.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <QuantityPotencial
                ref={refQuantityPotencial}
                idEntidade={undefined}
                color={Feature.Home.Dashboard.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <FinancialMovement
                ref={refFinancialMovement}
                idEntidade={undefined}
                color={Feature.Home.Dashboard.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <PackagingEquipmentVehicleQuantity
                ref={refPackagingEquipmentVehicleQuantity}
                color={Feature.Home.Dashboard.color}
                height={undefined}
              />
            </Grid.Col>
          </Grid>
        </div>
      ),
      forceRender: true,
    },
    {
      key: 'Contratos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Dashboard.color} variant="outline">
            <License size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Dashboard.color} weight={500}>
            Contratos
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <ContractGeneralTopFinance ref={refContractGeneralTopFinance} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <ContractGeneralTopResidues ref={refContractGeneralTopResidues} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <ContractGeneralTopServices ref={refContractGeneralTopServices} height={undefined} />
            </Grid.Col>
          </Grid>
        </div>
      ),
      forceRender: false,
    },
    {
      key: 'Entidades',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Dashboard.color} variant="outline">
            <BuildingSkyscraper size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Dashboard.color} weight={500}>
            Entidades
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntityGeneral ref={refEntityGeneral} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntityGeneralTopFinance ref={refEntityGeneralTopFinance} height={undefined} />
            </Grid.Col>
          </Grid>
          <Space h="lg" />

          <Grid columns={4}>
            <Grid.Col span={4}>
              <EntityGeneralTopCommission ref={refEntityGeneralTopCommission} height={undefined} />
            </Grid.Col>
          </Grid>
        </div>
      ),
      forceRender: false,
    },
    {
      key: 'Eventos',
      label: (
        <Group>
          <ThemeIcon color={Feature.Home.Dashboard.color} variant="outline">
            <CalendarEvent size={18} />
          </ThemeIcon>
          <Text size="md" color={Feature.Home.Dashboard.color} weight={500}>
            Eventos
          </Text>
        </Group>
      ),
      children: (
        <div>
          <Grid columns={4}>
            <Grid.Col span={4}>
              <EventGeneralCorrections ref={refEventGeneralCorrections} height={undefined} />
            </Grid.Col>
          </Grid>
        </div>
      ),
      forceRender: false,
    },
  ];

  const form = useForm<FilterData>({
    initialValues: {
      periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
    },
  });

  const refresh = () => {
    const chartInput = { filter: form.values };
    Promise.all([
      refCompanyGeneralFinance.current?.load(chartInput),
      refContractGeneralTopFinance.current?.load(chartInput),
      refContractGeneralTopResidues.current?.load(chartInput),
      refContractGeneralTopServices.current?.load(chartInput),
      refEntityGeneralTopFinance.current?.load(chartInput),
      refEntityGeneralTopCommission.current?.load(chartInput),
      refEntityGeneral.current?.load(),
      refEventGeneralCorrections.current?.load(chartInput),
      refFinancialPotencial.current?.load(chartInput),
      refQuantityPotencial.current?.load(chartInput),
      refFinancialMovement.current?.load(chartInput),
      refPackagingEquipmentVehicleQuantity.current?.load(chartInput),
    ]).then(() => {
      const now = moment().format(FORMAT);

      const newData = {
        cacheDate: now,
        guid: newGuid(),
        filter: form.values,
      };
      sessionStorage.setItem(SessionStorageKey.DashboardManagerial, JSON.stringify(newData));
      setData(newData);
    });
  };

  useEffect(() => {
    const loadFilterData = async () => {
      setLoading(true);

      setFilterData({
        companyData: await cacheUtils.listCompanies(),
        unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
      });
      setLoading(false);

      const cacheResult = JSON.parse(sessionStorage.getItem(SessionStorageKey.DashboardManagerial) || 'null');
      if (cacheResult) {
        const { periodo } = cacheResult.filter;
        periodo[0] = !periodo[0] ? null : new Date(periodo[0]);
        periodo[1] = !periodo[1] ? null : new Date(periodo[1]);
        setData({ cacheDate: cacheResult.cacheDate, filter: { ...cacheResult.filter, periodo } });
        form.setValues(cacheResult.filter);
      } else {
        refresh();
      }
    };

    loadFilterData();
  }, [currentUser.gerencial, navigate]);

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Dashboard}
        title="Dashboard Gerencial"
        description="Indicadores financeiros e quantitivos gerais e específicos de contratos, entidades e eventos."
        buttons={[
          (
            <DateRangePicker
              placeholder="Período"
              clearable={false}
              amountOfMonths={2}
              allowSingleDateInRange
              {...form.getInputProps('periodo')}
            />
          ) as any,
          (
            <Select
              icon={<Building size={15} />}
              placeholder="Empresa"
              data={filterData.companyData.map((x) => {
                return {
                  value: x.idEmpresa.toString(),
                  label: x.empresa,
                };
              })}
              searchable
              clearable={currentUser.executivo}
              disabled={!currentUser.executivo}
              {...form.getInputProps('idEmpresa')}
            />
          ) as any,
          (
            <Tooltip label={`Atualizado em ${data.cacheDate}`}>
              <Button color="accent" leftIcon={<Reload size={18} />} disabled={loading} onClick={refresh}>
                Atualizar
              </Button>
            </Tooltip>
          ) as any,
        ]}
      />
      <PageContent>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} />
          <Paper shadow="xs" p="md" withBorder>
            <Tabs items={tabs} defaultActiveKey="Empresas" />
          </Paper>
        </div>
      </PageContent>

      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default DashboardManagerial;
