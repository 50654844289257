/* eslint-disable react/destructuring-assignment */
import { Hierarchy, Mail, User } from 'tabler-icons-react';
import { Divider, NumberInput, Paper, Select, SimpleGrid, Space, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DepartmentType, Permission, PermissionDisabled } from '../../../../../models/core/departments.type';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import { Feature } from '../../../../../utils/constants.utils';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import DepartmentPermission from '../../../departments/components/DepartmentPermission';

type FormViewData = {
  nome: string;
  email: string;
  idDepartamento: number;
  validadeHoras: number;
};

type FormViewProps = {
  save(data: FormViewData): void;
  departments: DepartmentType[];
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const [currentUser] = useCurrentUser();

  const form = useForm<FormViewData>({
    initialValues: {
      nome: '',
      email: '',
      idDepartamento: -1,
      validadeHoras: 72,
    },
    validate: {
      nome: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
      email: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        if (!/^\S+@\S+$/.test(value.trim())) {
          return 'Formato inválido (Ex: nome@dominio.com)';
        }
        return null;
      },
      idDepartamento: (value) => (value.toString() !== '-1' ? null : 'Campo obrigatório'),
    },
  });

  return (
    <form id="invitation-add" onSubmit={form.onSubmit(props.save)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.Invitation.color} label="Identificação" text="" />
        <Space h="xs" />
        <SimpleGrid cols={4}>
          <TextInput
            icon={<User size={15} />}
            label="Nome"
            placeholder="Digite o nome"
            maxLength={50}
            {...form.getInputProps('nome')}
          />
          <TextInput
            icon={<Mail size={15} />}
            label="E-mail"
            placeholder="Digite e-mail"
            type="email"
            maxLength={100}
            {...form.getInputProps('email')}
          />
          <NumberInput
            defaultValue={72}
            min={1}
            max={3120}
            placeholder="Validade"
            label="Validade do Convite (horas)"
            required
            {...form.getInputProps('validadeHoras')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.Invitation.color} label="Acesso" text="" />
        <Space h="xs" />

        <SimpleGrid cols={4}>
          <Select
            icon={<Hierarchy size={15} />}
            label="Empresa/Departamento"
            placeholder="Selecione..."
            data={props.departments.map((x) => {
              return {
                value: x.idDepartamento.toString(),
                label: x.departamento,
                group: x.empresa || 'N/A',
                disabled: !!(x.executivo && !currentUser.executivo),
              };
            })}
            searchable
            required
            {...form.getInputProps('idDepartamento')}
          />
          <div style={{ marginTop: -5 }}>
            <PageViewProperty
              label="Acesso Executivo?"
              text={
                props.departments?.find(
                  (x) => Number(x.idDepartamento) === Number(form.values.idDepartamento)
                )?.executivo
                  ? 'Sim'
                  : 'Não'
              }
              size="sm"
            />
          </div>
          <div style={{ marginTop: -5 }}>
            <PageViewProperty
              label="Acesso Gerencial?"
              text={
                props.departments?.find(
                  (x) => Number(x.idDepartamento) === Number(form.values.idDepartamento)
                )?.gerencial
                  ? 'Sim'
                  : 'Não'
              }
              size="sm"
            />
          </div>
          <div style={{ marginTop: -5 }}>
            <PageViewProperty
              label="Acesso de Suporte?"
              text={
                props.departments?.find(
                  (x) => Number(x.idDepartamento) === Number(form.values.idDepartamento)
                )?.suporte
                  ? 'Sim'
                  : 'Não'
              }
              size="sm"
            />
          </div>
        </SimpleGrid>
        <Space h="xs" />

        <Paper shadow="xs" p="md" withBorder>
          <DepartmentPermission
            ref={null}
            idDepartamento={form.values.idDepartamento}
            permissoes={Object.values(Permission)}
            permissoesDesabilidatas={Object.values(PermissionDisabled) as any as Permission[]}
            permissoesAtuais={
              props.departments.find((x) => x.idDepartamento === Number(form.values.idDepartamento))
                ?.permissoes ?? []
            }
            modoEdicao={false}
          />
        </Paper>
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
