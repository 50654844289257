import { Button, Card, Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { CompanyType } from '../../../../models/core/cache.type';
import { DepartmentType } from '../../../../models/core/departments.type';
import departmentsService from '../../../../services/core/departments.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature } from '../../../../utils/constants.utils';
import { FormView, FormViewData } from './components/FormView';

type DataResult = {
  loading: boolean;
  companyData: CompanyType[] | null;
  departmentData: DepartmentType | null;
};

function DepartmentAddEdit() {
  const navigate = useNavigate();
  // const location: any = useLocation();
  const { idDepartamento } = useParams();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    companyData: null,
    departmentData: null,
  });
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      let companyData: CompanyType[] = [];
      let { departmentData } = dataResult;
      try {
        companyData = await cacheUtils.listCompanies();
        if (idDepartamento && dataResult.departmentData === null) {
          departmentData = await departmentsService.select({ idDepartamento: Number(idDepartamento) });
        }
      } catch (error: any) {
        showNotification({
          title: `Departamento - ${!idDepartamento ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException
            ? error.description
            : 'Não foi possível carregar o departamento.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, companyData, departmentData });
      }
    };
    if (dataResult.companyData === null || (idDepartamento && dataResult.departmentData === null)) {
      fetchData();
    }
  }, [idDepartamento, navigate, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);
      let newModel;
      if (!idDepartamento) {
        newModel = await departmentsService.insert({
          ...inputFormViewData,
          idEmpresa: Number(inputFormViewData.idEmpresa),
        });
      } else {
        await departmentsService.update({
          ...inputFormViewData,
          idDepartamento: Number(idDepartamento),
          idEmpresa: Number(inputFormViewData.idEmpresa),
        });
      }
      showNotification({
        title: `Departamento - ${!idDepartamento ? 'Adicionar' : 'Editar'}`,
        message: 'Departamento salvo com sucesso.',
        color: 'green',
      });
      navigate(`/departments/${newModel?.idDepartamento || idDepartamento}`, {
        state: {
          department: newModel || null,
        },
      });
    } catch (error: any) {
      showNotification({
        title: `Departamento - ${!idDepartamento ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar o departamento.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Managerial.Department}
        title={`Departamento - ${!idDepartamento ? 'Adicionar' : 'Editar'}`}
        description={`${!idDepartamento ? 'Adicione' : 'Edite'} um departamento.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            type="submit"
            form="department-add-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
          >
            Salvar
          </Button>,
        ]}
      />
      {dataResult.companyData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView
            companies={dataResult.companyData}
            department={dataResult.departmentData}
            loading={saving}
            save={save}
          />
        </PageContent>
      )}
    </Card>
  );
}

export default DepartmentAddEdit;
