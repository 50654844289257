/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Button, Group, LoadingOverlay, Paper, Space, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Table } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { DeviceFloppy, ExternalLink, InfoCircle } from 'tabler-icons-react';
import { EventStatusType } from '../../../models/core/cache.type';
import { EventMeasurementSearchResponseType, EventRelationType } from '../../../models/core/events.type';
import { UserType } from '../../../models/core/users.type';
import eventsService from '../../../services/core/events.service';
import theme from '../../../theme';
import { formatCurrency, formatDateToString } from '../../../utils/formatter.utils';
import { newGuid } from '../../../utils/helper.utils';
import TableCellEllipsis from '../TableCellEllipsis/TableCellEllipsis';
import { EventMeasurementSearchFilter, FilterData } from './EventMeasurementSearchFilter';

type FormViewProps = {
  referenceData: {
    eventStatusData: EventStatusType[];
    userData: UserType[];
  };
  idsToBeDisabled: number[];
  idContrato: number;
  dataInicial: Date | undefined;
  dataFinal: Date | undefined;
  callback(items: EventMeasurementSearchResponseType[] | EventRelationType[], confirmed: boolean): void;
};

export default function EventMeasurementSearch(props: FormViewProps) {
  const [data, setData] = useState<EventMeasurementSearchResponseType[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<EventMeasurementSearchResponseType[]>([]);

  const handleSubmit = async () => {
    props.callback(selectedData, true);
  };

  const clear = () => {
    setData([]);
  };

  const filter = async (inputFilterData: FilterData) => {
    const defaultLimit = 100;

    try {
      setLoading(true);

      const result = await eventsService.searchMeasurement({
        ...inputFilterData,
        idContrato: props.idContrato,
        dataInicial: inputFilterData.dataInicial
          ? moment(inputFilterData.dataInicial).format('yyyy-MM-DD')
          : null,
        dataFinal: inputFilterData.dataFinal ? moment(inputFilterData.dataFinal).format('yyyy-MM-DD') : null,
        limit: defaultLimit,
        offset: 0,
      });

      clear();
      setData(result);
    } catch (error: any) {
      showNotification({
        title: 'Eventos',
        message: error?.isBusinessException ? error.description : 'Não foi possível pesquisar eventos.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EventMeasurementSearchResponseType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: EventMeasurementSearchResponseType) => ({
      disabled: props.idsToBeDisabled.includes(record.idEvento),
      name: record.idEvento.toString(),
    }),
  };

  return (
    <div>
      <EventMeasurementSearchFilter
        eventStatusData={props.referenceData.eventStatusData}
        users={props.referenceData.userData}
        filter={filter}
        clear={clear}
        loading={loading}
        dataInicial={props.dataInicial}
        dataFinal={props.dataFinal}
      />
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} />
        <Paper shadow="xs" p="md" withBorder>
          <Table
            showSorterTooltip={false}
            size="small"
            dataSource={data}
            columns={[
              {
                title: '#',
                key: 'idEvento',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = a.idEvento;
                  const bValue = b.idEvento;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventMeasurementSearchResponseType) => row.idEvento,
              },
              {
                title: 'Tipo',
                key: 'eventoTipo',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) =>
                  a.eventoTipo.localeCompare(b.eventoTipo),
                render: (row: EventMeasurementSearchResponseType) => (
                  <Badge variant="outline">{row.eventoTipo}</Badge>
                ),
              },
              {
                title: 'Descrição',
                key: 'descricao',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = a.automatico
                    ? a.descricao
                    : a.resumoJSON?.residuo?.residuoCliente || a.resumoJSON?.servico?.servico || a.descricao;
                  const bValue = b.automatico
                    ? b.descricao
                    : b.resumoJSON?.residuo?.residuoCliente || b.resumoJSON?.servico?.servico || b.descricao;
                  return (aValue || '').localeCompare(bValue || '');
                },
                render: (row: EventMeasurementSearchResponseType) => {
                  let value = row.automatico
                    ? row.descricao
                    : `${
                        row.resumoJSON?.residuo?.residuoCliente || row.resumoJSON?.servico?.servico || '-'
                      } | ${row.descricao}`;
                  if (value.startsWith('- |')) {
                    value = value.replace('- |', '');
                  }
                  return <TableCellEllipsis label={value} numberOfChars={50} toolTipWidth={300} />;
                },
              },
              {
                title: 'Data',
                key: 'data',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) =>
                  new Date(a.data).valueOf() - new Date(b.data).valueOf(),
                render: (row: EventMeasurementSearchResponseType) => formatDateToString(row.data),
              },
              {
                title: 'Receita',
                key: 'receita',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = a.receita || 0;
                  const bValue = b.receita || 0;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventMeasurementSearchResponseType) => <div>{formatCurrency(row.receita)}</div>,
              },
              {
                title: 'Despesa',
                key: 'despesa',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = a.despesa || 0;
                  const bValue = b.despesa || 0;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventMeasurementSearchResponseType) => {
                  if ((row.resumoJSON?.comissao?.porcentagem || 0) > 0) {
                    return (
                      <Group>
                        <Tooltip
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label={<div>Despesa variável.</div>}
                        >
                          <div style={{ marginTop: 5, marginRight: -15 }}>
                            <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                          </div>
                        </Tooltip>
                        <div>{`${formatCurrency(row.despesa)}`}</div>
                      </Group>
                    );
                  }

                  return <div>{formatCurrency(row.despesa)}</div>;
                },
              },
              {
                title: 'Imposto',
                key: 'imposto',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = a.imposto || 0;
                  const bValue = b.imposto || 0;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventMeasurementSearchResponseType) => <div>{formatCurrency(row.imposto)}</div>,
              },
              {
                title: 'Balanço',
                key: 'balanco',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = a.receita - a.despesa - a.imposto;
                  const bValue = b.receita - b.despesa - b.imposto;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventMeasurementSearchResponseType) => {
                  const total = row.receita - row.despesa - row.imposto;
                  const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

                  return <div style={{ color }}>{formatCurrency(total)}</div>;
                },
              },
              {
                title: 'Documentos',
                key: 'documentos',
                sorter: (a: EventMeasurementSearchResponseType, b: EventMeasurementSearchResponseType) => {
                  const aValue = (a.resumoJSON?.documentos || []).length;
                  const bValue = (b.resumoJSON?.documentos || []).length;
                  if (aValue === bValue) {
                    return 0;
                  }
                  return aValue > bValue ? 1 : -1;
                },
                render: (row: EventMeasurementSearchResponseType) => {
                  const documentos = row.resumoJSON?.documentos || [];
                  if (documentos.length > 0) {
                    return (
                      <Group spacing="xs">
                        {documentos.map((y) => {
                          return (
                            <Badge
                              key={newGuid()}
                              variant="outline"
                            >{`${y.documentoTipo} - ${y.numeroDocumento}`}</Badge>
                          );
                        })}
                      </Group>
                    );
                  }
                  return '-';
                },
              },
              {
                title: 'Ações',
                width: '100px',
                render: (row: EventMeasurementSearchResponseType) => (
                  <Tooltip withArrow transition="fade" transitionDuration={200} label="Abrir">
                    <ActionIcon
                      size="sm"
                      color="primary"
                      style={{ borderColor: theme?.colors?.primary?.[6] }}
                      variant="outline"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.setAttribute('href', `/events?id=${row.idEvento}`);
                        link.setAttribute('target', '_blank');
                        link.click();
                      }}
                    >
                      <ExternalLink size={15} color={theme?.colors?.primary?.[6]} />
                    </ActionIcon>
                  </Tooltip>
                ),
              },
            ]}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            rowKey={(item: EventMeasurementSearchResponseType) => item.idEvento}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
            }}
          />
        </Paper>
        <Space h="xl" />
        <Group position="right">
          <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
            Salvar
          </Button>
        </Group>
      </div>
    </div>
  );
}
