/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Group, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useModals } from '@mantine/modals';
import { EntityDestinationType, EntityType } from '../../../../../../../models/core/entities.type';
import DestinationFormViewActions from './DestinationFormViewActions';
import DestinationFormAdd from './DestinationFormSearch';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { Action } from '../../../../../../../models/core/core.type';

type FormViewProps = {
  entity: EntityType | null;
};

const DestinationFormView = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [data, setData] = useState<EntityDestinationType[]>(
    props.entity?.residuoDestinosFinais.map((x) => {
      return {
        ...x,
        action: Action.Nothing,
        id: x.idEntidadeResiduoDestinoFinal?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Destino Final',
      key: 'residuoDestinoFinal',
      dataIndex: 'residuoDestinoFinal',
      sorter: (a: EntityDestinationType, b: EntityDestinationType) =>
        a.residuoDestinoFinal.localeCompare(b.residuoDestinoFinal),
    },
    {
      title: 'Descrição',
      key: 'residuoDestinoFinalDescricao',
      sorter: (a: EntityDestinationType, b: EntityDestinationType) =>
        (a.residuoDestinoFinalDescricao || '').localeCompare(b.residuoDestinoFinalDescricao || ''),
      render: (row: EntityDestinationType) => <div>{row?.residuoDestinoFinalDescricao || '-'}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityDestinationType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DestinationFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (items: EntityDestinationType[], action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === items[0]?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeResiduoDestinoFinal) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'adicionar':
        modals.openModal({
          title: `Destino Final - Adicionar`,
          size: '60%',
          closeOnClickOutside: false,
          children: (
            <DestinationFormAdd
              items={data}
              idEntidade={props.entity?.idEntidade}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        if (items.length > 0) {
          setData(data.concat(items));
        }
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityDestinationType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setData(tempEntity.residuoDestinosFinais);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Destinos Finais" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(data, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityDestinationType) => item.id || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default DestinationFormView;
