/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import {
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  Space,
  Switch,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { DatePicker } from '@mantine/dates';
import { Atom, Calendar, InfoCircle, Microscope, Notes, Ruler2 } from 'tabler-icons-react';
import EntityAddressList from '../../../../../../../../components/core/EntityAddressList/EntityAddressList';
import EntitySearch from '../../../../../../../../components/core/EntitySearch/EntitySearch';
import PageSection from '../../../../../../../../components/core/PageSection/PageSection';
import ResidueSearch from '../../../../../../../../components/core/ResidueSearch/ResidueSearch';
import { ProposalGeneralData } from '../../../../../../../../contexts/core/proposals/ProposalGeneral.context';
import { useProposalResidueContext } from '../../../../../../../../contexts/core/proposals/ProposalResidue.context';
import {
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../models/core/core.type';
import {
  EntityAddressType,
  EntitySearchResponseType,
} from '../../../../../../../../models/core/entities.type';
import { ProposalResidueType } from '../../../../../../../../models/core/proposals.type';
import { ResidueType } from '../../../../../../../../models/core/residues.type';
import { Feature } from '../../../../../../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../../../../../../utils/formatter.utils';
import {
  buildFakeAuditObject,
  dateDiffInDaysDisregardTime,
  newGuid,
  tryGetDateISOFormat,
} from '../../../../../../../../utils/helper.utils';
import theme from '../../../../../../../../theme';

type FormViewData = {
  action: Action;
  id: string;
  idResiduo: number | null;

  residuoData: ResidueType | null;
  residuo: string;
  codigoResiduoEstadoFisico: string | null;

  residuoCliente: string;
  residuoClientePerigoso: boolean;

  minimoAceitavel: number | null;
  minimoAceitavelIdUnidadeMedida1: string | null;
  minimoAceitavelIdUnidadeMedida2: string | null;
  estimativa: number | null;
  estimativaIdUnidadeMedida1: string | null;
  estimativaIdUnidadeMedida2: string | null;

  dataInicial: Date;
  dataFinal: Date;
  faturamentoDireto: boolean;
  compra: boolean;
  cobrarTolerancia: boolean;

  clienteData: EntitySearchResponseType | null;
  idCliente: number | null;
  cliente: string;
  enderecoData: EntityAddressType | null;
  idEntidadeEndereco: number | null;
  endereco: string;

  tecnologiaAtual: string | null;
  observacao: string | null;
};

type ProposalFormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
    entityTypeData: EntityTypeType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
  };
  proposalGeneralData: ProposalGeneralData;
  origItem: ProposalResidueType | null;
  item: ProposalResidueType | null;
  idProposta: number | undefined;
  disabled: boolean;
  callback(item: ProposalResidueType | null, action: string, confirmed: boolean): void;
};

const ResidueGeneralFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { proposalResidueData, setProposalResidueData } = useProposalResidueContext();

  const buildResidueDataFromItem = (item: ProposalResidueType | null): ResidueType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idResiduo: Number(item.idResiduo),
      residuo: item.residuo,
      perigoso: item.perigoso,
    };
  };

  const buildClienteDataFromItem = (item: ProposalResidueType | null): EntitySearchResponseType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idCliente),
      tipos: [],
      cnpj: item.clienteCNPJ || null,
      razaoSocial: item.clienteRazaoSocial || null,
      nomeFantasia: item.clienteNomeFantasia || null,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.clienteCPF || null,
      nome: item.clienteNome || null,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const buildEnderecoDataFromItem = (item: ProposalResidueType | null): EntityAddressType | null => {
    if (!item) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidadeEndereco: item.idEntidadeEndereco,
      idEntidade: item.idCliente,
      codigoEnderecoTipo: '',
      enderecoTipo: '',
      codigoEstado: item.codigoEstado,
      estado: '',
      cidade: item.cidade,
      cidadeCodigoIBGE: item.cidadeCodigoIBGE || null,
      bairro: item.bairro,
      logradouro: item.logradouro,
      numero: item.numero,
      complemento: item.complemento || null,
      referencia: item.referencia || null,
      cep: item.cep,
      latitude: item.latitude || null,
      longitude: item.longitude || null,
      contatos: [],
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      residuoData: buildResidueDataFromItem(props.item),
      idResiduo: props.item?.idResiduo || null,
      residuo: props.item?.residuo || '',
      codigoResiduoEstadoFisico: props.item?.codigoResiduoEstadoFisico?.toString() || null,

      residuoCliente: props.item?.residuoCliente || '',
      residuoClientePerigoso: props.item?.residuoClientePerigoso || false,

      minimoAceitavel: props.item?.minimoAceitavel || null,
      minimoAceitavelIdUnidadeMedida1: props.item?.minimoAceitavelIdUnidadeMedida1?.toString() || null,
      minimoAceitavelIdUnidadeMedida2: props.item?.minimoAceitavelIdUnidadeMedida2?.toString() || null,
      estimativa: props.item?.estimativa || 1,
      estimativaIdUnidadeMedida1: props.item?.estimativaIdUnidadeMedida1?.toString() || null,
      estimativaIdUnidadeMedida2: props.item?.estimativaIdUnidadeMedida2?.toString() || null,

      dataInicial: props.item
        ? formatDateStringToDate(tryGetDateISOFormat(props.item.dataInicial))
        : props.proposalGeneralData.dataInicial,

      dataFinal: props.item
        ? formatDateStringToDate(tryGetDateISOFormat(props.item.dataFinal))
        : props.proposalGeneralData.dataFinal,

      faturamentoDireto: props.item?.faturamentoDireto || false,
      compra: props.item?.compra || false,
      cobrarTolerancia: props.item?.cobrarTolerancia || false,

      clienteData: buildClienteDataFromItem(props.item) || props.proposalGeneralData.cliente,
      idCliente: props.item?.idCliente || props.proposalGeneralData.cliente?.idEntidade || null,
      cliente:
        props.item?.clienteNomeFantasia ||
        props.item?.clienteRazaoSocial ||
        props.item?.clienteNome ||
        props.proposalGeneralData.cliente?.nomeFantasia ||
        props.proposalGeneralData.cliente?.razaoSocial ||
        props.proposalGeneralData.cliente?.nome ||
        '',

      enderecoData: buildEnderecoDataFromItem(props.item),
      idEntidadeEndereco: props.item?.idEntidadeEndereco || null,
      endereco: props.item?.idEntidadeEndereco
        ? `${props.item.logradouro}, ${props.item.numero} | ${props.item.bairro} - ${props.item.cidade}/${props.item.codigoEstado}`
        : '',

      tecnologiaAtual: props.item?.tecnologiaAtual || '',
      observacao: props.item?.observacao || '',
    },
    validate: {
      residuo: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      residuoCliente: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      codigoResiduoEstadoFisico: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      cliente: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },
      endereco: (value) => {
        return value.trim() !== '' ? null : 'Campo obrigatório';
      },

      minimoAceitavel: (value: number | null, values: FormViewData) => {
        if (
          (value === null || value === undefined) &&
          (values.minimoAceitavelIdUnidadeMedida1 || values.minimoAceitavelIdUnidadeMedida2)
        ) {
          return 'Campo obrigatório';
        }
        if (value === 0 && values.minimoAceitavelIdUnidadeMedida1 && values.minimoAceitavelIdUnidadeMedida2) {
          return 'Mínimo Aceitável deve ser maior do que zero';
        }
        return null;
      },
      minimoAceitavelIdUnidadeMedida1: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.minimoAceitavel || values.minimoAceitavelIdUnidadeMedida2) {
          return 'Campo obrigatório';
        }
        return null;
      },
      minimoAceitavelIdUnidadeMedida2: (value: string | null, values: FormViewData) => {
        if (value) {
          if (!props.referenceData.unitOfMeasures.find((x) => Number(x.idUnidadeMedida) === Number(value))) {
            return 'Campo obrigatório';
          }
        } else if (values.minimoAceitavel || values.minimoAceitavelIdUnidadeMedida1) {
          return 'Campo obrigatório';
        }
        return null;
      },

      estimativa: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      estimativaIdUnidadeMedida1: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      estimativaIdUnidadeMedida2: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      dataInicial: (value) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataInicial, true) < 0 ||
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataFinal, true) > 0
        ) {
          return `Data fora do limite (${formatDateToString(
            props.proposalGeneralData.dataInicial
          )} - ${formatDateToString(props.proposalGeneralData.dataFinal)})`;
        }
        return null;
      },
      dataFinal: (value, values: FormViewData) => {
        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        if (value < values.dataInicial) {
          return 'Data final não pode ser anterior à data inicial';
        }
        if (
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataInicial, true) < 0 ||
          dateDiffInDaysDisregardTime(value, props.proposalGeneralData.dataFinal, true) > 0
        ) {
          return `Data fora do limite (${formatDateToString(
            props.proposalGeneralData.dataInicial
          )} - ${formatDateToString(props.proposalGeneralData.dataFinal)})`;
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduo || ''}
    |${props.origItem?.codigoResiduoEstadoFisico || ''}
    |${props.origItem?.residuoCliente || ''}
    |${props.origItem?.residuoClientePerigoso || ''}
    |${Number(props.origItem?.minimoAceitavel) || ''}
    |${props.origItem?.minimoAceitavelIdUnidadeMedida1 || ''}
    |${props.origItem?.minimoAceitavelIdUnidadeMedida2 || ''}
    |${Number(props.origItem?.estimativa) || ''}
    |${props.origItem?.estimativaIdUnidadeMedida1 || ''}
    |${props.origItem?.estimativaIdUnidadeMedida2 || ''}
    |${formatDateToString(props.origItem?.dataInicial || new Date()) || ''}
    |${formatDateToString(props.origItem?.dataFinal || new Date()) || ''}
    |${props.origItem?.faturamentoDireto || ''}
    |${props.origItem?.compra || ''}
    |${props.origItem?.cobrarTolerancia || ''}
    |${props.origItem?.idCliente || ''}
    |${props.origItem?.idEntidadeEndereco || ''}
    |${props.origItem?.tecnologiaAtual || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduo || ''}
    |${form.values.codigoResiduoEstadoFisico || ''}
    |${form.values.residuoCliente || ''}
    |${form.values.residuoClientePerigoso || ''}
    |${Number(form.values.minimoAceitavel) || ''}
    |${form.values.minimoAceitavelIdUnidadeMedida1 || ''}
    |${form.values.minimoAceitavelIdUnidadeMedida2 || ''}
    |${Number(form.values.estimativa) || ''}
    |${form.values.estimativaIdUnidadeMedida1 || ''}
    |${form.values.estimativaIdUnidadeMedida2 || ''}
    |${formatDateToString(form.values.dataInicial) || ''}
    |${formatDateToString(form.values.dataFinal) || ''}
    |${form.values.faturamentoDireto || ''}
    |${form.values.compra || ''}
    |${form.values.cobrarTolerancia || ''}
    |${form.values.idCliente || ''}
    |${form.values.idEntidadeEndereco || ''}
    |${form.values.tecnologiaAtual || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResidueType | null {
      if (form.validate().hasErrors) {
        throw Error('Existem pendências a serem corrigidas.');
      }
      let formValues = form.values;

      if (props.origItem) {
        if (isModified()) {
          formValues.action = Action.Modify;
        } else {
          formValues = {
            ...formValues,
            ...props.origItem,
            action: Action.Nothing,
            minimoAceitavelIdUnidadeMedida1: formValues.minimoAceitavelIdUnidadeMedida1?.toString() || '',
            minimoAceitavelIdUnidadeMedida2: formValues.minimoAceitavelIdUnidadeMedida2?.toString() || '',
            estimativaIdUnidadeMedida1: formValues.estimativaIdUnidadeMedida1?.toString() || '',
            estimativaIdUnidadeMedida2: formValues.estimativaIdUnidadeMedida2?.toString() || '',
          };
        }
      }

      const formItem: ProposalResidueType = {
        ...formValues,
        ...buildFakeAuditObject(),

        idResiduo: Number(formValues.idResiduo),
        residuo: formValues.residuo,

        idCliente: Number(formValues.idCliente),
        clienteCNPJ: formValues.clienteData?.cnpj,
        clienteRazaoSocial: formValues.clienteData?.razaoSocial,
        clienteNomeFantasia: formValues.clienteData?.nomeFantasia,
        clienteCPF: formValues.clienteData?.cpf,
        clienteNome: formValues.clienteData?.nome,

        idEntidadeEndereco: Number(formValues.idEntidadeEndereco),
        codigoEstado: formValues.enderecoData?.codigoEstado,
        cidade: formValues.enderecoData?.cidade,
        cidadeCodigoIBGE: formValues.enderecoData?.cidadeCodigoIBGE,
        bairro: formValues.enderecoData?.bairro,
        logradouro: formValues.enderecoData?.logradouro,
        numero: formValues.enderecoData?.numero,
        complemento: formValues.enderecoData?.complemento,
        referencia: formValues.enderecoData?.referencia,
        cep: formValues.enderecoData?.cep,
        latitude: formValues.enderecoData?.latitude,
        longitude: formValues.enderecoData?.longitude,

        idProposta: props.idProposta || -1,

        minimoAceitavel: formValues.minimoAceitavel === 0 ? null : formValues.minimoAceitavel,
        minimoAceitavelIdUnidadeMedida1: Number(formValues.minimoAceitavelIdUnidadeMedida1) || null,
        minimoAceitavelIdUnidadeMedida2: Number(formValues.minimoAceitavelIdUnidadeMedida2) || null,
        estimativaIdUnidadeMedida1: Number(formValues.estimativaIdUnidadeMedida1),
        estimativaIdUnidadeMedida2: Number(formValues.estimativaIdUnidadeMedida2),
      };

      formItem.minimoAceitavelUnidadeMedida1 =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === formItem.minimoAceitavelIdUnidadeMedida1
        )?.unidadeMedida || '';

      formItem.minimoAceitavelUnidadeMedida2 =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === formItem.minimoAceitavelIdUnidadeMedida2
        )?.unidadeMedida || '';

      formItem.estimativaUnidadeMedida1 =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === formItem.estimativaIdUnidadeMedida1
        )?.unidadeMedida || '';

      formItem.estimativaUnidadeMedida2 =
        props.referenceData.unitOfMeasures.find(
          (x) => x.idUnidadeMedida === formItem.estimativaIdUnidadeMedida2
        )?.unidadeMedida || '';

      formItem.dataInicial = formValues.dataInicial;
      formItem.dataFinal = formValues.dataFinal;

      formItem.tecnologiaAtual = formItem.tecnologiaAtual?.trim() || null;
      formItem.observacao = formItem.observacao?.trim() || null;

      return formItem;
    },
    getFormItemValue(item: keyof FormViewData) {
      return form.getInputProps(item).value;
    },
    clear() {
      form.reset();
      form.setFieldValue('idCliente', -1);
      form.setFieldValue('cliente', '');
      form.setFieldValue('idEntidadeEndereco', -1);
      form.setFieldValue('endereco', '');
    },
  }));

  useEffect(() => {
    setProposalResidueData({
      residuo: form.values.idResiduo ? { ...form.values, ...buildFakeAuditObject(), descricao: null } : null,
      codigoResiduoEstadoFisico: form.values.codigoResiduoEstadoFisico,
      residuoCliente: form.values.residuoCliente,
      residuoClientePerigoso: form.values.residuoClientePerigoso,

      minimoAceitavel: form.values.minimoAceitavel,
      minimoAceitavelIdUnidadeMedida1: form.values.minimoAceitavelIdUnidadeMedida1,
      minimoAceitavelIdUnidadeMedida2: form.values.minimoAceitavelIdUnidadeMedida2,
      estimativa: form.values.estimativa,
      estimativaIdUnidadeMedida1: form.values.estimativaIdUnidadeMedida1,
      estimativaIdUnidadeMedida2: form.values.estimativaIdUnidadeMedida2,

      dataInicial: form.values.dataInicial,
      dataFinal: form.values.dataFinal,
      idEntidadeEndereco: form.values.idEntidadeEndereco,
      planos: props.item?.planos || [],
    });
  }, [form.values, props.item?.planos, setProposalResidueData]);

  return (
    <div>
      <form id="proposal-residue" noValidate>
        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Geral"
            text="Informações gerais do resíduo como referências, datas, local, etc."
          />
          <Space h="xs" />

          <Grid columns={5}>
            <Grid.Col span={4}>
              <ResidueSearch
                label="Resíduo"
                formItem={form.getInputProps('residuo')}
                idsToBeDisabled={[]}
                disabled={props.disabled}
                callback={(item: ResidueType | null) => {
                  if (item) {
                    form.setFieldValue('residuoData', item);
                    form.setFieldValue('idResiduo', item.idResiduo);
                    form.setFieldValue('residuo', item.residuo);
                    setProposalResidueData({ ...proposalResidueData, residuo: item });
                  }
                }}
              />
            </Grid.Col>
            {/* <Grid.Col span={1}>
              <div style={{ marginTop: -5 }}>
                <PageViewProperty
                  label="Perigoso?"
                  text={form.values.residuoData?.perigoso ? 'Sim' : 'Não'}
                  size="sm"
                />
              </div>
            </Grid.Col> */}
            <Grid.Col span={1}>
              <Select
                icon={<Microscope size={15} />}
                label="Estado Físico"
                placeholder="Selecione..."
                data={props.referenceData.residueStateOfMatterType.map((x) => {
                  return {
                    value: x.codigoResiduoEstadoFisico,
                    label: x.residuoEstadoFisico,
                  };
                })}
                searchable
                required
                {...form.getInputProps('codigoResiduoEstadoFisico')}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={5}>
            <Grid.Col span={3}>
              <TextInput
                icon={<Atom size={15} />}
                label="Resíduo (Cliente)"
                placeholder="Resíduo"
                maxLength={100}
                required
                disabled={props.disabled}
                {...form.getInputProps('residuoCliente')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <div style={{ marginTop: 30 }}>
                <Switch
                  label="Resíduo Perigoso (Cliente)?"
                  {...form.getInputProps('residuoClientePerigoso', { type: 'checkbox' })}
                />
              </div>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={4}>
            <Grid.Col span={2}>
              <Input.Wrapper label="Mínimo Aceitável">
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                <NumberInput
                  style={{ width: 100 }}
                  placeholder="Mínimo Aceitável"
                  min={0}
                  precision={2}
                  step={0.5}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  {...form.getInputProps('minimoAceitavel')}
                />
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        x.tipo === 'Massa' ||
                        x.tipo === 'Volume' ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  clearable
                  searchable
                  required
                  {...form.getInputProps('minimoAceitavelIdUnidadeMedida1')}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' &&
                          (x.unidadeMedidaSigla === 'per' || x.unidadeMedidaSigla === 'col'))
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  clearable
                  searchable
                  required
                  {...form.getInputProps('minimoAceitavelIdUnidadeMedida2')}
                />
              </Group>
            </Grid.Col>

            <Grid.Col span={2}>
              <Input.Wrapper label="Estimativa" required>
                <div />
              </Input.Wrapper>
              <Group spacing="xs">
                <NumberInput
                  style={{ width: 100 }}
                  placeholder="Estimativa"
                  min={0.5}
                  defaultValue={1}
                  precision={2}
                  step={0.5}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  required
                  {...form.getInputProps('estimativa')}
                />
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        x.tipo === 'Massa' ||
                        x.tipo === 'Volume' ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('estimativaIdUnidadeMedida1')}
                />
                <Text>/</Text>
                <Select
                  icon={<Ruler2 size={15} />}
                  placeholder="Selecione..."
                  data={props.referenceData.unitOfMeasures
                    .filter(
                      (x) =>
                        (x.tipo === 'Tempo' &&
                          (x.unidadeMedidaSigla === 'd' ||
                            x.unidadeMedidaSigla === 'sem' ||
                            x.unidadeMedidaSigla === 'mês' ||
                            x.unidadeMedidaSigla === 'ano')) ||
                        (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                    )
                    .map((x) => {
                      return {
                        value: x.idUnidadeMedida.toString(),
                        label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                        group: x.tipo,
                      };
                    })}
                  searchable
                  required
                  {...form.getInputProps('estimativaIdUnidadeMedida2')}
                />
              </Group>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={5}>
            <Grid.Col span={1}>
              <Input.Wrapper label="Data Inicial" required>
                <Group
                  style={{
                    display: 'inline-block',
                    marginLeft: 5,
                    marginTop: -2,
                    verticalAlign: 'bottom',
                  }}
                >
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Data prevista de início do gerenciamento do resíduo"
                  >
                    <div>
                      <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                    </div>
                  </Tooltip>
                </Group>
              </Input.Wrapper>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={props.proposalGeneralData.dataInicial}
                maxDate={props.proposalGeneralData.dataFinal}
                required
                {...form.getInputProps('dataInicial')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Input.Wrapper label="Data Final" required>
                <Group
                  style={{
                    display: 'inline-block',
                    marginLeft: 5,
                    marginTop: -2,
                    verticalAlign: 'bottom',
                  }}
                >
                  <Tooltip
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Data prevista de fim do gerenciamento do resíduo"
                  >
                    <div>
                      <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                    </div>
                  </Tooltip>
                </Group>
              </Input.Wrapper>
              <DatePicker
                icon={<Calendar size={15} />}
                locale="pt-br"
                inputFormat="DD/MM/YYYY"
                clearable={false}
                allowFreeInput
                minDate={form.values.dataInicial}
                maxDate={props.proposalGeneralData.dataFinal}
                required
                {...form.getInputProps('dataFinal')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <div style={{ marginTop: 30 }}>
                <Switch
                  label="Faturamento Direto?"
                  {...form.getInputProps('faturamentoDireto', { type: 'checkbox' })}
                />
              </div>
            </Grid.Col>
            <Grid.Col span={1}>
              <div style={{ marginTop: 30 }}>
                <Switch label="Compra?" {...form.getInputProps('compra', { type: 'checkbox' })} />
              </div>
            </Grid.Col>
            <Grid.Col span={1}>
              <div style={{ marginTop: 30 }}>
                <Switch
                  label="Cobrar Tolerâncias?"
                  {...form.getInputProps('cobrarTolerancia', { type: 'checkbox' })}
                />
              </div>
            </Grid.Col>
          </Grid>
          <Space h="xs" />

          <Grid columns={2}>
            <Grid.Col span={1}>
              <Textarea
                icon={<Notes size={15} />}
                label="Tecnologia atual"
                placeholder="Observação sobre a tecnologia atual utilizada pelo cliente"
                autosize
                maxLength={500}
                {...form.getInputProps('tecnologiaAtual')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Textarea
                icon={<Notes size={15} />}
                label="Observação"
                placeholder="Observação sobre o resíduo"
                autosize
                maxLength={500}
                {...form.getInputProps('observacao')}
              />
            </Grid.Col>
          </Grid>
        </Paper>
        <Space h="lg" />

        <Paper shadow="xs" p="md" withBorder>
          <PageSection
            size="lg"
            color={Feature.Home.Proposal.color}
            label="Cliente e Local"
            text="Cliente e local onde a prestação de serviço deverá ocorrer."
          />
          <Space h="xs" />
          <Grid columns={4}>
            <Grid.Col span={2}>
              <EntitySearch
                label="Cliente"
                referenceData={props.referenceData}
                formItem={form.getInputProps('cliente') || undefined}
                idsToBeDisabled={[]}
                disabled={props.disabled}
                required
                callback={async (item: EntitySearchResponseType | null) => {
                  if (item) {
                    form.setFieldValue('clienteData', item);
                    form.setFieldValue('idCliente', item.idEntidade);
                    form.setFieldValue('cliente', item.nomeFantasia || item.razaoSocial || item.nome || '');
                    form.setFieldValue('idEntidadeEndereco', -1);
                    form.setFieldValue('endereco', '');
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <EntityAddressList
                label="Endereço"
                referenceData={{ idEntidade: form.values.idCliente! }}
                formItem={form.getInputProps('endereco')}
                idEntidadeEndereco={form.values.idEntidadeEndereco}
                endereco={form.values.endereco}
                idsToBeDisabled={[]}
                callback={async (item: EntityAddressType | null) => {
                  if (item) {
                    form.setFieldValue('enderecoData', item);
                    form.setFieldValue('idEntidadeEndereco', item.idEntidadeEndereco!);
                    form.setFieldValue(
                      'endereco',
                      `${item.logradouro}, ${item.numero} | ${item.bairro} - ${item.cidade}/${item.codigoEstado}`
                    );
                  }
                }}
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
    </div>
  );
});

export default ResidueGeneralFormView;
