/* eslint-disable react/function-component-definition */
import { useLocalStorage } from '@mantine/hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { LocalStorageKey } from '../../utils/constants.utils';

type PrivateRouteProps = {
  children: any;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [jwt] = useLocalStorage({ key: LocalStorageKey.UserAccessToken, getInitialValueInEffect: false });
  return jwt ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
