/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Affix, Button, Card, Center, Loader, Text, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, ArrowNarrowUp, ClearAll, DeviceFloppy, Pencil } from 'tabler-icons-react';
import { ProposalStatusEnum, ProposalTypeEnum } from '../../../../business/proposals/status';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import {
  ClosingType,
  CompanyType,
  EntityTypeType,
  ResidueStateOfMatterType,
  UnitOfMeasureType,
} from '../../../../models/core/cache.type';
import { Permission } from '../../../../models/core/departments.type';
import { ProposalInsertType, ProposalType } from '../../../../models/core/proposals.type';
import proposalsService from '../../../../services/core/proposals.service';
import cacheUtils from '../../../../utils/cache.utils';
import { Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import FormView from './components/FormView';

type DataResult = {
  loading: boolean;
  referenceData: {
    companies: CompanyType[];
    closingTypeData: ClosingType[];
    entityTypeData: EntityTypeType[];
    residueStateOfMatterType: ResidueStateOfMatterType[];
    unitOfMeasures: UnitOfMeasureType[];
  } | null;
  proposalData: ProposalType | null;
};

// BUG: Proposal: 007 - fix console errors (locally)
function ProposalAddEdit() {
  const modals = useModals();
  const navigate = useNavigate();
  const { idProposta } = useParams();
  const [scroll, scrollTo] = useWindowScroll();
  const refForm = useRef<any>();

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    referenceData: null,
    proposalData: null,
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let { referenceData, proposalData } = dataResult;
      try {
        if (dataResult.referenceData === null) {
          referenceData = {
            companies: await cacheUtils.listCompanies(),
            closingTypeData: await cacheUtils.listClosings(),
            entityTypeData: await cacheUtils.listEntityTypes(),
            residueStateOfMatterType: await cacheUtils.listResidueStateOfMatters(),
            unitOfMeasures: await cacheUtils.listUnityOfMeasures(),
          };
        }
        if (idProposta && dataResult.proposalData === null) {
          proposalData = await proposalsService.select({ idProposta: Number(idProposta) });
          if (proposalData.codigoPropostaStatus !== ProposalStatusEnum.EmEdicao) {
            showNotification({
              title: `Proposta - Editar`,
              message: 'A proposta não pode ser editada neste status.',
              color: 'red',
            });
            navigate('/500', {
              replace: true,
            });
          }
        }
      } catch (error: any) {
        showNotification({
          title: `Proposta - ${!idProposta ? 'Adicionar' : 'Editar'}`,
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar a proposta.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({
          loading: false,
          referenceData,
          proposalData,
        });
      }
    };

    if (dataResult.referenceData === null || (idProposta && dataResult.proposalData === null)) {
      fetchData();
    }
  }, [idProposta, navigate, dataResult]);

  const save = async () => {
    try {
      const formData: ProposalInsertType | null = refForm.current.validate();
      if (formData === null) {
        return;
      }

      setSaving(true);
      let newModel;
      if (!idProposta) {
        newModel = await proposalsService.insert({
          ...formData,
          idEmpresa: Number(formData.idEmpresa),
          codigoPropostaTipo: ProposalTypeEnum.Completa,
        });
      } else {
        await proposalsService.update({
          ...formData,
          codigoPropostaTipo: dataResult.proposalData!.codigoPropostaTipo,
          idEmpresa: Number(formData.idEmpresa),
          idProposta: Number(idProposta),
        });
      }
      showNotification({
        title: `Proposta - ${!idProposta ? 'Adicionar' : 'Editar'}`,
        message: 'Proposta salva com sucesso.',
        color: 'green',
      });
      sessionStorage.removeItem(SessionStorageKey.TempProposal);
      navigate(`/proposals/${newModel?.idProposta || idProposta}`);
    } catch (error: any) {
      showNotification({
        title: `Proposta - ${!idProposta ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar a proposta.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  const saveSession = () => {
    try {
      const formData: ProposalInsertType | null = refForm.current.validate();
      if (formData === null) {
        return;
      }
      setSaving(true);
      sessionStorage.setItem(SessionStorageKey.TempProposal, JSON.stringify(formData));
      showNotification({
        title: `Proposta - ${!idProposta ? 'Adicionar' : 'Editar'}`,
        message: 'Proposta temporariamente salva com sucesso.',
        color: 'green',
      });
    } catch (error: any) {
      showNotification({
        title: `Proposta - ${!idProposta ? 'Adicionar' : 'Editar'}`,
        message: error?.isBusinessException
          ? error.description
          : 'Não foi possível salvar temporariamente a proposta.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <PageHeader
        feature={Feature.Home.Proposal}
        title={`Proposta - ${!idProposta ? 'Adicionar' : 'Editar'}`}
        description={`${!idProposta ? 'Adicione' : 'Edite'} uma proposta.`}
        buttons={[
          <Button
            key="Voltar"
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            key="Limpar"
            color="secondary"
            leftIcon={<ClearAll size={18} />}
            disabled={dataResult.loading || saving}
            hidden={!!idProposta}
            onClick={() => {
              modals.openConfirmModal({
                title: `Gostaria de limpar todo o formulário?`,
                children: <Text size="sm">Todas as informações preenchidas serão descartadas.</Text>,
                onConfirm: () => {
                  refForm.current.clear();
                },
              });
            }}
          >
            Limpar
          </Button>,
          <Button
            key="Rascunho"
            color="accent"
            leftIcon={<Pencil size={18} />}
            disabled={dataResult.loading || saving}
            hidden={!!idProposta}
            onClick={saveSession}
          >
            Rascunho
          </Button>,
          <Button
            key="Salvar"
            color="primary"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
            onClick={save}
            data-permission={!idProposta ? Permission.ProposalAdd : Permission.ProposalEdit}
          >
            Salvar
          </Button>,
        ]}
      />
      {dataResult.referenceData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView
            ref={refForm}
            referenceData={dataResult.referenceData}
            proposal={dataResult.proposalData}
          />
        </PageContent>
      )}
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<ArrowNarrowUp size={18} />}
              style={transitionStyles}
              color="secondary"
              onClick={() => scrollTo({ y: 0 })}
            >
              Ir ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Card>
  );
}

export default ProposalAddEdit;
