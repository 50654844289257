/* eslint-disable react/destructuring-assignment */
import { Badge } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { DismissalType } from '../../../../../models/core/dismissals.type';
import DataViewActions from './DataViewActions';

type DataViewProps = {
  data: DismissalType[];
  confirmActionResult(item: DismissalType, action: string, confirmed: boolean): void;
};

export default function DataView(props: DataViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      key: 'nomeCompleto',
      dataIndex: 'nomeCompleto',
      // eslint-disable-next-line prettier/prettier
      sorter: (a: DismissalType, b: DismissalType) => a.nomeCompleto.localeCompare(b.nomeCompleto),
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      sorter: (a: DismissalType, b: DismissalType) => a.email.localeCompare(b.email),
    },
    {
      title: 'Empresa',
      key: 'empresa',
      dataIndex: 'empresa',
      sorter: (a: DismissalType, b: DismissalType) => a.empresa.localeCompare(b.empresa),
    },
    {
      title: 'Departamento',
      key: 'departamento',
      dataIndex: 'departamento',
      sorter: (a: DismissalType, b: DismissalType) => a.departamento.localeCompare(b.departamento),
    },
    {
      title: 'Status',
      key: 'usuarioDesligamentoStatus',
      sorter: (a: DismissalType, b: DismissalType) =>
        a.usuarioDesligamentoStatus.localeCompare(b.usuarioDesligamentoStatus),
      render: (row: DismissalType) => <Badge variant="outline">{row.usuarioDesligamentoStatus}</Badge>,
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: DismissalType, b: DismissalType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: DismissalType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: DismissalType) => (
        <DataViewActions item={row} confirmActionResult={props.confirmActionResult} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      dataSource={props.data}
      columns={columns}
      rowKey={(item: DismissalType) => item.idUsuarioDesligamento}
      pagination={{
        pageSizeOptions: [10, 25, 50],
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
      }}
    />
  );
}
