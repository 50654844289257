import {
  ProposalDeleteType,
  ProposalFinancialPatchType,
  ProposalInsertType,
  ProposalResidueType,
  ProposalSearchRequestType,
  ProposalSearchResponseType,
  ProposalSelectType,
  ProposalServiceType,
  ProposalStatusUpdateType,
  ProposalType,
  ProposalUpdateType,
} from '../../models/core/proposals.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { call } from '../core.service';

const proposalsEndpoint = '/v1/propostas';

export default class ProposalsService {
  static search = async (searchProps: ProposalSearchRequestType): Promise<ProposalSearchResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: proposalsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static list = async (): Promise<ProposalSearchResponseType[]> => {
    return this.search({ limit: 1000, offset: 0 });
  };

  static select = async (selectProps: ProposalSelectType): Promise<ProposalType> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${proposalsEndpoint}/${selectProps.idProposta}`,
    };
    return (await call(request))?.data?.data;
  };

  static update = async (updateProps: ProposalUpdateType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PUT',
      url: `${proposalsEndpoint}/${updateProps.idProposta}`,
      data: updateProps,
      timeout: 60000,
    };
    return call(request);
  };

  static delete = async (deleteProps: ProposalDeleteType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'DELETE',
      url: `${proposalsEndpoint}/${deleteProps.idProposta}`,
    };
    return call(request);
  };

  static insert = async (insertProps: ProposalInsertType): Promise<ProposalType> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: proposalsEndpoint,
      data: insertProps,
      timeout: 60000,
    };
    return (await call(request))?.data?.data;
  };

  static updateStatus = async (
    updateStatusProps: ProposalStatusUpdateType
  ): Promise<{ idContrato: number | null }> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: `${proposalsEndpoint}/${updateStatusProps.idProposta}/status`,
      data: updateStatusProps,
      timeout: 60000,
    };
    return (await call(request))?.data?.data;
  };

  static updateFinancial = async (updateFinancialProps: ProposalFinancialPatchType): Promise<void> => {
    const request: AxiosCustomRequestType = {
      method: 'PATCH',
      url: `${proposalsEndpoint}/${updateFinancialProps.idProposta}/financeiro`,
      data: updateFinancialProps,
      timeout: 60000,
    };
    return call(request);
  };

  static listResidues = async (selectProps: ProposalSelectType): Promise<ProposalResidueType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${proposalsEndpoint}/${selectProps.idProposta}/residuos`,
      params: { cache: selectProps.cache },
    };
    return (await call(request))?.data?.data;
  };

  static listServices = async (selectProps: ProposalSelectType): Promise<ProposalServiceType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: `${proposalsEndpoint}/${selectProps.idProposta}/servicos`,
      params: { cache: selectProps.cache },
    };
    return (await call(request))?.data?.data;
  };
}
