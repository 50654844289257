/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Badge, Group, Paper, Select, Space } from '@mantine/core';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ZoomMoney } from 'tabler-icons-react';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { EventServiceProvider } from '../../../../../../../contexts/core/events/EventService.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { EventServiceType } from '../../../../../../../models/core/events.type';
import { ProposalServiceQuotationType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';
import EventServiceFormAddEdit from './EventServiceFormAddEdit';
import EventServiceQuotationFormAddEdit from './EventServiceQuotationFormAddEdit';
import { calcularPreco } from '../../../../../../../business/proposals/estimate';

type EventServiceFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  quotations: ProposalServiceQuotationType[];
  origItem: EventServiceType | null;
  item: EventServiceType | null;
  disabled: boolean;
};

const EventServiceFormView = forwardRef((props: EventServiceFormViewProps, ref) => {
  const refServiceForm = useRef<any>();
  const refServiceQuotationForm = useRef<any>();
  const [quotation, setQuotation] = useState<ProposalServiceQuotationType | null>(
    JSON.parse(JSON.stringify(props.item?.cotacao || null))
  );
  const { eventGeneralData } = useEventGeneralContext();
  const cotacaoPrimaria = eventGeneralData?.propostaServicoData?.cotacoes?.find((y) => y.primario);

  useImperativeHandle(ref, () => ({
    validate(): any {
      try {
        const servico = refServiceForm?.current?.validate();
        const cotacao = refServiceQuotationForm?.current?.validate();

        if (servico === null || cotacao === null) {
          throw Error();
        }

        return {
          servico: {
            ...servico,
            cotacao: {
              ...cotacao,
            },
          },
          idEventoReferencia3: eventGeneralData.eventoReferenciaPersonalizado
            ? null
            : quotation?.idPropostaServicoCotacao,
        };
      } catch (ex: any) {
        throw Error('Existem pendências a serem corrigidas.');
      }
    },
    clear() {
      refServiceForm.current.clear();
      refServiceQuotationForm.current.clear();
    },
  }));

  return (
    <EventServiceProvider>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Home.Event.color} label="Serviço" text="Detalhes do serviço." />
        <Space h="xs" />

        <EventServiceFormAddEdit
          ref={refServiceForm}
          referenceData={props.referenceData}
          origItem={props.origItem}
          item={props.item}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
        />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Fornecedor"
            text="Detalhes do fornecedor."
          />
          <Group>
            {cotacaoPrimaria && (
              <Badge variant="outline" size="md" color="orange">
                {`Preço (Cliente): ${formatCurrency(
                  calcularPreco(
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.preco,
                    cotacaoPrimaria.margem,
                    cotacaoPrimaria.precoFinal,
                    cotacaoPrimaria.imposto || 0,
                    eventGeneralData.propostaResiduoData?.compra || false,
                    false
                  ).novoPrecoComMargem
                )} | ${cotacaoPrimaria.quantidade} ${cotacaoPrimaria.frequenciaUnidadeMedida1} / ${
                  cotacaoPrimaria.frequenciaUnidadeMedida2
                }`}
              </Badge>
            )}
            {props.quotations.length > 0 && !props.origItem && (
              <Select
                icon={<ZoomMoney size={15} />}
                placeholder="Selecione..."
                data={props.quotations.map((x) => {
                  return {
                    value: x.idPropostaServicoCotacao!.toString(),
                    label: `${formatCurrency(x.preco)} | ${x.quantidade} ${x.frequenciaUnidadeMedida1} / ${
                      x.frequenciaUnidadeMedida2
                    }`,
                    group: `${
                      x.fornecedorNomeFantasia || x.fornecedorRazaoSocial || x.fornecedorNome || '?'
                    }`,
                  };
                })}
                value={quotation?.idPropostaServicoCotacao?.toString()}
                onChange={(value) => {
                  let quotationRef =
                    props.quotations.find((x) => x.idPropostaServicoCotacao === Number(value)) || null;

                  if (quotationRef) {
                    quotationRef = {
                      ...props.item?.cotacao,
                      ...quotationRef,
                    };
                  }
                  setQuotation(quotationRef);
                }}
                searchable
                disabled={props.disabled}
              />
            )}
          </Group>
        </Group>
        <Space h="xs" />

        <EventServiceQuotationFormAddEdit
          ref={refServiceQuotationForm}
          key={quotation?.idPropostaServicoCotacao || '-1'}
          referenceData={props.referenceData}
          origItem={props.origItem?.cotacao || null}
          item={quotation || null}
          disabled={props.disabled || !eventGeneralData.eventoReferenciaPersonalizado}
        />
      </Paper>
    </EventServiceProvider>
  );
});

export default EventServiceFormView;
