/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, Space } from '@mantine/core';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ProposalResidueType } from '../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../../utils/formatter.utils';

type ViewProps = {
  data: ProposalResidueType;
};

export default function ResidueGeneralView(props: ViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Geral"
          text="Informações gerais do resíduo como referências, datas, local, etc."
        />
        <Space h="xs" />

        <Grid columns={5}>
          <Grid.Col span={4}>
            <PageViewProperty
              label="Resíduo"
              text={
                <ProfileCardLink
                  id={props.data.idResiduo.toString()}
                  name={props.data.residuo}
                  nameSize="sm"
                  description={`Código IBAMA: ${props.data.codigoIBAMA || '-'} | Perigoso: ${
                    props.data.perigoso ? 'Sim' : 'Não'
                  }`}
                  descriptionSize="xs"
                  linkPrefix="residues"
                  showLink
                />
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Estado Físico" text={props.data.residuoEstadoFisico} size="sm" />
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <Grid columns={5}>
          <Grid.Col span={4}>
            <PageViewProperty label="Resíduo (Cliente)" text={props.data.residuoCliente || '-'} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Resíduo Perigoso (Cliente)?"
              text={props.data.residuoClientePerigoso ? 'Sim' : 'Não'}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Mínimo Aceitável"
              text={
                props.data.minimoAceitavel
                  ? `${props.data.minimoAceitavel} ${props.data.minimoAceitavelUnidadeMedida1} / ${props.data.minimoAceitavelUnidadeMedida2}`
                  : '-'
              }
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Estimativa"
              text={`${props.data.estimativa} ${props.data.estimativaUnidadeMedida1} / ${props.data.estimativaUnidadeMedida2}`}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Data Inicial"
              text={formatDateToString(props.data.dataInicial)}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Data Final" text={formatDateToString(props.data.dataFinal)} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Faturamento Direto?"
              text={props.data.faturamentoDireto ? 'Sim' : 'Não'}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Compra?" text={props.data.compra ? 'Sim' : 'Não'} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Cobrar Tolerância?"
              text={props.data.cobrarTolerancia ? 'Sim' : 'Não'}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        <Grid columns={2}>
          <Grid.Col span={1}>
            <PageViewProperty label="Tecnologia Atual" text={props.data.tecnologiaAtual || '-'} size="sm" />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Cliente e Local"
          text="Cliente e local onde a prestação de serviço deverá ocorrer."
        />
        <Space h="xs" />

        <Grid columns={6}>
          <Grid.Col span={2}>
            {props.data.clienteCNPJ ? (
              <PageViewProperty
                label="Cliente"
                text={
                  <ProfileCardLink
                    id={props.data.idCliente?.toString() || ''}
                    name={props.data.clienteNomeFantasia || props.data.clienteRazaoSocial || '-'}
                    nameSize="sm"
                    description={
                      props.data.clienteNomeFantasia ? props.data.clienteRazaoSocial : props.data.clienteCNPJ
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            ) : (
              <PageViewProperty
                label="Cliente"
                text={
                  <ProfileCardLink
                    id={props.data.idCliente?.toString() || ''}
                    name={props.data.clienteNome || '-'}
                    nameSize="sm"
                    description={props.data.clienteCPF}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>
          <Grid.Col span={2}>
            <PageViewProperty
              label="Logradouro"
              text={`${props.data.logradouro}, ${props.data.numero}${
                props.data.complemento ? ` | ${props.data.complemento}` : ''
              }`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty
              label="Bairro - Cidade"
              text={`${props.data.bairro} - ${props.data.cidade} / ${props.data.codigoEstado}`}
              size="sm"
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <PageViewProperty label="CEP" text={props.data.cep} size="sm" />
          </Grid.Col>
        </Grid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
      />
    </div>
  );
}
