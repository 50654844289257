/* eslint-disable react/destructuring-assignment */
import { Edit, Eye, X } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import theme from '../../../../../theme';
import { UserType } from '../../../../../models/core/users.type';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import useCurrentUser from '../../../../../hooks/useCurrentUser';

type DataViewActionProps = {
  item: UserType;
  confirmActionResult(item: UserType, action: string, confirmed: boolean): void;
};

export default function DataViewActions(props: DataViewActionProps) {
  const modals = useModals();
  const [currentUser] = useCurrentUser();

  const showModal = (item: UserType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} esse usuário?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Nome" text={item.nomeCompleto} size="sm" />
            <PageViewProperty label="E-mail" text={item.email} size="sm" />
            <PageViewProperty label="Empresa" text={item.empresa} size="sm" />
            <PageViewProperty label="Departamento" text={item.departamento} size="sm" />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Visualizar">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.primary?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'visualizar', true);
          }}
        >
          <Eye size={15} color={theme?.colors?.primary?.[6]} />
        </ActionIcon>
      </Tooltip>
      {props.item.status === 'active' && (
        <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.accent?.[6] }}
            variant="outline"
            onClick={() => {
              props.confirmActionResult(props.item, 'editar', true);
            }}
          >
            <Edit size={15} color={theme?.colors?.accent?.[6]} />
          </ActionIcon>
        </Tooltip>
      )}
      {props.item.status === 'active' &&
        currentUser.idUsuario !== props.item.idUsuario &&
        (!props.item.executivo || currentUser.executivo) && (
          <Tooltip withArrow transition="fade" transitionDuration={200} label="Desligar">
            <ActionIcon
              size="sm"
              radius="sm"
              color="primary"
              style={{ borderColor: 'red' }}
              variant="outline"
              onClick={() => {
                showModal(props.item, 'desligar');
              }}
            >
              <X size={15} color="red" />
            </ActionIcon>
          </Tooltip>
        )}
    </Group>
  );
}
