/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Card, Center, Loader, Modal } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackUp, DeviceFloppy, Mail } from 'tabler-icons-react';
import PageContent from '../../../../components/core/PageContent/PageContent';
import PageHeader from '../../../../components/core/PageHeader/PageHeader';
import { DepartmentType } from '../../../../models/core/departments.type';
import { UserType } from '../../../../models/core/users.type';
import departmentsService from '../../../../services/core/departments.service';
import usersService from '../../../../services/core/users.service';
import identityService from '../../../../services/iam/identity.service';
import { Feature, LocalStorageKey } from '../../../../utils/constants.utils';
import { buildFakeAuditObject, decrypt, encrypt } from '../../../../utils/helper.utils';
import EmailChange from './components/EmailChange';
import { FormView, FormViewData } from './components/FormView';

type DataResult = {
  loading: boolean;
  departmentData: DepartmentType[] | null;
  userData: UserType | null;
};

function UserEdit() {
  const navigate = useNavigate();
  const { idUsuario } = useParams();

  // eslint-disable-next-line prefer-const
  let [currentUser, setCurrentUser] = useLocalStorage<UserType>({
    key: LocalStorageKey.User,
    getInitialValueInEffect: false,
  });
  currentUser = JSON.parse(decrypt(currentUser as any) as any);

  const [dataResult, setDataResult] = useState<DataResult>({
    loading: true,
    departmentData: null,
    userData: null,
  });
  const [saving, setSaving] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let departmentData: DepartmentType[] = [];
      let { userData } = dataResult;

      try {
        departmentData = !currentUser.gerencial
          ? [
              {
                ...buildFakeAuditObject(),
                idDepartamento: currentUser.idDepartamento,
                departamento: currentUser.departamento,
                idEmpresa: currentUser.idEmpresa,
                empresa: currentUser.empresa,
                executivo: currentUser.executivo,
                gerencial: currentUser.gerencial,
                suporte: currentUser.suporte,
              },
            ]
          : await departmentsService.list(true);
        if (idUsuario && dataResult.userData === null) {
          userData = await usersService.select({ idUsuario });
          if (userData.status === 'inactive') {
            navigate('/403', {
              replace: true,
            });
          }
        }

        if (
          userData?.status === 'inactive' ||
          (currentUser.idUsuario !== idUsuario && !currentUser.gerencial) ||
          (!currentUser.executivo && currentUser.idEmpresa !== userData?.idEmpresa)
        ) {
          showNotification({
            title: 'Usuário - Editar',
            message: 'Você não tem permissão para editar esse perfil.',
            color: 'red',
          });
          navigate('/403', {
            replace: true,
          });
        }
      } catch (error: any) {
        showNotification({
          title: 'Usuário - Editar',
          message: error?.isBusinessException ? error.description : 'Não foi possível carregar o usuário.',
          color: 'red',
        });
        navigate(`/${error?.statusCode || '500'}`, {
          replace: true,
        });
      } finally {
        setDataResult({ loading: false, departmentData, userData });
      }
    };

    if (dataResult.departmentData === null || (idUsuario && dataResult.userData === null)) {
      fetchData();
    }
  }, [idUsuario, navigate, currentUser, dataResult]);

  const save = async (inputFormViewData: FormViewData) => {
    try {
      setSaving(true);

      await identityService.updateIdentity({
        identityId: idUsuario!,
        firstName: inputFormViewData.nome.trim(),
        lastName: inputFormViewData.sobrenome.trim(),
        gender: inputFormViewData.genero.toLowerCase() === 'm' ? 'male' : 'female',
        birthdate: inputFormViewData.dataNascimento,
        extras: {
          locale: 'pt-BR',
          departmentId: Number(inputFormViewData.idDepartamento),
        },
      });

      const newDepartment = dataResult.departmentData?.find(
        (x) => x.idDepartamento === Number(inputFormViewData.idDepartamento)
      );
      const newUserData = {
        ...dataResult.userData!,
        nome: inputFormViewData.nome,
        sobrenome: inputFormViewData.sobrenome,
        nomeCompleto: `${inputFormViewData.nome} ${inputFormViewData.sobrenome}`,
        genero: inputFormViewData.genero,
        dataNascimento: inputFormViewData.dataNascimento,
        idEmpresa: newDepartment?.idEmpresa || -1,
        empresa: newDepartment?.empresa || '',
        idDepartamento: newDepartment?.idDepartamento || -1,
        departamento: newDepartment?.departamento || '',
        executivo: newDepartment?.executivo || false,
        gerencial: newDepartment?.gerencial || false,
        suporte: newDepartment?.suporte || false,
      };

      if (currentUser.idUsuario === idUsuario) {
        setCurrentUser(encrypt(JSON.stringify(newUserData)) as any);
      }

      showNotification({
        title: 'Usuário - Editar',
        message: 'Usuário salvo com sucesso.',
        color: 'green',
      });
      navigate(`/users/${idUsuario}`);
    } catch (error: any) {
      showNotification({
        title: 'Usuário - Editar',
        message: error?.isBusinessException ? error.description : 'Não foi possível salvar o usuário.',
        color: 'red',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Editar Perfil - Alterar E-mail"
      >
        <EmailChange email={dataResult.userData?.email || ''} />
      </Modal>
      <PageHeader
        feature={Feature.Managerial.User}
        title="Usuário - Editar"
        description="Edite um usuário."
        buttons={[
          <Button
            color="secondary"
            leftIcon={<ArrowBackUp size={18} />}
            onClick={() => {
              navigate(-1);
            }}
            disabled={dataResult.loading || saving}
          >
            Voltar
          </Button>,
          <Button
            color="accent"
            leftIcon={<Mail size={18} />}
            onClick={() => setOpened(true)}
            hidden={currentUser.idUsuario !== dataResult.userData?.idUsuario}
            disabled={dataResult.loading || saving}
          >
            Alterar E-mail
          </Button>,
          <Button
            color="primary"
            type="submit"
            form="user-edit"
            leftIcon={<DeviceFloppy size={18} />}
            disabled={dataResult.loading}
            loading={saving}
          >
            Salvar
          </Button>,
        ]}
      />
      {dataResult.departmentData === null ? (
        <Center>
          <Loader size="xl" />
        </Center>
      ) : (
        <PageContent>
          <FormView
            departments={dataResult.departmentData}
            user={dataResult.userData}
            loading={saving}
            save={save}
          />
        </PageContent>
      )}
    </Card>
  );
}

export default UserEdit;
