/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { Accordion, Alert, Badge, Button, Divider, Group, Modal, Space, Text } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { AlertTriangle, Briefcase, Check, CurrencyReal, Plus, Share, X } from 'tabler-icons-react';
import {
  converterDestinacao,
  converterLocacao,
  converterTransporte,
} from '../../../../../../../../../../business/proposals/residue-plan';
import PageSection from '../../../../../../../../../../components/core/PageSection/PageSection';
import { useProposalResidueContext } from '../../../../../../../../../../contexts/core/proposals/ProposalResidue.context';
import { useProposalResiduePlanContext } from '../../../../../../../../../../contexts/core/proposals/ProposalResiduePlan.context';
import { EntityTypeType, UnitOfMeasureType } from '../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../models/core/core.type';
import {
  ProposalResiduePlanQuotationType,
  ProposalResiduePlanType,
} from '../../../../../../../../../../models/core/proposals.type';
import { Feature } from '../../../../../../../../../../utils/constants.utils';
import { formatCurrency } from '../../../../../../../../../../utils/formatter.utils';
import { newGuid } from '../../../../../../../../../../utils/helper.utils';
import ResiduePlanQuotationSummary from '../../../../../../../components/ResiduePlanQuotationSummary';
import ResiduePlanQuotationFormAddEdit from './ResiduePlanQuotationFormView/ResiduePlanQuotationFormAddEdit';
import ResiduePlanQuotationFormViewActions from './ResiduePlanQuotationFormView/ResiduePlanQuotationFormViewActions';

type ProposalFormViewProps = {
  referenceData: {
    entityTypeData: EntityTypeType[];
    unitOfMeasures: UnitOfMeasureType[];
  };
  proposalResiduePlan: ProposalResiduePlanType | null;
};

const ResiduePlanQuotationFormView = forwardRef((props: ProposalFormViewProps, ref) => {
  const { proposalResidueData } = useProposalResidueContext();
  const { proposalResiduePlanData } = useProposalResiduePlanContext();

  const [data, setData] = useState<ProposalResiduePlanQuotationType[]>(
    props.proposalResiduePlan?.cotacoes.map((x: ProposalResiduePlanQuotationType) => {
      return {
        ...x,
        action: x.action || Action.Nothing,
        id: x.idPropostaResiduoPlanoCotacao?.toString() || x.idTemporaria || newGuid(),
      };
    }) || []
  );

  const [selectedData, setSelectedData] = useState<ProposalResiduePlanQuotationType[]>([]);
  const [quotationItem, setQuotationItem] = useState<ProposalResiduePlanQuotationType | null>(null);
  const [opened, setOpened] = useState(false);

  const residueEstimateUnityOfMeasure1 = props.referenceData.unitOfMeasures.find(
    (x) => x.idUnidadeMedida === Number(proposalResidueData.estimativaIdUnidadeMedida1)
  )?.unidadeMedida;

  const residueEstimateUnityOfMeasure2 = props.referenceData.unitOfMeasures.find(
    (x) => x.idUnidadeMedida === Number(proposalResidueData.estimativaIdUnidadeMedida2)
  )?.unidadeMedida;

  const columns: ColumnsType<ProposalResiduePlanQuotationType> = [
    {
      title: '#',
      key: 'idPropostaResiduoPlanoCotacao',
      sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) => {
        const aValue = a.idPropostaResiduoPlanoCotacao;
        const bValue = b.idPropostaResiduoPlanoCotacao;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: ProposalResiduePlanQuotationType) => (
        <div>{row.idPropostaResiduoPlanoCotacao || '-'}</div>
      ),
    },
    {
      title: `Locação / ${residueEstimateUnityOfMeasure2}`,
      key: 'locacao',
      render: (row: ProposalResiduePlanQuotationType) => {
        if (
          row.acondicionamentos.filter((x) => x.action !== Action.Delete).length === 0 &&
          row.equipamentos.filter((x) => x.action !== Action.Delete).length === 0
        ) {
          return '-';
        }

        const locacao = converterLocacao(
          props.referenceData.unitOfMeasures,
          proposalResidueData.estimativaIdUnidadeMedida2!,
          { ...props.proposalResiduePlan!, ...proposalResiduePlanData },
          row
        );

        const valido = locacao.every((x) => x.valido);
        const cobrarSemUso = locacao.some((x) => x.cobrarSemUso !== undefined && !x.cobrarSemUso);

        return (
          <Accordion chevronPosition="right">
            <Accordion.Item value="rent">
              <Accordion.Control>
                {valido && locacao.length > 0 ? (
                  <Text size="sm" color={cobrarSemUso ? 'orange' : 'primary'} weight="500">
                    {formatCurrency(locacao.map((x) => x.preco).reduce((a, b) => a + b, 0))}
                  </Text>
                ) : (
                  <Text size="sm" weight="500">
                    R$ ?
                  </Text>
                )}
              </Accordion.Control>
              <Accordion.Panel>
                {locacao.map((x) => {
                  return (
                    <div>
                      <Divider my="sm" variant="dotted" />
                      <Group spacing={3} className="custom-mantine-badge-group-down">
                        <x.icon size={16} />
                        {x.gerenciar ? <Briefcase size={16} /> : <></>}
                        {x.compartilhado ? <Share size={16} /> : <></>}
                        <Badge key={newGuid()} variant="outline" size="sm">
                          <Group spacing={3} className="custom-mantine-badge-group-up">
                            <div>{x.item}</div>
                            {x.valido ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                          </Group>
                        </Badge>
                      </Group>
                      <Group spacing={3}>
                        <CurrencyReal size={16} />
                        <Text size="sm">
                          {`${x.valido ? x.preco.toFixed(2) : '?'} / ${residueEstimateUnityOfMeasure2}`}
                        </Text>
                      </Group>
                    </div>
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );
      },
    },
    {
      title: `Transporte`,
      key: 'transporte',
      render: (row: ProposalResiduePlanQuotationType) => {
        if (!row.veiculo || row.veiculo.action === Action.Delete) {
          return '-';
        }

        const transporte = converterTransporte(
          { ...props.proposalResiduePlan!, ...proposalResiduePlanData },
          row
        );

        return (
          <Accordion chevronPosition="right">
            <Accordion.Item value="rent">
              <Accordion.Control>
                {transporte.valido ? (
                  <Text size="sm" weight="500">
                    {`${formatCurrency(transporte.preco)} / ${row.veiculo.frequenciaUnidadeMedida}`}
                  </Text>
                ) : (
                  <Text size="sm" weight="500">
                    R$ ?
                  </Text>
                )}
              </Accordion.Control>
              <Accordion.Panel>
                <div>
                  <Divider my="sm" variant="dotted" />
                  <Group spacing={3} className="custom-mantine-badge-group-down">
                    <transporte.icon size={16} />
                    <Badge key={newGuid()} variant="outline" size="sm">
                      <Group spacing={3} className="custom-mantine-badge-group-up">
                        <div>{transporte.item}</div>
                        {transporte.valido ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                      </Group>
                    </Badge>
                  </Group>
                  <Group spacing={3}>
                    <CurrencyReal size={16} />
                    <Text size="sm">
                      {`${transporte.valido ? transporte.preco.toFixed(2) : '?'} / ${
                        row.veiculo.frequenciaUnidadeMedida
                      }`}
                    </Text>
                  </Group>
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );
      },
    },
    {
      title: `Destinação / ${residueEstimateUnityOfMeasure1}`,
      key: 'destinacao',
      render: (row: ProposalResiduePlanQuotationType) => {
        if (
          (!row.tratamento || row.tratamento.action === Action.Delete) &&
          (!row.destinoFinal || row.destinoFinal.action === Action.Delete)
        ) {
          return '-';
        }

        const destinacao = converterDestinacao(
          props.referenceData.unitOfMeasures,
          proposalResidueData.estimativa!,
          proposalResidueData.estimativaIdUnidadeMedida1!,
          row
        );

        const valido = destinacao.every((x) => x.valido);

        return (
          <Accordion chevronPosition="right">
            <Accordion.Item value="rent">
              <Accordion.Control>
                {valido && destinacao.length > 0 ? (
                  <Text size="sm" weight="500">
                    {formatCurrency(
                      destinacao
                        .map((x) => x.preco)
                        .reduce(
                          (a, b, i) =>
                            a + (destinacao[i].receita !== undefined && destinacao[i].receita ? b * -1 : b),
                          0
                        )
                    )}
                  </Text>
                ) : (
                  <Text size="sm" weight="500">
                    R$ ?
                  </Text>
                )}
              </Accordion.Control>
              <Accordion.Panel>
                {destinacao.map((x) => {
                  return (
                    <div>
                      <Divider my="sm" variant="dotted" />
                      <Group spacing={3} className="custom-mantine-badge-group-down">
                        <x.icon size={16} />
                        <Badge key={newGuid()} variant="outline" size="sm">
                          <Group spacing={3} className="custom-mantine-badge-group-up">
                            <div>{x.item}</div>
                            {x.valido ? <Check size={14} color="green" /> : <X size={14} color="red" />}
                          </Group>
                        </Badge>
                      </Group>
                      <Group spacing={3}>
                        <CurrencyReal size={16} />
                        <Text color={x.receita !== undefined && x.receita ? 'green' : 'red'} size="sm">
                          {`${x.valido ? x.preco.toFixed(2) : '?'} / ${residueEstimateUnityOfMeasure1}`}
                        </Text>
                      </Group>
                    </div>
                  );
                })}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        );
      },
    },
    {
      title: 'Completo?',
      width: '120px',
      key: 'completo',
      dataIndex: 'completo',
      sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) =>
        a.completo === b.completo ? 0 : a.completo ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Primário?',
      width: '120px',
      key: 'primario',
      dataIndex: 'primario',
      sorter: (a: ProposalResiduePlanQuotationType, b: ProposalResiduePlanQuotationType) =>
        a.primario === b.primario ? 0 : a.primario ? -1 : 1,
      render: (row: boolean) => <div>{row ? <Check color="green" /> : <X color="red" />}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: ProposalResiduePlanQuotationType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <ResiduePlanQuotationFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (
    item: ProposalResiduePlanQuotationType | null,
    action: string,
    confirmed: boolean
  ) => {
    if (!confirmed) {
      return;
    }
    let dataItemIndex: number;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idPropostaResiduoPlanoCotacao) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(JSON.parse(JSON.stringify(data)));
        }
        break;
      case 'editar':
      case 'adicionar':
        setQuotationItem(item);
        setOpened(true);
        break;
      case 'callback':
        setOpened(false);
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
          if (!item!.completo && item!.primario) {
            data[dataItemIndex].primario = false;
          }
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      case 'primario':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        dataItem = data[dataItemIndex];
        data[dataItemIndex] = { ...dataItem, ...item };

        data.forEach((element, index) => {
          if (index !== dataItemIndex && element.primario) {
            element.primario = false;
            if (element.action === Action.Nothing) {
              element.action = Action.Modify;
            }
          }
        });
        setData(JSON.parse(JSON.stringify(data)));
        break;
      default:
        break;
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProposalResiduePlanQuotationType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ProposalResiduePlanQuotationType) => ({
      name: record.id || record.idPropostaResiduoPlanoCotacao?.toString(),
    }),
  };

  useImperativeHandle(ref, () => ({
    validate(): ProposalResiduePlanQuotationType[] {
      return data;
    },
    clear() {
      setData([]);
    },
  }));

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title={`Resíduo/Plano/Cotação - ${quotationItem ? 'Editar' : 'Adicionar'}`}
        size="60%"
      >
        <ResiduePlanQuotationFormAddEdit
          referenceData={props.referenceData}
          origItem={
            quotationItem
              ? props.proposalResiduePlan?.cotacoes?.find(
                  (x) =>
                    x.idPropostaResiduoPlanoCotacao === quotationItem.idPropostaResiduoPlanoCotacao &&
                    x.idPropostaResiduoPlano !== -1
                ) || null
              : null
          }
          item={quotationItem}
          idPropostaResiduoPlano={props.proposalResiduePlan?.idPropostaResiduoPlano}
          callback={confirmActionResult}
        />
      </Modal>
      <div>
        {props.proposalResiduePlan?.idPropostaResiduoPlano &&
          proposalResiduePlanData &&
          (proposalResiduePlanData.acondicionamentos?.some((x) => x.action && x.action !== Action.Nothing) ||
            proposalResiduePlanData.equipamentos?.some((x) => x.action && x.action !== Action.Nothing) ||
            (proposalResiduePlanData.veiculo?.action &&
              proposalResiduePlanData.veiculo.action !== Action.Nothing) ||
            (proposalResiduePlanData.tratamento?.action &&
              proposalResiduePlanData.tratamento.action !== Action.Nothing) ||
            (proposalResiduePlanData.destinoFinal?.action &&
              proposalResiduePlanData.destinoFinal.action !== Action.Nothing)) && (
            <>
              <Alert icon={<AlertTriangle size={16} />} title="Atenção!" color="yellow">
                O plano foi alterado! Entre em cada cotação e salve-a novamente.
              </Alert>
              <Space h="xl" />
            </>
          )}
      </div>

      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Proposal.color}
          label="Cotações"
          text="Cotações para este plano de coleta de resíduo."
        />
        <Group>
          <Button
            color="accent"
            leftIcon={<Check size={18} />}
            onClick={() => {
              const item = selectedData[0];
              item.primario = true;
              if (item.action === Action.Nothing) {
                item.action = Action.Modify;
              }
              confirmActionResult(item, 'primario', true);
            }}
            disabled={selectedData.length === 0 || selectedData?.[0].primario || !selectedData[0].completo}
          >
            Definir como primário
          </Button>
          <Button
            color="primary"
            leftIcon={<Plus size={18} />}
            onClick={() => {
              confirmActionResult(null, 'adicionar', true);
            }}
            disabled={!proposalResiduePlanData.tratamento && !proposalResiduePlanData.destinoFinal}
          >
            Adicionar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowSelection={{ type: 'radio', ...rowSelection }}
        rowKey={(item: ProposalResiduePlanQuotationType) =>
          item.id || item.idPropostaResiduoPlanoCotacao || 0
        }
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (row) => (
            <ResiduePlanQuotationSummary data={props.proposalResiduePlan!} quotation={row} />
          ),
        }}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default ResiduePlanQuotationFormView;
