/* eslint-disable react/destructuring-assignment */
import { Badge, Group, Space, Text, Timeline } from '@mantine/core';
import { useMemo } from 'react';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { ContractType } from '../../../../../../models/core/contracts.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatTimestampToString } from '../../../../../../utils/formatter.utils';

type DataViewProps = {
  data: ContractType;
};

export default function StatusView(props: DataViewProps) {
  const buildStatuses = useMemo(() => {
    const list = props.data.status || [];
    if (list.length === 0) {
      return <div />;
    }
    return (
      <Timeline active={0} bulletSize={30} lineWidth={2} radius="sm" color={Feature.Home.Contract.color}>
        {list.reverse().map((x) => (
          <Timeline.Item
            key={x.idContratoStatus.toString()}
            bullet={<Text size="sm">{x.codigoContratoStatus}</Text>}
            title={x.contratoStatus}
            color="primary"
          >
            {x.codigoContratoStatusMotivo && <Badge variant="outline">{x.contratoStatusMotivo}</Badge>}
            {x.observacao && (
              <Text color="dimmed" size="sm">
                {x.observacao || '-'}
              </Text>
            )}
            <Text size="xs" mt={4}>
              <ProfileCardLink
                id={x.idCriadoPor}
                name={x.criadoPor}
                nameSize="sm"
                description={`Modificado em ${formatTimestampToString(x.dataCriacao)}`}
                descriptionSize="xs"
              />
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }, [props.data.status]);

  return (
    <div>
      <Group>
        <PageSection
          size="lg"
          color={Feature.Home.Contract.color}
          label="Status"
          text="Histórico de todas as mudanças de status do contrato."
        />
      </Group>
      <Space h="xs" />
      {buildStatuses}
    </div>
  );
}
