import {
  DashboardRequestType,
  ExpiringContractsResponseType,
  ExpiringEntityDocumentsResponseType,
  ExpiringProposalsResponseType,
  MyEventsResponseType,
  PendingEventsResponseType,
} from '../../models/core/dashboard.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';
import { call } from '../core.service';

const dashboardsEndpoint = '/v1/dashboard';

export default class DashboardService {
  static myEvents = async (searchProps: DashboardRequestType): Promise<MyEventsResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: dashboardsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static pendingEvents = async (searchProps: DashboardRequestType): Promise<PendingEventsResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: dashboardsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static expiringContracts = async (
    searchProps: DashboardRequestType
  ): Promise<ExpiringContractsResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: dashboardsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static expiringProposals = async (
    searchProps: DashboardRequestType
  ): Promise<ExpiringProposalsResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: dashboardsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };

  static expiringEntityDocuments = async (
    searchProps: DashboardRequestType
  ): Promise<ExpiringEntityDocumentsResponseType[]> => {
    const request: AxiosCustomRequestType = {
      method: 'GET',
      url: dashboardsEndpoint,
      params: searchProps,
    };
    return (await call(request))?.data?.data;
  };
}
