/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  ActionIcon,
  Grid,
  Group,
  Input,
  NumberInput,
  Select,
  SimpleGrid,
  Space,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { BrightnessHalf, Clock, InfoCircle, Notes, Ruler2 } from 'tabler-icons-react';
import EquipmentSearch from '../../../../../../../components/core/EquipmentSearch/EquipmentSearch';
import PackagingSearch from '../../../../../../../components/core/PackagingSearch/PackagingSearch';
import { useEventGeneralContext } from '../../../../../../../contexts/core/events/EventGeneral.context';
import { useEventServiceContext } from '../../../../../../../contexts/core/events/EventService.context';
import { UnitOfMeasureType } from '../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../models/core/core.type';
import { EquipmentType } from '../../../../../../../models/core/equipment.type';
import { EventServiceType } from '../../../../../../../models/core/events.type';
import { PackagingType } from '../../../../../../../models/core/packaging.type';
import theme from '../../../../../../../theme';
import { EntityItemEnum } from '../../../../../../../utils/constants.utils';
import { buildFakeAuditObject, newGuid } from '../../../../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;
  tipo: string;

  quantidade: number | null;
  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string;
  idResiduoEquipamento: number | null;
  residuoEquipamento: string;

  frequencia: number | null;
  frequenciaIdUnidadeMedida1: string | null;
  frequenciaIdUnidadeMedida2: string | null;
  turno: string | null;
  jornada: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: EventServiceType | null;
  item: EventServiceType | null;
  disabled: boolean;
};

const EventServiceFormAddEdit = forwardRef((props: FormViewProps, ref) => {
  const { eventGeneralData } = useEventGeneralContext();
  const { setEventServiceData } = useEventServiceContext();

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      tipo: eventGeneralData.propostaServicoData?.idResiduoAcondicionamento
        ? EntityItemEnum.Packaging
        : eventGeneralData.propostaServicoData?.idResiduoEquipamento
        ? EntityItemEnum.Equipment
        : EntityItemEnum.Service,

      quantidade: props.item?.quantidade || 1,
      idResiduoAcondicionamento: props.item?.idResiduoAcondicionamento || null,
      residuoAcondicionamento: props.item?.residuoAcondicionamento || '',
      idResiduoEquipamento: props.item?.idResiduoEquipamento || null,
      residuoEquipamento: props.item?.residuoEquipamento || '',

      frequencia: props.item?.frequencia || 1,
      frequenciaIdUnidadeMedida1: props.item?.frequenciaIdUnidadeMedida1?.toString() || null,
      frequenciaIdUnidadeMedida2: props.item?.frequenciaIdUnidadeMedida2?.toString() || null,
      turno: props.item?.turno?.toString() || '',
      jornada: props.item?.jornada || '',

      observacao: props.item?.observacao || '',
    },
    validate: {
      quantidade: (value: number | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      residuoAcondicionamento: (value, values: FormViewData) => {
        if (value.trim() === '' && values.tipo === EntityItemEnum.Packaging) {
          return 'Campo obrigatório';
        }
        return null;
      },
      residuoEquipamento: (value, values: FormViewData) => {
        if (value.trim() === '' && values.tipo === EntityItemEnum.Equipment) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequencia: (value: number | null, values: FormViewData) => {
        if (!value && values.tipo === EntityItemEnum.Service) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida1: (value: string | null, values: FormViewData) => {
        if (!value && values.tipo === EntityItemEnum.Service) {
          return 'Campo obrigatório';
        }
        return null;
      },
      frequenciaIdUnidadeMedida2: (value: string | null, values: FormViewData) => {
        if (!value && values.tipo === EntityItemEnum.Service) {
          return 'Campo obrigatório';
        }
        return null;
      },
      jornada: (value: string | null, values: FormViewData) => {
        if (
          !value ||
          /^(((Seg|Ter|Qua|Qui|Sex|Sab|Dom)-(Seg|Ter|Qua|Qui|Sex|Sab|Dom) \d{2}:\d{2}-\d{2}:\d{2}),?){1,}$/.test(
            value || ''
          ) ||
          values.tipo !== EntityItemEnum.Service
        ) {
          return null;
        }
        return 'Formato inválido (Ex: Seg-Sex 08:00-17:00,Sab-Sab 08:00-12:00)';
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.quantidade || ''}
    |${props.origItem?.idResiduoAcondicionamento || ''}
    |${props.origItem?.idResiduoEquipamento || ''}
    |${Number(props.origItem?.frequencia) || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida1 || ''}
    |${props.origItem?.frequenciaIdUnidadeMedida2 || ''}
    |${Number(props.origItem?.turno) || ''}
    |${props.origItem?.jornada || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.quantidade || ''}
    |${form.values.idResiduoAcondicionamento || ''}
    |${form.values.idResiduoEquipamento || ''}
    |${Number(form.values.frequencia) || ''}
    |${form.values.frequenciaIdUnidadeMedida1 || ''}
    |${form.values.frequenciaIdUnidadeMedida2 || ''}
    |${Number(form.values.turno) || ''}
    |${form.values.jornada || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): EventServiceType | null {
      if (form.validate().hasErrors) {
        return null;
      }

      let { values } = form;
      if (props.origItem) {
        if (isModified()) {
          values.action = Action.Modify;
        } else {
          values = {
            ...values,
            ...props.origItem,
            action: Action.Nothing,
            residuoAcondicionamento: form.values.residuoAcondicionamento || '',
            residuoEquipamento: form.values.residuoEquipamento || '',
            frequenciaIdUnidadeMedida1: form.values.frequenciaIdUnidadeMedida1?.toString() || '',
            frequenciaIdUnidadeMedida2: form.values.frequenciaIdUnidadeMedida2?.toString() || '',
          };
        }
      }

      const formItem: EventServiceType = {
        ...values,
        ...buildFakeAuditObject(),
        idTemporaria: values.id,
        idEventoServico: props.item?.idEventoServico || -1,

        quantidade: Number(values.quantidade),
        frequenciaIdUnidadeMedida1: Number(values.frequenciaIdUnidadeMedida1),
        frequenciaIdUnidadeMedida2: Number(values.frequenciaIdUnidadeMedida2),
      };

      if (values.tipo === EntityItemEnum.Packaging) {
        formItem.idResiduoEquipamento = null;
        formItem.residuoEquipamento = null;
        formItem.residuoEquipamentoDescricao = null;
      } else if (values.tipo === EntityItemEnum.Equipment) {
        formItem.idResiduoAcondicionamento = null;
        formItem.residuoAcondicionamento = null;
        formItem.residuoAcondicionamentoDescricao = null;
      } else {
        formItem.idResiduoEquipamento = null;
        formItem.residuoEquipamento = null;
        formItem.residuoEquipamentoDescricao = null;
        formItem.idResiduoAcondicionamento = null;
        formItem.residuoAcondicionamento = null;
        formItem.residuoAcondicionamentoDescricao = null;
      }

      if (values.tipo !== EntityItemEnum.Service) {
        formItem.frequencia = 1;
        formItem.frequenciaIdUnidadeMedida1 = Number(
          props.referenceData.unitOfMeasures.find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
            ?.idUnidadeMedida || null
        );
        formItem.frequenciaIdUnidadeMedida2 = Number(
          props.referenceData.unitOfMeasures.find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
            ?.idUnidadeMedida || null
        );
        formItem.turno = null;
        formItem.jornada = null;
      }

      formItem.frequenciaUnidadeMedida1 = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === formItem.frequenciaIdUnidadeMedida1
      )?.unidadeMedida;

      formItem.frequenciaUnidadeMedida2 = props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === formItem.frequenciaIdUnidadeMedida2
      )?.unidadeMedida;

      formItem.jornada = formItem.jornada?.trim() || null;
      formItem.observacao = formItem.observacao?.trim() || null;

      return formItem;
    },
  }));

  useEffect(() => {
    setEventServiceData({
      tipo: form.values.tipo as EntityItemEnum,
      quantidade: form.values.quantidade,
      idServico: eventGeneralData.propostaServicoData?.idServico || null,
      idResiduoAcondicionamento: form.values.idResiduoAcondicionamento,
      idResiduoEquipamento: form.values.idResiduoEquipamento,
      frequencia: form.values.frequencia,
      frequenciaIdUnidadeMedida1: Number(form.values.frequenciaIdUnidadeMedida1),
      frequenciaIdUnidadeMedida2: Number(form.values.frequenciaIdUnidadeMedida2),
    });
  }, [
    eventGeneralData.idEventoReferencia1,
    eventGeneralData.propostaServicoData?.idServico,
    form.values,
    setEventServiceData,
  ]);

  return (
    <form id="event-service" noValidate>
      <Grid columns={6}>
        <Grid.Col span={1}>
          <NumberInput
            label="Quantidade"
            placeholder="Quantidade"
            min={1}
            defaultValue={1}
            step={1}
            stepHoldDelay={500}
            stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
            required
            {...form.getInputProps('quantidade')}
            disabled={props.disabled}
          />
        </Grid.Col>

        <Grid.Col span={1} hidden>
          <div>
            <Input.Wrapper label="Referência" required>
              <Group
                style={{ display: 'inline-block', marginLeft: 5, marginTop: -2, verticalAlign: 'bottom' }}
              >
                <Tooltip
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  label="Determina o tipo do item a ser associado com este serviço."
                >
                  <div>
                    <InfoCircle size={18} color={theme?.colors?.accent?.[6]} />
                  </div>
                </Tooltip>
              </Group>
            </Input.Wrapper>
            <Select
              placeholder="Selecione..."
              data={[
                { label: 'Fornecedor', value: EntityItemEnum.Service },
                { label: 'Acondicionamento', value: EntityItemEnum.Packaging },
                { label: 'Equipamento', value: EntityItemEnum.Equipment },
              ]}
              // {...form.getInputProps('tipo')}
              value={form.values.tipo}
              onChange={(tipo: string) => {
                form.setFieldValue('tipo', tipo);

                if (tipo === EntityItemEnum.Packaging || tipo === EntityItemEnum.Equipment) {
                  form.setFieldValue('frequencia', 1);
                  form.setFieldValue(
                    'frequenciaIdUnidadeMedida1',
                    props.referenceData.unitOfMeasures
                      .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                      ?.idUnidadeMedida?.toString() || null
                  );
                  form.setFieldValue(
                    'frequenciaIdUnidadeMedida2',
                    props.referenceData.unitOfMeasures
                      .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                      ?.idUnidadeMedida?.toString() || null
                  );
                  form.setFieldValue('turno', null);
                  form.setFieldValue('jornada', null);
                } else {
                  form.setFieldValue('frequencia', 1);
                  form.setFieldValue('frequenciaIdUnidadeMedida1', null);
                  form.setFieldValue('frequenciaIdUnidadeMedida2', null);
                }

                if (tipo === EntityItemEnum.Packaging) {
                  form.setFieldValue('idResiduoEquipamento', null);
                  form.setFieldValue('residuoEquipamento', '');
                } else if (tipo === EntityItemEnum.Equipment) {
                  form.setFieldValue('idResiduoAcondicionamento', null);
                  form.setFieldValue('residuoAcondicionamento', '');
                } else {
                  form.setFieldValue('idResiduoEquipamento', null);
                  form.setFieldValue('residuoEquipamento', '');
                  form.setFieldValue('idResiduoAcondicionamento', null);
                  form.setFieldValue('residuoAcondicionamento', '');
                }
              }}
              required
              disabled={props.disabled}
            />
          </div>
        </Grid.Col>

        {form.values.tipo === EntityItemEnum.Packaging ? (
          <Grid.Col span={2} hidden>
            <PackagingSearch
              label="Acondicionamento"
              formItem={form.getInputProps('residuoAcondicionamento')}
              idsToBeDisabled={[]}
              callback={(item: PackagingType | null) => {
                if (item) {
                  form.setFieldValue('idResiduoAcondicionamento', item.idResiduoAcondicionamento);
                  form.setFieldValue('residuoAcondicionamento', item.residuoAcondicionamento);
                }
              }}
              disabled={props.disabled}
            />
          </Grid.Col>
        ) : form.values.tipo === EntityItemEnum.Equipment ? (
          <Grid.Col span={2} hidden>
            <EquipmentSearch
              label="Equipamento"
              formItem={form.getInputProps('residuoEquipamento')}
              idsToBeDisabled={[]}
              callback={(item: EquipmentType | null) => {
                if (item) {
                  form.setFieldValue('idResiduoEquipamento', item.idResiduoEquipamento);
                  form.setFieldValue('residuoEquipamento', item.residuoEquipamento);
                }
              }}
              disabled={props.disabled}
            />
          </Grid.Col>
        ) : (
          <></>
        )}

        <Grid.Col span={5}>
          <Input.Wrapper label="Frequência" required>
            <div />
          </Input.Wrapper>
          <Group spacing="xs">
            {form.values.tipo === EntityItemEnum.Service && (
              <NumberInput
                placeholder="Frequência"
                min={0.5}
                defaultValue={1}
                precision={2}
                step={0.5}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                disabled={props.disabled || form.values.tipo !== EntityItemEnum.Service}
                {...form.getInputProps('frequencia')}
              />
            )}
            <Select
              icon={<Ruler2 size={15} />}
              placeholder="Selecione..."
              data={props.referenceData.unitOfMeasures
                .filter(
                  (x) =>
                    (x.tipo === 'Tempo' && x.unidadeMedidaSigla === 'h') ||
                    (x.tipo === 'Outro' && x.unidadeMedidaSigla !== 'per')
                )
                .map((x) => {
                  return {
                    value: x.idUnidadeMedida.toString(),
                    label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                    group: x.tipo,
                  };
                })}
              searchable
              disabled={props.disabled || form.values.tipo !== EntityItemEnum.Service}
              required
              {...form.getInputProps('frequenciaIdUnidadeMedida1')}
            />
            <Text>/</Text>
            <Select
              icon={<Ruler2 size={15} />}
              placeholder="Selecione..."
              data={props.referenceData.unitOfMeasures
                .filter(
                  (x) =>
                    (x.tipo === 'Tempo' &&
                      (x.unidadeMedidaSigla === 'd' ||
                        x.unidadeMedidaSigla === 'sem' ||
                        x.unidadeMedidaSigla === 'mês' ||
                        x.unidadeMedidaSigla === 'ano')) ||
                    (x.tipo === 'Outro' && x.unidadeMedidaSigla === 'per')
                )
                .map((x) => {
                  return {
                    value: x.idUnidadeMedida.toString(),
                    label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                    group: x.tipo,
                  };
                })}
              searchable
              disabled={props.disabled || form.values.tipo !== EntityItemEnum.Service}
              required
              {...form.getInputProps('frequenciaIdUnidadeMedida2')}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <Space h="xs" />

      {form.values.tipo === EntityItemEnum.Service && (
        <div>
          <Grid columns={6}>
            <Grid.Col span={1}>
              <Select
                icon={<BrightnessHalf size={15} />}
                label="Turno"
                placeholder="Selecione..."
                data={['1º Turno', '2º Turno', '3º Turno'].map((x, i) => {
                  return {
                    value: i.toString(),
                    label: x,
                  };
                })}
                searchable
                clearable
                {...form.getInputProps('turno')}
                disabled={props.disabled}
              />
            </Grid.Col>
            <Grid.Col span={5}>
              <TextInput
                icon={<Clock size={15} />}
                rightSection={
                  <ActionIcon
                    size="sm"
                    radius="sm"
                    color="primary"
                    style={{ borderColor: theme?.colors?.primary?.[6] }}
                    variant="outline"
                    onClick={() => {
                      form.setFieldValue('jornada', 'Seg-Sex 08:00-17:00,Sab-Sab 08:00-12:00');
                    }}
                    disabled={props.disabled}
                  >
                    <Clock size={15} color={theme?.colors?.primary?.[6]} />
                  </ActionIcon>
                }
                label="Jornada"
                placeholder="Jornada"
                maxLength={100}
                {...form.getInputProps('jornada')}
                disabled={props.disabled}
              />
            </Grid.Col>
          </Grid>
          <Space h="xs" />
        </div>
      )}

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Observação"
          placeholder="Observação sobre o serviço"
          autosize
          maxLength={500}
          {...form.getInputProps('observacao')}
          disabled={props.disabled}
        />
      </SimpleGrid>
    </form>
  );
});

export default EventServiceFormAddEdit;
