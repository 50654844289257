/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Group, Paper, Space } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useModals } from '@mantine/modals';
import {
  EntityDocumentFileType,
  EntityDocumentType,
  EntityType,
} from '../../../../../../../models/core/entities.type';
import DocumentFormAddEdit from './DocumentFormAddEdit';
import { DocumentType } from '../../../../../../../models/core/cache.type';
import { Feature, SessionStorageKey } from '../../../../../../../utils/constants.utils';
import { formatDateToString } from '../../../../../../../utils/formatter.utils';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import TableCellEllipsis from '../../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import DocumentFormViewActions from './DocumentFormViewActions';
import FileView from '../../../../EntityView/components/components/FileView';
import { Action } from '../../../../../../../models/core/core.type';

type EntityDocumentEditType = EntityDocumentType & {
  origFiles?: EntityDocumentFileType[];
};

type FormViewProps = {
  referenceData: {
    documentTypeData: DocumentType[];
  };
  entity: EntityType | null;
};

const DocumentFormView = forwardRef((props: FormViewProps, ref) => {
  const modals = useModals();

  const [data, setData] = useState<EntityDocumentEditType[]>(
    props.entity?.documentos.map((x) => {
      return {
        ...x,
        // origFiles: x.arquivos,
        action: Action.Nothing,
        id: x.idEntidadeDocumento?.toString() || '',
      };
    }) || []
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo',
      key: 'documentoTipo',
      dataIndex: 'documentoTipo',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) =>
        a.documentoTipo.localeCompare(b.documentoTipo),
    },
    {
      title: 'Outro Tipo',
      key: 'outroTipo',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        return (a.outroTipo || '').localeCompare(b.outroTipo || '');
      },
      render: (row: EntityDocumentType) => <div>{row?.outroTipo || '-'}</div>,
    },
    {
      title: 'Número do Documento',
      key: 'numeroDocumento',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        return (a.numeroDocumento || '').localeCompare(b.numeroDocumento || '');
      },
      render: (row: EntityDocumentType) => <div>{row?.numeroDocumento || '-'}</div>,
    },
    {
      title: 'Lembrete (dias)',
      key: 'validadeLembreteDias',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        const aValue = a.validadeLembreteDias || 0;
        const bValue = b.validadeLembreteDias || 0;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityDocumentType) => <div>{row?.validadeLembreteDias || '-'}</div>,
    },
    {
      title: 'Validade',
      key: 'validade',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) =>
        new Date(a.validade).valueOf() - new Date(b.validade).valueOf(),
      render: (row: EntityDocumentType) => formatDateToString(row.validade),
    },
    {
      title: 'Observação',
      key: 'observacao',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        return (a.observacao || '').localeCompare(b.observacao || '');
      },
      render: (row: EntityDocumentType) => {
        return <TableCellEllipsis label={row?.observacao} numberOfChars={20} toolTipWidth={300} />;
      },
    },
    {
      title: 'Arquivos',
      key: 'arquivos',
      sorter: (a: EntityDocumentType, b: EntityDocumentType) => {
        const aValue = a.arquivos.filter((x) => x.action !== Action.Delete).length;
        const bValue = b.arquivos.filter((x) => x.action !== Action.Delete).length;
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityDocumentType) => <div>{row.arquivos.length}</div>,
    },
    {
      title: 'Ações',
      width: '100px',
      render: (row: EntityDocumentType) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <DocumentFormViewActions item={row} confirmActionResult={confirmActionResult} />
      ),
    },
  ];

  const confirmActionResult = (item: EntityDocumentEditType | null, action: string, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }

    let dataItemIndex;
    let dataItem;

    switch (action) {
      case 'excluir':
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          if (dataItem.idEntidadeDocumento) {
            data[dataItemIndex] = { ...dataItem, action: Action.Delete };
          } else {
            data.splice(dataItemIndex, 1);
          }
          setData(data);
        }
        break;
      case 'editar':
      case 'adicionar':
        modals.openModal({
          title: `Documento - ${item ? 'Editar' : 'Adicionar'}`,
          size: 'xl',
          closeOnClickOutside: false,
          children: (
            <DocumentFormAddEdit
              referenceData={props.referenceData}
              origItem={
                item
                  ? props.entity?.documentos.find(
                      (x) => x.idEntidadeDocumento === item.idEntidadeDocumento
                    ) || null
                  : null
              }
              item={item}
              idEntidade={props.entity?.idEntidade}
              callback={confirmActionResult}
            />
          ),
        });
        break;
      case 'callback':
        modals.closeAll();
        dataItemIndex = data.findIndex((x) => x.id === item?.id);
        if (dataItemIndex > -1) {
          dataItem = data[dataItemIndex];
          data[dataItemIndex] = { ...dataItem, ...item };
        } else {
          data.push(item!);
        }
        setData(data);
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    validate(): EntityDocumentEditType[] {
      return data.map((x) => {
        return {
          ...x,
          arquivos: x.arquivos.map((y) => {
            return { ...y, action: y.action || Action.Nothing };
          }),
        };
      });
    },
    clear() {
      setData([]);
    },
  }));

  useEffect(() => {
    const tempEntity = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEntity) || 'null');
    if (tempEntity) {
      setData(tempEntity.documentos);
    }
  }, []);

  return (
    <div>
      <Group position="apart">
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Documentos" text="" />
        <Button
          color="primary"
          leftIcon={<Plus size={18} />}
          onClick={() => {
            confirmActionResult(null, 'adicionar', true);
          }}
        >
          Adicionar
        </Button>
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        dataSource={data.filter((x) => x.action !== Action.Delete)}
        columns={columns}
        rowKey={(item: EntityDocumentEditType) => item.id || 0}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandedRowRender: (item) => {
            return (
              <Paper shadow="xs" p="md" withBorder>
                <FileView
                  typeId={null}
                  data={item.arquivos.filter((x) => x.action !== Action.Delete)}
                  size="sm"
                  expandable={false}
                />
              </Paper>
            );
          },
        }}
        pagination={{
          locale: { items_per_page: 'página' },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
});

export default DocumentFormView;
