/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ScrollArea, Navbar, Box, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Brand from './Brand/Brand';
import DataReferenceLinks from './DataReferenceLinks/DataReferenceLinks';
import DefaultLinks from './DefaultLinks/DefaultLinks';
import ManagerialLinks from './ManagerialLinks/ManagerialLinks';
import { LocalStorageKey } from '../../../utils/constants.utils';

const NavBar = forwardRef((_props, ref) => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [currentUser] = useCurrentUser();

  useImperativeHandle(ref, () => ({
    setOpened(status: boolean): void {
      setOpened(status);
    },
  }));

  useEffect(() => {
    const currentAppVersion = process.env.REACT_APP_VERSION!;
    let localAppVersion = localStorage.getItem(LocalStorageKey.ApplicationVersion);

    if (!localAppVersion) {
      localAppVersion = '0.0.0';
    }

    if (Number(localAppVersion.replaceAll('.', '')) < Number(currentAppVersion.replaceAll('.', ''))) {
      localStorage.setItem(LocalStorageKey.ApplicationVersion, currentAppVersion);
      navigate('/logout', {
        replace: true,
      });
    }
  }, []);

  return (
    <Navbar hiddenBreakpoint="sm" hidden={!opened} p="xs" width={{ sm: 300, lg: 300 }}>
      <Navbar.Section mt="xs">
        <Brand />
      </Navbar.Section>
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <Box py="sm">
          <DefaultLinks visible={currentUser.gerencial} userPermissions={currentUser.permissoes} />
          <DataReferenceLinks userPermissions={currentUser.permissoes} />
          <ManagerialLinks visible={currentUser.gerencial} />
        </Box>
      </Navbar.Section>
      <Navbar.Section>
        <Text color="dimmed" size="xs" align="center">
          Performa Manager © {new Date().getFullYear()}
        </Text>
        <Text color="dimmed" size="xs" align="center">
          Version {process.env.REACT_APP_VERSION}
        </Text>
      </Navbar.Section>
    </Navbar>
  );
});

export default NavBar;
