import { call } from '../core.service';
import { FileDownloadType } from '../../models/core/files.type';
import { AxiosCustomRequestType } from '../../models/utils/axios.type';

const filesEndpoint = '/v1/arquivos';

export default class FilesService {
  static download = async (insertProps: FileDownloadType): Promise<any> => {
    const request: AxiosCustomRequestType = {
      method: 'POST',
      url: filesEndpoint,
      data: insertProps,
      timeout: 60000,
    };
    return call(request);
  };
}
