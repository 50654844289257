import {
  AddressTypeType,
  ClosingType,
  CompanyType,
  ContractStatusType,
  DismissalStatusType,
  DocumentType,
  EntityTypeType,
  EventRelationTypeData,
  EventStatusReasonType,
  EventStatusType,
  IBAMAResidueType,
  InvitationStatusType,
  MovementBillingType,
  MovementType,
  PaymentMethodType,
  ProposalStatusType,
  ResidueStateOfMatterType,
  ResidueTreatmentType,
  UnitOfMeasureType,
} from '../models/core/cache.type';
import othersService from '../services/core/references.service';
import { LocalStorageKey } from './constants.utils';

let cache: any = null;
const loadCache = async (): Promise<void> => {
  const data = await othersService.listReferences();
  cache = data;
  localStorage.setItem(LocalStorageKey.CoreDataReference, JSON.stringify(data));
};

const getCache = async (prop: string) => {
  if (cache === null) {
    const lsReferences = localStorage.getItem(LocalStorageKey.CoreDataReference);
    if (lsReferences !== null) {
      cache = JSON.parse(lsReferences);
    } else {
      await loadCache();
    }
  }
  return cache[prop];
};

const listClosings = async (): Promise<ClosingType[]> => {
  return getCache('fechamento');
};
const listProposalStatuses = async (): Promise<ProposalStatusType[]> => {
  return getCache('propostaStatus');
};
const listContractStatuses = async (): Promise<ContractStatusType[]> => {
  return getCache('contratoStatus');
};
const listEventStatuses = async (): Promise<EventStatusType[]> => {
  return getCache('eventoStatus');
};
const listCompanies = async (): Promise<CompanyType[]> => {
  return getCache('empresa');
};
const listAddressTypes = async (): Promise<AddressTypeType[]> => {
  return getCache('enderecoTipo');
};
const listEntityTypes = async (): Promise<EntityTypeType[]> => {
  return getCache('entidadeTipo');
};
const listPaymentMethods = async (): Promise<PaymentMethodType[]> => {
  return getCache('formaPagamento');
};
const listResidueStateOfMatters = async (): Promise<ResidueStateOfMatterType[]> => {
  return getCache('residuoEstadoFisico');
};
const listResidueTreatments = async (): Promise<ResidueTreatmentType[]> => {
  return getCache('residuoTratamento');
};
const listUnityOfMeasures = async (): Promise<UnitOfMeasureType[]> => {
  return getCache('unidadeMedida');
};
const listInvitationStatuses = async (): Promise<InvitationStatusType[]> => {
  return getCache('usuarioConviteStatus');
};
const listDismissalStatuses = async (): Promise<DismissalStatusType[]> => {
  return getCache('usuarioDesligamentoStatus');
};
const listDocumentTypes = async (): Promise<DocumentType[]> => {
  return getCache('documentoTipo');
};
const listMovementTypes = async (): Promise<MovementType[]> => {
  return getCache('movimentacaoTipo');
};
const listMovementBillingTypes = async (): Promise<MovementBillingType[]> => {
  return getCache('movimentacaoFaturamentoTipo');
};
const listEventRelationTypes = async (): Promise<EventRelationTypeData[]> => {
  return getCache('eventoRelacao');
};
const listIBAMAResidueTypes = async (): Promise<IBAMAResidueType[]> => {
  return getCache('residuoIBAMA');
};
const listEventStatusReasonTypes = async (): Promise<EventStatusReasonType[]> => {
  return getCache('eventoStatusMotivo');
};

export default {
  loadCache,
  listClosings,
  listProposalStatuses,
  listContractStatuses,
  listEventStatuses,
  listCompanies,
  listAddressTypes,
  listEntityTypes,
  listPaymentMethods,
  listResidueStateOfMatters,
  listResidueTreatments,
  listUnityOfMeasures,
  listInvitationStatuses,
  listDismissalStatuses,
  listDocumentTypes,
  listMovementTypes,
  listMovementBillingTypes,
  listEventRelationTypes,
  listIBAMAResidueTypes,
  listEventStatusReasonTypes,
};
