/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Modal, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Click, Recycle } from 'tabler-icons-react';
import { ProposalResidueType } from '../../../models/core/proposals.type';
import theme from '../../../theme';
import ProposalResidueListModal from './ProposalResidueListModal';

type FormViewProps = {
  label: string;
  referenceData: {
    idProposta: number;
  };
  formItem: any;
  idPropostaResiduo: number | null;
  propostaResiduo: string | null;
  idsToBeDisabled: number[];
  disabled: boolean;
  callback(item: ProposalResidueType | null): void;
};

export default function ProposalResidueList(props: FormViewProps) {
  const [opened, setOpened] = useState(false);

  const confirmActionResult = (item: ProposalResidueType | null, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    setOpened(false);
    props.callback(item);
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Proposta - Resíduos"
        size="70%"
      >
        <ProposalResidueListModal
          referenceData={props.referenceData}
          idsToBeDisabled={props.idsToBeDisabled}
          callback={confirmActionResult}
        />
      </Modal>

      <TextInput
        icon={<Recycle size={15} />}
        rightSection={
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            disabled={props.disabled}
            onClick={() => {
              setOpened(true);
            }}
          >
            <Click size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        }
        onClick={() => {
          setOpened(true);
        }}
        label={props.label}
        placeholder="Selecione..."
        {...props.formItem}
        readOnly
        required
        disabled={props.disabled}
      />
    </div>
  );
}
