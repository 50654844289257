/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Alert, Button, Group, Paper, Select, Space, Stack, Text } from '@mantine/core';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { CircleX, DeviceFloppy, Schema, ZoomMoney } from 'tabler-icons-react';
import {
  Fonte,
  ReferenciaCodigo,
  obterMapeamentoReferencia2,
} from '../../../../../../business/events/general';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useDynamicRefs from '../../../../../../hooks/useDynamicRefs';
import {
  EventSharedResidueRequestType,
  EventSharedResidueType,
} from '../../../../../../models/core/events.type';
import { ProposalResidueType } from '../../../../../../models/core/proposals.type';
import { newGuid } from '../../../../../../utils/helper.utils';
import { PlanSelectItem } from '../PlanQuotationSelectItem/PlanQuotationSelectItem';

type SharedResidueViewProps = {
  sharedResidues: EventSharedResidueType;
  callback(data: EventSharedResidueRequestType[]): void;
};
export default function SharedResidueView(props: SharedResidueViewProps) {
  const [data, setData] = useState<ProposalResidueType[]>([]);
  const [selectedData, setSelectedData] = useState<ProposalResidueType[]>([]);
  const [quotations, setQuotations] = useState<Map<string, any>>(new Map());
  const [pendingResidues, setPendingResidues] = useState<string[]>([]);
  const [getRef, setRef] = useDynamicRefs();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProposalResidueType[]) => {
      setSelectedData(selectedRows);
    },
    getCheckboxProps: (record: ProposalResidueType) => ({
      name: record.idPropostaResiduo?.toString(),
    }),
  };

  const getSelectData = (row: ProposalResidueType, newIdPlano: string | undefined) => {
    const planos = obterMapeamentoReferencia2(
      Fonte.Contrato,
      Number(row.idPropostaResiduo),
      ReferenciaCodigo.ContratoResiduoPlano,
      row,
      false
    );
    let idPlano: any = newIdPlano;
    if (!idPlano) {
      idPlano =
        row.planos.length === 1
          ? row.planos[0].idPropostaResiduoPlano
          : row.planos.find((x) => x.primario)?.idPropostaResiduoPlano;
    }

    let cotacoes;
    let idCotacao: any;
    if (idPlano) {
      const plano = planos.find((x) => Number(x.value) === Number(idPlano));

      cotacoes = plano?.extras.map((x: any) => {
        return {
          label: x.cotacao, // x.itens.map((xx: any) => xx.fornecedor)?.join(' | '),
          value: `#${x.idPropostaResiduoPlanoCotacao}`,
          group: `Cotação ${x.primary ? 'Primária' : 'Alternativa'} ${x.cotacao}`,
          primary: x.primary.toString(),
          extras: [{ ...x, hidePrimaryHeader: true, selected: false }],
        };
      });
      idCotacao = cotacoes.length === 1 ? cotacoes[0].value : cotacoes.find((x: any) => x.primary)?.value;

      const c = cotacoes.find((x: any) => x.value === idCotacao);
      if (c) {
        c.extras[0].selected = true;
      }
    }

    return {
      planos,
      idPlano: idPlano?.toString() ?? undefined,
      cotacoes,
      idCotacao: idCotacao?.toString() ?? undefined,
    };
  };

  useEffect(() => {
    const newQuotations = new Map();
    const { proposta, planos } = props.sharedResidues;

    const residuos =
      proposta?.residuos?.filter((x) =>
        planos.map((y) => y.idPropostaResiduo).includes(Number(x.idPropostaResiduo))
      ) ?? [];

    for (const residuo of residuos) {
      residuo.planos = residuo.planos.filter((x) =>
        planos.map((y) => y.idPropostaResiduoPlano).includes(Number(x.idPropostaResiduoPlano))
      );

      const { cotacoes } = getSelectData(residuo, undefined);
      if (cotacoes) {
        newQuotations.set(`c_${residuo.idPropostaResiduo}`, cotacoes);
      }
    }
    setData(residuos);
    setQuotations(newQuotations);
  }, []);

  const handleSubmit = () => {
    const invalidResidues =
      selectedData
        .filter((x) => !getRef(`c_${x.idPropostaResiduo}`).current.value)
        .map((y) => y.residuoCliente) ?? [];

    if (invalidResidues.length > 0) {
      setPendingResidues(invalidResidues);
      return;
    }
    setPendingResidues([]);

    const result: EventSharedResidueRequestType[] = [];

    for (const row of selectedData) {
      const idPropostaResiduoPlanoCotacao = Number(
        getRef(`c_${row.idPropostaResiduo}`).current.value.replace('#', '')
      );

      result.push({
        idPropostaResiduo: row.idPropostaResiduo!,
        idPropostaResiduoPlano: row.planos.find((x) =>
          x.cotacoes.find((y) => Number(y.idPropostaResiduoPlanoCotacao) === idPropostaResiduoPlanoCotacao)
        )?.idPropostaResiduoPlano!,
        idPropostaResiduoPlanoCotacao,
      });
    }

    props.callback(result);
  };

  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        {pendingResidues.length > 0 && (
          <>
            <Alert
              icon={<CircleX size={18} />}
              title="O(s) resíduo(s) selecionado(s) abaixo não possui(em) um plano/cotação definido."
              color="red"
              withCloseButton
              onClose={() => setPendingResidues([])}
            >
              {pendingResidues.map((x) => (
                <div>- {x}</div>
              ))}
            </Alert>
            <Space h="xl" />
          </>
        )}

        <Table
          showSorterTooltip={false}
          size="small"
          dataSource={data}
          columns={[
            {
              title: 'Resíduo',
              key: 'residuo',
              sorter: (a: ProposalResidueType, b: ProposalResidueType) =>
                a.residuoCliente.localeCompare(b.residuoCliente),
              render: (row: ProposalResidueType) => {
                return (
                  <ProfileCardLink
                    id={row.idResiduo.toString()}
                    name={row.residuoCliente}
                    nameSize="sm"
                    description={`Planos: ${row.codigoIBAMA || '-'} | Perigoso: ${
                      row.residuoClientePerigoso ? 'Sim' : 'Não'
                    }`}
                    descriptionSize="xs"
                    linkPrefix="residues"
                    showLink={false}
                  />
                );
              },
            },
            {
              title: 'Plano',
              key: 'plano',
              render: (row: ProposalResidueType) => {
                const { planos, idPlano } = getSelectData(row, undefined);

                return (
                  <Select
                    key={`p_${row.idPropostaResiduo}`}
                    ref={setRef(`p_${row.idPropostaResiduo}`)}
                    icon={<Schema size={15} />}
                    itemComponent={PlanSelectItem}
                    placeholder="Selecione..."
                    data={planos}
                    defaultValue={idPlano}
                    onChange={(value) => {
                      const { cotacoes, idCotacao } = getSelectData(row, value ?? undefined);

                      const newQuotations = new Map(quotations);
                      newQuotations.set(`c_${row.idPropostaResiduo}`, cotacoes);
                      setQuotations(newQuotations);

                      const selectCotacao = getRef(`c_${row.idPropostaResiduo}`).current;
                      selectCotacao.value = idCotacao;
                    }}
                  />
                );
              },
            },
            {
              title: 'Cotação',
              key: 'cotacao',
              render: (row: ProposalResidueType) => {
                const { cotacoes } = getSelectData(row, undefined);
                const newData = quotations?.get(`c_${row.idPropostaResiduo}`) ?? cotacoes ?? [];
                const cotacao: any = newData?.find((x: any) => x.extras.filter((y: any) => y.selected));
                const idCotacao = cotacao?.value;

                return (
                  <Group>
                    <Select
                      key={newGuid()}
                      ref={setRef(`c_${row.idPropostaResiduo}`)}
                      icon={<ZoomMoney size={15} />}
                      itemComponent={PlanSelectItem}
                      placeholder="Selecione..."
                      data={newData}
                      defaultValue={idCotacao}
                    />
                    <Stack spacing={0.3}>
                      {cotacao?.extras?.[0]?.itens?.map((item: any) => {
                        return (
                          <Group spacing="sm" noWrap>
                            <item.icone size={10} />
                            <Text size="xs">{item.fornecedor}</Text>
                          </Group>
                        );
                      })}
                    </Stack>
                  </Group>
                );
              },
            },
          ]}
          rowSelection={{ type: 'checkbox', ...rowSelection }}
          rowKey={(item: ProposalResidueType) => item.idPropostaResiduo?.toString() ?? ''}
          pagination={{
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button leftIcon={<DeviceFloppy size={18} />} onClick={handleSubmit}>
          Salvar
        </Button>
      </Group>
    </div>
  );
}
