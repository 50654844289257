/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Grid, Group, Paper, Space, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Edit, Trash } from 'tabler-icons-react';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import { ProposalCommissionType } from '../../../../../../../models/core/proposals.type';
import theme from '../../../../../../../theme';
import { formatCurrency } from '../../../../../../../utils/formatter.utils';

type DataViewActionProps = {
  item: ProposalCommissionType;
  confirmActionResult(item: ProposalCommissionType | null, action: string, confirmed: boolean): void;
};

export default function ResidueFormViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: ProposalCommissionType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} essa comissão?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <Grid columns={4}>
            <Grid.Col span={2}>
              <PageViewProperty
                label="Entidade"
                text={props.item.nomeFantasia || props.item.razaoSocial || props.item.nome || '-'}
                size="sm"
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <PageViewProperty
                label="Valor"
                text={item.porcentagem ? `${item.porcentagem}%` : formatCurrency(item.preco!)}
                size="sm"
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <PageViewProperty label="Ocorrências" text={item.ocorrencia || '-'} size="sm" />
            </Grid.Col>
          </Grid>
          <Space h="xs" />
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
        <ActionIcon
          size="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.accent?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'editar', true);
          }}
        >
          <Edit size={15} color={theme?.colors?.accent?.[6]} />
        </ActionIcon>
      </Tooltip>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
