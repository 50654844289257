/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Edit, Trash } from 'tabler-icons-react';
import { ActionIcon, Group, Paper, SimpleGrid, Space, Tooltip } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { EntityPaymentMethodType } from '../../../../../../../models/core/entities.type';
import theme from '../../../../../../../theme';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';

type DataViewActionProps = {
  item: EntityPaymentMethodType;
  confirmActionResult(item: EntityPaymentMethodType | null, action: string, confirmed: boolean): void;
};

export default function PaymentMethodFormViewActions(props: DataViewActionProps) {
  const modals = useModals();

  const showModal = (item: EntityPaymentMethodType, action: string) =>
    modals.openConfirmModal({
      title: `Gostaria de ${action} essa forma de pagamento?`,
      size: 'xl',
      closeOnClickOutside: false,
      closeOnEscape: false,
      children: (
        <Paper shadow="xs" p="md" withBorder>
          <SimpleGrid cols={4}>
            <PageViewProperty label="Forma" text={item.formaPagamento} size="sm" />
            <PageViewProperty
              label="Prazo (dias)"
              text={item.prazoPagamentoDias?.toString() || '-'}
              size="sm"
            />
            <PageViewProperty label="CNPJ/CPF" text={item.cnpj || item.cpf || '-'} size="sm" />
            <PageViewProperty label="Beneficiário" text={item.nome || '-'} size="sm" />
          </SimpleGrid>
          <Space h="xs" />
          <SimpleGrid cols={1}>
            <PageViewProperty
              label="Dados do Pagamento"
              text={
                item.codigoFormaPagamento === 'PX'
                  ? `Chave PIX: ${item.chavePix}`
                  : item.codigoFormaPagamento === 'TB'
                  ? `Banco: ${item.banco} | Agência: ${item.agencia} | Conta corrente: ${item.contaCorrente}${
                      !item.digitoVerificador ? '' : `-${item.digitoVerificador}`
                    }`
                  : '-'
              }
              size="sm"
            />
          </SimpleGrid>
        </Paper>
      ),
      onCancel: () => {
        props.confirmActionResult(item, action, false);
      },
      onConfirm: () => {
        props.confirmActionResult(item, action, true);
      },
    });

  return (
    <Group>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Editar">
        <ActionIcon
          size="sm"
          color="primary"
          style={{ borderColor: theme?.colors?.accent?.[6] }}
          variant="outline"
          onClick={() => {
            props.confirmActionResult(props.item, 'editar', true);
          }}
        >
          <Edit size={15} color={theme?.colors?.accent?.[6]} />
        </ActionIcon>
      </Tooltip>
      <Tooltip withArrow transition="fade" transitionDuration={200} label="Excluir">
        <ActionIcon
          size="sm"
          radius="sm"
          color="primary"
          style={{ borderColor: 'red' }}
          variant="outline"
          onClick={() => {
            showModal(props.item, 'excluir');
          }}
        >
          <Trash size={15} color="red" />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}
