/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Grid, Paper, Select, SimpleGrid, Space, Switch, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Backhoe, Building, Calendar, Notes, Package, Schema, User, Users } from 'tabler-icons-react';
import {
  Audiencia,
  AudienciaCodigo,
  Fonte,
  Referencia,
  ReferenciaCodigo,
  Status,
  Tipo,
  TipoCodigo,
  obterMapeamentoReferencia2,
} from '../../../../../business/events/general';
import ContractSearch from '../../../../../components/core/ContractSearch/ContractSearch';
import EntitySearch from '../../../../../components/core/EntitySearch/EntitySearch';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import ProposalResidueList from '../../../../../components/core/ProposalResidueList/ProposalResidueList';
import ProposalSearch from '../../../../../components/core/ProposalSearch/ProposalSearch';
import ProposalServiceList from '../../../../../components/core/ProposalServiceList/ProposalServiceList';
import { useEventGeneralContext } from '../../../../../contexts/core/events/EventGeneral.context';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import {
  CompanyType,
  ContractStatusType,
  EntityTypeType,
  ProposalStatusType,
} from '../../../../../models/core/cache.type';
import { ContractSearchResponseType } from '../../../../../models/core/contracts.type';
import { Action } from '../../../../../models/core/core.type';
import { EntitySearchResponseType } from '../../../../../models/core/entities.type';
import { EventType } from '../../../../../models/core/events.type';
import {
  ProposalResidueType,
  ProposalSearchResponseType,
  ProposalServiceType,
} from '../../../../../models/core/proposals.type';
import { UserType } from '../../../../../models/core/users.type';
import { Feature, SessionStorageKey } from '../../../../../utils/constants.utils';
import { formatDateStringToDate, formatDateToString } from '../../../../../utils/formatter.utils';
import { buildFakeAuditObject, tryGetDateISOFormat } from '../../../../../utils/helper.utils';
import { PlanSelectItem } from './PlanQuotationSelectItem/PlanQuotationSelectItem';

type FormViewData = {
  action: Action;
  idEvento: number | undefined;
  idEmpresa: string;
  idResponsavel: string | null;

  contratoData: ContractSearchResponseType | null;
  idContrato: number | null;

  entidadeData: EntitySearchResponseType | null;
  idEntidade: number | null;
  entidade: string;

  idProposta: number | null;

  codigoEventoAudiencia: string;
  codigoEventoTipo: string;
  fonte: string;

  codigoEventoReferencia: string;
  idEventoReferencia1: number | null;
  idEventoReferencia2: string;

  propostaResiduoData: ProposalResidueType | null;
  idPropostaResiduo: number | null;
  propostaResiduo: string;

  propostaServicoData: ProposalServiceType | null;
  idPropostaServico: number | null;
  propostaServico: string;

  data: Date | null;
  dataRetirada: Date | undefined;
  descricao: string;
  observacao: string | null;
  personalizado: boolean;
  automatico: boolean;

  tempCompartilhamento: boolean;
};

type EventFormViewProps = {
  referenceData: {
    companyData: CompanyType[];
    proposalStatusData: ProposalStatusType[];
    contractStatusData: ContractStatusType[];
    userData: UserType[];
    entityTypeData: EntityTypeType[];
  };
  event: EventType | null;
};

const audienciaData = [
  { label: Audiencia.Comercial, value: AudienciaCodigo.Comercial },
  { label: Audiencia.Financeiro, value: AudienciaCodigo.Financeiro },
  { label: Audiencia.Operacional, value: AudienciaCodigo.Operacional },
];

const GeneralFormView = forwardRef((props: EventFormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const { eventGeneralData, setEventGeneralData } = useEventGeneralContext();

  const tempEvento = JSON.parse(sessionStorage.getItem(SessionStorageKey.TempEvent) || 'null');
  const evento = tempEvento
    ? {
        ...tempEvento,
        idEvento: undefined,
        codigoEventoReferencia:
          tempEvento.codigoEventoTipo === TipoCodigo.Lembrete ? null : tempEvento.codigoEventoReferencia,
        idEventoReferencia1:
          tempEvento.codigoEventoTipo === TipoCodigo.Lembrete ? null : tempEvento.idEventoReferencia1,
        idEventoReferencia2: '-999', // set '-1' to import as 'Other'
        idEventoReferencia3: null,
        idResponsavel: currentUser.idUsuario,
        automatico: false,
        // relacoes: [
        //   {
        //     action: Action.Add,
        //     idEvento: null,
        //     idEventoReferenciado: tempEvento.idEvento,
        //     codigoEventoRelacao: EventRelationEnum.Importado,
        //   },
        // ],
      }
    : props.event;
  const statusSoftDisabled = evento
    ? evento.codigoEventoStatus === Status.PendenteFaturamento ||
      evento.codigoEventoStatus === Status.PendenteMedicao
    : false;

  const buildContractDataFromItem = (item: EventType | null): ContractSearchResponseType | null => {
    if (!item || !item.idContrato) {
      return null;
    }
    return JSON.parse(JSON.stringify(item.referencia?.contrato || null)) as ContractSearchResponseType;
  };

  const buildClienteDataFromItem = (item: EventType | null): EntitySearchResponseType | null => {
    if (!item || !item.idEntidade) {
      return null;
    }
    return {
      ...buildFakeAuditObject(),
      idEntidade: Number(item.idEntidade),
      tipos: [],
      cnpj: item.CNPJ,
      razaoSocial: item.razaoSocial,
      nomeFantasia: item.nomeFantasia,
      incricaoEstadual: null,
      incricaoMunicipal: null,
      cpf: item.CPF,
      nome: item.nome,
      webSite: null,
      aceitaEncontroContas: false,
    };
  };

  const form = useForm<FormViewData>({
    initialValues: {
      action: evento?.idEvento ? Action.Nothing : Action.Add,
      idEvento: evento?.idEvento || undefined,
      idEmpresa: evento?.idEmpresa?.toString() || currentUser.idEmpresa.toString() || '',
      idResponsavel: !evento ? currentUser.idUsuario : evento?.idResponsavel ?? '',

      contratoData: buildContractDataFromItem(evento),
      idContrato: evento?.idContrato || null,

      entidadeData: buildClienteDataFromItem(evento),
      idEntidade: evento?.idEntidade || null,
      entidade: evento?.nomeFantasia || evento?.razaoSocial || evento?.nome || '',

      idProposta: evento?.idProposta || null,

      codigoEventoAudiencia: evento?.codigoEventoAudiencia?.toString() || '',
      codigoEventoTipo: evento?.codigoEventoTipo?.toString() || '',
      fonte: evento?.idContrato
        ? Fonte.Contrato
        : evento?.idEntidade
        ? Fonte.Entidade
        : evento?.idProposta
        ? Fonte.Proposta
        : '',

      codigoEventoReferencia: evento?.codigoEventoReferencia || '',
      idEventoReferencia1: evento?.idEventoReferencia1 || null,
      idEventoReferencia2: evento?.idEventoReferencia2
        ? evento?.idEventoReferencia2.toString()
        : evento
        ? '-1'
        : '',

      propostaResiduoData: evento?.referencia?.contrato?.residuo || null,
      idPropostaResiduo: evento?.referencia?.contrato?.residuo?.idPropostaResiduo || null,
      propostaResiduo: evento?.referencia?.contrato?.residuo?.residuoCliente || '',

      propostaServicoData: evento?.referencia?.contrato?.servico || null,
      idPropostaServico: evento?.referencia?.contrato?.servico?.idPropostaServico || null,
      propostaServico: evento?.referencia?.contrato?.servico?.servico || '',

      data: evento?.data ? formatDateStringToDate(tryGetDateISOFormat(evento?.data)) : null,
      dataRetirada: undefined, // now().toDate(),
      descricao: evento?.descricao || '-',
      observacao: evento?.observacao || '',
      personalizado: false,
      automatico: evento?.automatico || false,
      tempCompartilhamento: false,
    },
    validate: {
      idEmpresa: (value) => {
        return props.referenceData.companyData.find((x) => x.idEmpresa === Number(value))
          ? null
          : 'Campo obrigatório';
      },
      codigoEventoAudiencia: (value) => (value !== '' ? null : 'Campo obrigatório'),
      codigoEventoTipo: (value) => {
        if (value === '') {
          return 'Campo obrigatório';
        }
        const item = obterMapeamentoAudienciaTipo().find((x) => x.value === value);
        if (!item || (item.disabled && !props.event)) {
          return 'Valor selecionado inválido';
        }
        return null;
      },
      fonte: (value, values: FormViewData) => {
        if (
          values.codigoEventoTipo &&
          values.codigoEventoTipo !== TipoCodigo.Lembrete &&
          (!value || value === '')
        ) {
          return 'Campo obrigatório';
        }
        if (value) {
          if (obterMapeamentoFonte().findIndex((x) => x.value === value) === -1) {
            return 'Valor selecionado inválido';
          }
        }
        return null;
      },
      codigoEventoReferencia: (value, values: FormViewData) => {
        if (
          values.codigoEventoTipo &&
          values.fonte === Fonte.Contrato &&
          (values.codigoEventoTipo === TipoCodigo.Coleta ||
            values.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
            values.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar)
        ) {
          if (value === '' || !value) {
            return 'Campo obrigatório';
          }
          if (obterMapeamentoReferencia().findIndex((x) => x.value === value) === -1) {
            return 'Valor selecionado inválido';
          }
        }
        return null;
      },
      idEventoReferencia2: (value: string, values: FormViewData) => {
        if (
          values.fonte === Fonte.Contrato &&
          (values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
            values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
            values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano)
        ) {
          if (value === '' || !value) {
            return 'Campo obrigatório';
          }
          if (obterMapeamentoReferencia2Interno().findIndex((x) => x.value === value) === -1) {
            return 'Valor selecionado inválido';
          }
        }
        return null;
      },
      propostaResiduo: (value, values: FormViewData) => {
        if (
          (values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
            values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
            values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) &&
          value.trim() === ''
        ) {
          return 'Campo obrigatório';
        }
        return null;
      },
      propostaServico: (value, values: FormViewData) => {
        if (values.codigoEventoReferencia === ReferenciaCodigo.ContratoServico && value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      idContrato: (value, values: FormViewData) => {
        if (values.fonte === Fonte.Contrato && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      entidade: (value, values: FormViewData) => {
        if (values.fonte === Fonte.Entidade && value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      idProposta: (value, values: FormViewData) => {
        if (values.fonte === Fonte.Proposta && !value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      data: (value) => {
        if (!value) {
          return 'Campo obrigatório';
        }

        if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
          return 'Data não suportada';
        }
        return null;
      },
      dataRetirada: (value, values: FormViewData) => {
        if (form.values.codigoEventoTipo !== TipoCodigo.MobilizacaoEnviar || props.event) {
          return null;
        }
        if (!value) {
          return 'Campo obrigatório';
        }

        if (value && values.data) {
          if (Math.abs(value.getFullYear() - new Date().getFullYear()) > 200) {
            return 'Data não suportada';
          }
          if (value < values.data) {
            return 'Data de retirada não pode ser anterior à data de envio';
          }
        }

        return null;
      },
      descricao: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const obterMapeamentoAudienciaTipo = () => {
    if (form.values.codigoEventoAudiencia === AudienciaCodigo.Comercial) {
      return [
        { label: Tipo.Expiracao, value: TipoCodigo.Expiracao, disabled: true },
        { label: Tipo.Lembrete, value: TipoCodigo.Lembrete },
      ];
    }
    if (form.values.codigoEventoAudiencia === AudienciaCodigo.Financeiro) {
      return [
        { label: Tipo.Cobranca, value: TipoCodigo.Cobranca, disabled: true },
        { label: Tipo.Coleta, value: TipoCodigo.Coleta, disabled: true },
        { label: Tipo.Comissao, value: TipoCodigo.Comissao, disabled: true },
        { label: Tipo.Fechamento, value: TipoCodigo.Fechamento },
        { label: Tipo.Lembrete, value: TipoCodigo.Lembrete },
        { label: Tipo.MobilizacaoEnviar, value: TipoCodigo.MobilizacaoEnviar, disabled: true },
        { label: Tipo.MobilizacaoRetirar, value: TipoCodigo.MobilizacaoRetirar, disabled: true },
      ];
    }

    return [
      { label: Tipo.Coleta, value: TipoCodigo.Coleta },
      { label: Tipo.Documetacao, value: TipoCodigo.Documetacao },
      { label: Tipo.Expiracao, value: TipoCodigo.Expiracao, disabled: true },
      { label: Tipo.Lembrete, value: TipoCodigo.Lembrete },
      { label: Tipo.MobilizacaoEnviar, value: TipoCodigo.MobilizacaoEnviar },
      { label: Tipo.MobilizacaoRetirar, value: TipoCodigo.MobilizacaoRetirar },
    ];
  };

  const obterMapeamentoFonte = () => {
    if (
      form.values.codigoEventoTipo &&
      form.values.codigoEventoTipo !== TipoCodigo.Expiracao &&
      form.values.codigoEventoTipo !== TipoCodigo.Lembrete
    ) {
      return [{ label: Fonte.Contrato, value: Fonte.Contrato }];
    }
    return [
      { label: Fonte.Contrato, value: Fonte.Contrato },
      { label: Fonte.Entidade, value: Fonte.Entidade },
      { label: Fonte.Proposta, value: Fonte.Proposta },
    ];
  };

  const obterMapeamentoReferencia = () => {
    if (form.values.codigoEventoTipo && form.values.codigoEventoTipo === TipoCodigo.Coleta) {
      return [
        {
          label: Referencia.ContratoResiduoPlano,
          value: ReferenciaCodigo.ContratoResiduoPlano,
          group: 'Resíduo',
        },
      ];
    }

    if (
      form.values.codigoEventoTipo &&
      (form.values.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
        form.values.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar)
    ) {
      return [
        {
          label: Referencia.ContratoResiduoAcondicionamento,
          value: ReferenciaCodigo.ContratoResiduoAcondicionamento,
          group: 'Resíduo',
        },
        {
          label: Referencia.ContratoResiduoEquipamento,
          value: ReferenciaCodigo.ContratoResiduoEquipamento,
          group: 'Resíduo',
        },
        {
          label: Referencia.ContratoServico,
          value: ReferenciaCodigo.ContratoServico,
          group: 'Serviço',
        },
      ];
    }

    return [];
  };

  const obterMapeamentoReferencia2Interno = () => {
    return obterMapeamentoReferencia2(
      form.values.fonte as any,
      form.values.idPropostaResiduo,
      form.values.codigoEventoReferencia as any,
      form.values.propostaResiduoData,
      true
    );
  };

  const isModified = (): boolean => {
    const origData = `${evento?.idEmpresa || ''}
    |${evento?.idResponsavel || ''}
    |${evento?.idContrato || ''}
    |${evento?.idEntidade || ''}
    |${evento?.idProposta || ''}
    |${evento?.codigoEventoAudiencia || ''}
    |${evento?.codigoEventoTipo || ''}
    |${evento?.codigoEventoReferencia || ''}
    |${evento?.idEventoReferencia1 || ''}
    |${evento?.idEventoReferencia2 || ''}
    |${formatDateToString(evento?.data || new Date()) || ''}
    |${evento?.descricao || ''}
    |${evento?.observacao || ''}
    |${evento?.automatico || ''}`;

    const formData = `${form.values.idEmpresa || ''}
    |${form.values.idResponsavel || ''}
    |${form.values.idContrato || ''}
    |${form.values.idEntidade || ''}
    |${form.values.idProposta || ''}
    |${form.values.codigoEventoAudiencia || ''}
    |${form.values.codigoEventoTipo || ''}
    |${form.values.codigoEventoReferencia || ''}
    |${form.values.idEventoReferencia1 || ''}
    |${form.values.idEventoReferencia2 === '-1' ? '' : form.values.idEventoReferencia2 || ''}
    |${formatDateToString(form.values.data || new Date())}
    |${form.values.descricao || ''}
    |${form.values.observacao || ''}
    |${form.values.automatico || ''}`;

    return origData !== formData;
  };

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      if (form.validate().hasErrors) {
        throw Error('Existem pendências a serem corrigidas.');
      }
      const formItem = JSON.parse(JSON.stringify(form.values)) as FormViewData;
      if (evento?.idEvento && isModified()) {
        formItem.action = Action.Modify;
      }

      (formItem as any).idEmpresa = Number(formItem.idEmpresa);

      (formItem as any).eventoAudiencia = audienciaData.find(
        (x) => x.value === formItem.codigoEventoAudiencia
      )?.label;
      (formItem as any).eventoTipo = obterMapeamentoAudienciaTipo().find(
        (x) => x.value === formItem.codigoEventoTipo
      )?.label;

      if (formItem.fonte === Fonte.Contrato) {
        formItem.idEntidade = null;
        formItem.idProposta = null;
      } else if (formItem.fonte === Fonte.Entidade) {
        formItem.idContrato = null;
        formItem.idProposta = null;
      } else if (formItem.fonte === Fonte.Proposta) {
        formItem.idContrato = null;
        formItem.idEntidade = null;
      } else {
        formItem.idContrato = null;
        formItem.idEntidade = null;
        formItem.idProposta = null;
      }

      if (
        formItem.codigoEventoReferencia !== ReferenciaCodigo.ContratoResiduoAcondicionamento &&
        formItem.codigoEventoReferencia !== ReferenciaCodigo.ContratoResiduoEquipamento &&
        formItem.codigoEventoReferencia !== ReferenciaCodigo.ContratoResiduoPlano
      ) {
        formItem.propostaResiduoData = null;
        formItem.propostaResiduo = '';
        formItem.idPropostaResiduo = null;
      }

      if (formItem.codigoEventoReferencia !== ReferenciaCodigo.ContratoServico) {
        formItem.propostaServicoData = null;
        formItem.propostaServico = '';
        formItem.idPropostaServico = null;
      }

      if (formItem.fonte !== Fonte.Contrato) {
        (formItem as any).idEventoReferencia2 = null;
      }

      (formItem as any).codigoEventoReferencia = formItem.codigoEventoReferencia?.trim() || null;
      // (formItem as any).idEventoReferencia1 = Number(formItem.idEventoReferencia1);
      (formItem as any).idEventoReferencia2 =
        formItem.idEventoReferencia2 === '-1' || !formItem.idEventoReferencia2
          ? null
          : Number(formItem.idEventoReferencia2);

      if (formItem.idResponsavel === '') {
        formItem.idResponsavel = null;
        (formItem as any).responsavel = null;
      } else {
        (formItem as any).responsavel = props.referenceData.userData.find(
          (x) => x.idUsuario === formItem.idResponsavel
        )?.nomeCompleto;
      }

      if (props.event || formItem.codigoEventoTipo !== TipoCodigo.MobilizacaoEnviar) {
        formItem.dataRetirada = undefined;
      }

      formItem.personalizado = eventGeneralData.eventoReferenciaPersonalizado;
      formItem.observacao = formItem.observacao?.trim() || null;

      formItem.tempCompartilhamento =
        formItem.codigoEventoTipo === TipoCodigo.Coleta &&
        !!formItem.propostaResiduoData?.planos
          .find((x) => Number(x.idPropostaResiduoPlano) === Number(formItem?.idEventoReferencia2))
          ?.acondicionamentos?.find((y) => y.compartilhado);

      delete (formItem as any).contratoData;
      delete (formItem as any).entidadeData;
      delete (formItem as any).entidade;
      delete (formItem as any).propostaResiduoData;
      delete (formItem as any).propostaServicoData;

      return formItem;
    },
    clear() {
      form.reset();
    },
  }));

  const limparResiduo = () => {
    form.values.propostaResiduoData = null;
    form.values.propostaResiduo = '';
    form.values.idPropostaResiduo = null;
  };

  const limparServico = () => {
    form.values.propostaServicoData = null;
    form.values.propostaServico = '';
    form.values.idPropostaServico = null;
  };

  const limparFontes = () => {
    form.values.entidadeData = null;
    form.values.entidade = '';
    form.values.idEntidade = null;

    form.values.idProposta = null;

    form.values.contratoData = null;
    form.values.idContrato = null;
  };

  useEffect(() => {
    const eventoReferencia =
      (obterMapeamentoReferencia2Interno().find((x) => x.value === form.values.idEventoReferencia2) as any) ||
      null;

    let eventoReferenciaPersonalizado = false;
    if (
      form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
      form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
      form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano
    ) {
      eventoReferenciaPersonalizado = eventoReferencia?.value === '-1';
    } else if (form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoServico) {
      if (evento) {
        eventoReferenciaPersonalizado = !evento.idEventoReferencia3;
      } else {
        eventoReferenciaPersonalizado = eventGeneralData.eventoReferenciaPersonalizado;
      }
    }

    setEventGeneralData({
      codigoEventoTipo: (form.values.codigoEventoTipo as TipoCodigo) || null,
      eventoTipo:
        obterMapeamentoAudienciaTipo().find((x) => x.value === form.values.codigoEventoTipo)?.label || null,
      fonte: (form.values.fonte as Fonte) || null,
      codigoEventoReferencia: (form.values.codigoEventoReferencia as ReferenciaCodigo) || null,
      idEventoReferencia1: form.values.idEventoReferencia1,
      idEventoReferencia2: eventoReferencia?.value === '-1' ? null : Number(eventoReferencia?.value),
      eventoReferenciaPersonalizado,
      propostaResiduoData: form.values.propostaResiduoData,
      propostaServicoData: form.values.propostaServicoData,
    });
  }, [form.values, setEventGeneralData]);

  return (
    <Paper shadow="xs" p="md" withBorder>
      <PageSection
        size="lg"
        color={Feature.Home.Event.color}
        label="Geral"
        text="Informações gerais do evento como audiência, data, descrição, etc."
      />
      <Space h="xs" />

      <Grid columns={5}>
        <Grid.Col span={1}>
          <Select
            icon={<Building size={15} />}
            label="Empresa"
            placeholder="Selecione..."
            data={props.referenceData.companyData.map((x) => {
              return {
                value: x.idEmpresa.toString(),
                label: x.empresa,
                disabled: x.idEmpresa === 0,
              };
            })}
            searchable
            required
            disabled={!currentUser.executivo || evento?.automatico}
            {...form.getInputProps('idEmpresa')}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Select
            icon={<Users size={15} />}
            label="Audiência"
            placeholder="Selecione..."
            data={audienciaData}
            clearable={false}
            searchable
            required
            onSelect={() => {
              form.values.codigoEventoTipo = '';
              form.values.fonte = '';
              form.values.codigoEventoReferencia = '';
              form.values.idEventoReferencia1 = null;
              form.values.idEventoReferencia2 = '';
              limparResiduo();
              limparServico();
            }}
            disabled={evento?.automatico || props?.event?.idContrato}
            {...form.getInputProps('codigoEventoAudiencia')}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Select
            label="Tipo"
            placeholder="Selecione..."
            data={obterMapeamentoAudienciaTipo()}
            searchable
            disabled={!form.values.codigoEventoAudiencia || evento?.automatico || props?.event?.idContrato}
            required
            onSelect={() => {
              form.values.fonte = '';
              form.values.codigoEventoReferencia = '';
              form.values.idEventoReferencia1 = null;
              form.values.idEventoReferencia2 = '';
              limparResiduo();
              limparServico();
            }}
            {...form.getInputProps('codigoEventoTipo')}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Select
            label="Fonte"
            placeholder="Selecione..."
            data={obterMapeamentoFonte()}
            clearable
            onSelect={() => {
              form.values.codigoEventoReferencia = '';
              form.values.idEventoReferencia1 = null;
              form.values.idEventoReferencia2 = '';
              limparFontes();
            }}
            required={!!form.values.codigoEventoTipo && form.values.codigoEventoTipo !== TipoCodigo.Lembrete}
            disabled={!form.values.codigoEventoTipo || evento?.automatico || props?.event?.idContrato}
            {...form.getInputProps('fonte')}
          />
        </Grid.Col>
        {form.values.fonte === Fonte.Contrato && (
          <Grid.Col span={1}>
            <ContractSearch
              label="Contrato"
              referenceData={props.referenceData}
              formItem={form.getInputProps('idContrato')}
              idsToBeDisabled={[]}
              disabled={evento?.automatico || props?.event?.idContrato}
              callback={(item: ContractSearchResponseType | null) => {
                if (item) {
                  const currentId = form.values.idContrato;
                  form.setFieldValue('contratoData', item);
                  form.setFieldValue('idContrato', item.idContrato);
                  if (currentId !== item.idContrato) {
                    form.setFieldValue('codigoEventoReferencia', '');
                  }
                }
              }}
            />
          </Grid.Col>
        )}
        {form.values.fonte === Fonte.Entidade && (
          <Grid.Col span={1}>
            <EntitySearch
              label="Entidade"
              referenceData={props.referenceData}
              formItem={form.getInputProps('entidade')}
              idsToBeDisabled={[]}
              disabled={evento?.automatico || false}
              required
              callback={(item: EntitySearchResponseType | null) => {
                if (item) {
                  form.setFieldValue('entidadeData', item);
                  form.setFieldValue('idEntidade', item.idEntidade);
                  form.setFieldValue('entidade', item.nomeFantasia || item.razaoSocial || item.nome || '');
                }
              }}
            />
          </Grid.Col>
        )}
        {form.values.fonte === Fonte.Proposta && (
          <Grid.Col span={1}>
            <ProposalSearch
              label="Proposta"
              referenceData={props.referenceData}
              formItem={form.getInputProps('idProposta')}
              idsToBeDisabled={[]}
              disabled={evento?.automatico || false}
              callback={(item: ProposalSearchResponseType | null) => {
                if (item) {
                  form.setFieldValue('idProposta', item.idProposta);
                }
              }}
            />
          </Grid.Col>
        )}
      </Grid>
      <Space h="xs" />

      <Grid columns={5}>
        {form.values.fonte === Fonte.Contrato &&
          (form.values.codigoEventoTipo === TipoCodigo.Coleta ||
            form.values.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar ||
            form.values.codigoEventoTipo === TipoCodigo.MobilizacaoRetirar) && (
            <Grid.Col span={1}>
              <Select
                label="Referência"
                placeholder="Selecione..."
                data={obterMapeamentoReferencia()}
                clearable
                required
                disabled={!form.values.idContrato || evento?.automatico || props.event}
                onSelect={() => {
                  form.values.idEventoReferencia1 = null;
                  form.values.idEventoReferencia2 = '';
                  limparResiduo();
                  limparServico();
                }}
                {...form.getInputProps('codigoEventoReferencia')}
              />
            </Grid.Col>
          )}
        {form.values.fonte === Fonte.Contrato &&
          (form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
            form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
            form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) && (
            <Grid.Col span={2}>
              <ProposalResidueList
                label="Resíduo"
                referenceData={{
                  idProposta:
                    form.values.contratoData?.idProposta ||
                    (form.values.contratoData as any)?.proposta?.idProposta ||
                    0,
                }}
                formItem={form.getInputProps('propostaResiduo')}
                idPropostaResiduo={form.values.idPropostaResiduo || 0}
                propostaResiduo={form.values.propostaResiduo}
                idsToBeDisabled={[]}
                disabled={evento?.automatico || !!props.event}
                callback={async (item: ProposalResidueType | null) => {
                  if (item) {
                    form.setFieldValue('propostaResiduoData', item);
                    form.setFieldValue('idPropostaResiduo', item.idPropostaResiduo!);
                    form.setFieldValue('propostaResiduo', `${item.residuoCliente || '-'}`);
                    form.setFieldValue('idEventoReferencia1', item.idPropostaResiduo!);
                    form.setFieldValue('idEventoReferencia2', '');
                  }
                }}
              />
            </Grid.Col>
          )}
        {form.values.fonte === Fonte.Contrato &&
          form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoServico && (
            <Grid.Col span={2}>
              <ProposalServiceList
                label="Serviço"
                tooltip={null}
                referenceData={{
                  idProposta:
                    form.values.contratoData?.idProposta ||
                    (form.values.contratoData as any)?.proposta?.idProposta ||
                    0,
                  servicos: [],
                }}
                formItem={form.getInputProps('propostaServico')}
                idPropostaServico={form.values.idPropostaServico || 0}
                propostaServico={form.values.propostaServico}
                idsToBeDisabled={[]}
                disabled={evento?.automatico || !!props.event}
                callback={async (item: ProposalServiceType | null) => {
                  if (item) {
                    form.setFieldValue('propostaServicoData', item);
                    form.setFieldValue('idPropostaServico', item.idPropostaServico!);
                    form.setFieldValue('propostaServico', `${item.servico || '-'}`);
                    form.setFieldValue('idEventoReferencia1', item.idPropostaServico!);
                  }
                }}
              />
            </Grid.Col>
          )}
        {form.values.fonte === Fonte.Contrato &&
          form.values.idPropostaResiduo &&
          (form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ||
            form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ||
            form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoPlano) && (
            <Grid.Col span={2}>
              <Select
                icon={
                  form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoAcondicionamento ? (
                    <Package size={15} />
                  ) : form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoResiduoEquipamento ? (
                    <Backhoe size={15} />
                  ) : (
                    <Schema size={15} />
                  )
                }
                itemComponent={PlanSelectItem}
                label="Plano"
                placeholder="Selecione..."
                data={obterMapeamentoReferencia2Interno()}
                clearable
                required
                disabled={!form.values.idContrato || evento?.automatico || props.event}
                {...form.getInputProps('idEventoReferencia2')}
              />
            </Grid.Col>
          )}
        {form.values.fonte === Fonte.Contrato &&
          form.values.idPropostaServico &&
          form.values.codigoEventoReferencia === ReferenciaCodigo.ContratoServico && (
            <Grid.Col span={1}>
              <div style={{ marginTop: 30 }}>
                <Switch
                  label="Personalizado?"
                  checked={eventGeneralData.eventoReferenciaPersonalizado}
                  onChange={(event) => {
                    setEventGeneralData({
                      ...eventGeneralData,
                      eventoReferenciaPersonalizado: event.currentTarget.checked,
                    });
                  }}
                  disabled={!form.values.idContrato || evento?.automatico || props.event}
                />
              </div>
            </Grid.Col>
          )}
      </Grid>
      <Space h="xs" />

      <Grid columns={5}>
        <Grid.Col span={1}>
          <Select
            icon={<User size={15} />}
            label="Responsável"
            placeholder="Selecione..."
            data={props.referenceData.userData.map((x) => {
              return {
                label: x.idUsuario === currentUser.idUsuario ? `${x.nomeCompleto} (eu)` : x.nomeCompleto,
                value: x.idUsuario,
                group: x.empresa,
              };
            })}
            clearable
            searchable
            {...form.getInputProps('idResponsavel')}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <DatePicker
            icon={<Calendar size={15} />}
            locale="pt-br"
            label="Data"
            placeholder="Selecione..."
            clearable={false}
            inputFormat="DD/MM/YYYY"
            allowFreeInput
            required
            {...form.getInputProps('data')}
            disabled={
              (evento?.automatico &&
                (evento?.codigoEventoTipo === TipoCodigo.Lembrete ||
                  evento?.codigoEventoTipo === TipoCodigo.Expiracao)) ||
              statusSoftDisabled
            }
          />
        </Grid.Col>
        {!props.event && form.values.codigoEventoTipo === TipoCodigo.MobilizacaoEnviar && (
          <Grid.Col span={1}>
            <DatePicker
              icon={<Calendar size={15} />}
              locale="pt-br"
              label="Data de Retirada"
              placeholder="Selecione..."
              clearable={false}
              inputFormat="DD/MM/YYYY"
              allowFreeInput
              required
              minDate={form.values.data ?? undefined}
              {...form.getInputProps('dataRetirada')}
            />
          </Grid.Col>
        )}
      </Grid>
      <Space h="xs" />

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Descrição"
          placeholder="Descrição do evento"
          autosize
          maxLength={500}
          required
          disabled={evento?.automatico || statusSoftDisabled}
          {...form.getInputProps('descricao')}
        />
      </SimpleGrid>
      <Space h="xs" />

      <SimpleGrid cols={1}>
        <Textarea
          icon={<Notes size={15} />}
          label="Observação"
          placeholder="Observação sobre a evento"
          autosize
          maxLength={500}
          {...form.getInputProps('observacao')}
        />
      </SimpleGrid>
    </Paper>
  );
});

export default GeneralFormView;
