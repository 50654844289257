/* eslint-disable react/destructuring-assignment */
import { Badge, Divider, Paper, SimpleGrid, Space } from '@mantine/core';
import PageSection from '../../../../../components/core/PageSection/PageSection';
import PageViewAudit from '../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { InvitationType } from '../../../../../models/core/invitations.type';
import { Feature } from '../../../../../utils/constants.utils';

type DataViewProps = {
  data: InvitationType;
};

export default function DataView(props: DataViewProps) {
  return (
    <>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Managerial.Invitation.color} label="Identificação" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Nome" text={props.data.nome} size="md" />
          <PageViewProperty label="E-mail" text={props.data.email} size="md" />
          <PageViewProperty label="Empresa" text={props.data.empresa} size="md" />
          <PageViewProperty label="Departamento" text={props.data.departamento} size="md" />
        </SimpleGrid>
        <Space h="xs" />
        <Divider my="sm" variant="dashed" />

        <PageSection size="lg" color={Feature.Managerial.Invitation.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <PageViewProperty label="Validade" text={`${props.data.validadeHoras} horas`} size="md" />
          <PageViewProperty
            label="Status do Convite"
            text={<Badge variant="outline">{props.data.usuarioConviteStatus}</Badge>}
            size="md"
          />
          {props.data.idCanceladoPor !== null && (
            <ProfileCardLink
              id={props.data?.idCanceladoPor || 'N/A'}
              name={props.data?.canceladoPor || 'N/A'}
              nameSize="md"
              description={`Cancelado às ${props.data?.dataCancelamento?.toString() || 'N/A'}`}
              descriptionSize="sm"
            />
          )}
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="md"
      />
    </>
  );
}
