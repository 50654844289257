/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { Group, Space } from '@mantine/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PageSection from '../../../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import TableCellEllipsis from '../../../../../../components/core/TableCellEllipsis/TableCellEllipsis';
import { EntityPackagingType } from '../../../../../../models/core/entities.type';
import { Feature } from '../../../../../../utils/constants.utils';
import { formatTimestampToString } from '../../../../../../utils/formatter.utils';

type ViewProps = {
  data: EntityPackagingType[];
};

export default function PackagingView(props: ViewProps) {
  const columns: ColumnsType<any> = [
    {
      title: 'Acondicionamento',
      key: 'residuoAcondicionamento',
      dataIndex: 'residuoAcondicionamento',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) =>
        a.residuoAcondicionamento.localeCompare(b.residuoAcondicionamento),
    },
    {
      title: 'Descrição',
      key: 'residuoAcondicionamentoDescricao',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) =>
        (a.residuoAcondicionamentoDescricao || '').localeCompare(b.residuoAcondicionamentoDescricao || ''),
      render: (row: EntityPackagingType) => (
        <TableCellEllipsis
          label={row?.residuoAcondicionamentoDescricao}
          numberOfChars={25}
          toolTipWidth={300}
        />
      ),
    },
    {
      title: 'Tipo',
      key: 'proprio',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) =>
        a.proprio === b.proprio ? 0 : a.proprio ? -1 : 1,
      render: (row: EntityPackagingType) => <div>{row?.proprio ? 'Próprio' : 'Terceirizado'}</div>,
    },
    {
      title: 'Criado Por',
      key: 'criadoPor',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) => a.criadoPor.localeCompare(b.criadoPor),
      render: (row: EntityPackagingType) => (
        <ProfileCardLink id={row.idCriadoPor} name={row.criadoPor} nameSize="sm" />
      ),
    },
    {
      title: 'Data Criação',
      key: 'dataCriacao',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) => {
        const aValue = a.dataCriacao.valueOf();
        const bValue = b.dataCriacao.valueOf();
        if (aValue === bValue) {
          return 0;
        }
        return aValue > bValue ? 1 : -1;
      },
      render: (row: EntityPackagingType) => formatTimestampToString(row.dataCriacao),
    },
    {
      title: 'Modificado Por',
      key: 'modificadoPor',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) =>
        (a.modificadoPor || '').localeCompare(b.modificadoPor || ''),
      render: (row: EntityPackagingType) => {
        if (row.idModificadoPor) {
          return (
            <ProfileCardLink id={row.idModificadoPor || ''} name={row.modificadoPor || ''} nameSize="sm" />
          );
        }
        return '-';
      },
    },
    {
      title: 'Data Modificação',
      key: 'dataModificacao',
      sorter: (a: EntityPackagingType, b: EntityPackagingType) => {
        const date = new Date();
        return new Date(a.dataModificacao || date).valueOf() - new Date(b.dataModificacao || date).valueOf();
      },
      render: (row: EntityPackagingType) => {
        if (row.dataModificacao) {
          return formatTimestampToString(row.dataModificacao);
        }
        return '-';
      },
    },
  ];

  return (
    <div>
      <Group>
        <PageSection size="lg" color={Feature.Reference.Entity.color} label="Acondicionamentos" text="" />
      </Group>
      <Space h="xs" />
      <Table
        showSorterTooltip={false}
        // size="small"
        dataSource={props.data}
        columns={columns}
        rowKey={(item: EntityPackagingType) => item.idEntidadeResiduoAcondicionamento || 0}
        pagination={{
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
        }}
      />
    </div>
  );
}
