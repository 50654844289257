/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { Column, ColumnConfig } from '@ant-design/plots';
import {
  Badge,
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { Table } from 'antd';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Building,
  Filter,
  Table as ITable,
  PlugConnectedX,
  Reload,
  SortDescendingNumbers,
  TableOff,
} from 'tabler-icons-react';
import PageSection from '../../../../components/core/PageSection/PageSection';
import ProfileCardLink from '../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { CompanyType } from '../../../../models/core/cache.type';
import { EntityGeneralTopFinanceResponseType, ReportItem } from '../../../../models/core/report.type';
import reportsService from '../../../../services/core/report.service';
import theme from '../../../../theme';
import cacheUtils from '../../../../utils/cache.utils';
import { Chart, Feature, SessionStorageKey } from '../../../../utils/constants.utils';
import { formatCurrency } from '../../../../utils/formatter.utils';
import { newGuid } from '../../../../utils/helper.utils';

type FormViewProps = {
  height: number | undefined;
};

type ExternalInputData = {
  filter: { periodo: Date[]; idEmpresa: string | null };
};

type FilterData = {
  periodo: Date[];
  idEmpresa: string | null;
  top: number;
  ordem: string;
};

type Response = {
  filterConfig: FilterData;
  data: EntityGeneralTopFinanceResponseType[];
  filteredData: EntityGeneralTopFinanceResponseType[];
  chartConfig: ColumnConfig;
};

type Result = {
  response: Response | null;
  loaded: boolean;
  error: string | null;
};

const componentConfig = {
  title: 'Entidades',
  description:
    'Entidades com suas receitas e despesas em um período de tempo baseado em seus eventos de fechamento.',
  cacheKey: `${SessionStorageKey.DashboardManagerial}-${ReportItem.EntidadeGeralTopFinanceiro}`,
  filterConfig: {
    periodo: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    top: 10,
    ordemData: [
      {
        value: 'receita',
        label: `Receita`,
      },
      {
        value: 'despesa',
        label: `Despesa`,
      },
    ],
    ordem: 'receita',
  },
  dataConfig: {
    exportable: true,
    rowKey: newGuid(),
    columns: [
      {
        title: 'Empresa',
        key: 'empresa',
        sorter: (a: EntityGeneralTopFinanceResponseType, b: EntityGeneralTopFinanceResponseType) =>
          a.empresa.localeCompare(b.empresa),
        render: (row: EntityGeneralTopFinanceResponseType) => <Badge variant="outline">{row.empresa}</Badge>,
      },
      {
        title: 'Entidade',
        key: 'idEntidade',
        sorter: (a: EntityGeneralTopFinanceResponseType, b: EntityGeneralTopFinanceResponseType) =>
          (a.nomeFantasia || a.razaoSocial || a.nome || '').localeCompare(
            b.nomeFantasia || b.razaoSocial || b.nome || ''
          ),
        render: (row: EntityGeneralTopFinanceResponseType) => {
          if (row.cnpj) {
            return (
              <ProfileCardLink
                id={row.idEntidade.toString()}
                name={row.nomeFantasia || row.razaoSocial || '-'}
                nameSize="sm"
                description={row.nomeFantasia ? row.razaoSocial : row.cnpj}
                descriptionSize="xs"
                linkPrefix="entities"
                showLink
              />
            );
          }
          return (
            <ProfileCardLink
              id={row.idEntidade.toString()}
              name={row.nome || '-'}
              nameSize="sm"
              description={row.cpf}
              descriptionSize="xs"
              linkPrefix="entities"
              showLink
            />
          );
        },
      },
      {
        title: 'Receita',
        key: 'receita',
        sorter: (a: EntityGeneralTopFinanceResponseType, b: EntityGeneralTopFinanceResponseType) => {
          const aValue = a.receita || 0;
          const bValue = b.receita || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: EntityGeneralTopFinanceResponseType) => <div>{formatCurrency(row.receita)}</div>,
      },
      {
        title: 'Despesa',
        key: 'despesa',
        sorter: (a: EntityGeneralTopFinanceResponseType, b: EntityGeneralTopFinanceResponseType) => {
          const aValue = a.despesa || 0;
          const bValue = b.despesa || 0;
          if (aValue === bValue) {
            return 0;
          }
          return aValue > bValue ? 1 : -1;
        },
        render: (row: EntityGeneralTopFinanceResponseType) => <div>{formatCurrency(row.despesa)}</div>,
      },
    ],
  },
  chartConfig: {
    data: [],
    xField: 'referencia',
    yField: 'valor',
    yAxis: {
      label: {
        formatter: (value) => {
          return formatCurrency(Number(value));
        },
      },
    },
    seriesField: 'tipo',
    isGroup: true,
    appendPadding: 15,
    // label: {
    //   position: 'top',
    //   formatter: (el) => {
    //     return formatCurrency(Number(el.valor));
    //   },
    // },
    color: (data) => {
      return (Chart.Color as any)[data.tipo];
    },
    legend: {
      position: 'bottom',
    },
    tooltip: {
      formatter: (el: any) => ({
        name: el.tipo,
        value: formatCurrency(el.valor),
      }),
    },
  } as ColumnConfig,
};

const EntityGeneralTopFinance = forwardRef((props: FormViewProps, ref) => {
  const [currentUser] = useCurrentUser();
  const modals = useModals();

  const [result, setResult] = useState<Result>({
    response: null,
    loaded: false,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [forceSearch, setForceSearch] = useState(false);

  const [referenceData, setReferenceData] = useState<{
    companyData: CompanyType[];
  }>({
    companyData: [],
  });

  const tempResult = JSON.parse(sessionStorage.getItem(componentConfig.cacheKey) || 'null') as Result | null;
  const form = useForm<FilterData>({
    initialValues: tempResult?.response?.filterConfig
      ? {
          ...tempResult.response.filterConfig,
          periodo: [
            new Date(tempResult?.response?.filterConfig.periodo[0]),
            new Date(tempResult?.response?.filterConfig.periodo[1]),
          ],
        }
      : {
          ...componentConfig.filterConfig,
          idEmpresa: currentUser.executivo ? null : currentUser.idEmpresa.toString(),
        },
    validate: {
      periodo: (value) => {
        if (!value || value.length === 0 || (value as any).includes(null)) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const searchMe = async () => {
    const searchCriteria = {
      idEmpresa:
        form.values.idEmpresa !== null && form.values.idEmpresa !== undefined
          ? Number(form.values.idEmpresa)
          : null,
      dataInicial: moment(form.values.periodo[0]).format('yyyy-MM-DD'),
      dataFinal: moment(form.values.periodo[1]).format('yyyy-MM-DD'),
    };

    const data = await reportsService.entidadeGeralTopFinanceiro(searchCriteria);
    return data;
  };

  const sortMe = (data: any[]) => {
    return data.sort((a: any, b: any) => b[form.values.ordem] - a[form.values.ordem]);
  };

  const filterMe = (data: EntityGeneralTopFinanceResponseType[]) => {
    let filteredData = data.filter(
      (x) => Number(x.idEmpresa) === (Number(form.values.idEmpresa) || Number(x.idEmpresa))
    );
    filteredData = sortMe(filteredData);
    filteredData = filteredData.slice(0, form.values.top || 1);
    return filteredData;
  };

  const buildChartData = (data: EntityGeneralTopFinanceResponseType[]) => {
    const chartData = [];
    for (const item of data) {
      const referencia = `${item.nomeFantasia || item.razaoSocial || item.nome}`;

      chartData.push({ referencia, tipo: 'Receita', valor: item.receita });
      chartData.push({ referencia, tipo: 'Despesa', valor: item.despesa });
    }

    return chartData;
  };

  const buildResponse = async (force: boolean): Promise<Response> => {
    let data = tempResult?.response?.data || [];

    if (force) {
      data = await searchMe();
    }
    const filteredData = filterMe(data);
    const chartData = buildChartData(filteredData);

    return {
      filterConfig: form.values,
      data,
      filteredData,
      chartConfig: {
        ...componentConfig.chartConfig,
        data: chartData,
      },
    };
  };

  const load = async (resolve: any, force: boolean) => {
    if (form.validate().hasErrors) {
      return;
    }

    let newResult: Result | null = null;
    let response;

    try {
      setLoading(true);
      response = await buildResponse(force);

      newResult = { response, loaded: true, error: null };
    } catch (error: any) {
      const message = error?.isBusinessException
        ? error.description
        : 'Oops! Não foi possível carregar os dados.';

      newResult = { response: null, loaded: true, error: message };
    } finally {
      try {
        if (newResult?.response) {
          sessionStorage.setItem(componentConfig.cacheKey, JSON.stringify(newResult));
        } else {
          sessionStorage.removeItem(componentConfig.cacheKey);
        }
      } catch (error) {}

      setResult(newResult as any);
      setLoading(false);
      resolve();
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);

      setReferenceData({
        companyData: await cacheUtils.listCompanies(),
      });

      const cacheResult = JSON.parse(
        sessionStorage.getItem(componentConfig.cacheKey) || 'null'
      ) as Result | null;
      if (cacheResult) {
        const { periodo } = cacheResult.response!.filterConfig;
        periodo[0] = new Date(periodo[0]);
        periodo[1] = new Date(periodo[1]);

        setResult({ ...cacheResult });
        form.setValues({ ...cacheResult.response!.filterConfig, periodo });
      }

      setLoading(false);
    };

    try {
      loadInitialData();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (forceSearch) {
      load(() => {}, true);
      setForceSearch(false);
      return;
    }

    const filterConfig = result.response?.filterConfig;
    const idEmpresaChanged = form.values.idEmpresa !== filterConfig?.idEmpresa;
    const topChanged = form.values.top !== filterConfig?.top;
    const ordemChanged = form.values.ordem !== filterConfig?.ordem;

    if (idEmpresaChanged || topChanged || ordemChanged) {
      load(() => {}, false);
    }
  }, [form.values]);

  useImperativeHandle(ref, () => ({
    load(input: ExternalInputData) {
      setForceSearch(true);
      form.setValues({ ...form.values, ...input.filter });
      return Promise.resolve();
    },
  }));

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Group position="apart">
        <PageSection
          size="lg"
          color={Feature.Home.Dashboard.color}
          label={componentConfig.title}
          text={componentConfig.description}
        />
        <Group>
          <Button
            size="xs"
            color={Feature.Home.Dashboard.color}
            leftIcon={<ITable size={14} />}
            hidden={
              loading || (result.response?.data || []).length === 0 || !componentConfig.dataConfig.exportable
            }
            onClick={() => {
              modals.openModal({
                title: `Dashboard Gerencial - Dados`,
                size: '80%',
                closeOnClickOutside: false,
                children: (
                  <Paper shadow="xs" p="md" withBorder>
                    <PageSection
                      size="lg"
                      color={Feature.Home.Dashboard.color}
                      label={componentConfig.title}
                      text={componentConfig.description}
                    />
                    <Space h="xs" />

                    <Paper shadow="xs" p="md" withBorder>
                      <Table
                        showSorterTooltip={false}
                        size="small"
                        dataSource={result.response?.data}
                        columns={componentConfig.dataConfig.columns}
                        rowKey={componentConfig.dataConfig.rowKey}
                        pagination={{
                          pageSizeOptions: [10, 25, 50],
                          showSizeChanger: true,
                          showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
                        }}
                      />
                    </Paper>
                  </Paper>
                ),
              });
            }}
          >
            Dados
          </Button>
          <Button
            size="xs"
            color="accent"
            leftIcon={<Reload size={14} />}
            loading={loading}
            onClick={() => {
              load(() => {}, true);
            }}
          >
            Atualizar
          </Button>
        </Group>
      </Group>
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <div style={{ position: 'relative' }}>
          <LoadingOverlay visible={loading} overlayBlur={3} />

          <Paper shadow="xs" p="md" withBorder>
            <Group position="apart">
              <Group>
                <DateRangePicker
                  label="Período"
                  description="Filtro: entre"
                  placeholder="Período"
                  clearable={false}
                  amountOfMonths={2}
                  allowSingleDateInRange
                  required
                  {...form.getInputProps('periodo')}
                />
              </Group>
              <Group spacing="xs">
                <Select
                  icon={<Building size={15} />}
                  label="Empresa"
                  description="Filtro: igual"
                  placeholder="Todos..."
                  data={referenceData.companyData.map((x) => {
                    return {
                      value: x.idEmpresa.toString(),
                      label: x.empresa,
                    };
                  })}
                  searchable
                  clearable={currentUser.executivo}
                  disabled={!currentUser.executivo}
                  {...form.getInputProps('idEmpresa')}
                />
                {/* <NumberInput
                    icon={<Calendar size={15} />}
                    label="Dias"
                    description="Filtro: Últimos"
                    placeholder="Desde Sempre"
                    min={0}
                    step={1}
                    stepHoldDelay={500}
                    stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                    {...form.getInputProps('dias')}
                  /> */}
                <NumberInput
                  icon={<Filter size={15} />}
                  label="Top"
                  description="Filtro: Igual"
                  placeholder="Top"
                  type="number"
                  min={1}
                  max={25}
                  step={1}
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  {...form.getInputProps('top')}
                />
                <Select
                  icon={<SortDescendingNumbers size={15} />}
                  style={{ width: 150 }}
                  label="Ordem"
                  description="Filtro: Igual"
                  placeholder="Selecione..."
                  data={componentConfig.filterConfig.ordemData}
                  {...form.getInputProps('ordem')}
                />
              </Group>
            </Group>
          </Paper>
          <Space h="md" />

          {result.error ? (
            <Stack spacing="md" align="center">
              <PlugConnectedX color="red" size={32} />
              <Text>{result.error}</Text>
            </Stack>
          ) : result.loaded ? (
            (result.response?.filteredData || []).length > 0 ? (
              <Paper shadow="xs" p="md" withBorder>
                <Column {...result.response?.chartConfig!} height={props.height} />
              </Paper>
            ) : (
              <Paper shadow="xs" p="md" withBorder>
                <Stack spacing="md" align="center">
                  <TableOff color={theme?.colors?.primary?.[6]} size={32} />
                  <Text>Sem dados para mostrar.</Text>
                </Stack>
              </Paper>
            )
          ) : (
            <Paper shadow="xs" p="md" withBorder>
              <Stack spacing="md" align="center">
                <Reload color={theme?.colors?.primary?.[6]} size={32} />
                <Text>Esperando atualização</Text>
              </Stack>
            </Paper>
          )}
        </div>
      </Paper>
    </Paper>
  );
});

export default EntityGeneralTopFinance;
