import { createContext, ReactNode, useContext, useState } from 'react';

type EventPackagingData = {
  idResiduoAcondicionamento: number | null;
  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;
};

interface EventPackagingContextProps {
  eventPackagingData: EventPackagingData;
  setEventPackagingData(eventPackagingContextData: EventPackagingData): void;
}
const EventPackagingContext = createContext<EventPackagingContextProps>({} as EventPackagingContextProps);

// eslint-disable-next-line react/function-component-definition
export const EventPackagingProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [eventPackagingData, setEventPackagingData] = useState<EventPackagingData>({} as EventPackagingData);
  return (
    <EventPackagingContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventPackagingData,
        setEventPackagingData,
      }}
    >
      {children}
    </EventPackagingContext.Provider>
  );
};

export type { EventPackagingData };

export function useEventPackagingContext() {
  return useContext(EventPackagingContext);
}
