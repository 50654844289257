/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import { Button, Group, NumberInput, Paper, Select, SimpleGrid, Space, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CurrencyReal, DeviceFloppy, Notes, Ruler2 } from 'tabler-icons-react';
import { UnitOfMeasureType } from '../../../../models/core/cache.type';
import { Action, ItemToleranceType } from '../../../../models/core/core.type';
import { formatCurrency } from '../../../../utils/formatter.utils';
import { buildFakeAuditObject, newGuid } from '../../../../utils/helper.utils';

type FormViewData = {
  action: Action;
  id: string;
  idUnidadeMedida: string | null;
  quantidade: number | null;
  precoUnitario: number | null;
  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ItemToleranceType | null;
  item: ItemToleranceType | null;
  idProposta: number | undefined;
  idEvento: number | undefined;
  callback(item: ItemToleranceType | null, action: string, confirmed: boolean): void;
};

// TODO: Proposals: 050 - origItem is overwritten after saving parent screen. Thus, re-opening this screen, making no changes and saving it will result an action=nothing (it may happen to everything)
export default function ToleranceFormAddEdit(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      action: props.item?.action ? props.item.action : props.origItem ? Action.Nothing : Action.Add,
      id:
        props.item?.idPropostaTolerancia?.toString() ||
        props.item?.idEventoTolerancia?.toString() ||
        props.item?.id ||
        newGuid(),
      idUnidadeMedida: props.item?.idUnidadeMedida.toString() || null,
      quantidade: props.item?.quantidade || 0,
      precoUnitario: props.item?.precoUnitario || null,
      observacao: props.item?.observacao || '',
    },
    validate: {
      idUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        return null;
      },
      precoUnitario: (value: number | null) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (Number(value) <= 0) {
          return `Valor deve ser no mínimo ${formatCurrency(0.01)}`;
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idUnidadeMedida || ''}
    |${Number(props.origItem?.quantidade) || ''}
    |${Number(props.origItem?.precoUnitario) || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idUnidadeMedida || ''}
    |${Number(form.values.quantidade) || ''}
    |${Number(form.values.precoUnitario) || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }
    if (props.origItem) {
      if (isModified()) {
        values.action = Action.Modify;
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: Action.Nothing,
          idUnidadeMedida: form.values.idUnidadeMedida?.toString() || '',
        };
      }
    }

    const formItem: ItemToleranceType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idProposta: props.idProposta || props.idEvento || -1,
    };

    formItem.idUnidadeMedida = Number(formItem.idUnidadeMedida);
    formItem.unidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.idUnidadeMedida)
      )?.unidadeMedida || '';

    formItem.precoUnitario = Number(formItem.precoUnitario);
    formItem.observacao = formItem.observacao?.trim() || null;

    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form id="tolerance" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={3}>
          <Select
            icon={<Ruler2 size={15} />}
            label="Unidade de Medida"
            placeholder="Selecione..."
            data={props.referenceData.unitOfMeasures.map((x) => {
              return {
                value: x.idUnidadeMedida.toString(),
                label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                group: x.tipo,
              };
            })}
            searchable
            required
            {...form.getInputProps('idUnidadeMedida')}
          />
          <NumberInput
            label="Quantidade"
            placeholder="Quantidade"
            min={0.0}
            defaultValue={0}
            precision={2}
            step={0.5}
            stepHoldDelay={500}
            stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
            required
            {...form.getInputProps('quantidade')}
          />
          <NumberInput
            icon={<CurrencyReal size={15} />}
            label="Preço Unitário"
            placeholder="Preço Unitário"
            min={0}
            precision={2}
            hideControls
            {...form.getInputProps('precoUnitario')}
            required
          />
        </SimpleGrid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre a tolerância"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />
      <Group position="right">
        <Button
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="tolerance"
          onClick={() => handleSubmit(form.values)}
        >
          Salvar
        </Button>
      </Group>
    </form>
  );
}
