/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Modal, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Click, Truck } from 'tabler-icons-react';
import { VehicleType } from '../../../models/core/vehicles.type';
import theme from '../../../theme';
import VehicleSearchModal from './VehicleSearchModal';

type VehicleFormViewProps = {
  label: string;
  formItem: any;
  idsToBeDisabled: number[];
  callback(item: VehicleType | null): void;
  disabled: boolean;
};

export default function VehicleSearch(props: VehicleFormViewProps) {
  const [opened, setOpened] = useState(false);

  const confirmActionResult = (item: VehicleType | null, confirmed: boolean) => {
    if (!confirmed) {
      return;
    }
    setOpened(false);
    props.callback(item);
  };

  return (
    <div>
      <Modal
        opened={opened}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={() => setOpened(false)}
        title="Veículos - Procurar"
        size="70%"
      >
        <VehicleSearchModal idsToBeDisabled={props.idsToBeDisabled} callback={confirmActionResult} />
      </Modal>

      <TextInput
        icon={<Truck size={15} />}
        rightSection={
          <ActionIcon
            size="sm"
            radius="sm"
            color="primary"
            style={{ borderColor: theme?.colors?.primary?.[6] }}
            variant="outline"
            onClick={() => {
              setOpened(true);
            }}
            disabled={props.disabled}
          >
            <Click size={15} color={theme?.colors?.primary?.[6]} />
          </ActionIcon>
        }
        onClick={() => {
          setOpened(true);
        }}
        label={props.label}
        placeholder="Selecione..."
        {...props.formItem}
        readOnly
        required
        disabled={props.disabled}
      />
    </div>
  );
}
