/* eslint-disable react/destructuring-assignment */
import { Backhoe, Notes } from 'tabler-icons-react';
import { Paper, SimpleGrid, Space, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { EquipmentType } from '../../../../../models/core/equipment.type';
import { Feature } from '../../../../../utils/constants.utils';
import PageSection from '../../../../../components/core/PageSection/PageSection';

type FormViewData = {
  idResiduoEquipamento: number | null;
  residuoEquipamento: string;
  descricao: string | null;
};

type FormViewProps = {
  save(data: FormViewData): void;
  equipment: EquipmentType | null;
  loading: boolean;
};

function FormView(props: FormViewProps) {
  const form = useForm<FormViewData>({
    initialValues: {
      idResiduoEquipamento: props.equipment?.idResiduoEquipamento || null,
      residuoEquipamento: props.equipment?.residuoEquipamento || '',
      descricao: props.equipment?.descricao || '',
    },
    validate: {
      residuoEquipamento: (value) => (value.trim() !== '' ? null : 'Campo obrigatório'),
    },
  });

  const handleSubmit = async (formData: FormViewData) => {
    props.save({
      ...formData,
      residuoEquipamento: formData.residuoEquipamento.trim(),
      descricao: formData.descricao?.trim() || null,
    });
  };

  return (
    <form id="equipment-add-edit" onSubmit={form.onSubmit(handleSubmit)} noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <PageSection size="lg" color={Feature.Reference.Equipment.color} label="Geral" text="" />
        <Space h="xs" />
        <SimpleGrid cols={5}>
          <TextInput
            icon={<Backhoe size={15} />}
            label="Equipamento"
            placeholder="Nome do equipamento"
            maxLength={50}
            required
            {...form.getInputProps('residuoEquipamento')}
          />
        </SimpleGrid>
        <Space h="xs" />
        <Textarea
          icon={<Notes size={15} />}
          label="Descrição"
          placeholder="Descrição do equipamento"
          autosize
          maxLength={500}
          {...form.getInputProps('descricao')}
        />
      </Paper>
    </form>
  );
}

export { FormView };
export type { FormViewData };
