/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Divider, Grid, Paper, SimpleGrid, Space } from '@mantine/core';
import PageViewAudit from '../../../../../../../components/core/PageViewAudit/PageViewAudit';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import { EventServiceType } from '../../../../../../../models/core/events.type';

type DataViewProps = {
  data: EventServiceType;
};

export default function EventServiceItem(props: DataViewProps) {
  return (
    <div>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={5}>
          <Grid.Col span={2}>
            {props.data.idResiduoAcondicionamento && (
              <PageViewProperty
                label="Acondicionamento"
                text={
                  <ProfileCardLink
                    id={(props.data.idResiduoAcondicionamento || '').toString()}
                    name={props.data.residuoAcondicionamento || '-'}
                    nameSize="sm"
                    description={props.data.residuoAcondicionamentoDescricao}
                    descriptionSize="xs"
                    linkPrefix="packaging"
                    showLink
                  />
                }
                size="sm"
              />
            )}
            {props.data.idResiduoEquipamento && (
              <PageViewProperty
                label="Equipamento"
                text={
                  <ProfileCardLink
                    id={(props.data.idResiduoEquipamento || '').toString()}
                    name={props.data.residuoEquipamento || '-'}
                    nameSize="sm"
                    description={props.data.residuoEquipamentoDescricao}
                    descriptionSize="xs"
                    linkPrefix="equipment"
                    showLink
                  />
                }
                size="sm"
              />
            )}
            {!props.data.idResiduoAcondicionamento && !props.data.idResiduoEquipamento && (
              <PageViewProperty
                label="Fornecedor"
                text={
                  <ProfileCardLink
                    id="-1"
                    name="Fornecedor"
                    nameSize="sm"
                    description={null}
                    descriptionSize="xs"
                    showLink={false}
                  />
                }
                size="sm"
              />
            )}
          </Grid.Col>

          <Grid.Col span={1}>
            <PageViewProperty label="Quantidade" text={props.data.quantidade.toString()} size="sm" />
          </Grid.Col>

          <Grid.Col span={2}>
            <PageViewProperty
              label="Frequência"
              text={`${
                !(props.data.idResiduoAcondicionamento || props.data.idResiduoEquipamento)
                  ? `${props.data.frequencia} `
                  : ''
              }${props.data.frequenciaUnidadeMedida1} / ${props.data.frequenciaUnidadeMedida2}`}
              size="sm"
            />
          </Grid.Col>
        </Grid>
        <Divider my="sm" variant="dotted" />

        {!props.data.idResiduoAcondicionamento && !props.data.idResiduoEquipamento && (
          <>
            <Grid columns={5}>
              <Grid.Col span={2}>
                <PageViewProperty
                  label="Turno"
                  text={props.data.turno ? `${props.data.turno + 1}º Turno` : '-'}
                  size="sm"
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <PageViewProperty label="Jornada" text={props.data.jornada || '-'} size="sm" />
              </Grid.Col>
            </Grid>
            <Divider my="sm" variant="dotted" />
          </>
        )}

        <SimpleGrid>
          <PageViewProperty label="Observação" text={props.data.observacao || '-'} size="sm" />
        </SimpleGrid>
      </Paper>
      <Space h="lg" />

      <PageViewAudit
        idCriadoPor={props.data.idCriadoPor}
        criadoPor={props.data.criadoPor}
        dataCriacao={props.data.dataCriacao}
        idModificadoPor={props.data?.idModificadoPor || null}
        modificadoPor={props.data?.modificadoPor || null}
        dataModificacao={props.data?.dataModificacao || null}
        size="sm"
        gridSize={2}
      />
    </div>
  );
}
