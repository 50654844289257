/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { ActionIcon, Badge, Grid, Group, Table as MantineTable, Paper, Space, Tooltip } from '@mantine/core';
import { Table } from 'antd';
import { useMemo } from 'react';
import { Check, Printer, X } from 'tabler-icons-react';
import {
  BillingSummary,
  Statement,
  buildStatementData,
  buildSummaryData,
} from '../../../../../../../business/events/billing';
import PageSection from '../../../../../../../components/core/PageSection/PageSection';
import PageViewProperty from '../../../../../../../components/core/PageViewProperty/PageViewProperty';
import ProfileCardLink from '../../../../../../../components/core/ProfileCardLink/ProfileCardLink';
import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import { MovementBillingType, MovementBillingTypeCode } from '../../../../../../../models/core/cache.type';
import { Permission } from '../../../../../../../models/core/departments.type';
import { EventType } from '../../../../../../../models/core/events.type';
import theme from '../../../../../../../theme';
import { Feature } from '../../../../../../../utils/constants.utils';
import { formatCurrency, formatDateToString } from '../../../../../../../utils/formatter.utils';
import { validate } from '../../../../../../../utils/permission.utils';

type DataViewProps = {
  data: EventType;
};

export default function BillingStatementView(props: DataViewProps) {
  const [currentUser] = useCurrentUser();
  const buildStatement = useMemo(() => {
    return buildStatementData(props.data);
  }, [props.data]);
  const statementData = buildStatement;

  const buildSummary = useMemo(() => {
    return buildSummaryData(statementData);
  }, [statementData]);
  const summaryData = buildSummary;

  return (
    <Paper shadow="xs" p="md" withBorder>
      <PageSection
        size="lg"
        color={Feature.Home.Event.color}
        label="Extrato"
        text="Informações financeiras gerais desse fechamento."
      />
      <Space h="xs" />

      <Paper shadow="xs" p="md" withBorder>
        <Group position="apart">
          <PageSection
            size="lg"
            color={Feature.Home.Event.color}
            label="Resumo"
            text="Lista consolidada de todas de movimentações associados à esse fechamento."
          />
          {/* <Group>
            <Button
              size="sm"
              color={Feature.Home.Dashboard.color}
              leftIcon={<Printer size={14} />}
              onClick={() => {
                window.open(`${Feature.Home.Event.link}/${props.data.idEvento}/print`, '_blank');
              }}
            >
              Imprimir
            </Button>
          </Group> */}
        </Group>

        <Space h="xs" />

        <Table
          showSorterTooltip={false}
          dataSource={summaryData}
          columns={[
            {
              title: 'Entidade',
              key: 'entidade',
              sorter: (a: BillingSummary, b: BillingSummary) => {
                const aValue =
                  a.clienteFornecedor.nomeFantasia ||
                  a.clienteFornecedor.razaoSocial ||
                  a.clienteFornecedor.nome ||
                  '-';
                const bValue =
                  b.clienteFornecedor.nomeFantasia ||
                  b.clienteFornecedor.razaoSocial ||
                  b.clienteFornecedor.nome ||
                  '-';
                return aValue.localeCompare(bValue);
              },
              render: (row: BillingSummary) => {
                return row.clienteFornecedor.cnpj ? (
                  <ProfileCardLink
                    id={row.clienteFornecedor.idEntidade.toString()}
                    name={row.clienteFornecedor.nomeFantasia || row.clienteFornecedor.razaoSocial || '-'}
                    nameSize="sm"
                    description={
                      row.clienteFornecedor.nomeFantasia
                        ? row.clienteFornecedor.razaoSocial
                        : row.clienteFornecedor.cnpj
                    }
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : (
                  <ProfileCardLink
                    id={row.clienteFornecedor.idEntidade.toString()}
                    name={row.clienteFornecedor.nome || '-'}
                    nameSize="sm"
                    description={row.clienteFornecedor.cpf}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                );
              },
            },
            {
              title: 'Tipo',
              key: 'tipo',
              sorter: (a: BillingSummary, b: BillingSummary) => a.tipo.localeCompare(b.tipo),
              render: (row: BillingSummary) => <Badge variant="outline">{row.tipo}</Badge>,
            },
            {
              title: 'Receita',
              key: 'receita',
              sorter: (a: BillingSummary, b: BillingSummary) => {
                const aValue = a.receita || 0;
                const bValue = b.receita || 0;
                if (aValue === bValue) {
                  return 0;
                }
                return aValue > bValue ? 1 : -1;
              },
              render: (row: BillingSummary) => <div>{formatCurrency(row.receita)}</div>,
            },
            {
              title: 'Despesa',
              key: 'despesa',
              sorter: (a: BillingSummary, b: BillingSummary) => {
                const aValue = a.despesa || 0;
                const bValue = b.despesa || 0;
                if (aValue === bValue) {
                  return 0;
                }
                return aValue > bValue ? 1 : -1;
              },
              render: (row: BillingSummary) => <div>{formatCurrency(row.despesa)}</div>,
            },
            {
              title: 'Balanço',
              key: 'balanco',
              sorter: (a: BillingSummary, b: BillingSummary) => {
                const aValue = a.balanco;
                const bValue = b.balanco;
                if (aValue === bValue) {
                  return 0;
                }
                return aValue > bValue ? 1 : -1;
              },
              render: (row: BillingSummary) => {
                const total = row.balanco;
                const color = total > 0 ? 'green' : total === 0 ? 'orange' : 'red';

                return (
                  <div style={{ color }}>{`${formatCurrency(total)} (${
                    row.balanco > 0 ? 'Recebimento' : 'Pagamento'
                  })`}</div>
                );
              },
            },
            {
              title: 'Encontro de Contas?',
              key: 'aceitaEncontroContas',
              sorter: (a: BillingSummary, b: BillingSummary) =>
                a.clienteFornecedor.aceitaEncontroContas === b.clienteFornecedor.aceitaEncontroContas
                  ? 0
                  : a.clienteFornecedor.aceitaEncontroContas
                  ? -1
                  : 1,
              render: (row: BillingSummary) => (
                <div>
                  {row.clienteFornecedor.aceitaEncontroContas ? <Check color="green" /> : <X color="red" />}
                </div>
              ),
            },
            {
              title: 'Ações',
              width: '100px',
              render: (row: BillingSummary) => {
                return (
                  <Group>
                    {validate(Permission.EventPrint, currentUser.permissoes) ? (
                      <>
                        <Tooltip withArrow transition="fade" transitionDuration={200} label="Imprimir">
                          <ActionIcon
                            size="sm"
                            radius="sm"
                            color="primary"
                            style={{ borderColor: theme?.colors?.dark?.[6] }}
                            variant="outline"
                            onClick={() => {
                              window.open(
                                `${Feature.Home.Event.link}/${props.data.idEvento}/print?entityId=${row.clienteFornecedor.idEntidade}&perspective=internal`,
                                '_blank'
                              );
                            }}
                          >
                            <Printer size={15} color={theme?.colors?.dark?.[6]} />
                          </ActionIcon>
                        </Tooltip>
                        <Tooltip
                          withArrow
                          transition="fade"
                          transitionDuration={200}
                          label={`Imprimir (perspectiva do ${row.tipo}`}
                        >
                          <ActionIcon
                            size="sm"
                            radius="sm"
                            color="violet"
                            style={{ borderColor: theme?.colors?.violet?.[6] }}
                            variant="outline"
                            onClick={() => {
                              window.open(
                                `${Feature.Home.Event.link}/${props.data.idEvento}/print?entityId=${
                                  row.clienteFornecedor.idEntidade
                                }&perspective=${row.tipo === 'Cliente' ? 'client' : 'supplier'}`,
                                '_blank'
                              );
                            }}
                          >
                            <Printer size={15} color={theme?.colors?.violet?.[6]} />
                          </ActionIcon>
                        </Tooltip>
                      </>
                    ) : (
                      '-'
                    )}
                  </Group>
                );
              },
            },
          ]}
          rowKey="key"
          expandable={{
            // eslint-disable-next-line react/no-unstable-nested-components
            expandedRowRender: (item) => {
              return (
                <Paper shadow="xs" p="md" withBorder>
                  <MantineTable withBorder withColumnBorders striped>
                    <thead>
                      <tr>
                        <th>Tipo de Faturamento</th>
                        <th>Receita</th>
                        <th>Despesa</th>
                        <th>Balanço</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.faturamento.map((x) => {
                        return (
                          <tr>
                            <td>{x.tipo}</td>
                            <td>{formatCurrency(x.receita)}</td>
                            <td>{formatCurrency(x.despesa)}</td>
                            <td
                              style={{
                                color: x.balanco > 0 ? 'green' : x.balanco === 0 ? 'orange' : 'red',
                              }}
                            >
                              {`${formatCurrency(x.balanco)} (${
                                x.balanco > 0 ? 'Recebimento' : 'Pagamento'
                              })`}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MantineTable>
                </Paper>
              );
            },
          }}
          pagination={{
            locale: { items_per_page: 'página' },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
      <Space h="lg" />

      <Paper shadow="xs" p="md" withBorder>
        <PageSection
          size="lg"
          color={Feature.Home.Event.color}
          label="Movimentações"
          text="Lista detalhada de todas as movimentações associadas à esse fechamento."
        />
        <Space h="xs" />

        <Table
          showSorterTooltip={false}
          dataSource={statementData.extrato}
          columns={[
            {
              title: 'Evento',
              key: 'idEventoReferenciado',
              sorter: (a: Statement, b: Statement) => {
                const aValue = a.idEvento;
                const bValue = b.idEvento;
                if (aValue === bValue) {
                  return 0;
                }
                return aValue > bValue ? 1 : -1;
              },
              render: (row: Statement) => {
                return (
                  <ProfileCardLink
                    id={row.idEvento.toString()}
                    name={`# ${row.idEvento}`}
                    nameSize="sm"
                    avatar="E"
                    description={`${row.eventoTipo}`}
                    descriptionSize="xs"
                    linkPrefix="events"
                    showLink
                  />
                );
              },
            },
            {
              title: 'Resíduo / Serviço',
              key: 'receita',
              sorter: (a: Statement, b: Statement) =>
                (a.residuo?.residuoCliente || a.servico?.servico || '-').localeCompare(
                  b.residuo?.residuoCliente || b.servico?.servico || '-'
                ),
              render: (row: Statement) => {
                return row.residuo ? (
                  <ProfileCardLink
                    id={row.residuo.idResiduo.toString()}
                    name={row.residuo.residuoCliente}
                    nameSize="sm"
                    description={`Código IBAMA: ${row.residuo.codigoIBAMA || '-'} | Perigoso: ${
                      row.residuo.residuoClientePerigoso ? 'Sim' : 'Não'
                    }`}
                    descriptionSize="xs"
                    descriptionLength={100}
                    linkPrefix="residues"
                    avatar="R"
                    showLink
                  />
                ) : row.servico ? (
                  <ProfileCardLink
                    id={row.servico!.idServico.toString()}
                    name={row.servico!.servico}
                    nameSize="sm"
                    description={row.servico!.servicoDescricao}
                    descriptionSize="xs"
                    linkPrefix="services"
                    avatar="S"
                    showLink
                  />
                ) : (
                  '-'
                );
              },
            },
            {
              title: 'Descrição',
              key: 'descricao',
              sorter: (a: Statement, b: Statement) => a.descricao.localeCompare(b.descricao),
              render: (row: Statement) => {
                return (
                  <ProfileCardLink
                    id={row.movimentacao.idEventoMedicaoMovimentacao?.toString() || '-'}
                    name={row.descricao}
                    nameSize="sm"
                    description={row.item}
                    descriptionSize="xs"
                    avatar={
                      row.grupo === MovementBillingTypeCode.GR
                        ? MovementBillingType.GerenciamentoResiduo
                        : row.grupo === MovementBillingTypeCode.LO
                        ? MovementBillingType.Locacao
                        : MovementBillingType.PrestacaoServico
                    }
                    showLink={false}
                  />
                );
              },
            },
            // {
            //   title: 'Documentos',
            //   key: 'documentos',
            //   sorter: (a: Statement, b: Statement) => {
            //     const aValue = (a.documentos || []).length;
            //     const bValue = (b.documentos || []).length;
            //     if (aValue === bValue) {
            //       return 0;
            //     }
            //     return aValue > bValue ? 1 : -1;
            //   },
            //   render: (item: Statement) => {
            //     return (item.documentos || [])?.length > 0 ? (
            //       <Group spacing="xs">
            //         {item
            //           .documentos!.filter(
            //             (x) =>
            //               x.documentoTipo === 'Nota Fiscal' ||
            //               x.documentoTipo === 'MTR' ||
            //               x.documentoTipo === 'Romaneio de Coleta'
            //           )
            //           .map((y) => {
            //             return (
            //               <Badge
            //                 key={newGuid()}
            //                 variant="outline"
            //               >{`${y.documentoTipo} - ${y.numeroDocumento}`}</Badge>
            //             );
            //           })}
            //       </Group>
            //     ) : (
            //       <>-</>
            //     );
            //   },
            // },
            // {
            //   title: 'Medição',
            //   key: 'medicao',
            //   render: (row: Statement) => {
            //     const quantidadeOrigem = row.medicao.quantidadeOrigem
            //       ? `${row.medicao.quantidadeOrigem} ${row.medicao.quantidadeOrigemUnidadeMedida}`
            //       : null;
            //     const quantidadeDestino = row.medicao.quantidadeDestino
            //       ? `${row.medicao.quantidadeDestino} ${row.medicao.quantidadeDestinoUnidadeMedida}`
            //       : null;
            //     const tempo = row.medicao.tempo
            //       ? `${row.medicao.tempo} ${row.medicao.tempoUnidadeMedida}`
            //       : null;
            //     const minimoAceitavel =
            //       row.residuo && row.residuo.minimoAceitavelIdUnidadeMedida1
            //         ? `${row.residuo.minimoAceitavel} ${row.residuo.minimoAceitavelUnidadeMedida1} / ${row.residuo.minimoAceitavelUnidadeMedida2}`
            //         : null;

            //     return (
            //       <Stack spacing="xs">
            //         {quantidadeOrigem && <div>{`Quantidade Origem: ${quantidadeOrigem}`}</div>}
            //         {quantidadeDestino && <div>{`Quantidade Destino: ${quantidadeDestino}`}</div>}
            //         {tempo && <div>{`Tempo Coleta: ${tempo}`}</div>}
            //         {minimoAceitavel && <div>{`Mínimo Aceitável: ${minimoAceitavel}`}</div>}
            //       </Stack>
            //     );
            //   },
            // },
            // {
            //   title: 'Quantidade (Origem)',
            //   key: 'quantidadeOrigem',
            //   render: (row: Statement) =>
            //     row.medicao.quantidadeOrigem
            //       ? `${row.medicao.quantidadeOrigem} ${row.medicao.quantidadeOrigemUnidadeMedida}`
            //       : '-',
            // },
            // {
            //   title: 'Quantidade (Destino)',
            //   key: 'quantidadeDestino',
            //   render: (row: Statement) =>
            //     row.medicao.quantidadeDestino
            //       ? `${row.medicao.quantidadeDestino} ${row.medicao.quantidadeDestinoUnidadeMedida}`
            //       : '-',
            // },
            // {
            //   title: 'Mínimo Aceitável',
            //   key: 'minimoAceitavel',
            //   render: (row: Statement) =>
            //     row.residuo && row.residuo.minimoAceitavelIdUnidadeMedida1
            //       ? `${row.residuo.minimoAceitavel} ${row.residuo.minimoAceitavelUnidadeMedida1} / ${row.residuo.minimoAceitavelUnidadeMedida2}`
            //       : '-',
            // },
            {
              title: 'Cliente / Receita',
              key: 'idCliente',
              sorter: (a: Statement, b: Statement) =>
                (a.cliente.nomeFantasia || a.cliente.razaoSocial || a.cliente.nome || '').localeCompare(
                  b.cliente.nomeFantasia || b.cliente.razaoSocial || b.cliente.nome || ''
                ),
              render: (row: Statement) => {
                return row.cliente.cnpj ? (
                  <ProfileCardLink
                    id={row.cliente.idEntidade.toString()}
                    name={row.cliente.nomeFantasia || row.cliente.razaoSocial || '-'}
                    nameSize="sm"
                    description={formatCurrency(row.movimentacao.receita)}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : (
                  <ProfileCardLink
                    id={row.cliente.idEntidade.toString()}
                    name={row.cliente.nome || '-'}
                    nameSize="sm"
                    description={formatCurrency(row.movimentacao.receita)}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                );
              },
            },
            // {
            //   title: 'Cliente (Valor)',
            //   key: 'receita',
            //   sorter: (a: Statement, b: Statement) => {
            //     const aValue = a.movimentacao.receita || 0;
            //     const bValue = b.movimentacao.receita || 0;
            //     if (aValue === bValue) {
            //       return 0;
            //     }
            //     return aValue > bValue ? 1 : -1;
            //   },
            //   render: (row: Statement) => <div>{formatCurrency(row.movimentacao.receita)}</div>,
            // },
            {
              title: 'Fornecedor / Despesa',
              key: 'idFornecedor',
              sorter: (a: Statement, b: Statement) =>
                (
                  a.fornecedor.nomeFantasia ||
                  a.fornecedor.razaoSocial ||
                  a.fornecedor.nome ||
                  ''
                ).localeCompare(
                  b.fornecedor.nomeFantasia || b.fornecedor.razaoSocial || b.fornecedor.nome || ''
                ),
              render: (row: Statement) => {
                return row.fornecedor.cnpj ? (
                  <ProfileCardLink
                    id={row.fornecedor.idEntidade.toString()}
                    name={row.fornecedor.nomeFantasia || row.fornecedor.razaoSocial || '-'}
                    nameSize="sm"
                    description={formatCurrency(row.movimentacao.despesa)}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                ) : (
                  <ProfileCardLink
                    id={row.fornecedor.idEntidade.toString()}
                    name={row.fornecedor.nome || '-'}
                    nameSize="sm"
                    description={formatCurrency(row.movimentacao.despesa)}
                    descriptionSize="xs"
                    linkPrefix="entities"
                    showLink
                  />
                );
              },
            },
            // {
            //   title: 'Fornecedor (Valor)',
            //   key: 'despesa',
            //   sorter: (a: Statement, b: Statement) => {
            //     const aValue = a.movimentacao.despesa || 0;
            //     const bValue = b.movimentacao.despesa || 0;
            //     if (aValue === bValue) {
            //       return 0;
            //     }
            //     return aValue > bValue ? 1 : -1;
            //   },
            //   render: (row: Statement) => <div>{formatCurrency(row.movimentacao.despesa)}</div>,
            // },
            {
              title: 'Data',
              key: 'data',
              sorter: (a: Statement, b: Statement) => new Date(a.data).valueOf() - new Date(b.data).valueOf(),
              render: (row: Statement) => <Badge variant="outline">{formatDateToString(row.data)}</Badge>,
              width: '120px',
            },
          ]}
          rowKey="key"
          expandable={{
            // eslint-disable-next-line react/no-unstable-nested-components
            expandedRowRender: (item) => {
              return (
                <Paper shadow="xs" p="md" withBorder>
                  <PageSection size="md" color={Feature.Home.Event.color} label="Medições" text="" />
                  <Space h="md" />
                  <Grid columns={4}>
                    <Grid.Col span={1}>
                      <PageViewProperty
                        label="Quantidade de Origem"
                        text={
                          item.medicao.quantidadeOrigem
                            ? `${item.medicao.quantidadeOrigem} ${item.medicao.quantidadeOrigemUnidadeMedida}`
                            : '-'
                        }
                        size="sm"
                      />
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <PageViewProperty
                        label="Quantidade de Destino"
                        text={
                          item.medicao.quantidadeDestino
                            ? `${item.medicao.quantidadeDestino} ${item.medicao.quantidadeDestinoUnidadeMedida}`
                            : '-'
                        }
                        size="sm"
                      />
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <PageViewProperty
                        label="Mínimo Aceitável"
                        text={
                          item.cotacao.minimoAceitavel
                            ? `${item.cotacao.minimoAceitavel} ${item.cotacao.minimoAceitavelUnidadeMedida} / ${item.cotacao.frequenciaUnidadeMedida}`
                            : item.residuo && item.residuo.minimoAceitavelIdUnidadeMedida1
                            ? `${item.residuo.minimoAceitavel} ${item.residuo.minimoAceitavelUnidadeMedida1} / ${item.residuo.minimoAceitavelUnidadeMedida2}`
                            : '-'
                        }
                        size="sm"
                      />
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <PageViewProperty
                        label="Tempo de Coleta/Serviço"
                        text={
                          item.medicao.tempo
                            ? `${item.medicao.tempo} ${item.medicao.tempoUnidadeMedida}`
                            : '-'
                        }
                        size="sm"
                      />
                    </Grid.Col>
                  </Grid>
                </Paper>
              );
            },
          }}
          pagination={{
            locale: { items_per_page: 'página' },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]} - ${range[1]} de ${total} resultado(s)`,
          }}
        />
      </Paper>
    </Paper>
  );
}
