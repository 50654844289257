/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/destructuring-assignment */
import { Paper, SimpleGrid, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { forwardRef, useImperativeHandle } from 'react';
import { Notes } from 'tabler-icons-react';
import { ContractType } from '../../../../../models/core/contracts.type';

type FormViewData = {
  observacao: string | null;
};

type FormViewProps = {
  contract: ContractType;
};

const StatusChangeForm = forwardRef((props: FormViewProps, ref) => {
  const form = useForm<FormViewData>({
    initialValues: {
      observacao: '',
    },
    validate: {},
  });

  useImperativeHandle(ref, () => ({
    validate(): FormViewData | null {
      return { observacao: form.values.observacao?.trim() || null };
    },
  }));

  return (
    <form id="status-change-form" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o status"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
    </form>
  );
});

export default StatusChangeForm;
