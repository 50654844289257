/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Group,
  Input,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Switch,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DeviceFloppy, Notes, Percentage, Ruler2 } from 'tabler-icons-react';
import PackagingSearch from '../../../../../../../../../../../../components/core/PackagingSearch/PackagingSearch';
import ProposalServiceList from '../../../../../../../../../../../../components/core/ProposalServiceList/ProposalServiceList';
import { UnitOfMeasureType } from '../../../../../../../../../../../../models/core/cache.type';
import { Action } from '../../../../../../../../../../../../models/core/core.type';
import { PackagingType } from '../../../../../../../../../../../../models/core/packaging.type';
import {
  ProposalResiduePlanPackagingType,
  ProposalServiceType,
} from '../../../../../../../../../../../../models/core/proposals.type';
import { buildFakeAuditObject, newGuid } from '../../../../../../../../../../../../utils/helper.utils';
import { validarReferenciaServicoCompartilhado } from '../../../../../../../../../../../../business/proposals/validation';

type FormViewData = {
  action: Action;
  id: string;

  idResiduoAcondicionamento: number | null;
  residuoAcondicionamento: string;
  residuoAcondicionamentoDescricao: string | null;

  quantidade: number | null;
  quantidadeIdUnidadeMedida: string | null;
  compartilhado: boolean;
  gerenciar: boolean;

  propostaServicoData: ProposalServiceType | null;
  idPropostaServico: number | null;
  idPropostaServicoTemp: string | null;
  propostaServico: string | null;

  observacao: string | null;
};

type FormViewProps = {
  referenceData: {
    unitOfMeasures: UnitOfMeasureType[];
  };
  origItem: ProposalResiduePlanPackagingType | null;
  item: ProposalResiduePlanPackagingType | null;
  idPropostaResiduoPlano: number | undefined;

  idEntidadeEndereco: number;
  services: ProposalServiceType[];
  callback(item: ProposalResiduePlanPackagingType | null, action: string, confirmed: boolean): void;
};

export default function ResiduePlanPackagingFormAddEdit(props: FormViewProps) {
  let servico = props.services.find(
    (x) =>
      (props.item?.idPropostaServico && x.idPropostaServico === props.item?.idPropostaServico) ||
      (props.item?.idPropostaServicoTemp && x.id === props.item?.idPropostaServicoTemp)
  );
  if (
    !validarReferenciaServicoCompartilhado(
      props.item?.idResiduoAcondicionamento || -1,
      props.idEntidadeEndereco,
      servico
    )
  ) {
    servico = undefined;
  }

  const form = useForm<FormViewData>({
    initialValues: {
      action: props.origItem ? Action.Nothing : Action.Add,
      id: props.item?.id || newGuid(),

      idResiduoAcondicionamento: props.item?.idResiduoAcondicionamento || null,
      residuoAcondicionamento: props.item?.residuoAcondicionamento || '',
      residuoAcondicionamentoDescricao: props.item?.residuoAcondicionamentoDescricao || null,

      quantidade: props.item?.compartilhado
        ? (props.item?.quantidade || 0) * 100
        : props.item?.quantidade || 1,
      quantidadeIdUnidadeMedida:
        props.item?.quantidadeIdUnidadeMedida.toString() ||
        props.referenceData.unitOfMeasures
          .find((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
          ?.idUnidadeMedida.toString() ||
        null,

      compartilhado: props.item ? props.item.compartilhado : false,
      gerenciar: props.item ? props.item.gerenciar : true,

      propostaServicoData: servico || null,
      idPropostaServico: props.item?.idPropostaServico || null,
      idPropostaServicoTemp: props.item?.idPropostaServicoTemp || null,
      propostaServico: servico?.servico
        ? `${servico.servico} | ${servico.quantidade} ${servico.frequenciaUnidadeMedida1} / ${servico.frequenciaUnidadeMedida2}`
        : null,

      observacao: props.item?.observacao || '',
    },
    validate: {
      residuoAcondicionamento: (value) => {
        if (value.trim() === '') {
          return 'Campo obrigatório';
        }
        return null;
      },
      quantidade: (value: number | null, values: FormViewData) => {
        if (value === null || value === undefined) {
          return 'Campo obrigatório';
        }
        if (!values.compartilhado && value < 1) {
          return 'Valor deve ser maior que zero';
        }
        return null;
      },
      quantidadeIdUnidadeMedida: (value: string | null) => {
        if (!value) {
          return 'Campo obrigatório';
        }
        return null;
      },
      propostaServico: (value: string | null, values: FormViewData) => {
        if (values.compartilhado && (!value || value.trim() === '')) {
          return 'Campo obrigatório';
        }
        return null;
      },
    },
  });

  const isModified = (): boolean => {
    const origData = `${props.origItem?.idResiduoAcondicionamento || ''}
    |${Number(props.origItem?.quantidade) || ''}
    |${props.origItem?.quantidadeIdUnidadeMedida || ''}
    |${props.origItem?.compartilhado || ''}
    |${props.origItem?.gerenciar || ''}
    |${props.origItem?.idPropostaServico || ''}
    |${props.origItem?.idPropostaServicoTemp || ''}
    |${props.origItem?.observacao || ''}`;

    const formData = `${form.values.idResiduoAcondicionamento || ''}
    |${Number(form.values.quantidade) || ''}
    |${form.values.quantidadeIdUnidadeMedida || ''}
    |${form.values.compartilhado || ''}
    |${form.values.gerenciar || ''}
    |${form.values.idPropostaServico || ''}
    |${form.values.idPropostaServicoTemp || ''}
    |${form.values.observacao || ''}`;

    return origData !== formData;
  };

  const handleSubmit = async (values: FormViewData) => {
    if (form.validate().hasErrors) {
      return;
    }

    if (props.origItem) {
      if (isModified() && props.origItem.idPropostaResiduoPlanoAcondicionamento) {
        values.action = Action.Modify;
      } else if (values.compartilhado) {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
          compartilhado: true,
          idPropostaServico: values.idPropostaServico,
          idPropostaServicoTemp: values.idPropostaServicoTemp,
        };
      } else {
        values = {
          ...values,
          ...props.origItem,
          action: props.item?.action || Action.Nothing,
          quantidadeIdUnidadeMedida: form.values.quantidadeIdUnidadeMedida?.toString() || '',
        };
      }
    }

    const formItem: ProposalResiduePlanPackagingType = {
      ...values,
      ...buildFakeAuditObject(),
      idTemporaria: values.id,
      idPropostaResiduoPlano: props.idPropostaResiduoPlano || -1,
    };

    formItem.quantidadeIdUnidadeMedida = Number(formItem.quantidadeIdUnidadeMedida);
    formItem.quantidadeUnidadeMedida =
      props.referenceData.unitOfMeasures.find(
        (x) => x.idUnidadeMedida === Number(form.values.quantidadeIdUnidadeMedida)
      )?.unidadeMedida || '';

    if (!formItem.compartilhado) {
      formItem.idPropostaServico = null;
      formItem.idPropostaServicoTemp = null;
    } else {
      // formItem.quantidade /= 100;
      formItem.quantidade = 0;
    }

    formItem.observacao = formItem.observacao?.trim() || null;
    props.callback({ ...formItem, action: values.action, id: values.id }, 'callback', true);
  };

  return (
    <form id="residue-plan-packaging" noValidate>
      <Paper shadow="xs" p="md" withBorder>
        <Grid columns={6}>
          <Grid.Col span={3}>
            <PackagingSearch
              label="Acondicionamento"
              formItem={form.getInputProps('residuoAcondicionamento')}
              idsToBeDisabled={[]}
              callback={(item: PackagingType | null) => {
                if (item) {
                  form.setFieldValue('idResiduoAcondicionamento', item.idResiduoAcondicionamento);
                  form.setFieldValue('residuoAcondicionamento', item.residuoAcondicionamento);
                  form.setFieldValue('residuoAcondicionamentoDescricao', item.descricao);

                  if (form.values.compartilhado) {
                    form.setFieldValue('propostaServicoData', null);
                    form.setFieldValue('idPropostaServico', null);
                    form.setFieldValue('idPropostaServicoTemp', null);
                    form.setFieldValue('propostaServico', '');
                  }
                }
              }}
              disabled={false}
            />
          </Grid.Col>
          {/* Client no longer wants to fractionate shared services. Plumbing was already in place. */}
          <Grid.Col span={3} hidden={form.values.compartilhado}>
            <Input.Wrapper label="Quantidade" required>
              <div />
            </Input.Wrapper>
            <Group spacing="xs">
              <NumberInput
                style={{ width: 125 }}
                placeholder="Quantidade"
                icon={form.values.compartilhado ? <Percentage size={15} /> : undefined}
                min={1}
                max={form.values.compartilhado ? 99 : undefined}
                defaultValue={1}
                step={1}
                stepHoldDelay={500}
                stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                required
                disabled={form.values.compartilhado}
                {...form.getInputProps('quantidade')}
              />
              <Select
                icon={<Ruler2 size={15} />}
                placeholder="Selecione..."
                data={props.referenceData.unitOfMeasures
                  .filter((x) => x.tipo === 'Outro' && x.unidadeMedidaSigla === 'un')
                  .map((x) => {
                    return {
                      value: x.idUnidadeMedida.toString(),
                      label: `${x.unidadeMedida} (${x.unidadeMedidaSigla})`,
                      group: x.tipo,
                    };
                  })}
                searchable
                disabled
                required
                {...form.getInputProps('quantidadeIdUnidadeMedida')}
              />
            </Group>
          </Grid.Col>
        </Grid>
        <Space h="xs" />

        <Grid columns={5}>
          <Grid.Col span={1}>
            <div>
              <Input.Wrapper label="Gerenciar?">
                <div />
              </Input.Wrapper>
              <Switch {...form.getInputProps('gerenciar', { type: 'checkbox' })} />
            </div>
          </Grid.Col>
          <Grid.Col span={1}>
            <div>
              <Input.Wrapper label="Compartilhado?">
                <div />
              </Input.Wrapper>
              <Switch {...form.getInputProps('compartilhado', { type: 'checkbox' })} />
            </div>
          </Grid.Col>
          {form.values.compartilhado && (
            <Grid.Col span={3}>
              <ProposalServiceList
                label="Serviço"
                tooltip="O serviço deve referenciar o mesmo acondicionamento, ser no mesmo endereço do resíduo, ter uma cotação primária e ser compartilhado."
                referenceData={{
                  idProposta: null,
                  servicos: props.services.filter((x) =>
                    validarReferenciaServicoCompartilhado(
                      form.values.idResiduoAcondicionamento!,
                      props.idEntidadeEndereco,
                      x
                    )
                  ),
                }}
                formItem={form.getInputProps('propostaServico')}
                idPropostaServico={form.values.idPropostaServico || 0}
                propostaServico={form.values.propostaServico}
                idsToBeDisabled={[]}
                disabled={false}
                callback={async (item: ProposalServiceType | null) => {
                  if (item) {
                    form.setFieldValue('propostaServicoData', item);
                    form.setFieldValue('idPropostaServico', item.idPropostaServico || null);
                    form.setFieldValue('idPropostaServicoTemp', item.id || null);
                    form.setFieldValue(
                      'propostaServico',
                      `${item.servico} | ${item.quantidade} ${item.frequenciaUnidadeMedida1} / ${item.frequenciaUnidadeMedida2}`
                    );
                  }
                }}
              />
            </Grid.Col>
          )}
        </Grid>
        <Space h="xs" />

        <SimpleGrid cols={1}>
          <Textarea
            icon={<Notes size={15} />}
            label="Observação"
            placeholder="Observação sobre o acondicionamento"
            autosize
            maxLength={500}
            {...form.getInputProps('observacao')}
          />
        </SimpleGrid>
      </Paper>
      <Space h="xl" />

      <Group position="right">
        <Button
          leftIcon={<DeviceFloppy size={18} />}
          type="button"
          form="residue-plan-packaging"
          onClick={() => handleSubmit(form.values)}
        >
          Salvar
        </Button>
      </Group>
    </form>
  );
}
